// -- (function(scope){
// -- 'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.fb.ne === region.gW.ne)
	{
		return 'on line ' + region.fb.ne;
	}
	return 'on lines ' + region.fb.ne + ' through ' + region.gW.ne;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.m1,
		impl.oV,
		impl.oD,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


/*
function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}

*/

/*
function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}
*/


/*
function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}

*/

/*
function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}
*/




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		a4: func(record.a4),
		fc: record.fc,
		e$: record.e$
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.a4;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.fc;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.e$) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.m1,
		impl.oV,
		impl.oD,
		function(sendToApp, initialModel) {
			var view = impl.o8;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.m1,
		impl.oV,
		impl.oD,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.e7 && impl.e7(sendToApp)
			var view = impl.o8;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.le);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.dW) && (_VirtualDom_doc.title = title = doc.dW);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.nN;
	var onUrlRequest = impl.nO;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		e7: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.ob === next.ob
							&& curr.mL === next.mL
							&& curr.n2.a === next.n2.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		m1: function(flags)
		{
			return A3(impl.m1, flags, _Browser_getUrl(), key);
		},
		o8: impl.o8,
		oV: impl.oV,
		oD: impl.oD
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { mK: 'hidden', ls: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { mK: 'mozHidden', ls: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { mK: 'msHidden', ls: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { mK: 'webkitHidden', ls: 'webkitvisibilitychange' }
		: { mK: 'hidden', ls: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		jF: _Browser_getScene(),
		kF: {
			kM: _Browser_window.pageXOffset,
			kN: _Browser_window.pageYOffset,
			kL: _Browser_doc.documentElement.clientWidth,
			hw: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		kL: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		hw: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			jF: {
				kL: node.scrollWidth,
				hw: node.scrollHeight
			},
			kF: {
				kM: node.scrollLeft,
				kN: node.scrollTop,
				kL: node.clientWidth,
				hw: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			jF: _Browser_getScene(),
			kF: {
				kM: x,
				kN: y,
				kL: _Browser_doc.documentElement.clientWidth,
				hw: _Browser_doc.documentElement.clientHeight
			},
			l8: {
				kM: x + rect.left,
				kN: y + rect.top,
				kL: rect.width,
				hw: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.mc.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.mc.b, xhr)); });
		$elm$core$Maybe$isJust(request.oS) && _Http_track(router, xhr, request.oS.a);

		try {
			xhr.open(request.nq, request.o$, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.o$));
		}

		_Http_configureRequest(xhr, request);

		request.le.a && xhr.setRequestHeader('Content-Type', request.le.a);
		xhr.send(request.le.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.mJ; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.oN.a || 0;
	xhr.responseType = request.mc.d;
	xhr.withCredentials = request.kY;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		o$: xhr.responseURL,
		oy: xhr.status,
		oz: xhr.statusText,
		mJ: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			ou: event.loaded,
			jW: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			og: event.loaded,
			jW: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}



// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


// DECODER

var _File_decoder = _Json_decodePrim(function(value) {
	// NOTE: checks if `File` exists in case this is run on node
	return (typeof File !== 'undefined' && value instanceof File)
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FILE', value);
});


// METADATA

function _File_name(file) { return file.name; }
function _File_mime(file) { return file.type; }
function _File_size(file) { return file.size; }

function _File_lastModified(file)
{
	return $elm$time$Time$millisToPosix(file.lastModified);
}


// DOWNLOAD

var _File_downloadNode;

function _File_getDownloadNode()
{
	return _File_downloadNode || (_File_downloadNode = document.createElement('a'));
}

var _File_download = F3(function(name, mime, content)
{
	return _Scheduler_binding(function(callback)
	{
		var blob = new Blob([content], {type: mime});

		// for IE10+
		if (navigator.msSaveOrOpenBlob)
		{
			navigator.msSaveOrOpenBlob(blob, name);
			return;
		}

		// for HTML5
		var node = _File_getDownloadNode();
		var objectUrl = URL.createObjectURL(blob);
		node.href = objectUrl;
		node.download = name;
		_File_click(node);
		URL.revokeObjectURL(objectUrl);
	});
});

function _File_downloadUrl(href)
{
	return _Scheduler_binding(function(callback)
	{
		var node = _File_getDownloadNode();
		node.href = href;
		node.download = '';
		node.origin === location.origin || (node.target = '_blank');
		_File_click(node);
	});
}


// IE COMPATIBILITY

function _File_makeBytesSafeForInternetExplorer(bytes)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/10
	// all other browsers can just run `new Blob([bytes])` directly with no problem
	//
	return new Uint8Array(bytes.buffer, bytes.byteOffset, bytes.byteLength);
}

function _File_click(node)
{
	// only needed by IE10 and IE11 to fix https://github.com/elm/file/issues/11
	// all other browsers have MouseEvent and do not need this conditional stuff
	//
	if (typeof MouseEvent === 'function')
	{
		node.dispatchEvent(new MouseEvent('click'));
	}
	else
	{
		var event = document.createEvent('MouseEvents');
		event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
		document.body.appendChild(node);
		node.dispatchEvent(event);
		document.body.removeChild(node);
	}
}


// UPLOAD

var _File_node;

function _File_uploadOne(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			callback(_Scheduler_succeed(event.target.files[0]));
		});
		_File_click(_File_node);
	});
}

function _File_uploadOneOrMore(mimes)
{
	return _Scheduler_binding(function(callback)
	{
		_File_node = document.createElement('input');
		_File_node.type = 'file';
		_File_node.multiple = true;
		_File_node.accept = A2($elm$core$String$join, ',', mimes);
		_File_node.addEventListener('change', function(event)
		{
			var elmFiles = _List_fromArray(event.target.files);
			callback(_Scheduler_succeed(_Utils_Tuple2(elmFiles.a, elmFiles.b)));
		});
		_File_click(_File_node);
	});
}


// CONTENT

function _File_toString(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsText(blob);
		return function() { reader.abort(); };
	});
}

function _File_toBytes(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(new DataView(reader.result)));
		});
		reader.readAsArrayBuffer(blob);
		return function() { reader.abort(); };
	});
}

function _File_toUrl(blob)
{
	return _Scheduler_binding(function(callback)
	{
		var reader = new FileReader();
		reader.addEventListener('loadend', function() {
			callback(_Scheduler_succeed(reader.result));
		});
		reader.readAsDataURL(blob);
		return function() { reader.abort(); };
	});
}




function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}
var $elm$browser$Browser$Document = F2(
	function (title, body) {
		return {le: body, dW: title};
	});
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$Msg$LinkClicked = function (a) {
	return {$: 11, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $author$project$Main$Msg$UrlChanged = function (a) {
	return {$: 12, a: a};
};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.z) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.A),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.A);
		} else {
			var treeLen = builder.z * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.B) : builder.B;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.z);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.A) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.A);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{B: nodeList, z: (len / $elm$core$Array$branchFactor) | 0, A: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {mw: fragment, mL: host, nX: path, n2: port_, ob: protocol, of: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Main$Msg$FetchPIRootApiLinks = {$: 0};
var $author$project$Routes$Pages$Landing = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$CommonModel$RootAPILinks = F4(
	function (activites, clients, piLinks, allLinks) {
		return {d0: activites, kW: allLinks, ca: clients, n0: piLinks};
	});
var $author$project$Main$CommonModel$emptyRootApiLinks = A4($author$project$Main$CommonModel$RootAPILinks, _List_Nil, _List_Nil, _List_Nil, _List_Nil);
var $author$project$Components$Landing$Model$Model = F5(
	function (links, countClients, ca, production, preferredDocs) {
		return {lh: ca, lC: countClients, nf: links, n3: preferredDocs, n6: production};
	});
var $author$project$Components$Landing$Model$init = function (links) {
	return A5($author$project$Components$Landing$Model$Model, links, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, _List_Nil);
};
var $justinmimbs$timezone_data$TimeZone$Specification$DateTime = F5(
	function (year, month, day, time, clock) {
		return {ay: clock, gC: day, ia: month, dU: time, kO: year};
	});
var $elm$time$Time$Jan = 0;
var $justinmimbs$timezone_data$TimeZone$Specification$Rules = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$timezone_data$TimeZone$Specification$WallClock = 2;
var $justinmimbs$timezone_data$TimeZone$Specification$Zone = F2(
	function (history, current) {
		return {ei: current, U: history};
	});
var $justinmimbs$timezone_data$TimeZone$Specification$ZoneState = F2(
	function (standardOffset, zoneRules) {
		return {fa: standardOffset, fs: zoneRules};
	});
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $justinmimbs$timezone_data$TimeZone$maxYear = 2037;
var $justinmimbs$timezone_data$TimeZone$minYear = 1970;
var $justinmimbs$timezone_data$TimeZone$Specification$Universal = 0;
var $justinmimbs$timezone_data$TimeZone$Specification$dropChangesBeforeEpoch = function (_v0) {
	dropChangesBeforeEpoch:
	while (true) {
		var initial = _v0.a;
		var changes = _v0.b;
		if (changes.b) {
			var change = changes.a;
			var rest = changes.b;
			if (change.fb <= 0) {
				var $temp$_v0 = _Utils_Tuple2(change.d, rest);
				_v0 = $temp$_v0;
				continue dropChangesBeforeEpoch;
			} else {
				return _Utils_Tuple2(initial, changes);
			}
		} else {
			return _Utils_Tuple2(initial, _List_Nil);
		}
	}
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $justinmimbs$timezone_data$RataDie$weekdayNumber = function (rd) {
	var _v0 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v0) {
		return 7;
	} else {
		var n = _v0;
		return n;
	}
};
var $justinmimbs$timezone_data$RataDie$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$timezone_data$RataDie$floorWeekday = F2(
	function (weekday, rd) {
		var daysSincePreviousWeekday = A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$timezone_data$RataDie$weekdayNumber(rd) + 7) - $justinmimbs$timezone_data$RataDie$weekdayToNumber(weekday));
		return rd - daysSincePreviousWeekday;
	});
var $justinmimbs$timezone_data$RataDie$ceilingWeekday = F2(
	function (weekday, rd) {
		var floored = A2($justinmimbs$timezone_data$RataDie$floorWeekday, weekday, rd);
		return _Utils_eq(rd, floored) ? rd : (floored + 7);
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $justinmimbs$timezone_data$RataDie$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$timezone_data$RataDie$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$timezone_data$RataDie$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$timezone_data$RataDie$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
	return (365 * y) + leapYears;
};
var $justinmimbs$timezone_data$RataDie$dayOfMonth = F3(
	function (y, m, d) {
		return ($justinmimbs$timezone_data$RataDie$daysBeforeYear(y) + A2($justinmimbs$timezone_data$RataDie$daysBeforeMonth, y, m)) + d;
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $justinmimbs$timezone_data$RataDie$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$timezone_data$RataDie$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$timezone_data$RataDie$lastOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$timezone_data$RataDie$daysBeforeYear(y) + A2($justinmimbs$timezone_data$RataDie$daysBeforeMonth, y, m)) + A2($justinmimbs$timezone_data$RataDie$daysInMonth, y, m);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie = function (rd) {
	return (rd - 719163) * 1440;
};
var $elm$core$List$sortBy = _List_sortBy;
var $justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment = F2(
	function (clock, _v0) {
		var standard = _v0.aN;
		var save = _v0.G;
		switch (clock) {
			case 0:
				return 0;
			case 1:
				return 0 - standard;
			default:
				return 0 - (standard + save);
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$minutesFromDateTime = function (_v0) {
	var year = _v0.kO;
	var month = _v0.ia;
	var day = _v0.gC;
	var time = _v0.dU;
	return $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie(
		A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, month, day)) + time;
};
var $justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime = F2(
	function (offset, datetime) {
		return $justinmimbs$timezone_data$TimeZone$Specification$minutesFromDateTime(datetime) + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, datetime.ay, offset);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$rulesToOffsetChanges = F5(
	function (previousOffset, start, until, standardOffset, rules) {
		var transitions = A2(
			$elm$core$List$concatMap,
			function (year) {
				return A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.fb;
					},
					A2(
						$elm$core$List$map,
						function (rule) {
							return {
								ay: rule.ay,
								G: rule.G,
								fb: $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie(
									function () {
										var _v2 = rule.gC;
										switch (_v2.$) {
											case 0:
												var day = _v2.a;
												return A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.ia, day);
											case 1:
												var weekday = _v2.a;
												var after = _v2.b;
												return A2(
													$justinmimbs$timezone_data$RataDie$ceilingWeekday,
													weekday,
													A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.ia, after));
											case 2:
												var weekday = _v2.a;
												var before = _v2.b;
												return A2(
													$justinmimbs$timezone_data$RataDie$floorWeekday,
													weekday,
													A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.ia, before));
											default:
												var weekday = _v2.a;
												return A2(
													$justinmimbs$timezone_data$RataDie$floorWeekday,
													weekday,
													A2($justinmimbs$timezone_data$RataDie$lastOfMonth, year, rule.ia));
										}
									}()) + rule.dU
							};
						},
						A2(
							$elm$core$List$filter,
							function (rule) {
								return (_Utils_cmp(rule.my, year) < 1) && (_Utils_cmp(year, rule.oO) < 1);
							},
							rules)));
			},
			A2($elm$core$List$range, start.kO - 1, until.kO));
		var initialOffset = {G: 0, aN: standardOffset};
		var initialChange = {
			d: standardOffset,
			fb: A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, previousOffset, start)
		};
		var _v0 = A3(
			$elm$core$List$foldl,
			F2(
				function (transition, _v1) {
					var currentOffset = _v1.a;
					var changes = _v1.b;
					var newOffset = {G: transition.G, aN: standardOffset};
					if (_Utils_cmp(
						transition.fb + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.ay, previousOffset),
						initialChange.fb) < 1) {
						var updatedInitialChange = {d: standardOffset + transition.G, fb: initialChange.fb};
						return _Utils_Tuple2(
							newOffset,
							_List_fromArray(
								[updatedInitialChange]));
					} else {
						if (_Utils_cmp(
							transition.fb + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.ay, currentOffset),
							A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, currentOffset, until)) < 0) {
							var change = {
								d: standardOffset + transition.G,
								fb: transition.fb + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.ay, currentOffset)
							};
							return _Utils_Tuple2(
								newOffset,
								A2($elm$core$List$cons, change, changes));
						} else {
							return _Utils_Tuple2(currentOffset, changes);
						}
					}
				}),
			_Utils_Tuple2(
				initialOffset,
				_List_fromArray(
					[initialChange])),
			transitions);
		var nextOffset = _v0.a;
		var descendingChanges = _v0.b;
		return _Utils_Tuple2(
			$elm$core$List$reverse(descendingChanges),
			nextOffset);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$stateToOffsetChanges = F4(
	function (previousOffset, start, until, _v0) {
		var standardOffset = _v0.fa;
		var zoneRules = _v0.fs;
		if (!zoneRules.$) {
			var save = zoneRules.a;
			return _Utils_Tuple2(
				_List_fromArray(
					[
						{
						d: standardOffset + save,
						fb: A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, previousOffset, start)
					}
					]),
				{G: save, aN: standardOffset});
		} else {
			var rules = zoneRules.a;
			return A5($justinmimbs$timezone_data$TimeZone$Specification$rulesToOffsetChanges, previousOffset, start, until, standardOffset, rules);
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$stripDuplicatesByHelp = F4(
	function (f, a, rev, list) {
		stripDuplicatesByHelp:
		while (true) {
			if (!list.b) {
				return $elm$core$List$reverse(rev);
			} else {
				var x = list.a;
				var xs = list.b;
				var b = f(x);
				var rev_ = (!_Utils_eq(a, b)) ? A2($elm$core$List$cons, x, rev) : rev;
				var $temp$f = f,
					$temp$a = b,
					$temp$rev = rev_,
					$temp$list = xs;
				f = $temp$f;
				a = $temp$a;
				rev = $temp$rev;
				list = $temp$list;
				continue stripDuplicatesByHelp;
			}
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$zoneToRanges = F3(
	function (zoneStart, zoneUntil, zone) {
		var _v0 = A3(
			$elm$core$List$foldl,
			F2(
				function (_v1, _v2) {
					var state = _v1.a;
					var nextStart = _v1.b;
					var start = _v2.a;
					var ranges = _v2.b;
					return _Utils_Tuple2(
						nextStart,
						A2(
							$elm$core$List$cons,
							_Utils_Tuple3(start, state, nextStart),
							ranges));
				}),
			_Utils_Tuple2(zoneStart, _List_Nil),
			zone.U);
		var currentStart = _v0.a;
		var historyRanges = _v0.b;
		return $elm$core$List$reverse(
			A2(
				$elm$core$List$cons,
				_Utils_Tuple3(currentStart, zone.ei, zoneUntil),
				historyRanges));
	});
var $justinmimbs$timezone_data$TimeZone$Specification$toOffsets = F3(
	function (minYear, maxYear, zone) {
		var initialState = function () {
			var _v5 = zone.U;
			if (_v5.b) {
				var _v6 = _v5.a;
				var earliest = _v6.a;
				return earliest;
			} else {
				return zone.ei;
			}
		}();
		var initialOffset = {
			G: function () {
				var _v4 = initialState.fs;
				if (!_v4.$) {
					var save = _v4.a;
					return save;
				} else {
					return 0;
				}
			}(),
			aN: initialState.fa
		};
		var ascendingChanges = A4(
			$justinmimbs$timezone_data$TimeZone$Specification$stripDuplicatesByHelp,
			function ($) {
				return $.d;
			},
			initialOffset.aN + initialOffset.G,
			_List_Nil,
			A3(
				$elm$core$List$foldl,
				F2(
					function (_v1, _v2) {
						var start = _v1.a;
						var state = _v1.b;
						var until = _v1.c;
						var prevOffset = _v2.a;
						var prevChanges = _v2.b;
						var _v3 = A4($justinmimbs$timezone_data$TimeZone$Specification$stateToOffsetChanges, prevOffset, start, until, state);
						var nextChanges = _v3.a;
						var nextOffset = _v3.b;
						return _Utils_Tuple2(
							nextOffset,
							_Utils_ap(prevChanges, nextChanges));
					}),
				_Utils_Tuple2(initialOffset, _List_Nil),
				A3(
					$justinmimbs$timezone_data$TimeZone$Specification$zoneToRanges,
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, minYear, 0, 1, 0, 0),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, maxYear + 1, 0, 1, 0, 0),
					zone)).b);
		var _v0 = $justinmimbs$timezone_data$TimeZone$Specification$dropChangesBeforeEpoch(
			_Utils_Tuple2(initialOffset.aN + initialOffset.G, ascendingChanges));
		var initial = _v0.a;
		var ascending = _v0.b;
		return _Utils_Tuple2(
			$elm$core$List$reverse(ascending),
			initial);
	});
var $justinmimbs$timezone_data$TimeZone$fromSpecification = function (zone) {
	var _v0 = A3($justinmimbs$timezone_data$TimeZone$Specification$toOffsets, $justinmimbs$timezone_data$TimeZone$minYear, $justinmimbs$timezone_data$TimeZone$maxYear, zone);
	var descending = _v0.a;
	var bottom = _v0.b;
	return A2($elm$time$Time$customZone, bottom, descending);
};
var $elm$time$Time$Apr = 3;
var $justinmimbs$timezone_data$TimeZone$Specification$Day = function (a) {
	return {$: 0, a: a};
};
var $justinmimbs$timezone_data$TimeZone$Specification$Last = function (a) {
	return {$: 3, a: a};
};
var $elm$time$Time$Mar = 2;
var $justinmimbs$timezone_data$TimeZone$Specification$Next = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$time$Time$Oct = 9;
var $justinmimbs$timezone_data$TimeZone$Specification$Rule = F7(
	function (from, to, month, day, time, clock, save) {
		return {ay: clock, gC: day, my: from, ia: month, G: save, dU: time, oO: to};
	});
var $elm$time$Time$Sep = 8;
var $elm$time$Time$Sun = 6;
var $justinmimbs$timezone_data$TimeZone$rules_EU = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1980,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		60,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		60,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1995,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$rules_France = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		60,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(26),
		60,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__paris = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_France)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1977, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $author$project$Components$Auth$Model$Model = F4(
	function (identifier, password, authenticated, links) {
		return {k6: authenticated, mO: identifier, nf: links, nW: password};
	});
var $author$project$Components$Auth$Model$init = A4($author$project$Components$Auth$Model$Model, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, _List_Nil);
var $author$project$Components$Errors$Errors$Model = function (messages) {
	return {cp: messages};
};
var $author$project$Components$Errors$Errors$init = $author$project$Components$Errors$Errors$Model($elm$core$Maybe$Nothing);
var $author$project$Components$Loading$Model$Model = function (load) {
	return {ng: load};
};
var $author$project$Components$Loading$Model$init = $author$project$Components$Loading$Model$Model(0);
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Main$MainModel$parseDisabledModules = function (raw) {
	return A2($elm$core$String$split, ',', raw);
};
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Main$MainModel$initMainModel = F4(
	function (page, flags, key, url) {
		return {
			fw: $elm$core$Maybe$Nothing,
			d2: _List_Nil,
			fI: key,
			fK: $author$project$Components$Auth$Model$init,
			fL: $elm$core$Maybe$Nothing,
			lV: A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2($elm$core$Maybe$map, $author$project$Main$MainModel$parseDisabledModules, flags.lV)),
			ch: $author$project$Components$Errors$Errors$init,
			aa: flags,
			aG: $author$project$Components$Loading$Model$init,
			cq: _List_Nil,
			ig: $elm$core$Maybe$Nothing,
			iy: page,
			aM: $author$project$Main$CommonModel$emptyRootApiLinks,
			aO: $justinmimbs$timezone_data$TimeZone$europe__paris(0),
			o$: url
		};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd = A2(
	$elm$core$Basics$composeR,
	$elm$core$Task$succeed,
	$elm$core$Task$perform($elm$core$Basics$identity));
var $author$project$Main$MainUpdate$init = F3(
	function (flags, url, key) {
		return _Utils_Tuple2(
			A4(
				$author$project$Main$MainModel$initMainModel,
				$author$project$Routes$Pages$Landing(
					$author$project$Components$Landing$Model$init($author$project$Main$CommonModel$emptyRootApiLinks)),
				flags,
				key,
				url),
			$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd($author$project$Main$Msg$FetchPIRootApiLinks));
	});
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Main$Msg$ActiveUserComponentAction = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$Msg$Fail = function (a) {
	return {$: 31, a: a};
};
var $author$project$Main$Msg$LoadingAction = function (a) {
	return {$: 33, a: a};
};
var $author$project$Main$Msg$NoOp = {$: 34};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $author$project$Components$Loading$Update$FetchEnd = 1;
var $author$project$Components$Loading$Update$FetchStart = 0;
var $author$project$Main$buildLoadingEvent = function (raw) {
	switch (raw) {
		case 'Start':
			return $elm$core$Maybe$Just(0);
		case 'End':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Main$initBaseUrl = function (url) {
	return _Utils_update(
		url,
		{mw: $elm$core$Maybe$Nothing, of: $elm$core$Maybe$Nothing});
};
var $author$project$Main$listenLinkClickedInSubModule = _Platform_incomingPort('listenLinkClickedInSubModule', $elm$json$Json$Decode$string);
var $author$project$Main$loadingEvent = _Platform_incomingPort('loadingEvent', $elm$json$Json$Decode$string);
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$json$Json$Decode$list = _Json_decodeList;
var $author$project$Main$showError = _Platform_incomingPort(
	'showError',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $author$project$Components$ActiveUser$Update$ActiveUser = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$ActiveUser$Update$receiveActiveUserProcessUid = _Platform_incomingPort('receiveActiveUserProcessUid', $elm$json$Json$Decode$string);
var $author$project$Components$ActiveUser$Update$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$Components$ActiveUser$Update$receiveActiveUserProcessUid($author$project$Components$ActiveUser$Update$ActiveUser)
		]));
var $author$project$Main$Msg$ClientsDetailComponentAction = function (a) {
	return {$: 23, a: a};
};
var $author$project$Main$Msg$ClientsListComponentAction = function (a) {
	return {$: 22, a: a};
};
var $author$project$Main$Msg$ImportSupportsAction = function (a) {
	return {$: 26, a: a};
};
var $author$project$Main$Msg$ListSupportsAction = function (a) {
	return {$: 28, a: a};
};
var $author$project$Main$Msg$ProduitDetailsAction = function (a) {
	return {$: 25, a: a};
};
var $author$project$Main$Msg$ProduitsAction = function (a) {
	return {$: 24, a: a};
};
var $author$project$Main$Msg$SupportDetailAction = function (a) {
	return {$: 29, a: a};
};
var $author$project$Main$Msg$UserNewComponentAction = function (a) {
	return {$: 20, a: a};
};
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Components$Cabinets$Users$New$Update$SetActivitesRights = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$Cabinets$Users$New$Update$SetAvailableSyncUsers = function (a) {
	return {$: 19, a: a};
};
var $author$project$Components$Cabinets$Users$New$Update$SetCabinetRights = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Cabinets$Users$New$Update$SetClientsRights = function (a) {
	return {$: 8, a: a};
};
var $author$project$Components$Cabinets$Users$New$Update$SetUsersLinkedToAssistant = function (a) {
	return {$: 5, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Click = {$: 1};
var $inkuzmin$elm_multiselect$Multiselect$Opened = 2;
var $elm$browser$Browser$Events$Document = 0;
var $elm$browser$Browser$Events$MySub = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$browser$Browser$Events$State = F2(
	function (subs, pids) {
		return {iQ: pids, j4: subs};
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$browser$Browser$Events$init = $elm$core$Task$succeed(
	A2($elm$browser$Browser$Events$State, _List_Nil, $elm$core$Dict$empty));
var $elm$browser$Browser$Events$nodeToKey = function (node) {
	if (!node) {
		return 'd_';
	} else {
		return 'w_';
	}
};
var $elm$browser$Browser$Events$addKey = function (sub) {
	var node = sub.a;
	var name = sub.b;
	return _Utils_Tuple2(
		_Utils_ap(
			$elm$browser$Browser$Events$nodeToKey(node),
			name),
		sub);
};
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$browser$Browser$Events$Event = F2(
	function (key, event) {
		return {gZ: event, hP: key};
	});
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$browser$Browser$Events$spawn = F3(
	function (router, key, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var actualNode = function () {
			if (!node) {
				return _Browser_doc;
			} else {
				return _Browser_window;
			}
		}();
		return A2(
			$elm$core$Task$map,
			function (value) {
				return _Utils_Tuple2(key, value);
			},
			A3(
				_Browser_on,
				actualNode,
				name,
				function (event) {
					return A2(
						$elm$core$Platform$sendToSelf,
						router,
						A2($elm$browser$Browser$Events$Event, key, event));
				}));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $elm$browser$Browser$Events$onEffects = F3(
	function (router, subs, state) {
		var stepRight = F3(
			function (key, sub, _v6) {
				var deads = _v6.a;
				var lives = _v6.b;
				var news = _v6.c;
				return _Utils_Tuple3(
					deads,
					lives,
					A2(
						$elm$core$List$cons,
						A3($elm$browser$Browser$Events$spawn, router, key, sub),
						news));
			});
		var stepLeft = F3(
			function (_v4, pid, _v5) {
				var deads = _v5.a;
				var lives = _v5.b;
				var news = _v5.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, pid, deads),
					lives,
					news);
			});
		var stepBoth = F4(
			function (key, pid, _v2, _v3) {
				var deads = _v3.a;
				var lives = _v3.b;
				var news = _v3.c;
				return _Utils_Tuple3(
					deads,
					A3($elm$core$Dict$insert, key, pid, lives),
					news);
			});
		var newSubs = A2($elm$core$List$map, $elm$browser$Browser$Events$addKey, subs);
		var _v0 = A6(
			$elm$core$Dict$merge,
			stepLeft,
			stepBoth,
			stepRight,
			state.iQ,
			$elm$core$Dict$fromList(newSubs),
			_Utils_Tuple3(_List_Nil, $elm$core$Dict$empty, _List_Nil));
		var deadPids = _v0.a;
		var livePids = _v0.b;
		var makeNewPids = _v0.c;
		return A2(
			$elm$core$Task$andThen,
			function (pids) {
				return $elm$core$Task$succeed(
					A2(
						$elm$browser$Browser$Events$State,
						newSubs,
						A2(
							$elm$core$Dict$union,
							livePids,
							$elm$core$Dict$fromList(pids))));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$sequence(makeNewPids);
				},
				$elm$core$Task$sequence(
					A2($elm$core$List$map, $elm$core$Process$kill, deadPids))));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$browser$Browser$Events$onSelfMsg = F3(
	function (router, _v0, state) {
		var key = _v0.hP;
		var event = _v0.gZ;
		var toMessage = function (_v2) {
			var subKey = _v2.a;
			var _v3 = _v2.b;
			var node = _v3.a;
			var name = _v3.b;
			var decoder = _v3.c;
			return _Utils_eq(subKey, key) ? A2(_Browser_decodeEvent, decoder, event) : $elm$core$Maybe$Nothing;
		};
		var messages = A2($elm$core$List$filterMap, toMessage, state.j4);
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Platform$sendToApp(router),
					messages)));
	});
var $elm$browser$Browser$Events$subMap = F2(
	function (func, _v0) {
		var node = _v0.a;
		var name = _v0.b;
		var decoder = _v0.c;
		return A3(
			$elm$browser$Browser$Events$MySub,
			node,
			name,
			A2($elm$json$Json$Decode$map, func, decoder));
	});
_Platform_effectManagers['Browser.Events'] = _Platform_createManager($elm$browser$Browser$Events$init, $elm$browser$Browser$Events$onEffects, $elm$browser$Browser$Events$onSelfMsg, 0, $elm$browser$Browser$Events$subMap);
var $elm$browser$Browser$Events$subscription = _Platform_leaf('Browser.Events');
var $elm$browser$Browser$Events$on = F3(
	function (node, name, decoder) {
		return $elm$browser$Browser$Events$subscription(
			A3($elm$browser$Browser$Events$MySub, node, name, decoder));
	});
var $elm$browser$Browser$Events$onClick = A2($elm$browser$Browser$Events$on, 0, 'click');
var $inkuzmin$elm_multiselect$Multiselect$subscriptions = function (_v0) {
	var model = _v0;
	return (model.ox === 2) ? $elm$browser$Browser$Events$onClick(
		$elm$json$Json$Decode$succeed($inkuzmin$elm_multiselect$Multiselect$Click)) : $elm$core$Platform$Sub$none;
};
var $author$project$Components$Cabinets$Users$New$Update$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Components$Cabinets$Users$New$Update$SetUsersLinkedToAssistant,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.d4)),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Components$Cabinets$Users$New$Update$SetClientsRights,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.c4)),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Components$Cabinets$Users$New$Update$SetActivitesRights,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.c2)),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Components$Cabinets$Users$New$Update$SetCabinetRights,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.c3)),
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Components$Cabinets$Users$New$Update$SetAvailableSyncUsers,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.fO))
			]));
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$AutreNationalites = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointAutreNationalite = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointPartenaireF = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Conseillers = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PM_RepresentantLegal = function (a) {
	return {$: 9, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PP_RepresentantLegal = function (a) {
	return {$: 12, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonneMoraleF = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonnePhysiqueF = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalAutreNationalite = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Components$Clients$Detail$Action$SetFormFields = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SituationFamillialeF = function (a) {
	return {$: 10, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$UnreferencedConjointF = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Value = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $author$project$Perfimmo$Form$FormModel$getBlock = function (block) {
	if (!block.$) {
		var x = block.a;
		return x;
	} else {
		var x = block.a;
		return x;
	}
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$subscriptions = function (_v0) {
	var model = _v0.a;
	return $inkuzmin$elm_multiselect$Multiselect$subscriptions(model);
};
var $author$project$Components$Clients$Detail$Update$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2(
				$elm$core$Platform$Sub$map,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $author$project$Components$Clients$Detail$Action$SetFormFields, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Conseillers),
					$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Value),
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$subscriptions(model.gb.ef)),
				function () {
				var _v0 = model.gb.ic;
				if (!_v0.$) {
					var params = _v0.a;
					var sub3 = $elm$core$Platform$Sub$batch(
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, r) {
									return A2(
										$elm$core$Platform$Sub$map,
										A2(
											$elm$core$Basics$composeL,
											A2(
												$elm$core$Basics$composeL,
												A2(
													$elm$core$Basics$composeL,
													A2(
														$elm$core$Basics$composeL,
														A2($elm$core$Basics$composeL, $author$project$Components$Clients$Detail$Action$SetFormFields, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique),
														$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonnePhysiqueF),
													$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PP_RepresentantLegal),
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalAutreNationalite(i + 1)),
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Value),
										$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$subscriptions(r.bf));
								}),
							params.dA));
					var sub2 = function () {
						var _v1 = $author$project$Perfimmo$Form$FormModel$getBlock(params.jU).gf;
						if (!_v1.$) {
							return $elm$core$Platform$Sub$none;
						} else {
							var unref = _v1.a;
							return A2(
								$elm$core$Platform$Sub$map,
								A2(
									$elm$core$Basics$composeL,
									A2(
										$elm$core$Basics$composeL,
										A2(
											$elm$core$Basics$composeL,
											A2(
												$elm$core$Basics$composeL,
												A2(
													$elm$core$Basics$composeL,
													A2(
														$elm$core$Basics$composeL,
														A2($elm$core$Basics$composeL, $author$project$Components$Clients$Detail$Action$SetFormFields, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique),
														$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonnePhysiqueF),
													$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SituationFamillialeF),
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointPartenaireF),
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$UnreferencedConjointF),
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointAutreNationalite),
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Value),
								$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$subscriptions(unref.bf));
						}
					}();
					var sub1 = A2(
						$elm$core$Platform$Sub$map,
						A2(
							$elm$core$Basics$composeL,
							A2(
								$elm$core$Basics$composeL,
								A2(
									$elm$core$Basics$composeL,
									A2($elm$core$Basics$composeL, $author$project$Components$Clients$Detail$Action$SetFormFields, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique),
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonnePhysiqueF),
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$AutreNationalites),
							$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Value),
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$subscriptions(params.bf));
					return $elm$core$Platform$Sub$batch(
						_List_fromArray(
							[sub1, sub2, sub3]));
				} else {
					var params = _v0.a;
					return $elm$core$Platform$Sub$batch(
						A2(
							$elm$core$List$indexedMap,
							F2(
								function (i, r) {
									return A2(
										$elm$core$Platform$Sub$map,
										A2(
											$elm$core$Basics$composeL,
											A2(
												$elm$core$Basics$composeL,
												A2(
													$elm$core$Basics$composeL,
													A2(
														$elm$core$Basics$composeL,
														A2($elm$core$Basics$composeL, $author$project$Components$Clients$Detail$Action$SetFormFields, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique),
														$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonneMoraleF),
													$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PM_RepresentantLegal),
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalAutreNationalite(i + 1)),
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Value),
										$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$subscriptions(r.bf));
								}),
							params.dA));
				}
			}()
			]));
};
var $author$project$Components$Clients$List$Update$FilterByConseillers = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Clients$List$Update$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2(
				$elm$core$Platform$Sub$map,
				$author$project$Components$Clients$List$Update$FilterByConseillers,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.cX))
			]));
};
var $author$project$Components$Produits$Details$Update$DocumentsActions = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Produits$Details$Documents$Update$Filtrer = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$Documents$Model$FiltrerParTags = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$Documents$Update$SetTags = function (a) {
	return {$: 8, a: a};
};
var $author$project$Components$Produits$Details$Documents$Model$getEditModel = function (model) {
	var _v0 = model._;
	switch (_v0.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var documentEditModel = _v0.a;
			return $elm$core$Maybe$Just(documentEditModel);
		default:
			var documentEditModel = _v0.b;
			return $elm$core$Maybe$Just(documentEditModel);
	}
};
var $author$project$Components$Produits$Details$Documents$Update$subscriptions = function (model) {
	var tagsSub = A2(
		$elm$core$Platform$Sub$map,
		$author$project$Components$Produits$Details$Documents$Update$SetTags,
		A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Sub$none,
			A2(
				$elm$core$Maybe$map,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions,
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.oK;
					},
					$author$project$Components$Produits$Details$Documents$Model$getEditModel(model)))));
	var filtrerParTagSub = A2(
		$elm$core$Platform$Sub$map,
		A2($elm$core$Basics$composeR, $author$project$Components$Produits$Details$Documents$Model$FiltrerParTags, $author$project$Components$Produits$Details$Documents$Update$Filtrer),
		$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.aY.cu));
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[tagsSub, filtrerParTagSub]));
};
var $author$project$Components$Produits$Details$Update$subscriptions = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Platform$Sub$none,
		A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Components$Produits$Details$Documents$Update$subscriptions,
				$elm$core$Platform$Sub$map($author$project$Components$Produits$Details$Update$DocumentsActions)),
			model.gO));
};
var $author$project$Components$Produits$List$Update$ByProducteur = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$List$Update$SearchProduits = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$List$Update$subscriptions = function (state) {
	if (!state.$) {
		return $elm$core$Platform$Sub$none;
	} else {
		var model = state.a;
		return A2(
			$elm$core$Platform$Sub$map,
			A2($elm$core$Basics$composeL, $author$project$Components$Produits$List$Update$SearchProduits, $author$project$Components$Produits$List$Update$ByProducteur),
			$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.dM));
	}
};
var $author$project$Components$Supports$Details$Update$DocumentsActions = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Supports$Details$Documents$Update$Filtrer = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Supports$Details$Documents$Model$FiltrerParTags = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Supports$Details$Documents$Update$SetTags = function (a) {
	return {$: 8, a: a};
};
var $author$project$Components$Supports$Details$Documents$Model$getEditModel = function (model) {
	var _v0 = model._;
	switch (_v0.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var documentEditModel = _v0.a;
			return $elm$core$Maybe$Just(documentEditModel);
		default:
			var documentEditModel = _v0.b;
			return $elm$core$Maybe$Just(documentEditModel);
	}
};
var $author$project$Components$Supports$Details$Documents$Update$subscriptions = function (model) {
	var tagsSub = A2(
		$elm$core$Platform$Sub$map,
		$author$project$Components$Supports$Details$Documents$Update$SetTags,
		A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Sub$none,
			A2(
				$elm$core$Maybe$map,
				$inkuzmin$elm_multiselect$Multiselect$subscriptions,
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.oK;
					},
					$author$project$Components$Supports$Details$Documents$Model$getEditModel(model)))));
	var filtrerParTagSub = A2(
		$elm$core$Platform$Sub$map,
		A2($elm$core$Basics$composeR, $author$project$Components$Supports$Details$Documents$Model$FiltrerParTags, $author$project$Components$Supports$Details$Documents$Update$Filtrer),
		$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.aY.cu));
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[tagsSub, filtrerParTagSub]));
};
var $author$project$Components$Supports$Details$Update$subscriptions = function (model) {
	return A2(
		$elm$core$Platform$Sub$map,
		$author$project$Components$Supports$Details$Update$DocumentsActions,
		$author$project$Components$Supports$Details$Documents$Update$subscriptions(model.fd.bn));
};
var $author$project$Components$Supports$ImportSupports$List$Update$Filtrer = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Model$FiltrerParCabinets = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Model$FiltrerParProduits = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Model$FiltrerParStatuts = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Model$FiltrerParTypes = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Update$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeL, $author$project$Components$Supports$ImportSupports$List$Update$Filtrer, $author$project$Components$Supports$ImportSupports$List$Model$FiltrerParTypes),
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.aY.b6)),
				A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeL, $author$project$Components$Supports$ImportSupports$List$Update$Filtrer, $author$project$Components$Supports$ImportSupports$List$Model$FiltrerParProduits),
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.aY.aT)),
				A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeL, $author$project$Components$Supports$ImportSupports$List$Update$Filtrer, $author$project$Components$Supports$ImportSupports$List$Model$FiltrerParCabinets),
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.aY.aS)),
				A2(
				$elm$core$Platform$Sub$map,
				A2($elm$core$Basics$composeL, $author$project$Components$Supports$ImportSupports$List$Update$Filtrer, $author$project$Components$Supports$ImportSupports$List$Model$FiltrerParStatuts),
				$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.aY.b7))
			]));
};
var $author$project$Components$Supports$List$Update$ByCompany = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Supports$List$Update$SearchSupports = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$List$Update$subscriptions = function (state) {
	if (!state.$) {
		return $elm$core$Platform$Sub$none;
	} else {
		var model = state.a;
		return A2(
			$elm$core$Platform$Sub$map,
			A2($elm$core$Basics$composeL, $author$project$Components$Supports$List$Update$SearchSupports, $author$project$Components$Supports$List$Update$ByCompany),
			$inkuzmin$elm_multiselect$Multiselect$subscriptions(model.dK));
	}
};
var $author$project$Main$MainUpdate$subscriptions = function (m) {
	var _v0 = m.iy;
	switch (_v0.$) {
		case 6:
			var model = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$Msg$UserNewComponentAction,
				$author$project$Components$Cabinets$Users$New$Update$subscriptions(model));
		case 8:
			var model = _v0.b;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$Msg$ClientsListComponentAction,
				$author$project$Components$Clients$List$Update$subscriptions(model));
		case 9:
			var model = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$Msg$ClientsDetailComponentAction,
				$author$project$Components$Clients$Detail$Update$subscriptions(model));
		case 10:
			var model = _v0.b;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$Msg$ProduitsAction,
				$author$project$Components$Produits$List$Update$subscriptions(model));
		case 11:
			var model = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$Msg$ProduitDetailsAction,
				$author$project$Components$Produits$Details$Update$subscriptions(model));
		case 15:
			var model = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$Msg$SupportDetailAction,
				$author$project$Components$Supports$Details$Update$subscriptions(model));
		case 12:
			var model = _v0.b;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$Msg$ImportSupportsAction,
				$author$project$Components$Supports$ImportSupports$List$Update$subscriptions(model));
		case 14:
			var model = _v0.b;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Main$Msg$ListSupportsAction,
				$author$project$Components$Supports$List$Update$subscriptions(model));
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.ob;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.mw,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.of,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.n2,
					_Utils_ap(http, url.mL)),
				url.nX)));
};
var $author$project$Main$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Main$showError($author$project$Main$Msg$Fail),
				$author$project$Main$listenLinkClickedInSubModule(
				function (rawUrl) {
					var clickLink = function (url) {
						return $author$project$Main$Msg$LinkClicked(
							$elm$browser$Browser$Internal(url));
					};
					var baseUrl = $author$project$Main$initBaseUrl(model.o$);
					var buildedUrl = $elm$url$Url$fromString(
						$elm$url$Url$toString(
							_Utils_update(
								baseUrl,
								{nX: rawUrl})));
					return A2(
						$elm$core$Maybe$withDefault,
						$author$project$Main$Msg$Fail(
							_List_fromArray(
								['Url malformée: ', rawUrl])),
						A2($elm$core$Maybe$map, clickLink, buildedUrl));
				}),
				A2(
				$elm$core$Platform$Sub$map,
				$elm$core$Maybe$withDefault($author$project$Main$Msg$NoOp),
				A2(
					$elm$core$Platform$Sub$map,
					$elm$core$Maybe$map($author$project$Main$Msg$LoadingAction),
					$author$project$Main$loadingEvent($author$project$Main$buildLoadingEvent))),
				$author$project$Main$MainUpdate$subscriptions(model),
				A2($elm$core$Platform$Sub$map, $author$project$Main$Msg$ActiveUserComponentAction, $author$project$Components$ActiveUser$Update$subscriptions)
			]));
};
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles = F2(
	function (_v0, styles) {
		var newStyles = _v0.b;
		var classname = _v0.c;
		return $elm$core$List$isEmpty(newStyles) ? styles : A3($elm$core$Dict$insert, classname, newStyles, styles);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute = function (_v0) {
	var val = _v0.a;
	return val;
};
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$keyedNodeNS = F2(
	function (namespace, tag) {
		return A2(
			_VirtualDom_keyedNodeNS,
			namespace,
			_VirtualDom_noScript(tag));
	});
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$nodeNS = function (tag) {
	return _VirtualDom_nodeNS(
		_VirtualDom_noScript(tag));
};
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml = F2(
	function (_v6, _v7) {
		var key = _v6.a;
		var html = _v6.b;
		var pairs = _v7.a;
		var styles = _v7.b;
		switch (html.$) {
			case 4:
				var vdom = html.a;
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v9 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v9.a;
				var finalStyles = _v9.b;
				var vdom = A3(
					$elm$virtual_dom$VirtualDom$node,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v10 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v10.a;
				var finalStyles = _v10.b;
				var vdom = A4(
					$elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v11 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v11.a;
				var finalStyles = _v11.b;
				var vdom = A3(
					$elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v12 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v12.a;
				var finalStyles = _v12.b;
				var vdom = A4(
					$elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml = F2(
	function (html, _v0) {
		var nodes = _v0.a;
		var styles = _v0.b;
		switch (html.$) {
			case 4:
				var vdomNode = html.a;
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v2 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v2.a;
				var finalStyles = _v2.b;
				var vdomNode = A3(
					$elm$virtual_dom$VirtualDom$node,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v3 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v3.a;
				var finalStyles = _v3.b;
				var vdomNode = A4(
					$elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v4 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v4.a;
				var finalStyles = _v4.b;
				var vdomNode = A3(
					$elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v5 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v5.a;
				var finalStyles = _v5.b;
				var vdomNode = A4(
					$elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
		}
	});
var $elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$stylesFromPropertiesHelp = F2(
	function (candidate, properties) {
		stylesFromPropertiesHelp:
		while (true) {
			if (!properties.b) {
				return candidate;
			} else {
				var _v1 = properties.a;
				var styles = _v1.b;
				var classname = _v1.c;
				var rest = properties.b;
				if ($elm$core$String$isEmpty(classname)) {
					var $temp$candidate = candidate,
						$temp$properties = rest;
					candidate = $temp$candidate;
					properties = $temp$properties;
					continue stylesFromPropertiesHelp;
				} else {
					var $temp$candidate = $elm$core$Maybe$Just(
						_Utils_Tuple2(classname, styles)),
						$temp$properties = rest;
					candidate = $temp$candidate;
					properties = $temp$properties;
					continue stylesFromPropertiesHelp;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties = function (properties) {
	var _v0 = A2($rtfeldman$elm_css$VirtualDom$Styled$stylesFromPropertiesHelp, $elm$core$Maybe$Nothing, properties);
	if (_v0.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var _v1 = _v0.a;
		var classname = _v1.a;
		var styles = _v1.b;
		return A2($elm$core$Dict$singleton, classname, styles);
	}
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$Basics$not = _Basics_not;
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $rtfeldman$elm_css$Css$Structure$compactHelp = F2(
	function (declaration, _v0) {
		var keyframesByName = _v0.a;
		var declarations = _v0.b;
		switch (declaration.$) {
			case 0:
				var _v2 = declaration.a;
				var properties = _v2.c;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 1:
				var styleBlocks = declaration.b;
				return A2(
					$elm$core$List$all,
					function (_v3) {
						var properties = _v3.c;
						return $elm$core$List$isEmpty(properties);
					},
					styleBlocks) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 2:
				var otherDeclarations = declaration.b;
				return $elm$core$List$isEmpty(otherDeclarations) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 3:
				return _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 4:
				var properties = declaration.b;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 5:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 6:
				var record = declaration.a;
				return $elm$core$String$isEmpty(record.lM) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					A3($elm$core$Dict$insert, record.nz, record.lM, keyframesByName),
					declarations);
			case 7:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 8:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			default:
				var tuples = declaration.a;
				return A2(
					$elm$core$List$all,
					function (_v4) {
						var properties = _v4.b;
						return $elm$core$List$isEmpty(properties);
					},
					tuples) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
		}
	});
var $rtfeldman$elm_css$Css$Structure$Keyframes = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations = F2(
	function (keyframesByName, compactedDeclarations) {
		return A2(
			$elm$core$List$append,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var name = _v0.a;
					var decl = _v0.b;
					return $rtfeldman$elm_css$Css$Structure$Keyframes(
						{lM: decl, nz: name});
				},
				$elm$core$Dict$toList(keyframesByName)),
			compactedDeclarations);
	});
var $rtfeldman$elm_css$Css$Structure$compactStylesheet = function (_v0) {
	var charset = _v0.f7;
	var imports = _v0.hD;
	var namespaces = _v0.ib;
	var declarations = _v0.lN;
	var _v1 = A3(
		$elm$core$List$foldr,
		$rtfeldman$elm_css$Css$Structure$compactHelp,
		_Utils_Tuple2($elm$core$Dict$empty, _List_Nil),
		declarations);
	var keyframesByName = _v1.a;
	var compactedDeclarations = _v1.b;
	var finalDeclarations = A2($rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations, keyframesByName, compactedDeclarations);
	return {f7: charset, lN: finalDeclarations, hD: imports, ib: namespaces};
};
var $rtfeldman$elm_css$Css$Structure$Output$charsetToString = function (charset) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			function (str) {
				return '@charset \"' + (str + '\"');
			},
			charset));
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString = function (expression) {
	return '(' + (expression.g1 + (A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$append(': '),
			expression.o6)) + ')'));
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString = function (mediaType) {
	switch (mediaType) {
		case 0:
			return 'print';
		case 1:
			return 'screen';
		default:
			return 'speech';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString = function (mediaQuery) {
	var prefixWith = F3(
		function (str, mediaType, expressions) {
			return str + (' ' + A2(
				$elm$core$String$join,
				' and ',
				A2(
					$elm$core$List$cons,
					$rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString(mediaType),
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions))));
		});
	switch (mediaQuery.$) {
		case 0:
			var expressions = mediaQuery.a;
			return A2(
				$elm$core$String$join,
				' and ',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions));
		case 1:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'only', mediaType, expressions);
		case 2:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'not', mediaType, expressions);
		default:
			var str = mediaQuery.a;
			return str;
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString = F2(
	function (name, mediaQuery) {
		return '@import \"' + (name + ($rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString(mediaQuery) + '\"'));
	});
var $rtfeldman$elm_css$Css$Structure$Output$importToString = function (_v0) {
	var name = _v0.a;
	var mediaQueries = _v0.b;
	return A2(
		$elm$core$String$join,
		'\n',
		A2(
			$elm$core$List$map,
			$rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString(name),
			mediaQueries));
};
var $rtfeldman$elm_css$Css$Structure$Output$namespaceToString = function (_v0) {
	var prefix = _v0.a;
	var str = _v0.b;
	return '@namespace ' + (prefix + ('\"' + (str + '\"')));
};
var $rtfeldman$elm_css$Css$Structure$Output$spaceIndent = '    ';
var $rtfeldman$elm_css$Css$Structure$Output$indent = function (str) {
	return _Utils_ap($rtfeldman$elm_css$Css$Structure$Output$spaceIndent, str);
};
var $rtfeldman$elm_css$Css$Structure$Output$noIndent = '';
var $rtfeldman$elm_css$Css$Structure$Output$emitProperty = function (str) {
	return str + ';';
};
var $rtfeldman$elm_css$Css$Structure$Output$emitProperties = function (properties) {
	return A2(
		$elm$core$String$join,
		'\n',
		A2(
			$elm$core$List$map,
			A2($elm$core$Basics$composeL, $rtfeldman$elm_css$Css$Structure$Output$indent, $rtfeldman$elm_css$Css$Structure$Output$emitProperty),
			properties));
};
var $elm$core$String$append = _String_append;
var $rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString = function (_v0) {
	var str = _v0;
	return '::' + str;
};
var $rtfeldman$elm_css$Css$Structure$Output$combinatorToString = function (combinator) {
	switch (combinator) {
		case 0:
			return '+';
		case 1:
			return '~';
		case 2:
			return '>';
		default:
			return '';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString = function (repeatableSimpleSelector) {
	switch (repeatableSimpleSelector.$) {
		case 0:
			var str = repeatableSimpleSelector.a;
			return '.' + str;
		case 1:
			var str = repeatableSimpleSelector.a;
			return '#' + str;
		case 2:
			var str = repeatableSimpleSelector.a;
			return ':' + str;
		default:
			var str = repeatableSimpleSelector.a;
			return '[' + (str + ']');
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString = function (simpleSelectorSequence) {
	switch (simpleSelectorSequence.$) {
		case 0:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$cons,
					str,
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
		case 1:
			var repeatableSimpleSelectors = simpleSelectorSequence.a;
			return $elm$core$List$isEmpty(repeatableSimpleSelectors) ? '*' : A2(
				$elm$core$String$join,
				'',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors));
		default:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$cons,
					str,
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$selectorChainToString = function (_v0) {
	var combinator = _v0.a;
	var sequence = _v0.b;
	return A2(
		$elm$core$String$join,
		' ',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Structure$Output$combinatorToString(combinator),
				$rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(sequence)
			]));
};
var $rtfeldman$elm_css$Css$Structure$Output$selectorToString = function (_v0) {
	var simpleSelectorSequence = _v0.a;
	var chain = _v0.b;
	var pseudoElement = _v0.c;
	var segments = A2(
		$elm$core$List$cons,
		$rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(simpleSelectorSequence),
		A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$selectorChainToString, chain));
	var pseudoElementsString = A2(
		$elm$core$String$join,
		'',
		_List_fromArray(
			[
				A2(
				$elm$core$Maybe$withDefault,
				'',
				A2($elm$core$Maybe$map, $rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString, pseudoElement))
			]));
	return A2(
		$elm$core$String$append,
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$filter,
				A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
				segments)),
		pseudoElementsString);
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock = F2(
	function (indentLevel, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var properties = _v0.c;
		var selectorStr = A2(
			$elm$core$String$join,
			', ',
			A2(
				$elm$core$List$map,
				$rtfeldman$elm_css$Css$Structure$Output$selectorToString,
				A2($elm$core$List$cons, firstSelector, otherSelectors)));
		return A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					selectorStr,
					' {\n',
					indentLevel,
					$rtfeldman$elm_css$Css$Structure$Output$emitProperties(properties),
					'\n',
					indentLevel,
					'}'
				]));
	});
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration = function (decl) {
	switch (decl.$) {
		case 0:
			var styleBlock = decl.a;
			return A2($rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock, $rtfeldman$elm_css$Css$Structure$Output$noIndent, styleBlock);
		case 1:
			var mediaQueries = decl.a;
			var styleBlocks = decl.b;
			var query = A2(
				$elm$core$String$join,
				',\n',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString, mediaQueries));
			var blocks = A2(
				$elm$core$String$join,
				'\n\n',
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeL,
						$rtfeldman$elm_css$Css$Structure$Output$indent,
						$rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock($rtfeldman$elm_css$Css$Structure$Output$spaceIndent)),
					styleBlocks));
			return '@media ' + (query + (' {\n' + (blocks + '\n}')));
		case 2:
			return 'TODO';
		case 3:
			return 'TODO';
		case 4:
			return 'TODO';
		case 5:
			return 'TODO';
		case 6:
			var name = decl.a.nz;
			var declaration = decl.a.lM;
			return '@keyframes ' + (name + (' {\n' + (declaration + '\n}')));
		case 7:
			return 'TODO';
		case 8:
			return 'TODO';
		default:
			return 'TODO';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrint = function (_v0) {
	var charset = _v0.f7;
	var imports = _v0.hD;
	var namespaces = _v0.ib;
	var declarations = _v0.lN;
	return A2(
		$elm$core$String$join,
		'\n\n',
		A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$Output$charsetToString(charset),
					A2(
					$elm$core$String$join,
					'\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$importToString, imports)),
					A2(
					$elm$core$String$join,
					'\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$namespaceToString, namespaces)),
					A2(
					$elm$core$String$join,
					'\n\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration, declarations))
				])));
};
var $rtfeldman$elm_css$Css$Structure$CounterStyle = function (a) {
	return {$: 8, a: a};
};
var $rtfeldman$elm_css$Css$Structure$FontFace = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_css$Css$Structure$PageRule = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$Selector = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$Structure$SupportsRule = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$Viewport = function (a) {
	return {$: 7, a: a};
};
var $rtfeldman$elm_css$Css$Structure$MediaRule = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$mapLast = F2(
	function (update, list) {
		if (!list.b) {
			return list;
		} else {
			if (!list.b.b) {
				var only = list.a;
				return _List_fromArray(
					[
						update(only)
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$mapLast, update, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$withPropertyAppended = F2(
	function (property, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var properties = _v0.c;
		return A3(
			$rtfeldman$elm_css$Css$Structure$StyleBlock,
			firstSelector,
			otherSelectors,
			_Utils_ap(
				properties,
				_List_fromArray(
					[property])));
	});
var $rtfeldman$elm_css$Css$Structure$appendProperty = F2(
	function (property, declarations) {
		if (!declarations.b) {
			return declarations;
		} else {
			if (!declarations.b.b) {
				switch (declarations.a.$) {
					case 0:
						var styleBlock = declarations.a.a;
						return _List_fromArray(
							[
								$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
								A2($rtfeldman$elm_css$Css$Structure$withPropertyAppended, property, styleBlock))
							]);
					case 1:
						var _v1 = declarations.a;
						var mediaQueries = _v1.a;
						var styleBlocks = _v1.b;
						return _List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$Structure$MediaRule,
								mediaQueries,
								A2(
									$rtfeldman$elm_css$Css$Structure$mapLast,
									$rtfeldman$elm_css$Css$Structure$withPropertyAppended(property),
									styleBlocks))
							]);
					default:
						return declarations;
				}
			} else {
				var first = declarations.a;
				var rest = declarations.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$appendProperty, property, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendToLastSelector = F2(
	function (f, styleBlock) {
		if (!styleBlock.b.b) {
			var only = styleBlock.a;
			var properties = styleBlock.c;
			return _List_fromArray(
				[
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, only, _List_Nil, properties),
					A3(
					$rtfeldman$elm_css$Css$Structure$StyleBlock,
					f(only),
					_List_Nil,
					_List_Nil)
				]);
		} else {
			var first = styleBlock.a;
			var rest = styleBlock.b;
			var properties = styleBlock.c;
			var newRest = A2($elm$core$List$map, f, rest);
			var newFirst = f(first);
			return _List_fromArray(
				[
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, first, rest, properties),
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, newFirst, newRest, _List_Nil)
				]);
		}
	});
var $rtfeldman$elm_css$Css$Structure$applyPseudoElement = F2(
	function (pseudo, _v0) {
		var sequence = _v0.a;
		var selectors = _v0.b;
		return A3(
			$rtfeldman$elm_css$Css$Structure$Selector,
			sequence,
			selectors,
			$elm$core$Maybe$Just(pseudo));
	});
var $rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector = F2(
	function (pseudo, styleBlock) {
		return A2(
			$rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			$rtfeldman$elm_css$Css$Structure$applyPseudoElement(pseudo),
			styleBlock);
	});
var $rtfeldman$elm_css$Css$Structure$CustomSelector = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$TypeSelectorSequence = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Css$Structure$appendRepeatable = F2(
	function (selector, sequence) {
		switch (sequence.$) {
			case 0:
				var typeSelector = sequence.a;
				var list = sequence.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$TypeSelectorSequence,
					typeSelector,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			case 1:
				var list = sequence.a;
				return $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			default:
				var str = sequence.a;
				var list = sequence.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$CustomSelector,
					str,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator = F2(
	function (selector, list) {
		if (!list.b) {
			return _List_Nil;
		} else {
			if (!list.b.b) {
				var _v1 = list.a;
				var combinator = _v1.a;
				var sequence = _v1.b;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						combinator,
						A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, selector, sequence))
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, selector, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableSelector = F2(
	function (repeatableSimpleSelector, selector) {
		if (!selector.b.b) {
			var sequence = selector.a;
			var pseudoElement = selector.c;
			return A3(
				$rtfeldman$elm_css$Css$Structure$Selector,
				A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, repeatableSimpleSelector, sequence),
				_List_Nil,
				pseudoElement);
		} else {
			var firstSelector = selector.a;
			var tuples = selector.b;
			var pseudoElement = selector.c;
			return A3(
				$rtfeldman$elm_css$Css$Structure$Selector,
				firstSelector,
				A2($rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, repeatableSimpleSelector, tuples),
				pseudoElement);
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector = F2(
	function (selector, styleBlock) {
		return A2(
			$rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			$rtfeldman$elm_css$Css$Structure$appendRepeatableSelector(selector),
			styleBlock);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors = function (declarations) {
	collectSelectors:
	while (true) {
		if (!declarations.b) {
			return _List_Nil;
		} else {
			if (!declarations.a.$) {
				var _v1 = declarations.a.a;
				var firstSelector = _v1.a;
				var otherSelectors = _v1.b;
				var rest = declarations.b;
				return _Utils_ap(
					A2($elm$core$List$cons, firstSelector, otherSelectors),
					$rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(rest));
			} else {
				var rest = declarations.b;
				var $temp$declarations = rest;
				declarations = $temp$declarations;
				continue collectSelectors;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Structure$DocumentRule = F5(
	function (a, b, c, d, e) {
		return {$: 3, a: a, b: b, c: c, d: d, e: e};
	});
var $rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock = F2(
	function (update, declarations) {
		_v0$12:
		while (true) {
			if (!declarations.b) {
				return declarations;
			} else {
				if (!declarations.b.b) {
					switch (declarations.a.$) {
						case 0:
							var styleBlock = declarations.a.a;
							return A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration,
								update(styleBlock));
						case 1:
							if (declarations.a.b.b) {
								if (!declarations.a.b.b.b) {
									var _v1 = declarations.a;
									var mediaQueries = _v1.a;
									var _v2 = _v1.b;
									var styleBlock = _v2.a;
									return _List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$Structure$MediaRule,
											mediaQueries,
											update(styleBlock))
										]);
								} else {
									var _v3 = declarations.a;
									var mediaQueries = _v3.a;
									var _v4 = _v3.b;
									var first = _v4.a;
									var rest = _v4.b;
									var _v5 = A2(
										$rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock,
										update,
										_List_fromArray(
											[
												A2($rtfeldman$elm_css$Css$Structure$MediaRule, mediaQueries, rest)
											]));
									if ((_v5.b && (_v5.a.$ === 1)) && (!_v5.b.b)) {
										var _v6 = _v5.a;
										var newMediaQueries = _v6.a;
										var newStyleBlocks = _v6.b;
										return _List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Css$Structure$MediaRule,
												newMediaQueries,
												A2($elm$core$List$cons, first, newStyleBlocks))
											]);
									} else {
										var newDeclarations = _v5;
										return newDeclarations;
									}
								}
							} else {
								break _v0$12;
							}
						case 2:
							var _v7 = declarations.a;
							var str = _v7.a;
							var nestedDeclarations = _v7.b;
							return _List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Structure$SupportsRule,
									str,
									A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, nestedDeclarations))
								]);
						case 3:
							var _v8 = declarations.a;
							var str1 = _v8.a;
							var str2 = _v8.b;
							var str3 = _v8.c;
							var str4 = _v8.d;
							var styleBlock = _v8.e;
							return A2(
								$elm$core$List$map,
								A4($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4),
								update(styleBlock));
						case 4:
							var _v9 = declarations.a;
							return declarations;
						case 5:
							return declarations;
						case 6:
							return declarations;
						case 7:
							return declarations;
						case 8:
							return declarations;
						default:
							return declarations;
					}
				} else {
					break _v0$12;
				}
			}
		}
		var first = declarations.a;
		var rest = declarations.b;
		return A2(
			$elm$core$List$cons,
			first,
			A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, rest));
	});
var $elm$core$String$cons = _String_cons;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {bj: charsProcessed, bw: hash, jI: seed, bO: shift};
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$c1 = 3432918353;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$c2 = 461845907;
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$finalize = function (data) {
	var acc = (!(!data.bw)) ? (data.jI ^ A2(
		$rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy,
		$rtfeldman$elm_css$ElmCssVendor$Murmur3$c2,
		A2(
			$rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy,
			15,
			A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, $rtfeldman$elm_css$ElmCssVendor$Murmur3$c1, data.bw)))) : data.jI;
	var h0 = acc ^ data.bj;
	var h1 = A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var $elm$core$String$foldl = _String_foldl;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$mix = F2(
	function (h1, k1) {
		return A2(
			$rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy,
			5,
			A2(
				$rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy,
				13,
				h1 ^ A2(
					$rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy,
					$rtfeldman$elm_css$ElmCssVendor$Murmur3$c2,
					A2(
						$rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy,
						15,
						A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, $rtfeldman$elm_css$ElmCssVendor$Murmur3$c1, k1))))) + 3864292196;
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$hashFold = F2(
	function (c, data) {
		var res = data.bw | ((255 & $elm$core$Char$toCode(c)) << data.bO);
		var _v0 = data.bO;
		if (_v0 === 24) {
			return {
				bj: data.bj + 1,
				bw: 0,
				jI: A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$mix, data.jI, res),
				bO: 0
			};
		} else {
			return {bj: data.bj + 1, bw: res, jI: data.jI, bO: data.bO + 8};
		}
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$hashString = F2(
	function (seed, str) {
		return $rtfeldman$elm_css$ElmCssVendor$Murmur3$finalize(
			A3(
				$elm$core$String$foldl,
				$rtfeldman$elm_css$ElmCssVendor$Murmur3$hashFold,
				A4($rtfeldman$elm_css$ElmCssVendor$Murmur3$HashData, 0, seed, 0, 0),
				str));
	});
var $rtfeldman$elm_css$Hash$murmurSeed = 15739;
var $elm$core$String$fromList = _String_fromList;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $rtfeldman$elm_hex$Hex$unsafeToDigit = function (num) {
	unsafeToDigit:
	while (true) {
		switch (num) {
			case 0:
				return '0';
			case 1:
				return '1';
			case 2:
				return '2';
			case 3:
				return '3';
			case 4:
				return '4';
			case 5:
				return '5';
			case 6:
				return '6';
			case 7:
				return '7';
			case 8:
				return '8';
			case 9:
				return '9';
			case 10:
				return 'a';
			case 11:
				return 'b';
			case 12:
				return 'c';
			case 13:
				return 'd';
			case 14:
				return 'e';
			case 15:
				return 'f';
			default:
				var $temp$num = num;
				num = $temp$num;
				continue unsafeToDigit;
		}
	}
};
var $rtfeldman$elm_hex$Hex$unsafePositiveToDigits = F2(
	function (digits, num) {
		unsafePositiveToDigits:
		while (true) {
			if (num < 16) {
				return A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(num),
					digits);
			} else {
				var $temp$digits = A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(
						A2($elm$core$Basics$modBy, 16, num)),
					digits),
					$temp$num = (num / 16) | 0;
				digits = $temp$digits;
				num = $temp$num;
				continue unsafePositiveToDigits;
			}
		}
	});
var $rtfeldman$elm_hex$Hex$toString = function (num) {
	return $elm$core$String$fromList(
		(num < 0) ? A2(
			$elm$core$List$cons,
			'-',
			A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, -num)) : A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, num));
};
var $rtfeldman$elm_css$Hash$fromString = function (str) {
	return A2(
		$elm$core$String$cons,
		'_',
		$rtfeldman$elm_hex$Hex$toString(
			A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$hashString, $rtfeldman$elm_css$Hash$murmurSeed, str)));
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$last = function (list) {
	last:
	while (true) {
		if (!list.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!list.b.b) {
				var singleton = list.a;
				return $elm$core$Maybe$Just(singleton);
			} else {
				var rest = list.b;
				var $temp$list = rest;
				list = $temp$list;
				continue last;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration = function (declarations) {
	lastDeclaration:
	while (true) {
		if (!declarations.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!declarations.b.b) {
				var x = declarations.a;
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[x]));
			} else {
				var xs = declarations.b;
				var $temp$declarations = xs;
				declarations = $temp$declarations;
				continue lastDeclaration;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf = function (maybes) {
	oneOf:
	while (true) {
		if (!maybes.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var maybe = maybes.a;
			var rest = maybes.b;
			if (maybe.$ === 1) {
				var $temp$maybes = rest;
				maybes = $temp$maybes;
				continue oneOf;
			} else {
				return maybe;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Structure$FontFeatureValues = function (a) {
	return {$: 9, a: a};
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues = function (tuples) {
	var expandTuples = function (tuplesToExpand) {
		if (!tuplesToExpand.b) {
			return _List_Nil;
		} else {
			var properties = tuplesToExpand.a;
			var rest = tuplesToExpand.b;
			return A2(
				$elm$core$List$cons,
				properties,
				expandTuples(rest));
		}
	};
	var newTuples = expandTuples(tuples);
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$Structure$FontFeatureValues(newTuples)
		]);
};
var $rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule = F2(
	function (mediaQueries, declaration) {
		if (!declaration.$) {
			var styleBlock = declaration.a;
			return A2(
				$rtfeldman$elm_css$Css$Structure$MediaRule,
				mediaQueries,
				_List_fromArray(
					[styleBlock]));
		} else {
			return declaration;
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule = F5(
	function (str1, str2, str3, str4, declaration) {
		if (!declaration.$) {
			var structureStyleBlock = declaration.a;
			return A5($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
		} else {
			return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule = F2(
	function (mediaQueries, declaration) {
		switch (declaration.$) {
			case 0:
				var structureStyleBlock = declaration.a;
				return A2(
					$rtfeldman$elm_css$Css$Structure$MediaRule,
					mediaQueries,
					_List_fromArray(
						[structureStyleBlock]));
			case 1:
				var newMediaQueries = declaration.a;
				var structureStyleBlocks = declaration.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$MediaRule,
					_Utils_ap(mediaQueries, newMediaQueries),
					structureStyleBlocks);
			case 2:
				var str = declaration.a;
				var declarations = declaration.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$SupportsRule,
					str,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
						declarations));
			case 3:
				var str1 = declaration.a;
				var str2 = declaration.b;
				var str3 = declaration.c;
				var str4 = declaration.d;
				var structureStyleBlock = declaration.e;
				return A5($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
			case 4:
				return declaration;
			case 5:
				return declaration;
			case 6:
				return declaration;
			case 7:
				return declaration;
			case 8:
				return declaration;
			default:
				return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet = function (_v0) {
	var declarations = _v0;
	return declarations;
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast = F4(
	function (nestedStyles, rest, f, declarations) {
		var withoutParent = function (decls) {
			return A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$elm$core$List$tail(decls));
		};
		var nextResult = A2(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
			rest,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		var newDeclarations = function () {
			var _v14 = _Utils_Tuple2(
				$elm$core$List$head(nextResult),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$last(declarations));
			if ((!_v14.a.$) && (!_v14.b.$)) {
				var nextResultParent = _v14.a.a;
				var originalParent = _v14.b.a;
				return _Utils_ap(
					A2(
						$elm$core$List$take,
						$elm$core$List$length(declarations) - 1,
						declarations),
					_List_fromArray(
						[
							(!_Utils_eq(originalParent, nextResultParent)) ? nextResultParent : originalParent
						]));
			} else {
				return declarations;
			}
		}();
		var insertStylesToNestedDecl = function (lastDecl) {
			return $elm$core$List$concat(
				A2(
					$rtfeldman$elm_css$Css$Structure$mapLast,
					$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles(nestedStyles),
					A2(
						$elm$core$List$map,
						$elm$core$List$singleton,
						A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, f, lastDecl))));
		};
		var initialResult = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				insertStylesToNestedDecl,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		return _Utils_ap(
			newDeclarations,
			_Utils_ap(
				withoutParent(initialResult),
				withoutParent(nextResult)));
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles = F2(
	function (styles, declarations) {
		if (!styles.b) {
			return declarations;
		} else {
			switch (styles.a.$) {
				case 0:
					var property = styles.a.a;
					var rest = styles.b;
					return A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($rtfeldman$elm_css$Css$Structure$appendProperty, property, declarations));
				case 1:
					var _v4 = styles.a;
					var selector = _v4.a;
					var nestedStyles = _v4.b;
					var rest = styles.b;
					return A4(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector(selector),
						declarations);
				case 2:
					var _v5 = styles.a;
					var selectorCombinator = _v5.a;
					var snippets = _v5.b;
					var rest = styles.b;
					var chain = F2(
						function (_v9, _v10) {
							var originalSequence = _v9.a;
							var originalTuples = _v9.b;
							var originalPseudoElement = _v9.c;
							var newSequence = _v10.a;
							var newTuples = _v10.b;
							var newPseudoElement = _v10.c;
							return A3(
								$rtfeldman$elm_css$Css$Structure$Selector,
								originalSequence,
								_Utils_ap(
									originalTuples,
									A2(
										$elm$core$List$cons,
										_Utils_Tuple2(selectorCombinator, newSequence),
										newTuples)),
								$rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf(
									_List_fromArray(
										[newPseudoElement, originalPseudoElement])));
						});
					var expandDeclaration = function (declaration) {
						switch (declaration.$) {
							case 0:
								var _v7 = declaration.a;
								var firstSelector = _v7.a;
								var otherSelectors = _v7.b;
								var nestedStyles = _v7.c;
								var newSelectors = A2(
									$elm$core$List$concatMap,
									function (originalSelector) {
										return A2(
											$elm$core$List$map,
											chain(originalSelector),
											A2($elm$core$List$cons, firstSelector, otherSelectors));
									},
									$rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations));
								var newDeclarations = function () {
									if (!newSelectors.b) {
										return _List_Nil;
									} else {
										var first = newSelectors.a;
										var remainder = newSelectors.b;
										return _List_fromArray(
											[
												$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
												A3($rtfeldman$elm_css$Css$Structure$StyleBlock, first, remainder, _List_Nil))
											]);
									}
								}();
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, nestedStyles, newDeclarations);
							case 1:
								var mediaQueries = declaration.a;
								var styleBlocks = declaration.b;
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
							case 2:
								var str = declaration.a;
								var otherSnippets = declaration.b;
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, otherSnippets);
							case 3:
								var str1 = declaration.a;
								var str2 = declaration.b;
								var str3 = declaration.c;
								var str4 = declaration.d;
								var styleBlock = declaration.e;
								return A2(
									$elm$core$List$map,
									A4($rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
									$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
							case 4:
								var str = declaration.a;
								var properties = declaration.b;
								return _List_fromArray(
									[
										A2($rtfeldman$elm_css$Css$Structure$PageRule, str, properties)
									]);
							case 5:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$FontFace(properties)
									]);
							case 6:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$Viewport(properties)
									]);
							case 7:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
									]);
							default:
								var tuples = declaration.a;
								return $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
						}
					};
					return $elm$core$List$concat(
						_Utils_ap(
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations)
								]),
							A2(
								$elm$core$List$map,
								expandDeclaration,
								A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets))));
				case 3:
					var _v11 = styles.a;
					var pseudoElement = _v11.a;
					var nestedStyles = _v11.b;
					var rest = styles.b;
					return A4(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector(pseudoElement),
						declarations);
				case 5:
					var str = styles.a.a;
					var rest = styles.b;
					var name = $rtfeldman$elm_css$Hash$fromString(str);
					var newProperty = 'animation-name:' + name;
					var newDeclarations = A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($rtfeldman$elm_css$Css$Structure$appendProperty, newProperty, declarations));
					return A2(
						$elm$core$List$append,
						newDeclarations,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Structure$Keyframes(
								{lM: str, nz: name})
							]));
				case 4:
					var _v12 = styles.a;
					var mediaQueries = _v12.a;
					var nestedStyles = _v12.b;
					var rest = styles.b;
					var extraDeclarations = function () {
						var _v13 = $rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations);
						if (!_v13.b) {
							return _List_Nil;
						} else {
							var firstSelector = _v13.a;
							var otherSelectors = _v13.b;
							return A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule(mediaQueries),
								A2(
									$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
									nestedStyles,
									$elm$core$List$singleton(
										$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
											A3($rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil)))));
						}
					}();
					return _Utils_ap(
						A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations),
						extraDeclarations);
				default:
					var otherStyles = styles.a.a;
					var rest = styles.b;
					return A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						_Utils_ap(otherStyles, rest),
						declarations);
			}
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock = function (_v2) {
	var firstSelector = _v2.a;
	var otherSelectors = _v2.b;
	var styles = _v2.c;
	return A2(
		$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
		styles,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil))
			]));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$extract = function (snippetDeclarations) {
	if (!snippetDeclarations.b) {
		return _List_Nil;
	} else {
		var first = snippetDeclarations.a;
		var rest = snippetDeclarations.b;
		return _Utils_ap(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations(first),
			$rtfeldman$elm_css$Css$Preprocess$Resolve$extract(rest));
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule = F2(
	function (mediaQueries, styleBlocks) {
		var handleStyleBlock = function (styleBlock) {
			return A2(
				$elm$core$List$map,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		};
		return A2($elm$core$List$concatMap, handleStyleBlock, styleBlocks);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule = F2(
	function (str, snippets) {
		var declarations = $rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
			A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
		return _List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$Structure$SupportsRule, str, declarations)
			]);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations = function (snippetDeclaration) {
	switch (snippetDeclaration.$) {
		case 0:
			var styleBlock = snippetDeclaration.a;
			return $rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock);
		case 1:
			var mediaQueries = snippetDeclaration.a;
			var styleBlocks = snippetDeclaration.b;
			return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
		case 2:
			var str = snippetDeclaration.a;
			var snippets = snippetDeclaration.b;
			return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, snippets);
		case 3:
			var str1 = snippetDeclaration.a;
			var str2 = snippetDeclaration.b;
			var str3 = snippetDeclaration.c;
			var str4 = snippetDeclaration.d;
			var styleBlock = snippetDeclaration.e;
			return A2(
				$elm$core$List$map,
				A4($rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		case 4:
			var str = snippetDeclaration.a;
			var properties = snippetDeclaration.b;
			return _List_fromArray(
				[
					A2($rtfeldman$elm_css$Css$Structure$PageRule, str, properties)
				]);
		case 5:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$FontFace(properties)
				]);
		case 6:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$Viewport(properties)
				]);
		case 7:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
				]);
		default:
			var tuples = snippetDeclaration.a;
			return $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure = function (_v0) {
	var charset = _v0.f7;
	var imports = _v0.hD;
	var namespaces = _v0.ib;
	var snippets = _v0.jY;
	var declarations = $rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
		A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
	return {f7: charset, lN: declarations, hD: imports, ib: namespaces};
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$compileHelp = function (sheet) {
	return $rtfeldman$elm_css$Css$Structure$Output$prettyPrint(
		$rtfeldman$elm_css$Css$Structure$compactStylesheet(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure(sheet)));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$compile = function (styles) {
	return A2(
		$elm$core$String$join,
		'\n\n',
		A2($elm$core$List$map, $rtfeldman$elm_css$Css$Preprocess$Resolve$compileHelp, styles));
};
var $rtfeldman$elm_css$Css$Structure$ClassSelector = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$Preprocess$Snippet = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$Preprocess$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$makeSnippet = F2(
	function (styles, sequence) {
		var selector = A3($rtfeldman$elm_css$Css$Structure$Selector, sequence, _List_Nil, $elm$core$Maybe$Nothing);
		return _List_fromArray(
			[
				$rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Preprocess$StyleBlock, selector, _List_Nil, styles))
			]);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$snippetFromPair = function (_v0) {
	var classname = _v0.a;
	var styles = _v0.b;
	return A2(
		$rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
		styles,
		$rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$ClassSelector(classname)
				])));
};
var $rtfeldman$elm_css$Css$Preprocess$stylesheet = function (snippets) {
	return {f7: $elm$core$Maybe$Nothing, hD: _List_Nil, ib: _List_Nil, jY: snippets};
};
var $rtfeldman$elm_css$VirtualDom$Styled$toDeclaration = function (dict) {
	return $rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
		$elm$core$List$singleton(
			$rtfeldman$elm_css$Css$Preprocess$stylesheet(
				A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$VirtualDom$Styled$snippetFromPair,
					$elm$core$Dict$toList(dict)))));
};
var $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode = function (styles) {
	return A3(
		$elm$virtual_dom$VirtualDom$node,
		'style',
		_List_Nil,
		$elm$core$List$singleton(
			$elm$virtual_dom$VirtualDom$text(
				$rtfeldman$elm_css$VirtualDom$Styled$toDeclaration(styles))));
};
var $rtfeldman$elm_css$VirtualDom$Styled$unstyle = F3(
	function (elemType, properties, children) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _v0.a;
		var styles = _v0.b;
		var styleNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(styles);
		return A3(
			$elm$virtual_dom$VirtualDom$node,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				styleNode,
				$elm$core$List$reverse(childNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$containsKey = F2(
	function (key, pairs) {
		containsKey:
		while (true) {
			if (!pairs.b) {
				return false;
			} else {
				var _v1 = pairs.a;
				var str = _v1.a;
				var rest = pairs.b;
				if (_Utils_eq(key, str)) {
					return true;
				} else {
					var $temp$key = key,
						$temp$pairs = rest;
					key = $temp$key;
					pairs = $temp$pairs;
					continue containsKey;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey = F2(
	function (_default, pairs) {
		getUnusedKey:
		while (true) {
			if (!pairs.b) {
				return _default;
			} else {
				var _v1 = pairs.a;
				var firstKey = _v1.a;
				var rest = pairs.b;
				var newKey = '_' + firstKey;
				if (A2($rtfeldman$elm_css$VirtualDom$Styled$containsKey, newKey, rest)) {
					var $temp$default = newKey,
						$temp$pairs = rest;
					_default = $temp$default;
					pairs = $temp$pairs;
					continue getUnusedKey;
				} else {
					return newKey;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode = F2(
	function (allStyles, keyedChildNodes) {
		var styleNodeKey = A2($rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey, '_', keyedChildNodes);
		var finalNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(allStyles);
		return _Utils_Tuple2(styleNodeKey, finalNode);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed = F3(
	function (elemType, properties, keyedChildren) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _v0.a;
		var styles = _v0.b;
		var keyedStyleNode = A2($rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode, styles, keyedChildNodes);
		return A3(
			$elm$virtual_dom$VirtualDom$keyedNode,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				keyedStyleNode,
				$elm$core$List$reverse(keyedChildNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS = F4(
	function (ns, elemType, properties, keyedChildren) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _v0.a;
		var styles = _v0.b;
		var keyedStyleNode = A2($rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode, styles, keyedChildNodes);
		return A4(
			$elm$virtual_dom$VirtualDom$keyedNodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				keyedStyleNode,
				$elm$core$List$reverse(keyedChildNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleNS = F4(
	function (ns, elemType, properties, children) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _v0.a;
		var styles = _v0.b;
		var styleNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(styles);
		return A4(
			$elm$virtual_dom$VirtualDom$nodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				styleNode,
				$elm$core$List$reverse(childNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled = function (vdom) {
	switch (vdom.$) {
		case 4:
			var plainNode = vdom.a;
			return plainNode;
		case 0:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A3($rtfeldman$elm_css$VirtualDom$Styled$unstyle, elemType, properties, children);
		case 1:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A4($rtfeldman$elm_css$VirtualDom$Styled$unstyleNS, ns, elemType, properties, children);
		case 2:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A3($rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed, elemType, properties, children);
		default:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A4($rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS, ns, elemType, properties, children);
	}
};
var $rtfeldman$elm_css$Html$Styled$toUnstyled = $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled;
var $author$project$Routes$Pages$ActiveUserAccount = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$Msg$AlertAction = function (a) {
	return {$: 32, a: a};
};
var $author$project$Main$Msg$AuthAction = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$Msg$AuthInfoFetched = function (a) {
	return {$: 3, a: a};
};
var $author$project$Routes$Pages$CabinetNew = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$Msg$CabinetNewComponentAction = function (a) {
	return {$: 19, a: a};
};
var $author$project$Routes$Pages$CabinetOffresCommerciales = function (a) {
	return {$: 7, a: a};
};
var $author$project$Main$Msg$CabinetOffresComponentAction = function (a) {
	return {$: 21, a: a};
};
var $author$project$Routes$Pages$CabinetUsers = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Main$Msg$CabinetUsersComponentAction = function (a) {
	return {$: 18, a: a};
};
var $author$project$Routes$Pages$Cabinets = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Main$Msg$CabinetsListComponentAction = function (a) {
	return {$: 17, a: a};
};
var $author$project$Routes$Pages$ClientDetail = function (a) {
	return {$: 9, a: a};
};
var $author$project$Routes$Pages$Clients = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Main$Msg$FetchAuthInfo = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$Msg$FetchModulesInfo = {$: 9};
var $author$project$Main$Msg$FetchMustComplianceRootApiLinks = {$: 7};
var $author$project$Routes$Pages$ForgotPassword = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$Msg$ForgotPasswordComponentAction = function (a) {
	return {$: 6, a: a};
};
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$Get = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Routes$Pages$ImportSupportDetail = function (a) {
	return {$: 13, a: a};
};
var $author$project$Main$Msg$ImportSupportDetailAction = function (a) {
	return {$: 27, a: a};
};
var $author$project$Routes$Pages$ImportSupports = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$Main$Msg$LandingComponentAction = function (a) {
	return {$: 30, a: a};
};
var $author$project$Main$Msg$LinkClickedInfo = function (url) {
	return {o$: url};
};
var $author$project$Routes$Pages$ListSupports = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $author$project$Main$Msg$ModulesInfoFetched = function (a) {
	return {$: 10, a: a};
};
var $author$project$Main$Msg$MustComplianceRootApiLinksFetched = function (a) {
	return {$: 8, a: a};
};
var $author$project$Routes$Pages$NotFound = {$: 17};
var $author$project$Main$Msg$PIRootApiLinksFetched = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$Msg$PassUrlToSubModule = function (a) {
	return {$: 16, a: a};
};
var $author$project$Routes$Pages$ProduitDetails = function (a) {
	return {$: 11, a: a};
};
var $author$project$Routes$Pages$Produits = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Main$Msg$SubModuleLoaded = function (a) {
	return {$: 14, a: a};
};
var $author$project$Routes$Pages$SupportDetail = function (a) {
	return {$: 15, a: a};
};
var $author$project$Routes$Pages$UserNew = function (a) {
	return {$: 6, a: a};
};
var $author$project$Routes$Auth$AuthInfo = F5(
	function (uid, login, company, firstName, lastName) {
		return {lx: company, ms: firstName, na: lastName, nh: login, kx: uid};
	});
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Routes$Auth$UidLabel = F2(
	function (uid, label) {
		return {m9: label, kx: uid};
	});
var $author$project$Routes$Auth$uidLabelDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'label',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'uid',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Routes$Auth$UidLabel)));
var $author$project$Routes$Auth$authInfoDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'lastName',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'firstName',
		$elm$json$Json$Decode$string,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'company',
			$elm$json$Json$Decode$nullable($author$project$Routes$Auth$uidLabelDecoder),
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'login',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'uid',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Routes$Auth$AuthInfo))))));
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$Delete = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$Patch = F3(
	function (a, b, c) {
		return {$: 4, a: a, b: b, c: c};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$Post = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$Put = F3(
	function (a, b, c) {
		return {$: 3, a: a, b: b, c: c};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$request = F3(
	function (link, body, exp) {
		var _v0 = link.nq;
		switch (_v0) {
			case 0:
				return A2($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$Get, link.hA, exp);
			case 1:
				return A3($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$Post, link.hA, body, exp);
			case 2:
				return A3($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$Patch, link.hA, body, exp);
			case 3:
				return A2($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$Delete, link.hA, exp);
			default:
				return A3($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$Put, link.hA, body, exp);
		}
	});
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {jq: reqs, j4: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.oS;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.jq));
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.j4)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					kY: r.kY,
					le: r.le,
					mc: A2(_Http_mapExpect, func, r.mc),
					mJ: r.mJ,
					nq: r.nq,
					oN: r.oN,
					oS: r.oS,
					o$: r.o$
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{kY: true, le: r.le, mc: r.mc, mJ: r.mJ, nq: r.nq, oN: r.oN, oS: r.oS, o$: r.o$}));
};
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$executeRequest = F5(
	function (method, headers, url, body, expect) {
		return $elm$http$Http$riskyRequest(
			{le: body, mc: expect, mJ: headers, nq: method, oN: $elm$core$Maybe$Nothing, oS: $elm$core$Maybe$Nothing, o$: url});
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$withAuthReqWithHeaders = F2(
	function (headers, req) {
		switch (req.$) {
			case 0:
				var url = req.a;
				var msg = req.b;
				return A5($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$executeRequest, 'GET', headers, url, $elm$http$Http$emptyBody, msg);
			case 1:
				var url = req.a;
				var msg = req.b;
				return A5($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$executeRequest, 'DELETE', headers, url, $elm$http$Http$emptyBody, msg);
			case 2:
				var url = req.a;
				var body = req.b;
				var msg = req.c;
				return A5($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$executeRequest, 'POST', headers, url, body, msg);
			case 3:
				var url = req.a;
				var body = req.b;
				var msg = req.c;
				return A5($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$executeRequest, 'PUT', headers, url, body, msg);
			default:
				var url = req.a;
				var body = req.b;
				var msg = req.c;
				return A5($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$executeRequest, 'PATCH', headers, url, body, msg);
		}
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$withAuthReq = function (req) {
	return A2($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$withAuthReqWithHeaders, _List_Nil, req);
};
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest = F3(
	function (link, body, exp) {
		return $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$withAuthReq(
			A3($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$request, link, body, exp));
	});
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Routes$Model$CreateItem = {$: 0};
var $author$project$Routes$Pages$SubModule = function (a) {
	return {$: 16, a: a};
};
var $author$project$Routes$Model$UpdateItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$ActiveUser$Model$ActiveUser = 0;
var $author$project$Routes$Pages$Error = function (a) {
	return {$: 18, a: a};
};
var $author$project$Routes$Pages$NotAbleToBuildRouteModel = 0;
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink = F2(
	function (rel, links) {
		return A2(
			$elm_community$list_extra$List$Extra$find,
			function (l) {
				return _Utils_eq(rel, l.jm);
			},
			links);
	});
var $author$project$Components$ActiveUser$Model$Model = F8(
	function (askedAction, actionLink, demandKey, userUid, password, confirmPassword, saveState, processUid) {
		return {kQ: actionLink, k1: askedAction, ly: confirmPassword, lR: demandKey, nW: password, n4: processUid, oq: saveState, o1: userUid};
	});
var $author$project$Components$ActiveUser$Model$NotReady = 0;
var $PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLink$PATCH = 2;
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$Components$ActiveUser$Model$init = F4(
	function (askedAction, piBaseUri, demandKey, userUid) {
		var activeUserLink = _Utils_update(
			piBaseUri,
			{
				hA: A3($elm$core$String$replace, 'v1', 'vEvent', piBaseUri.hA) + ('/users/' + userUid),
				nq: 2
			});
		return A8($author$project$Components$ActiveUser$Model$Model, askedAction, activeUserLink, demandKey, userUid, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, 0, $elm$core$Maybe$Nothing);
	});
var $author$project$Components$ActiveUser$Update$init = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$core$Tuple$mapBoth = F3(
	function (funcA, funcB, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			funcA(x),
			funcB(y));
	});
var $author$project$Routes$ComponentBuilder$activeUserAccount = F2(
	function (_v0, rootApiLinks) {
		var demandKey = _v0.lR;
		var userId = _v0.kB;
		var pibaseUri = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'self', rootApiLinks.n0);
		var init = function (url) {
			return A3(
				$elm$core$Tuple$mapBoth,
				$author$project$Routes$Pages$ActiveUserAccount,
				$elm$core$Platform$Cmd$map($author$project$Main$Msg$ActiveUserComponentAction),
				$author$project$Components$ActiveUser$Update$init(
					A4($author$project$Components$ActiveUser$Model$init, 0, url, demandKey, userId)));
		};
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(
				$author$project$Routes$Pages$Error(0),
				$elm$core$Platform$Cmd$none),
			A2($elm$core$Maybe$map, init, pibaseUri));
	});
var $author$project$Components$Cabinets$Model$HarvestSyncInfosRead = $elm$core$Basics$identity;
var $author$project$Main$NavLinkRef$cabinetRel = {
	lG: function (links) {
		return A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'create', links);
	},
	mh: {
		d5: function (links) {
			return A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'availableSyncUsers', links);
		},
		lF: function (links) {
			return A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'external_sync_infos', links);
		},
		oM: function (links) {
			return A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'external_sync_infos/test', links);
		}
	},
	oY: function (links) {
		return A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'update', links);
	}
};
var $author$project$Components$Cabinets$Model$buildCabinetFromApi = F4(
	function (uid, name, sync, links) {
		var updateLink = $author$project$Main$NavLinkRef$cabinetRel.oY(links);
		return {
			mh: A2(
				$elm$core$Maybe$map,
				function (s) {
					var infos = s;
					return {lu: infos.lu, hB: infos.hB, o2: infos.o2};
				},
				sync),
			nz: name,
			kx: uid,
			kz: updateLink
		};
	});
var $author$project$Components$Cabinets$List$Update$FetchCabinets = {$: 0};
var $author$project$Components$Cabinets$List$Update$init = function (model) {
	return _Utils_Tuple2(
		model,
		$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd($author$project$Components$Cabinets$List$Update$FetchCabinets));
};
var $author$project$Components$Cabinets$List$Model$Model = F2(
	function (apiLinks, cabinets) {
		return {k_: apiLinks, ll: cabinets};
	});
var $author$project$Components$Cabinets$List$Model$APILinks = F4(
	function (fetchCabinets, createCabinet, testExternalSync, availableSyncUsers) {
		return {d5: availableSyncUsers, gk: createCabinet, ml: fetchCabinets, kg: testExternalSync};
	});
var $author$project$Components$Cabinets$List$Model$initAPILinks = function (link) {
	return A4($author$project$Components$Cabinets$List$Model$APILinks, link, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
};
var $author$project$Components$Cabinets$List$Model$initModel = function (link) {
	return A2(
		$author$project$Components$Cabinets$List$Model$Model,
		$author$project$Components$Cabinets$List$Model$initAPILinks(link),
		_List_Nil);
};
var $author$project$Routes$ComponentBuilder$cabinets = F2(
	function (step, rootApiLinks) {
		var init = function (url) {
			return A3(
				$elm$core$Tuple$mapBoth,
				$author$project$Routes$Pages$Cabinets(step),
				$elm$core$Platform$Cmd$map($author$project$Main$Msg$CabinetsListComponentAction),
				$author$project$Components$Cabinets$List$Update$init(
					$author$project$Components$Cabinets$List$Model$initModel(url)));
		};
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2($author$project$Routes$Pages$NotFound, $elm$core$Platform$Cmd$none),
			A2(
				$elm$core$Maybe$map,
				init,
				A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'cabinets', rootApiLinks.kW)));
	});
var $ccapndave$elm_flat_map$Maybe$FlatMap$join = function (mx) {
	if (!mx.$) {
		var x = mx.a;
		return x;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $ccapndave$elm_flat_map$Maybe$FlatMap$flatMap = F2(
	function (f, maybe) {
		return $ccapndave$elm_flat_map$Maybe$FlatMap$join(
			A2($elm$core$Maybe$map, f, maybe));
	});
var $author$project$Components$Cabinets$OffresCommerciales$Action$FetchOffresCommerciales = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Update$init = function (model) {
	var initCmd = model.eJ ? $elm$core$Platform$Cmd$none : $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
		$author$project$Components$Cabinets$OffresCommerciales$Action$FetchOffresCommerciales(model.hr));
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{eJ: true}),
		initCmd);
};
var $author$project$Components$Cabinets$OffresCommerciales$Model$OffresCommerciales = function (docGen) {
	return {l_: docGen};
};
var $author$project$Components$Cabinets$OffresCommerciales$Model$initDocGenOffre = {eb: false, ex: '', ey: '', ez: false, eO: false, bE: $elm$core$Maybe$Nothing, cA: _List_Nil, e1: _List_Nil, n9: _List_Nil, e2: _List_Nil, fo: $elm$core$Maybe$Nothing, fp: $elm$core$Maybe$Nothing};
var $author$project$Components$Cabinets$OffresCommerciales$Model$Activites = 1;
var $author$project$Components$Cabinets$OffresCommerciales$Model$DocGenerator = 0;
var $author$project$Components$Cabinets$OffresCommerciales$Model$offreFromS = function (raw) {
	switch (raw) {
		case 'generation_document':
			return $elm$core$Maybe$Just(0);
		case 'activites':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Cabinets$OffresCommerciales$Model$initModel = F3(
	function (cabinet, getOffresLink, detailOffre) {
		return {
			fZ: cabinet,
			gK: A2($ccapndave$elm_flat_map$Maybe$FlatMap$flatMap, $author$project$Components$Cabinets$OffresCommerciales$Model$offreFromS, detailOffre),
			hr: getOffresLink,
			eJ: false,
			eK: false,
			hL: false,
			eX: $author$project$Components$Cabinets$OffresCommerciales$Model$OffresCommerciales($author$project$Components$Cabinets$OffresCommerciales$Model$initDocGenOffre)
		};
	});
var $author$project$Main$NavLinkRef$findBaseRel = function (cab) {
	return A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'offres_commerciales', cab.nf);
};
var $author$project$Main$NavLinkRef$findUpdateDocGenRel = function (offres) {
	return A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'updateDocgen', offres.l_.nf);
};
var $author$project$Main$NavLinkRef$offresCommercialesRel = {
	ld: $author$project$Main$NavLinkRef$findBaseRel,
	l$: {oW: $author$project$Main$NavLinkRef$findUpdateDocGenRel}
};
var $author$project$Routes$ComponentBuilder$cabinetOffres = F3(
	function (_v0, rootApiLinks, previousPage) {
		var cabinetId = _v0.lj;
		var offre = _v0.eW;
		var getOffreNavLink = function (cab) {
			return $author$project$Main$NavLinkRef$offresCommercialesRel.ld(cab);
		};
		var cabinet = function (model) {
			return A2(
				$elm_community$list_extra$List$Extra$find,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.kx;
					},
					$elm$core$Basics$eq(cabinetId)),
				model.ll);
		};
		var offresModel = function (model) {
			return A2(
				$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
				function (c) {
					return A2(
						$elm$core$Maybe$map,
						function (link) {
							return A3(
								$author$project$Components$Cabinets$OffresCommerciales$Model$initModel,
								A4($author$project$Components$Cabinets$Model$buildCabinetFromApi, c.kx, c.nz, c.mh, c.nf),
								link,
								offre);
						},
						getOffreNavLink(c));
				},
				cabinet(model));
		};
		var goToOffres = function (model) {
			return A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2(
					$author$project$Routes$Pages$Error(0),
					$elm$core$Platform$Cmd$none),
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Tuple$mapBoth,
						$author$project$Routes$Pages$CabinetOffresCommerciales,
						$elm$core$Platform$Cmd$map($author$project$Main$Msg$CabinetOffresComponentAction)),
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$Cabinets$OffresCommerciales$Update$init,
						offresModel(model))));
		};
		var callback = function (page) {
			if (page.$ === 3) {
				var subModel = page.b;
				return A2(
					$elm$core$Maybe$map,
					function (_v3) {
						return $elm$core$Result$Ok(
							goToOffres(subModel));
					},
					offresModel(subModel));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		};
		switch (previousPage.$) {
			case 7:
				var model = previousPage.a;
				return model.hL ? A2(
					$author$project$Routes$ComponentBuilder$cabinets,
					$elm$core$Maybe$Just(callback),
					rootApiLinks) : A3(
					$elm$core$Tuple$mapBoth,
					$author$project$Routes$Pages$CabinetOffresCommerciales,
					$elm$core$Platform$Cmd$map($author$project$Main$Msg$CabinetOffresComponentAction),
					$author$project$Components$Cabinets$OffresCommerciales$Update$init(
						A3($author$project$Components$Cabinets$OffresCommerciales$Model$initModel, model.fZ, model.hr, offre)));
			case 3:
				var model = previousPage.b;
				return goToOffres(model);
			default:
				return A2(
					$author$project$Routes$ComponentBuilder$cabinets,
					$elm$core$Maybe$Just(callback),
					rootApiLinks);
		}
	});
var $author$project$Routes$ComponentNavigation$CabinetItem = function (uid) {
	return {kx: uid};
};
var $author$project$Routes$ComponentNavigation$GoToCabinetSubEntry = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Routes$ComponentNavigation$PushUrl = function (a) {
	return {$: 0, a: a};
};
var $author$project$Routes$ComponentNavigation$ReplaceUrl = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routes$Model$Infos = {$: 0};
var $author$project$Routes$Model$OffresCommerciales = function (a) {
	return {$: 2, a: a};
};
var $author$project$Routes$Model$Users = {$: 1};
var $author$project$Routes$ComponentBuilder$cabSubEntryFromS = function (raw) {
	switch (raw) {
		case 'informations':
			return $elm$core$Maybe$Just($author$project$Routes$Model$Infos);
		case 'utilisateurs':
			return $elm$core$Maybe$Just($author$project$Routes$Model$Users);
		case 'offres':
			return $elm$core$Maybe$Just(
				$author$project$Routes$Model$OffresCommerciales($elm$core$Maybe$Nothing));
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Cabinets$New$Update$init = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$Components$Cabinets$Users$List$Update$FetchUsers = {$: 0};
var $author$project$Components$Cabinets$Users$List$Update$init = function (model) {
	return _Utils_Tuple2(
		model,
		$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd($author$project$Components$Cabinets$Users$List$Update$FetchUsers));
};
var $author$project$Components$Cabinets$New$Model$CreateCabinet = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Cabinets$New$Model$NotSaved = {$: 0};
var $author$project$Components$Cabinets$New$Model$UpdateCabinet = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$New$ExternalSync$Model$HarvestChoiceSyncModel = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$New$ExternalSync$Model$NoSync = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Cabinets$New$ExternalSync$Model$Saved = {$: 6};
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $author$project$Components$Cabinets$New$Model$initExtSyncModel = F2(
	function (cabinet, links) {
		var testSyncLink = $author$project$Main$NavLinkRef$cabinetRel.mh.oM(links);
		var createSyncLink = $author$project$Main$NavLinkRef$cabinetRel.mh.lF(links);
		var buildModel = F2(
			function (testLink, createLink) {
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$Components$Cabinets$New$ExternalSync$Model$NoSync(
						{cc: createLink, cF: testLink}),
					A2(
						$elm$core$Maybe$map,
						function (i) {
							var hInfo = i;
							return $author$project$Components$Cabinets$New$ExternalSync$Model$HarvestChoiceSyncModel(
								{lu: hInfo.lu, lv: '', cc: createLink, hB: hInfo.hB, nW: '', bQ: $author$project$Components$Cabinets$New$ExternalSync$Model$Saved, cF: testLink, o2: hInfo.o2});
						},
						cabinet.mh));
			});
		return A3($elm$core$Maybe$map2, buildModel, testSyncLink, createSyncLink);
	});
var $author$project$Components$Cabinets$New$Model$initModel = F2(
	function (cabinet, links) {
		if (cabinet.$ === 1) {
			return $author$project$Components$Cabinets$New$Model$CreateCabinet(
				{
					aw: '',
					gl: $author$project$Main$NavLinkRef$cabinetRel.lG(links),
					am: $elm$core$Maybe$Nothing,
					by: $author$project$Components$Cabinets$New$Model$NotSaved,
					ab: false
				});
		} else {
			var cab = cabinet.a;
			return $author$project$Components$Cabinets$New$Model$UpdateCabinet(
				{
					lj: cab.kx,
					aw: cab.nz,
					am: A2($author$project$Components$Cabinets$New$Model$initExtSyncModel, cab, links),
					by: $author$project$Components$Cabinets$New$Model$NotSaved,
					ab: false,
					kz: cab.kz
				});
		}
	});
var $author$project$Components$Cabinets$APIModel$Activated = 0;
var $author$project$Components$Cabinets$APIModel$Deactivated = 2;
var $author$project$Components$Cabinets$APIModel$Pending = 1;
var $inkuzmin$elm_multiselect$Multiselect$Show = 0;
var $inkuzmin$elm_multiselect$Multiselect$Closed = 0;
var $inkuzmin$elm_multiselect$Multiselect$Model = $elm$core$Basics$identity;
var $inkuzmin$elm_multiselect$Multiselect$initModel = F3(
	function (values, tag1, inputInMenu) {
		return {
			es: $elm$core$Maybe$Nothing,
			s: values,
			t: $elm$core$List$head(values),
			a1: $elm$core$Maybe$Just(''),
			eL: inputInMenu,
			eM: 23.0,
			aq: false,
			o: _List_Nil,
			ox: 0,
			fh: tag1,
			o7: values
		};
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected = function (_v0) {
	var selected = _v0.o;
	var values = _v0.o7;
	return A2(
		$elm$core$List$filter,
		function (value) {
			return !A2($elm$core$List$member, value, selected);
		},
		values);
};
var $inkuzmin$elm_multiselect$Multiselect$populateValues = F3(
	function (_v0, values, selected) {
		var model = _v0;
		var filtered = $elm$core$List$isEmpty(selected) ? values : $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
			{o: selected, o7: values});
		return _Utils_update(
			model,
			{s: filtered, o: selected, o7: values});
	});
var $author$project$Components$Cabinets$Users$List$Model$statusToS = function (status) {
	switch (status) {
		case 0:
			return 'Activé';
		case 1:
			return 'En attente d\'activation';
		default:
			return 'Désactivé';
	}
};
var $author$project$Components$Cabinets$Users$List$Model$initFilters = function () {
	var buildStatusValues = function (xs) {
		return A2(
			$elm$core$List$map,
			function (s) {
				return _Utils_Tuple2(
					$author$project$Components$Cabinets$Users$List$Model$statusToS(s),
					$author$project$Components$Cabinets$Users$List$Model$statusToS(s));
			},
			xs);
	};
	return {
		b5: A3(
			$inkuzmin$elm_multiselect$Multiselect$populateValues,
			A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'byActivationStatFilter', 0),
			buildStatusValues(
				_List_fromArray(
					[0, 1, 2])),
			buildStatusValues(
				_List_fromArray(
					[0, 1]))),
		cS: ''
	};
}();
var $author$project$Components$Cabinets$Users$List$Model$initModel = F3(
	function (cabinet, cabinetLinks, availableSyncUsers) {
		var updateCabinetLink = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'update', cabinetLinks);
		var fetchUsersLink = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'users', cabinetLinks);
		return {d5: availableSyncUsers, fZ: cabinet, eh: $elm$core$Maybe$Nothing, g3: fetchUsersLink, eF: _List_Nil, az: $author$project$Components$Cabinets$Users$List$Model$initFilters, kz: updateCabinetLink, o3: _List_Nil};
	});
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Routes$ComponentNavigation$pushUrl = F2(
	function (appNavKey, path) {
		return A2($elm$browser$Browser$Navigation$pushUrl, appNavKey, path);
	});
var $author$project$Routes$Model$GetUserRecord = F2(
	function (cabinetId, userId) {
		return {lj: cabinetId, kB: userId};
	});
var $author$project$Routes$Model$OffreRecord = F2(
	function (cabinetId, offre) {
		return {lj: cabinetId, eW: offre};
	});
var $author$project$Routes$Model$PasswordRecord = F2(
	function (demandKey, userId) {
		return {lR: demandKey, kB: userId};
	});
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {a_: frag, a5: params, aP: unvisited, o6: value, bc: visited};
	});
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.bc;
		var unvisited = _v0.aP;
		var params = _v0.a5;
		var frag = _v0.a_;
		var value = _v0.o6;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.bc;
			var unvisited = _v1.aP;
			var params = _v1.a5;
			var frag = _v1.a_;
			var value = _v1.o6;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $miyamoen$elm_route_builder$RouteBuilder$dynamic = F2(
	function (toParams, b) {
		var toPaths = function (r) {
			return $elm$core$List$reverse(
				A2(
					$elm$core$List$map,
					function (getter) {
						return getter(r);
					},
					b.X));
		};
		return {
			q: function (toPage) {
				return A2(
					$elm$url$Url$Parser$map,
					toPage,
					A2($elm$url$Url$Parser$map, toParams, b.aK));
			},
			X: toPaths,
			dX: function (r) {
				return A2(
					$elm$url$Url$Builder$absolute,
					toPaths(r),
					_List_Nil);
			}
		};
	});
var $author$project$Routes$Model$paths = {fz: 'activites', cN: 'admin', ll: 'cabinets', ca: 'clients', hl: 'generation_document', mQ: 'imports', mZ: 'informations', cr: 'new', ct: 'offres_commerciales', n7: 'produits', fe: 'supports', o3: 'utilisateurs'};
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $miyamoen$elm_route_builder$RouteBuilder$root = {aK: $elm$url$Url$Parser$top, X: _List_Nil};
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.bc;
		var unvisited = _v0.aP;
		var params = _v0.a5;
		var frag = _v0.a_;
		var value = _v0.o6;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $miyamoen$elm_route_builder$RouteBuilder$s = F2(
	function (str, b) {
		return {
			aK: A2(
				$elm$url$Url$Parser$slash,
				b.aK,
				$elm$url$Url$Parser$s(str)),
			X: A2(
				$elm$core$List$cons,
				$elm$core$Basics$always(str),
				b.X)
		};
	});
var $miyamoen$elm_route_builder$RouteBuilder$static = function (b) {
	var toPaths = function (_v1) {
		return $elm$core$List$reverse(
			A2(
				$elm$core$List$map,
				function (getter) {
					return getter(0);
				},
				b.X));
	};
	return {
		q: function (toPage) {
			return A2(
				$elm$url$Url$Parser$map,
				toPage,
				A2($elm$url$Url$Parser$map, 0, b.aK));
		},
		X: toPaths,
		dX: function (_v0) {
			return A2(
				$elm$url$Url$Builder$absolute,
				toPaths(0),
				_List_Nil);
		}
	};
};
var $miyamoen$elm_route_builder$RouteBuilder$customWithParser = F4(
	function (customParser, encoder, getter, b) {
		return {
			aK: A2($elm$url$Url$Parser$slash, b.aK, customParser),
			X: A2(
				$elm$core$List$cons,
				A2($elm$core$Basics$composeR, getter, encoder),
				b.X)
		};
	});
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.bc;
			var unvisited = _v0.aP;
			var params = _v0.a5;
			var frag = _v0.a_;
			var value = _v0.o6;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $miyamoen$elm_route_builder$RouteBuilder$string = A2($miyamoen$elm_route_builder$RouteBuilder$customWithParser, $elm$url$Url$Parser$string, $elm$core$Basics$identity);
var $author$project$Routes$Model$routes = {
	fy: A2(
		$miyamoen$elm_route_builder$RouteBuilder$dynamic,
		$author$project$Routes$Model$PasswordRecord,
		A2(
			$miyamoen$elm_route_builder$RouteBuilder$string,
			function ($) {
				return $.kB;
			},
			A2(
				$miyamoen$elm_route_builder$RouteBuilder$string,
				function ($) {
					return $.lR;
				},
				A2($miyamoen$elm_route_builder$RouteBuilder$s, 'activeuseraccount', $miyamoen$elm_route_builder$RouteBuilder$root)))),
	cN: $miyamoen$elm_route_builder$RouteBuilder$static(
		A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.cN, $miyamoen$elm_route_builder$RouteBuilder$root)),
	ll: {
		lF: $miyamoen$elm_route_builder$RouteBuilder$static(
			A2(
				$miyamoen$elm_route_builder$RouteBuilder$s,
				$author$project$Routes$Model$paths.cr,
				A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.ll, $miyamoen$elm_route_builder$RouteBuilder$root))),
		mZ: A2(
			$miyamoen$elm_route_builder$RouteBuilder$dynamic,
			$elm$core$Basics$identity,
			A2(
				$miyamoen$elm_route_builder$RouteBuilder$s,
				$author$project$Routes$Model$paths.mZ,
				A2(
					$miyamoen$elm_route_builder$RouteBuilder$string,
					$elm$core$Basics$identity,
					A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.ll, $miyamoen$elm_route_builder$RouteBuilder$root)))),
		hY: $miyamoen$elm_route_builder$RouteBuilder$static(
			A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.ll, $miyamoen$elm_route_builder$RouteBuilder$root)),
		nM: {
			mG: A2(
				$miyamoen$elm_route_builder$RouteBuilder$dynamic,
				$author$project$Routes$Model$OffreRecord,
				A2(
					$miyamoen$elm_route_builder$RouteBuilder$string,
					function ($) {
						return $.eW;
					},
					A2(
						$miyamoen$elm_route_builder$RouteBuilder$s,
						$author$project$Routes$Model$paths.ct,
						A2(
							$miyamoen$elm_route_builder$RouteBuilder$string,
							function ($) {
								return $.lj;
							},
							A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.ll, $miyamoen$elm_route_builder$RouteBuilder$root))))),
			hs: A2(
				$miyamoen$elm_route_builder$RouteBuilder$dynamic,
				$elm$core$Basics$identity,
				A2(
					$miyamoen$elm_route_builder$RouteBuilder$s,
					$author$project$Routes$Model$paths.fz,
					A2(
						$miyamoen$elm_route_builder$RouteBuilder$s,
						$author$project$Routes$Model$paths.ct,
						A2(
							$miyamoen$elm_route_builder$RouteBuilder$string,
							$elm$core$Basics$identity,
							A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.ll, $miyamoen$elm_route_builder$RouteBuilder$root))))),
			ht: A2(
				$miyamoen$elm_route_builder$RouteBuilder$dynamic,
				$elm$core$Basics$identity,
				A2(
					$miyamoen$elm_route_builder$RouteBuilder$s,
					$author$project$Routes$Model$paths.hl,
					A2(
						$miyamoen$elm_route_builder$RouteBuilder$s,
						$author$project$Routes$Model$paths.ct,
						A2(
							$miyamoen$elm_route_builder$RouteBuilder$string,
							$elm$core$Basics$identity,
							A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.ll, $miyamoen$elm_route_builder$RouteBuilder$root))))),
			hY: A2(
				$miyamoen$elm_route_builder$RouteBuilder$dynamic,
				$elm$core$Basics$identity,
				A2(
					$miyamoen$elm_route_builder$RouteBuilder$s,
					$author$project$Routes$Model$paths.ct,
					A2(
						$miyamoen$elm_route_builder$RouteBuilder$string,
						$elm$core$Basics$identity,
						A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.ll, $miyamoen$elm_route_builder$RouteBuilder$root))))
		},
		oB: A2(
			$miyamoen$elm_route_builder$RouteBuilder$dynamic,
			$elm$core$Basics$identity,
			A2(
				$miyamoen$elm_route_builder$RouteBuilder$string,
				$elm$core$Basics$identity,
				A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.ll, $miyamoen$elm_route_builder$RouteBuilder$root))),
		oV: A2(
			$miyamoen$elm_route_builder$RouteBuilder$dynamic,
			$elm$core$Basics$identity,
			A2(
				$miyamoen$elm_route_builder$RouteBuilder$s,
				$author$project$Routes$Model$paths.mZ,
				A2(
					$miyamoen$elm_route_builder$RouteBuilder$string,
					$elm$core$Basics$identity,
					A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.ll, $miyamoen$elm_route_builder$RouteBuilder$root)))),
		o3: {
			lF: A2(
				$miyamoen$elm_route_builder$RouteBuilder$dynamic,
				$elm$core$Basics$identity,
				A2(
					$miyamoen$elm_route_builder$RouteBuilder$s,
					$author$project$Routes$Model$paths.cr,
					A2(
						$miyamoen$elm_route_builder$RouteBuilder$s,
						$author$project$Routes$Model$paths.o3,
						A2(
							$miyamoen$elm_route_builder$RouteBuilder$string,
							$elm$core$Basics$identity,
							A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.ll, $miyamoen$elm_route_builder$RouteBuilder$root))))),
			mG: A2(
				$miyamoen$elm_route_builder$RouteBuilder$dynamic,
				$author$project$Routes$Model$GetUserRecord,
				A2(
					$miyamoen$elm_route_builder$RouteBuilder$string,
					function ($) {
						return $.kB;
					},
					A2(
						$miyamoen$elm_route_builder$RouteBuilder$s,
						$author$project$Routes$Model$paths.o3,
						A2(
							$miyamoen$elm_route_builder$RouteBuilder$string,
							function ($) {
								return $.lj;
							},
							A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.ll, $miyamoen$elm_route_builder$RouteBuilder$root))))),
			hY: A2(
				$miyamoen$elm_route_builder$RouteBuilder$dynamic,
				$elm$core$Basics$identity,
				A2(
					$miyamoen$elm_route_builder$RouteBuilder$s,
					$author$project$Routes$Model$paths.o3,
					A2(
						$miyamoen$elm_route_builder$RouteBuilder$string,
						$elm$core$Basics$identity,
						A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.ll, $miyamoen$elm_route_builder$RouteBuilder$root))))
		}
	},
	ca: {
		lF: $miyamoen$elm_route_builder$RouteBuilder$static(
			A2(
				$miyamoen$elm_route_builder$RouteBuilder$s,
				$author$project$Routes$Model$paths.cr,
				A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.ca, $miyamoen$elm_route_builder$RouteBuilder$root))),
		mG: A2(
			$miyamoen$elm_route_builder$RouteBuilder$dynamic,
			$elm$core$Basics$identity,
			A2(
				$miyamoen$elm_route_builder$RouteBuilder$string,
				$elm$core$Basics$identity,
				A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.ca, $miyamoen$elm_route_builder$RouteBuilder$root))),
		hY: $miyamoen$elm_route_builder$RouteBuilder$static(
			A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.ca, $miyamoen$elm_route_builder$RouteBuilder$root))
	},
	hb: $miyamoen$elm_route_builder$RouteBuilder$static(
		A2($miyamoen$elm_route_builder$RouteBuilder$s, 'forgotpassword', $miyamoen$elm_route_builder$RouteBuilder$root)),
	n7: {
		lF: $miyamoen$elm_route_builder$RouteBuilder$static(
			A2(
				$miyamoen$elm_route_builder$RouteBuilder$s,
				$author$project$Routes$Model$paths.cr,
				A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.n7, $miyamoen$elm_route_builder$RouteBuilder$root))),
		mG: A2(
			$miyamoen$elm_route_builder$RouteBuilder$dynamic,
			$elm$core$Basics$identity,
			A2(
				$miyamoen$elm_route_builder$RouteBuilder$string,
				$elm$core$Basics$identity,
				A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.n7, $miyamoen$elm_route_builder$RouteBuilder$root))),
		hY: $miyamoen$elm_route_builder$RouteBuilder$static(
			A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.n7, $miyamoen$elm_route_builder$RouteBuilder$root))
	},
	jt: A2(
		$miyamoen$elm_route_builder$RouteBuilder$dynamic,
		$author$project$Routes$Model$PasswordRecord,
		A2(
			$miyamoen$elm_route_builder$RouteBuilder$string,
			function ($) {
				return $.kB;
			},
			A2(
				$miyamoen$elm_route_builder$RouteBuilder$string,
				function ($) {
					return $.lR;
				},
				A2($miyamoen$elm_route_builder$RouteBuilder$s, 'resetpassword', $miyamoen$elm_route_builder$RouteBuilder$root)))),
	op: $miyamoen$elm_route_builder$RouteBuilder$static($miyamoen$elm_route_builder$RouteBuilder$root),
	fe: {
		lT: A2(
			$miyamoen$elm_route_builder$RouteBuilder$dynamic,
			$elm$core$Basics$identity,
			A2(
				$miyamoen$elm_route_builder$RouteBuilder$string,
				$elm$core$Basics$identity,
				A2(
					$miyamoen$elm_route_builder$RouteBuilder$s,
					$author$project$Routes$Model$paths.mQ,
					A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.fe, $miyamoen$elm_route_builder$RouteBuilder$root)))),
		mG: A2(
			$miyamoen$elm_route_builder$RouteBuilder$dynamic,
			$elm$core$Basics$identity,
			A2(
				$miyamoen$elm_route_builder$RouteBuilder$string,
				$elm$core$Basics$identity,
				A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.fe, $miyamoen$elm_route_builder$RouteBuilder$root))),
		mQ: $miyamoen$elm_route_builder$RouteBuilder$static(
			A2(
				$miyamoen$elm_route_builder$RouteBuilder$s,
				$author$project$Routes$Model$paths.mQ,
				A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.fe, $miyamoen$elm_route_builder$RouteBuilder$root))),
		hY: $miyamoen$elm_route_builder$RouteBuilder$static(
			A2($miyamoen$elm_route_builder$RouteBuilder$s, $author$project$Routes$Model$paths.fe, $miyamoen$elm_route_builder$RouteBuilder$root))
	}
};
var $author$project$Routes$ComponentNavigation$redirectToPath = function (comp) {
	switch (comp.$) {
		case 0:
			var state = comp.a;
			if (!state.$) {
				var cabinet = state.a;
				return $author$project$Routes$Model$routes.ll.oV.dX(cabinet.kx);
			} else {
				var upsertMode = state.a;
				if (!upsertMode.$) {
					return $author$project$Routes$Model$routes.ll.lF.dX(0);
				} else {
					var cabUid = upsertMode.a;
					return $author$project$Routes$Model$routes.ll.oV.dX(cabUid);
				}
			}
		case 1:
			var state = comp.a;
			if (!state.$) {
				var user = state.a;
				return $author$project$Routes$Model$routes.ll.o3.mG.dX(
					{lj: user.f_, kB: user.kx});
			} else {
				var cab = state.a;
				return $author$project$Routes$Model$routes.ll.o3.hY.dX(cab.kx);
			}
		case 2:
			return $author$project$Routes$Model$routes.ca.hY.dX(0);
		case 3:
			var state = comp.a;
			if (!state.$) {
				var uid = state.a;
				return $author$project$Routes$Model$routes.ca.mG.dX(uid);
			} else {
				return $author$project$Routes$Model$routes.ca.hY.dX(0);
			}
		case 4:
			var state = comp.a;
			if (!state.$) {
				var produitId = state.a;
				return $author$project$Routes$Model$routes.n7.mG.dX(produitId);
			} else {
				return $author$project$Routes$Model$routes.n7.hY.dX(0);
			}
		case 5:
			var state = comp.a;
			if (!state.$) {
				var produitId = state.a;
				return $author$project$Routes$Model$routes.fe.mG.dX(produitId);
			} else {
				return $author$project$Routes$Model$routes.fe.hY.dX(0);
			}
		default:
			var state = comp.a;
			if (!state.$) {
				if (!state.a.b) {
					var _v8 = state.a;
					var cabinetId = _v8.a;
					var _v9 = _v8.b;
					return $author$project$Routes$Model$routes.ll.nM.ht.dX(cabinetId.kx);
				} else {
					var _v10 = state.a;
					var cabinetId = _v10.a;
					var _v11 = _v10.b;
					return $author$project$Routes$Model$routes.ll.nM.hs.dX(cabinetId.kx);
				}
			} else {
				if (!state.a.b) {
					var _v12 = state.a;
					var cabinetId = _v12.a;
					var _v13 = _v12.b;
					return $author$project$Routes$Model$routes.ll.nM.ht.dX(cabinetId.kx);
				} else {
					var _v14 = state.a;
					var cabinetId = _v14.a;
					var _v15 = _v14.b;
					return $author$project$Routes$Model$routes.ll.nM.hs.dX(cabinetId.kx);
				}
			}
	}
};
var $author$project$Routes$ComponentNavigation$redirectToSubEntry = F2(
	function (cabinetId, subEntry) {
		switch (subEntry.$) {
			case 0:
				return $author$project$Routes$Model$routes.ll.mZ.dX(cabinetId);
			case 1:
				return $author$project$Routes$Model$routes.ll.o3.hY.dX(cabinetId);
			default:
				if (subEntry.a.$ === 1) {
					var _v1 = subEntry.a;
					return $author$project$Routes$Model$routes.ll.nM.hY.dX(cabinetId);
				} else {
					var offre = subEntry.a.a;
					return $author$project$Routes$Model$routes.ll.nM.mG.dX(
						{lj: cabinetId, eW: offre});
				}
		}
	});
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$Routes$ComponentNavigation$navigateToComponent = F2(
	function (navStyle, nav) {
		var path = function () {
			if (!nav.$) {
				var comp = nav.a;
				return $author$project$Routes$ComponentNavigation$redirectToPath(comp);
			} else {
				var cabItem = nav.a;
				var subEntry = nav.b;
				return A2($author$project$Routes$ComponentNavigation$redirectToSubEntry, cabItem.kx, subEntry);
			}
		}();
		if (!navStyle.$) {
			var key = navStyle.a;
			return A2($author$project$Routes$ComponentNavigation$pushUrl, key, path);
		} else {
			var key = navStyle.a;
			return A2($elm$browser$Browser$Navigation$replaceUrl, key, path);
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$toList = function (m) {
	if (m.$ === 1) {
		return _List_Nil;
	} else {
		var x = m.a;
		return _List_fromArray(
			[x]);
	}
};
var $author$project$Routes$ComponentBuilder$cabinetSubEntry = F3(
	function (appNavKey, rawSubEntry, rootApiLinks) {
		var testSyncLink = function (listModel) {
			return $elm_community$maybe_extra$Maybe$Extra$toList(listModel.k_.kg);
		};
		var replaceUrl = F2(
			function (subEntry, cabUid) {
				return A2(
					$author$project$Routes$ComponentNavigation$navigateToComponent,
					$author$project$Routes$ComponentNavigation$ReplaceUrl(appNavKey),
					A2(
						$author$project$Routes$ComponentNavigation$GoToCabinetSubEntry,
						$author$project$Routes$ComponentNavigation$CabinetItem(cabUid),
						subEntry));
			});
		var changeUrl = F2(
			function (subEntry, cabUid) {
				return A2(
					$author$project$Routes$ComponentNavigation$navigateToComponent,
					$author$project$Routes$ComponentNavigation$PushUrl(appNavKey),
					A2(
						$author$project$Routes$ComponentNavigation$GoToCabinetSubEntry,
						$author$project$Routes$ComponentNavigation$CabinetItem(cabUid),
						subEntry));
			});
		var cabinet = function (cab) {
			return A4($author$project$Components$Cabinets$Model$buildCabinetFromApi, cab.kx, cab.nz, cab.mh, cab.nf);
		};
		var subEntryRedirect = F3(
			function (subEntry, listModel, cab) {
				switch (subEntry.$) {
					case 0:
						return A2(
							$elm$core$Tuple$mapSecond,
							function (initCmd) {
								return $elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											initCmd,
											A2(changeUrl, subEntry, cab.kx)
										]));
							},
							A3(
								$elm$core$Tuple$mapBoth,
								$author$project$Routes$Pages$CabinetNew,
								$elm$core$Platform$Cmd$map($author$project$Main$Msg$CabinetNewComponentAction),
								$author$project$Components$Cabinets$New$Update$init(
									A2(
										$author$project$Components$Cabinets$New$Model$initModel,
										$elm$core$Maybe$Just(
											cabinet(cab)),
										_Utils_ap(
											cab.nf,
											testSyncLink(listModel))))));
					case 1:
						return A2(
							$elm$core$Tuple$mapSecond,
							function (initCmd) {
								return $elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											initCmd,
											A2(changeUrl, subEntry, cab.kx)
										]));
							},
							A3(
								$elm$core$Tuple$mapBoth,
								$author$project$Routes$Pages$CabinetUsers($elm$core$Maybe$Nothing),
								$elm$core$Platform$Cmd$map($author$project$Main$Msg$CabinetUsersComponentAction),
								$author$project$Components$Cabinets$Users$List$Update$init(
									A3(
										$author$project$Components$Cabinets$Users$List$Model$initModel,
										cabinet(cab),
										cab.nf,
										listModel.k_.d5))));
					default:
						var offre = subEntry.a;
						return A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								$author$project$Routes$Pages$Error(0),
								$elm$core$Platform$Cmd$none),
							A2(
								$elm$core$Maybe$map,
								function (getOffreLink) {
									return A2(
										$elm$core$Tuple$mapSecond,
										function (initCmd) {
											return $elm$core$Platform$Cmd$batch(
												_List_fromArray(
													[
														initCmd,
														A2(replaceUrl, subEntry, cab.kx)
													]));
										},
										A3(
											$elm$core$Tuple$mapBoth,
											$author$project$Routes$Pages$CabinetOffresCommerciales,
											$elm$core$Platform$Cmd$map($author$project$Main$Msg$CabinetOffresComponentAction),
											$author$project$Components$Cabinets$OffresCommerciales$Update$init(
												A3(
													$author$project$Components$Cabinets$OffresCommerciales$Model$initModel,
													cabinet(cab),
													getOffreLink,
													offre))));
								},
								$author$project$Main$NavLinkRef$offresCommercialesRel.ld(cab)));
				}
			});
		var goTo = function (subEntry) {
			return function (page) {
				if (page.$ === 3) {
					var subModel = page.b;
					return A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							A2(subEntryRedirect, subEntry, subModel),
							$elm$core$Result$Ok),
						$elm$core$List$head(subModel.ll));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			};
		};
		var _v0 = $author$project$Routes$ComponentBuilder$cabSubEntryFromS(rawSubEntry);
		if (!_v0.$) {
			var subEntry = _v0.a;
			return A2(
				$author$project$Routes$ComponentBuilder$cabinets,
				$elm$core$Maybe$Just(
					goTo(subEntry)),
				rootApiLinks);
		} else {
			return _Utils_Tuple2(
				$author$project$Routes$Pages$Error(0),
				$elm$core$Platform$Cmd$none);
		}
	});
var $ccapndave$elm_flat_map$List$FlatMap$join = A2($elm$core$List$foldr, $elm$core$Basics$append, _List_Nil);
var $ccapndave$elm_flat_map$List$FlatMap$flatMap = F2(
	function (f, list) {
		return $ccapndave$elm_flat_map$List$FlatMap$join(
			A2($elm$core$List$map, f, list));
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten = $ccapndave$elm_flat_map$List$FlatMap$flatMap($elm$core$Basics$identity);
var $author$project$Components$Cabinets$New$Model$isModified = function (model) {
	if (!model.$) {
		var m = model.a;
		return m.ab;
	} else {
		var m = model.a;
		return m.ab;
	}
};
var $author$project$Routes$ComponentBuilder$cabinetUpsert = F3(
	function (maybeCabUid, rootApiLinks, previousPage) {
		var apiCabinet = function (model) {
			return A2(
				$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
				function (cabUid) {
					return A2(
						$elm_community$list_extra$List$Extra$find,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.kx;
							},
							$elm$core$Basics$eq(cabUid)),
						model.ll);
				},
				maybeCabUid);
		};
		var cabinet = function (model) {
			return A2(
				$elm$core$Maybe$map,
				function (cab) {
					return A4($author$project$Components$Cabinets$Model$buildCabinetFromApi, cab.kx, cab.nz, cab.mh, cab.nf);
				},
				apiCabinet(model));
		};
		var links = function (model) {
			return _Utils_ap(
				$elm_community$maybe_extra$Maybe$Extra$toList(model.k_.gk),
				_Utils_ap(
					$elm_community$maybe_extra$Maybe$Extra$toList(model.k_.kg),
					$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten(
						$elm_community$maybe_extra$Maybe$Extra$toList(
							A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.nf;
								},
								apiCabinet(model))))));
		};
		var newModel = F2(
			function (createLink, model) {
				return A2(
					$author$project$Components$Cabinets$New$Model$initModel,
					cabinet(model),
					_Utils_ap(
						links(model),
						$elm_community$maybe_extra$Maybe$Extra$toList(createLink)));
			});
		var init = function (model) {
			return A3(
				$elm$core$Tuple$mapBoth,
				$author$project$Routes$Pages$CabinetNew,
				$elm$core$Platform$Cmd$map($author$project$Main$Msg$CabinetNewComponentAction),
				$author$project$Components$Cabinets$New$Update$init(
					A2(newModel, model.k_.gk, model)));
		};
		var goTo = function (page) {
			if (page.$ === 3) {
				var model = page.b;
				return A2(
					$elm$core$Maybe$map,
					function (_v2) {
						return $elm$core$Result$Ok(
							init(model));
					},
					apiCabinet(model));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		};
		switch (previousPage.$) {
			case 4:
				var model = previousPage.a;
				return $author$project$Components$Cabinets$New$Model$isModified(model) ? A2(
					$author$project$Routes$ComponentBuilder$cabinets,
					$elm$core$Maybe$Just(goTo),
					rootApiLinks) : _Utils_Tuple2(
					$author$project$Routes$Pages$CabinetNew(model),
					$elm$core$Platform$Cmd$none);
			case 3:
				var model = previousPage.b;
				return init(model);
			default:
				return A2(
					$author$project$Routes$ComponentBuilder$cabinets,
					$elm$core$Maybe$Just(goTo),
					rootApiLinks);
		}
	});
var $author$project$Routes$ComponentBuilder$cabinetUsers = F4(
	function (step, cabUid, rootApiLinks, previousPage) {
		var cabinetUsersModel = function (model) {
			return A2(
				$elm_community$list_extra$List$Extra$find,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.kx;
					},
					$elm$core$Basics$eq(cabUid)),
				model.ll);
		};
		var goToUsers = F2(
			function (step_, model) {
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(
						$author$project$Routes$Pages$Error(0),
						$elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Tuple$mapBoth,
							$author$project$Routes$Pages$CabinetUsers(step_),
							$elm$core$Platform$Cmd$map($author$project$Main$Msg$CabinetUsersComponentAction)),
						A2(
							$elm$core$Maybe$map,
							$author$project$Components$Cabinets$Users$List$Update$init,
							A2(
								$elm$core$Maybe$map,
								function (c) {
									return A3(
										$author$project$Components$Cabinets$Users$List$Model$initModel,
										A4($author$project$Components$Cabinets$Model$buildCabinetFromApi, c.kx, c.nz, c.mh, c.nf),
										c.nf,
										model.k_.d5);
								},
								cabinetUsersModel(model)))));
			});
		switch (previousPage.$) {
			case 5:
				var step_ = previousPage.a;
				var model = previousPage.b;
				return _Utils_Tuple2(
					A2($author$project$Routes$Pages$CabinetUsers, step_, model),
					$elm$core$Platform$Cmd$none);
			case 3:
				var subModel = previousPage.b;
				return A2(goToUsers, step, subModel);
			default:
				var goTo = function (page) {
					if (page.$ === 3) {
						var subModel = page.b;
						return A2(
							$elm$core$Maybe$map,
							function (_v2) {
								return $elm$core$Result$Ok(
									A2(goToUsers, $elm$core$Maybe$Nothing, subModel));
							},
							cabinetUsersModel(subModel));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				};
				return A2(
					$author$project$Routes$ComponentBuilder$cabinets,
					$elm$core$Maybe$Just(goTo),
					rootApiLinks);
		}
	});
var $author$project$Components$Clients$Detail$Model$UpdateClient = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$NavLinkRef$fetchCabinetsLink = $PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink('cabinets');
var $author$project$Main$NavLinkRef$fetchClientsLink = $PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink('clients');
var $author$project$Main$NavLinkRef$fetchConseillersLink = $PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink('users');
var $author$project$Components$Clients$List$Update$CabinetInfosFetched = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Cabinets$APIModel$Cabinet = F4(
	function (uid, name, externalSyncInfos, links) {
		return {mh: externalSyncInfos, nf: links, nz: name, kx: uid};
	});
var $author$project$Components$Cabinets$APIModel$HarvestSyncInfos = $elm$core$Basics$identity;
var $author$project$Components$Cabinets$APIModel$HarvestSyncInfosBody = F3(
	function (id, clientId, username) {
		return {lu: clientId, hB: id, o2: username};
	});
var $author$project$Components$Cabinets$APIModel$externalSyncInfosDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$map,
			$elm$core$Basics$identity,
			A2(
				$elm$json$Json$Decode$field,
				'HarvestSyncInfos',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'username',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'clientId',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'id',
							$elm$json$Json$Decode$string,
							$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$APIModel$HarvestSyncInfosBody))))))
		]));
var $PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLink$RestNavigationLink = F3(
	function (rel, href, method) {
		return {hA: href, nq: method, jm: rel};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLink$DELETE = 3;
var $PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLink$GET = 0;
var $PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLink$POST = 1;
var $PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLink$PUT = 4;
var $PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$string2HttpVerbDecoder = function (s) {
	switch (s) {
		case 'GET':
			return $elm$json$Json$Decode$succeed(0);
		case 'POST':
			return $elm$json$Json$Decode$succeed(1);
		case 'PATCH':
			return $elm$json$Json$Decode$succeed(2);
		case 'DELETE':
			return $elm$json$Json$Decode$succeed(3);
		case 'PUT':
			return $elm$json$Json$Decode$succeed(4);
		default:
			var other = s;
			return $elm$json$Json$Decode$fail(other + ' is not an known http verb in app');
	}
};
var $PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$httpVerbDecoder = A2($elm$json$Json$Decode$andThen, $PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$string2HttpVerbDecoder, $elm$json$Json$Decode$string);
var $PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'method',
	$PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$httpVerbDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'href',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'rel',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLink$RestNavigationLink))));
var $author$project$Components$Cabinets$APIModel$cabinetJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'externalSyncInfos',
		$elm$json$Json$Decode$nullable($author$project$Components$Cabinets$APIModel$externalSyncInfosDecoder),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'uid',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$APIModel$Cabinet)))));
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.oy));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Http$RestAPIUtils$SeqAPIResult = F2(
	function (result, links) {
		return {nf: links, ok: result};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Http$RestAPIUtils$seqAPIResultJsonDecoder = function (decoder) {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'_links',
		$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'result',
			$elm$json$Json$Decode$list(decoder),
			$elm$json$Json$Decode$succeed($PerformanceIMMO$elm_utils$Perfimmo$Http$RestAPIUtils$SeqAPIResult)));
};
var $author$project$Components$Clients$HttpAPI$fetchCabinetInfos = function (link) {
	return A3(
		$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
		link,
		$elm$http$Http$emptyBody,
		$elm$http$Http$expectJson($elm$core$Basics$identity)(
			$PerformanceIMMO$elm_utils$Perfimmo$Http$RestAPIUtils$seqAPIResultJsonDecoder($author$project$Components$Cabinets$APIModel$cabinetJsonDecoder)));
};
var $author$project$Components$Clients$List$Update$init = function (model) {
	var effect = A2(
		$elm$core$Platform$Cmd$map,
		$author$project$Components$Clients$List$Update$CabinetInfosFetched,
		$author$project$Components$Clients$HttpAPI$fetchCabinetInfos(model.k_.ml));
	return _Utils_Tuple2(model, effect);
};
var $author$project$Components$Clients$List$Model$APILinks = F5(
	function (fetchClients, fetchConseillers, fetchCabinets, createClient, synchroniseWithHarvest) {
		return {gm: createClient, ml: fetchCabinets, mm: fetchClients, mn: fetchConseillers, ff: synchroniseWithHarvest};
	});
var $author$project$Components$Clients$List$Model$initConseillerFilter = A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'conseillersFilter', 0);
var $author$project$Components$Clients$List$Model$initModel = F3(
	function (fetchClientsL, fetchConseillersL, fetchCabinetsL) {
		return {
			d$: _List_Nil,
			k_: A5($author$project$Components$Clients$List$Model$APILinks, fetchClientsL, fetchConseillersL, fetchCabinetsL, $elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing),
			k8: _List_Nil,
			ca: _List_Nil,
			cX: $author$project$Components$Clients$List$Model$initConseillerFilter,
			k: {aW: $elm$core$Maybe$Nothing, bs: _List_Nil, eN: $elm$core$Maybe$Nothing, dg: false, hU: false, e8: false, ai: $elm$core$Maybe$Nothing},
			ck: _List_Nil,
			jG: $elm$core$Maybe$Nothing
		};
	});
var $elm$core$Maybe$map3 = F4(
	function (func, ma, mb, mc) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					return $elm$core$Maybe$Just(
						A3(func, a, b, c));
				}
			}
		}
	});
var $author$project$Routes$ClientsComponentBuilder$clients = F2(
	function (step, rootApiLinks) {
		var getLinks = function (f) {
			return A4(
				$elm$core$Maybe$map3,
				f,
				$author$project$Main$NavLinkRef$fetchClientsLink(rootApiLinks.ca),
				$author$project$Main$NavLinkRef$fetchConseillersLink(rootApiLinks.kW),
				$author$project$Main$NavLinkRef$fetchCabinetsLink(rootApiLinks.kW));
		};
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(
				$author$project$Routes$Pages$Error(0),
				$elm$core$Platform$Cmd$none),
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Tuple$mapBoth,
					$author$project$Routes$Pages$Clients(step),
					$elm$core$Platform$Cmd$map($author$project$Main$Msg$ClientsListComponentAction)),
				A2(
					$elm$core$Maybe$map,
					$author$project$Components$Clients$List$Update$init,
					getLinks(
						F3(
							function (l1, l2, l3) {
								return A3($author$project$Components$Clients$List$Model$initModel, l1, l2, l3);
							})))));
	});
var $author$project$Components$Clients$Detail$Action$FetchClient = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$Update$init = function (model) {
	var effects = A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Platform$Cmd$none,
		A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$Action$FetchClient, $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd),
			model.d1.mH));
	return _Utils_Tuple2(model, effects);
};
var $author$project$Components$Clients$Detail$Model$AppLink = F3(
	function (getClient, deleteClient, upsertClient) {
		return {gJ: deleteClient, mH: getClient, kA: upsertClient};
	});
var $author$project$Components$Clients$Detail$Model$Model = function (apiLink) {
	return function (abstractClients) {
		return function (abstractConseillers) {
			return function (clientId) {
				return function (extSync) {
					return function (syncStatus) {
						return function (clientForm) {
							return function (activeOnglet) {
								return function (saveState) {
									return function (formErrors) {
										return function (deleteModel) {
											return {d$: abstractClients, fu: abstractConseillers, fx: activeOnglet, d1: apiLink, gb: clientForm, lu: clientId, lQ: deleteModel, am: extSync, mv: formErrors, oq: saveState, bQ: syncStatus};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Clients$Detail$SaveState$NotModified = {$: 0};
var $author$project$Components$Clients$Detail$Model$Presentation = 0;
var $author$project$Components$Clients$Detail$Model$ShouldNotBeSync = {$: 0};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ClientFormRepr = F5(
	function (nature, contact, profilInvestisseur, profilExtraFinancier, conseillers) {
		return {ef: conseillers, cY: contact, ic: nature, jg: profilExtraFinancier, jh: profilInvestisseur};
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ContactPhoneForm = F3(
	function (tel1, tel2, tel3) {
		return {fj: tel1, fk: tel2, fl: tel3};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$FormField = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue = F2(
	function (maybeVal, _v0) {
		var infos = _v0.b;
		return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$FormField, maybeVal, infos);
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue = function (val) {
	return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue(
		$elm$core$Maybe$Just(val));
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$FormSelect = F5(
	function (a, b, c, d, e) {
		return {$: 0, a: a, b: b, c: c, d: d, e: e};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setOptionalValue = F2(
	function (maybeX, _v0) {
		var values = _v0.b;
		var infos = _v0.c;
		var builder = _v0.d;
		var toString = _v0.e;
		return A5($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$FormSelect, maybeX, values, infos, builder, toString);
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue = function (x) {
	return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setOptionalValue(
		$elm$core$Maybe$Just(x));
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$adresseFromApi = F2(
	function (adresseFormInit, add) {
		return _Utils_update(
			adresseFormInit,
			{
				fB: A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, add.fB),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					adresseFormInit.fB),
				fC: A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, add.fC),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					adresseFormInit.fC),
				gc: A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, add.gc),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					adresseFormInit.gc),
				dq: A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, add.dq),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					adresseFormInit.dq),
				d_: A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, add.d_),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					adresseFormInit.d_)
			});
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ContactForm = F3(
	function (email, telephones, adresseFiscale) {
		return {fE: adresseFiscale, gU: email, kf: telephones};
	});
var $author$project$Components$Clients$Detail$ViewForm$FormElements$BothSyncDirection = 0;
var $author$project$Components$Clients$Detail$ViewForm$FormElements$Harvest = 0;
var $author$project$Components$Clients$Detail$ViewForm$FormElements$IsSyncWithExtSystem = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$CustomDecorator = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Components$Clients$Detail$ViewForm$FormElements$fieldDecorator2String = function (decorator) {
	var syncDir2S = function (d) {
		switch (d) {
			case 0:
				return '_BothSyncDirection';
			case 1:
				return '_FromExtSync';
			default:
				return '_FromMustSync';
		}
	};
	var extSyst2S = function (e) {
		return 'Harvest';
	};
	switch (decorator.$) {
		case 0:
			return 'NoDecoration';
		case 1:
			var extSystem = decorator.a;
			return 'IsNotSyncWithExtSystem_' + extSyst2S(extSystem);
		default:
			var extSystem = decorator.a;
			var syncDirection = decorator.b;
			return 'IsNotSyncWithExtSystem_' + (extSyst2S(extSystem) + syncDir2S(syncDirection));
	}
};
var $author$project$Components$Clients$Detail$ViewForm$FormElements$customDecorator = function (decorator) {
	return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$CustomDecorator, decorator, $author$project$Components$Clients$Detail$ViewForm$FormElements$fieldDecorator2String);
};
var $author$project$Components$Clients$Detail$ViewForm$FormElements$harvestSyncBothDir = $author$project$Components$Clients$Detail$ViewForm$FormElements$customDecorator(
	A2($author$project$Components$Clients$Detail$ViewForm$FormElements$IsSyncWithExtSystem, 0, 0));
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$ifIsSync = F3(
	function (extSync, withExtSyncInfos, elseInfos) {
		if (!extSync.$) {
			var _v1 = extSync.a;
			return withExtSyncInfos;
		} else {
			return elseInfos;
		}
	});
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync = F2(
	function (extSync, else_) {
		return A3(
			$author$project$Components$Clients$Detail$ModelForm$FormInitializers$ifIsSync,
			extSync,
			_Utils_ap(
				else_,
				_List_fromArray(
					[$author$project$Components$Clients$Detail$ViewForm$FormElements$harvestSyncBothDir])),
			else_);
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$formFieldComparable = function (f) {
	if (!f.$) {
		return 'FieldIsMandatory';
	} else {
		var x = f.a;
		var comparable = f.b;
		return 'CustomDecorator_' + comparable(x);
	}
};
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $elm_community$list_extra$List$Extra$uniqueHelp = F4(
	function (f, existing, remaining, accumulator) {
		uniqueHelp:
		while (true) {
			if (!remaining.b) {
				return $elm$core$List$reverse(accumulator);
			} else {
				var first = remaining.a;
				var rest = remaining.b;
				var computedFirst = f(first);
				if (A2($elm$core$Set$member, computedFirst, existing)) {
					var $temp$f = f,
						$temp$existing = existing,
						$temp$remaining = rest,
						$temp$accumulator = accumulator;
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				} else {
					var $temp$f = f,
						$temp$existing = A2($elm$core$Set$insert, computedFirst, existing),
						$temp$remaining = rest,
						$temp$accumulator = A2($elm$core$List$cons, first, accumulator);
					f = $temp$f;
					existing = $temp$existing;
					remaining = $temp$remaining;
					accumulator = $temp$accumulator;
					continue uniqueHelp;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$uniqueBy = F2(
	function (f, list) {
		return A4($elm_community$list_extra$List$Extra$uniqueHelp, f, $elm$core$Set$empty, list, _List_Nil);
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$initFormFieldInfos = function (infos) {
	return A2($elm_community$list_extra$List$Extra$uniqueBy, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$formFieldComparable, infos);
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init = F2(
	function (val, infos) {
		return A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$FormField,
			val,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$initFormFieldInfos(infos));
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$AdresseForm = F5(
	function (addr1, addr2, codePostal, ville, pays) {
		return {fB: addr1, fC: addr2, gc: codePostal, dq: pays, d_: ville};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory = {$: 0};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init = F4(
	function (values, infos, stringBuilder, toString) {
		return A5(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$FormSelect,
			$elm$core$Maybe$Nothing,
			values,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$initFormFieldInfos(infos),
			stringBuilder,
			toString);
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Data$OfficialDataRef$paysTuples = _List_fromArray(
	[
		_Utils_Tuple2('AF', 'Afghanistan'),
		_Utils_Tuple2('ZA', 'Afrique du Sud'),
		_Utils_Tuple2('AL', 'Albanie'),
		_Utils_Tuple2('DZ', 'Algérie'),
		_Utils_Tuple2('DE', 'Allemagne'),
		_Utils_Tuple2('AD', 'Andorre'),
		_Utils_Tuple2('AO', 'Angola'),
		_Utils_Tuple2('AI', 'Anguilla'),
		_Utils_Tuple2('AQ', 'Antarctique'),
		_Utils_Tuple2('AG', 'Antigua-et-Barbuda'),
		_Utils_Tuple2('AN', 'Antilles néerlandaises'),
		_Utils_Tuple2('SA', 'Arabie saoudite'),
		_Utils_Tuple2('AR', 'Argentine'),
		_Utils_Tuple2('AM', 'Arménie'),
		_Utils_Tuple2('AW', 'Aruba'),
		_Utils_Tuple2('AU', 'Australie'),
		_Utils_Tuple2('AT', 'Autriche'),
		_Utils_Tuple2('AZ', 'Azerbaïdjan'),
		_Utils_Tuple2('BS', 'Bahamas'),
		_Utils_Tuple2('BH', 'Bahreïn'),
		_Utils_Tuple2('BD', 'Bangladesh'),
		_Utils_Tuple2('BB', 'Barbade'),
		_Utils_Tuple2('BY', 'Bélarus'),
		_Utils_Tuple2('BE', 'Belgique'),
		_Utils_Tuple2('BZ', 'Belize'),
		_Utils_Tuple2('BJ', 'Bénin'),
		_Utils_Tuple2('BM', 'Bermudes'),
		_Utils_Tuple2('BT', 'Bhoutan'),
		_Utils_Tuple2('BO', 'Bolivie'),
		_Utils_Tuple2('BA', 'Bosnie-Herzégovine'),
		_Utils_Tuple2('BW', 'Botswana'),
		_Utils_Tuple2('BR', 'Brésil'),
		_Utils_Tuple2('BN', 'Brunéi Darussalam'),
		_Utils_Tuple2('BG', 'Bulgarie'),
		_Utils_Tuple2('BF', 'Burkina Faso'),
		_Utils_Tuple2('BI', 'Burundi'),
		_Utils_Tuple2('KH', 'Cambodge'),
		_Utils_Tuple2('CM', 'Cameroun'),
		_Utils_Tuple2('CA', 'Canada'),
		_Utils_Tuple2('CV', 'Cap-Vert'),
		_Utils_Tuple2('EA', 'Ceuta et Melilla'),
		_Utils_Tuple2('CL', 'Chili'),
		_Utils_Tuple2('CN', 'Chine'),
		_Utils_Tuple2('CY', 'Chypre'),
		_Utils_Tuple2('CO', 'Colombie'),
		_Utils_Tuple2('KM', 'Comores'),
		_Utils_Tuple2('CG', 'Congo-Brazzaville'),
		_Utils_Tuple2('KP', 'Corée du Nord'),
		_Utils_Tuple2('KR', 'Corée du Sud'),
		_Utils_Tuple2('CR', 'Costa Rica'),
		_Utils_Tuple2('CI', 'Côte d’Ivoire'),
		_Utils_Tuple2('HR', 'Croatie'),
		_Utils_Tuple2('CU', 'Cuba'),
		_Utils_Tuple2('DK', 'Danemark'),
		_Utils_Tuple2('DG', 'Diego Garcia'),
		_Utils_Tuple2('DJ', 'Djibouti'),
		_Utils_Tuple2('DM', 'Dominique'),
		_Utils_Tuple2('EG', 'Égypte'),
		_Utils_Tuple2('SV', 'El Salvador'),
		_Utils_Tuple2('AE', 'Émirats arabes unis'),
		_Utils_Tuple2('EC', 'Équateur'),
		_Utils_Tuple2('ER', 'Érythrée'),
		_Utils_Tuple2('ES', 'Espagne'),
		_Utils_Tuple2('EE', 'Estonie'),
		_Utils_Tuple2('VA', 'État de la Cité du Vatican'),
		_Utils_Tuple2('FM', 'États fédérés de Micronésie'),
		_Utils_Tuple2('US', 'États-Unis'),
		_Utils_Tuple2('ET', 'Éthiopie'),
		_Utils_Tuple2('FJ', 'Fidji'),
		_Utils_Tuple2('FI', 'Finlande'),
		_Utils_Tuple2('FR', 'France'),
		_Utils_Tuple2('GA', 'Gabon'),
		_Utils_Tuple2('GM', 'Gambie'),
		_Utils_Tuple2('GE', 'Géorgie'),
		_Utils_Tuple2('GS', 'Géorgie du Sud et les îles Sandwich du Sud'),
		_Utils_Tuple2('GH', 'Ghana'),
		_Utils_Tuple2('GI', 'Gibraltar'),
		_Utils_Tuple2('GR', 'Grèce'),
		_Utils_Tuple2('GD', 'Grenade'),
		_Utils_Tuple2('GL', 'Groenland'),
		_Utils_Tuple2('GP', 'Guadeloupe'),
		_Utils_Tuple2('GU', 'Guam'),
		_Utils_Tuple2('GT', 'Guatemala'),
		_Utils_Tuple2('GG', 'Guernesey'),
		_Utils_Tuple2('GN', 'Guinée'),
		_Utils_Tuple2('GQ', 'Guinée équatoriale'),
		_Utils_Tuple2('GW', 'Guinée-Bissau'),
		_Utils_Tuple2('GY', 'Guyana'),
		_Utils_Tuple2('GF', 'Guyane française'),
		_Utils_Tuple2('HT', 'Haïti'),
		_Utils_Tuple2('HN', 'Honduras'),
		_Utils_Tuple2('HU', 'Hongrie'),
		_Utils_Tuple2('BV', 'Île Bouvet'),
		_Utils_Tuple2('CX', 'Île Christmas'),
		_Utils_Tuple2('CP', 'Île Clipperton'),
		_Utils_Tuple2('AC', 'Île de l\'Ascension'),
		_Utils_Tuple2('IM', 'Île de Man'),
		_Utils_Tuple2('NF', 'Île Norfolk'),
		_Utils_Tuple2('AX', 'Îles Åland'),
		_Utils_Tuple2('KY', 'Îles Caïmans'),
		_Utils_Tuple2('IC', 'Îles Canaries'),
		_Utils_Tuple2('CC', 'Îles Cocos - Keeling'),
		_Utils_Tuple2('CK', 'Îles Cook'),
		_Utils_Tuple2('FO', 'Îles Féroé'),
		_Utils_Tuple2('HM', 'Îles Heard et MacDonald'),
		_Utils_Tuple2('FK', 'Îles Malouines'),
		_Utils_Tuple2('MP', 'Îles Mariannes du Nord'),
		_Utils_Tuple2('MH', 'Îles Marshall'),
		_Utils_Tuple2('UM', 'Îles Mineures Éloignées des États-Unis'),
		_Utils_Tuple2('SB', 'Îles Salomon'),
		_Utils_Tuple2('TC', 'Îles Turks et Caïques'),
		_Utils_Tuple2('VG', 'Îles Vierges britanniques'),
		_Utils_Tuple2('VI', 'Îles Vierges des États-Unis'),
		_Utils_Tuple2('IN', 'Inde'),
		_Utils_Tuple2('ID', 'Indonésie'),
		_Utils_Tuple2('IQ', 'Irak'),
		_Utils_Tuple2('IR', 'Iran'),
		_Utils_Tuple2('IE', 'Irlande'),
		_Utils_Tuple2('IS', 'Islande'),
		_Utils_Tuple2('IL', 'Israël'),
		_Utils_Tuple2('IT', 'Italie'),
		_Utils_Tuple2('JM', 'Jamaïque'),
		_Utils_Tuple2('JP', 'Japon'),
		_Utils_Tuple2('JE', 'Jersey'),
		_Utils_Tuple2('JO', 'Jordanie'),
		_Utils_Tuple2('KZ', 'Kazakhstan'),
		_Utils_Tuple2('KE', 'Kenya'),
		_Utils_Tuple2('KG', 'Kirghizistan'),
		_Utils_Tuple2('KI', 'Kiribati'),
		_Utils_Tuple2('KW', 'Koweït'),
		_Utils_Tuple2('LA', 'Laos'),
		_Utils_Tuple2('LS', 'Lesotho'),
		_Utils_Tuple2('LV', 'Lettonie'),
		_Utils_Tuple2('LB', 'Liban'),
		_Utils_Tuple2('LR', 'Libéria'),
		_Utils_Tuple2('LY', 'Libye'),
		_Utils_Tuple2('LI', 'Liechtenstein'),
		_Utils_Tuple2('LT', 'Lituanie'),
		_Utils_Tuple2('LU', 'Luxembourg'),
		_Utils_Tuple2('MK', 'Macédoine'),
		_Utils_Tuple2('MG', 'Madagascar'),
		_Utils_Tuple2('MY', 'Malaisie'),
		_Utils_Tuple2('MW', 'Malawi'),
		_Utils_Tuple2('MV', 'Maldives'),
		_Utils_Tuple2('ML', 'Mali'),
		_Utils_Tuple2('MT', 'Malte'),
		_Utils_Tuple2('MA', 'Maroc'),
		_Utils_Tuple2('MQ', 'Martinique'),
		_Utils_Tuple2('MU', 'Maurice'),
		_Utils_Tuple2('MR', 'Mauritanie'),
		_Utils_Tuple2('YT', 'Mayotte'),
		_Utils_Tuple2('MX', 'Mexique'),
		_Utils_Tuple2('MD', 'Moldavie'),
		_Utils_Tuple2('MC', 'Monaco'),
		_Utils_Tuple2('MN', 'Mongolie'),
		_Utils_Tuple2('ME', 'Monténégro'),
		_Utils_Tuple2('MS', 'Montserrat'),
		_Utils_Tuple2('MZ', 'Mozambique'),
		_Utils_Tuple2('MM', 'Myanmar'),
		_Utils_Tuple2('NA', 'Namibie'),
		_Utils_Tuple2('NR', 'Nauru'),
		_Utils_Tuple2('NP', 'Népal'),
		_Utils_Tuple2('NI', 'Nicaragua'),
		_Utils_Tuple2('NE', 'Niger'),
		_Utils_Tuple2('NG', 'Nigéria'),
		_Utils_Tuple2('NU', 'Niue'),
		_Utils_Tuple2('NO', 'Norvège'),
		_Utils_Tuple2('NC', 'Nouvelle-Calédonie'),
		_Utils_Tuple2('NZ', 'Nouvelle-Zélande'),
		_Utils_Tuple2('OM', 'Oman'),
		_Utils_Tuple2('UG', 'Ouganda'),
		_Utils_Tuple2('UZ', 'Ouzbékistan'),
		_Utils_Tuple2('PK', 'Pakistan'),
		_Utils_Tuple2('PW', 'Palaos'),
		_Utils_Tuple2('PA', 'Panama'),
		_Utils_Tuple2('PG', 'Papouasie-Nouvelle-Guinée'),
		_Utils_Tuple2('PY', 'Paraguay'),
		_Utils_Tuple2('NL', 'Pays-Bas'),
		_Utils_Tuple2('PE', 'Pérou'),
		_Utils_Tuple2('PH', 'Philippines'),
		_Utils_Tuple2('PN', 'Pitcairn'),
		_Utils_Tuple2('PL', 'Pologne'),
		_Utils_Tuple2('PF', 'Polynésie française'),
		_Utils_Tuple2('PR', 'Porto Rico'),
		_Utils_Tuple2('PT', 'Portugal'),
		_Utils_Tuple2('QA', 'Qatar'),
		_Utils_Tuple2('HK', 'R.A.S. chinoise de Hong Kong'),
		_Utils_Tuple2('MO', 'R.A.S. chinoise de Macao'),
		_Utils_Tuple2('QO', 'régions éloignées de l’Océanie'),
		_Utils_Tuple2('CF', 'République centrafricaine'),
		_Utils_Tuple2('CD', 'République démocratique du Congo'),
		_Utils_Tuple2('DO', 'République dominicaine'),
		_Utils_Tuple2('CZ', 'République tchèque'),
		_Utils_Tuple2('RE', 'Réunion'),
		_Utils_Tuple2('RO', 'Roumanie'),
		_Utils_Tuple2('GB', 'Royaume-Uni'),
		_Utils_Tuple2('RU', 'Russie'),
		_Utils_Tuple2('RW', 'Rwanda'),
		_Utils_Tuple2('EH', 'Sahara occidental'),
		_Utils_Tuple2('BL', 'Saint-Barthélémy'),
		_Utils_Tuple2('KN', 'Saint-Kitts-et-Nevis'),
		_Utils_Tuple2('SM', 'Saint-Marin'),
		_Utils_Tuple2('MF', 'Saint-Martin'),
		_Utils_Tuple2('PM', 'Saint-Pierre-et-Miquelon'),
		_Utils_Tuple2('VC', 'Saint-Vincent-et-les Grenadines'),
		_Utils_Tuple2('SH', 'Sainte-Hélène'),
		_Utils_Tuple2('LC', 'Sainte-Lucie'),
		_Utils_Tuple2('WS', 'Samoa'),
		_Utils_Tuple2('AS', 'Samoa américaines'),
		_Utils_Tuple2('ST', 'Sao Tomé-et-Principe'),
		_Utils_Tuple2('SN', 'Sénégal'),
		_Utils_Tuple2('RS', 'Serbie'),
		_Utils_Tuple2('CS', 'Serbie-et-Monténégro'),
		_Utils_Tuple2('SC', 'Seychelles'),
		_Utils_Tuple2('SL', 'Sierra Leone'),
		_Utils_Tuple2('SG', 'Singapour'),
		_Utils_Tuple2('SK', 'Slovaquie'),
		_Utils_Tuple2('SI', 'Slovénie'),
		_Utils_Tuple2('SO', 'Somalie'),
		_Utils_Tuple2('SD', 'Soudan'),
		_Utils_Tuple2('LK', 'Sri Lanka'),
		_Utils_Tuple2('SE', 'Suède'),
		_Utils_Tuple2('CH', 'Suisse'),
		_Utils_Tuple2('SR', 'Suriname'),
		_Utils_Tuple2('SJ', 'Svalbard et Île Jan Mayen'),
		_Utils_Tuple2('SZ', 'Swaziland'),
		_Utils_Tuple2('SY', 'Syrie'),
		_Utils_Tuple2('TJ', 'Tadjikistan'),
		_Utils_Tuple2('TW', 'Taïwan'),
		_Utils_Tuple2('TZ', 'Tanzanie'),
		_Utils_Tuple2('TD', 'Tchad'),
		_Utils_Tuple2('TF', 'Terres australes françaises'),
		_Utils_Tuple2('IO', 'Territoire britannique de l\'océan Indien'),
		_Utils_Tuple2('PS', 'Territoire palestinien'),
		_Utils_Tuple2('TH', 'Thaïlande'),
		_Utils_Tuple2('TL', 'Timor oriental'),
		_Utils_Tuple2('TG', 'Togo'),
		_Utils_Tuple2('TK', 'Tokelau'),
		_Utils_Tuple2('TO', 'Tonga'),
		_Utils_Tuple2('TT', 'Trinité-et-Tobago'),
		_Utils_Tuple2('TA', 'Tristan da Cunha'),
		_Utils_Tuple2('TN', 'Tunisie'),
		_Utils_Tuple2('TM', 'Turkménistan'),
		_Utils_Tuple2('TR', 'Turquie'),
		_Utils_Tuple2('TV', 'Tuvalu'),
		_Utils_Tuple2('UA', 'Ukraine'),
		_Utils_Tuple2('EU', 'Union européenne'),
		_Utils_Tuple2('UY', 'Uruguay'),
		_Utils_Tuple2('VU', 'Vanuatu'),
		_Utils_Tuple2('VE', 'Venezuela'),
		_Utils_Tuple2('VN', 'Viêt Nam'),
		_Utils_Tuple2('WF', 'Wallis-et-Futuna'),
		_Utils_Tuple2('YE', 'Yémen'),
		_Utils_Tuple2('ZM', 'Zambie'),
		_Utils_Tuple2('ZW', 'Zimbabwe')
	]);
var $author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$paysTuplesWithFranceFirst = _Utils_ap(
	_List_fromArray(
		[
			_Utils_Tuple2('FR', 'France')
		]),
	$PerformanceIMMO$elm_utils$Perfimmo$Data$OfficialDataRef$paysTuples);
var $author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$initNationaliteField = function (infos) {
	return A4($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init, $author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$paysTuplesWithFranceFirst, infos, $elm$core$Maybe$Just, $elm$core$Basics$identity);
};
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initAdresseForm = function (extSync) {
	return A5(
		$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$AdresseForm,
		A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
			$elm$core$Maybe$Nothing,
			A2(
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
				extSync,
				_List_fromArray(
					[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
		A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
			$elm$core$Maybe$Nothing,
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)),
		A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
			$elm$core$Maybe$Nothing,
			A2(
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
				extSync,
				_List_fromArray(
					[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
		A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
			$elm$core$Maybe$Nothing,
			A2(
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
				extSync,
				_List_fromArray(
					[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
		$author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$initNationaliteField(
			A2(
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
				extSync,
				_List_fromArray(
					[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))));
};
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initContactPhoneForm = function (extSync) {
	return A3(
		$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ContactPhoneForm,
		A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
			$elm$core$Maybe$Nothing,
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)),
		A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
			$elm$core$Maybe$Nothing,
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)),
		A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
			$elm$core$Maybe$Nothing,
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)));
};
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initContactForm = function (extSync) {
	return A3(
		$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ContactForm,
		A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
			$elm$core$Maybe$Nothing,
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)),
		$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initContactPhoneForm(extSync),
		$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initAdresseForm(extSync));
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$contactFromApi = F2(
	function (extSync, contact) {
		var contact_form = $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initContactForm(extSync);
		return _Utils_update(
			contact_form,
			{
				fE: A2(
					$elm$core$Maybe$withDefault,
					contact_form.fE,
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$adresseFromApi(contact_form.fE),
						contact.fE)),
				gU: A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, contact.gU),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					contact_form.gU),
				kf: function () {
					var _v0 = contact.kf;
					if (!_v0.b) {
						return contact_form.kf;
					} else {
						if (!_v0.b.b) {
							var x = _v0.a;
							return A3(
								$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ContactPhoneForm,
								A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, x, contact_form.kf.fj),
								contact_form.kf.fk,
								contact_form.kf.fl);
						} else {
							if (!_v0.b.b.b) {
								var x = _v0.a;
								var _v1 = _v0.b;
								var y = _v1.a;
								return A3(
									$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ContactPhoneForm,
									A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, x, contact_form.kf.fj),
									A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, y, contact_form.kf.fk),
									contact_form.kf.fl);
							} else {
								var x = _v0.a;
								var _v2 = _v0.b;
								var y = _v2.a;
								var _v3 = _v2.b;
								var z = _v3.a;
								return A3(
									$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ContactPhoneForm,
									A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, x, contact_form.kf.fj),
									A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, y, contact_form.kf.fk),
									A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, z, contact_form.kf.fl));
							}
						}
					}
				}()
			});
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$FormMultiSelect = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$init = F3(
	function (values, tag, infos) {
		var model = A3($inkuzmin$elm_multiselect$Multiselect$initModel, values, tag, 0);
		return A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$FormMultiSelect,
			model,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$initFormFieldInfos(infos));
	});
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initConseillers = F2(
	function (extSync, conseillers) {
		var conseillersTuples = A2(
			$elm$core$List$map,
			function (c) {
				return _Utils_Tuple2(c.kx, c.nz);
			},
			conseillers);
		return A3(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$init,
			conseillersTuples,
			'conseillers',
			A2(
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
				extSync,
				_List_fromArray(
					[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory])));
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueParamsForm = function (identite) {
	return function (naissance) {
		return function (pieceIdentite) {
			return function (deces) {
				return function (nationalite) {
					return function (autreNationalites) {
						return function (paysDeResidenceFiscale) {
							return function (dateDepartRetraite) {
								return function (capaciteJuridique) {
									return function (profession) {
										return function (situationFamilliale) {
											return function (situationPatrimoniale) {
												return function (representantsLegaux) {
													return function (evaluationRisqueLCBFT) {
														return function (personnePolitiquementExposee) {
															return function (personneVulnerable) {
																return {bf: autreNationalites, f0: capaciteJuridique, gs: dateDepartRetraite, gF: deces, c5: evaluationRisqueLCBFT, aD: identite, aI: naissance, bD: nationalite, iE: paysDeResidenceFiscale, iO: personnePolitiquementExposee, iP: personneVulnerable, du: pieceIdentite, dw: profession, dA: representantsLegaux, jU: situationFamilliale, jV: situationPatrimoniale};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Clients$Detail$ViewForm$FormElements$FromExtSync = 1;
var $author$project$Components$Clients$Detail$ViewForm$FormElements$harvestSyncFromExtDir = $author$project$Components$Clients$Detail$ViewForm$FormElements$customDecorator(
	A2($author$project$Components$Clients$Detail$ViewForm$FormElements$IsSyncWithExtSystem, 0, 1));
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync = F2(
	function (extSync, else_) {
		return A3(
			$author$project$Components$Clients$Detail$ModelForm$FormInitializers$ifIsSync,
			extSync,
			_Utils_ap(
				else_,
				_List_fromArray(
					[$author$project$Components$Clients$Detail$ViewForm$FormElements$harvestSyncFromExtDir])),
			else_);
	});
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initAutreNationalites = function (fieldInfos) {
	return A3($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$init, $PerformanceIMMO$elm_utils$Perfimmo$Data$OfficialDataRef$paysTuples, 'autreNationalites', fieldInfos);
};
var $author$project$Components$Clients$APIModel$AbsentOuAutreMajeurIncapable = 4;
var $author$project$Components$Clients$APIModel$MajeurCapable = 0;
var $author$project$Components$Clients$APIModel$MajeurProtegeSousCuratelle = 2;
var $author$project$Components$Clients$APIModel$MajeurProtegeSousTutelle = 1;
var $author$project$Components$Clients$APIModel$MajeurSousSauvegardeDeJustice = 3;
var $author$project$Components$Clients$APIModel$MineurEmancipe = 6;
var $author$project$Components$Clients$APIModel$MineurNonEmancipe = 8;
var $author$project$Components$Clients$APIModel$MineurSousControleJudiciaire = 7;
var $author$project$Components$Clients$APIModel$MineurSousTutelle = 5;
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$capaciteJuridiqueFromS = function (c) {
	switch (c) {
		case 'Majeur capable':
			return $elm$core$Maybe$Just(0);
		case 'Majeur protegé sous tutelle':
			return $elm$core$Maybe$Just(1);
		case 'Majeur protegé sous curatelle':
			return $elm$core$Maybe$Just(2);
		case 'Majeur sous sauvegarde de justice':
			return $elm$core$Maybe$Just(3);
		case 'Absent ou autre majeur incapable':
			return $elm$core$Maybe$Just(4);
		case 'Mineur sous tutelle':
			return $elm$core$Maybe$Just(5);
		case 'Mineur émancipé':
			return $elm$core$Maybe$Just(6);
		case 'Mineur sous contrôle judiciaire':
			return $elm$core$Maybe$Just(7);
		case 'Mineur non émancipé':
			return $elm$core$Maybe$Just(8);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$capaciteJuridiqueToS = function (c) {
	switch (c) {
		case 0:
			return 'Majeur capable';
		case 1:
			return 'Majeur protegé sous tutelle';
		case 2:
			return 'Majeur protegé sous curatelle';
		case 3:
			return 'Majeur sous sauvegarde de justice';
		case 4:
			return 'Absent ou autre majeur incapable';
		case 5:
			return 'Mineur sous tutelle';
		case 6:
			return 'Mineur émancipé';
		case 7:
			return 'Mineur sous contrôle judiciaire';
		default:
			return 'Mineur non émancipé';
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$capaciteJuridiqueTuples = A2(
	$elm$core$List$map,
	function (v) {
		return _Utils_Tuple2(
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$capaciteJuridiqueToS(v),
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$capaciteJuridiqueToS(v));
	},
	_List_fromArray(
		[0, 1, 2, 3, 4, 5, 6, 7, 8]));
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initCapaciteJuridiqueField = function (infos) {
	return A4($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$capaciteJuridiqueTuples, infos, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$capaciteJuridiqueFromS, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$capaciteJuridiqueToS);
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$IdentiteForm = F4(
	function (civilite, nom, nomNaissance, prenom) {
		return {f8: civilite, dl: nom, ii: nomNaissance, iV: prenom};
	});
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$availableCivilite = _List_fromArray(
	[
		_Utils_Tuple2('Monsieur', 'Monsieur'),
		_Utils_Tuple2('Madame', 'Madame')
	]);
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$civilite2String = function (civ) {
	if (!civ) {
		return 'Monsieur';
	} else {
		return 'Madame';
	}
};
var $author$project$Components$Clients$APIModel$Madame = 1;
var $author$project$Components$Clients$APIModel$Monsieur = 0;
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$s2Civilite = function (s) {
	switch (s) {
		case 'Monsieur':
			return $elm$core$Maybe$Just(0);
		case 'Madame':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Just(0);
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initCiviliteField = function (infos) {
	return A4($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$availableCivilite, infos, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$s2Civilite, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$civilite2String);
};
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initIdentiteForm = F2(
	function (extSync, syncDirFunc) {
		return A4(
			$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$IdentiteForm,
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initCiviliteField(
				A2(
					syncDirFunc,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2(
					syncDirFunc,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2(syncDirFunc, extSync, _List_Nil)),
			A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2(
					syncDirFunc,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))));
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$NaissanceForm = F3(
	function (date, ville, pays) {
		return {c$: date, dq: pays, d_: ville};
	});
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initNaissanceForm = F2(
	function (extSync, syncDirFunc) {
		return A3(
			$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$NaissanceForm,
			A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2(
					syncDirFunc,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2(syncDirFunc, extSync, _List_Nil)),
			$author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$initNationaliteField(
				A2(syncDirFunc, extSync, _List_Nil)));
	});
var $author$project$Perfimmo$Form$FormModel$OptionalBlock = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PieceIdentiteForm = F4(
	function (numero, typeDePiece, dateDelivrance, dateExpiration) {
		return {gr: dateDelivrance, gu: dateExpiration, is: numero, ks: typeDePiece};
	});
var $author$project$Components$Clients$APIModel$AutrePieceIdentite = 9;
var $author$project$Components$Clients$APIModel$CarteDeCommercantEtranger = 7;
var $author$project$Components$Clients$APIModel$CarteDeResident = 6;
var $author$project$Components$Clients$APIModel$CarteDeRessortissantUE_EEE = 2;
var $author$project$Components$Clients$APIModel$CarteDeSejour = 3;
var $author$project$Components$Clients$APIModel$CarteNationaleIdentite = 0;
var $author$project$Components$Clients$APIModel$OrdonnanceDeProtection = 5;
var $author$project$Components$Clients$APIModel$Passeport = 1;
var $author$project$Components$Clients$APIModel$PasseportNonResident = 4;
var $author$project$Components$Clients$APIModel$PermisDeConduire = 8;
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$pieceIdentiteTypeFromS = function (s) {
	switch (s) {
		case 'Carte nationale d\'identité':
			return $elm$core$Maybe$Just(0);
		case 'Passeport':
			return $elm$core$Maybe$Just(1);
		case 'Carte de ressortissant UE_EEE':
			return $elm$core$Maybe$Just(2);
		case 'Carte de séjour':
			return $elm$core$Maybe$Just(3);
		case 'Passeport non résident':
			return $elm$core$Maybe$Just(4);
		case 'Ordonnance de protection':
			return $elm$core$Maybe$Just(5);
		case 'Carte de résident':
			return $elm$core$Maybe$Just(6);
		case 'Carte de commerçant étranger':
			return $elm$core$Maybe$Just(7);
		case 'Permis de conduire':
			return $elm$core$Maybe$Just(8);
		case 'Autre pièce d\'identité':
			return $elm$core$Maybe$Just(9);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$pieceIdentiteTypeToS = function (c) {
	switch (c) {
		case 0:
			return 'Carte nationale d\'identité';
		case 1:
			return 'Passeport';
		case 2:
			return 'Carte de ressortissant UE_EEE';
		case 3:
			return 'Carte de séjour';
		case 4:
			return 'Passeport non résident';
		case 5:
			return 'Ordonnance de protection';
		case 6:
			return 'Carte de résident';
		case 7:
			return 'Carte de commerçant étranger';
		case 8:
			return 'Permis de conduire';
		default:
			return 'Autre pièce d\'identité';
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$pieceIdentiteTypeTuples = A2(
	$elm$core$List$map,
	function (v) {
		return _Utils_Tuple2(
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$pieceIdentiteTypeToS(v),
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$pieceIdentiteTypeToS(v));
	},
	_List_fromArray(
		[0, 1, 2, 3, 4, 5, 9]));
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initPieceIdentiteField = function (infos) {
	return A4($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$pieceIdentiteTypeTuples, infos, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$pieceIdentiteTypeFromS, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$pieceIdentiteTypeToS);
};
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initPieceIdentiteForm = function (extSync) {
	return $author$project$Perfimmo$Form$FormModel$OptionalBlock(
		A4(
			$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PieceIdentiteForm,
			A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initPieceIdentiteField(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory])))));
};
var $author$project$Components$Clients$APIModel$Faible = 0;
var $author$project$Components$Clients$APIModel$Fort = 2;
var $author$project$Components$Clients$APIModel$Moyen = 1;
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$evaluationRisqueLCBFTFromS = function (s) {
	switch (s) {
		case 'Faible':
			return $elm$core$Maybe$Just(0);
		case 'Moyen':
			return $elm$core$Maybe$Just(1);
		case 'Fort':
			return $elm$core$Maybe$Just(2);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$evaluationRisqueLCBFTToS = function (s) {
	switch (s) {
		case 0:
			return 'Faible';
		case 1:
			return 'Moyen';
		default:
			return 'Fort';
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$evaluationRisqueLCBFTTuples = A2(
	$elm$core$List$map,
	function (v) {
		return _Utils_Tuple2(
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$evaluationRisqueLCBFTToS(v),
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$evaluationRisqueLCBFTToS(v));
	},
	_List_fromArray(
		[0, 1, 2]));
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initRisqueLCBFTField = function (infos) {
	return A4($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$evaluationRisqueLCBFTTuples, infos, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$evaluationRisqueLCBFTFromS, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$evaluationRisqueLCBFTToS);
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$SituationPatrimonialeForm = F4(
	function (fiscalite, patrimoine, budget, tauxEndettement) {
		return {fX: budget, db: fiscalite, iD: patrimoine, kb: tauxEndettement};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$FormPourcent = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$init = function (infos) {
	return A3(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$FormPourcent,
		$elm$core$Maybe$Nothing,
		'',
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$initFormFieldInfos(infos));
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$BudgetForm = F2(
	function (revenusAnnuels, chargesAnnuelles) {
		return {f5: chargesAnnuelles, jy: revenusAnnuels};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$FormFloat = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init = function (infos) {
	return A3(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$FormFloat,
		$elm$core$Maybe$Nothing,
		'',
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$initFormFieldInfos(infos));
};
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initBudgetForm = function (extSync) {
	return $author$project$Perfimmo$Form$FormModel$OptionalBlock(
		A2(
			$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$BudgetForm,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory])))));
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$FiscalitePatrimonialeForm = F4(
	function (nombrePartsFiscales, montantIR, montant_IFI, tmi) {
		return {h7: montantIR, h8: montant_IFI, ik: nombrePartsFiscales, kj: tmi};
	});
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initFiscalitePatrimonialeForm = function (extSync) {
	return $author$project$Perfimmo$Form$FormModel$OptionalBlock(
		A4(
			$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$FiscalitePatrimonialeForm,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$init(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory])))));
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PatrimoineForm = F2(
	function (actif, passif) {
		return {kP: actif, iB: passif};
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PatrimoineActifForm = F3(
	function (brut, financier, non_financier) {
		return {cR: brut, da: financier, io: non_financier};
	});
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initPatrimoineActifForm = function (extSync) {
	return A3(
		$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PatrimoineActifForm,
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
			A2(
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
				extSync,
				_List_fromArray(
					[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
			A2(
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
				extSync,
				_List_fromArray(
					[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
			A2(
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
				extSync,
				_List_fromArray(
					[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))));
};
var $author$project$Components$Clients$Detail$ViewForm$FormElements$IsNotSyncWithExtSystem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$ViewForm$FormElements$harvestNotSync = $author$project$Components$Clients$Detail$ViewForm$FormElements$customDecorator(
	$author$project$Components$Clients$Detail$ViewForm$FormElements$IsNotSyncWithExtSystem(0));
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync = F2(
	function (extSync, else_) {
		return A3(
			$author$project$Components$Clients$Detail$ModelForm$FormInitializers$ifIsSync,
			extSync,
			_Utils_ap(
				else_,
				_List_fromArray(
					[$author$project$Components$Clients$Detail$ViewForm$FormElements$harvestNotSync])),
			else_);
	});
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initPatrimoineForm = function (extSync) {
	return $author$project$Perfimmo$Form$FormModel$OptionalBlock(
		A2(
			$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PatrimoineForm,
			$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initPatrimoineActifForm(extSync),
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory])))));
};
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initSitPatForm = function (extSync) {
	return $author$project$Perfimmo$Form$FormModel$OptionalBlock(
		A4(
			$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$SituationPatrimonialeForm,
			$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initFiscalitePatrimonialeForm(extSync),
			$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initPatrimoineForm(extSync),
			$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initBudgetForm(extSync),
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$init(
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync, extSync, _List_Nil))));
};
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initPersonnePhysiqueForm = F2(
	function (extSync, sitFamInit) {
		return $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueParamsForm(
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initIdentiteForm, extSync, $author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync))(
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initNaissanceForm, extSync, $author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync))(
			$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initPieceIdentiteForm(extSync))(
			A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)))(
			$author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$initNationaliteField(
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)))(
			$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initAutreNationalites(
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)))(
			$author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$initNationaliteField(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))))(
			A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)))(
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initCapaciteJuridiqueField(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))))(
			A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)))(sitFamInit)(
			$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initSitPatForm(extSync))(_List_Nil)(
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initRisqueLCBFTField(
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync, extSync, _List_Nil)))(
			A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)))(
			A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync, extSync, _List_Nil)));
	});
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$pef_default_val = $elm$core$Maybe$Just('0');
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initProfilExtraFinancierForm = function (extSync) {
	return $author$project$Perfimmo$Form$FormModel$OptionalBlock(
		{
			gy: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			iF: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$pef_default_val,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			iG: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$pef_default_val,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			iH: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$pef_default_val,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			iI: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$pef_default_val,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			iJ: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$pef_default_val,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			iK: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$pef_default_val,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			iL: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$pef_default_val,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			iM: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$pef_default_val,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			iN: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$pef_default_val,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			cx: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Just(
					$elm$core$Dict$fromList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								_Utils_Tuple2('Gaz à effet de serre', 'GazEffetDeSerre'),
								false),
								_Utils_Tuple2(
								_Utils_Tuple2('Niveau d\'impact sur la biodiversité', 'NiveauImpactBiodiversite'),
								false),
								_Utils_Tuple2(
								_Utils_Tuple2('Emissions polluantes dans l\'eau', 'EmissionsPolluantesEau'),
								false),
								_Utils_Tuple2(
								_Utils_Tuple2('Génération de déchets dangereux', 'GenerationDechetsDangereux'),
								false),
								_Utils_Tuple2(
								_Utils_Tuple2('Inefficacité énergétique (immobilier)', 'InefficaciteEnergetiqueImmobilier'),
								false),
								_Utils_Tuple2(
								_Utils_Tuple2('Respect des normes internationales (OCDE, Nations Unies)', 'RespectDesNormesInternationales'),
								false),
								_Utils_Tuple2(
								_Utils_Tuple2('Processus de contrôle des normes internationales', 'ProcessusDeControleDesNormesInternationales'),
								false),
								_Utils_Tuple2(
								_Utils_Tuple2('Égalité de rémunération (Homme/Femme)', 'EgaliteRemuneration'),
								false),
								_Utils_Tuple2(
								_Utils_Tuple2('Diversité des genres au sein des conseils d\'administration', 'DiversiteDesGenresConseilsAdministration'),
								false),
								_Utils_Tuple2(
								_Utils_Tuple2('Exposition aux armes controversées', 'ExpositionAuxArmesControversees'),
								false)
							]))),
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			i0: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			i1: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			i2: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			i3: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory])))
		});
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ClassificationMifIIForm = F2(
	function (type_, date) {
		return {c$: date, kv: type_};
	});
var $author$project$Components$Clients$APIModel$AucunePerteToleree_0pct = 0;
var $author$project$Components$Clients$APIModel$PerteFinanciereAConcurrenceDesMontantsInvestis_100pct = 3;
var $author$project$Components$Clients$APIModel$PerteFinanciereAuDelaDesMontantsInvestisSup_100pct = 4;
var $author$project$Components$Clients$APIModel$PerteFinanciereLimiteeInf_10pct = 1;
var $author$project$Components$Clients$APIModel$PerteFinanciereSignificativeEntre_10et50pct = 2;
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$capaciteSubirPertesFromS = function (s) {
	switch (s) {
		case 'Aucune perte Tolerée (0%)':
			return $elm$core$Maybe$Just(0);
		case 'Perte financière limitée (< 10%)':
			return $elm$core$Maybe$Just(1);
		case 'Perte financière significative (entre 10% et 50%)':
			return $elm$core$Maybe$Just(2);
		case 'Perte financière à concurrence des montants investis (100%)':
			return $elm$core$Maybe$Just(3);
		case 'Perte financière au delà des montants investis (> 100%)':
			return $elm$core$Maybe$Just(4);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$capaciteSubirPertesToS = function (s) {
	switch (s) {
		case 0:
			return 'Aucune perte Tolerée (0%)';
		case 1:
			return 'Perte financière limitée (< 10%)';
		case 2:
			return 'Perte financière significative (entre 10% et 50%)';
		case 3:
			return 'Perte financière à concurrence des montants investis (100%)';
		default:
			return 'Perte financière au delà des montants investis (> 100%)';
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$capaciteSubirPertesTuples = A2(
	$elm$core$List$map,
	function (v) {
		return _Utils_Tuple2(
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$capaciteSubirPertesToS(v),
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$capaciteSubirPertesToS(v));
	},
	_List_fromArray(
		[0, 1, 2, 3, 4]));
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initCapaciteSubirPertesField = function (infos) {
	return A4($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$capaciteSubirPertesTuples, infos, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$capaciteSubirPertesFromS, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$capaciteSubirPertesToS);
};
var $author$project$Components$Clients$APIModel$Experimente = 2;
var $author$project$Components$Clients$APIModel$Informe = 1;
var $author$project$Components$Clients$APIModel$Novice = 0;
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$experienceFinanciereToS = function (s) {
	switch (s) {
		case 0:
			return 'Novice';
		case 1:
			return 'Informé';
		default:
			return 'Experimenté';
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$experienceFinancierTuples = A2(
	$elm$core$List$map,
	function (v) {
		return _Utils_Tuple2(
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$experienceFinanciereToS(v),
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$experienceFinanciereToS(v));
	},
	_List_fromArray(
		[0, 1, 2]));
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$experienceFinanciereFromS = function (s) {
	switch (s) {
		case 'Novice':
			return $elm$core$Maybe$Just(0);
		case 'Informé':
			return $elm$core$Maybe$Just(1);
		case 'Experimenté':
			return $elm$core$Maybe$Just(2);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initExperienceFinanciereField = function (infos) {
	return A4($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$experienceFinancierTuples, infos, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$experienceFinanciereFromS, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$experienceFinanciereToS);
};
var $author$project$Components$Clients$APIModel$HorizonCourtTermeEntre_1et3an = 1;
var $author$project$Components$Clients$APIModel$HorizonLongTermeSup_5an = 3;
var $author$project$Components$Clients$APIModel$HorizonMoyenTermeEntre_3et5an = 2;
var $author$project$Components$Clients$APIModel$HorizonTresCourtTermeInf_1an = 0;
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$horizonInvestissementFromS = function (s) {
	switch (s) {
		case 'Horizon très court terme (< 1 an)':
			return $elm$core$Maybe$Just(0);
		case 'Horizon court terme (entre 1 et 3 ans)':
			return $elm$core$Maybe$Just(1);
		case 'Horizon moyen terme (entre 3 et 5 ans)':
			return $elm$core$Maybe$Just(2);
		case 'Horizon long terme (> 5 ans)':
			return $elm$core$Maybe$Just(3);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$horizonInvestissementToS = function (s) {
	switch (s) {
		case 0:
			return 'Horizon très court terme (< 1 an)';
		case 1:
			return 'Horizon court terme (entre 1 et 3 ans)';
		case 2:
			return 'Horizon moyen terme (entre 3 et 5 ans)';
		default:
			return 'Horizon long terme (> 5 ans)';
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$horizonInvestissementTuples = A2(
	$elm$core$List$map,
	function (v) {
		return _Utils_Tuple2(
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$horizonInvestissementToS(v),
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$horizonInvestissementToS(v));
	},
	_List_fromArray(
		[0, 1, 2, 3]));
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initHorizonInvestissementField = function (infos) {
	return A4($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$horizonInvestissementTuples, infos, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$horizonInvestissementFromS, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$horizonInvestissementToS);
};
var $author$project$Components$Clients$APIModel$ClientNonProfessionnel = 2;
var $author$project$Components$Clients$APIModel$ClientNonProfessionnelSurOption = 3;
var $author$project$Components$Clients$APIModel$ClientProfessionnel = 0;
var $author$project$Components$Clients$APIModel$ClientProfessionnelSurOption = 1;
var $author$project$Components$Clients$APIModel$ContrepartieEligible = 4;
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$mifIITypeFromS = function (s) {
	switch (s) {
		case 'Client professionnel':
			return $elm$core$Maybe$Just(0);
		case 'Client non professionnel':
			return $elm$core$Maybe$Just(2);
		case 'Contrepartie éligible':
			return $elm$core$Maybe$Just(4);
		case 'Client professionnel sur option':
			return $elm$core$Maybe$Just(1);
		case 'Client non professionnel sur option':
			return $elm$core$Maybe$Just(3);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$mifIITypeToS = function (s) {
	switch (s) {
		case 0:
			return 'Client professionnel';
		case 2:
			return 'Client non professionnel';
		case 4:
			return 'Contrepartie éligible';
		case 1:
			return 'Client professionnel sur option';
		default:
			return 'Client non professionnel sur option';
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$mifIITypeTuples = A2(
	$elm$core$List$map,
	function (v) {
		return _Utils_Tuple2(
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$mifIITypeToS(v),
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$mifIITypeToS(v));
	},
	_List_fromArray(
		[0, 2, 4]));
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initMifIITypeField = function (infos) {
	return A4($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$mifIITypeTuples, infos, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$mifIITypeFromS, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$mifIITypeToS);
};
var $author$project$Components$Clients$APIModel$Conservation = 0;
var $author$project$Components$Clients$APIModel$Couverture = 3;
var $author$project$Components$Clients$APIModel$Croissance = 1;
var $author$project$Components$Clients$APIModel$Rendement = 2;
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$objectifInvestissementFromS = function (s) {
	switch (s) {
		case 'Conservation':
			return $elm$core$Maybe$Just(0);
		case 'Croissance':
			return $elm$core$Maybe$Just(1);
		case 'Rendement':
			return $elm$core$Maybe$Just(2);
		case 'Couverture':
			return $elm$core$Maybe$Just(3);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$objectifInvestissementToS = function (s) {
	switch (s) {
		case 0:
			return 'Conservation';
		case 1:
			return 'Croissance';
		case 2:
			return 'Rendement';
		default:
			return 'Couverture';
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$objectifInvestissementTuples = A2(
	$elm$core$List$map,
	function (v) {
		return _Utils_Tuple2(
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$objectifInvestissementToS(v),
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$objectifInvestissementToS(v));
	},
	_List_fromArray(
		[0, 1, 2, 3]));
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initObjectifInvestissementField = function (infos) {
	return A4($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$objectifInvestissementTuples, infos, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$objectifInvestissementFromS, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$objectifInvestissementToS);
};
var $author$project$Components$Clients$APIModel$Defensif = 1;
var $author$project$Components$Clients$APIModel$Dynamique = 3;
var $author$project$Components$Clients$APIModel$Equilibre = 2;
var $author$project$Components$Clients$APIModel$Offensif = 4;
var $author$project$Components$Clients$APIModel$Securitaire = 0;
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$profilRisqueInvestisseurFromS = function (s) {
	switch (s) {
		case 'Sécuritaire':
			return $elm$core$Maybe$Just(0);
		case 'Défensif':
			return $elm$core$Maybe$Just(1);
		case 'Équilibre':
			return $elm$core$Maybe$Just(2);
		case 'Dynamique':
			return $elm$core$Maybe$Just(3);
		case 'Offensif':
			return $elm$core$Maybe$Just(4);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$profilRisqueInvestisseurToS = function (s) {
	switch (s) {
		case 0:
			return 'Sécuritaire';
		case 1:
			return 'Défensif';
		case 2:
			return 'Équilibre';
		case 3:
			return 'Dynamique';
		default:
			return 'Offensif';
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$profilRisqueInvestisseurTuples = A2(
	$elm$core$List$map,
	function (v) {
		return _Utils_Tuple2(
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$profilRisqueInvestisseurToS(v),
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$profilRisqueInvestisseurToS(v));
	},
	_List_fromArray(
		[0, 1, 2, 3, 4]));
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initRisqueInvestisseurField = function (infos) {
	return A4($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$profilRisqueInvestisseurTuples, infos, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$profilRisqueInvestisseurFromS, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$profilRisqueInvestisseurToS);
};
var $author$project$Components$Clients$APIModel$Tolerance_Elevee = 2;
var $author$project$Components$Clients$APIModel$Tolerance_Faible = 0;
var $author$project$Components$Clients$APIModel$Tolerance_Moyenne = 1;
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$toleranceAuxRisquesFromS = function (s) {
	switch (s) {
		case 'Tolérance faible':
			return $elm$core$Maybe$Just(0);
		case 'Tolérance moyenne':
			return $elm$core$Maybe$Just(1);
		case 'Tolérance élevée':
			return $elm$core$Maybe$Just(2);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$toleranceAuxRisquesToS = function (s) {
	switch (s) {
		case 0:
			return 'Tolérance faible';
		case 1:
			return 'Tolérance moyenne';
		default:
			return 'Tolérance élevée';
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$toleranceAuxRisquesTuples = A2(
	$elm$core$List$map,
	function (v) {
		return _Utils_Tuple2(
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$toleranceAuxRisquesToS(v),
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$toleranceAuxRisquesToS(v));
	},
	_List_fromArray(
		[0, 1, 2]));
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initToleranceAuxRisquesField = function (infos) {
	return A4($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$toleranceAuxRisquesTuples, infos, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$toleranceAuxRisquesFromS, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$toleranceAuxRisquesToS);
};
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initProfilInvestisseurForm = function (extSync) {
	return $author$project$Perfimmo$Form$FormModel$OptionalBlock(
		{
			f1: $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initCapaciteSubirPertesField(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			ea: A2(
				$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ClassificationMifIIForm,
				$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initMifIITypeField(
					A2(
						$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
						extSync,
						_List_fromArray(
							[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
				A2(
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
					$elm$core$Maybe$Nothing,
					A2(
						$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
						extSync,
						_List_fromArray(
							[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory])))),
			gg: $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initExperienceFinanciereField(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			gt: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			gw: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)),
			gx: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync, extSync, _List_Nil)),
			gA: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync, extSync, _List_Nil)),
			gB: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync, extSync, _List_Nil)),
			hz: $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initHorizonInvestissementField(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			it: $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initObjectifInvestissementField(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			ji: $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initRisqueInvestisseurField(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			km: $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initToleranceAuxRisquesField(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory])))
		});
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$SituationFamilialeForm = F4(
	function (situationFamille, conjointPartenaire, nombreEnfantACharge, enfants) {
		return {gf: conjointPartenaire, gX: enfants, ij: nombreEnfantACharge, jT: situationFamille};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$FormInt = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$init = function (infos) {
	return A3(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$FormInt,
		$elm$core$Maybe$Nothing,
		'',
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$initFormFieldInfos(infos));
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ReferencedClientForm = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ReferencedPersonParamsForm = F3(
	function (client, dateMariage, regimeMatrimoniale) {
		return {ga: client, c0: dateMariage, dy: regimeMatrimoniale};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$FormDatalist = $elm$core$Basics$identity;
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$ChosenItem = function (a) {
	return {$: 0, a: a};
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$InputSearch = function (a) {
	return {$: 1, a: a};
};
var $elm_community$maybe_extra$Maybe$Extra$orElse = F2(
	function (ma, mb) {
		if (mb.$ === 1) {
			return ma;
		} else {
			return mb;
		}
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$builder = F2(
	function (values, f) {
		return function (raw) {
			return A2(
				$elm_community$maybe_extra$Maybe$Extra$orElse,
				$elm$core$Maybe$Just(
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$InputSearch(raw)),
				A2(
					$elm$core$Maybe$map,
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$ChosenItem,
					A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						f,
						A2(
							$elm_community$list_extra$List$Extra$find,
							$elm$core$Basics$eq(raw),
							values))));
		};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$toStringV = function (f) {
	return function (state) {
		if (!state.$) {
			var x = state.a;
			return f(x);
		} else {
			var x = state.a;
			return '';
		}
	};
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$init = F4(
	function (values, infos, stringBuilder, toString) {
		var tupledValues = A2(
			$elm$core$List$map,
			function (x) {
				return _Utils_Tuple2(x, x);
			},
			values);
		var dlToSring = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$toStringV(toString);
		var dlFromStringBuilder = A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$builder, values, stringBuilder);
		return A4($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init, tupledValues, infos, dlFromStringBuilder, dlToSring);
	});
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initReferencedConjointPartenaireDatalist = F2(
	function (extSync, clients) {
		return A4(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$init,
			clients,
			A2(
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
				extSync,
				_List_fromArray(
					[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory])),
			$elm$core$Maybe$Just,
			$elm$core$Basics$identity);
	});
var $author$project$Components$Clients$APIModel$CommunauteDeMeublesEtAcquets = 5;
var $author$project$Components$Clients$APIModel$CommunauteReduiteAuxAcquets = 0;
var $author$project$Components$Clients$APIModel$CommunauteUniverselle = 3;
var $author$project$Components$Clients$APIModel$CommunauteUniverselleIntegrale = 4;
var $author$project$Components$Clients$APIModel$ParticipationAuxAcquets = 2;
var $author$project$Components$Clients$APIModel$ParticipationOptionnelleAuxAcquets = 6;
var $author$project$Components$Clients$APIModel$RegimeEtranger = 8;
var $author$project$Components$Clients$APIModel$SeparationDesBiens = 1;
var $author$project$Components$Clients$APIModel$SeparationDesBiensAvecSocieteAcquets = 7;
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$regimeMatrimonialeFromS = function (s) {
	switch (s) {
		case 'Communauté':
			return $elm$core$Maybe$Just(0);
		case 'Séparation des biens':
			return $elm$core$Maybe$Just(1);
		case 'Participation aux acquets':
			return $elm$core$Maybe$Just(2);
		case 'Communauté universelle':
			return $elm$core$Maybe$Just(3);
		case 'Communauté universelle intégrale':
			return $elm$core$Maybe$Just(4);
		case 'Communauté de meubles et acquets':
			return $elm$core$Maybe$Just(5);
		case 'Participation optionnelle aux acquêts':
			return $elm$core$Maybe$Just(6);
		case 'Séparation des biens avec société d\'acquets':
			return $elm$core$Maybe$Just(7);
		case 'Régime étranger':
			return $elm$core$Maybe$Just(8);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$regimeMatrimonialeToS = function (s) {
	switch (s) {
		case 0:
			return 'Communauté';
		case 1:
			return 'Séparation des biens';
		case 2:
			return 'Participation aux acquets';
		case 3:
			return 'Communauté universelle';
		case 4:
			return 'Communauté universelle intégrale';
		case 5:
			return 'Communauté de meubles et acquets';
		case 6:
			return 'Participation optionnelle aux acquêts';
		case 7:
			return 'Séparation des biens avec société d\'acquets';
		default:
			return 'Régime étranger';
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$regimeMatrimonialeTuples = A2(
	$elm$core$List$map,
	function (v) {
		return _Utils_Tuple2(
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$regimeMatrimonialeToS(v),
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$regimeMatrimonialeToS(v));
	},
	_List_fromArray(
		[0, 1, 2, 3, 4, 5, 6, 7, 8]));
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initRegimeMatrimonialeField = function (infos) {
	return A4($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$regimeMatrimonialeTuples, infos, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$regimeMatrimonialeFromS, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$regimeMatrimonialeToS);
};
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initReferencedConjointParamsForm = F2(
	function (extSync, clients) {
		return A3(
			$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ReferencedPersonParamsForm,
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initReferencedConjointPartenaireDatalist, extSync, clients),
			A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)),
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initRegimeMatrimonialeField(
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)));
	});
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initConjointPartenaireForm = F2(
	function (extSync, clients) {
		return $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ReferencedClientForm(
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initReferencedConjointParamsForm, extSync, clients));
	});
var $author$project$Components$Clients$APIModel$Celibataire = 0;
var $author$project$Components$Clients$APIModel$Divorce = 3;
var $author$project$Components$Clients$APIModel$Marie = 1;
var $author$project$Components$Clients$APIModel$NonDefinie = 7;
var $author$project$Components$Clients$APIModel$Pacse = 6;
var $author$project$Components$Clients$APIModel$Separe = 2;
var $author$project$Components$Clients$APIModel$UnionLibre = 5;
var $author$project$Components$Clients$APIModel$Veuf = 4;
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$situationFamileFromS = function (s) {
	switch (s) {
		case 'Célibataire':
			return $elm$core$Maybe$Just(0);
		case 'Marié(e)':
			return $elm$core$Maybe$Just(1);
		case 'Separé(e)':
			return $elm$core$Maybe$Just(2);
		case 'Divorcé(e)':
			return $elm$core$Maybe$Just(3);
		case 'Veuf(ve)':
			return $elm$core$Maybe$Just(4);
		case 'En union libre':
			return $elm$core$Maybe$Just(5);
		case 'Pacsé(e)':
			return $elm$core$Maybe$Just(6);
		case 'Non définie':
			return $elm$core$Maybe$Just(7);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$situationFamilleToS = function (s) {
	switch (s) {
		case 0:
			return 'Célibataire';
		case 1:
			return 'Marié(e)';
		case 2:
			return 'Separé(e)';
		case 3:
			return 'Divorcé(e)';
		case 4:
			return 'Veuf(ve)';
		case 5:
			return 'En union libre';
		case 6:
			return 'Pacsé(e)';
		default:
			return 'Non définie';
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$situationFamilleTuples = A2(
	$elm$core$List$map,
	function (v) {
		return _Utils_Tuple2(
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$situationFamilleToS(v),
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$situationFamilleToS(v));
	},
	_List_fromArray(
		[0, 1, 2, 3, 4, 5, 6, 7]));
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initSituationFamilleField = function (infos) {
	return A4($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$situationFamilleTuples, infos, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$situationFamileFromS, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$situationFamilleToS);
};
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initSitFamForm = F2(
	function (extSync, clients) {
		return $author$project$Perfimmo$Form$FormModel$OptionalBlock(
			A4(
				$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$SituationFamilialeForm,
				$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initSituationFamilleField(
					A2(
						$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
						extSync,
						_List_fromArray(
							[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initConjointPartenaireForm, extSync, clients),
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$init(
					A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync, extSync, _List_Nil)),
				_List_Nil));
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleForm = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ActifAnnuelForm = F3(
	function (brut, financier, nonFinancier) {
		return {cR: brut, da: financier, im: nonFinancier};
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$BilanForm = F3(
	function (actifAnnuel, passifAnnuel, capitauxPropres) {
		return {cM: actifAnnuel, f3: capitauxPropres, iC: passifAnnuel};
	});
var $author$project$Perfimmo$Form$FormModel$MandatoryBlock = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$B_ActifAnnuel = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$APIModel$Bilan = F3(
	function (actifAnnuel, passifAnnuel, capitauxPropres) {
		return {cM: actifAnnuel, f3: capitauxPropres, iC: passifAnnuel};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CapitauxPropres = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PassifAnnuel = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$APIModel$ActifAnnuel = F3(
	function (brut, financier, nonFinancier) {
		return {cR: brut, da: financier, im: nonFinancier};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Brut = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Financier = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NonFinancier = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Result$fromMaybe = F2(
	function (err, maybe) {
		if (!maybe.$) {
			var v = maybe.a;
			return $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(err);
		}
	});
var $author$project$Components$Clients$ResultUtils$map3C = F4(
	function (f, r1, r2, r3) {
		var _v0 = _Utils_Tuple3(r1, r2, r3);
		if (_v0.a.$ === 1) {
			if (_v0.b.$ === 1) {
				if (_v0.c.$ === 1) {
					var e1 = _v0.a.a;
					var e2 = _v0.b.a;
					var e3 = _v0.c.a;
					return $elm$core$Result$Err(
						_List_fromArray(
							[e1, e2, e3]));
				} else {
					var e1 = _v0.a.a;
					var e2 = _v0.b.a;
					return $elm$core$Result$Err(
						_List_fromArray(
							[e1, e2]));
				}
			} else {
				if (_v0.c.$ === 1) {
					var e1 = _v0.a.a;
					var e3 = _v0.c.a;
					return $elm$core$Result$Err(
						_List_fromArray(
							[e1, e3]));
				} else {
					var e = _v0.a.a;
					return $elm$core$Result$Err(
						_List_fromArray(
							[e]));
				}
			}
		} else {
			if (_v0.b.$ === 1) {
				if (_v0.c.$ === 1) {
					var e2 = _v0.b.a;
					var e3 = _v0.c.a;
					return $elm$core$Result$Err(
						_List_fromArray(
							[e2, e3]));
				} else {
					var e = _v0.b.a;
					return $elm$core$Result$Err(
						_List_fromArray(
							[e]));
				}
			} else {
				if (_v0.c.$ === 1) {
					var e = _v0.c.a;
					return $elm$core$Result$Err(
						_List_fromArray(
							[e]));
				} else {
					var x = _v0.a.a;
					var y = _v0.b.a;
					var z = _v0.c.a;
					return $elm$core$Result$Ok(
						A3(f, x, y, z));
				}
			}
		}
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat = function (_v0) {
	var _float = _v0.a;
	return _float;
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$actifAnnuelToApi = function (form) {
	var nonFinancier = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NonFinancier(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.im));
	var financier = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Financier(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.da));
	var brut = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Brut(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.cR));
	return A4(
		$author$project$Components$Clients$ResultUtils$map3C,
		F3(
			function (b, f, nf) {
				return A3($author$project$Components$Clients$APIModel$ActifAnnuel, b, f, nf);
			}),
		brut,
		financier,
		nonFinancier);
};
var $elm_community$result_extra$Result$Extra$isErr = function (x) {
	if (!x.$) {
		return false;
	} else {
		return true;
	}
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$bilanToApi = function (form) {
	var passifAnnuel = A2(
		$elm$core$Result$fromMaybe,
		_List_fromArray(
			[
				$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PassifAnnuel('')
			]),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.iC));
	var capitauxPropres = A2(
		$elm$core$Result$fromMaybe,
		_List_fromArray(
			[
				$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CapitauxPropres('')
			]),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.f3));
	var actifAnnuel = A2(
		$elm$core$Result$mapError,
		$elm$core$List$map($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$B_ActifAnnuel),
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$actifAnnuelToApi(form.cM));
	var emptyBlock = _Utils_eq(
		A2($elm$core$Result$mapError, $elm$core$List$length, actifAnnuel),
		$elm$core$Result$Err(3)) && ($elm_community$result_extra$Result$Extra$isErr(passifAnnuel) && $elm_community$result_extra$Result$Extra$isErr(capitauxPropres));
	var result = A2(
		$elm$core$Result$mapError,
		$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
		A4(
			$author$project$Components$Clients$ResultUtils$map3C,
			F3(
				function (actif, passif, capitaux) {
					return $elm$core$Maybe$Just(
						A3($author$project$Components$Clients$APIModel$Bilan, actif, passif, capitaux));
				}),
			actifAnnuel,
			passifAnnuel,
			capitauxPropres));
	return emptyBlock ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : result;
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculBilanBlockState = function (bilan) {
	var _v0 = $author$project$Components$Clients$Detail$UpdateForm$FormValidator$bilanToApi(bilan);
	if (!_v0.$) {
		if (!_v0.a.$) {
			return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(bilan);
		} else {
			var _v1 = _v0.a;
			return $author$project$Perfimmo$Form$FormModel$OptionalBlock(bilan);
		}
	} else {
		return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(bilan);
	}
};
var $elm$core$String$fromFloat = _String_fromNumber;
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setOptionalValue = F2(
	function (maybeFloat, _v0) {
		var infos = _v0.c;
		return A3(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$FormFloat,
			maybeFloat,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2($elm$core$Maybe$map, $elm$core$String$fromFloat, maybeFloat)),
			infos);
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue = function (_float) {
	return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setOptionalValue(
		$elm$core$Maybe$Just(_float));
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$bilanFromApi = F2(
	function (bilandInit, b) {
		return $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculBilanBlockState(
			A3(
				$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$BilanForm,
				A3(
					$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ActifAnnuelForm,
					A3(
						$elm$core$Basics$apR,
						A2(
							$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.cR;
								},
								$elm$core$Maybe$map($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue)),
							b.cM),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						bilandInit.cM.cR),
					A3(
						$elm$core$Basics$apR,
						A2(
							$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.da;
								},
								$elm$core$Maybe$map($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue)),
							b.cM),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						bilandInit.cM.da),
					A3(
						$elm$core$Basics$apR,
						A2(
							$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.im;
								},
								$elm$core$Maybe$map($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue)),
							b.cM),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						bilandInit.cM.im)),
				A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue, b.iC),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					bilandInit.iC),
				A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue, b.f3),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					bilandInit.f3)));
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$CompteResultatForm = F3(
	function (produitsAnnuels, charges_annuelles, resultat_annuel) {
		return {f6: charges_annuelles, jd: produitsAnnuels, jw: resultat_annuel};
	});
var $author$project$Components$Clients$APIModel$CompteResultat = F3(
	function (produitsAnnuels, charges_annuelles, resultat_annuel) {
		return {f6: charges_annuelles, jd: produitsAnnuels, jw: resultat_annuel};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PM_ChargesAnnuelles = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProduitsAnnuels = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ResultatAnnuel = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$compteResultToApi = function (form) {
	var resultat = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ResultatAnnuel(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.jw));
	var produits = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProduitsAnnuels(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.jd));
	var charges = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PM_ChargesAnnuelles(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.f6));
	var emptyBlock = $elm_community$result_extra$Result$Extra$isErr(produits) && ($elm_community$result_extra$Result$Extra$isErr(charges) && $elm_community$result_extra$Result$Extra$isErr(resultat));
	var result = A4(
		$author$project$Components$Clients$ResultUtils$map3C,
		F3(
			function (p, c, r) {
				return $elm$core$Maybe$Just(
					A3($author$project$Components$Clients$APIModel$CompteResultat, p, c, r));
			}),
		produits,
		charges,
		resultat);
	return emptyBlock ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : result;
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculCompteResultatBlockState = function (compte) {
	var _v0 = $author$project$Components$Clients$Detail$UpdateForm$FormValidator$compteResultToApi(compte);
	if (!_v0.$) {
		if (!_v0.a.$) {
			return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(compte);
		} else {
			var _v1 = _v0.a;
			return $author$project$Perfimmo$Form$FormModel$OptionalBlock(compte);
		}
	} else {
		return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(compte);
	}
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$compteResultatFromApi = F2(
	function (compteResultInit, c) {
		return $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculCompteResultatBlockState(
			A3(
				$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$CompteResultatForm,
				A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue, c.jd),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					compteResultInit.jd),
				A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue, c.f6),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					compteResultInit.f6),
				A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue, c.jw),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					compteResultInit.jw)));
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$FiscaliteForm = F2(
	function (tauxImposition, montantsImpots) {
		return {h9: montantsImpots, kc: tauxImposition};
	});
var $author$project$Components$Clients$APIModel$Fiscalite = F2(
	function (tauxImposition, montantsImpots) {
		return {h9: montantsImpots, kc: tauxImposition};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$MontantsImpots = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$TauxImposition = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$ResultUtils$map2C = F3(
	function (f, r1, r2) {
		var _v0 = _Utils_Tuple2(r1, r2);
		if (_v0.a.$ === 1) {
			if (_v0.b.$ === 1) {
				var e1 = _v0.a.a;
				var e2 = _v0.b.a;
				return $elm$core$Result$Err(
					_List_fromArray(
						[e1, e2]));
			} else {
				var e1 = _v0.a.a;
				return $elm$core$Result$Err(
					_List_fromArray(
						[e1]));
			}
		} else {
			if (_v0.b.$ === 1) {
				var e2 = _v0.b.a;
				return $elm$core$Result$Err(
					_List_fromArray(
						[e2]));
			} else {
				var x = _v0.a.a;
				var y = _v0.b.a;
				return $elm$core$Result$Ok(
					A2(f, x, y));
			}
		}
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$toFloat = function (_v0) {
	var v = _v0.a;
	return v;
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$fiscaliteToApi = function (form) {
	var tauxImposition = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$TauxImposition(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$toFloat(form.kc));
	var montantsImpots = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$MontantsImpots(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.h9));
	var result = A3(
		$author$project$Components$Clients$ResultUtils$map2C,
		F2(
			function (taux, montant) {
				return $elm$core$Maybe$Just(
					A2($author$project$Components$Clients$APIModel$Fiscalite, taux, montant));
			}),
		tauxImposition,
		montantsImpots);
	var emptyBlock = $elm_community$result_extra$Result$Extra$isErr(tauxImposition) && $elm_community$result_extra$Result$Extra$isErr(montantsImpots);
	return emptyBlock ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : result;
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculFiscaliteBlockState = function (fisc) {
	var _v0 = $author$project$Components$Clients$Detail$UpdateForm$FormValidator$fiscaliteToApi(fisc);
	if (!_v0.$) {
		if (!_v0.a.$) {
			return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(fisc);
		} else {
			var _v1 = _v0.a;
			return $author$project$Perfimmo$Form$FormModel$OptionalBlock(fisc);
		}
	} else {
		return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(fisc);
	}
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$testPourcent = F3(
	function (raw, _v0, _float) {
		var oldFloat = _v0.a;
		var oldRaw = _v0.b;
		var info = _v0.c;
		return ((0 <= _float) && (_float <= 100)) ? A3(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$FormPourcent,
			$elm$core$Maybe$Just(_float),
			raw,
			info) : A3($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$FormPourcent, oldFloat, oldRaw, info);
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setValue = F2(
	function (_float, form) {
		return A3(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$testPourcent,
			$elm$core$String$fromFloat(_float),
			form,
			_float);
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$fiscaliteFromApi = F2(
	function (fiscaliteInit, f) {
		return $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculFiscaliteBlockState(
			A2(
				$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$FiscaliteForm,
				A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setValue, f.kc),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					fiscaliteInit.kc),
				A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue, f.h9),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					fiscaliteInit.h9)));
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Data$OfficialDataRef$formeJuridiquesTuples = _List_fromArray(
	[
		_Utils_Tuple2('0000', 'Organisme de placement collectif en valeurs mobilières sans personnalité morale'),
		_Utils_Tuple2('1000', 'Entrepreneur individuel'),
		_Utils_Tuple2('2110', 'Indivision entre personnes physiques'),
		_Utils_Tuple2('2120', 'Indivision avec personne morale'),
		_Utils_Tuple2('2210', 'Société créée de fait entre personnes physiques'),
		_Utils_Tuple2('2220', 'Société créée de fait avec personne morale'),
		_Utils_Tuple2('2310', 'Société en participation entre personnes physiques'),
		_Utils_Tuple2('2320', 'Société en participation avec personne morale'),
		_Utils_Tuple2('2385', 'Société en participation de professions libérales'),
		_Utils_Tuple2('2400', 'Fiducie'),
		_Utils_Tuple2('2700', 'Paroisse hors zone concordataire'),
		_Utils_Tuple2('2800', 'Assujetti unique à la TVA'),
		_Utils_Tuple2('2900', 'Autre groupement de droit privé non doté de la personnalité morale'),
		_Utils_Tuple2('3110', 'Représentation ou agence commerciale d\'état ou organisme public étranger immatriculé au RCS'),
		_Utils_Tuple2('3120', 'Société commerciale étrangère immatriculée au RCS'),
		_Utils_Tuple2('3205', 'Organisation internationale'),
		_Utils_Tuple2('3210', 'État, collectivité ou établissement public étranger'),
		_Utils_Tuple2('3220', 'Société étrangère non immatriculée au RCS'),
		_Utils_Tuple2('3290', 'Autre personne morale de droit étranger'),
		_Utils_Tuple2('4110', 'Établissement public national à caractère industriel ou commercial doté d\'un comptable public'),
		_Utils_Tuple2('4120', 'Établissement public national à caractère industriel ou commercial non doté d\'un comptable public'),
		_Utils_Tuple2('4130', 'Exploitant public'),
		_Utils_Tuple2('4140', 'Établissement public local à caractère industriel ou commercial'),
		_Utils_Tuple2('4150', 'Régie d\'une collectivité locale à caractère industriel ou commercial'),
		_Utils_Tuple2('4160', 'Institution Banque de France'),
		_Utils_Tuple2('5191', 'Société de caution mutuelle'),
		_Utils_Tuple2('5192', 'Société coopérative de banque populaire'),
		_Utils_Tuple2('5193', 'Caisse de crédit maritime mutuel'),
		_Utils_Tuple2('5194', 'Caisse (fédérale) de crédit mutuel'),
		_Utils_Tuple2('5195', 'Association coopérative inscrite (droit local Alsace Moselle)'),
		_Utils_Tuple2('5196', 'Caisse d\'épargne et de prévoyance à forme coopérative'),
		_Utils_Tuple2('5202', 'Société en nom collectif'),
		_Utils_Tuple2('5203', 'Société en nom collectif coopérative'),
		_Utils_Tuple2('5306', 'Société en commandite simple'),
		_Utils_Tuple2('5307', 'Société en commandite simple coopérative'),
		_Utils_Tuple2('5308', 'Société en commandite par actions'),
		_Utils_Tuple2('5309', 'Société en commandite par actions coopérative'),
		_Utils_Tuple2('5310', 'Société en libre partenariat (SLP)'),
		_Utils_Tuple2('5370', 'Société de Participations Financières de Profession Libérale Société en commandite par actions (SPFPL SCA)'),
		_Utils_Tuple2('5385', 'Société d\'exercice libéral en commandite par actions'),
		_Utils_Tuple2('5410', 'SARL nationale'),
		_Utils_Tuple2('5415', 'SARL d\'économie mixte'),
		_Utils_Tuple2('5422', 'SARL immobilière pour le commerce et l\'industrie (SICOMI)'),
		_Utils_Tuple2('5426', 'SARL immobilière de gestion'),
		_Utils_Tuple2('5430', 'SARL d\'aménagement foncier et d\'équipement rural (SAFER)'),
		_Utils_Tuple2('5431', 'SARL mixte d\'intérêt agricole (SMIA)'),
		_Utils_Tuple2('5432', 'SARL d\'intérêt collectif agricole (SICA)'),
		_Utils_Tuple2('5442', 'SARL d\'attribution'),
		_Utils_Tuple2('5443', 'SARL coopérative de construction'),
		_Utils_Tuple2('5451', 'SARL coopérative de consommation'),
		_Utils_Tuple2('5453', 'SARL coopérative artisanale'),
		_Utils_Tuple2('5454', 'SARL coopérative d\'intérêt maritime'),
		_Utils_Tuple2('5455', 'SARL coopérative de transport'),
		_Utils_Tuple2('5458', 'SARL coopérative de production (SCOP)'),
		_Utils_Tuple2('5459', 'SARL union de sociétés coopératives'),
		_Utils_Tuple2('5460', 'Autre SARL coopérative'),
		_Utils_Tuple2('5470', 'Société de Participations Financières de Profession Libérale Société à responsabilité limitée (SPFPL SARL)'),
		_Utils_Tuple2('5485', 'Société d\'exercice libéral à responsabilité limitée'),
		_Utils_Tuple2('5499', 'Société à responsabilité limitée (sans autre indication)'),
		_Utils_Tuple2('5505', 'SA à participation ouvrière à conseil d\'administration'),
		_Utils_Tuple2('5510', 'SA nationale à conseil d\'administration'),
		_Utils_Tuple2('5515', 'SA d\'économie mixte à conseil d\'administration'),
		_Utils_Tuple2('5520', 'Fonds à forme sociétale à conseil d\'administration'),
		_Utils_Tuple2('5522', 'SA immobilière pour le commerce et l\'industrie (SICOMI) à conseil d\'administration'),
		_Utils_Tuple2('5525', 'SA immobilière d\'investissement à conseil d\'administration'),
		_Utils_Tuple2('5530', 'SA d\'aménagement foncier et d\'équipement rural (SAFER) à conseil d\'administration'),
		_Utils_Tuple2('5531', 'Société anonyme mixte d\'intérêt agricole (SMIA) à conseil d\'administration'),
		_Utils_Tuple2('5532', 'SA d\'intérêt collectif agricole (SICA) à conseil d\'administration'),
		_Utils_Tuple2('5542', 'SA d\'attribution à conseil d\'administration'),
		_Utils_Tuple2('5543', 'SA coopérative de construction à conseil d\'administration'),
		_Utils_Tuple2('5546', 'SA de HLM à conseil d\'administration'),
		_Utils_Tuple2('5547', 'SA coopérative de production de HLM à conseil d\'administration'),
		_Utils_Tuple2('5548', 'SA de crédit immobilier à conseil d\'administration'),
		_Utils_Tuple2('5551', 'SA coopérative de consommation à conseil d\'administration'),
		_Utils_Tuple2('5552', 'SA coopérative de commerçants-détaillants à conseil d\'administration'),
		_Utils_Tuple2('5553', 'SA coopérative artisanale à conseil d\'administration'),
		_Utils_Tuple2('5554', 'SA coopérative (d\'intérêt) maritime à conseil d\'administration'),
		_Utils_Tuple2('5555', 'SA coopérative de transport à conseil d\'administration'),
		_Utils_Tuple2('5558', 'SA coopérative de production  (SCOP) à conseil d\'administration'),
		_Utils_Tuple2('5559', 'SA union de sociétés coopératives à conseil d\'administration'),
		_Utils_Tuple2('5560', 'Autre SA coopérative à conseil d\'administration'),
		_Utils_Tuple2('5570', 'Société de Participations Financières de Profession Libérale Société anonyme à conseil d\'administration (SPFPL SA à conseil d\'administration)'),
		_Utils_Tuple2('5585', 'Société d\'exercice libéral à forme anonyme à conseil d\'administration'),
		_Utils_Tuple2('5599', 'SA à conseil d\'administration (s.a.i.)'),
		_Utils_Tuple2('5605', 'SA à participation ouvrière à directoire'),
		_Utils_Tuple2('5610', 'SA nationale à directoire'),
		_Utils_Tuple2('5615', 'SA d\'économie mixte à directoire'),
		_Utils_Tuple2('5620', 'Fonds à forme sociétale à directoire'),
		_Utils_Tuple2('5622', 'SA immobilière pour le commerce et l\'industrie (SICOMI) à directoire'),
		_Utils_Tuple2('5625', 'SA immobilière d\'investissement à directoire'),
		_Utils_Tuple2('5630', 'Safer anonyme à directoire'),
		_Utils_Tuple2('5631', 'SA mixte d\'intérêt agricole (SMIA)'),
		_Utils_Tuple2('5632', 'SA d\'intérêt collectif agricole (SICA)'),
		_Utils_Tuple2('5642', 'SA d\'attribution à directoire'),
		_Utils_Tuple2('5643', 'SA coopérative de construction à directoire'),
		_Utils_Tuple2('5646', 'SA de HLM à directoire'),
		_Utils_Tuple2('5647', 'Société coopérative de production de HLM anonyme à directoire'),
		_Utils_Tuple2('5648', 'SA de crédit immobilier à directoire'),
		_Utils_Tuple2('5651', 'SA coopérative de consommation à directoire'),
		_Utils_Tuple2('5652', 'SA coopérative de commerçants-détaillants à directoire'),
		_Utils_Tuple2('5653', 'SA coopérative artisanale à directoire'),
		_Utils_Tuple2('5654', 'SA coopérative d\'intérêt maritime à directoire'),
		_Utils_Tuple2('5655', 'SA coopérative de transport à directoire'),
		_Utils_Tuple2('5658', 'SA coopérative de production (SCOP) à directoire'),
		_Utils_Tuple2('5659', 'SA union de sociétés coopératives à directoire'),
		_Utils_Tuple2('5660', 'Autre SA coopérative à directoire'),
		_Utils_Tuple2('5670', 'Société de Participations Financières de Profession Libérale Société anonyme à Directoire (SPFPL SA à directoire)'),
		_Utils_Tuple2('5685', 'Société d\'exercice libéral à forme anonyme à directoire'),
		_Utils_Tuple2('5699', 'SA à directoire (s.a.i.)'),
		_Utils_Tuple2('5710', 'SAS, société par actions simplifiée'),
		_Utils_Tuple2('5770', 'Société de Participations Financières de Profession Libérale Société par actions simplifiée (SPFPL SAS)'),
		_Utils_Tuple2('5785', 'Société d\'exercice libéral par action simplifiée'),
		_Utils_Tuple2('5800', 'Société européenne'),
		_Utils_Tuple2('6100', 'Caisse d\'Épargne et de Prévoyance'),
		_Utils_Tuple2('6210', 'Groupement européen d\'intérêt économique (GEIE)'),
		_Utils_Tuple2('6220', 'Groupement d\'intérêt économique (GIE)'),
		_Utils_Tuple2('6316', 'Coopérative d\'utilisation de matériel agricole en commun (CUMA)'),
		_Utils_Tuple2('6317', 'Société coopérative agricole'),
		_Utils_Tuple2('6318', 'Union de sociétés coopératives agricoles'),
		_Utils_Tuple2('6411', 'Société d\'assurance à forme mutuelle'),
		_Utils_Tuple2('6511', 'Sociétés Interprofessionnelles de Soins Ambulatoires '),
		_Utils_Tuple2('6521', 'Société civile de placement collectif immobilier (SCPI)'),
		_Utils_Tuple2('6532', 'Société civile d\'intérêt collectif agricole (SICA)'),
		_Utils_Tuple2('6533', 'Groupement agricole d\'exploitation en commun (GAEC)'),
		_Utils_Tuple2('6534', 'Groupement foncier agricole'),
		_Utils_Tuple2('6535', 'Groupement agricole foncier'),
		_Utils_Tuple2('6536', 'Groupement forestier'),
		_Utils_Tuple2('6537', 'Groupement pastoral'),
		_Utils_Tuple2('6538', 'Groupement foncier et rural'),
		_Utils_Tuple2('6539', 'Société civile foncière'),
		_Utils_Tuple2('6540', 'Société civile immobilière'),
		_Utils_Tuple2('6541', 'Société civile immobilière de construction-vente'),
		_Utils_Tuple2('6542', 'Société civile d\'attribution'),
		_Utils_Tuple2('6543', 'Société civile coopérative de construction'),
		_Utils_Tuple2('6544', 'Société civile immobilière d\' accession progressive à la propriété'),
		_Utils_Tuple2('6551', 'Société civile coopérative de consommation'),
		_Utils_Tuple2('6554', 'Société civile coopérative d\'intérêt maritime'),
		_Utils_Tuple2('6558', 'Société civile coopérative entre médecins'),
		_Utils_Tuple2('6560', 'Autre société civile coopérative'),
		_Utils_Tuple2('6561', 'SCP d\'avocats'),
		_Utils_Tuple2('6562', 'SCP d\'avocats aux conseils'),
		_Utils_Tuple2('6563', 'SCP d\'avoués d\'appel'),
		_Utils_Tuple2('6564', 'SCP d\'huissiers'),
		_Utils_Tuple2('6565', 'SCP de notaires'),
		_Utils_Tuple2('6566', 'SCP de commissaires-priseurs'),
		_Utils_Tuple2('6567', 'SCP de greffiers de tribunal de commerce'),
		_Utils_Tuple2('6568', 'SCP de conseils juridiques'),
		_Utils_Tuple2('6569', 'SCP de commissaires aux comptes'),
		_Utils_Tuple2('6571', 'SCP de médecins'),
		_Utils_Tuple2('6572', 'SCP de dentistes'),
		_Utils_Tuple2('6573', 'SCP d\'infirmiers'),
		_Utils_Tuple2('6574', 'SCP de masseurs-kinésithérapeutes'),
		_Utils_Tuple2('6575', 'SCP de directeurs de laboratoire d\'analyse médicale'),
		_Utils_Tuple2('6576', 'SCP de vétérinaires'),
		_Utils_Tuple2('6577', 'SCP de géomètres experts'),
		_Utils_Tuple2('6578', 'SCP d\'architectes'),
		_Utils_Tuple2('6585', 'Autre société civile professionnelle'),
		_Utils_Tuple2('6589', 'Société civile de moyens'),
		_Utils_Tuple2('6595', 'Caisse locale de crédit mutuel'),
		_Utils_Tuple2('6596', 'Caisse de crédit agricole mutuel'),
		_Utils_Tuple2('6597', 'Société civile d\'exploitation agricole'),
		_Utils_Tuple2('6598', 'Exploitation agricole à responsabilité limitée'),
		_Utils_Tuple2('6599', 'Autre société civile'),
		_Utils_Tuple2('6901', 'Autre personne de droit privé inscrite au registre du commerce et des sociétés'),
		_Utils_Tuple2('7111', 'Autorité constitutionnelle'),
		_Utils_Tuple2('7112', 'Autorité administrative ou publique indépendante'),
		_Utils_Tuple2('7113', 'Ministère'),
		_Utils_Tuple2('7120', 'Service central d\'un ministère'),
		_Utils_Tuple2('7150', 'Service du ministère de la Défense'),
		_Utils_Tuple2('7160', 'Service déconcentré à compétence nationale d\'un ministère (hors Défense)'),
		_Utils_Tuple2('7171', 'Service déconcentré de l\'État à compétence (inter) régionale'),
		_Utils_Tuple2('7172', 'Service déconcentré de l\'État à compétence (inter) départementale'),
		_Utils_Tuple2('7179', '(Autre) Service déconcentré de l\'État à compétence territoriale'),
		_Utils_Tuple2('7190', 'Ecole nationale non dotée de la personnalité morale'),
		_Utils_Tuple2('7210', 'Commune et commune nouvelle'),
		_Utils_Tuple2('7220', 'Département'),
		_Utils_Tuple2('7225', 'Collectivité et territoire d\'Outre Mer'),
		_Utils_Tuple2('7229', '(Autre) Collectivité territoriale'),
		_Utils_Tuple2('7230', 'Région'),
		_Utils_Tuple2('7312', 'Commune associée et commune déléguée'),
		_Utils_Tuple2('7313', 'Section de commune'),
		_Utils_Tuple2('7314', 'Ensemble urbain'),
		_Utils_Tuple2('7321', 'Association syndicale autorisée'),
		_Utils_Tuple2('7322', 'Association foncière urbaine'),
		_Utils_Tuple2('7323', 'Association foncière de remembrement'),
		_Utils_Tuple2('7331', 'Établissement public local d\'enseignement'),
		_Utils_Tuple2('7340', 'Pôle métropolitain'),
		_Utils_Tuple2('7341', 'Secteur de commune'),
		_Utils_Tuple2('7342', 'District urbain'),
		_Utils_Tuple2('7343', 'Communauté urbaine'),
		_Utils_Tuple2('7344', 'Métropole'),
		_Utils_Tuple2('7345', 'Syndicat intercommunal à vocation multiple (SIVOM)'),
		_Utils_Tuple2('7346', 'Communauté de communes'),
		_Utils_Tuple2('7347', 'Communauté de villes'),
		_Utils_Tuple2('7348', 'Communauté d\'agglomération'),
		_Utils_Tuple2('7349', 'Autre établissement public local de coopération non spécialisé ou entente'),
		_Utils_Tuple2('7351', 'Institution interdépartementale ou entente'),
		_Utils_Tuple2('7352', 'Institution interrégionale ou entente'),
		_Utils_Tuple2('7353', 'Syndicat intercommunal à vocation unique (SIVU)'),
		_Utils_Tuple2('7354', 'Syndicat mixte fermé'),
		_Utils_Tuple2('7355', 'Syndicat mixte ouvert'),
		_Utils_Tuple2('7356', 'Commission syndicale pour la gestion des biens indivis des communes'),
		_Utils_Tuple2('7357', 'Pôle d\'équilibre territorial et rural (PETR)'),
		_Utils_Tuple2('7361', 'Centre communal d\'action sociale'),
		_Utils_Tuple2('7362', 'Caisse des écoles'),
		_Utils_Tuple2('7363', 'Caisse de crédit municipal'),
		_Utils_Tuple2('7364', 'Établissement d\'hospitalisation'),
		_Utils_Tuple2('7365', 'Syndicat inter hospitalier'),
		_Utils_Tuple2('7366', 'Établissement public local social et médico-social'),
		_Utils_Tuple2('7367', 'Centre Intercommunal d\'action sociale (CIAS)'),
		_Utils_Tuple2('7371', 'Office public d\'habitation à loyer modéré (OPHLM)'),
		_Utils_Tuple2('7372', 'Service départemental d\'incendie et de secours (SDIS)'),
		_Utils_Tuple2('7373', 'Établissement public local culturel'),
		_Utils_Tuple2('7378', 'Régie d\'une collectivité locale à caractère administratif'),
		_Utils_Tuple2('7379', '(Autre) Établissement public administratif local'),
		_Utils_Tuple2('7381', 'Organisme consulaire'),
		_Utils_Tuple2('7382', 'Établissement public national ayant fonction d\'administration centrale'),
		_Utils_Tuple2('7383', 'Établissement public national à caractère scientifique culturel et professionnel'),
		_Utils_Tuple2('7384', 'Autre établissement public national d\'enseignement'),
		_Utils_Tuple2('7385', 'Autre établissement public national administratif à compétence territoriale limitée'),
		_Utils_Tuple2('7389', 'Établissement public national à caractère administratif'),
		_Utils_Tuple2('7410', 'Groupement d\'intérêt public (GIP)'),
		_Utils_Tuple2('7430', 'Établissement public des cultes d\'Alsace-Lorraine'),
		_Utils_Tuple2('7450', 'Etablissement public administratif, cercle et foyer dans les armées'),
		_Utils_Tuple2('7470', 'Groupement de coopération sanitaire à gestion publique'),
		_Utils_Tuple2('7490', 'Autre personne morale de droit administratif'),
		_Utils_Tuple2('8110', 'Régime général de la Sécurité Sociale'),
		_Utils_Tuple2('8120', 'Régime spécial de Sécurité Sociale'),
		_Utils_Tuple2('8130', 'Institution de retraite complémentaire'),
		_Utils_Tuple2('8140', 'Mutualité sociale agricole'),
		_Utils_Tuple2('8150', 'Régime maladie des non-salariés non agricoles'),
		_Utils_Tuple2('8160', 'Régime vieillesse ne dépendant pas du régime général de la Sécurité Sociale'),
		_Utils_Tuple2('8170', 'Régime d\'assurance chômage'),
		_Utils_Tuple2('8190', 'Autre régime de prévoyance sociale'),
		_Utils_Tuple2('8210', 'Mutuelle'),
		_Utils_Tuple2('8250', 'Assurance mutuelle agricole'),
		_Utils_Tuple2('8290', 'Autre organisme mutualiste'),
		_Utils_Tuple2('8310', 'Comité social économique d’entreprise'),
		_Utils_Tuple2('8311', 'Comité social économique d\'établissement'),
		_Utils_Tuple2('8410', 'Syndicat de salariés'),
		_Utils_Tuple2('8420', 'Syndicat patronal'),
		_Utils_Tuple2('8450', 'Ordre professionnel ou assimilé'),
		_Utils_Tuple2('8470', 'Centre technique industriel ou comité professionnel du développement économique'),
		_Utils_Tuple2('8490', 'Autre organisme professionnel'),
		_Utils_Tuple2('8510', 'Institution de prévoyance'),
		_Utils_Tuple2('8520', 'Institution de retraite supplémentaire'),
		_Utils_Tuple2('9110', 'Syndicat de copropriété'),
		_Utils_Tuple2('9150', 'Association syndicale libre'),
		_Utils_Tuple2('9210', 'Association non déclarée'),
		_Utils_Tuple2('9220', 'Association déclarée'),
		_Utils_Tuple2('9221', 'Association déclarée d\'insertion par l\'économique'),
		_Utils_Tuple2('9222', 'Association intermédiaire'),
		_Utils_Tuple2('9223', 'Groupement d\'employeurs'),
		_Utils_Tuple2('9224', 'Association d\'avocats à responsabilité professionnelle individuelle'),
		_Utils_Tuple2('9230', 'Association déclarée, reconnue d\'utilité publique'),
		_Utils_Tuple2('9240', 'Congrégation'),
		_Utils_Tuple2('9260', 'Association de droit local (Bas-Rhin, Haut-Rhin et Moselle)'),
		_Utils_Tuple2('9300', 'Fondation'),
		_Utils_Tuple2('9900', 'Autre personne morale de droit privé'),
		_Utils_Tuple2('9970', 'Groupement de coopération sanitaire à gestion privée'),
		_Utils_Tuple2('0', 'Organisme de placement collectif en valeurs mobilières sans personnalité morale'),
		_Utils_Tuple2('1', 'Entrepreneur individuel'),
		_Utils_Tuple2('2', 'Groupement de droit privé non doté de la personnalité morale'),
		_Utils_Tuple2('3', 'Personne morale de droit étranger'),
		_Utils_Tuple2('4', 'Personne morale de droit public soumise au droit commercial'),
		_Utils_Tuple2('5', 'Société commerciale'),
		_Utils_Tuple2('6', 'Autre personne morale immatriculée au RCS'),
		_Utils_Tuple2('7', 'Personne morale et organisme soumis au droit administratif'),
		_Utils_Tuple2('8', 'Organisme privé spécialisé'),
		_Utils_Tuple2('9', 'Groupement de droit privé'),
		_Utils_Tuple2('00', 'Organisme de placement collectif en valeurs mobilières sans personnalité morale'),
		_Utils_Tuple2('10', 'Entrepreneur individuel'),
		_Utils_Tuple2('21', 'Indivision'),
		_Utils_Tuple2('22', 'Société créée de fait'),
		_Utils_Tuple2('23', 'Société en participation'),
		_Utils_Tuple2('24', 'Fiducie'),
		_Utils_Tuple2('27', 'Paroisse hors zone concordataire'),
		_Utils_Tuple2('28', 'Assujetti unique à la TVA'),
		_Utils_Tuple2('29', 'Autre groupement de droit privé non doté de la personnalité morale'),
		_Utils_Tuple2('31', 'Personne morale de droit étranger, immatriculée au RCS (registre du commerce et des sociétés)'),
		_Utils_Tuple2('32', 'Personne morale de droit étranger, non immatriculée au RCS'),
		_Utils_Tuple2('41', 'Etablissement public ou régie à caractère industriel ou commercial'),
		_Utils_Tuple2('51', 'Société coopérative commerciale particulière'),
		_Utils_Tuple2('52', 'Société en nom collectif'),
		_Utils_Tuple2('53', 'Société en commandite'),
		_Utils_Tuple2('54', 'Société à responsabilité limitée (SARL)'),
		_Utils_Tuple2('55', 'Société anonyme à conseil d\'administration'),
		_Utils_Tuple2('56', 'Société anonyme à directoire'),
		_Utils_Tuple2('57', 'Société par actions simplifiée'),
		_Utils_Tuple2('58', 'Société européenne'),
		_Utils_Tuple2('61', 'Caisse d\'épargne et de prévoyance'),
		_Utils_Tuple2('62', 'Groupement d\'intérêt économique'),
		_Utils_Tuple2('63', 'Société coopérative agricole'),
		_Utils_Tuple2('64', 'Société d\'assurance mutuelle'),
		_Utils_Tuple2('65', 'Société civile'),
		_Utils_Tuple2('69', 'Autre personne morale de droit privé inscrite au registre du commerce et des sociétés'),
		_Utils_Tuple2('71', 'Administration de l\'état'),
		_Utils_Tuple2('72', 'Collectivité territoriale'),
		_Utils_Tuple2('73', 'Etablissement public administratif'),
		_Utils_Tuple2('74', 'Autre personne morale de droit public administratif'),
		_Utils_Tuple2('81', 'Organisme gérant un régime de protection sociale à adhésion obligatoire'),
		_Utils_Tuple2('82', 'Organisme mutualiste'),
		_Utils_Tuple2('83', 'Comité d\'entreprise'),
		_Utils_Tuple2('84', 'Organisme professionnel'),
		_Utils_Tuple2('85', 'Organisme de retraite à adhésion non obligatoire'),
		_Utils_Tuple2('91', 'Syndicat de propriétaires'),
		_Utils_Tuple2('92', 'Association loi 1901 ou assimilé'),
		_Utils_Tuple2('93', 'Fondation'),
		_Utils_Tuple2('99', 'Autre personne morale de droit privé')
	]);
var $author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$modifiedFormeJuridiqueRef = _Utils_ap(
	$PerformanceIMMO$elm_utils$Perfimmo$Data$OfficialDataRef$formeJuridiquesTuples,
	_List_fromArray(
		[
			_Utils_Tuple2('11', 'Artisan-commerçant'),
			_Utils_Tuple2('12', 'Commerçant'),
			_Utils_Tuple2('13', 'Artisan'),
			_Utils_Tuple2('14', 'Officier public ou ministériel'),
			_Utils_Tuple2('15', 'Profession libérale'),
			_Utils_Tuple2('16', 'Exploitant agricole'),
			_Utils_Tuple2('17', 'Agent commercial'),
			_Utils_Tuple2('18', 'Associé gérant de Société'),
			_Utils_Tuple2('19', '(Autre) Personne physique')
		]));
var $author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$fromCodeToFormeJuridiqueModified = function (code) {
	return $elm$core$List$head(
		A2(
			$elm$core$List$filterMap,
			function (_v0) {
				var c = _v0.a;
				var forme = _v0.b;
				return _Utils_eq(c, code) ? $elm$core$Maybe$Just(forme) : $elm$core$Maybe$Nothing;
			},
			$author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$modifiedFormeJuridiqueRef));
};
var $PerformanceIMMO$elm_utils$Perfimmo$Data$OfficialDataRef$fromCodeToPays = function (code) {
	return $elm$core$List$head(
		A2(
			$elm$core$List$filterMap,
			function (_v0) {
				var c = _v0.a;
				var pays = _v0.b;
				return _Utils_eq(c, code) ? $elm$core$Maybe$Just(pays) : $elm$core$Maybe$Nothing;
			},
			$PerformanceIMMO$elm_utils$Perfimmo$Data$OfficialDataRef$paysTuples));
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$identiteFromApi = F2(
	function (identiteForm, id) {
		return _Utils_update(
			identiteForm,
			{
				f8: A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, id.f8),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					identiteForm.f8),
				dl: A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, id.dl),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					identiteForm.dl),
				ii: A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, id.ii),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					identiteForm.ii),
				iV: A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, id.iV),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					identiteForm.iV)
			});
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleParamsForm = function (nom) {
	return function (formeJuridique) {
		return function (siren) {
			return function (villeTribunalCommerce) {
				return function (capitalSocial) {
					return function (typologie) {
						return function (compteResultat) {
							return function (bilan) {
								return function (fiscalite) {
									return function (representantsLegaux) {
										return function (evaluationRisqueLCBFT) {
											return {fS: bilan, f2: capitalSocial, ge: compteResultat, c5: evaluationRisqueLCBFT, db: fiscalite, hd: formeJuridique, dl: nom, dA: representantsLegaux, jR: siren, dY: typologie, kG: villeTribunalCommerce};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initBilanForm = function (extSync) {
	return $author$project$Perfimmo$Form$FormModel$OptionalBlock(
		A3(
			$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$BilanForm,
			A3(
				$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ActifAnnuelForm,
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
					A2(
						$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
						extSync,
						_List_fromArray(
							[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
					A2(
						$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
						extSync,
						_List_fromArray(
							[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
					A2(
						$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
						extSync,
						_List_fromArray(
							[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory])))),
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory])))));
};
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initCompteResultatForm = function (extSync) {
	return $author$project$Perfimmo$Form$FormModel$OptionalBlock(
		A3(
			$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$CompteResultatForm,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory])))));
};
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initFiscaliteForm = function (extSync) {
	return $author$project$Perfimmo$Form$FormModel$OptionalBlock(
		A2(
			$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$FiscaliteForm,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$init(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory])))));
};
var $author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$initFormJuridiqueField = function (infos) {
	return A4(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$init,
		A2($elm$core$List$map, $elm$core$Tuple$second, $author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$modifiedFormeJuridiqueRef),
		infos,
		$elm$core$Maybe$Just,
		$elm$core$Basics$identity);
};
var $author$project$Components$Clients$APIModel$Entreprises_ChefEntreprise = 3;
var $author$project$Components$Clients$APIModel$IntermediairesFinanciers = 2;
var $author$project$Components$Clients$APIModel$InvestisseursInstitutionnels = 1;
var $author$project$Components$Clients$APIModel$OrganismeSansButLucratif = 0;
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$personneMorale_TypologieFromS = function (s) {
	switch (s) {
		case 'Organisme sans but lucratif':
			return $elm$core$Maybe$Just(0);
		case 'Investisseurs institutionnels':
			return $elm$core$Maybe$Just(1);
		case 'Intermédiaires financiers':
			return $elm$core$Maybe$Just(2);
		case 'Entreprises / Chef d\'entreprise':
			return $elm$core$Maybe$Just(3);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$personneMorale_TypologieToS = function (s) {
	switch (s) {
		case 0:
			return 'Organisme sans but lucratif';
		case 1:
			return 'Investisseurs institutionnels';
		case 2:
			return 'Intermédiaires financiers';
		default:
			return 'Entreprises / Chef d\'entreprise';
	}
};
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$personneMorale_TypologieTuples = A2(
	$elm$core$List$map,
	function (v) {
		return _Utils_Tuple2(
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$personneMorale_TypologieToS(v),
			$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$personneMorale_TypologieToS(v));
	},
	_List_fromArray(
		[0, 1, 2, 3]));
var $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initPersonneMorale_TypologieField = function (infos) {
	return A4($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$personneMorale_TypologieTuples, infos, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$personneMorale_TypologieFromS, $author$project$Components$Clients$Detail$ModelForm$SelectFormRef$personneMorale_TypologieToS);
};
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initPersonneMoraleForm = function (extSync) {
	return $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleParamsForm(
		A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
			$elm$core$Maybe$Nothing,
			A2(
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
				extSync,
				_List_fromArray(
					[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))))(
		$author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$initFormJuridiqueField(
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)))(
		A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
			$elm$core$Maybe$Nothing,
			A2(
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
				extSync,
				_List_fromArray(
					[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))))(
		A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
			$elm$core$Maybe$Nothing,
			A2(
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
				extSync,
				_List_fromArray(
					[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))))(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)))(
		$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initPersonneMorale_TypologieField(
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync, extSync, _List_Nil)))(
		$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initCompteResultatForm(extSync))(
		$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initBilanForm(extSync))(
		$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initFiscaliteForm(extSync))(_List_Nil)(
		$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initRisqueLCBFTField(
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync, extSync, _List_Nil)));
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$naissanceFromApi = F2(
	function (naissanceForm, n) {
		return _Utils_update(
			naissanceForm,
			{
				c$: A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, n.c$),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					naissanceForm.c$),
				dq: A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, n.dq),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					naissanceForm.dq),
				d_: A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, n.d_),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					naissanceForm.d_)
			});
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateDelivrance = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateExpiration = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Numero = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$APIModel$PieceIdentite = F4(
	function (numero, typeDePiece, dateDelivrance, dateExpiration) {
		return {gr: dateDelivrance, gu: dateExpiration, is: numero, ks: typeDePiece};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$TypeDePiece = function (a) {
	return {$: 1, a: a};
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue = function (_v0) {
	var val = _v0.a;
	return val;
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue = function (_v0) {
	var val = _v0.a;
	return val;
};
var $author$project$Components$Clients$ResultUtils$map4C = F5(
	function (f, r1, r2, r3, r4) {
		var _v0 = _Utils_Tuple2(
			_Utils_Tuple2(r1, r2),
			_Utils_Tuple2(r3, r4));
		if (_v0.a.a.$ === 1) {
			if (_v0.a.b.$ === 1) {
				if (_v0.b.a.$ === 1) {
					if (_v0.b.b.$ === 1) {
						var _v1 = _v0.a;
						var e1 = _v1.a.a;
						var e2 = _v1.b.a;
						var _v2 = _v0.b;
						var e3 = _v2.a.a;
						var e4 = _v2.b.a;
						return $elm$core$Result$Err(
							_List_fromArray(
								[e1, e2, e3, e4]));
					} else {
						var _v3 = _v0.a;
						var e1 = _v3.a.a;
						var e2 = _v3.b.a;
						var _v4 = _v0.b;
						var e3 = _v4.a.a;
						return $elm$core$Result$Err(
							_List_fromArray(
								[e1, e2, e3]));
					}
				} else {
					if (_v0.b.b.$ === 1) {
						var _v5 = _v0.a;
						var e1 = _v5.a.a;
						var e2 = _v5.b.a;
						var _v6 = _v0.b;
						var e3 = _v6.b.a;
						return $elm$core$Result$Err(
							_List_fromArray(
								[e1, e2, e3]));
					} else {
						var _v11 = _v0.a;
						var e1 = _v11.a.a;
						var e2 = _v11.b.a;
						var _v12 = _v0.b;
						return $elm$core$Result$Err(
							_List_fromArray(
								[e1, e2]));
					}
				}
			} else {
				if (_v0.b.a.$ === 1) {
					if (_v0.b.b.$ === 1) {
						var _v7 = _v0.a;
						var e1 = _v7.a.a;
						var _v8 = _v0.b;
						var e2 = _v8.a.a;
						var e3 = _v8.b.a;
						return $elm$core$Result$Err(
							_List_fromArray(
								[e1, e2, e3]));
					} else {
						var _v13 = _v0.a;
						var e1 = _v13.a.a;
						var _v14 = _v0.b;
						var e2 = _v14.a.a;
						return $elm$core$Result$Err(
							_List_fromArray(
								[e1, e2]));
					}
				} else {
					if (_v0.b.b.$ === 1) {
						var _v17 = _v0.a;
						var e1 = _v17.a.a;
						var _v18 = _v0.b;
						var e2 = _v18.b.a;
						return $elm$core$Result$Err(
							_List_fromArray(
								[e1, e2]));
					} else {
						var _v29 = _v0.a;
						var e = _v29.a.a;
						var _v30 = _v0.b;
						return $elm$core$Result$Err(
							_List_fromArray(
								[e]));
					}
				}
			}
		} else {
			if (_v0.a.b.$ === 1) {
				if (_v0.b.a.$ === 1) {
					if (_v0.b.b.$ === 1) {
						var _v9 = _v0.a;
						var e1 = _v9.b.a;
						var _v10 = _v0.b;
						var e2 = _v10.a.a;
						var e3 = _v10.b.a;
						return $elm$core$Result$Err(
							_List_fromArray(
								[e1, e2, e3]));
					} else {
						var _v15 = _v0.a;
						var e1 = _v15.b.a;
						var _v16 = _v0.b;
						var e2 = _v16.a.a;
						return $elm$core$Result$Err(
							_List_fromArray(
								[e1, e2]));
					}
				} else {
					if (_v0.b.b.$ === 1) {
						var _v19 = _v0.a;
						var e1 = _v19.b.a;
						var _v20 = _v0.b;
						var e2 = _v20.b.a;
						return $elm$core$Result$Err(
							_List_fromArray(
								[e1, e2]));
					} else {
						var _v27 = _v0.a;
						var e = _v27.b.a;
						var _v28 = _v0.b;
						return $elm$core$Result$Err(
							_List_fromArray(
								[e]));
					}
				}
			} else {
				if (_v0.b.a.$ === 1) {
					if (_v0.b.b.$ === 1) {
						var _v21 = _v0.a;
						var _v22 = _v0.b;
						var e1 = _v22.a.a;
						var e2 = _v22.b.a;
						return $elm$core$Result$Err(
							_List_fromArray(
								[e1, e2]));
					} else {
						var _v25 = _v0.a;
						var _v26 = _v0.b;
						var e = _v26.a.a;
						return $elm$core$Result$Err(
							_List_fromArray(
								[e]));
					}
				} else {
					if (_v0.b.b.$ === 1) {
						var _v23 = _v0.a;
						var _v24 = _v0.b;
						var e = _v24.b.a;
						return $elm$core$Result$Err(
							_List_fromArray(
								[e]));
					} else {
						var _v31 = _v0.a;
						var x = _v31.a.a;
						var y = _v31.b.a;
						var _v32 = _v0.b;
						var z = _v32.a.a;
						var zz = _v32.b.a;
						return $elm$core$Result$Ok(
							A4(f, x, y, z, zz));
					}
				}
			}
		}
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$nonEmptyStringE = F2(
	function (error, s) {
		if (!s.$) {
			var v = s.a;
			return $elm$core$String$isEmpty(v) ? $elm$core$Result$Err(error) : $elm$core$Result$Ok(v);
		} else {
			return $elm$core$Result$Err(error);
		}
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$pieceIdentiteToApi = function (form) {
	var typeDePiece = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$TypeDePiece(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.ks));
	var numero = A2(
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$nonEmptyStringE,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Numero(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.is));
	var dateExpiration = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateExpiration(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.gu));
	var dateDelivrance = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateDelivrance(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.gr));
	var emptyBlock = $elm_community$result_extra$Result$Extra$isErr(numero) && ($elm_community$result_extra$Result$Extra$isErr(typeDePiece) && ($elm_community$result_extra$Result$Extra$isErr(dateDelivrance) && $elm_community$result_extra$Result$Extra$isErr(dateExpiration)));
	var result = A5(
		$author$project$Components$Clients$ResultUtils$map4C,
		F4(
			function (num, type_, dateDel, dateExp) {
				return $elm$core$Maybe$Just(
					A4($author$project$Components$Clients$APIModel$PieceIdentite, num, type_, dateDel, dateExp));
			}),
		numero,
		typeDePiece,
		dateDelivrance,
		dateExpiration);
	return emptyBlock ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : result;
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculPieceIdentiteFormBlockState = function (piece) {
	var _v0 = $author$project$Components$Clients$Detail$UpdateForm$FormValidator$pieceIdentiteToApi(piece);
	if (!_v0.$) {
		if (!_v0.a.$) {
			return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(piece);
		} else {
			var _v1 = _v0.a;
			return $author$project$Perfimmo$Form$FormModel$OptionalBlock(piece);
		}
	} else {
		return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(piece);
	}
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setValue = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue;
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$pieceIdentiteFromApi = F2(
	function (initPiece, piece) {
		return $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculPieceIdentiteFormBlockState(
			A4(
				$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PieceIdentiteForm,
				A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, piece.is),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					initPiece.is),
				A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, piece.ks),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					initPiece.ks),
				A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setValue, piece.gr),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					initPiece.gr),
				A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setValue, piece.gu),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					initPiece.gu)));
	});
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initAutreNationalitesMulti = F2(
	function (i, fieldInfos) {
		return A3(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$init,
			$PerformanceIMMO$elm_utils$Perfimmo$Data$OfficialDataRef$paysTuples,
			'autreNationalites_' + $elm$core$String$fromInt(i),
			fieldInfos);
	});
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initRepresentantLegalForm = F2(
	function (extSync, isPM) {
		return {
			fD: $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initAdresseForm(extSync),
			bf: $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initAutreNationalites(
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)),
			g9: isPM ? A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))) : A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync,
					extSync,
					_List_fromArray(
						[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
			aD: A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initIdentiteForm, extSync, $author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync),
			h_: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
				$elm$core$Maybe$Nothing,
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)),
			aI: A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initNaissanceForm, extSync, $author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync),
			bD: $author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$initNationaliteField(
				A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil)),
			du: $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initPieceIdentiteForm(extSync),
			jj: $elm$core$Maybe$Nothing
		};
	});
var $inkuzmin$elm_multiselect$Multiselect$getValues = function (_v0) {
	var model = _v0;
	return model.o7;
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$selectValues = F2(
	function (values, _v0) {
		var model = _v0.a;
		var infos = _v0.b;
		var newModel = A3(
			$inkuzmin$elm_multiselect$Multiselect$populateValues,
			model,
			$inkuzmin$elm_multiselect$Multiselect$getValues(model),
			values);
		return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$FormMultiSelect, newModel, infos);
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$representantLegalFromApi = F4(
	function (extSync, isPM, i, r) {
		var repr = A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initRepresentantLegalForm, extSync, isPM);
		return _Utils_update(
			repr,
			{
				fD: A2(
					$elm$core$Maybe$withDefault,
					repr.fD,
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$adresseFromApi(repr.fD),
						r.fD)),
				bf: A2(
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$selectValues,
					A2(
						$elm$core$List$map,
						function (code) {
							return _Utils_Tuple2(
								code,
								A2(
									$elm$core$Maybe$withDefault,
									'NA',
									$PerformanceIMMO$elm_utils$Perfimmo$Data$OfficialDataRef$fromCodeToPays(code)));
						},
						r.bf),
					A2(
						$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initAutreNationalitesMulti,
						i + 1,
						A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$bothSync, extSync, _List_Nil))),
				g9: A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, r.g9),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					repr.g9),
				aD: A2(
					$elm$core$Maybe$withDefault,
					repr.aD,
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$identiteFromApi(repr.aD),
						r.aD)),
				h_: A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, r.h_),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					repr.h_),
				aI: A2(
					$elm$core$Maybe$withDefault,
					repr.aI,
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$naissanceFromApi(repr.aI),
						r.aI)),
				bD: A3(
					$elm$core$Basics$apR,
					A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, r.bD),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					repr.bD),
				du: A2(
					$elm$core$Maybe$withDefault,
					repr.du,
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$pieceIdentiteFromApi(
							$author$project$Perfimmo$Form$FormModel$getBlock(repr.du)),
						r.du)),
				jj: r.jj
			});
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$setValue = F2(
	function (x, _v0) {
		var select = _v0;
		return A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$ChosenItem(x),
			select);
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Enfants = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Components$Clients$APIModel$SituationFamiliale = F4(
	function (situationFamille, conjointPartenaire, nombreEnfantACharge, enfants) {
		return {gf: conjointPartenaire, gX: enfants, ij: nombreEnfantACharge, jT: situationFamille};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SituationFamille = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Result$map2 = F3(
	function (func, ra, rb) {
		if (ra.$ === 1) {
			var x = ra.a;
			return $elm$core$Result$Err(x);
		} else {
			var a = ra.a;
			if (rb.$ === 1) {
				var x = rb.a;
				return $elm$core$Result$Err(x);
			} else {
				var b = rb.a;
				return $elm$core$Result$Ok(
					A2(func, a, b));
			}
		}
	});
var $elm_community$result_extra$Result$Extra$combine = A2(
	$elm$core$List$foldr,
	$elm$core$Result$map2($elm$core$List$cons),
	$elm$core$Result$Ok(_List_Nil));
var $author$project$Components$Clients$ResultUtils$combineC = function (rs) {
	var f = F2(
		function (r, acc) {
			if (r.$ === 1) {
				var e = r.a;
				return _Utils_ap(
					acc,
					_List_fromArray(
						[e]));
			} else {
				return acc;
			}
		});
	var errors = A3($elm$core$List$foldl, f, _List_Nil, rs);
	return $elm$core$List$isEmpty(errors) ? A2(
		$elm$core$Result$mapError,
		function (_v0) {
			return _List_Nil;
		},
		$elm_community$result_extra$Result$Extra$combine(rs)) : $elm$core$Result$Err(errors);
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ChooseClient = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointIdentiteF = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointNaissance = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$APIModel$ReferencedClient = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$APIModel$ReferencedClientParams = F3(
	function (uid, dateMariage, regimeMatrimoniale) {
		return {c0: dateMariage, dy: regimeMatrimoniale, kx: uid};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReferencedConjoint = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$APIModel$UnreferencedPerson = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$APIModel$UnreferencedPersonParams = F8(
	function (identite, naissance, nationalite, autreNationalites, usPerson, profession, dateMariage, regimeMatrimoniale) {
		return {bf: autreNationalites, c0: dateMariage, aD: identite, aI: naissance, bD: nationalite, dw: profession, dy: regimeMatrimoniale, bV: usPerson};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$getValue = function (_v0) {
	var select = _v0;
	return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(select);
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CiviliteF = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Nom = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Prenom = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$identiteAllFieldsMissing = _List_fromArray(
	[
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CiviliteF(''),
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Nom(''),
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Prenom('')
	]);
var $author$project$Components$Clients$APIModel$Identite = F4(
	function (civilite, nom, nomNaissance, prenom) {
		return {f8: civilite, dl: nom, ii: nomNaissance, iV: prenom};
	});
var $elm$core$String$trim = _String_trim;
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$isEmptyString = function (s) {
	var _v0 = A2($elm$core$Maybe$map, $elm$core$String$trim, s);
	if (_v0.$ === 1) {
		return true;
	} else {
		if (_v0.a === '') {
			return true;
		} else {
			return false;
		}
	}
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$identiteToApi = function (form) {
	var prenom = A2(
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$nonEmptyStringE,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Prenom(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.iV));
	var nomUsage = A2(
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$nonEmptyStringE,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Nom(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.dl));
	var nomNaissance = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.ii);
	var civilite = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CiviliteF(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.f8));
	var emptyBlock = $elm_community$result_extra$Result$Extra$isErr(civilite) && ($elm_community$result_extra$Result$Extra$isErr(nomUsage) && ($elm_community$result_extra$Result$Extra$isErr(prenom) && $author$project$Components$Clients$Detail$UpdateForm$FormValidator$isEmptyString(nomNaissance)));
	var nomEmptyResult = A4(
		$author$project$Components$Clients$ResultUtils$map3C,
		F3(
			function (civ, nom, pren) {
				return $elm$core$Maybe$Just(
					A4($author$project$Components$Clients$APIModel$Identite, civ, nom, nomNaissance, pren));
			}),
		civilite,
		nomUsage,
		prenom);
	return emptyBlock ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : nomEmptyResult;
};
var $elm_community$result_extra$Result$Extra$join = function (r) {
	if (r.$ === 1) {
		var x = r.a;
		return $elm$core$Result$Err(x);
	} else {
		if (r.a.$ === 1) {
			var x = r.a.a;
			return $elm$core$Result$Err(x);
		} else {
			var a = r.a.a;
			return $elm$core$Result$Ok(a);
		}
	}
};
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateNaissance = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$naissanceAllFieldsMissing = _List_fromArray(
	[
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateNaissance('')
	]);
var $author$project$Components$Clients$APIModel$Naissance = F3(
	function (date, ville, pays) {
		return {c$: date, dq: pays, d_: ville};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$naissanceToApi = function (form) {
	var ville = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.d_);
	var pays = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.dq);
	var date = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateNaissance(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c$));
	var emptyBlock = $elm_community$result_extra$Result$Extra$isErr(date) && ($author$project$Components$Clients$Detail$UpdateForm$FormValidator$isEmptyString(ville) && $author$project$Components$Clients$Detail$UpdateForm$FormValidator$isEmptyString(pays));
	var result = A2(
		$elm$core$Result$mapError,
		$elm$core$List$singleton,
		A2(
			$elm$core$Result$map,
			function (d) {
				return $elm$core$Maybe$Just(
					A3($author$project$Components$Clients$APIModel$Naissance, d, ville, pays));
			},
			date));
	return emptyBlock ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : result;
};
var $inkuzmin$elm_multiselect$Multiselect$getSelectedValues = function (_v0) {
	var model = _v0;
	return model.o;
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$getSelectedValues = function (_v0) {
	var model = _v0.a;
	return $inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model);
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$nationaliteToApi = F2(
	function (nationalite, autres) {
		var autreNationalites = A2(
			$elm$core$List$map,
			$elm$core$Tuple$first,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$getSelectedValues(autres));
		var usPerson = A2(
			$elm$core$List$any,
			$elm$core$Basics$eq('US'),
			_Utils_ap(
				$elm_community$maybe_extra$Maybe$Extra$toList(nationalite),
				autreNationalites));
		return _Utils_Tuple3(nationalite, autreNationalites, usPerson);
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$conjointToApi = F2(
	function (clients, form) {
		if (!form.$) {
			var params = form.a;
			var regimeMatrimoniale = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(params.dy);
			var dateMariage = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(params.c0);
			var clientChosenState = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$getValue(params.ga);
			var isEmptyBlock = _Utils_eq(clientChosenState, $elm$core$Maybe$Nothing) && (_Utils_eq(dateMariage, $elm$core$Maybe$Nothing) && _Utils_eq(regimeMatrimoniale, $elm$core$Maybe$Nothing));
			var clientChosen = function () {
				if (!clientChosenState.$) {
					if (!clientChosenState.a.$) {
						var x = clientChosenState.a.a;
						return A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Result$Err(
								_List_fromArray(
									[
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReferencedConjoint(
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ChooseClient(''))
									])),
							A2(
								$elm$core$Maybe$map,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.kx;
									},
									$elm$core$Result$Ok),
								A2(
									$elm_community$list_extra$List$Extra$find,
									function (c) {
										return _Utils_eq(c.nz, x);
									},
									clients)));
					} else {
						return $elm$core$Result$Err(
							_List_fromArray(
								[
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReferencedConjoint(
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ChooseClient(''))
								]));
					}
				} else {
					return $elm$core$Result$Err(
						_List_fromArray(
							[
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReferencedConjoint(
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ChooseClient(''))
							]));
				}
			}();
			var result = A2(
				$elm$core$Result$map,
				function (uid) {
					return $elm$core$Maybe$Just(
						$author$project$Components$Clients$APIModel$ReferencedClient(
							A3($author$project$Components$Clients$APIModel$ReferencedClientParams, uid, dateMariage, regimeMatrimoniale)));
				},
				clientChosen);
			return isEmptyBlock ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : result;
		} else {
			var params = form.a;
			var regimeMatrimoniale = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(params.dy);
			var profession = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(params.dw);
			var naissance = A2(
				$elm$core$Result$mapError,
				$elm$core$List$map(
					A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointNaissance, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$UnreferencedConjointF)),
				$author$project$Components$Clients$Detail$UpdateForm$FormValidator$naissanceToApi(params.aI));
			var naissanceAllError = $elm_community$result_extra$Result$Extra$join(
				A2(
					$elm$core$Result$map,
					$elm$core$Result$fromMaybe(
						A2(
							$elm$core$List$map,
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointNaissance, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$UnreferencedConjointF),
							$author$project$Components$Clients$Detail$UpdateForm$FormValidator$naissanceAllFieldsMissing)),
					naissance));
			var identite = A2(
				$elm$core$Result$mapError,
				$elm$core$List$map(
					A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointIdentiteF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$UnreferencedConjointF)),
				$author$project$Components$Clients$Detail$UpdateForm$FormValidator$identiteToApi(params.aD));
			var identiteAllError = $elm_community$result_extra$Result$Extra$join(
				A2(
					$elm$core$Result$map,
					$elm$core$Result$fromMaybe(
						A2(
							$elm$core$List$map,
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointIdentiteF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$UnreferencedConjointF),
							$author$project$Components$Clients$Detail$UpdateForm$FormValidator$identiteAllFieldsMissing)),
					identite));
			var dateMariage = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(params.c0);
			var _v2 = A2(
				$author$project$Components$Clients$Detail$UpdateForm$FormValidator$nationaliteToApi,
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(params.bD),
				params.bf);
			var nationalite = _v2.a;
			var autres = _v2.b;
			var usPerson = _v2.c;
			var emptyBlock = _Utils_eq(
				identite,
				$elm$core$Result$Ok($elm$core$Maybe$Nothing)) && (_Utils_eq(
				naissance,
				$elm$core$Result$Ok($elm$core$Maybe$Nothing)) && (_Utils_eq(
				_Utils_Tuple3(nationalite, autres, usPerson),
				_Utils_Tuple3($elm$core$Maybe$Nothing, _List_Nil, false)) && ($author$project$Components$Clients$Detail$UpdateForm$FormValidator$isEmptyString(profession) && (_Utils_eq(dateMariage, $elm$core$Maybe$Nothing) && _Utils_eq(regimeMatrimoniale, $elm$core$Maybe$Nothing)))));
			var result = A2(
				$elm$core$Result$mapError,
				$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
				A3(
					$author$project$Components$Clients$ResultUtils$map2C,
					F2(
						function (id, nai) {
							return $elm$core$Maybe$Just(
								$author$project$Components$Clients$APIModel$UnreferencedPerson(
									A8(
										$author$project$Components$Clients$APIModel$UnreferencedPersonParams,
										id,
										nai,
										nationalite,
										autres,
										$elm$core$Maybe$Just(usPerson),
										profession,
										dateMariage,
										regimeMatrimoniale)));
						}),
					identiteAllError,
					naissanceAllError));
			return emptyBlock ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : result;
		}
	});
var $author$project$Components$Clients$APIModel$Enfant = F2(
	function (identite, naissance) {
		return {aD: identite, aI: naissance};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$EnfantIdentiteF = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$EnfantNaissanceF = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$enfantToApi = function (form) {
	var naissance = $elm_community$result_extra$Result$Extra$join(
		A2(
			$elm$core$Result$map,
			$elm$core$Result$fromMaybe(
				A2($elm$core$List$map, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$EnfantNaissanceF, $author$project$Components$Clients$Detail$UpdateForm$FormValidator$naissanceAllFieldsMissing)),
			A2(
				$elm$core$Result$mapError,
				$elm$core$List$map($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$EnfantNaissanceF),
				$author$project$Components$Clients$Detail$UpdateForm$FormValidator$naissanceToApi(form.aI))));
	var identite = $elm_community$result_extra$Result$Extra$join(
		A2(
			$elm$core$Result$map,
			$elm$core$Result$fromMaybe(
				A2($elm$core$List$map, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$EnfantIdentiteF, $author$project$Components$Clients$Detail$UpdateForm$FormValidator$identiteAllFieldsMissing)),
			A2(
				$elm$core$Result$mapError,
				$elm$core$List$map($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$EnfantIdentiteF),
				$author$project$Components$Clients$Detail$UpdateForm$FormValidator$identiteToApi(form.aD))));
	return A2(
		$elm$core$Result$mapError,
		$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
		A3(
			$author$project$Components$Clients$ResultUtils$map2C,
			F2(
				function (id, nai) {
					return A2($author$project$Components$Clients$APIModel$Enfant, id, nai);
				}),
			identite,
			naissance));
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$toInt = function (_v0) {
	var _int = _v0.a;
	return _int;
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$situationFamilialeToApi = F2(
	function (clients, form) {
		var situationFamille = A2(
			$elm$core$Result$fromMaybe,
			_List_fromArray(
				[
					$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SituationFamille('')
				]),
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.jT));
		var nbEnfantsACharge = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$toInt(form.ij);
		var enfants = A2(
			$elm$core$Result$mapError,
			$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
			$author$project$Components$Clients$ResultUtils$combineC(
				A2(
					$elm$core$List$indexedMap,
					F2(
						function (no, r) {
							return A2(
								$elm$core$Result$mapError,
								$elm$core$List$map(
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Enfants(no + 1)),
								r);
						}),
					A2($elm$core$List$map, $author$project$Components$Clients$Detail$UpdateForm$FormValidator$enfantToApi, form.gX))));
		var conjoint = A2(
			$elm$core$Result$mapError,
			$elm$core$List$map($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointPartenaireF),
			A2($author$project$Components$Clients$Detail$UpdateForm$FormValidator$conjointToApi, clients, form.gf));
		var emptyBlock = _Utils_eq(
			enfants,
			$elm$core$Result$Ok(_List_Nil)) && (_Utils_eq(
			conjoint,
			$elm$core$Result$Ok($elm$core$Maybe$Nothing)) && ($elm_community$result_extra$Result$Extra$isErr(situationFamille) && _Utils_eq(nbEnfantsACharge, $elm$core$Maybe$Nothing)));
		var result = A2(
			$elm$core$Result$mapError,
			$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
			A4(
				$author$project$Components$Clients$ResultUtils$map3C,
				F3(
					function (sitFam, conj, kids) {
						return $elm$core$Maybe$Just(
							A4($author$project$Components$Clients$APIModel$SituationFamiliale, sitFam, conj, nbEnfantsACharge, kids));
					}),
				situationFamille,
				conjoint,
				enfants));
		return emptyBlock ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : result;
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculSitFamFormBlockState = F2(
	function (clients, sitFam) {
		var _v0 = A2($author$project$Components$Clients$Detail$UpdateForm$FormValidator$situationFamilialeToApi, clients, sitFam);
		if (!_v0.$) {
			if (!_v0.a.$) {
				return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(sitFam);
			} else {
				var _v1 = _v0.a;
				return $author$project$Perfimmo$Form$FormModel$OptionalBlock(sitFam);
			}
		} else {
			return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(sitFam);
		}
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$UnreferencedPersonForm = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$UnreferencedPersonParamsForm = F7(
	function (identite, naissance, nationalite, autreNationalites, profession, dateMariage, regimeMatrimoniale) {
		return {bf: autreNationalites, c0: dateMariage, aD: identite, aI: naissance, bD: nationalite, dw: profession, dy: regimeMatrimoniale};
	});
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initUnreferencedConjointPartenaireForm = function (extSync) {
	return A7(
		$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$UnreferencedPersonParamsForm,
		A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initIdentiteForm, extSync, $author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync),
		A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initNaissanceForm, extSync, $author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync),
		$author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$initNationaliteField(
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync, extSync, _List_Nil)),
		$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initAutreNationalites(
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync, extSync, _List_Nil)),
		A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
			$elm$core$Maybe$Nothing,
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync, extSync, _List_Nil)),
		A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
			$elm$core$Maybe$Nothing,
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync, extSync, _List_Nil)),
		$author$project$Components$Clients$Detail$ModelForm$SelectFormRef$initRegimeMatrimonialeField(
			A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync, extSync, _List_Nil)));
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$conjointPartenaireFromApi = F3(
	function (extSync, clients, c) {
		if (!c.$) {
			var params = c.a;
			var refClientForm = A2(
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initReferencedConjointParamsForm,
				extSync,
				A2(
					$elm$core$List$map,
					function ($) {
						return $.nz;
					},
					clients));
			var regime = A3(
				$elm$core$Basics$apR,
				A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, params.dy),
				$elm$core$Maybe$withDefault($elm$core$Basics$identity),
				refClientForm.dy);
			var dateMariage = A2(
				$elm$core$Maybe$withDefault,
				refClientForm.c0,
				A2(
					$elm$core$Maybe$map,
					function (d) {
						return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, d, refClientForm.c0);
					},
					params.c0));
			var datalistFromApi = A2(
				$elm$core$Maybe$withDefault,
				refClientForm.ga,
				A2(
					$elm$core$Maybe$map,
					function (val) {
						return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$setValue, val, refClientForm.ga);
					},
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.nz;
						},
						A2(
							$elm_community$list_extra$List$Extra$find,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.kx;
								},
								$elm$core$Basics$eq(params.kx)),
							clients))));
			return $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ReferencedClientForm(
				A3($author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ReferencedPersonParamsForm, datalistFromApi, dateMariage, regime));
		} else {
			var person = c.a;
			var formInit = $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initUnreferencedConjointPartenaireForm(extSync);
			return $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$UnreferencedPersonForm(
				A7(
					$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$UnreferencedPersonParamsForm,
					A2(
						$elm$core$Maybe$withDefault,
						formInit.aD,
						A2(
							$elm$core$Maybe$map,
							$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$identiteFromApi(formInit.aD),
							person.aD)),
					A2(
						$elm$core$Maybe$withDefault,
						formInit.aI,
						A2(
							$elm$core$Maybe$map,
							$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$naissanceFromApi(formInit.aI),
							person.aI)),
					A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, person.bD),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						formInit.bD),
					A2(
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$selectValues,
						A2(
							$elm$core$List$map,
							function (code) {
								return _Utils_Tuple2(
									code,
									A2(
										$elm$core$Maybe$withDefault,
										'NA',
										$PerformanceIMMO$elm_utils$Perfimmo$Data$OfficialDataRef$fromCodeToPays(code)));
							},
							person.bf),
						formInit.bf),
					A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, person.dw),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						formInit.dw),
					A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, person.c0),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						formInit.c0),
					A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, person.dy),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						formInit.dy)));
		}
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$EnfantForm = F2(
	function (identite, naissance) {
		return {aD: identite, aI: naissance};
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$enfantFromApi = F2(
	function (initEnfant, e) {
		return A2(
			$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$EnfantForm,
			A2(
				$elm$core$Maybe$withDefault,
				initEnfant.aD,
				A2(
					$elm$core$Maybe$map,
					$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$identiteFromApi(initEnfant.aD),
					e.aD)),
			A2(
				$elm$core$Maybe$withDefault,
				initEnfant.aI,
				A2(
					$elm$core$Maybe$map,
					$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$naissanceFromApi(initEnfant.aI),
					e.aI)));
	});
var $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initEnfantForm = function (extSync) {
	return {
		aD: A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initIdentiteForm, extSync, $author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync),
		aI: A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initNaissanceForm, extSync, $author$project$Components$Clients$Detail$ModelForm$FormInitializers$fromHarvestSync)
	};
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$setOptionalValue = F2(
	function (maybeInt, _v0) {
		var infos = _v0.c;
		return A3(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$FormInt,
			maybeInt,
			A2(
				$elm$core$Maybe$withDefault,
				'',
				A2($elm$core$Maybe$map, $elm$core$String$fromInt, maybeInt)),
			infos);
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$setValue = function (_int) {
	return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$setOptionalValue(
		$elm$core$Maybe$Just(_int));
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$situationFamilialeFromApi = F4(
	function (extSync, clients, sitFamInit, s) {
		var initForm = $author$project$Perfimmo$Form$FormModel$getBlock(sitFamInit);
		var enfantFormInit = $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initEnfantForm(extSync);
		return A2(
			$author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculSitFamFormBlockState,
			clients,
			_Utils_update(
				initForm,
				{
					gf: A2(
						$elm$core$Maybe$withDefault,
						initForm.gf,
						A2(
							$elm$core$Maybe$map,
							A2($author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$conjointPartenaireFromApi, extSync, clients),
							s.gf)),
					gX: A2(
						$elm$core$List$map,
						$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$enfantFromApi(enfantFormInit),
						s.gX),
					ij: A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$setValue, s.ij),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						initForm.ij),
					jT: A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, s.jT),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						initForm.jT)
				}));
	});
var $author$project$Components$Clients$APIModel$Budget = F2(
	function (revenusAnnuels, chargesAnnuelles) {
		return {f5: chargesAnnuelles, jy: revenusAnnuels};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ChargesAnnuelles = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$RevenusAnnuels = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$budgetToApi = function (form) {
	var revenusAnnuels = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$RevenusAnnuels(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.jy));
	var chargesAnnuelles = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ChargesAnnuelles(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.f5));
	var emptyBlock = $elm_community$result_extra$Result$Extra$isErr(revenusAnnuels) && $elm_community$result_extra$Result$Extra$isErr(chargesAnnuelles);
	var result = A3(
		$author$project$Components$Clients$ResultUtils$map2C,
		F2(
			function (r, c) {
				return $elm$core$Maybe$Just(
					A2($author$project$Components$Clients$APIModel$Budget, r, c));
			}),
		revenusAnnuels,
		chargesAnnuelles);
	return emptyBlock ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : result;
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculBudgetBlockState = function (budget) {
	var _v0 = $author$project$Components$Clients$Detail$UpdateForm$FormValidator$budgetToApi(budget);
	if (!_v0.$) {
		if (!_v0.a.$) {
			return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(budget);
		} else {
			var _v1 = _v0.a;
			return $author$project$Perfimmo$Form$FormModel$OptionalBlock(budget);
		}
	} else {
		return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(budget);
	}
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$budgetFromApi = F2(
	function (initBudget, budget) {
		return $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculBudgetBlockState(
			A2(
				$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$BudgetForm,
				A3(
					$elm$core$Basics$apR,
					A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.jy;
							},
							$elm$core$Maybe$map($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue)),
						budget),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					initBudget.jy),
				A3(
					$elm$core$Basics$apR,
					A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.f5;
							},
							$elm$core$Maybe$map($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue)),
						budget),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					initBudget.f5)));
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$BudgetF = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$FiscalitePatrimonialeF = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PatrimoineF = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$APIModel$SituationPatrimoniale = F4(
	function (fiscalite, patrimoine, budget, tauxEndettement) {
		return {fX: budget, db: fiscalite, iD: patrimoine, kb: tauxEndettement};
	});
var $author$project$Components$Clients$APIModel$FiscalitePatrimoniale = F4(
	function (nombrePartsFiscales, montantIR, montant_IFI, tmi) {
		return {h7: montantIR, h8: montant_IFI, ik: nombrePartsFiscales, kj: tmi};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$MontIFI = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$MontantIR = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NbPartFiscales = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$TMI = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$fiscalitePatrimonialeToApi = function (form) {
	var tmi = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$TMI(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$toFloat(form.kj));
	var nombrePartsFiscales = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NbPartFiscales(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.ik));
	var montant_IFI = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$MontIFI(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.h8));
	var montantIR = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$MontantIR(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.h7));
	var result = A5(
		$author$project$Components$Clients$ResultUtils$map4C,
		F4(
			function (bn, mIR, mIFI, t) {
				return $elm$core$Maybe$Just(
					A4($author$project$Components$Clients$APIModel$FiscalitePatrimoniale, bn, mIR, mIFI, t));
			}),
		nombrePartsFiscales,
		montantIR,
		montant_IFI,
		tmi);
	var emptyBlock = $elm_community$result_extra$Result$Extra$isErr(nombrePartsFiscales) && ($elm_community$result_extra$Result$Extra$isErr(montantIR) && ($elm_community$result_extra$Result$Extra$isErr(montant_IFI) && $elm_community$result_extra$Result$Extra$isErr(tmi)));
	return emptyBlock ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : result;
};
var $author$project$Components$Clients$APIModel$Patrimoine = F2(
	function (actif, passif) {
		return {kP: actif, iB: passif};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PatrimoineActifF = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PatrimoinePassif = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$APIModel$PatrimoineActif = F3(
	function (brut, financier, non_financier) {
		return {cR: brut, da: financier, io: non_financier};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$actifToApi = function (form) {
	var non_financier = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NonFinancier(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.io));
	var financier = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Financier(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.da));
	var brut = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Brut(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.cR));
	var emptyBlock = $elm_community$result_extra$Result$Extra$isErr(brut) && ($elm_community$result_extra$Result$Extra$isErr(financier) && $elm_community$result_extra$Result$Extra$isErr(non_financier));
	var result = A4(
		$author$project$Components$Clients$ResultUtils$map3C,
		F3(
			function (b, f, nf) {
				return $elm$core$Maybe$Just(
					A3($author$project$Components$Clients$APIModel$PatrimoineActif, b, f, nf));
			}),
		brut,
		financier,
		non_financier);
	return emptyBlock ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : result;
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$allPatrimoineActifFields = _List_fromArray(
	[
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Brut(''),
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Financier(''),
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NonFinancier('')
	]);
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$patrimoineToApi = function (form) {
	var passif = A2(
		$elm$core$Result$fromMaybe,
		_List_fromArray(
			[
				$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PatrimoinePassif('')
			]),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.iB));
	var actif = A2(
		$elm$core$Result$mapError,
		$elm$core$List$map($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PatrimoineActifF),
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$actifToApi(form.kP));
	var actifAllError = $elm_community$result_extra$Result$Extra$join(
		A2(
			$elm$core$Result$map,
			$elm$core$Result$fromMaybe(
				A2($elm$core$List$map, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PatrimoineActifF, $author$project$Components$Clients$Detail$UpdateForm$FormValidator$allPatrimoineActifFields)),
			actif));
	var result = A2(
		$elm$core$Result$mapError,
		$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
		A3(
			$author$project$Components$Clients$ResultUtils$map2C,
			F2(
				function (act, pass) {
					return $elm$core$Maybe$Just(
						A2($author$project$Components$Clients$APIModel$Patrimoine, act, pass));
				}),
			actifAllError,
			passif));
	var emptyBlock = _Utils_eq(
		actif,
		$elm$core$Result$Ok($elm$core$Maybe$Nothing)) && $elm_community$result_extra$Result$Extra$isErr(passif);
	return emptyBlock ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : result;
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$situationPatrimonialeToApi = function (form) {
	var tauxEndettement = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$toFloat(form.kb);
	var patrimoine = A2(
		$elm$core$Result$mapError,
		$elm$core$List$map($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PatrimoineF),
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$patrimoineToApi(
			$author$project$Perfimmo$Form$FormModel$getBlock(form.iD)));
	var fiscalite = A2(
		$elm$core$Result$mapError,
		$elm$core$List$map($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$FiscalitePatrimonialeF),
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$fiscalitePatrimonialeToApi(
			$author$project$Perfimmo$Form$FormModel$getBlock(form.db)));
	var budget = A2(
		$elm$core$Result$mapError,
		$elm$core$List$map($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$BudgetF),
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$budgetToApi(
			$author$project$Perfimmo$Form$FormModel$getBlock(form.fX)));
	var emptyBlock = $elm_community$result_extra$Result$Extra$isErr(fiscalite) && ($elm_community$result_extra$Result$Extra$isErr(patrimoine) && $elm_community$result_extra$Result$Extra$isErr(budget));
	var result = A2(
		$elm$core$Result$mapError,
		$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
		A4(
			$author$project$Components$Clients$ResultUtils$map3C,
			F3(
				function (fisc, pat, bud) {
					return $elm$core$Maybe$Just(
						A4($author$project$Components$Clients$APIModel$SituationPatrimoniale, fisc, pat, bud, tauxEndettement));
				}),
			fiscalite,
			patrimoine,
			budget));
	return emptyBlock ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : result;
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculSitPatFormBlockState = function (sitPat) {
	var _v0 = $author$project$Components$Clients$Detail$UpdateForm$FormValidator$situationPatrimonialeToApi(sitPat);
	if (!_v0.$) {
		if (!_v0.a.$) {
			return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(sitPat);
		} else {
			var _v1 = _v0.a;
			return $author$project$Perfimmo$Form$FormModel$OptionalBlock(sitPat);
		}
	} else {
		return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(sitPat);
	}
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculFiscalitePatrimonialeBlockState = function (fisc) {
	var _v0 = $author$project$Components$Clients$Detail$UpdateForm$FormValidator$fiscalitePatrimonialeToApi(fisc);
	if (!_v0.$) {
		if (!_v0.a.$) {
			return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(fisc);
		} else {
			var _v1 = _v0.a;
			return $author$project$Perfimmo$Form$FormModel$OptionalBlock(fisc);
		}
	} else {
		return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(fisc);
	}
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$fiscalitePatFromApi = F2(
	function (initForm, f) {
		return $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculFiscalitePatrimonialeBlockState(
			A4(
				$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$FiscalitePatrimonialeForm,
				A3(
					$elm$core$Basics$apR,
					A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.ik;
							},
							$elm$core$Maybe$map($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue)),
						f),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					initForm.ik),
				A3(
					$elm$core$Basics$apR,
					A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.h7;
							},
							$elm$core$Maybe$map($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue)),
						f),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					initForm.h7),
				A3(
					$elm$core$Basics$apR,
					A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.h8;
							},
							$elm$core$Maybe$map($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue)),
						f),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					initForm.h8),
				A3(
					$elm$core$Basics$apR,
					A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.kj;
							},
							$elm$core$Maybe$map($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setValue)),
						f),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					initForm.kj)));
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculPatrimoineBlockState = function (p) {
	var _v0 = $author$project$Components$Clients$Detail$UpdateForm$FormValidator$patrimoineToApi(p);
	if (!_v0.$) {
		if (!_v0.a.$) {
			return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(p);
		} else {
			var _v1 = _v0.a;
			return $author$project$Perfimmo$Form$FormModel$OptionalBlock(p);
		}
	} else {
		return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(p);
	}
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$patrimoineFromApi = F2(
	function (initPat, pat) {
		return $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculPatrimoineBlockState(
			A2(
				$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PatrimoineForm,
				A2(
					$elm$core$Maybe$withDefault,
					initPat.kP,
					A2(
						$elm$core$Maybe$map,
						function (p) {
							return A3(
								$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PatrimoineActifForm,
								A3(
									$elm$core$Basics$apR,
									A2(
										$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.cR;
											},
											$elm$core$Maybe$map($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue)),
										p.kP),
									$elm$core$Maybe$withDefault($elm$core$Basics$identity),
									initPat.kP.cR),
								A3(
									$elm$core$Basics$apR,
									A2(
										$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.da;
											},
											$elm$core$Maybe$map($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue)),
										p.kP),
									$elm$core$Maybe$withDefault($elm$core$Basics$identity),
									initPat.kP.da),
								A3(
									$elm$core$Basics$apR,
									A2(
										$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.io;
											},
											$elm$core$Maybe$map($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue)),
										p.kP),
									$elm$core$Maybe$withDefault($elm$core$Basics$identity),
									initPat.kP.io));
						},
						pat)),
				A3(
					$elm$core$Basics$apR,
					A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.iB;
							},
							$elm$core$Maybe$map($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue)),
						pat),
					$elm$core$Maybe$withDefault($elm$core$Basics$identity),
					initPat.iB)));
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$situationPatrimonialeFromApi = F2(
	function (sitPatInit, s) {
		var form = $author$project$Perfimmo$Form$FormModel$getBlock(sitPatInit);
		return $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculSitPatFormBlockState(
			_Utils_update(
				form,
				{
					fX: A2(
						$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$budgetFromApi,
						$author$project$Perfimmo$Form$FormModel$getBlock(form.fX),
						s.fX),
					db: A2(
						$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$fiscalitePatFromApi,
						$author$project$Perfimmo$Form$FormModel$getBlock(form.db),
						s.db),
					iD: A2(
						$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$patrimoineFromApi,
						$author$project$Perfimmo$Form$FormModel$getBlock(form.iD),
						s.iD),
					kb: A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setValue, s.kb),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						form.kb)
				}));
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$natureFromApi = F4(
	function (extSync, clients, ppForm, nature) {
		var sitPatInit = ppForm.jV;
		var sitFamInit = ppForm.jU;
		if (!nature.$) {
			var pp = nature.a;
			return $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
				_Utils_update(
					ppForm,
					{
						bf: A2(
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$selectValues,
							A2(
								$elm$core$List$map,
								function (code) {
									return _Utils_Tuple2(
										code,
										A2(
											$elm$core$Maybe$withDefault,
											'NA',
											$PerformanceIMMO$elm_utils$Perfimmo$Data$OfficialDataRef$fromCodeToPays(code)));
								},
								pp.bf),
							ppForm.bf),
						f0: A3(
							$elm$core$Basics$apR,
							A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, pp.f0),
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							ppForm.f0),
						gs: A3(
							$elm$core$Basics$apR,
							A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setValue, pp.gs),
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							ppForm.gs),
						gF: A3(
							$elm$core$Basics$apR,
							A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setValue, pp.gF),
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							ppForm.gF),
						c5: A3(
							$elm$core$Basics$apR,
							A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, pp.c5),
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							ppForm.c5),
						aD: A2(
							$elm$core$Maybe$withDefault,
							ppForm.aD,
							A2(
								$elm$core$Maybe$map,
								$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$identiteFromApi(ppForm.aD),
								pp.aD)),
						aI: A2(
							$elm$core$Maybe$withDefault,
							ppForm.aI,
							A2(
								$elm$core$Maybe$map,
								$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$naissanceFromApi(ppForm.aI),
								pp.aI)),
						bD: A3(
							$elm$core$Basics$apR,
							A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, pp.bD),
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							ppForm.bD),
						iE: A3(
							$elm$core$Basics$apR,
							A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, pp.iE),
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							ppForm.iE),
						iO: A3(
							$elm$core$Basics$apR,
							A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, pp.iO),
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							ppForm.iO),
						iP: A3(
							$elm$core$Basics$apR,
							A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, pp.iP),
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							ppForm.iP),
						du: A2(
							$elm$core$Maybe$withDefault,
							ppForm.du,
							A2(
								$elm$core$Maybe$map,
								$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$pieceIdentiteFromApi(
									$author$project$Perfimmo$Form$FormModel$getBlock(ppForm.du)),
								pp.du)),
						dw: A3(
							$elm$core$Basics$apR,
							A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, pp.dw),
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							ppForm.dw),
						dA: A2(
							$elm$core$List$indexedMap,
							A2($author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$representantLegalFromApi, extSync, false),
							pp.dA),
						jU: A2(
							$elm$core$Maybe$withDefault,
							sitFamInit,
							A2(
								$elm$core$Maybe$map,
								A3($author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$situationFamilialeFromApi, extSync, clients, sitFamInit),
								pp.jS)),
						jV: A2(
							$elm$core$Maybe$withDefault,
							sitPatInit,
							A2(
								$elm$core$Maybe$map,
								$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$situationPatrimonialeFromApi(sitPatInit),
								pp.jV))
					}));
		} else {
			var pm = nature.a;
			var pm_form = $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initPersonneMoraleForm(extSync);
			return $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleForm(
				_Utils_update(
					pm_form,
					{
						fS: A2(
							$elm$core$Maybe$withDefault,
							pm_form.fS,
							A2(
								$elm$core$Maybe$map,
								$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$bilanFromApi(
									$author$project$Perfimmo$Form$FormModel$getBlock(pm_form.fS)),
								pm.fS)),
						f2: A3(
							$elm$core$Basics$apR,
							A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue, pm.f2),
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							pm_form.f2),
						ge: A2(
							$elm$core$Maybe$withDefault,
							pm_form.ge,
							A2(
								$elm$core$Maybe$map,
								$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$compteResultatFromApi(
									$author$project$Perfimmo$Form$FormModel$getBlock(pm_form.ge)),
								pm.ge)),
						c5: A3(
							$elm$core$Basics$apR,
							A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, pm.c5),
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							pm_form.c5),
						db: A2(
							$elm$core$Maybe$withDefault,
							pm_form.db,
							A2(
								$elm$core$Maybe$map,
								$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$fiscaliteFromApi(
									$author$project$Perfimmo$Form$FormModel$getBlock(pm_form.db)),
								pm.db)),
						hd: A3(
							$elm$core$Basics$apR,
							A2(
								$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
								function (code) {
									return A2(
										$elm$core$Maybe$map,
										$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$setValue,
										$author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$fromCodeToFormeJuridiqueModified(code));
								},
								pm.hd),
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							pm_form.hd),
						dl: A3(
							$elm$core$Basics$apR,
							A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, pm.dl),
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							pm_form.dl),
						dA: A2(
							$elm$core$List$indexedMap,
							A2($author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$representantLegalFromApi, extSync, true),
							pm.dA),
						jR: A3(
							$elm$core$Basics$apR,
							A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, pm.jR),
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							pm_form.jR),
						dY: A3(
							$elm$core$Basics$apR,
							A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, pm.dY),
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							pm_form.dY),
						kG: A3(
							$elm$core$Basics$apR,
							A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, pm.kG),
							$elm$core$Maybe$withDefault($elm$core$Basics$identity),
							pm_form.kG)
					}));
		}
	});
var $author$project$Components$Clients$Detail$ViewForm$UtilModels$SwitchWithMiddle_IsLeft = 1;
var $author$project$Components$Clients$Detail$ViewForm$UtilModels$SwitchWithMiddle_IsMiddle = 2;
var $author$project$Components$Clients$Detail$ViewForm$UtilModels$SwitchWithMiddle_IsRight = 0;
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateMiseAJourdateRecueilInfo = function (a) {
	return {$: 14, a: a};
};
var $author$project$Common$Referentiel$ProfilExtraFinancier$DiversiteDesGenresConseilsAdministration = 8;
var $author$project$Common$Referentiel$ProfilExtraFinancier$EgaliteRemuneration = 7;
var $author$project$Common$Referentiel$ProfilExtraFinancier$EmissionsPolluantesEau = 2;
var $author$project$Common$Referentiel$ProfilExtraFinancier$ExpositionAuxArmesControversees = 9;
var $author$project$Common$Referentiel$ProfilExtraFinancier$GazEffetDeSerre = 0;
var $author$project$Common$Referentiel$ProfilExtraFinancier$GenerationDechetsDangereux = 3;
var $author$project$Common$Referentiel$ProfilExtraFinancier$InefficaciteEnergetiqueImmobilier = 4;
var $author$project$Common$Referentiel$ProfilExtraFinancier$NiveauImpactBiodiversite = 1;
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentagePai = function (a) {
	return {$: 10, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr6 = function (a) {
	return {$: 9, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr8e = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr8g = function (a) {
	return {$: 8, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr8s = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr9e = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr9g = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr9s = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageTaxonomie = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompPai = function (a) {
	return {$: 13, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompSdfr = function (a) {
	return {$: 12, a: a};
};
var $author$project$Components$Clients$APIModel$PriseEnCompteESG_Non = {$: 1};
var $author$project$Components$Clients$APIModel$PriseEnCompteESG_Oui = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompteEsg = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$APIModel$PriseEnComptePAI_Non = {$: 1};
var $author$project$Components$Clients$APIModel$PriseEnComptePAI_Oui = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$APIModel$PriseEnCompteSFDR_Non = {$: 1};
var $author$project$Components$Clients$APIModel$PriseEnCompteSFDR_Oui = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompteSection = function (a) {
	return {$: 15, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompteTaxonomie = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$APIModel$PriseEnCompteTaxonomie_Non = {$: 1};
var $author$project$Components$Clients$APIModel$PriseEnCompteTaxonomie_Oui = function (a) {
	return {$: 0, a: a};
};
var $author$project$Common$Referentiel$ProfilExtraFinancier$ProcessusDeControleDesNormesInternationales = 6;
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF = function (a) {
	return {$: 3, a: a};
};
var $author$project$Common$Referentiel$ProfilExtraFinancier$RespectDesNormesInternationales = 5;
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SdfrSection = function (a) {
	return {$: 16, a: a};
};
var $author$project$Components$Clients$Detail$ViewForm$UtilModels$SelectedMiddleSwitch_IsLeft = 1;
var $author$project$Components$Clients$Detail$ViewForm$UtilModels$SelectedMiddleSwitch_IsRight = 0;
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SelectedPai = function (a) {
	return {$: 11, a: a};
};
var $author$project$Components$Clients$ResultUtils$combineBothC = F2(
	function (r1, r2) {
		var _v0 = _Utils_Tuple2(r1, r2);
		if (_v0.a.$ === 1) {
			if (_v0.b.$ === 1) {
				var e1 = _v0.a.a;
				var e2 = _v0.b.a;
				return $elm$core$Result$Err(
					_List_fromArray(
						[e1, e2]));
			} else {
				var e = _v0.a.a;
				return $elm$core$Result$Err(
					_List_fromArray(
						[e]));
			}
		} else {
			if (_v0.b.$ === 1) {
				var e = _v0.b.a;
				return $elm$core$Result$Err(
					_List_fromArray(
						[e]));
			} else {
				var x = _v0.a.a;
				var y = _v0.b.a;
				return $elm$core$Result$Ok(
					_Utils_Tuple2(x, y));
			}
		}
	});
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$profilExtraFinancierToApi = function (form) {
	var switchIsNotMiddleNorMissing = F3(
		function (x, res, y) {
			if (y.$ === 1) {
				return $elm$core$Result$Err(
					_List_fromArray(
						[
							$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
							x(res))
						]));
			} else {
				switch (y.a) {
					case 2:
						var _v27 = y.a;
						return $elm$core$Result$Err(
							_List_fromArray(
								[
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
									x(res))
								]));
					case 0:
						var _v28 = y.a;
						return $elm$core$Result$Ok(0);
					default:
						var _v29 = y.a;
						return $elm$core$Result$Ok(1);
				}
			}
		});
	var priseEnCompteTaxonomie = A3(
		switchIsNotMiddleNorMissing,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompteTaxonomie,
		2,
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.i3));
	var priseEnCompteSdfr = A3(
		switchIsNotMiddleNorMissing,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompSdfr,
		2,
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.i2));
	var priseEnComptePai = A3(
		switchIsNotMiddleNorMissing,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompPai,
		2,
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.i1));
	var missingErrors = F3(
		function (x, res, y) {
			return A2(
				$elm$core$Result$fromMaybe,
				_List_fromArray(
					[
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
						x(res))
					]),
				y);
		});
	var percentagePai = A3(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentagePai,
		'',
		A2(
			$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
			$elm$core$String$toInt,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.iF)));
	var paiIsntZeroWhenOn = A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Result$Ok(''),
		$elm$core$Result$toMaybe(
			A2(
				$elm$core$Result$mapError,
				$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
				A3(
					$author$project$Components$Clients$ResultUtils$map2C,
					F2(
						function (switchState, percentage) {
							if (switchState === 1) {
								return $elm$core$Result$Ok('');
							} else {
								return (!percentage) ? $elm$core$Result$Err(
									_List_fromArray(
										[
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentagePai(''))
										])) : $elm$core$Result$Ok('');
							}
						}),
					priseEnComptePai,
					percentagePai))));
	var percentageSdfr6 = A3(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr6,
		'',
		A2(
			$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
			$elm$core$String$toInt,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.iG)));
	var sdfr6IsntZeroWhenOn = A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Result$Ok(''),
		$elm$core$Result$toMaybe(
			A2(
				$elm$core$Result$mapError,
				$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
				A3(
					$author$project$Components$Clients$ResultUtils$map2C,
					F2(
						function (switchState, sdfr8e) {
							if (switchState === 1) {
								return $elm$core$Result$Ok('');
							} else {
								return (!sdfr8e) ? $elm$core$Result$Err(
									_List_fromArray(
										[
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr6(''))
										])) : $elm$core$Result$Ok('');
							}
						}),
					priseEnCompteSdfr,
					percentageSdfr6))));
	var percentageSdfr8e = A3(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr8e,
		'',
		A2(
			$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
			$elm$core$String$toInt,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.iH)));
	var percentageSdfr8g = A3(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr8g,
		'',
		A2(
			$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
			$elm$core$String$toInt,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.iI)));
	var percentageSdfr8s = A3(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr8s,
		'',
		A2(
			$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
			$elm$core$String$toInt,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.iJ)));
	var sdfr8IsntZeroWhenOn = A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Result$Ok(''),
		$elm$core$Result$toMaybe(
			A2(
				$elm$core$Result$mapError,
				$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
				A5(
					$author$project$Components$Clients$ResultUtils$map4C,
					F4(
						function (switchState, sdfr8e, sdfr8s, sdfr8g) {
							if (switchState === 1) {
								return $elm$core$Result$Ok('');
							} else {
								return ((!sdfr8e) && ((!sdfr8s) && (!sdfr8g))) ? $elm$core$Result$Err(
									_List_fromArray(
										[
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr8e('')),
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr8s('')),
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr8g(''))
										])) : $elm$core$Result$Ok('');
							}
						}),
					priseEnCompteSdfr,
					percentageSdfr8e,
					percentageSdfr8s,
					percentageSdfr8g))));
	var percentageSdfr9e = A3(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr9e,
		'',
		A2(
			$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
			$elm$core$String$toInt,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.iK)));
	var percentageSdfr9g = A3(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr9g,
		'',
		A2(
			$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
			$elm$core$String$toInt,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.iL)));
	var percentageSdfr9s = A3(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr9s,
		'',
		A2(
			$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
			$elm$core$String$toInt,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.iM)));
	var sdfr9IsntZeroWhenOn = A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Result$Ok(''),
		$elm$core$Result$toMaybe(
			A2(
				$elm$core$Result$mapError,
				$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
				A5(
					$author$project$Components$Clients$ResultUtils$map4C,
					F4(
						function (switchState, sdfr9e, sdfr9s, sdfr9g) {
							if (switchState === 1) {
								return $elm$core$Result$Ok('');
							} else {
								return ((!sdfr9e) && ((!sdfr9s) && (!sdfr9g))) ? $elm$core$Result$Err(
									_List_fromArray(
										[
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr9e('')),
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr9s('')),
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr9g(''))
										])) : $elm$core$Result$Ok('');
							}
						}),
					priseEnCompteSdfr,
					percentageSdfr9e,
					percentageSdfr9s,
					percentageSdfr9g))));
	var noSdfrZeroWhenOn = ($elm_community$result_extra$Result$Extra$isErr(sdfr9IsntZeroWhenOn) && ($elm_community$result_extra$Result$Extra$isErr(sdfr8IsntZeroWhenOn) && $elm_community$result_extra$Result$Extra$isErr(sdfr6IsntZeroWhenOn))) ? A2(
		$elm$core$Result$mapError,
		$elm$core$List$append(
			_List_fromArray(
				[
					$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
					$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SdfrSection(''))
				])),
		A2(
			$elm$core$Result$mapError,
			$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
			A4(
				$author$project$Components$Clients$ResultUtils$map3C,
				F3(
					function (sdfr9, sdfr8, sdfr6) {
						return '';
					}),
				sdfr9IsntZeroWhenOn,
				sdfr8IsntZeroWhenOn,
				sdfr6IsntZeroWhenOn))) : $elm$core$Result$Ok('');
	var percentageTaxonomie = A3(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageTaxonomie,
		'',
		A2(
			$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
			$elm$core$String$toInt,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.iN)));
	var taxonomieIsntZeroWhenOn = A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Result$Ok(''),
		$elm$core$Result$toMaybe(
			A2(
				$elm$core$Result$mapError,
				$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
				A3(
					$author$project$Components$Clients$ResultUtils$map2C,
					F2(
						function (switchState, percentage) {
							if (switchState === 1) {
								return $elm$core$Result$Ok('');
							} else {
								return (!percentage) ? $elm$core$Result$Err(
									_List_fromArray(
										[
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageTaxonomie(''))
										])) : $elm$core$Result$Ok('');
							}
						}),
					priseEnCompteTaxonomie,
					percentageTaxonomie))));
	var principalAdverseImpacts = A2(
		$elm$core$Result$map,
		$elm$core$List$filterMap(
			function (el) {
				if (el.b) {
					var _v20 = el.a.b;
					switch (_v20) {
						case 'GazEffetDeSerre':
							return $elm$core$Maybe$Just(0);
						case 'NiveauImpactBiodiversite':
							return $elm$core$Maybe$Just(1);
						case 'EmissionsPolluantesEau':
							return $elm$core$Maybe$Just(2);
						case 'GenerationDechetsDangereux':
							return $elm$core$Maybe$Just(3);
						case 'InefficaciteEnergetiqueImmobilier':
							return $elm$core$Maybe$Just(4);
						case 'RespectDesNormesInternationales':
							return $elm$core$Maybe$Just(5);
						case 'ProcessusDeControleDesNormesInternationales':
							return $elm$core$Maybe$Just(6);
						case 'EgaliteRemuneration':
							return $elm$core$Maybe$Just(7);
						case 'DiversiteDesGenresConseilsAdministration':
							return $elm$core$Maybe$Just(8);
						case 'ExpositionAuxArmesControversees':
							return $elm$core$Maybe$Just(9);
						default:
							return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}),
		A2(
			$elm$core$Result$map,
			$elm$core$Dict$toList,
			A3(
				missingErrors,
				$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SelectedPai,
				$elm$core$Dict$fromList(_List_Nil),
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.cx))));
	var priseEnCompteESG = A3(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompteEsg,
		2,
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.i0));
	var mustHaveEnabledOneSwitch = A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Result$Ok(''),
		$elm$core$Result$toMaybe(
			A2(
				$elm$core$Result$mapError,
				$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
				A5(
					$author$project$Components$Clients$ResultUtils$map4C,
					F4(
						function (esgSwitch, taxoSwitch, sdfrSwitch, paiSwitch) {
							switch (esgSwitch) {
								case 2:
									return $elm$core$Result$Ok('');
								case 1:
									return $elm$core$Result$Ok('');
								default:
									return ((taxoSwitch === 1) && ((sdfrSwitch === 1) && (paiSwitch === 1))) ? $elm$core$Result$Err(
										_List_fromArray(
											[
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompteSection('')),
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompteTaxonomie(2)),
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompSdfr(2)),
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompPai(2))
											])) : $elm$core$Result$Ok('');
							}
						}),
					priseEnCompteESG,
					priseEnCompteTaxonomie,
					priseEnCompteSdfr,
					priseEnComptePai))));
	var dateRecueilInfo = A3(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateMiseAJourdateRecueilInfo,
		'',
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.gy));
	var emptyBlock = $elm_community$result_extra$Result$Extra$isErr(dateRecueilInfo) && $elm_community$result_extra$Result$Extra$isErr(priseEnCompteESG);
	var atLeast1PaiSelectedWhenOn = A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Result$Ok(''),
		$elm$core$Result$toMaybe(
			A2(
				$elm$core$Result$mapError,
				$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
				A3(
					$author$project$Components$Clients$ResultUtils$map2C,
					F2(
						function (switchState, pais) {
							if (switchState === 1) {
								return $elm$core$Result$Ok('');
							} else {
								return (!$elm$core$List$length(pais)) ? $elm$core$Result$Err(
									_List_fromArray(
										[
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SelectedPai(
												$elm$core$Dict$fromList(_List_Nil)))
										])) : $elm$core$Result$Ok('');
							}
						}),
					priseEnComptePai,
					principalAdverseImpacts))));
	var result = function () {
		_v0$2:
		while (true) {
			if (!priseEnCompteESG.$) {
				switch (priseEnCompteESG.a) {
					case 1:
						var _v1 = priseEnCompteESG.a;
						return A2(
							$elm$core$Result$map,
							function (d) {
								return $elm$core$Maybe$Just(
									{gz: d, i$: $author$project$Components$Clients$APIModel$PriseEnCompteESG_Non});
							},
							dateRecueilInfo);
					case 0:
						var _v2 = priseEnCompteESG.a;
						var m = mustHaveEnabledOneSwitch;
						var l = A2(
							$elm$core$Result$mapError,
							$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
							A4(
								$author$project$Components$Clients$ResultUtils$map3C,
								F3(
									function (taxoNotZero, paiNotZero, somePaiSelected) {
										return _Utils_Tuple3(taxoNotZero, paiNotZero, somePaiSelected);
									}),
								taxonomieIsntZeroWhenOn,
								paiIsntZeroWhenOn,
								atLeast1PaiSelectedWhenOn));
						var k = A2(
							$elm$core$Result$mapError,
							$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
							A4(
								$author$project$Components$Clients$ResultUtils$map3C,
								F3(
									function (percPai, pais, noSdfrZero) {
										return _Utils_Tuple3(percPai, pais, noSdfrZero);
									}),
								percentagePai,
								principalAdverseImpacts,
								noSdfrZeroWhenOn));
						var kl = A2(
							$elm$core$Result$mapError,
							$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
							A2($author$project$Components$Clients$ResultUtils$combineBothC, k, l));
						var klm = A2(
							$elm$core$Result$mapError,
							$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
							A2($author$project$Components$Clients$ResultUtils$combineBothC, kl, m));
						var j = A2(
							$elm$core$Result$mapError,
							$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
							A4(
								$author$project$Components$Clients$ResultUtils$map3C,
								F3(
									function (percSdfr8s, percSdfr8g, percSdfr6) {
										return _Utils_Tuple3(percSdfr8s, percSdfr8g, percSdfr6);
									}),
								percentageSdfr8s,
								percentageSdfr8g,
								percentageSdfr6));
						var i = A2(
							$elm$core$Result$mapError,
							$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
							A4(
								$author$project$Components$Clients$ResultUtils$map3C,
								F3(
									function (percSdfr9s, percSdfr9g, percSdfr8e) {
										return _Utils_Tuple3(percSdfr9s, percSdfr9g, percSdfr8e);
									}),
								percentageSdfr9s,
								percentageSdfr9g,
								percentageSdfr8e));
						var h = A2(
							$elm$core$Result$mapError,
							$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
							A4(
								$author$project$Components$Clients$ResultUtils$map3C,
								F3(
									function (ppai, percTax, percSdfr9e) {
										return _Utils_Tuple3(ppai, percTax, percSdfr9e);
									}),
								priseEnComptePai,
								percentageTaxonomie,
								percentageSdfr9e));
						var g = A2(
							$elm$core$Result$mapError,
							$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
							A4(
								$author$project$Components$Clients$ResultUtils$map3C,
								F3(
									function (date, ptax, psdfr) {
										return _Utils_Tuple3(date, ptax, psdfr);
									}),
								dateRecueilInfo,
								priseEnCompteTaxonomie,
								priseEnCompteSdfr));
						var ghij = A2(
							$elm$core$Result$mapError,
							$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
							A2(
								$author$project$Components$Clients$ResultUtils$combineBothC,
								A2(
									$elm$core$Result$mapError,
									$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
									A2($author$project$Components$Clients$ResultUtils$combineBothC, g, h)),
								A2(
									$elm$core$Result$mapError,
									$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
									A2($author$project$Components$Clients$ResultUtils$combineBothC, i, j))));
						return A2(
							$elm$core$Result$map,
							function (_v3) {
								var _v4 = _v3.a;
								var _v5 = _v4.a;
								var _v6 = _v5.a;
								var date = _v6.a;
								var ptax = _v6.b;
								var psdfr = _v6.c;
								var _v7 = _v5.b;
								var ppai = _v7.a;
								var percTax = _v7.b;
								var percSdfr9e = _v7.c;
								var _v8 = _v4.b;
								var _v9 = _v8.a;
								var percSdfr9s = _v9.a;
								var percSdfr9g = _v9.b;
								var percSdfr8e = _v9.c;
								var _v10 = _v8.b;
								var percSdfr8s = _v10.a;
								var percSdfr8g = _v10.b;
								var percSdfr6 = _v10.c;
								var _v11 = _v3.b;
								var _v12 = _v11.a;
								var _v13 = _v12.a;
								var percPai = _v13.a;
								var pais = _v13.b;
								var _v14 = _v12.b;
								return $elm$core$Maybe$Just(
									{
										gz: date,
										i$: $author$project$Components$Clients$APIModel$PriseEnCompteESG_Oui(
											{
												i1: function () {
													if (ppai === 1) {
														return $author$project$Components$Clients$APIModel$PriseEnComptePAI_Non;
													} else {
														return $author$project$Components$Clients$APIModel$PriseEnComptePAI_Oui(
															{iz: pais, dv: percPai});
													}
												}(),
												i2: function () {
													if (psdfr === 1) {
														return $author$project$Components$Clients$APIModel$PriseEnCompteSFDR_Non;
													} else {
														return $author$project$Components$Clients$APIModel$PriseEnCompteSFDR_Oui(
															{
																i9: percSdfr6,
																ja: {iR: percSdfr8e, iS: percSdfr8g, iT: percSdfr8s},
																jb: {iR: percSdfr9e, iS: percSdfr9g, iT: percSdfr9s}
															});
													}
												}(),
												i3: function () {
													if (ptax === 1) {
														return $author$project$Components$Clients$APIModel$PriseEnCompteTaxonomie_Non;
													} else {
														return $author$project$Components$Clients$APIModel$PriseEnCompteTaxonomie_Oui(
															{dv: percTax});
													}
												}()
											})
									});
							},
							A2(
								$elm$core$Result$mapError,
								$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
								A2($author$project$Components$Clients$ResultUtils$combineBothC, ghij, klm)));
					default:
						break _v0$2;
				}
			} else {
				break _v0$2;
			}
		}
		return $elm$core$Result$Ok($elm$core$Maybe$Nothing);
	}();
	return emptyBlock ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : result;
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculProfilExtraFinancierFormBlockState = function (profil) {
	var _v0 = $author$project$Components$Clients$Detail$UpdateForm$FormValidator$profilExtraFinancierToApi(profil);
	if (!_v0.$) {
		if (!_v0.a.$) {
			return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(profil);
		} else {
			var _v1 = _v0.a;
			return $author$project$Perfimmo$Form$FormModel$OptionalBlock(profil);
		}
	} else {
		return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(profil);
	}
};
var $author$project$Components$Clients$QueryAPIModel$paiQToString = function (pai) {
	switch (pai) {
		case 0:
			return 'GazEffetDeSerre';
		case 1:
			return 'NiveauImpactBiodiversite';
		case 2:
			return 'EmissionsPolluantesEau';
		case 3:
			return 'GenerationDechetsDangereux';
		case 4:
			return 'InefficaciteEnergetiqueImmobilier';
		case 5:
			return 'RespectDesNormesInternationales';
		case 6:
			return 'ProcessusDeControleDesNormesInternationales';
		case 7:
			return 'EgaliteRemuneration';
		case 8:
			return 'DiversiteDesGenresConseilsAdministration';
		default:
			return 'ExpositionAuxArmesControversees';
	}
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$profilExtraFinancierFromApi = F2(
	function (profilFormInit, profil) {
		var profil_form = $author$project$Perfimmo$Form$FormModel$getBlock(profilFormInit);
		var pef_default_val = '0';
		return $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculProfilExtraFinancierFormBlockState(
			_Utils_update(
				profil_form,
				{
					gy: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setValue, profil.gz, profil_form.gy),
					iF: function () {
						var _v0 = profil.i$;
						if (!_v0.$) {
							var priseEnCompteEsg = _v0.a;
							var _v1 = priseEnCompteEsg.i1;
							if (!_v1.$) {
								var val = _v1.a;
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(
									$elm$core$String$fromInt(val.dv));
							} else {
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
							}
						} else {
							return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
						}
					}()(profil_form.iF),
					iG: function () {
						var _v2 = profil.i$;
						if (!_v2.$) {
							var priseEnCompteEsg = _v2.a;
							var _v3 = priseEnCompteEsg.i2;
							if (!_v3.$) {
								var val = _v3.a;
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(
									$elm$core$String$fromInt(val.i9));
							} else {
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
							}
						} else {
							return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
						}
					}()(profil_form.iG),
					iH: function () {
						var _v4 = profil.i$;
						if (!_v4.$) {
							var priseEnCompteEsg = _v4.a;
							var _v5 = priseEnCompteEsg.i2;
							if (!_v5.$) {
								var val = _v5.a;
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(
									$elm$core$String$fromInt(val.ja.iR));
							} else {
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
							}
						} else {
							return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
						}
					}()(profil_form.iH),
					iI: function () {
						var _v6 = profil.i$;
						if (!_v6.$) {
							var priseEnCompteEsg = _v6.a;
							var _v7 = priseEnCompteEsg.i2;
							if (!_v7.$) {
								var val = _v7.a;
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(
									$elm$core$String$fromInt(val.ja.iS));
							} else {
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
							}
						} else {
							return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
						}
					}()(profil_form.iI),
					iJ: function () {
						var _v8 = profil.i$;
						if (!_v8.$) {
							var priseEnCompteEsg = _v8.a;
							var _v9 = priseEnCompteEsg.i2;
							if (!_v9.$) {
								var val = _v9.a;
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(
									$elm$core$String$fromInt(val.ja.iT));
							} else {
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
							}
						} else {
							return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
						}
					}()(profil_form.iJ),
					iK: function () {
						var _v10 = profil.i$;
						if (!_v10.$) {
							var priseEnCompteEsg = _v10.a;
							var _v11 = priseEnCompteEsg.i2;
							if (!_v11.$) {
								var val = _v11.a;
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(
									$elm$core$String$fromInt(val.jb.iR));
							} else {
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
							}
						} else {
							return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
						}
					}()(profil_form.iK),
					iL: function () {
						var _v12 = profil.i$;
						if (!_v12.$) {
							var priseEnCompteEsg = _v12.a;
							var _v13 = priseEnCompteEsg.i2;
							if (!_v13.$) {
								var val = _v13.a;
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(
									$elm$core$String$fromInt(val.jb.iS));
							} else {
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
							}
						} else {
							return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
						}
					}()(profil_form.iL),
					iM: function () {
						var _v14 = profil.i$;
						if (!_v14.$) {
							var priseEnCompteEsg = _v14.a;
							var _v15 = priseEnCompteEsg.i2;
							if (!_v15.$) {
								var val = _v15.a;
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(
									$elm$core$String$fromInt(val.jb.iT));
							} else {
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
							}
						} else {
							return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
						}
					}()(profil_form.iM),
					iN: function () {
						var _v16 = profil.i$;
						if (!_v16.$) {
							var priseEnCompteEsg = _v16.a;
							var _v17 = priseEnCompteEsg.i3;
							if (!_v17.$) {
								var taxonomie = _v17.a;
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(
									$elm$core$String$fromInt(taxonomie.dv));
							} else {
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
							}
						} else {
							return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(pef_default_val);
						}
					}()(profil_form.iN),
					cx: function () {
						var _v18 = profil.i$;
						if (!_v18.$) {
							var priseEnCompteEsg = _v18.a;
							var _v19 = priseEnCompteEsg.i1;
							if (!_v19.$) {
								var val = _v19.a;
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(
									$elm$core$Dict$fromList(
										A2(
											$elm$core$List$map,
											function (fromAll) {
												var text = fromAll.a.a;
												var key = fromAll.a.b;
												var isSelected = A2(
													$elm$core$List$any,
													function (pai) {
														return _Utils_eq(
															$author$project$Components$Clients$QueryAPIModel$paiQToString(pai),
															key);
													},
													val.iz);
												return _Utils_Tuple2(
													_Utils_Tuple2(text, key),
													isSelected);
											},
											A2(
												$elm$core$Maybe$withDefault,
												_List_Nil,
												A2(
													$elm$core$Maybe$map,
													$elm$core$Dict$toList,
													$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(profil_form.cx))))));
							} else {
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(
									A2(
										$elm$core$Maybe$withDefault,
										$elm$core$Dict$fromList(_List_Nil),
										$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(profil_form.cx)));
							}
						} else {
							return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(
								A2(
									$elm$core$Maybe$withDefault,
									$elm$core$Dict$fromList(_List_Nil),
									$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(profil_form.cx)));
						}
					}()(profil_form.cx),
					i0: function () {
						var _v20 = profil.i$;
						if (!_v20.$) {
							return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(0);
						} else {
							return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(1);
						}
					}()(profil_form.i0),
					i1: function () {
						var _v21 = profil.i$;
						if (!_v21.$) {
							var priseEnCompteEsg = _v21.a;
							var _v22 = priseEnCompteEsg.i1;
							if (!_v22.$) {
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(0);
							} else {
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(1);
							}
						} else {
							return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(2);
						}
					}()(profil_form.i1),
					i2: function () {
						var _v23 = profil.i$;
						if (!_v23.$) {
							var priseEnCompteEsg = _v23.a;
							var _v24 = priseEnCompteEsg.i2;
							if (!_v24.$) {
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(0);
							} else {
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(1);
							}
						} else {
							return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(2);
						}
					}()(profil_form.i2),
					i3: function () {
						var _v25 = profil.i$;
						if (!_v25.$) {
							var priseEnCompteEsg = _v25.a;
							var _v26 = priseEnCompteEsg.i3;
							if (!_v26.$) {
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(0);
							} else {
								return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(1);
							}
						} else {
							return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue(2);
						}
					}()(profil_form.i3)
				}));
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CapaciteSubirPertes = function (a) {
	return {$: 10, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ClassificationMifIIF = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConnaissanceExperienceFinanciere = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateEntreeRelation = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$HorizonInvestissement = function (a) {
	return {$: 8, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ObjectifInvestissement = function (a) {
	return {$: 9, a: a};
};
var $author$project$Components$Clients$APIModel$ProfilInvestisseur = function (dateEntreeRelation) {
	return function (dateTransformationRelation) {
		return function (dateMajConnaissance) {
			return function (dateMajProfilInvestisseur) {
				return function (dateSignatureConventionReceptionTransmissionOrdre) {
					return function (classificationMifII) {
						return function (connaissanceExperienceFinanciere) {
							return function (profilRisqueInvestisseur) {
								return function (horizonInvestissement) {
									return function (objectifInvestissement) {
										return function (capaciteSubirPertes) {
											return function (toleranceAuxRisques) {
												return {f1: capaciteSubirPertes, ea: classificationMifII, gg: connaissanceExperienceFinanciere, gt: dateEntreeRelation, gw: dateMajConnaissance, gx: dateMajProfilInvestisseur, gA: dateSignatureConventionReceptionTransmissionOrdre, gB: dateTransformationRelation, hz: horizonInvestissement, it: objectifInvestissement, ji: profilRisqueInvestisseur, km: toleranceAuxRisques};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilInvestisseurF = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilRisqueInvestisseur = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ToleranceAuxRisques = function (a) {
	return {$: 11, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$classificationMifIIIsEmpty = function (classificationMifII) {
	if (!classificationMifII.$) {
		return false;
	} else {
		var errs = classificationMifII.a;
		return $elm$core$List$length(errs) === 2;
	}
};
var $author$project$Components$Clients$APIModel$ClassificationMifII = F2(
	function (type_, date) {
		return {c$: date, kv: type_};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ClassificationMifIIDate = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ClassificationMifIIType = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$classificationMifIIToApi = function (form) {
	var type_ = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ClassificationMifIIType(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.kv));
	var date = A2(
		$elm$core$Result$fromMaybe,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ClassificationMifIIDate(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c$));
	return A3(
		$author$project$Components$Clients$ResultUtils$map2C,
		F2(
			function (t, d) {
				return A2($author$project$Components$Clients$APIModel$ClassificationMifII, t, d);
			}),
		type_,
		date);
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$profilInvestisseurToApi = function (form) {
	var missingErrors = F2(
		function (x, y) {
			return A2(
				$elm$core$Result$fromMaybe,
				_List_fromArray(
					[
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilInvestisseurF(
						x(''))
					]),
				y);
		});
	var objectifInvestissement = A2(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ObjectifInvestissement,
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.it));
	var profilRisqueInvestisseur = A2(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilRisqueInvestisseur,
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.ji));
	var toleranceAuxRisques = A2(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ToleranceAuxRisques,
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.km));
	var horizonInvestissement = A2(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$HorizonInvestissement,
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.hz));
	var dateTransformationRelation = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.gB);
	var dateSignatureConventionReceptionTransmissionOrdre = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.gA);
	var dateMajProfilInvestisseur = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.gx);
	var dateMajConnaissance = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.gw);
	var dateEntreeRelation = A2(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateEntreeRelation,
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.gt));
	var connaissanceExperienceFinanciere = A2(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConnaissanceExperienceFinanciere,
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.gg));
	var h = A2(
		$elm$core$Result$mapError,
		$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
		A4(
			$author$project$Components$Clients$ResultUtils$map3C,
			F3(
				function (conn, profil, hor) {
					return _Utils_Tuple3(conn, profil, hor);
				}),
			connaissanceExperienceFinanciere,
			profilRisqueInvestisseur,
			horizonInvestissement));
	var classificationMifII = A2(
		$elm$core$Result$mapError,
		$elm$core$List$map(
			A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ClassificationMifIIF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilInvestisseurF)),
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$classificationMifIIToApi(form.ea));
	var g = A2(
		$elm$core$Result$mapError,
		$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
		A3(
			$author$project$Components$Clients$ResultUtils$map2C,
			F2(
				function (dateEntree, mifi) {
					return _Utils_Tuple2(dateEntree, mifi);
				}),
			dateEntreeRelation,
			classificationMifII));
	var capaciteSubirPertes = A2(
		missingErrors,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CapaciteSubirPertes,
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.f1));
	var emptyBlock = $elm_community$result_extra$Result$Extra$isErr(dateEntreeRelation) && (_Utils_eq(dateTransformationRelation, $elm$core$Maybe$Nothing) && (_Utils_eq(dateMajConnaissance, $elm$core$Maybe$Nothing) && (_Utils_eq(dateMajProfilInvestisseur, $elm$core$Maybe$Nothing) && (_Utils_eq(dateSignatureConventionReceptionTransmissionOrdre, $elm$core$Maybe$Nothing) && ($elm_community$result_extra$Result$Extra$isErr(connaissanceExperienceFinanciere) && ($elm_community$result_extra$Result$Extra$isErr(profilRisqueInvestisseur) && ($elm_community$result_extra$Result$Extra$isErr(horizonInvestissement) && ($elm_community$result_extra$Result$Extra$isErr(objectifInvestissement) && ($elm_community$result_extra$Result$Extra$isErr(capaciteSubirPertes) && ($elm_community$result_extra$Result$Extra$isErr(toleranceAuxRisques) && $author$project$Components$Clients$Detail$UpdateForm$FormValidator$classificationMifIIIsEmpty(classificationMifII)))))))))));
	var i = A4(
		$author$project$Components$Clients$ResultUtils$map3C,
		F3(
			function (obj, cap, tol) {
				return _Utils_Tuple3(obj, cap, tol);
			}),
		objectifInvestissement,
		capaciteSubirPertes,
		toleranceAuxRisques);
	var result = A2(
		$elm$core$Result$mapError,
		$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
		A2(
			$elm$core$Result$mapError,
			$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
			A2(
				$elm$core$Result$map,
				function (_v0) {
					var _v1 = _v0.a;
					var obj = _v1.a;
					var cap = _v1.b;
					var tol = _v1.c;
					var _v2 = _v0.b;
					var _v3 = _v2.a;
					var dateEntree = _v3.a;
					var mifi = _v3.b;
					var _v4 = _v2.b;
					var conn = _v4.a;
					var profil = _v4.b;
					var hor = _v4.c;
					return $elm$core$Maybe$Just(
						$author$project$Components$Clients$APIModel$ProfilInvestisseur(dateEntree)(dateTransformationRelation)(dateMajConnaissance)(dateMajProfilInvestisseur)(dateSignatureConventionReceptionTransmissionOrdre)(mifi)(conn)(profil)(hor)(obj)(cap)(tol));
				},
				A2(
					$author$project$Components$Clients$ResultUtils$combineBothC,
					i,
					A2($author$project$Components$Clients$ResultUtils$combineBothC, g, h)))));
	return emptyBlock ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : result;
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculProfilInvestisseurFormBlockState = function (profil) {
	var _v0 = $author$project$Components$Clients$Detail$UpdateForm$FormValidator$profilInvestisseurToApi(profil);
	if (!_v0.$) {
		if (!_v0.a.$) {
			return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(profil);
		} else {
			var _v1 = _v0.a;
			return $author$project$Perfimmo$Form$FormModel$OptionalBlock(profil);
		}
	} else {
		return $author$project$Perfimmo$Form$FormModel$MandatoryBlock(profil);
	}
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$classificationMifIIFromApi = F2(
	function (classMifIIInit, classMifII) {
		return A2(
			$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ClassificationMifIIForm,
			A3(
				$elm$core$Basics$apR,
				A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, classMifII.kv),
				$elm$core$Maybe$withDefault($elm$core$Basics$identity),
				classMifIIInit.kv),
			A3(
				$elm$core$Basics$apR,
				A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, classMifII.c$),
				$elm$core$Maybe$withDefault($elm$core$Basics$identity),
				classMifIIInit.c$));
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$profilInvestFromApi = F2(
	function (profilFormInit, profil) {
		var profil_form = $author$project$Perfimmo$Form$FormModel$getBlock(profilFormInit);
		return $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculProfilInvestisseurFormBlockState(
			_Utils_update(
				profil_form,
				{
					f1: A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, profil.f1),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						profil_form.f1),
					ea: A2(
						$elm$core$Maybe$withDefault,
						profil_form.ea,
						A2(
							$elm$core$Maybe$map,
							$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$classificationMifIIFromApi(profil_form.ea),
							profil.ea)),
					gg: A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, profil.gg),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						profil_form.gg),
					gt: A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setValue, profil.gt),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						profil_form.gt),
					gw: A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setValue, profil.gw),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						profil_form.gw),
					gx: A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setValue, profil.gx),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						profil_form.gx),
					gA: A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setValue, profil.gA),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						profil_form.gA),
					gB: A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setValue, profil.gB),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						profil_form.gB),
					hz: A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, profil.hz),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						profil_form.hz),
					it: A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, profil.it),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						profil_form.it),
					ji: A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, profil.ji),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						profil_form.ji),
					km: A3(
						$elm$core$Basics$apR,
						A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValue, profil.km),
						$elm$core$Maybe$withDefault($elm$core$Basics$identity),
						profil_form.km)
				}));
	});
var $author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$fromClientQueryView = F4(
	function (extSync, client, clients, conseillersRef) {
		var sitFamFormInit = A2(
			$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initSitFamForm,
			extSync,
			A2(
				$elm$core$List$map,
				function ($) {
					return $.nz;
				},
				clients));
		var profilInvestFormInit = $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initProfilInvestisseurForm(extSync);
		var profilExtraFinancierFormInit = $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initProfilExtraFinancierForm(extSync);
		var ppFormInit = A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initPersonnePhysiqueForm, extSync, sitFamFormInit);
		var conseillersFormInit = A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initConseillers, extSync, conseillersRef);
		if (client.$ === 1) {
			return A5(
				$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ClientFormRepr,
				$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(ppFormInit),
				$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initContactForm(extSync),
				profilInvestFormInit,
				profilExtraFinancierFormInit,
				conseillersFormInit);
		} else {
			var c = client.a;
			return A5(
				$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ClientFormRepr,
				A4($author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$natureFromApi, extSync, clients, ppFormInit, c.ic),
				A2($author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$contactFromApi, extSync, c.cY),
				A2(
					$elm$core$Maybe$withDefault,
					profilInvestFormInit,
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$profilInvestFromApi(profilInvestFormInit),
						c.jh)),
				A2(
					$elm$core$Maybe$withDefault,
					profilExtraFinancierFormInit,
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$profilExtraFinancierFromApi(profilExtraFinancierFormInit),
						c.jg)),
				A2(
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$selectValues,
					A2(
						$elm$core$List$filterMap,
						function (clientFromApi) {
							return A2(
								$elm$core$Maybe$map,
								function (cref) {
									return _Utils_Tuple2(cref.kx, cref.nz);
								},
								A2(
									$elm_community$list_extra$List$Extra$find,
									function (cref) {
										return _Utils_eq(cref.kx, clientFromApi);
									},
									conseillersRef));
						},
						c.ef),
					conseillersFormInit));
		}
	});
var $author$project$Components$Clients$Detail$Model$initModel = F4(
	function (extSyncStatus, clients, conseillers, initState) {
		var appLink = function () {
			if (!initState.$) {
				var getClientLink = initState.a;
				return A3(
					$author$project$Components$Clients$Detail$Model$AppLink,
					$elm$core$Maybe$Just(getClientLink),
					$elm$core$Maybe$Nothing,
					$elm$core$Maybe$Nothing);
			} else {
				var createClientLink = initState.a;
				return A3(
					$author$project$Components$Clients$Detail$Model$AppLink,
					$elm$core$Maybe$Nothing,
					$elm$core$Maybe$Nothing,
					$elm$core$Maybe$Just(createClientLink));
			}
		}();
		return $author$project$Components$Clients$Detail$Model$Model(appLink)(clients)(conseillers)($elm$core$Maybe$Nothing)(extSyncStatus)($author$project$Components$Clients$Detail$Model$ShouldNotBeSync)(
			A4($author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$fromClientQueryView, extSyncStatus, $elm$core$Maybe$Nothing, clients, conseillers))(0)($author$project$Components$Clients$Detail$SaveState$NotModified)(_List_Nil)($elm$core$Maybe$Nothing);
	});
var $author$project$Routes$ClientsComponentBuilder$initDetailModel = function (model) {
	return A3($author$project$Components$Clients$Detail$Model$initModel, model.k.eN, model.d$, model.k8);
};
var $author$project$Routes$ClientsComponentBuilder$clientDetail = F3(
	function (clientId, rootApiLinks, previousPage) {
		var client = function (model) {
			return A2(
				$elm_community$list_extra$List$Extra$find,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.kx;
					},
					$elm$core$Basics$eq(clientId)),
				model.ca);
		};
		var detailModel = function (model) {
			return A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Components$Clients$Detail$Model$UpdateClient,
					$author$project$Routes$ClientsComponentBuilder$initDetailModel(model)),
				A2(
					$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
					function (c) {
						return A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'self', c.nf);
					},
					client(model)));
		};
		var goToDetail = function (model) {
			return A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2(
					$author$project$Routes$Pages$Error(0),
					$elm$core$Platform$Cmd$none),
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Tuple$mapBoth,
						$author$project$Routes$Pages$ClientDetail,
						$elm$core$Platform$Cmd$map($author$project$Main$Msg$ClientsDetailComponentAction)),
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$Clients$Detail$Update$init,
						detailModel(model))));
		};
		if (previousPage.$ === 8) {
			var model = previousPage.b;
			return goToDetail(model);
		} else {
			var goToNextPage = function (page) {
				if (page.$ === 8) {
					var model = page.b;
					return A2(
						$elm$core$Maybe$map,
						function (_v2) {
							return $elm$core$Result$Ok(
								goToDetail(model));
						},
						client(model));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			};
			return A2(
				$author$project$Routes$ClientsComponentBuilder$clients,
				$elm$core$Maybe$Just(goToNextPage),
				rootApiLinks);
		}
	});
var $author$project$Components$ForgotPassword$Model$Model = F4(
	function (askResetPasswordLink, login, saveState, processUid) {
		return {k0: askResetPasswordLink, nh: login, n4: processUid, oq: saveState};
	});
var $author$project$Components$ForgotPassword$Model$NotReady = 0;
var $author$project$Components$ForgotPassword$Model$init = function (askResetPasswordLink) {
	return A4($author$project$Components$ForgotPassword$Model$Model, askResetPasswordLink, $elm$core$Maybe$Nothing, 0, $elm$core$Maybe$Nothing);
};
var $author$project$Components$ForgotPassword$Update$init = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$Routes$ComponentBuilder$forgotPassword = function (rootApiLinks) {
	var resetPasswordDemandLink = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'resetPasswordDemand', rootApiLinks.n0);
	var init = function (url) {
		return A3(
			$elm$core$Tuple$mapBoth,
			$author$project$Routes$Pages$ForgotPassword,
			$elm$core$Platform$Cmd$map($author$project$Main$Msg$ForgotPasswordComponentAction),
			$author$project$Components$ForgotPassword$Update$init(
				$author$project$Components$ForgotPassword$Model$init(url)));
	};
	return A2(
		$elm$core$Maybe$withDefault,
		_Utils_Tuple2(
			$author$project$Routes$Pages$Error(0),
			$elm$core$Platform$Cmd$none),
		A2($elm$core$Maybe$map, init, resetPasswordDemandLink));
};
var $author$project$Common$Identifiants$CabinetUid = $elm$core$Basics$identity;
var $author$project$Components$Supports$ImportSupports$List$Model$ImportCompletCabinet = 2;
var $author$project$Components$Supports$ImportSupports$List$Model$ImportCompletSupports = 0;
var $author$project$Components$Supports$ImportSupports$List$Model$ImportDansReferentiel = 4;
var $author$project$Components$Supports$ImportSupports$List$Model$ImportPartielCabinet = 3;
var $author$project$Components$Supports$ImportSupports$List$Model$ImportPartielSupports = 1;
var $author$project$Components$Supports$API$ListImportsPassesResult$Pending = 0;
var $author$project$Components$Supports$API$ListImportsPassesResult$Successful = 2;
var $author$project$Components$Supports$API$ListImportsPassesResult$Warning = 1;
var $author$project$Components$Supports$ImportSupports$List$Model$allImportStatut = function () {
	var next = function (types) {
		var _v0 = $elm$core$List$head(types);
		if (_v0.$ === 1) {
			return next(
				A2($elm$core$List$cons, 2, types));
		} else {
			switch (_v0.a) {
				case 2:
					var _v1 = _v0.a;
					return next(
						A2($elm$core$List$cons, 0, types));
				case 0:
					var _v2 = _v0.a;
					return next(
						A2($elm$core$List$cons, 1, types));
				default:
					var _v3 = _v0.a;
					return types;
			}
		}
	};
	return $elm$core$List$reverse(
		next(_List_Nil));
}();
var $author$project$Components$Supports$ImportSupports$List$Model$importStatutToString = function (importStatut) {
	switch (importStatut) {
		case 2:
			return 'Import Ok';
		case 0:
			return 'En cours';
		default:
			return 'En attente de résolution';
	}
};
var $author$project$Components$Supports$ImportSupports$List$Model$mappingImportStatut = $elm$core$Dict$fromList(
	A2(
		$elm$core$List$map,
		function (import_) {
			return _Utils_Tuple2(
				$author$project$Components$Supports$ImportSupports$List$Model$importStatutToString(import_),
				import_);
		},
		$author$project$Components$Supports$ImportSupports$List$Model$allImportStatut));
var $author$project$Components$Supports$ImportSupports$List$Model$parseImportStatut = function (input) {
	return A2($elm$core$Dict$get, input, $author$project$Components$Supports$ImportSupports$List$Model$mappingImportStatut);
};
var $author$project$Components$Supports$ImportSupports$List$Model$allImportTypes = function () {
	var next = function (types) {
		var _v0 = $elm$core$List$head(types);
		if (_v0.$ === 1) {
			return next(
				A2($elm$core$List$cons, 0, types));
		} else {
			switch (_v0.a) {
				case 0:
					var _v1 = _v0.a;
					return next(
						A2($elm$core$List$cons, 1, types));
				case 1:
					var _v2 = _v0.a;
					return next(
						A2($elm$core$List$cons, 2, types));
				case 2:
					var _v3 = _v0.a;
					return next(
						A2($elm$core$List$cons, 3, types));
				case 3:
					var _v4 = _v0.a;
					return next(
						A2($elm$core$List$cons, 4, types));
				default:
					var _v5 = _v0.a;
					return types;
			}
		}
	};
	return $elm$core$List$reverse(
		next(_List_Nil));
}();
var $author$project$Components$Supports$ImportSupports$List$Model$importTypeToString = function (importType) {
	switch (importType) {
		case 0:
			return 'Import complet des supports d\'un produit';
		case 1:
			return 'Import partiel des supports d\'un produit';
		case 2:
			return 'Import complet des commissions d\'un CGP pour un produit';
		case 3:
			return 'Import partiel des commissions d\'un CGP pour un produit';
		default:
			return 'Import d\'ajout de supports dans le référentiel';
	}
};
var $author$project$Components$Supports$ImportSupports$List$Model$mappingImportTypeChosen = $elm$core$Dict$fromList(
	A2(
		$elm$core$List$map,
		function (import_) {
			return _Utils_Tuple2(
				$author$project$Components$Supports$ImportSupports$List$Model$importTypeToString(import_),
				import_);
		},
		$author$project$Components$Supports$ImportSupports$List$Model$allImportTypes));
var $author$project$Components$Supports$ImportSupports$List$Model$parseImportType = function (input) {
	return A2($elm$core$Dict$get, input, $author$project$Components$Supports$ImportSupports$List$Model$mappingImportTypeChosen);
};
var $author$project$Components$Supports$ImportSupports$List$Model$appliquerLesFiltres = function (model) {
	var filtres = model.aY;
	var selectedCabinets = $inkuzmin$elm_multiselect$Multiselect$getSelectedValues(filtres.aS);
	var selectedImportTypes = $inkuzmin$elm_multiselect$Multiselect$getSelectedValues(filtres.b6);
	var selectedProduits = $inkuzmin$elm_multiselect$Multiselect$getSelectedValues(filtres.aT);
	var selectedStatuts = $inkuzmin$elm_multiselect$Multiselect$getSelectedValues(filtres.b7);
	var filteredImports = A2(
		$elm$core$List$filter,
		function (imp) {
			return $elm$core$List$isEmpty(selectedStatuts) ? true : A2(
				$elm$core$List$any,
				function (_v6) {
					var statut = _v6.a;
					return _Utils_eq(
						$author$project$Components$Supports$ImportSupports$List$Model$parseImportStatut(statut),
						$elm$core$Maybe$Just(imp.j$));
				},
				selectedStatuts);
		},
		A2(
			$elm$core$List$filter,
			function (imp) {
				return $elm$core$List$isEmpty(selectedProduits) ? true : A2(
					$elm$core$List$any,
					function (_v4) {
						var pUid = _v4.a;
						var _v5 = imp.kt;
						switch (_v5.$) {
							case 0:
								var prod = _v5.a;
								return _Utils_eq(prod.kx, pUid);
							case 1:
								var prod = _v5.a;
								return _Utils_eq(prod.kx, pUid);
							case 2:
								var prod = _v5.a;
								return _Utils_eq(prod.kx, pUid);
							case 3:
								var prod = _v5.a;
								return _Utils_eq(prod.kx, pUid);
							default:
								return false;
						}
					},
					selectedProduits);
			},
			A2(
				$elm$core$List$filter,
				function (imp) {
					return $elm$core$List$isEmpty(selectedImportTypes) ? true : A2(
						$elm$core$List$any,
						function (_v2) {
							var chosenType = _v2.a;
							var _v3 = imp.kt;
							switch (_v3.$) {
								case 0:
									return _Utils_eq(
										$author$project$Components$Supports$ImportSupports$List$Model$parseImportType(chosenType),
										$elm$core$Maybe$Just(0));
								case 1:
									return _Utils_eq(
										$author$project$Components$Supports$ImportSupports$List$Model$parseImportType(chosenType),
										$elm$core$Maybe$Just(1));
								case 2:
									return _Utils_eq(
										$author$project$Components$Supports$ImportSupports$List$Model$parseImportType(chosenType),
										$elm$core$Maybe$Just(2));
								case 3:
									return _Utils_eq(
										$author$project$Components$Supports$ImportSupports$List$Model$parseImportType(chosenType),
										$elm$core$Maybe$Just(3));
								default:
									return _Utils_eq(
										$author$project$Components$Supports$ImportSupports$List$Model$parseImportType(chosenType),
										$elm$core$Maybe$Just(4));
							}
						},
						selectedImportTypes);
				},
				A2(
					$elm$core$List$filter,
					function (imp) {
						return $elm$core$List$isEmpty(selectedCabinets) ? true : A2(
							$elm$core$List$any,
							function (_v0) {
								var cabId = _v0.a;
								var _v1 = imp.kt;
								switch (_v1.$) {
									case 0:
										return false;
									case 1:
										return false;
									case 2:
										var cab = _v1.b;
										return _Utils_eq(cab.kx, cabId);
									case 3:
										var cab = _v1.b;
										return _Utils_eq(cab.kx, cabId);
									default:
										return false;
								}
							},
							selectedCabinets);
					},
					model.a8.mW))));
	return _Utils_update(
		model,
		{eD: filteredImports});
};
var $author$project$Components$Supports$ImportSupports$List$Model$initImportTypesSelect = A2(
	$elm$core$List$map,
	function (i) {
		return _Utils_Tuple2(
			$author$project$Components$Supports$ImportSupports$List$Model$importTypeToString(i),
			$author$project$Components$Supports$ImportSupports$List$Model$importTypeToString(i));
	},
	$author$project$Components$Supports$ImportSupports$List$Model$allImportTypes);
var $author$project$Components$Supports$ImportSupports$List$Model$initStatutSelect = A2(
	$elm$core$List$map,
	function (i) {
		return _Utils_Tuple2(
			$author$project$Components$Supports$ImportSupports$List$Model$importStatutToString(i),
			$author$project$Components$Supports$ImportSupports$List$Model$importStatutToString(i));
	},
	$author$project$Components$Supports$ImportSupports$List$Model$allImportStatut);
var $author$project$Components$Supports$ImportSupports$List$Model$initFiltres = {
	aS: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'byCabinets', 0),
	b6: A3($inkuzmin$elm_multiselect$Multiselect$initModel, $author$project$Components$Supports$ImportSupports$List$Model$initImportTypesSelect, 'byTypeImports', 0),
	aT: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'byProduits', 0),
	b7: A3($inkuzmin$elm_multiselect$Multiselect$initModel, $author$project$Components$Supports$ImportSupports$List$Model$initStatutSelect, 'byStatuts', 0)
};
var $author$project$Components$Drawer$Model$Closed = 1;
var $author$project$Components$Drawer$Model$init = {ow: 1};
var $author$project$Perfimmo$Input$SelectInput$HideItems = 0;
var $author$project$Perfimmo$Input$SelectInput$initSelect = F3(
	function (id, placeholder, items) {
		return {aV: 0, hB: id, aE: items, cv: placeholder, dO: $elm$core$Maybe$Nothing};
	});
var $author$project$Components$Supports$ImportSupports$List$Model$initImportFile = {
	eq: $author$project$Components$Drawer$Model$init,
	ch: $elm$core$Maybe$Nothing,
	a0: $elm$core$Maybe$Nothing,
	dd: A3(
		$author$project$Perfimmo$Input$SelectInput$initSelect,
		'importTypesSelector',
		'Choisir un type d\'import de fichier',
		A2(
			$elm$core$List$map,
			function (x) {
				return _Utils_Tuple2(
					x,
					$author$project$Components$Supports$ImportSupports$List$Model$importTypeToString(x));
			},
			$author$project$Components$Supports$ImportSupports$List$Model$allImportTypes)),
	aG: false
};
var $author$project$Components$Supports$ImportSupports$List$Model$init = F2(
	function (timezone, getImportsLink) {
		return $author$project$Components$Supports$ImportSupports$List$Model$appliquerLesFiltres(
			{
				eD: _List_Nil,
				aY: $author$project$Components$Supports$ImportSupports$List$Model$initFiltres,
				hq: getImportsLink,
				hx: timezone,
				w: $author$project$Components$Supports$ImportSupports$List$Model$initImportFile,
				aG: true,
				a8: {lm: _List_Nil, mP: $elm$core$Maybe$Nothing, mW: _List_Nil, n8: _List_Nil}
			});
	});
var $author$project$Components$Supports$ImportSupports$List$Update$ImportsPassesFetched = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$API$ListImportsPassesResult$AllImportsPassesResult = F4(
	function (importsPasses, produitsDisponibles, cabinetsDisponibles, links) {
		return {lm: cabinetsDisponibles, mW: importsPasses, nf: links, n8: produitsDisponibles};
	});
var $author$project$Components$Supports$API$ListImportsPassesResult$CabinetDisponibleAPIView = F2(
	function (uid, label) {
		return {m9: label, kx: uid};
	});
var $author$project$Components$Supports$API$ListImportsPassesResult$cabinetDisponibleAPIViewJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'label',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'uid',
		A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string),
		$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$ListImportsPassesResult$CabinetDisponibleAPIView)));
var $author$project$Components$Supports$API$ListImportsPassesResult$ImportPasseAPIView = F5(
	function (uid, date, importStatus, importType, links) {
		return {c$: date, mU: importStatus, mV: importType, nf: links, kx: uid};
	});
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $rtfeldman$elm_iso8601_date_strings$DeadEnds$problemToString = function (p) {
	switch (p.$) {
		case 0:
			var s = p.a;
			return 'expecting \'' + (s + '\'');
		case 1:
			return 'expecting int';
		case 2:
			return 'expecting hex';
		case 3:
			return 'expecting octal';
		case 4:
			return 'expecting binary';
		case 5:
			return 'expecting float';
		case 6:
			return 'expecting number';
		case 7:
			return 'expecting variable';
		case 8:
			var s = p.a;
			return 'expecting symbol \'' + (s + '\'');
		case 9:
			var s = p.a;
			return 'expecting keyword \'' + (s + '\'');
		case 10:
			return 'expecting end';
		case 11:
			return 'unexpected char';
		case 12:
			var s = p.a;
			return 'problem ' + s;
		default:
			return 'bad repeat';
	}
};
var $rtfeldman$elm_iso8601_date_strings$DeadEnds$deadEndToString = function (deadend) {
	return $rtfeldman$elm_iso8601_date_strings$DeadEnds$problemToString(deadend.i4) + (' at row ' + ($elm$core$String$fromInt(deadend.jA) + (', col ' + $elm$core$String$fromInt(deadend.gd))));
};
var $elm$core$List$intersperse = F2(
	function (sep, xs) {
		if (!xs.b) {
			return _List_Nil;
		} else {
			var hd = xs.a;
			var tl = xs.b;
			var step = F2(
				function (x, rest) {
					return A2(
						$elm$core$List$cons,
						sep,
						A2($elm$core$List$cons, x, rest));
				});
			var spersed = A3($elm$core$List$foldr, step, _List_Nil, tl);
			return A2($elm$core$List$cons, hd, spersed);
		}
	});
var $rtfeldman$elm_iso8601_date_strings$DeadEnds$deadEndsToString = function (deadEnds) {
	return $elm$core$String$concat(
		A2(
			$elm$core$List$intersperse,
			'; ',
			A2($elm$core$List$map, $rtfeldman$elm_iso8601_date_strings$DeadEnds$deadEndToString, deadEnds)));
};
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {gd: col, lz: contextStack, i4: problem, jA: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.jA, s.gd, x, s.i));
	});
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.b),
			s.d) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.b);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.d, offset) < 0,
					0,
					{gd: col, i: s0.i, j: s0.j, d: offset, jA: row, b: s0.b});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.d, s.jA, s.gd, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.d, s1.d, s0.b),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $elm$core$Basics$round = _Basics_round;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.d, s.b);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{gd: 1, i: s.i, j: s.j, d: s.d + 1, jA: s.jA + 1, b: s.b}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{gd: s.gd + 1, i: s.i, j: s.j, d: newOffset, jA: s.jA, b: s.b}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.d, s.jA, s.gd, s.b);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{gd: newCol, i: s.i, j: s.j, d: newOffset, jA: newRow, b: s.b});
	};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
										$elm$parser$Parser$succeed(0)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {gd: col, i4: problem, jA: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.jA, p.gd, p.i4);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{gd: 1, i: _List_Nil, j: 1, d: 0, jA: 1, b: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (str) {
		var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(str);
		if (_v0.$ === 1) {
			var deadEnds = _v0.a;
			return $elm$json$Json$Decode$fail(
				$rtfeldman$elm_iso8601_date_strings$DeadEnds$deadEndsToString(deadEnds));
		} else {
			var time = _v0.a;
			return $elm$json$Json$Decode$succeed(time);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Components$Supports$API$ListImportsPassesResult$importStatusJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Pending',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'Warning',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'Successful',
			$elm$json$Json$Decode$succeed(2))
		]));
var $author$project$Components$Supports$API$ListImportsPassesResult$ImportSupportDansReferentiel = {$: 4};
var $author$project$Components$Supports$API$ListImportsPassesResult$ImportCompletSupportPourCabinet = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Components$Supports$API$ListImportsPassesResult$importCompletSupportPourCabinetDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'cabinetUid',
	A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'produitUid',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$ListImportsPassesResult$ImportCompletSupportPourCabinet)));
var $author$project$Components$Supports$API$ListImportsPassesResult$ImportCompletSupportPourProduit = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$API$ListImportsPassesResult$importCompletSupportPourProduitDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'produitUid',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$ListImportsPassesResult$ImportCompletSupportPourProduit));
var $author$project$Components$Supports$API$ListImportsPassesResult$ImportPartielSupportPourCabinet = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Components$Supports$API$ListImportsPassesResult$importPartielSupportPourCabinetDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'cabinetUid',
	A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $elm$json$Json$Decode$string),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'produitUid',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$ListImportsPassesResult$ImportPartielSupportPourCabinet)));
var $author$project$Components$Supports$API$ListImportsPassesResult$ImportPartielSupportPourProduit = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Supports$API$ListImportsPassesResult$importPartielSupportPourProduitDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'produitUid',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$ListImportsPassesResult$ImportPartielSupportPourProduit));
var $author$project$Components$Supports$API$ListImportsPassesResult$importTypeJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$field, 'ImportCompletSupportPourProduit', $author$project$Components$Supports$API$ListImportsPassesResult$importCompletSupportPourProduitDecoder),
			A2($elm$json$Json$Decode$field, 'ImportPartielSupportPourProduit', $author$project$Components$Supports$API$ListImportsPassesResult$importPartielSupportPourProduitDecoder),
			A2($elm$json$Json$Decode$field, 'ImportCompletSupportPourCabinet', $author$project$Components$Supports$API$ListImportsPassesResult$importCompletSupportPourCabinetDecoder),
			A2($elm$json$Json$Decode$field, 'ImportPartielSupportPourCabinet', $author$project$Components$Supports$API$ListImportsPassesResult$importPartielSupportPourCabinetDecoder),
			A2(
			$elm$json$Json$Decode$field,
			'ImportSupportDansReferentiel',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$ListImportsPassesResult$ImportSupportDansReferentiel))
		]));
var $author$project$Components$Supports$API$ListImportsPassesResult$importPasseAPIViewJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'importType',
		$author$project$Components$Supports$API$ListImportsPassesResult$importTypeJsonDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'importStatus',
			$author$project$Components$Supports$API$ListImportsPassesResult$importStatusJsonDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'date',
				$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'uid',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$ListImportsPassesResult$ImportPasseAPIView))))));
var $author$project$Components$Supports$API$ListImportsPassesResult$ProduitDisponibleAPIView = F3(
	function (uid, label, links) {
		return {m9: label, nf: links, kx: uid};
	});
var $author$project$Components$Supports$API$ListImportsPassesResult$produitDisponibleAPIViewJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'label',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'uid',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$ListImportsPassesResult$ProduitDisponibleAPIView))));
var $author$project$Components$Supports$API$ListImportsPassesResult$allImportsPassesResultJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'cabinetsDisponibles',
		$elm$json$Json$Decode$list($author$project$Components$Supports$API$ListImportsPassesResult$cabinetDisponibleAPIViewJsonDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'produitsDisponibles',
			$elm$json$Json$Decode$list($author$project$Components$Supports$API$ListImportsPassesResult$produitDisponibleAPIViewJsonDecoder),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'importsPasses',
				$elm$json$Json$Decode$list($author$project$Components$Supports$API$ListImportsPassesResult$importPasseAPIViewJsonDecoder),
				$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$ListImportsPassesResult$AllImportsPassesResult)))));
var $author$project$Components$Supports$API$Requests$listImportsPasses = function (link) {
	return A3(
		$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
		link,
		$elm$http$Http$emptyBody,
		A2($elm$http$Http$expectJson, $elm$core$Basics$identity, $author$project$Components$Supports$API$ListImportsPassesResult$allImportsPassesResultJsonDecoder));
};
var $author$project$Components$Supports$ImportSupports$List$Update$init = function (model) {
	var effect = A2(
		$elm$core$Platform$Cmd$map,
		$author$project$Components$Supports$ImportSupports$List$Update$ImportsPassesFetched,
		$author$project$Components$Supports$API$Requests$listImportsPasses(model.hq));
	return _Utils_Tuple2(model, effect);
};
var $author$project$Routes$ComponentsBuilders$SupportsComponentBuilder$importSupports = F3(
	function (step, timezone, rootApiLinks) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(
				$author$project$Routes$Pages$Error(0),
				$elm$core$Platform$Cmd$none),
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Tuple$mapBoth,
					$author$project$Routes$Pages$ImportSupports(step),
					$elm$core$Platform$Cmd$map($author$project$Main$Msg$ImportSupportsAction)),
				A2(
					$elm$core$Maybe$map,
					$author$project$Components$Supports$ImportSupports$List$Update$init,
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$Supports$ImportSupports$List$Model$init(timezone),
						A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'supports_investissement/imports', rootApiLinks.kW)))));
	});
var $author$project$Components$Supports$ImportSupports$Detail$Model$Loading = {$: 0};
var $author$project$Components$Supports$ImportSupports$Detail$Model$init = F2(
	function (timezone, getImportLink) {
		return {c9: false, hp: getImportLink, hx: timezone, ow: $author$project$Components$Supports$ImportSupports$Detail$Model$Loading};
	});
var $author$project$Components$Supports$ImportSupports$Detail$Update$ImportPasseDetailFetched = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$API$GetImportPasseDetailResult$ImportPasseDetailAPIView = F6(
	function (uid, date, importStatus, importType, supportsImportes, links) {
		return {c$: date, mU: importStatus, mV: importType, nf: links, oG: supportsImportes, kx: uid};
	});
var $author$project$Components$Supports$API$GetImportPasseDetailResult$ImportSupportDansReferentiel = {$: 4};
var $author$project$Components$Supports$API$GetImportPasseDetailResult$ImportCompletSupportPourCabinet = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Components$Supports$API$GetImportPasseDetailResult$UidAndLabel = F2(
	function (uid, label) {
		return {m9: label, kx: uid};
	});
var $author$project$Components$Supports$API$GetImportPasseDetailResult$uidAndLabelDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'label',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'uid',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$GetImportPasseDetailResult$UidAndLabel)));
var $author$project$Components$Supports$API$GetImportPasseDetailResult$importCompletSupportPourCabinetDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'cabinet',
	$author$project$Components$Supports$API$GetImportPasseDetailResult$uidAndLabelDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'produit',
		$author$project$Components$Supports$API$GetImportPasseDetailResult$uidAndLabelDecoder,
		$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$GetImportPasseDetailResult$ImportCompletSupportPourCabinet)));
var $author$project$Components$Supports$API$GetImportPasseDetailResult$ImportCompletSupportPourProduit = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$API$GetImportPasseDetailResult$importCompletSupportPourProduitDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'produit',
	$author$project$Components$Supports$API$GetImportPasseDetailResult$uidAndLabelDecoder,
	$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$GetImportPasseDetailResult$ImportCompletSupportPourProduit));
var $author$project$Components$Supports$API$GetImportPasseDetailResult$ImportPartielSupportPourCabinet = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Components$Supports$API$GetImportPasseDetailResult$importPartielSupportPourCabinetDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'cabinet',
	$author$project$Components$Supports$API$GetImportPasseDetailResult$uidAndLabelDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'produit',
		$author$project$Components$Supports$API$GetImportPasseDetailResult$uidAndLabelDecoder,
		$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$GetImportPasseDetailResult$ImportPartielSupportPourCabinet)));
var $author$project$Components$Supports$API$GetImportPasseDetailResult$ImportPartielSupportPourProduit = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Supports$API$GetImportPasseDetailResult$importPartielSupportPourProduitDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'produit',
	$author$project$Components$Supports$API$GetImportPasseDetailResult$uidAndLabelDecoder,
	$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$GetImportPasseDetailResult$ImportPartielSupportPourProduit));
var $author$project$Components$Supports$API$GetImportPasseDetailResult$importTypeDetailAPIViewJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2($elm$json$Json$Decode$field, 'ImportCompletSupportPourProduit', $author$project$Components$Supports$API$GetImportPasseDetailResult$importCompletSupportPourProduitDecoder),
			A2($elm$json$Json$Decode$field, 'ImportPartielSupportPourProduit', $author$project$Components$Supports$API$GetImportPasseDetailResult$importPartielSupportPourProduitDecoder),
			A2($elm$json$Json$Decode$field, 'ImportCompletSupportPourCabinet', $author$project$Components$Supports$API$GetImportPasseDetailResult$importCompletSupportPourCabinetDecoder),
			A2($elm$json$Json$Decode$field, 'ImportPartielSupportPourCabinet', $author$project$Components$Supports$API$GetImportPasseDetailResult$importPartielSupportPourCabinetDecoder),
			A2(
			$elm$json$Json$Decode$field,
			'ImportSupportDansReferentiel',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$GetImportPasseDetailResult$ImportSupportDansReferentiel))
		]));
var $author$project$Components$Supports$API$GetImportPasseDetailResult$SupportImporte = F2(
	function (supportId, status) {
		return {ox: status, oE: supportId};
	});
var $author$project$Components$Supports$API$GetImportPasseDetailResult$SupportImporteAvecSucces = {$: 0};
var $author$project$Components$Supports$API$GetImportPasseDetailResult$SupportNonImporte = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Supports$API$GetImportPasseDetailResult$EnAttente = {$: 0};
var $author$project$Components$Supports$API$GetImportPasseDetailResult$SupportEnAttenteAjoutDansCGPConseil = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Supports$API$GetImportPasseDetailResult$SupportNonTrouveDansCGPConseil = {$: 1};
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Components$Supports$API$GetImportPasseDetailResult$supportNonImporteCauseJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'EnAttente',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$GetImportPasseDetailResult$EnAttente)),
			A2(
			$elm$json$Json$Decode$field,
			'SupportNonTrouveDansCGPConseil',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$GetImportPasseDetailResult$SupportNonTrouveDansCGPConseil)),
			A2(
			$elm$json$Json$Decode$map,
			$author$project$Components$Supports$API$GetImportPasseDetailResult$SupportEnAttenteAjoutDansCGPConseil,
			A2(
				$elm$json$Json$Decode$field,
				'SupportEnAttenteAjoutDansCGPConseil',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'demandeEffectueeAvecSucces',
					$elm$json$Json$Decode$bool,
					$elm$json$Json$Decode$succeed(
						function (succes) {
							return {lS: succes};
						}))))
		]));
var $author$project$Components$Supports$API$GetImportPasseDetailResult$supportImporteStatusJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'SupportImporteAvecSucces',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$GetImportPasseDetailResult$SupportImporteAvecSucces)),
			A2(
			$elm$json$Json$Decode$map,
			$author$project$Components$Supports$API$GetImportPasseDetailResult$SupportNonImporte,
			A2(
				$elm$json$Json$Decode$field,
				'SupportNonImporte',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'cause',
					$author$project$Components$Supports$API$GetImportPasseDetailResult$supportNonImporteCauseJsonDecoder,
					$elm$json$Json$Decode$succeed(
						function (cause) {
							return {lr: cause};
						}))))
		]));
var $author$project$Components$Supports$API$GetImportPasseDetailResult$supportsImportesJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'status',
	$author$project$Components$Supports$API$GetImportPasseDetailResult$supportImporteStatusJsonDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'supportId',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$GetImportPasseDetailResult$SupportImporte)));
var $author$project$Components$Supports$API$GetImportPasseDetailResult$importPasseDetailJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'supportsImportes',
		$elm$json$Json$Decode$list($author$project$Components$Supports$API$GetImportPasseDetailResult$supportsImportesJsonDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'importType',
			$author$project$Components$Supports$API$GetImportPasseDetailResult$importTypeDetailAPIViewJsonDecoder,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'importStatus',
				$author$project$Components$Supports$API$ListImportsPassesResult$importStatusJsonDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'date',
					$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'uid',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$GetImportPasseDetailResult$ImportPasseDetailAPIView)))))));
var $author$project$Components$Supports$API$Requests$getImportPasseDetail = function (link) {
	return A3(
		$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
		link,
		$elm$http$Http$emptyBody,
		A2($elm$http$Http$expectJson, $elm$core$Basics$identity, $author$project$Components$Supports$API$GetImportPasseDetailResult$importPasseDetailJsonDecoder));
};
var $author$project$Components$Supports$ImportSupports$Detail$Update$init = function (model) {
	var effect = A2(
		$elm$core$Platform$Cmd$map,
		$author$project$Components$Supports$ImportSupports$Detail$Update$ImportPasseDetailFetched,
		$author$project$Components$Supports$API$Requests$getImportPasseDetail(model.hp));
	return _Utils_Tuple2(model, effect);
};
var $author$project$Routes$ComponentsBuilders$SupportsComponentBuilder$importSupportDetail = F4(
	function (importId, timezone, rootApiLinks, previousPage) {
		var goToDetail = function (imports) {
			return A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2(
					$author$project$Routes$Pages$Error(0),
					$elm$core$Platform$Cmd$none),
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Tuple$mapBoth,
						$author$project$Routes$Pages$ImportSupportDetail,
						$elm$core$Platform$Cmd$map($author$project$Main$Msg$ImportSupportDetailAction)),
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$Supports$ImportSupports$Detail$Update$init,
						A2(
							$elm$core$Maybe$map,
							function (getLink) {
								return A2($author$project$Components$Supports$ImportSupports$Detail$Model$init, timezone, getLink);
							},
							A2(
								$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
								function ($) {
									return $.mI;
								},
								A2(
									$elm_community$list_extra$List$Extra$find,
									A2(
										$elm$core$Basics$composeR,
										function ($) {
											return $.kx;
										},
										$elm$core$Basics$eq(importId)),
									imports))))));
		};
		if (previousPage.$ === 12) {
			var subModel = previousPage.b;
			return goToDetail(subModel.a8.mW);
		} else {
			var step = function (page) {
				if (page.$ === 12) {
					var subModel = page.b;
					return $elm$core$Maybe$Just(
						$elm$core$Result$Ok(
							goToDetail(subModel.a8.mW)));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			};
			return A3(
				$author$project$Routes$ComponentsBuilders$SupportsComponentBuilder$importSupports,
				$elm$core$Maybe$Just(step),
				timezone,
				rootApiLinks);
		}
	});
var $author$project$Components$Landing$Update$FetchCa = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Landing$Update$FetchClients = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Landing$Update$FetchPreferredDocs = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Landing$Update$FetchProduction = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Landing$Update$init = function (model) {
	return $elm$core$Platform$Cmd$batch(
		_List_fromArray(
			[
				A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$Maybe$map,
					A2($elm$core$Basics$composeR, $author$project$Components$Landing$Update$FetchClients, $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd),
					A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'activites/clients', model.nf.d0))),
				A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$Maybe$map,
					A2($elm$core$Basics$composeR, $author$project$Components$Landing$Update$FetchCa, $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd),
					A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'activites/chiffre_affaire', model.nf.d0))),
				A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$Maybe$map,
					A2($elm$core$Basics$composeR, $author$project$Components$Landing$Update$FetchProduction, $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd),
					A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'activites/production', model.nf.d0))),
				A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$Maybe$map,
					A2($elm$core$Basics$composeR, $author$project$Components$Landing$Update$FetchPreferredDocs, $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd),
					A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'preferred_docs', model.nf.kW)))
			]));
};
var $author$project$Routes$ComponentBuilder$landing = function (rootApiLinks) {
	var model = $author$project$Components$Landing$Model$init(rootApiLinks);
	var effect = $author$project$Components$Landing$Update$init(model);
	return _Utils_Tuple2(
		$author$project$Routes$Pages$Landing(model),
		A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg$LandingComponentAction, effect));
};
var $author$project$Components$Supports$List$Model$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$List$Update$SupportsFetched = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Supports$List$Model$getListLink = function (model) {
	if (!model.$) {
		var listLinks = model.a;
		return listLinks;
	} else {
		var listLinks = model.b;
		return listLinks;
	}
};
var $author$project$Components$Supports$API$ListResult$AllSupportsResult = F2(
	function (result, links) {
		return {nf: links, ok: result};
	});
var $author$project$Components$Supports$API$ListResult$SupportAbstract = F5(
	function (supportId, name, societeDeGestion, importType, links) {
		return {mV: importType, nf: links, nz: name, ov: societeDeGestion, oE: supportId};
	});
var $author$project$Components$Supports$API$ListResult$CgpConseil = 0;
var $author$project$Components$Supports$API$ListResult$MustCompliance = 1;
var $author$project$Components$Supports$API$ListResult$importTypeDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'CgpConseil',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'MustCompliance',
			$elm$json$Json$Decode$succeed(1))
		]));
var $author$project$Components$Supports$API$ListResult$supportDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'importType',
		$author$project$Components$Supports$API$ListResult$importTypeDecoder,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'societeDeGestion',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'name',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'supportId',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$ListResult$SupportAbstract))))));
var $author$project$Components$Supports$API$ListResult$supportsDecoder = $elm$json$Json$Decode$list($author$project$Components$Supports$API$ListResult$supportDecoder);
var $author$project$Components$Supports$API$ListResult$allSupportsResultDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'result',
		$author$project$Components$Supports$API$ListResult$supportsDecoder,
		$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$ListResult$AllSupportsResult)));
var $author$project$Components$Supports$API$Requests$list = function (link) {
	return A3(
		$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
		link,
		$elm$http$Http$emptyBody,
		A2($elm$http$Http$expectJson, $elm$core$Basics$identity, $author$project$Components$Supports$API$ListResult$allSupportsResultDecoder));
};
var $author$project$Components$Supports$List$Update$init = function (model) {
	return _Utils_Tuple2(
		model,
		A2(
			$elm$core$Platform$Cmd$map,
			$author$project$Components$Supports$List$Update$SupportsFetched,
			$author$project$Components$Supports$API$Requests$list(
				$author$project$Components$Supports$List$Model$getListLink(model))));
};
var $author$project$Routes$ComponentsBuilders$SupportsComponentBuilder$listSupports = F3(
	function (step, _v0, rootApiLinks) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(
				$author$project$Routes$Pages$Error(0),
				$elm$core$Platform$Cmd$none),
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Tuple$mapBoth,
					$author$project$Routes$Pages$ListSupports(step),
					$elm$core$Platform$Cmd$map($author$project$Main$Msg$ListSupportsAction)),
				A2(
					$elm$core$Maybe$map,
					$author$project$Components$Supports$List$Update$init,
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$Supports$List$Model$Loading,
						A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'supports_investissement', rootApiLinks.kW)))));
	});
var $author$project$Components$Clients$Detail$Model$CreateClient = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routes$ClientsComponentBuilder$newClient = F2(
	function (rootApiLinks, previousPage) {
		var detailModel = function (model) {
			return A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Components$Clients$Detail$Model$CreateClient,
					$author$project$Routes$ClientsComponentBuilder$initDetailModel(model)),
				model.k_.gm);
		};
		var goToNextDetails = function (model) {
			return A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2(
					$author$project$Routes$Pages$Error(0),
					$elm$core$Platform$Cmd$none),
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Tuple$mapBoth,
						$author$project$Routes$Pages$ClientDetail,
						$elm$core$Platform$Cmd$map($author$project$Main$Msg$ClientsDetailComponentAction)),
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$Clients$Detail$Update$init,
						detailModel(model))));
		};
		if (previousPage.$ === 8) {
			var model = previousPage.b;
			return goToNextDetails(model);
		} else {
			var goToNextPage = function (page) {
				if (page.$ === 8) {
					var model = page.b;
					return A2(
						$elm$core$Maybe$map,
						function (_v2) {
							return $elm$core$Result$Ok(
								goToNextDetails(model));
						},
						detailModel(model));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			};
			return A2(
				$author$project$Routes$ClientsComponentBuilder$clients,
				$elm$core$Maybe$Just(goToNextPage),
				rootApiLinks);
		}
	});
var $author$project$Components$Produits$Details$Model$Create = {$: 0};
var $author$project$Components$Produits$Details$Model$Update = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$Model$Informations = 0;
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$addFormFieldInfo = F2(
	function (existing, infoToAdd) {
		return A2(
			$elm_community$list_extra$List$Extra$uniqueBy,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$formFieldComparable,
			_Utils_ap(
				existing,
				_List_fromArray(
					[infoToAdd])));
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$addInfo = F2(
	function (infoToAdd, _v0) {
		var val = _v0.a;
		var infos = _v0.b;
		return A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$FormField,
			val,
			A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$addFormFieldInfo, infos, infoToAdd));
	});
var $author$project$Components$Produits$Common$MarcheCibleModel$AuDelaMontants = 4;
var $author$project$Components$Produits$Common$MarcheCibleModel$JusquaMontants = 3;
var $author$project$Components$Produits$Common$MarcheCibleModel$Limitees = 1;
var $author$project$Components$Produits$Common$MarcheCibleModel$Nulle = 0;
var $author$project$Components$Produits$Common$MarcheCibleModel$Significatives = 2;
var $author$project$Components$Produits$Common$MarcheCibleModel$allCapaciteSubirPerte = function () {
	var next = function (list) {
		var _v0 = $elm$core$List$head(list);
		if (_v0.$ === 1) {
			return next(
				A2($elm$core$List$cons, 0, list));
		} else {
			switch (_v0.a) {
				case 0:
					var _v1 = _v0.a;
					return next(
						A2($elm$core$List$cons, 1, list));
				case 1:
					var _v2 = _v0.a;
					return next(
						A2($elm$core$List$cons, 2, list));
				case 2:
					var _v3 = _v0.a;
					return next(
						A2($elm$core$List$cons, 3, list));
				case 3:
					var _v4 = _v0.a;
					return next(
						A2($elm$core$List$cons, 4, list));
				default:
					var _v5 = _v0.a;
					return list;
			}
		}
	};
	return $elm$core$List$reverse(
		next(_List_Nil));
}();
var $author$project$Components$Produits$Common$Classification$CIF = 1;
var $author$project$Components$Produits$Common$Classification$IAS = 0;
var $author$project$Components$Produits$Common$Classification$IOBSP = 2;
var $author$project$Components$Produits$Common$Classification$ITI = 3;
var $author$project$Components$Produits$Common$Classification$allClassifications = function () {
	var next = function (list) {
		var _v0 = $elm$core$List$head(list);
		if (_v0.$ === 1) {
			return next(
				A2($elm$core$List$cons, 3, list));
		} else {
			switch (_v0.a) {
				case 3:
					var _v1 = _v0.a;
					return next(
						A2($elm$core$List$cons, 2, list));
				case 2:
					var _v2 = _v0.a;
					return next(
						A2($elm$core$List$cons, 1, list));
				case 1:
					var _v3 = _v0.a;
					return next(
						A2($elm$core$List$cons, 0, list));
				default:
					var _v4 = _v0.a;
					return list;
			}
		}
	};
	return $elm$core$List$reverse(
		next(_List_Nil));
}();
var $author$project$Components$Produits$Common$Famille$AssuranceCapi = 2;
var $author$project$Components$Produits$Common$Famille$AssuranceRisquesDivers = 0;
var $author$project$Components$Produits$Common$Famille$AssuranceVie = 1;
var $author$project$Components$Produits$Common$Famille$CompteTitres = 3;
var $author$project$Components$Produits$Common$Famille$CompteTitresPea = 4;
var $author$project$Components$Produits$Common$Famille$DefiscalisationIsf = 5;
var $author$project$Components$Produits$Common$Famille$EpargneSalariale = 6;
var $author$project$Components$Produits$Common$Famille$FipFcpiFcpr = 7;
var $author$project$Components$Produits$Common$Famille$GestionSousMandat = 8;
var $author$project$Components$Produits$Common$Famille$Gff = 9;
var $author$project$Components$Produits$Common$Famille$GirardinIs = 10;
var $author$project$Components$Produits$Common$Famille$Honoraires = 11;
var $author$project$Components$Produits$Common$Famille$Lmnp = 12;
var $author$project$Components$Produits$Common$Famille$MandatDeGestion = 13;
var $author$project$Components$Produits$Common$Famille$Opci = 14;
var $author$project$Components$Produits$Common$Famille$Oppci = 15;
var $author$project$Components$Produits$Common$Famille$Pinel = 16;
var $author$project$Components$Produits$Common$Famille$PinelOutreMer = 17;
var $author$project$Components$Produits$Common$Famille$Prevoyance = 18;
var $author$project$Components$Produits$Common$Famille$Retraite = 19;
var $author$project$Components$Produits$Common$Famille$Scpi = 20;
var $author$project$Components$Produits$Common$Famille$allFamilles = function () {
	var next = function (list) {
		var _v0 = $elm$core$List$head(list);
		if (!_v0.$) {
			switch (_v0.a) {
				case 0:
					var _v1 = _v0.a;
					return list;
				case 1:
					var _v2 = _v0.a;
					return next(
						A2($elm$core$List$cons, 0, list));
				case 2:
					var _v3 = _v0.a;
					return next(
						A2($elm$core$List$cons, 1, list));
				case 3:
					var _v4 = _v0.a;
					return next(
						A2($elm$core$List$cons, 2, list));
				case 4:
					var _v5 = _v0.a;
					return next(
						A2($elm$core$List$cons, 3, list));
				case 5:
					var _v6 = _v0.a;
					return next(
						A2($elm$core$List$cons, 4, list));
				case 6:
					var _v7 = _v0.a;
					return next(
						A2($elm$core$List$cons, 5, list));
				case 7:
					var _v8 = _v0.a;
					return next(
						A2($elm$core$List$cons, 6, list));
				case 8:
					var _v9 = _v0.a;
					return next(
						A2($elm$core$List$cons, 7, list));
				case 9:
					var _v10 = _v0.a;
					return next(
						A2($elm$core$List$cons, 8, list));
				case 10:
					var _v11 = _v0.a;
					return next(
						A2($elm$core$List$cons, 9, list));
				case 11:
					var _v12 = _v0.a;
					return next(
						A2($elm$core$List$cons, 10, list));
				case 12:
					var _v13 = _v0.a;
					return next(
						A2($elm$core$List$cons, 11, list));
				case 13:
					var _v14 = _v0.a;
					return next(
						A2($elm$core$List$cons, 12, list));
				case 14:
					var _v15 = _v0.a;
					return next(
						A2($elm$core$List$cons, 13, list));
				case 15:
					var _v16 = _v0.a;
					return next(
						A2($elm$core$List$cons, 14, list));
				case 16:
					var _v17 = _v0.a;
					return next(
						A2($elm$core$List$cons, 15, list));
				case 17:
					var _v18 = _v0.a;
					return next(
						A2($elm$core$List$cons, 16, list));
				case 18:
					var _v19 = _v0.a;
					return next(
						A2($elm$core$List$cons, 17, list));
				case 19:
					var _v20 = _v0.a;
					return next(
						A2($elm$core$List$cons, 18, list));
				default:
					var _v21 = _v0.a;
					return next(
						A2($elm$core$List$cons, 19, list));
			}
		} else {
			return next(
				A2($elm$core$List$cons, 20, list));
		}
	};
	return $elm$core$List$reverse(
		next(_List_Nil));
}();
var $author$project$Components$Produits$Common$MarcheCibleModel$CourtTerme = 1;
var $author$project$Components$Produits$Common$MarcheCibleModel$LongTerme = 3;
var $author$project$Components$Produits$Common$MarcheCibleModel$MoyenTerme = 2;
var $author$project$Components$Produits$Common$MarcheCibleModel$TresCourtTerme = 0;
var $author$project$Components$Produits$Common$MarcheCibleModel$allHorizon = function () {
	var next = function (list) {
		var _v0 = $elm$core$List$head(list);
		if (_v0.$ === 1) {
			return next(
				A2($elm$core$List$cons, 0, list));
		} else {
			switch (_v0.a) {
				case 0:
					var _v1 = _v0.a;
					return next(
						A2($elm$core$List$cons, 1, list));
				case 1:
					var _v2 = _v0.a;
					return next(
						A2($elm$core$List$cons, 2, list));
				case 2:
					var _v3 = _v0.a;
					return next(
						A2($elm$core$List$cons, 3, list));
				default:
					var _v4 = _v0.a;
					return list;
			}
		}
	};
	return $elm$core$List$reverse(
		next(_List_Nil));
}();
var $author$project$Components$Produits$Common$MarcheCibleModel$Defensif = 1;
var $author$project$Components$Produits$Common$MarcheCibleModel$Dynamique = 3;
var $author$project$Components$Produits$Common$MarcheCibleModel$Equilibre = 2;
var $author$project$Components$Produits$Common$MarcheCibleModel$Offensif = 4;
var $author$project$Components$Produits$Common$MarcheCibleModel$Securitaire = 0;
var $author$project$Components$Produits$Common$MarcheCibleModel$allProfilMarcheCible = function () {
	var next = function (list) {
		var _v0 = $elm$core$List$head(list);
		if (_v0.$ === 1) {
			return next(
				A2($elm$core$List$cons, 0, list));
		} else {
			switch (_v0.a) {
				case 0:
					var _v1 = _v0.a;
					return next(
						A2($elm$core$List$cons, 1, list));
				case 1:
					var _v2 = _v0.a;
					return next(
						A2($elm$core$List$cons, 2, list));
				case 2:
					var _v3 = _v0.a;
					return next(
						A2($elm$core$List$cons, 3, list));
				case 3:
					var _v4 = _v0.a;
					return next(
						A2($elm$core$List$cons, 4, list));
				default:
					var _v5 = _v0.a;
					return list;
			}
		}
	};
	return $elm$core$List$reverse(
		next(_List_Nil));
}();
var $author$project$Components$Produits$Common$SousClassification$ConseilSurBiensDiversEtAutresProduitsDePlacement = 0;
var $author$project$Components$Produits$Common$SousClassification$ConseilSurInstrumentsFinanciers = 1;
var $author$project$Components$Produits$Common$SousClassification$ConseilSurLesServicesDInvestissement = 2;
var $author$project$Components$Produits$Common$SousClassification$HonorairesCIF = 3;
var $author$project$Components$Produits$Common$SousClassification$HonorairesIAS = 4;
var $author$project$Components$Produits$Common$SousClassification$allSousClassifications = function () {
	var next = function (list) {
		var _v0 = $elm$core$List$head(list);
		if (_v0.$ === 1) {
			return next(
				A2($elm$core$List$cons, 4, list));
		} else {
			switch (_v0.a) {
				case 0:
					var _v1 = _v0.a;
					return list;
				case 1:
					var _v2 = _v0.a;
					return next(
						A2($elm$core$List$cons, 0, list));
				case 2:
					var _v3 = _v0.a;
					return next(
						A2($elm$core$List$cons, 1, list));
				case 3:
					var _v4 = _v0.a;
					return next(
						A2($elm$core$List$cons, 2, list));
				default:
					var _v5 = _v0.a;
					return next(
						A2($elm$core$List$cons, 3, list));
			}
		}
	};
	return $elm$core$List$reverse(
		next(_List_Nil));
}();
var $author$project$Components$Produits$Common$MarcheCibleModel$Distributeur = 1;
var $author$project$Components$Produits$Common$MarcheCibleModel$Producteur = 0;
var $author$project$Components$Produits$Common$MarcheCibleModel$allTypeMarcheCible = function () {
	var next = function (list) {
		var _v0 = $elm$core$List$head(list);
		if (_v0.$ === 1) {
			return next(
				A2($elm$core$List$cons, 0, list));
		} else {
			if (!_v0.a) {
				var _v1 = _v0.a;
				return next(
					A2($elm$core$List$cons, 1, list));
			} else {
				var _v2 = _v0.a;
				return list;
			}
		}
	};
	return next(_List_Nil);
}();
var $author$project$Components$Produits$Common$CommercialisationModel$Article83 = 3;
var $author$project$Components$Produits$Common$CommercialisationModel$COREMC = 7;
var $author$project$Components$Produits$Common$CommercialisationModel$CRH = 6;
var $author$project$Components$Produits$Common$CommercialisationModel$Madelin = 2;
var $author$project$Components$Produits$Common$CommercialisationModel$PER = 0;
var $author$project$Components$Produits$Common$CommercialisationModel$PERCO = 8;
var $author$project$Components$Produits$Common$CommercialisationModel$PERE = 4;
var $author$project$Components$Produits$Common$CommercialisationModel$PERP = 1;
var $author$project$Components$Produits$Common$CommercialisationModel$PREFON = 5;
var $author$project$Components$Produits$Common$CommercialisationModel$allTypes = function () {
	var next = function (types) {
		var _v0 = $elm$core$List$head(types);
		if (_v0.$ === 1) {
			return next(
				A2($elm$core$List$cons, 0, types));
		} else {
			switch (_v0.a) {
				case 0:
					var _v1 = _v0.a;
					return next(
						A2($elm$core$List$cons, 1, types));
				case 1:
					var _v2 = _v0.a;
					return next(
						A2($elm$core$List$cons, 2, types));
				case 2:
					var _v3 = _v0.a;
					return next(
						A2($elm$core$List$cons, 3, types));
				case 3:
					var _v4 = _v0.a;
					return next(
						A2($elm$core$List$cons, 4, types));
				case 4:
					var _v5 = _v0.a;
					return next(
						A2($elm$core$List$cons, 5, types));
				case 5:
					var _v6 = _v0.a;
					return next(
						A2($elm$core$List$cons, 6, types));
				case 6:
					var _v7 = _v0.a;
					return next(
						A2($elm$core$List$cons, 7, types));
				case 7:
					var _v8 = _v0.a;
					return next(
						A2($elm$core$List$cons, 8, types));
				default:
					var _v9 = _v0.a;
					return types;
			}
		}
	};
	return $elm$core$List$reverse(
		next(_List_Nil));
}();
var $author$project$Components$Produits$Common$ExperienceModel$Experimente = 2;
var $author$project$Components$Produits$Common$ExperienceModel$Informe = 1;
var $author$project$Components$Produits$Common$ExperienceModel$Novice = 0;
var $author$project$Components$Produits$Common$ExperienceModel$allTypes = function () {
	var next = function (types) {
		var _v0 = $elm$core$List$head(types);
		if (_v0.$ === 1) {
			return next(
				A2($elm$core$List$cons, 2, types));
		} else {
			switch (_v0.a) {
				case 1:
					var _v1 = _v0.a;
					return next(
						A2($elm$core$List$cons, 0, types));
				case 2:
					var _v2 = _v0.a;
					return next(
						A2($elm$core$List$cons, 1, types));
				default:
					var _v3 = _v0.a;
					return types;
			}
		}
	};
	return $elm$core$List$reverse(
		next(_List_Nil));
}();
var $author$project$Components$Produits$Common$MarcheCibleModel$capaciteSubirPerteToString = function (_enum) {
	switch (_enum) {
		case 0:
			return 'Nulle (aucune perte tolérée)';
		case 1:
			return 'Limitées (moins de 10 %)';
		case 2:
			return 'Significatives (entre 10% et 50%)';
		case 3:
			return 'Jusqu\'à concurrence des montants investis';
		default:
			return 'Au delà des montants investis';
	}
};
var $author$project$Components$Produits$Common$Classification$classificationToString = function (classification) {
	switch (classification) {
		case 0:
			return 'IAS';
		case 1:
			return 'CIF';
		case 2:
			return 'IOBSP';
		default:
			return 'ITI';
	}
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty = A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$FormField, $elm$core$Maybe$Nothing, _List_Nil);
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$empty = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty;
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$empty = A3($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$FormInt, $elm$core$Maybe$Nothing, '', _List_Nil);
var $author$project$Components$Produits$Details$Model$enumSelect = function (args) {
	return A4(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$init,
		A2(
			$elm$core$List$map,
			function (x) {
				return _Utils_Tuple2(
					args.dX(x),
					args.dX(x));
			},
			args.fP),
		_List_Nil,
		args.af,
		args.dX);
};
var $author$project$Components$Produits$Common$Famille$familleToString = function (famille) {
	switch (famille) {
		case 0:
			return 'ASSURANCE RISQUES DIVERS';
		case 1:
			return 'ASSURANCE VIE';
		case 2:
			return 'ASSURANCE CAPI';
		case 3:
			return 'COMPTE TITRES';
		case 4:
			return 'COMPTE TITRES PEA';
		case 5:
			return 'DEFISCALISATION  ISF';
		case 6:
			return 'EPARGNE SALARIALE';
		case 7:
			return 'FIP - FCPI - FCPR';
		case 8:
			return 'GESTION SOUS MANDAT';
		case 9:
			return 'GFF';
		case 10:
			return 'GIRARDIN IS';
		case 11:
			return 'HONORAIRES';
		case 12:
			return 'LMNP';
		case 13:
			return 'MANDAT DE GESTION';
		case 14:
			return 'OPCI';
		case 15:
			return 'OPPCI';
		case 16:
			return 'PINEL';
		case 17:
			return 'PINEL OUTRE-MER';
		case 18:
			return 'PREVOYANCE';
		case 19:
			return 'RETRAITE';
		default:
			return 'SCPI';
	}
};
var $author$project$Components$Produits$Common$MarcheCibleModel$horizonToString = function (_enum) {
	switch (_enum) {
		case 0:
			return 'Très court terme (< 1 an)';
		case 1:
			return 'court terme (< 3 ans)';
		case 2:
			return 'moyen terme (< 5 ans)';
		default:
			return 'long terme (> 5 ans)';
	}
};
var $author$project$Components$Produits$Details$Model$initFraisDuContrat = {hf: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, hh: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, hi: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, hj: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty};
var $author$project$Components$Produits$Common$MarcheCibleModel$Autre = 6;
var $author$project$Components$Produits$Common$MarcheCibleModel$Conservation = 0;
var $author$project$Components$Produits$Common$MarcheCibleModel$Couverture = 4;
var $author$project$Components$Produits$Common$MarcheCibleModel$Croissance = 1;
var $author$project$Components$Produits$Common$MarcheCibleModel$OptionnelOuEffetLevier = 5;
var $author$project$Components$Produits$Common$MarcheCibleModel$Rendement = 2;
var $author$project$Components$Produits$Common$MarcheCibleModel$Revenus = 3;
var $author$project$Components$Produits$Common$MarcheCibleModel$allObjectifs = function () {
	var next = function (list) {
		var _v0 = $elm$core$List$head(list);
		if (_v0.$ === 1) {
			return next(
				A2($elm$core$List$cons, 0, list));
		} else {
			switch (_v0.a) {
				case 0:
					var _v1 = _v0.a;
					return next(
						A2($elm$core$List$cons, 1, list));
				case 1:
					var _v2 = _v0.a;
					return next(
						A2($elm$core$List$cons, 2, list));
				case 2:
					var _v3 = _v0.a;
					return next(
						A2($elm$core$List$cons, 3, list));
				case 3:
					var _v4 = _v0.a;
					return next(
						A2($elm$core$List$cons, 4, list));
				case 4:
					var _v5 = _v0.a;
					return next(
						A2($elm$core$List$cons, 5, list));
				case 5:
					var _v6 = _v0.a;
					return next(
						A2($elm$core$List$cons, 6, list));
				default:
					var _v7 = _v0.a;
					return list;
			}
		}
	};
	return $elm$core$List$reverse(
		next(_List_Nil));
}();
var $author$project$Perfimmo$Form$FormModel$listSelectFrom = function (args) {
	var selected = function (item) {
		return A2($elm$core$List$member, item, args.jN);
	};
	var toTuple = function (item) {
		return _Utils_Tuple2(
			_Utils_Tuple2(
				args.dX(item),
				args.dX(item)),
			selected(item));
	};
	return function (availableValues) {
		return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init, availableValues, _List_Nil);
	}(
		A3(
			$elm$core$Basics$composeR,
			$elm$core$Dict$fromList,
			$elm$core$Maybe$Just,
			A2($elm$core$List$map, toTuple, args.fP)));
};
var $author$project$Components$Produits$Common$MarcheCibleModel$objectifsToString = function (_enum) {
	switch (_enum) {
		case 0:
			return 'Conservation';
		case 1:
			return 'Croissance';
		case 2:
			return 'Rendement';
		case 3:
			return 'Revenus';
		case 4:
			return 'Couverture';
		case 5:
			return 'Optionnel ou à effet de levier';
		default:
			return 'Autre';
	}
};
var $author$project$Components$Produits$Details$Model$initObjectifs = {
	fM: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
	gh: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
	gj: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
	gp: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
	nS: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
	jp: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
	jx: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
	at: $author$project$Perfimmo$Form$FormModel$listSelectFrom(
		{fP: $author$project$Components$Produits$Common$MarcheCibleModel$allObjectifs, jN: _List_Nil, dX: $author$project$Components$Produits$Common$MarcheCibleModel$objectifsToString})
};
var $author$project$Common$Referentiel$ProfilExtraFinancier$allPAIs = function () {
	var next = function (pais) {
		var _v0 = $elm$core$List$head(pais);
		if (_v0.$ === 1) {
			return next(
				A2($elm$core$List$cons, 0, pais));
		} else {
			switch (_v0.a) {
				case 0:
					var _v1 = _v0.a;
					return next(
						A2($elm$core$List$cons, 1, pais));
				case 1:
					var _v2 = _v0.a;
					return next(
						A2($elm$core$List$cons, 2, pais));
				case 2:
					var _v3 = _v0.a;
					return next(
						A2($elm$core$List$cons, 3, pais));
				case 3:
					var _v4 = _v0.a;
					return next(
						A2($elm$core$List$cons, 4, pais));
				case 4:
					var _v5 = _v0.a;
					return next(
						A2($elm$core$List$cons, 5, pais));
				case 5:
					var _v6 = _v0.a;
					return next(
						A2($elm$core$List$cons, 6, pais));
				case 6:
					var _v7 = _v0.a;
					return next(
						A2($elm$core$List$cons, 7, pais));
				case 7:
					var _v8 = _v0.a;
					return next(
						A2($elm$core$List$cons, 8, pais));
				case 8:
					var _v9 = _v0.a;
					return next(
						A2($elm$core$List$cons, 9, pais));
				default:
					var _v10 = _v0.a;
					return pais;
			}
		}
	};
	return next(_List_Nil);
}();
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$empty = A3($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$FormPourcent, $elm$core$Maybe$Nothing, '', _List_Nil);
var $author$project$Components$Produits$Details$Model$initCategorieProduit = {
	Z: {
		d3: {aX: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, bK: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$empty},
		b1: {aX: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, dr: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$empty, ds: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$empty, dt: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$empty},
		b2: {aX: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, dr: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$empty, ds: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$empty, dt: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$empty}
	},
	aX: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty
};
var $author$project$Common$Referentiel$ProfilExtraFinancier$paiToString = function (pai) {
	switch (pai) {
		case 0:
			return 'Gaz à effet de serre';
		case 1:
			return 'Niveau d\'impact sur la biodiversité';
		case 2:
			return 'Emissions polluantes dans l\'eau';
		case 3:
			return 'Génération de déchets dangereux';
		case 4:
			return 'Inefficacité énergétique (immobilier)';
		case 5:
			return 'Respect des normes internationales (OCDE, Nations Unies)';
		case 6:
			return 'Processus de contrôle des normes internationales';
		case 7:
			return 'Égalité de rémunération (Homme/Femme)';
		case 8:
			return 'Diversité des genres au sein des conseils d\'administration';
		default:
			return 'Exposition aux armes controversées';
	}
};
var $author$project$Components$Produits$Details$Model$initPEF = {
	ej: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$empty,
	de: {
		hZ: $author$project$Perfimmo$Form$FormModel$listSelectFrom(
			{fP: $author$project$Common$Referentiel$ProfilExtraFinancier$allPAIs, jN: _List_Nil, dX: $author$project$Common$Referentiel$ProfilExtraFinancier$paiToString}),
		bK: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$empty,
		iU: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty
	},
	Q: $author$project$Components$Produits$Details$Model$initCategorieProduit,
	i_: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
	fi: {aX: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, bK: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$empty}
};
var $author$project$Components$Produits$Details$Model$initSupportInvestissement = {ha: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, he: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, hg: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, il: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, j6: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, j9: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, kd: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, ke: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty};
var $author$project$Components$Produits$Details$Model$AHorizon = 3;
var $author$project$Components$Produits$Details$Model$General = {$: 0};
var $author$project$Components$Produits$Details$Model$Libre = 0;
var $author$project$Components$Produits$Details$Model$Profilee = 1;
var $author$project$Components$Produits$Details$Model$SousMandat = 2;
var $author$project$Components$Produits$Details$Model$displayModeToString = function (mode) {
	if (!mode.$) {
		return 'Règle générale pour les commissions';
	} else {
		var cabinetUid = mode.a;
		var cabinetNom = mode.b;
		return cabinetNom;
	}
};
var $author$project$Components$Produits$Details$Model$SupportsRegroupesParModeDeGestion = F4(
	function (gestionLibre, gestionProfilee, gestionSousMandat, gestionAHorizon) {
		return {mC: gestionAHorizon, mD: gestionLibre, mE: gestionProfilee, mF: gestionSousMandat};
	});
var $author$project$Components$Produits$Details$Model$initSupports = A4($author$project$Components$Produits$Details$Model$SupportsRegroupesParModeDeGestion, _List_Nil, _List_Nil, _List_Nil, _List_Nil);
var $author$project$Components$Produits$Details$Model$modeDeGestionToString = function (gestion) {
	switch (gestion) {
		case 0:
			return 'Gestion libre';
		case 1:
			return 'Gestion profilée';
		case 2:
			return 'Gestion sous mandat';
		default:
			return 'Gestion à horizon';
	}
};
var $author$project$Perfimmo$Input$SelectInput$selectItem = F2(
	function (selectedItem, model) {
		return _Utils_update(
			model,
			{aV: 0, dO: selectedItem});
	});
var $author$project$Components$Produits$Details$Model$initSupportsTab = {
	lW: A2(
		$author$project$Perfimmo$Input$SelectInput$selectItem,
		$elm$core$Maybe$Just($author$project$Components$Produits$Details$Model$General),
		A3(
			$author$project$Perfimmo$Input$SelectInput$initSelect,
			'filtreTypeImport',
			'',
			_List_fromArray(
				[
					_Utils_Tuple2(
					$author$project$Components$Produits$Details$Model$General,
					$author$project$Components$Produits$Details$Model$displayModeToString($author$project$Components$Produits$Details$Model$General))
				]))),
	mr: $author$project$Components$Produits$Details$Model$initSupports,
	nP: false,
	e3: A3(
		$author$project$Perfimmo$Input$SelectInput$initSelect,
		'filtreModeDeGestion',
		'Filtrer par mode de gestion',
		_List_fromArray(
			[
				_Utils_Tuple2(
				0,
				$author$project$Components$Produits$Details$Model$modeDeGestionToString(0)),
				_Utils_Tuple2(
				1,
				$author$project$Components$Produits$Details$Model$modeDeGestionToString(1)),
				_Utils_Tuple2(
				2,
				$author$project$Components$Produits$Details$Model$modeDeGestionToString(2)),
				_Utils_Tuple2(
				3,
				$author$project$Components$Produits$Details$Model$modeDeGestionToString(3))
			])),
	e4: '',
	fe: $author$project$Components$Produits$Details$Model$initSupports
};
var $author$project$Components$Produits$Common$MarcheCibleModel$mapping = F2(
	function (all, toS) {
		return $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (x) {
					return _Utils_Tuple2(
						toS(x),
						x);
				},
				all));
	});
var $author$project$Components$Produits$Common$MarcheCibleModel$parseCapaciteSubirPerte = function (input) {
	return A2(
		$elm$core$Dict$get,
		input,
		A2($author$project$Components$Produits$Common$MarcheCibleModel$mapping, $author$project$Components$Produits$Common$MarcheCibleModel$allCapaciteSubirPerte, $author$project$Components$Produits$Common$MarcheCibleModel$capaciteSubirPerteToString));
};
var $author$project$Components$Produits$Common$Classification$mapping = $elm$core$Dict$fromList(
	A2(
		$elm$core$List$map,
		function (classification) {
			return _Utils_Tuple2(
				$author$project$Components$Produits$Common$Classification$classificationToString(classification),
				classification);
		},
		$author$project$Components$Produits$Common$Classification$allClassifications));
var $author$project$Components$Produits$Common$Classification$parseClassification = function (input) {
	return A2($elm$core$Dict$get, input, $author$project$Components$Produits$Common$Classification$mapping);
};
var $author$project$Components$Produits$Common$Famille$mapping = $elm$core$Dict$fromList(
	A2(
		$elm$core$List$map,
		function (famille) {
			return _Utils_Tuple2(
				$author$project$Components$Produits$Common$Famille$familleToString(famille),
				famille);
		},
		$author$project$Components$Produits$Common$Famille$allFamilles));
var $author$project$Components$Produits$Common$Famille$parseFamille = function (input) {
	return A2($elm$core$Dict$get, input, $author$project$Components$Produits$Common$Famille$mapping);
};
var $author$project$Components$Produits$Common$MarcheCibleModel$parseHorizon = function (input) {
	return A2(
		$elm$core$Dict$get,
		input,
		A2($author$project$Components$Produits$Common$MarcheCibleModel$mapping, $author$project$Components$Produits$Common$MarcheCibleModel$allHorizon, $author$project$Components$Produits$Common$MarcheCibleModel$horizonToString));
};
var $author$project$Components$Produits$Common$MarcheCibleModel$profilMarcheCibleToString = function (_enum) {
	switch (_enum) {
		case 0:
			return 'Sécuritaire';
		case 1:
			return 'Défensif';
		case 2:
			return 'Équilibré';
		case 3:
			return 'Dynamique';
		default:
			return 'Offensif';
	}
};
var $author$project$Components$Produits$Common$MarcheCibleModel$parseProfilMarcheCible = function (input) {
	return A2(
		$elm$core$Dict$get,
		input,
		A2($author$project$Components$Produits$Common$MarcheCibleModel$mapping, $author$project$Components$Produits$Common$MarcheCibleModel$allProfilMarcheCible, $author$project$Components$Produits$Common$MarcheCibleModel$profilMarcheCibleToString));
};
var $author$project$Components$Produits$Common$SousClassification$sousClassificationToString = function (sousClassification) {
	switch (sousClassification) {
		case 0:
			return 'Conseil sur biens divers et autres produits de placement';
		case 1:
			return 'Conseil sur instruments financiers';
		case 2:
			return 'Conseil sur les services d\'investissement';
		case 3:
			return 'Honoraires CIF';
		default:
			return 'Honoraires IAS';
	}
};
var $author$project$Components$Produits$Common$SousClassification$mapping = $elm$core$Dict$fromList(
	A2(
		$elm$core$List$map,
		function (sousClassification) {
			return _Utils_Tuple2(
				$author$project$Components$Produits$Common$SousClassification$sousClassificationToString(sousClassification),
				sousClassification);
		},
		$author$project$Components$Produits$Common$SousClassification$allSousClassifications));
var $author$project$Components$Produits$Common$SousClassification$parseSousClassification = function (input) {
	return A2($elm$core$Dict$get, input, $author$project$Components$Produits$Common$SousClassification$mapping);
};
var $author$project$Components$Produits$Common$CommercialisationModel$typeAsString = function (type_) {
	switch (type_) {
		case 0:
			return 'PER';
		case 1:
			return 'PERP';
		case 2:
			return 'Madelin';
		case 3:
			return 'Article 83';
		case 4:
			return 'PÈRE';
		case 5:
			return 'PREFON (Caisse nationale de prévoyance de la fonction publique)';
		case 6:
			return 'CRH';
		case 7:
			return 'COREMC (Complément de retraite mutualiste)';
		default:
			return 'PERCO';
	}
};
var $author$project$Components$Produits$Common$CommercialisationModel$parseType = function (string) {
	return A2(
		$elm_community$list_extra$List$Extra$find,
		function (type_) {
			return _Utils_eq(
				string,
				$author$project$Components$Produits$Common$CommercialisationModel$typeAsString(type_));
		},
		$author$project$Components$Produits$Common$CommercialisationModel$allTypes);
};
var $author$project$Components$Produits$Common$ExperienceModel$typeAsString = function (type_) {
	switch (type_) {
		case 0:
			return 'novice';
		case 1:
			return 'informé';
		default:
			return 'expérimenté';
	}
};
var $author$project$Components$Produits$Common$ExperienceModel$parseType = function (string) {
	return A2(
		$elm_community$list_extra$List$Extra$find,
		function (type_) {
			return _Utils_eq(
				string,
				$author$project$Components$Produits$Common$ExperienceModel$typeAsString(type_));
		},
		$author$project$Components$Produits$Common$ExperienceModel$allTypes);
};
var $author$project$Components$Produits$Common$MarcheCibleModel$typeMarcheCibleToString = function (typeMarcheCible) {
	if (!typeMarcheCible) {
		return 'Producteur';
	} else {
		return 'Distributeur';
	}
};
var $author$project$Components$Produits$Common$MarcheCibleModel$parseTypeMarcheCible = function (input) {
	return A2(
		$elm$core$Dict$get,
		input,
		A2($author$project$Components$Produits$Common$MarcheCibleModel$mapping, $author$project$Components$Produits$Common$MarcheCibleModel$allTypeMarcheCible, $author$project$Components$Produits$Common$MarcheCibleModel$typeMarcheCibleToString));
};
var $elm$core$List$sortWith = _List_sortWith;
var $elm$core$String$toLower = _String_toLower;
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Components$Produits$Details$Model$initForm = function (producteurs) {
	return {
		k3: $elm$core$Maybe$Nothing,
		lX: false,
		mZ: {
			k2: $elm$core$Maybe$Nothing,
			f9: $author$project$Components$Produits$Details$Model$enumSelect(
				{fP: $author$project$Components$Produits$Common$Classification$allClassifications, af: $author$project$Components$Produits$Common$Classification$parseClassification, dX: $author$project$Components$Produits$Common$Classification$classificationToString}),
			cf: {gG: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, iW: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, iX: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, iY: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty},
			g0: $author$project$Components$Produits$Details$Model$enumSelect(
				{fP: $author$project$Components$Produits$Common$Famille$allFamilles, af: $author$project$Components$Produits$Common$Famille$parseFamille, dX: $author$project$Components$Produits$Common$Famille$familleToString}),
			m9: '',
			dl: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$addInfo, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty),
			iq: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$empty,
			ix: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
			i7: A4(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$init,
				A2(
					$elm$core$List$sortWith,
					F2(
						function (p1, p2) {
							return (_Utils_cmp(
								$elm$core$String$toLower(p1),
								$elm$core$String$toLower(p2)) > 0) ? 2 : ((_Utils_cmp(
								$elm$core$String$toLower(p1),
								$elm$core$String$toLower(p2)) < 0) ? 0 : 1);
						}),
					A2(
						$elm$core$List$map,
						function ($) {
							return $.dl;
						},
						$elm$core$Dict$values(producteurs))),
				_List_fromArray(
					[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]),
				$elm$core$Maybe$Just,
				$elm$core$Basics$identity),
			jZ: $author$project$Components$Produits$Details$Model$enumSelect(
				{fP: $author$project$Components$Produits$Common$SousClassification$allSousClassifications, af: $author$project$Components$Produits$Common$SousClassification$parseSousClassification, dX: $author$project$Components$Produits$Common$SousClassification$sousClassificationToString}),
			kD: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty
		},
		c: {
			fv: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
			fF: {h0: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$empty, h3: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$empty},
			bi: {
				k5: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				m7: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				hW: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				ir: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				jP: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				at: $author$project$Components$Produits$Details$Model$enumSelect(
					{fP: $author$project$Components$Produits$Common$MarcheCibleModel$allCapaciteSubirPerte, af: $author$project$Components$Produits$Common$MarcheCibleModel$parseCapaciteSubirPerte, dX: $author$project$Components$Produits$Common$MarcheCibleModel$capaciteSubirPerteToString})
			},
			lJ: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$empty,
			ci: {
				me: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				m_: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				nJ: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				at: $author$project$Components$Produits$Details$Model$enumSelect(
					{fP: $author$project$Components$Produits$Common$ExperienceModel$allTypes, af: $author$project$Components$Produits$Common$ExperienceModel$parseType, dX: $author$project$Components$Produits$Common$ExperienceModel$typeAsString})
			},
			bx: {
				lE: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				nj: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				nw: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				oU: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				at: $author$project$Components$Produits$Details$Model$enumSelect(
					{fP: $author$project$Components$Produits$Common$MarcheCibleModel$allHorizon, af: $author$project$Components$Produits$Common$MarcheCibleModel$parseHorizon, dX: $author$project$Components$Produits$Common$MarcheCibleModel$horizonToString})
			},
			ap: $author$project$Components$Produits$Details$Model$initObjectifs,
			a6: {
				cj: {gI: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, gT: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, gY: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, iv: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, jH: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty},
				oa: $author$project$Components$Produits$Details$Model$enumSelect(
					{fP: $author$project$Components$Produits$Common$MarcheCibleModel$allProfilMarcheCible, af: $author$project$Components$Produits$Common$MarcheCibleModel$parseProfilMarcheCible, dX: $author$project$Components$Produits$Common$MarcheCibleModel$profilMarcheCibleToString})
			},
			fg: {nn: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, no: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty},
			cH: {lB: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, $9: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, je: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty, jf: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty},
			ku: $author$project$Components$Produits$Details$Model$enumSelect(
				{fP: $author$project$Components$Produits$Common$MarcheCibleModel$allTypeMarcheCible, af: $author$project$Components$Produits$Common$MarcheCibleModel$parseTypeMarcheCible, dX: $author$project$Components$Produits$Common$MarcheCibleModel$typeMarcheCibleToString}),
			kE: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty
		},
		jg: $author$project$Components$Produits$Details$Model$initPEF,
		fe: $author$project$Components$Produits$Details$Model$initSupportsTab,
		M: {
			cn: $author$project$Components$Produits$Details$Model$initFraisDuContrat,
			as: $author$project$Components$Produits$Details$Model$initSupportInvestissement,
			at: $author$project$Components$Produits$Details$Model$enumSelect(
				{fP: $author$project$Components$Produits$Common$CommercialisationModel$allTypes, af: $author$project$Components$Produits$Common$CommercialisationModel$parseType, dX: $author$project$Components$Produits$Common$CommercialisationModel$typeAsString})
		}
	};
};
var $author$project$Components$Produits$Details$Model$init = F4(
	function (timezone, producteurs, params, mode) {
		return {
			eg: params.gn,
			gO: $elm$core$Maybe$Nothing,
			aG: false,
			dj: mode,
			ip: true,
			n5: producteurs,
			i8: $author$project$Components$Produits$Details$Model$initForm(producteurs),
			e0: $elm$core$Maybe$Nothing,
			jc: $elm$core$Maybe$Nothing,
			jE: false,
			e6: 0,
			aO: timezone,
			kC: _List_Nil
		};
	});
var $author$project$Components$Produits$Details$Update$ProduitFetched = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$API$GetResult$DetailedProduit = function (uid) {
	return function (nom) {
		return function (label) {
			return function (disponiblePourLaGeneration) {
				return function (valideParProducteur) {
					return function (producteur) {
						return function (classification) {
							return function (noteRisqueProduit) {
								return function (marcheCible) {
									return function (experience) {
										return function (commercialisation) {
											return function (profilExtraFinancier) {
												return function (documentsPresentation) {
													return function (attribution) {
														return function (supportsInvestissement) {
															return function (links) {
																return {k2: attribution, f9: classification, lw: commercialisation, lY: disponiblePourLaGeneration, cf: documentsPresentation, ci: experience, m9: label, nf: links, c: marcheCible, dl: nom, iq: noteRisqueProduit, i7: producteur, jg: profilExtraFinancier, oH: supportsInvestissement, kx: uid, kD: valideParProducteur};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Produits$Common$DuplicationInformation$Accessible = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Common$DuplicationInformation$Duplicated = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Common$DuplicationInformation$AccessibleResult = function (toCgpIds) {
	return {oP: toCgpIds};
};
var $author$project$Components$Produits$Common$DuplicationInformation$decodeAccessible = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'toCgpIds',
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
	$elm$json$Json$Decode$succeed($author$project$Components$Produits$Common$DuplicationInformation$AccessibleResult));
var $author$project$Components$Produits$Common$DuplicationInformation$DuplicatedResult = F2(
	function (fromProductId, forCabinetId) {
		return {mt: forCabinetId, mA: fromProductId};
	});
var $author$project$Components$Produits$Common$DuplicationInformation$decodeDuplicated = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'forCabinetId',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'fromProductId',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Components$Produits$Common$DuplicationInformation$DuplicatedResult)));
var $author$project$Components$Produits$Common$DuplicationInformation$attributionInformationDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Duplicated',
			A2($elm$json$Json$Decode$map, $author$project$Components$Produits$Common$DuplicationInformation$Duplicated, $author$project$Components$Produits$Common$DuplicationInformation$decodeDuplicated)),
			A2(
			$elm$json$Json$Decode$field,
			'Accessible',
			A2($elm$json$Json$Decode$map, $author$project$Components$Produits$Common$DuplicationInformation$Accessible, $author$project$Components$Produits$Common$DuplicationInformation$decodeAccessible))
		]));
var $author$project$Components$Produits$API$GetResult$ProduitDocumentsPresentation = F4(
	function (presentationProducteur, presentationProduit, presentationPreconisation, declarationAdequation) {
		return {gG: declarationAdequation, iW: presentationPreconisation, iX: presentationProducteur, iY: presentationProduit};
	});
var $author$project$Components$Produits$API$GetResult$decodeDocumentPresentation = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'declarationAdequation',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'presentationPreconisation',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'presentationProduit',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'presentationProducteur',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitDocumentsPresentation)))));
var $author$project$Components$Produits$API$GetResult$ProduitClassification = F4(
	function (activite, famille, opc, sousClassification) {
		return {kR: activite, g0: famille, ix: opc, jZ: sousClassification};
	});
var $author$project$Components$Produits$API$Common$classificationDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'IAS',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'CIF',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'IOBSP',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'ITI',
			$elm$json$Json$Decode$succeed(3))
		]));
var $author$project$Components$Produits$API$Common$familleDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'AssuranceRisquesDivers',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'AssuranceVie',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'AssuranceCapi',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'CompteTitres',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'CompteTitresPea',
			$elm$json$Json$Decode$succeed(4)),
			A2(
			$elm$json$Json$Decode$field,
			'DefiscalisationIsf',
			$elm$json$Json$Decode$succeed(5)),
			A2(
			$elm$json$Json$Decode$field,
			'EpargneSalariale',
			$elm$json$Json$Decode$succeed(6)),
			A2(
			$elm$json$Json$Decode$field,
			'FipFcpiFcpr',
			$elm$json$Json$Decode$succeed(7)),
			A2(
			$elm$json$Json$Decode$field,
			'GestionSousMandat',
			$elm$json$Json$Decode$succeed(8)),
			A2(
			$elm$json$Json$Decode$field,
			'Gff',
			$elm$json$Json$Decode$succeed(9)),
			A2(
			$elm$json$Json$Decode$field,
			'GirardinIs',
			$elm$json$Json$Decode$succeed(10)),
			A2(
			$elm$json$Json$Decode$field,
			'Honoraires',
			$elm$json$Json$Decode$succeed(11)),
			A2(
			$elm$json$Json$Decode$field,
			'Lmnp',
			$elm$json$Json$Decode$succeed(12)),
			A2(
			$elm$json$Json$Decode$field,
			'MandatDeGestion',
			$elm$json$Json$Decode$succeed(13)),
			A2(
			$elm$json$Json$Decode$field,
			'Opci',
			$elm$json$Json$Decode$succeed(14)),
			A2(
			$elm$json$Json$Decode$field,
			'Oppci',
			$elm$json$Json$Decode$succeed(15)),
			A2(
			$elm$json$Json$Decode$field,
			'Pinel',
			$elm$json$Json$Decode$succeed(16)),
			A2(
			$elm$json$Json$Decode$field,
			'PinelOutreMer',
			$elm$json$Json$Decode$succeed(17)),
			A2(
			$elm$json$Json$Decode$field,
			'Prevoyance',
			$elm$json$Json$Decode$succeed(18)),
			A2(
			$elm$json$Json$Decode$field,
			'Retraite',
			$elm$json$Json$Decode$succeed(19)),
			A2(
			$elm$json$Json$Decode$field,
			'Scpi',
			$elm$json$Json$Decode$succeed(20))
		]));
var $author$project$Components$Produits$API$Common$sousClassificationDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'ConseilSurBiensDiversEtAutresProduitsDePlacement',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'ConseilSurInstrumentsFinanciers',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'ConseilSurLesServicesDInvestissement',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'HonorairesCIF',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'HonorairesIAS',
			$elm$json$Json$Decode$succeed(4))
		]));
var $author$project$Components$Produits$API$GetResult$decodeProduitClassification = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'sous_classification',
	$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$Common$sousClassificationDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'OPC',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'famille',
			$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$Common$familleDecoder),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'activite',
				$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$Common$classificationDecoder),
				$elm$core$Maybe$Nothing,
				$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitClassification)))));
var $author$project$Components$Produits$API$GetResult$ProduitCommercialisation = F3(
	function (fraisDuContrat, supportInvestissement, type_) {
		return {cn: fraisDuContrat, as: supportInvestissement, kv: type_};
	});
var $author$project$Components$Produits$API$Common$commercialisationTypeDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'PER',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'PERP',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'Madelin',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'Article83',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'PERE',
			$elm$json$Json$Decode$succeed(4)),
			A2(
			$elm$json$Json$Decode$field,
			'PREFON',
			$elm$json$Json$Decode$succeed(5)),
			A2(
			$elm$json$Json$Decode$field,
			'CRH',
			$elm$json$Json$Decode$succeed(6)),
			A2(
			$elm$json$Json$Decode$field,
			'COREMC',
			$elm$json$Json$Decode$succeed(7)),
			A2(
			$elm$json$Json$Decode$field,
			'PERCO',
			$elm$json$Json$Decode$succeed(8))
		]));
var $author$project$Components$Produits$API$GetResult$ProduitCommercialisationFraisDuContrat = F4(
	function (fraisGestionFondsEuro, fraisGestionUC, fraisTransfertSortant, fraisVersement) {
		return {hf: fraisGestionFondsEuro, hh: fraisGestionUC, hi: fraisTransfertSortant, hj: fraisVersement};
	});
var $author$project$Components$Produits$API$GetResult$decodeProduitCommercialisationFraisDuContrat = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'frais_versement',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'frais_transfert_sortant',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'frais_gestion_UC',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'frais_gestion_fonds_Euro',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitCommercialisationFraisDuContrat)))));
var $author$project$Components$Produits$API$GetResult$ProduitCommercialisationSupportInvestissement = F8(
	function (fondsEuro, fraisArrerage, fraisGestionPhaseRente, nombreUc, supportsImmobiliers, tableMortalite, tauxMinimumGaranti, tauxTechnique) {
		return {ha: fondsEuro, he: fraisArrerage, hg: fraisGestionPhaseRente, il: nombreUc, j6: supportsImmobiliers, j9: tableMortalite, kd: tauxMinimumGaranti, ke: tauxTechnique};
	});
var $author$project$Components$Produits$API$GetResult$decodeProduitCommercialisationSupportInvestissement = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'taux_technique',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'taux_minimum_garanti',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'table_mortalite',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'supports_immobiliers',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'nombre_uc',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'frais_gestion_phase_rente',
						$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'frais_arrerage',
							$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'fonds_euro',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
								$elm$core$Maybe$Nothing,
								$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitCommercialisationSupportInvestissement)))))))));
var $author$project$Components$Produits$API$GetResult$decodeProduitCommercialisation = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'type',
	$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$Common$commercialisationTypeDecoder),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'support_investissement',
		$author$project$Components$Produits$API$GetResult$decodeProduitCommercialisationSupportInvestissement,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'frais_du_contrat',
			$author$project$Components$Produits$API$GetResult$decodeProduitCommercialisationFraisDuContrat,
			$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitCommercialisation))));
var $author$project$Components$Produits$API$GetResult$ProduitExperience = F4(
	function (experimenteTexte, informeTexte, noviceTexte, valeur) {
		return {mf: experimenteTexte, m$: informeTexte, nK: noviceTexte, at: valeur};
	});
var $author$project$Components$Produits$API$Common$expValeurDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Novice',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'Informe',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'Experimente',
			$elm$json$Json$Decode$succeed(2))
		]));
var $author$project$Components$Produits$API$GetResult$decodeProduitExperience = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'valeurs',
	$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$Common$expValeurDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'novice_texte',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'informe_texte',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'experimente_texte',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitExperience)))));
var $author$project$Components$Produits$API$GetResult$ProduitMarcheCible = function (accessiblePersonnesVulnerables) {
	return function (ageLimiteClient) {
		return function (capacitePerte) {
			return function (dateMiseAJour) {
				return function (horizon) {
					return function (objectifs) {
						return function (professionExclues) {
							return function (profils) {
								return function (synthese) {
									return function (typeInvestisseur) {
										return function (typeMarcheCible) {
											return function (version) {
												return {fv: accessiblePersonnesVulnerables, kT: ageLimiteClient, ln: capacitePerte, ej: dateMiseAJour, bx: horizon, ap: objectifs, je: professionExclues, a6: profils, fg: synthese, cH: typeInvestisseur, ku: typeMarcheCible, kE: version};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $justinmimbs$date$Date$deadEndToString = function (_v0) {
	var problem = _v0.i4;
	if (problem.$ === 12) {
		var message = problem.a;
		return message;
	} else {
		return 'Expected a date in ISO 8601 format';
	}
};
var $justinmimbs$date$Date$MonthAndDay = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $justinmimbs$date$Date$OrdinalDay = function (a) {
	return {$: 2, a: a};
};
var $justinmimbs$date$Date$WeekAndWeekday = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$Advanced$commit = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, true, a, s);
	};
};
var $elm$parser$Parser$commit = $elm$parser$Parser$Advanced$commit;
var $elm$parser$Parser$mapChompedString = $elm$parser$Parser$Advanced$mapChompedString;
var $justinmimbs$date$Date$int1 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	$elm$parser$Parser$chompIf($elm$core$Char$isDigit));
var $justinmimbs$date$Date$int2 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed(0),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $justinmimbs$date$Date$int3 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Date$dayOfYear = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($elm$core$Basics$identity),
				$elm$parser$Parser$token('-')),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$backtrackable(
						A2(
							$elm$parser$Parser$andThen,
							$elm$parser$Parser$commit,
							A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int2),
									$elm$parser$Parser$succeed(1)
								]))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$ignorer,
								$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
								$elm$parser$Parser$token('W')),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int1),
									$elm$parser$Parser$succeed(1)
								])))
					]))),
			$elm$parser$Parser$backtrackable(
			A2(
				$elm$parser$Parser$andThen,
				$elm$parser$Parser$commit,
				A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
						$justinmimbs$date$Date$int2),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$justinmimbs$date$Date$int2,
								$elm$parser$Parser$succeed(1)
							]))))),
			A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3),
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
					$elm$parser$Parser$token('W')),
				$justinmimbs$date$Date$int2),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$justinmimbs$date$Date$int1,
						$elm$parser$Parser$succeed(1)
					]))),
			$elm$parser$Parser$succeed(
			$justinmimbs$date$Date$OrdinalDay(1))
		]));
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$isBetweenInt = F3(
	function (a, b, x) {
		return (_Utils_cmp(a, x) < 1) && (_Utils_cmp(x, b) < 1);
	});
var $justinmimbs$date$Date$monthToName = function (m) {
	switch (m) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$fromCalendarParts = F3(
	function (y, mn, d) {
		return (!A3($justinmimbs$date$Date$isBetweenInt, 1, 12, mn)) ? $elm$core$Result$Err(
			'Invalid date: ' + (('month ' + ($elm$core$String$fromInt(mn) + ' is out of range')) + (' (1 to 12)' + ('; received (year ' + ($elm$core$String$fromInt(y) + (', month ' + ($elm$core$String$fromInt(mn) + (', day ' + ($elm$core$String$fromInt(d) + ')'))))))))) : ((!A3(
			$justinmimbs$date$Date$isBetweenInt,
			1,
			A2(
				$justinmimbs$date$Date$daysInMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn)),
			d)) ? $elm$core$Result$Err(
			'Invalid date: ' + (('day ' + ($elm$core$String$fromInt(d) + ' is out of range')) + ((' (1 to ' + ($elm$core$String$fromInt(
				A2(
					$justinmimbs$date$Date$daysInMonth,
					y,
					$justinmimbs$date$Date$numberToMonth(mn))) + ')')) + ((' for ' + $justinmimbs$date$Date$monthToName(
				$justinmimbs$date$Date$numberToMonth(mn))) + ((((mn === 2) && (d === 29)) ? (' (' + ($elm$core$String$fromInt(y) + ' is not a leap year)')) : '') + ('; received (year ' + ($elm$core$String$fromInt(y) + (', month ' + ($elm$core$String$fromInt(mn) + (', day ' + ($elm$core$String$fromInt(d) + ')'))))))))))) : $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2(
				$justinmimbs$date$Date$daysBeforeMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn))) + d));
	});
var $justinmimbs$date$Date$fromOrdinalParts = F2(
	function (y, od) {
		var daysInYear = $justinmimbs$date$Date$isLeapYear(y) ? 366 : 365;
		return (!A3($justinmimbs$date$Date$isBetweenInt, 1, daysInYear, od)) ? $elm$core$Result$Err(
			'Invalid ordinal date: ' + (('ordinal-day ' + ($elm$core$String$fromInt(od) + ' is out of range')) + ((' (1 to ' + ($elm$core$String$fromInt(daysInYear) + ')')) + ((' for ' + $elm$core$String$fromInt(y)) + ('; received (year ' + ($elm$core$String$fromInt(y) + (', ordinal-day ' + ($elm$core$String$fromInt(od) + ')')))))))) : $elm$core$Result$Ok(
			$justinmimbs$date$Date$daysBeforeYear(y) + od);
	});
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$is53WeekYear = function (y) {
	var wdnJan1 = $justinmimbs$date$Date$weekdayNumber(
		$justinmimbs$date$Date$firstOfYear(y));
	return (wdnJan1 === 4) || ((wdnJan1 === 3) && $justinmimbs$date$Date$isLeapYear(y));
};
var $justinmimbs$date$Date$fromWeekParts = F3(
	function (wy, wn, wdn) {
		var weeksInYear = $justinmimbs$date$Date$is53WeekYear(wy) ? 53 : 52;
		return (!A3($justinmimbs$date$Date$isBetweenInt, 1, weeksInYear, wn)) ? $elm$core$Result$Err(
			'Invalid week date: ' + (('week ' + ($elm$core$String$fromInt(wn) + ' is out of range')) + ((' (1 to ' + ($elm$core$String$fromInt(weeksInYear) + ')')) + ((' for ' + $elm$core$String$fromInt(wy)) + ('; received (year ' + ($elm$core$String$fromInt(wy) + (', week ' + ($elm$core$String$fromInt(wn) + (', weekday ' + ($elm$core$String$fromInt(wdn) + ')')))))))))) : ((!A3($justinmimbs$date$Date$isBetweenInt, 1, 7, wdn)) ? $elm$core$Result$Err(
			'Invalid week date: ' + (('weekday ' + ($elm$core$String$fromInt(wdn) + ' is out of range')) + (' (1 to 7)' + ('; received (year ' + ($elm$core$String$fromInt(wy) + (', week ' + ($elm$core$String$fromInt(wn) + (', weekday ' + ($elm$core$String$fromInt(wdn) + ')'))))))))) : $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeWeekYear(wy) + ((wn - 1) * 7)) + wdn));
	});
var $justinmimbs$date$Date$fromYearAndDayOfYear = function (_v0) {
	var y = _v0.a;
	var doy = _v0.b;
	switch (doy.$) {
		case 0:
			var mn = doy.a;
			var d = doy.b;
			return A3($justinmimbs$date$Date$fromCalendarParts, y, mn, d);
		case 1:
			var wn = doy.a;
			var wdn = doy.b;
			return A3($justinmimbs$date$Date$fromWeekParts, y, wn, wdn);
		default:
			var od = doy.a;
			return A2($justinmimbs$date$Date$fromOrdinalParts, y, od);
	}
};
var $justinmimbs$date$Date$int4 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed(0),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									$elm$parser$Parser$chompIf(
									function (c) {
										return c === '-';
									}),
									$elm$parser$Parser$succeed(0)
								]))),
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $justinmimbs$date$Date$resultToParser = function (result) {
	if (!result.$) {
		var x = result.a;
		return $elm$parser$Parser$succeed(x);
	} else {
		var message = result.a;
		return $elm$parser$Parser$problem(message);
	}
};
var $justinmimbs$date$Date$parser = A2(
	$elm$parser$Parser$andThen,
	A2($elm$core$Basics$composeR, $justinmimbs$date$Date$fromYearAndDayOfYear, $justinmimbs$date$Date$resultToParser),
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Tuple$pair),
			$justinmimbs$date$Date$int4),
		$justinmimbs$date$Date$dayOfYear));
var $justinmimbs$date$Date$fromIsoString = A2(
	$elm$core$Basics$composeR,
	$elm$parser$Parser$run(
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			A2(
				$elm$parser$Parser$ignorer,
				$justinmimbs$date$Date$parser,
				A2(
					$elm$parser$Parser$andThen,
					$justinmimbs$date$Date$resultToParser,
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2($elm$parser$Parser$map, $elm$core$Result$Ok, $elm$parser$Parser$end),
								A2(
								$elm$parser$Parser$map,
								$elm$core$Basics$always(
									$elm$core$Result$Err('Expected a date only, not a date and time')),
								$elm$parser$Parser$chompIf(
									$elm$core$Basics$eq('T'))),
								$elm$parser$Parser$succeed(
								$elm$core$Result$Err('Expected a date only'))
							])))))),
	$elm$core$Result$mapError(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$head,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($justinmimbs$date$Date$deadEndToString),
				$elm$core$Maybe$withDefault('')))));
var $author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		var _v0 = $justinmimbs$date$Date$fromIsoString(s);
		if (!_v0.$) {
			var date = _v0.a;
			return $elm$json$Json$Decode$succeed(date);
		} else {
			var e = _v0.a;
			return $elm$json$Json$Decode$fail(e);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Components$Produits$API$GetResult$ProduitMarcheCibleAgeLimiteClient = F2(
	function (max, min) {
		return {h0: max, h3: min};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $author$project$Components$Produits$API$GetResult$decodeProduitMarcheCibleAgeLimiteClient = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'min',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'max',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitMarcheCibleAgeLimiteClient)));
var $author$project$Components$Produits$API$GetResult$ProduitMarcheCibleCapacitePerte = F6(
	function (auDelaMontants, jusquaMontants, limitees, nulle, significatives, valeur) {
		return {k4: auDelaMontants, m6: jusquaMontants, hW: limitees, ir: nulle, jP: significatives, at: valeur};
	});
var $author$project$Components$Produits$API$Common$decodeCapaciteSubirPerte = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Nulle',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'Limitees',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'Significatives',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'JusquaMontants',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'AuDelaMontants',
			$elm$json$Json$Decode$succeed(4))
		]));
var $author$project$Components$Produits$API$GetResult$decodeProduitMarcheCibleCapacitePerte = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'valeurs',
	$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$Common$decodeCapaciteSubirPerte),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'significatives',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'nulle',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'limitées',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'jusqua_montants',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'au_dela_montants',
						$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
						$elm$core$Maybe$Nothing,
						$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitMarcheCibleCapacitePerte)))))));
var $author$project$Components$Produits$API$GetResult$ProduitMarcheCibleHorizon = F5(
	function (courtTerme, longTerme, moyenTerme, tresCourtTerme, valeur) {
		return {lD: courtTerme, ni: longTerme, nv: moyenTerme, oT: tresCourtTerme, at: valeur};
	});
var $author$project$Components$Produits$API$Common$decodeMarcheCibleHorizon = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'TresCourtTerme',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'CourtTerme',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'MoyenTerme',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'LongTerme',
			$elm$json$Json$Decode$succeed(3))
		]));
var $author$project$Components$Produits$API$GetResult$decodeProduitMarcheCibleHorizon = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'valeur',
	$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$Common$decodeMarcheCibleHorizon),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'tres_court_terme',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'moyen_terme',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'long_terme',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'court_terme',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
					$elm$core$Maybe$Nothing,
					$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitMarcheCibleHorizon))))));
var $author$project$Components$Produits$API$GetResult$ProduitMarcheCibleObjectifs = F8(
	function (autre, conservation, couverture, croissance, optionnelOuAEffetDeLevier, rendement, revenus, valeurs) {
		return {fM: autre, gh: conservation, gj: couverture, gp: croissance, nR: optionnelOuAEffetDeLevier, jp: rendement, jx: revenus, o4: valeurs};
	});
var $author$project$Components$Produits$API$Common$decodeMarcheCibleObjectifs = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Conservation',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'Croissance',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'Rendement',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'Revenus',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'Couverture',
			$elm$json$Json$Decode$succeed(4)),
			A2(
			$elm$json$Json$Decode$field,
			'OptionnelOuEffetLevier',
			$elm$json$Json$Decode$succeed(5)),
			A2(
			$elm$json$Json$Decode$field,
			'Autre',
			$elm$json$Json$Decode$succeed(6))
		]));
var $edkelly303$elm_any_type_collections$Any$Set$Set = $elm$core$Basics$identity;
var $elm$core$Dict$diff = F2(
	function (t1, t2) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, t) {
					return A2($elm$core$Dict$remove, k, t);
				}),
			t1,
			t2);
	});
var $elm$core$Set$diff = F2(
	function (_v0, _v1) {
		var dict1 = _v0;
		var dict2 = _v1;
		return A2($elm$core$Dict$diff, dict1, dict2);
	});
var $edkelly303$elm_any_type_collections$Any$Set$diff = F2(
	function (_v0, _v1) {
		var s1 = _v0;
		var s2 = _v1;
		return A2($elm$core$Set$diff, s1, s2);
	});
var $edkelly303$elm_any_type_collections$Any$Set$empty = $elm$core$Set$empty;
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $elm$core$Set$filter = F2(
	function (isGood, _v0) {
		var dict = _v0;
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (key, _v1) {
					return isGood(key);
				}),
			dict);
	});
var $edkelly303$elm_any_type_collections$Any$Set$filter = F3(
	function (fromComparable, f, _v0) {
		var s = _v0;
		return A2(
			$elm$core$Set$filter,
			A2($elm$core$Basics$composeR, fromComparable, f),
			s);
	});
var $elm$core$Set$foldl = F3(
	function (func, initialState, _v0) {
		var dict = _v0;
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (key, _v1, state) {
					return A2(func, key, state);
				}),
			initialState,
			dict);
	});
var $edkelly303$elm_any_type_collections$Any$Set$foldl = F4(
	function (fromComparable, f, acc, _v0) {
		var set = _v0;
		return A3(
			$elm$core$Set$foldl,
			F2(
				function (a, b) {
					return A2(
						f,
						fromComparable(a),
						b);
				}),
			acc,
			set);
	});
var $elm$core$Set$foldr = F3(
	function (func, initialState, _v0) {
		var dict = _v0;
		return A3(
			$elm$core$Dict$foldr,
			F3(
				function (key, _v1, state) {
					return A2(func, key, state);
				}),
			initialState,
			dict);
	});
var $edkelly303$elm_any_type_collections$Any$Set$foldr = F4(
	function (fromComparable, f, acc, _v0) {
		var set = _v0;
		return A3(
			$elm$core$Set$foldr,
			F2(
				function (a, b) {
					return A2(
						f,
						fromComparable(a),
						b);
				}),
			acc,
			set);
	});
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $edkelly303$elm_any_type_collections$Any$Set$fromList = F2(
	function (toComparable, list) {
		return $elm$core$Set$fromList(
			A2($elm$core$List$map, toComparable, list));
	});
var $edkelly303$elm_any_type_collections$Any$Set$insert = F3(
	function (toComparable, a, _v0) {
		var s = _v0;
		return A2(
			$elm$core$Set$insert,
			toComparable(a),
			s);
	});
var $elm$core$Dict$intersect = F2(
	function (t1, t2) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (k, _v0) {
					return A2($elm$core$Dict$member, k, t2);
				}),
			t1);
	});
var $elm$core$Set$intersect = F2(
	function (_v0, _v1) {
		var dict1 = _v0;
		var dict2 = _v1;
		return A2($elm$core$Dict$intersect, dict1, dict2);
	});
var $edkelly303$elm_any_type_collections$Any$Set$intersect = F2(
	function (_v0, _v1) {
		var s1 = _v0;
		var s2 = _v1;
		return A2($elm$core$Set$intersect, s1, s2);
	});
var $elm$core$Dict$isEmpty = function (dict) {
	if (dict.$ === -2) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Set$isEmpty = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$isEmpty(dict);
};
var $edkelly303$elm_any_type_collections$Any$Set$isEmpty = function (_v0) {
	var s = _v0;
	return $elm$core$Set$isEmpty(s);
};
var $elm$core$Set$map = F2(
	function (func, set) {
		return $elm$core$Set$fromList(
			A3(
				$elm$core$Set$foldl,
				F2(
					function (x, xs) {
						return A2(
							$elm$core$List$cons,
							func(x),
							xs);
					}),
				_List_Nil,
				set));
	});
var $edkelly303$elm_any_type_collections$Any$Set$map = F4(
	function (fromComparable, toComparable2, f, _v0) {
		var set = _v0;
		return A2(
			$elm$core$Set$map,
			A2(
				$elm$core$Basics$composeR,
				fromComparable,
				A2($elm$core$Basics$composeR, f, toComparable2)),
			set);
	});
var $edkelly303$elm_any_type_collections$Any$Set$member = F3(
	function (toComparable, a, _v0) {
		var s = _v0;
		return A2(
			$elm$core$Set$member,
			toComparable(a),
			s);
	});
var $elm$core$Dict$partition = F2(
	function (isGood, dict) {
		var add = F3(
			function (key, value, _v0) {
				var t1 = _v0.a;
				var t2 = _v0.b;
				return A2(isGood, key, value) ? _Utils_Tuple2(
					A3($elm$core$Dict$insert, key, value, t1),
					t2) : _Utils_Tuple2(
					t1,
					A3($elm$core$Dict$insert, key, value, t2));
			});
		return A3(
			$elm$core$Dict$foldl,
			add,
			_Utils_Tuple2($elm$core$Dict$empty, $elm$core$Dict$empty),
			dict);
	});
var $elm$core$Set$partition = F2(
	function (isGood, _v0) {
		var dict = _v0;
		var _v1 = A2(
			$elm$core$Dict$partition,
			F2(
				function (key, _v2) {
					return isGood(key);
				}),
			dict);
		var dict1 = _v1.a;
		var dict2 = _v1.b;
		return _Utils_Tuple2(dict1, dict2);
	});
var $edkelly303$elm_any_type_collections$Any$Set$partition = F3(
	function (fromComparable, f, _v0) {
		var s = _v0;
		var _v1 = A2(
			$elm$core$Set$partition,
			A2($elm$core$Basics$composeR, fromComparable, f),
			s);
		var s1 = _v1.a;
		var s2 = _v1.b;
		return _Utils_Tuple2(s1, s2);
	});
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $edkelly303$elm_any_type_collections$Any$Set$remove = F3(
	function (toComparable, a, _v0) {
		var s = _v0;
		return A2(
			$elm$core$Set$remove,
			toComparable(a),
			s);
	});
var $elm$core$Set$singleton = function (key) {
	return A2($elm$core$Dict$singleton, key, 0);
};
var $edkelly303$elm_any_type_collections$Any$Set$singleton = F2(
	function (toComparable, a) {
		return $elm$core$Set$singleton(
			toComparable(a));
	});
var $elm$core$Dict$sizeHelp = F2(
	function (n, dict) {
		sizeHelp:
		while (true) {
			if (dict.$ === -2) {
				return n;
			} else {
				var left = dict.d;
				var right = dict.e;
				var $temp$n = A2($elm$core$Dict$sizeHelp, n + 1, right),
					$temp$dict = left;
				n = $temp$n;
				dict = $temp$dict;
				continue sizeHelp;
			}
		}
	});
var $elm$core$Dict$size = function (dict) {
	return A2($elm$core$Dict$sizeHelp, 0, dict);
};
var $elm$core$Set$size = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$size(dict);
};
var $edkelly303$elm_any_type_collections$Any$Set$size = function (_v0) {
	var s = _v0;
	return $elm$core$Set$size(s);
};
var $edkelly303$elm_any_type_collections$Any$Set$toList = F2(
	function (fromComparable, _v0) {
		var s = _v0;
		return A2(
			$elm$core$List$map,
			fromComparable,
			$elm$core$Set$toList(s));
	});
var $edkelly303$elm_any_type_collections$Any$Set$toggle = F3(
	function (toComparable, a, set) {
		return A3($edkelly303$elm_any_type_collections$Any$Set$member, toComparable, a, set) ? A3($edkelly303$elm_any_type_collections$Any$Set$remove, toComparable, a, set) : A3($edkelly303$elm_any_type_collections$Any$Set$insert, toComparable, a, set);
	});
var $elm$core$Set$union = F2(
	function (_v0, _v1) {
		var dict1 = _v0;
		var dict2 = _v1;
		return A2($elm$core$Dict$union, dict1, dict2);
	});
var $edkelly303$elm_any_type_collections$Any$Set$union = F2(
	function (_v0, _v1) {
		var s1 = _v0;
		var s2 = _v1;
		return A2($elm$core$Set$union, s1, s2);
	});
var $edkelly303$elm_any_type_collections$Any$Set$makeInterface = function (_v0) {
	var toComparable = _v0.oQ;
	var fromComparable = _v0.mz;
	return {
		gL: $edkelly303$elm_any_type_collections$Any$Set$diff,
		gV: $edkelly303$elm_any_type_collections$Any$Set$empty,
		g4: $edkelly303$elm_any_type_collections$Any$Set$filter(fromComparable),
		g7: $edkelly303$elm_any_type_collections$Any$Set$foldl(fromComparable),
		g8: $edkelly303$elm_any_type_collections$Any$Set$foldr(fromComparable),
		hk: $edkelly303$elm_any_type_collections$Any$Set$fromList(toComparable),
		hI: $edkelly303$elm_any_type_collections$Any$Set$insert(toComparable),
		hK: $edkelly303$elm_any_type_collections$Any$Set$intersect,
		hM: $edkelly303$elm_any_type_collections$Any$Set$isEmpty,
		h$: $edkelly303$elm_any_type_collections$Any$Set$map(fromComparable),
		h1: $edkelly303$elm_any_type_collections$Any$Set$member(toComparable),
		iA: $edkelly303$elm_any_type_collections$Any$Set$partition(fromComparable),
		jn: $edkelly303$elm_any_type_collections$Any$Set$remove(toComparable),
		jQ: $edkelly303$elm_any_type_collections$Any$Set$singleton(toComparable),
		jW: $edkelly303$elm_any_type_collections$Any$Set$size,
		kk: $edkelly303$elm_any_type_collections$Any$Set$toList(fromComparable),
		kl: $edkelly303$elm_any_type_collections$Any$Set$toggle(toComparable),
		ky: $edkelly303$elm_any_type_collections$Any$Set$union
	};
};
var $author$project$Components$Produits$Common$MarcheCibleModel$parseObjectifs = function (input) {
	return A2(
		$elm$core$Dict$get,
		input,
		A2($author$project$Components$Produits$Common$MarcheCibleModel$mapping, $author$project$Components$Produits$Common$MarcheCibleModel$allObjectifs, $author$project$Components$Produits$Common$MarcheCibleModel$objectifsToString));
};
var $author$project$Components$Produits$Common$MarcheCibleModel$objectifsSetBuilder = $edkelly303$elm_any_type_collections$Any$Set$makeInterface(
	{
		mz: A2(
			$elm$core$Basics$composeR,
			$author$project$Components$Produits$Common$MarcheCibleModel$parseObjectifs,
			$elm$core$Maybe$withDefault(0)),
		oQ: $author$project$Components$Produits$Common$MarcheCibleModel$objectifsToString
	});
var $author$project$Components$Produits$API$GetResult$decodeProduitMarcheCibleObjectifs = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'valeurs',
	A2(
		$elm$json$Json$Decode$map,
		$author$project$Components$Produits$Common$MarcheCibleModel$objectifsSetBuilder.hk,
		$elm$json$Json$Decode$list($author$project$Components$Produits$API$Common$decodeMarcheCibleObjectifs)),
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'revenus',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'rendement',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'optionnel_ou_à_effet_de_levier',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'croissance',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'couverture',
						$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'conservation',
							$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'autre',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
								$elm$core$Maybe$Nothing,
								$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitMarcheCibleObjectifs)))))))));
var $author$project$Components$Produits$API$GetResult$ProduitMarcheCibleProfils = F6(
	function (defensif, dynamique, equilibre, offensif, securitaire, valeur) {
		return {gI: defensif, gT: dynamique, gY: equilibre, iv: offensif, jH: securitaire, at: valeur};
	});
var $author$project$Components$Produits$API$Common$decodeMarcheCibleProfils = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Securitaire',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'Defensif',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'Equilibre',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'Dynamique',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'Offensif',
			$elm$json$Json$Decode$succeed(4))
		]));
var $author$project$Components$Produits$API$GetResult$decodeProduitMarcheCibleProfils = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'valeurs',
	$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$Common$decodeMarcheCibleProfils),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'securitaire',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'offensif',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'equilibre',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'dynamique',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'defensif',
						$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
						$elm$core$Maybe$Nothing,
						$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitMarcheCibleProfils)))))));
var $author$project$Components$Produits$API$GetResult$ProduitMarcheCibleSynthese = F2(
	function (marcheCibleDuProduitParLeCabinet, marcheCibleNegatifDuProduitParLeCabinet) {
		return {nl: marcheCibleDuProduitParLeCabinet, nm: marcheCibleNegatifDuProduitParLeCabinet};
	});
var $author$project$Components$Produits$API$GetResult$decodeProduitMarcheCibleSynthese = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'marche_cible_négatif_du_produit_par_le_cabinet',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'marche_cible_du_produit_par_le_cabinet',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitMarcheCibleSynthese)));
var $author$project$Components$Produits$API$Common$decodeProduitMarcheCibleType = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Distributeur',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'Producteur',
			$elm$json$Json$Decode$succeed(0))
		]));
var $author$project$Components$Produits$API$GetResult$ProduitMarcheCibleTypeInvestisseur = F3(
	function (contrepartieEligible, nonProfessionnel, professionnel) {
		return {lA: contrepartieEligible, $9: nonProfessionnel, jf: professionnel};
	});
var $author$project$Components$Produits$API$GetResult$decodeProduitMarcheCibleTypeInvestisseur = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'professionnel',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'non_professionnel',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'contrepartie_eligible',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitMarcheCibleTypeInvestisseur))));
var $author$project$Components$Produits$API$GetResult$decodeProduitMarcheCible = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'version',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'type_marche_cible',
		$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$Common$decodeProduitMarcheCibleType),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'type_investisseur',
			$author$project$Components$Produits$API$GetResult$decodeProduitMarcheCibleTypeInvestisseur,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'synthese',
				$author$project$Components$Produits$API$GetResult$decodeProduitMarcheCibleSynthese,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'profils',
					$author$project$Components$Produits$API$GetResult$decodeProduitMarcheCibleProfils,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'profession_exclues',
						$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
						$elm$core$Maybe$Nothing,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'objectifs',
							$author$project$Components$Produits$API$GetResult$decodeProduitMarcheCibleObjectifs,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'horizon',
								$author$project$Components$Produits$API$GetResult$decodeProduitMarcheCibleHorizon,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'date_mise_a_jour',
									$elm$json$Json$Decode$nullable($author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder),
									$elm$core$Maybe$Nothing,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'capacite_perte',
										$author$project$Components$Produits$API$GetResult$decodeProduitMarcheCibleCapacitePerte,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'age_limite_client',
											$author$project$Components$Produits$API$GetResult$decodeProduitMarcheCibleAgeLimiteClient,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'accessible_personnes_vulnerables',
												$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
												$elm$core$Maybe$Nothing,
												$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitMarcheCible)))))))))))));
var $author$project$Components$Produits$API$GetResult$ProduitProfilExtraFinancier = F5(
	function (dateMiseAJour, incidenceNegativePai, investissementDurableSfdr, prisEnCompte, taxonomie) {
		return {ej: dateMiseAJour, de: incidenceNegativePai, Q: investissementDurableSfdr, i_: prisEnCompte, fi: taxonomie};
	});
var $author$project$Components$Produits$API$GetResult$ProduitProfilExtraFinancierIncidenceNegativePai = F3(
	function (listePaiPrisEnCompte, pctAlignement, prendEnComptePai) {
		return {hZ: listePaiPrisEnCompte, bK: pctAlignement, iU: prendEnComptePai};
	});
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $author$project$Components$Produits$API$Common$paiDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'GazEffetDeSerre',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'NiveauImpactBiodiversite',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'EmissionsPolluantesEau',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'GenerationDechetsDangereux',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'InefficaciteEnergetiqueImmobilier',
			$elm$json$Json$Decode$succeed(4)),
			A2(
			$elm$json$Json$Decode$field,
			'RespectDesNormesInternationales',
			$elm$json$Json$Decode$succeed(5)),
			A2(
			$elm$json$Json$Decode$field,
			'ProcessusDeControleDesNormesInternationales',
			$elm$json$Json$Decode$succeed(6)),
			A2(
			$elm$json$Json$Decode$field,
			'EgaliteRemuneration',
			$elm$json$Json$Decode$succeed(7)),
			A2(
			$elm$json$Json$Decode$field,
			'DiversiteDesGenresConseilsAdministration',
			$elm$json$Json$Decode$succeed(8)),
			A2(
			$elm$json$Json$Decode$field,
			'ExpositionAuxArmesControversees',
			$elm$json$Json$Decode$succeed(9))
		]));
var $author$project$Common$Referentiel$ProfilExtraFinancier$parsePai = function (input) {
	return A2(
		$elm$core$Maybe$map,
		$elm$core$Tuple$second,
		A2(
			$elm_community$list_extra$List$Extra$find,
			function (_v0) {
				var str = _v0.a;
				return _Utils_eq(str, input);
			},
			A2(
				$elm$core$List$map,
				function (pai) {
					return _Utils_Tuple2(
						$author$project$Common$Referentiel$ProfilExtraFinancier$paiToString(pai),
						pai);
				},
				$author$project$Common$Referentiel$ProfilExtraFinancier$allPAIs)));
};
var $author$project$Common$Referentiel$ProfilExtraFinancier$setBuilder = $edkelly303$elm_any_type_collections$Any$Set$makeInterface(
	{
		mz: A2(
			$elm$core$Basics$composeR,
			$author$project$Common$Referentiel$ProfilExtraFinancier$parsePai,
			$elm$core$Maybe$withDefault(0)),
		oQ: $author$project$Common$Referentiel$ProfilExtraFinancier$paiToString
	});
var $author$project$Components$Produits$API$GetResult$decodeProduitProfilExtraFinancierIncidenceNegativePai = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'prend_en_compte_pai',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'pct_alignement',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'liste_pai_pris_en_compte',
			A2(
				$elm$json$Json$Decode$map,
				$author$project$Common$Referentiel$ProfilExtraFinancier$setBuilder.hk,
				$elm$json$Json$Decode$list($author$project$Components$Produits$API$Common$paiDecoder)),
			$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitProfilExtraFinancierIncidenceNegativePai))));
var $author$project$Components$Produits$API$GetResult$ProduitProfilExtraFinancierInvestissementDurableSfdr = F2(
	function (categoriesProduits, estAligne) {
		return {lq: categoriesProduits, aX: estAligne};
	});
var $author$project$Components$Produits$API$GetResult$ProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduits = F3(
	function (article6, article8, article9) {
		return {d3: article6, b1: article8, b2: article9};
	});
var $author$project$Components$Produits$API$GetResult$ProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle6 = F2(
	function (est_aligne, pctAlignement) {
		return {et: est_aligne, bK: pctAlignement};
	});
var $author$project$Components$Produits$API$GetResult$decodeProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle6 = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'pct_alignement',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'est_aligne',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle6)));
var $author$project$Components$Produits$API$GetResult$ProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle8 = F4(
	function (est_aligne, pctAlignementEnvironnemental, pctAlignementGouvernance, pctAlignementSocial) {
		return {et: est_aligne, dr: pctAlignementEnvironnemental, ds: pctAlignementGouvernance, dt: pctAlignementSocial};
	});
var $author$project$Components$Produits$API$GetResult$decodeProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle8 = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'pct_alignement_social',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'pct_alignement_gouvernance',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'pct_alignement_environnemental',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'est_aligne',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
				$elm$core$Maybe$Nothing,
				$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle8)))));
var $author$project$Components$Produits$API$GetResult$ProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle9 = F4(
	function (est_aligne, pctAlignementEnvironnemental, pctAlignementGouvernance, pctAlignementSocial) {
		return {et: est_aligne, dr: pctAlignementEnvironnemental, ds: pctAlignementGouvernance, dt: pctAlignementSocial};
	});
var $author$project$Components$Produits$API$GetResult$decodeProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle9 = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'pct_alignement_social',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'pct_alignement_gouvernance',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'pct_alignement_environnemental',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'est_aligne',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
				$elm$core$Maybe$Nothing,
				$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle9)))));
var $author$project$Components$Produits$API$GetResult$decodeProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduits = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'article_9',
	$author$project$Components$Produits$API$GetResult$decodeProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle9,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'article_8',
		$author$project$Components$Produits$API$GetResult$decodeProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle8,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'article_6',
			$author$project$Components$Produits$API$GetResult$decodeProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle6,
			$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduits))));
var $author$project$Components$Produits$API$GetResult$decodeProduitProfilExtraFinancierInvestissementDurableSfdr = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'est_aligne',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'categories_produits',
		$author$project$Components$Produits$API$GetResult$decodeProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduits,
		$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitProfilExtraFinancierInvestissementDurableSfdr)));
var $author$project$Components$Produits$API$GetResult$ProduitProfilExtraFinancierTaxonomie = F2(
	function (estAligne, pctAlignement) {
		return {aX: estAligne, bK: pctAlignement};
	});
var $author$project$Components$Produits$API$GetResult$decodeProduitProfilExtraFinancierTaxonomie = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'pct_alignement',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'est_aligne',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitProfilExtraFinancierTaxonomie)));
var $author$project$Components$Produits$API$GetResult$decodeProduitProfilExtraFinancier = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'taxonomie',
	$author$project$Components$Produits$API$GetResult$decodeProduitProfilExtraFinancierTaxonomie,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'pris_en_compte',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'investissement_durable_sfdr',
			$author$project$Components$Produits$API$GetResult$decodeProduitProfilExtraFinancierInvestissementDurableSfdr,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'incidence_negative_pai',
				$author$project$Components$Produits$API$GetResult$decodeProduitProfilExtraFinancierIncidenceNegativePai,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'date_mise_a_jour',
					$elm$json$Json$Decode$nullable($author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder),
					$elm$core$Maybe$Nothing,
					$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitProfilExtraFinancier))))));
var $author$project$Components$Produits$Common$Model$Producteur = F2(
	function (uid, nom) {
		return {dl: nom, kx: uid};
	});
var $author$project$Components$Produits$API$Common$producteurDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'nom',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'uid',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Components$Produits$Common$Model$Producteur)));
var $author$project$Components$Produits$API$GetResult$ProduitSupportInfo = F4(
	function (supportId, name, modeDeGestions, importType) {
		return {mV: importType, nu: modeDeGestions, nz: name, oE: supportId};
	});
var $author$project$Components$Produits$API$GetResult$CgpConseil = 0;
var $author$project$Components$Produits$API$GetResult$MustCompliance = 1;
var $author$project$Components$Produits$API$GetResult$importTypeDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'CgpConseil',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'MustCompliance',
			$elm$json$Json$Decode$succeed(1))
		]));
var $author$project$Components$Produits$API$GetResult$ModesDeGestion = F4(
	function (gestionLibre, gestionProfilee, gestionSousMandat, gestionAHorizon) {
		return {mC: gestionAHorizon, mD: gestionLibre, mE: gestionProfilee, mF: gestionSousMandat};
	});
var $author$project$Components$Produits$API$GetResult$DonneesImportees = F2(
	function (importDataGeneral, importDataSpecific) {
		return {mR: importDataGeneral, mS: importDataSpecific};
	});
var $author$project$Components$Produits$API$GetResult$DonneeImportee = F2(
	function (remunerationFraisDeGestion, tauxRetrocession) {
		return {oi: remunerationFraisDeGestion, oL: tauxRetrocession};
	});
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $torreyatcitty$the_best_decimal$Decimal$Decimal = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $cmditch$elm_bigint$BigInt$Negative = 1;
var $cmditch$elm_bigint$BigInt$Positive = 0;
var $cmditch$elm_bigint$BigInt$Magnitude = $elm$core$Basics$identity;
var $elm_community$maybe_extra$Maybe$Extra$combineHelp = F2(
	function (list, acc) {
		combineHelp:
		while (true) {
			if (list.b) {
				var head = list.a;
				var tail = list.b;
				if (!head.$) {
					var a = head.a;
					var $temp$list = tail,
						$temp$acc = A2($elm$core$List$cons, a, acc);
					list = $temp$list;
					acc = $temp$acc;
					continue combineHelp;
				} else {
					return $elm$core$Maybe$Nothing;
				}
			} else {
				return $elm$core$Maybe$Just(
					$elm$core$List$reverse(acc));
			}
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$combine = function (list) {
	return A2($elm_community$maybe_extra$Maybe$Extra$combineHelp, list, _List_Nil);
};
var $elm_community$list_extra$List$Extra$dropWhile = F2(
	function (predicate, list) {
		dropWhile:
		while (true) {
			if (!list.b) {
				return _List_Nil;
			} else {
				var x = list.a;
				var xs = list.b;
				if (predicate(x)) {
					var $temp$predicate = predicate,
						$temp$list = xs;
					predicate = $temp$predicate;
					list = $temp$list;
					continue dropWhile;
				} else {
					return list;
				}
			}
		}
	});
var $cmditch$elm_bigint$BigInt$emptyZero = function (_v0) {
	var xs = _v0;
	var _v1 = A2(
		$elm_community$list_extra$List$Extra$dropWhile,
		$elm$core$Basics$eq(0),
		xs);
	if (!_v1.b) {
		return _List_Nil;
	} else {
		return xs;
	}
};
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOfWithStep = F3(
	function (size, step, xs) {
		var xs_ = A2($elm$core$List$drop, step, xs);
		var okayXs = $elm$core$List$length(xs) > 0;
		var okayArgs = (size > 0) && (step > 0);
		return (okayArgs && okayXs) ? A2(
			$elm$core$List$cons,
			A2($elm$core$List$take, size, xs),
			A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, step, xs_)) : _List_Nil;
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, size, xs);
	});
var $cmditch$elm_bigint$Constants$maxDigitMagnitude = 7;
var $cmditch$elm_bigint$BigInt$fromString_ = function (x) {
	return A2(
		$elm$core$Maybe$map,
		A2($elm$core$Basics$composeL, $cmditch$elm_bigint$BigInt$emptyZero, $elm$core$Basics$identity),
		$elm_community$maybe_extra$Maybe$Extra$combine(
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$reverse,
					A2($elm$core$Basics$composeR, $elm$core$String$fromList, $elm$core$String$toInt)),
				A2(
					$elm_community$list_extra$List$Extra$greedyGroupsOf,
					$cmditch$elm_bigint$Constants$maxDigitMagnitude,
					$elm$core$List$reverse(x)))));
};
var $cmditch$elm_bigint$BigInt$Neg = function (a) {
	return {$: 1, a: a};
};
var $cmditch$elm_bigint$BigInt$Pos = function (a) {
	return {$: 0, a: a};
};
var $cmditch$elm_bigint$BigInt$Zer = {$: 2};
var $cmditch$elm_bigint$BigInt$mkBigInt = F2(
	function (s, mag) {
		var digits = mag;
		if ($elm$core$List$isEmpty(digits)) {
			return $cmditch$elm_bigint$BigInt$Zer;
		} else {
			switch (s) {
				case 2:
					return $cmditch$elm_bigint$BigInt$Zer;
				case 0:
					return $cmditch$elm_bigint$BigInt$Pos(mag);
				default:
					return $cmditch$elm_bigint$BigInt$Neg(mag);
			}
		}
	});
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $cmditch$elm_bigint$BigInt$fromString = function (x) {
	var _v0 = $elm$core$String$toList(
		$elm$core$String$toLower(x));
	if (!_v0.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		switch (_v0.a) {
			case '-':
				if (!_v0.b.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var xs = _v0.b;
					return A2(
						$elm$core$Maybe$map,
						$cmditch$elm_bigint$BigInt$mkBigInt(1),
						$cmditch$elm_bigint$BigInt$fromString_(xs));
				}
			case '+':
				if (!_v0.b.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var xs = _v0.b;
					return A2(
						$elm$core$Maybe$map,
						$cmditch$elm_bigint$BigInt$mkBigInt(0),
						$cmditch$elm_bigint$BigInt$fromString_(xs));
				}
			default:
				var xs = _v0;
				return A2(
					$elm$core$Maybe$map,
					$cmditch$elm_bigint$BigInt$mkBigInt(0),
					$cmditch$elm_bigint$BigInt$fromString_(xs));
		}
	}
};
var $torreyatcitty$the_best_decimal$Decimal$fromString = function (s) {
	var stringToDecimal = function (targetString) {
		var stringIntToDecimal = F2(
			function (s_, e) {
				var _v8 = $cmditch$elm_bigint$BigInt$fromString(s_);
				if (_v8.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var a = _v8.a;
					return $elm$core$Maybe$Just(
						A2($torreyatcitty$the_best_decimal$Decimal$Decimal, a, e));
				}
			});
		var _v6 = A2($elm$core$String$split, '.', targetString);
		_v6$2:
		while (true) {
			if (_v6.b) {
				if (_v6.b.b) {
					if (!_v6.b.b.b) {
						var a = _v6.a;
						var _v7 = _v6.b;
						var b = _v7.a;
						return A2(
							stringIntToDecimal,
							_Utils_ap(a, b),
							-$elm$core$String$length(b));
					} else {
						break _v6$2;
					}
				} else {
					var a = _v6.a;
					return A2(stringIntToDecimal, a, 0);
				}
			} else {
				break _v6$2;
			}
		}
		return $elm$core$Maybe$Nothing;
	};
	var makeMantissa = function (targetString) {
		var _v4 = A2($elm$core$String$split, '.', targetString);
		_v4$2:
		while (true) {
			if (_v4.b) {
				if (!_v4.b.b) {
					var s1 = _v4.a;
					return stringToDecimal(s1);
				} else {
					if (!_v4.b.b.b) {
						var s1 = _v4.a;
						var _v5 = _v4.b;
						var s2 = _v5.a;
						return stringToDecimal(
							A2(
								$elm$core$String$join,
								'',
								_List_fromArray(
									[s1, s2])));
					} else {
						break _v4$2;
					}
				}
			} else {
				break _v4$2;
			}
		}
		return $elm$core$Maybe$Nothing;
	};
	var splitMantissaExponent = function (targetString) {
		var _v2 = A2(
			$elm$core$String$split,
			'e',
			$elm$core$String$toLower(targetString));
		_v2$2:
		while (true) {
			if (_v2.b) {
				if (!_v2.b.b) {
					var s1 = _v2.a;
					return _Utils_Tuple2(
						stringToDecimal(s1),
						$elm$core$Maybe$Just(0));
				} else {
					if (!_v2.b.b.b) {
						var s1 = _v2.a;
						var _v3 = _v2.b;
						var s2 = _v3.a;
						return _Utils_Tuple2(
							stringToDecimal(s1),
							$elm$core$String$toInt(s2));
					} else {
						break _v2$2;
					}
				}
			} else {
				break _v2$2;
			}
		}
		return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
	};
	var _v0 = splitMantissaExponent(s);
	if ((!_v0.a.$) && (!_v0.b.$)) {
		var _v1 = _v0.a.a;
		var m = _v1.a;
		var a = _v1.b;
		var e = _v0.b.a;
		return $elm$core$Maybe$Just(
			A2($torreyatcitty$the_best_decimal$Decimal$Decimal, m, e + a));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Produits$API$GetResult$decodeDonneeImportee = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'tauxRetrocession',
	A2(
		$elm$json$Json$Decode$map,
		function (v) {
			return A2($elm$core$Maybe$andThen, $torreyatcitty$the_best_decimal$Decimal$fromString, v);
		},
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string)),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'remunerationFraisDeGestion',
		A2(
			$elm$json$Json$Decode$map,
			function (v) {
				return A2($elm$core$Maybe$andThen, $torreyatcitty$the_best_decimal$Decimal$fromString, v);
			},
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string)),
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$DonneeImportee)));
var $author$project$Components$Produits$API$GetResult$DonneeDimportCabinet = F3(
	function (cabinetId, cabinetNom, donnees) {
		return {lj: cabinetId, lk: cabinetNom, l2: donnees};
	});
var $author$project$Components$Produits$API$GetResult$importDonneeDimportCabinet = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'donnees',
	$author$project$Components$Produits$API$GetResult$decodeDonneeImportee,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'cabinetNom',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'cabinetId',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$DonneeDimportCabinet))));
var $author$project$Components$Produits$API$GetResult$donneesImporteesDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'importDataSpecific',
	$elm$json$Json$Decode$list($author$project$Components$Produits$API$GetResult$importDonneeDimportCabinet),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'importDataGeneral',
		$author$project$Components$Produits$API$GetResult$decodeDonneeImportee,
		$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$DonneesImportees)));
var $author$project$Components$Produits$API$GetResult$modeDeGestionsDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'gestionAHorizon',
	$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$GetResult$donneesImporteesDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'gestionSousMandat',
		$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$GetResult$donneesImporteesDecoder),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'gestionProfilee',
			$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$GetResult$donneesImporteesDecoder),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'gestionLibre',
				$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$GetResult$donneesImporteesDecoder),
				$elm$core$Maybe$Nothing,
				$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ModesDeGestion)))));
var $author$project$Components$Produits$API$GetResult$produitSupportInfoDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'importType',
	$author$project$Components$Produits$API$GetResult$importTypeDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'modeDeGestions',
		$author$project$Components$Produits$API$GetResult$modeDeGestionsDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'name',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'supportId',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$ProduitSupportInfo)))));
var $author$project$Components$Produits$API$GetResult$decodeProduitDetail = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'supportsInvestissement',
		$elm$json$Json$Decode$list($author$project$Components$Produits$API$GetResult$produitSupportInfoDecoder),
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'attribution',
			$elm$json$Json$Decode$nullable($author$project$Components$Produits$Common$DuplicationInformation$attributionInformationDecoder),
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'documentsPresentation',
				$author$project$Components$Produits$API$GetResult$decodeDocumentPresentation,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'profilExtraFinancier',
					$author$project$Components$Produits$API$GetResult$decodeProduitProfilExtraFinancier,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'commercialisation',
						$author$project$Components$Produits$API$GetResult$decodeProduitCommercialisation,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'experience',
							$author$project$Components$Produits$API$GetResult$decodeProduitExperience,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'marcheCible',
								$author$project$Components$Produits$API$GetResult$decodeProduitMarcheCible,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'noteRisqueProduit',
									$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
									$elm$core$Maybe$Nothing,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'classification',
										$author$project$Components$Produits$API$GetResult$decodeProduitClassification,
										A3(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
											'producteur',
											$author$project$Components$Produits$API$Common$producteurDecoder,
											A3(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
												'valideParProducteur',
												$elm$json$Json$Decode$bool,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'actif',
													$elm$json$Json$Decode$bool,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'label',
														$elm$json$Json$Decode$string,
														A3(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
															'nom',
															$elm$json$Json$Decode$string,
															A3(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																'uid',
																$elm$json$Json$Decode$string,
																$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$GetResult$DetailedProduit)))))))))))))))));
var $author$project$Components$Produits$API$Requests$fetch = function (link) {
	return A3(
		$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
		link,
		$elm$http$Http$emptyBody,
		$elm$http$Http$expectJson($elm$core$Basics$identity)($author$project$Components$Produits$API$GetResult$decodeProduitDetail));
};
var $author$project$Components$Produits$Details$Update$init = function (model) {
	var fetchProduit = function (fetchLink) {
		return A2(
			$elm$core$Platform$Cmd$map,
			$author$project$Components$Produits$Details$Update$ProduitFetched,
			$author$project$Components$Produits$API$Requests$fetch(fetchLink));
	};
	var _v0 = model.dj;
	if (_v0.$ === 1) {
		var fetchLink = _v0.a;
		return _Utils_Tuple2(
			model,
			fetchProduit(fetchLink));
	} else {
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	}
};
var $author$project$Components$Produits$List$Update$ProduitsFetched = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$List$Model$getListLink = function (model) {
	if (!model.$) {
		var listLinks = model.a;
		return listLinks;
	} else {
		var listLinks = model.b;
		return listLinks;
	}
};
var $author$project$Components$Produits$API$ListResult$AllProduitsResult = F3(
	function (result, producteurs, links) {
		return {nf: links, n5: producteurs, ok: result};
	});
var $author$project$Components$Produits$API$ListResult$ProduitAbstract = function (id) {
	return function (nom) {
		return function (label) {
			return function (classification) {
				return function (famille) {
					return function (sousClassification) {
						return function (producteur) {
							return function (disponibleALaGeneration) {
								return function (attribution) {
									return function (links) {
										return {k2: attribution, f9: classification, lX: disponibleALaGeneration, g0: famille, hB: id, m9: label, nf: links, dl: nom, i7: producteur, jZ: sousClassification};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Produits$API$ListResult$productDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'attribution',
		$elm$json$Json$Decode$nullable($author$project$Components$Produits$Common$DuplicationInformation$attributionInformationDecoder),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'actif',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'producteur',
				$author$project$Components$Produits$API$Common$producteurDecoder,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'sousClassification',
					$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$Common$sousClassificationDecoder),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'famille',
						$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$Common$familleDecoder),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'classification',
							$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$Common$classificationDecoder),
							$elm$core$Maybe$Nothing,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'label',
								$elm$json$Json$Decode$string,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'nom',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'id',
										$elm$json$Json$Decode$string,
										$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$ListResult$ProduitAbstract)))))))))));
var $author$project$Components$Produits$API$ListResult$productsDecoder = $elm$json$Json$Decode$list($author$project$Components$Produits$API$ListResult$productDecoder);
var $author$project$Components$Produits$API$ListResult$allProduitsResultDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'producteurs',
		$elm$json$Json$Decode$list($author$project$Components$Produits$API$Common$producteurDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'result',
			$author$project$Components$Produits$API$ListResult$productsDecoder,
			$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$ListResult$AllProduitsResult))));
var $author$project$Components$Produits$API$Requests$list = function (link) {
	return A3(
		$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
		link,
		$elm$http$Http$emptyBody,
		$elm$http$Http$expectJson($elm$core$Basics$identity)($author$project$Components$Produits$API$ListResult$allProduitsResultDecoder));
};
var $author$project$Components$Produits$List$Update$init = function (model) {
	return _Utils_Tuple2(
		model,
		A2(
			$elm$core$Platform$Cmd$map,
			$author$project$Components$Produits$List$Update$ProduitsFetched,
			$author$project$Components$Produits$API$Requests$list(
				$author$project$Components$Produits$List$Model$getListLink(model))));
};
var $author$project$Components$Produits$List$Model$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$List$Model$loading = function (listLink) {
	return $author$project$Components$Produits$List$Model$Loading(listLink);
};
var $author$project$Routes$ProduitsComponentBuilder$produits = F2(
	function (step, rootApiLinks) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(
				$author$project$Routes$Pages$Error(0),
				$elm$core$Platform$Cmd$none),
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Tuple$mapBoth,
					$author$project$Routes$Pages$Produits(step),
					$elm$core$Platform$Cmd$map($author$project$Main$Msg$ProduitsAction)),
				A2(
					$elm$core$Maybe$map,
					$author$project$Components$Produits$List$Update$init,
					A2(
						$elm$core$Maybe$map,
						function (listLink) {
							return $author$project$Components$Produits$List$Model$loading(listLink);
						},
						A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'produits', rootApiLinks.kW)))));
	});
var $author$project$Routes$ProduitsComponentBuilder$produitDetails = F4(
	function (timezone, createOrUpdate, rootApiLinks, previousPage) {
		var detailModel = F3(
			function (loadedProduits, producteurs, params) {
				if (!createOrUpdate.$) {
					return $elm$core$Maybe$Just(
						A4($author$project$Components$Produits$Details$Model$init, timezone, producteurs, params, $author$project$Components$Produits$Details$Model$Create));
				} else {
					var produitId = createOrUpdate.a;
					return A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						function (produit) {
							var initProduitDetailsModel = A3(
								$author$project$Components$Produits$Details$Model$init,
								timezone,
								producteurs,
								{gn: $elm$core$Maybe$Nothing});
							return A2(
								$elm$core$Maybe$map,
								A2($elm$core$Basics$composeR, $author$project$Components$Produits$Details$Model$Update, initProduitDetailsModel),
								A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'self', produit.nf));
						},
						A2(
							$elm_community$list_extra$List$Extra$find,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.hB;
								},
								$elm$core$Basics$eq(produitId)),
							loadedProduits));
				}
			});
		var goToDetail = F3(
			function (loadedProduits, producteurs, params) {
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(
						$author$project$Routes$Pages$Error(0),
						$elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Tuple$mapBoth,
							$author$project$Routes$Pages$ProduitDetails,
							$elm$core$Platform$Cmd$map($author$project$Main$Msg$ProduitDetailsAction)),
						A2(
							$elm$core$Maybe$map,
							$author$project$Components$Produits$Details$Update$init,
							A3(detailModel, loadedProduits, producteurs, params))));
			});
		var goTo = function (page) {
			if ((page.$ === 10) && (page.b.$ === 1)) {
				var _v5 = page.b;
				var model = _v5.a;
				return $elm$core$Maybe$Just(
					$elm$core$Result$Ok(
						A3(
							goToDetail,
							model.n7,
							model.n5,
							{gn: model.go})));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		};
		var goThroughProduits = A2(
			$author$project$Routes$ProduitsComponentBuilder$produits,
			$elm$core$Maybe$Just(goTo),
			rootApiLinks);
		switch (previousPage.$) {
			case 10:
				var subModel = previousPage.b;
				var _v1 = function () {
					if (!subModel.$) {
						return _Utils_Tuple3(
							_List_Nil,
							$elm$core$Dict$empty,
							{gn: $elm$core$Maybe$Nothing});
					} else {
						var model = subModel.a;
						return _Utils_Tuple3(
							model.n7,
							model.n5,
							{gn: model.go});
					}
				}();
				var loadedProduits = _v1.a;
				var producteurs = _v1.b;
				var params = _v1.c;
				return A3(goToDetail, loadedProduits, producteurs, params);
			case 11:
				var subModel = previousPage.a;
				if (!createOrUpdate.$) {
					return goThroughProduits;
				} else {
					var id = createOrUpdate.a;
					return A2(
						$elm$core$Maybe$withDefault,
						goThroughProduits,
						A2(
							$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
							function (previsouId) {
								return _Utils_eq(previsouId, id) ? $elm$core$Maybe$Just(
									A3(
										$elm$core$Tuple$mapBoth,
										$author$project$Routes$Pages$ProduitDetails,
										$elm$core$Platform$Cmd$map($author$project$Main$Msg$ProduitDetailsAction),
										$author$project$Components$Produits$Details$Update$init(subModel))) : $elm$core$Maybe$Nothing;
							},
							subModel.e0));
				}
			default:
				return goThroughProduits;
		}
	});
var $author$project$Components$ActiveUser$Model$ResetPassword = 1;
var $author$project$Routes$ComponentBuilder$resetPassword = F2(
	function (_v0, rootApiLinks) {
		var demandKey = _v0.lR;
		var userId = _v0.kB;
		var pibaseUri = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'self', rootApiLinks.n0);
		var init = function (url) {
			return A3(
				$elm$core$Tuple$mapBoth,
				$author$project$Routes$Pages$ActiveUserAccount,
				$elm$core$Platform$Cmd$map($author$project$Main$Msg$ActiveUserComponentAction),
				$author$project$Components$ActiveUser$Update$init(
					A4($author$project$Components$ActiveUser$Model$init, 1, url, demandKey, userId)));
		};
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(
				$author$project$Routes$Pages$Error(0),
				$elm$core$Platform$Cmd$none),
			A2($elm$core$Maybe$map, init, pibaseUri));
	});
var $author$project$Components$Supports$Details$Model$CgpConseil = 0;
var $author$project$Components$Supports$Details$Model$Informations = 0;
var $author$project$Components$Supports$Details$Documents$Model$ListMode = {$: 0};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$init = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init;
var $author$project$Components$Supports$Details$Documents$Model$initFiltres = function (tags) {
	return {
		bo: false,
		cg: false,
		bG: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$init, $elm$core$Maybe$Nothing, _List_Nil),
		bH: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$init, $elm$core$Maybe$Nothing, _List_Nil),
		bI: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$init, $elm$core$Maybe$Nothing, _List_Nil),
		bJ: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$init, $elm$core$Maybe$Nothing, _List_Nil),
		dp: '',
		cu: A3($inkuzmin$elm_multiselect$Multiselect$initModel, tags, 'filtreParTags', 0)
	};
};
var $author$project$Components$Supports$Details$Documents$Model$init = F2(
	function (timezone, fetchDocumentLink) {
		return {
			lQ: $elm$core$Maybe$Nothing,
			ce: _List_Nil,
			bn: _List_Nil,
			ep: $author$project$Components$Drawer$Model$init,
			_: $author$project$Components$Supports$Details$Documents$Model$ListMode,
			g2: fetchDocumentLink,
			eB: _List_Nil,
			aY: $author$project$Components$Supports$Details$Documents$Model$initFiltres(_List_Nil),
			aO: timezone,
			fq: $elm$core$Maybe$Nothing
		};
	});
var $author$project$Components$Supports$Details$Model$initForm = F4(
	function (support, produits, timezone, documentsLinks) {
		var info = {
			lp: A2(
				$elm$core$Maybe$withDefault,
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				A2(
					$elm$core$Maybe$map,
					function (s) {
						return A2(
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
							s,
							A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$addInfo, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty));
					},
					A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						function (s) {
							return s.lp;
						},
						support))),
			mx: A2(
				$elm$core$Maybe$withDefault,
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				A2(
					$elm$core$Maybe$map,
					function (s) {
						return A2(
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
							s,
							A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$addInfo, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty));
					},
					A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						function (s) {
							return s.mx;
						},
						support))),
			nd: A2(
				$elm$core$Maybe$withDefault,
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				A2(
					$elm$core$Maybe$map,
					function (s) {
						return A2(
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
							s.nd,
							A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$addInfo, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty));
					},
					support)),
			nZ: A2(
				$elm$core$Maybe$withDefault,
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				A2(
					$elm$core$Maybe$map,
					function (s) {
						return A2(
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
							s,
							A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$addInfo, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty));
					},
					A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						function (s) {
							return s.nZ;
						},
						support))),
			n$: A2(
				$elm$core$Maybe$withDefault,
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				A2(
					$elm$core$Maybe$map,
					function (s) {
						return A2(
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
							s,
							A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$addInfo, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty));
					},
					A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						function (s) {
							return s.n$;
						},
						support))),
			ov: A2(
				$elm$core$Maybe$withDefault,
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				A2(
					$elm$core$Maybe$map,
					function (s) {
						return A2(
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
							s,
							A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$addInfo, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty));
					},
					A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						function (s) {
							return s.ov;
						},
						support))),
			oE: A2(
				$elm$core$Maybe$withDefault,
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
				A2(
					$elm$core$Maybe$map,
					function (s) {
						return A2(
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
							s.oE,
							A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$addInfo, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty));
					},
					support))
		};
		return {
			bn: A2($author$project$Components$Supports$Details$Documents$Model$init, timezone, documentsLinks),
			mZ: info,
			n7: {n7: produits}
		};
	});
var $author$project$Components$Supports$Details$Model$init = F2(
	function (supportId, timezone) {
		return {
			eo: $elm$core$Maybe$Nothing,
			mV: 0,
			m9: '',
			aG: true,
			e6: 0,
			fd: A4($author$project$Components$Supports$Details$Model$initForm, $elm$core$Maybe$Nothing, _List_Nil, timezone, $elm$core$Maybe$Nothing),
			oE: supportId,
			aO: timezone
		};
	});
var $author$project$Components$Supports$Details$Update$SupportFetched = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$API$GetResult$DetailedSupport = function (supportId) {
	return function (name) {
		return function (societeDeGestion) {
			return function (fraisGestion) {
				return function (performanceBrute) {
					return function (performanceNette) {
						return function (categorieActif) {
							return function (includedInProduits) {
								return function (importType) {
									return function (links) {
										return {lp: categorieActif, mx: fraisGestion, mV: importType, mX: includedInProduits, nf: links, nz: name, nY: performanceBrute, n_: performanceNette, ov: societeDeGestion, oE: supportId};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Supports$API$GetResult$Autres = 9;
var $author$project$Components$Supports$API$GetResult$Fonds_actions = 0;
var $author$project$Components$Supports$API$GetResult$Fonds_de_capital_investissement = 5;
var $author$project$Components$Supports$API$GetResult$Fonds_euros = 7;
var $author$project$Components$Supports$API$GetResult$Fonds_immobiliers = 3;
var $author$project$Components$Supports$API$GetResult$Fonds_mixtes = 2;
var $author$project$Components$Supports$API$GetResult$Fonds_monetaires = 6;
var $author$project$Components$Supports$API$GetResult$Fonds_obligations = 1;
var $author$project$Components$Supports$API$GetResult$Fonds_speculatifs = 4;
var $author$project$Components$Supports$API$GetResult$Fonds_structures = 8;
var $author$project$Components$Supports$API$GetResult$Inconnu = 10;
var $author$project$Components$Supports$API$GetResult$categorieActifDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Fonds_actions',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'Fonds_obligations',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'Fonds_mixtes',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'Fonds_immobiliers',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'Fonds_speculatifs',
			$elm$json$Json$Decode$succeed(4)),
			A2(
			$elm$json$Json$Decode$field,
			'Fonds_de_capital_investissement',
			$elm$json$Json$Decode$succeed(5)),
			A2(
			$elm$json$Json$Decode$field,
			'Fonds_monetaires',
			$elm$json$Json$Decode$succeed(6)),
			A2(
			$elm$json$Json$Decode$field,
			'Fonds_euros',
			$elm$json$Json$Decode$succeed(7)),
			A2(
			$elm$json$Json$Decode$field,
			'Fonds_structures',
			$elm$json$Json$Decode$succeed(8)),
			A2(
			$elm$json$Json$Decode$field,
			'Fonds_autres',
			$elm$json$Json$Decode$succeed(9)),
			A2(
			$elm$json$Json$Decode$field,
			'Inconnu',
			$elm$json$Json$Decode$succeed(10))
		]));
var $author$project$Components$Supports$API$GetResult$ProduitLinkedToSupport = F4(
	function (id, nom, producteur, sousClassification) {
		return {hB: id, dl: nom, i7: producteur, jZ: sousClassification};
	});
var $author$project$Components$Supports$API$GetResult$decodeProduitClassification = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'famille',
	$elm$json$Json$Decode$nullable($author$project$Components$Produits$API$Common$familleDecoder),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'producteur',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'nom',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'id',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$GetResult$ProduitLinkedToSupport)))));
var $author$project$Components$Supports$API$GetResult$CgpConseil = 0;
var $author$project$Components$Supports$API$GetResult$MustCompliance = 1;
var $author$project$Components$Supports$API$GetResult$importTypeDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'CgpConseil',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'MustCompliance',
			$elm$json$Json$Decode$succeed(1))
		]));
var $author$project$Components$Supports$API$GetResult$supportDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'importType',
		$author$project$Components$Supports$API$GetResult$importTypeDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'includedInProduits',
			$elm$json$Json$Decode$list($author$project$Components$Supports$API$GetResult$decodeProduitClassification),
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'categorieActif',
				$elm$json$Json$Decode$nullable($author$project$Components$Supports$API$GetResult$categorieActifDecoder),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'performanceNette',
					A2(
						$elm$json$Json$Decode$map,
						function (v) {
							return A2($elm$core$Maybe$andThen, $torreyatcitty$the_best_decimal$Decimal$fromString, v);
						},
						$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string)),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'performanceBrute',
						A2(
							$elm$json$Json$Decode$map,
							function (v) {
								return A2($elm$core$Maybe$andThen, $torreyatcitty$the_best_decimal$Decimal$fromString, v);
							},
							$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string)),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'fraisGestion',
							A2(
								$elm$json$Json$Decode$map,
								function (v) {
									return A2($elm$core$Maybe$andThen, $torreyatcitty$the_best_decimal$Decimal$fromString, v);
								},
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string)),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'societeDeGestion',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
								$elm$core$Maybe$Nothing,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'name',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'supportId',
										$elm$json$Json$Decode$string,
										$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$GetResult$DetailedSupport)))))))))));
var $author$project$Components$Supports$API$Requests$fetch = function (link) {
	return A3(
		$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
		link,
		$elm$http$Http$emptyBody,
		A2($elm$http$Http$expectJson, $elm$core$Basics$identity, $author$project$Components$Supports$API$GetResult$supportDecoder));
};
var $author$project$Components$Supports$Details$Update$init = F2(
	function (model, supportGetNavLink) {
		var fetchSupport = function (fetchLink) {
			return A2(
				$elm$core$Platform$Cmd$map,
				$author$project$Components$Supports$Details$Update$SupportFetched,
				$author$project$Components$Supports$API$Requests$fetch(fetchLink));
		};
		return _Utils_Tuple2(
			model,
			fetchSupport(supportGetNavLink));
	});
var $author$project$Routes$ComponentsBuilders$SupportsComponentBuilder$supportDetails = F4(
	function (supportId, timezone, rootApiLinks, previousPage) {
		var goToDetail = function (supports) {
			return A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2(
					$author$project$Routes$Pages$Error(0),
					$elm$core$Platform$Cmd$none),
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Tuple$mapBoth,
						$author$project$Routes$Pages$SupportDetail,
						$elm$core$Platform$Cmd$map($author$project$Main$Msg$SupportDetailAction)),
					A2(
						$elm$core$Maybe$map,
						function (link) {
							return A2(
								$author$project$Components$Supports$Details$Update$init,
								A2($author$project$Components$Supports$Details$Model$init, supportId, timezone),
								link);
						},
						A2(
							$elm$core$Maybe$andThen,
							function (support) {
								return A2(
									$elm_community$list_extra$List$Extra$find,
									function (link) {
										return link.jm === 'self';
									},
									support.nf);
							},
							A2(
								$elm_community$list_extra$List$Extra$find,
								function (support) {
									return _Utils_eq(support.oE, supportId);
								},
								supports)))));
		};
		if (previousPage.$ === 14) {
			var model = previousPage.b;
			if (!model.$) {
				return _Utils_Tuple2(
					$author$project$Routes$Pages$Error(0),
					$elm$core$Platform$Cmd$none);
			} else {
				var loadedModel = model.a;
				return goToDetail(loadedModel.fe);
			}
		} else {
			var step = function (page) {
				if ((page.$ === 14) && (page.b.$ === 1)) {
					var _v3 = page.b;
					var loadedModel = _v3.a;
					return $elm$core$Maybe$Just(
						$elm$core$Result$Ok(
							goToDetail(loadedModel.fe)));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			};
			return A3(
				$author$project$Routes$ComponentsBuilders$SupportsComponentBuilder$listSupports,
				$elm$core$Maybe$Just(step),
				timezone,
				rootApiLinks);
		}
	});
var $author$project$Components$Cabinets$APIModel$Conseiller = 1;
var $author$project$Components$Cabinets$Model$UserAbstract = F2(
	function (uid, label) {
		return {m9: label, kx: uid};
	});
var $author$project$Components$Cabinets$Model$conseillersAbstract = function (users) {
	return A2(
		$elm$core$List$map,
		function (u) {
			return A2($author$project$Components$Cabinets$Model$UserAbstract, u.kx, u.nh);
		},
		A2(
			$elm$core$List$filter,
			function (u) {
				return u.oo === 1;
			},
			users));
};
var $author$project$Components$Cabinets$Users$New$Update$FetchAvailableSyncUsers = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$Components$Cabinets$Users$New$Update$init = function (model) {
	return _Utils_Tuple2(
		model,
		A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A3(
				$elm$core$Maybe$map2,
				F2(
					function (l, _v0) {
						return $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
							A2($author$project$Components$Cabinets$Users$New$Update$FetchAvailableSyncUsers, l, model.fZ.kx));
					}),
				model.fN,
				model.fZ.mh)));
};
var $author$project$Components$Cabinets$Model$LinkedToDirigeant = {$: 1};
var $author$project$Components$Cabinets$Model$LinkedToUser = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Cabinets$Users$New$Model$NotModified = 0;
var $author$project$Components$Cabinets$Users$New$Model$activitesRights2Key = function (r) {
	switch (r) {
		case 0:
			return 'SeeClients';
		case 1:
			return 'HandleProduction';
		case 2:
			return 'SeeChiffreAffaire';
		default:
			return 'SeeFraCif';
	}
};
var $author$project$Components$Cabinets$Users$New$Model$clientsRights2Key = function (r) {
	switch (r) {
		case 0:
			return 'SeeClients';
		case 1:
			return 'UpdateClients';
		default:
			return 'DeleteClients';
	}
};
var $author$project$Components$Cabinets$Users$New$Model$initAssistantUserLinkMultiS = function (users) {
	return A2(
		$elm$core$List$map,
		function (u) {
			return _Utils_Tuple2(u.kx, u.m9);
		},
		users);
};
var $author$project$Components$Cabinets$Model$HandleProduction = 1;
var $author$project$Components$Cabinets$Model$SeeChiffreAffaire = 2;
var $author$project$Components$Cabinets$Model$SeeClientsPage = 0;
var $author$project$Components$Cabinets$Model$SeeFraCif = 3;
var $author$project$Components$Cabinets$Users$New$Model$availableActivitesRights = _List_fromArray(
	[0, 1, 2, 3]);
var $author$project$Components$Cabinets$Users$New$Model$showActivitesRights = function (r) {
	switch (r) {
		case 0:
			return 'Voir mon activité Clients';
		case 1:
			return 'Gérer ma production';
		case 2:
			return 'Voir mon chiffre d\'affaires';
		default:
			return 'Voir l\'aide FRACIF';
	}
};
var $author$project$Components$Cabinets$Users$New$Model$initMultiMActivitesRights = A2(
	$elm$core$List$map,
	function (r) {
		return _Utils_Tuple2(
			$author$project$Components$Cabinets$Users$New$Model$activitesRights2Key(r),
			$author$project$Components$Cabinets$Users$New$Model$showActivitesRights(r));
	},
	$author$project$Components$Cabinets$Users$New$Model$availableActivitesRights);
var $author$project$Components$Cabinets$Model$DeleteClients = 2;
var $author$project$Components$Cabinets$Model$SeeClients = 0;
var $author$project$Components$Cabinets$Model$UpdateClients = 1;
var $author$project$Components$Cabinets$Users$New$Model$availableClientsRights = _List_fromArray(
	[0, 1, 2]);
var $author$project$Components$Cabinets$Users$New$Model$showClientsRights = function (r) {
	switch (r) {
		case 0:
			return 'Voir mes Clients';
		case 1:
			return 'Modifier mes Clients';
		default:
			return 'Supprimer mes Clients';
	}
};
var $author$project$Components$Cabinets$Users$New$Model$initMultiMClientsRights = A2(
	$elm$core$List$map,
	function (r) {
		return _Utils_Tuple2(
			$author$project$Components$Cabinets$Users$New$Model$clientsRights2Key(r),
			$author$project$Components$Cabinets$Users$New$Model$showClientsRights(r));
	},
	$author$project$Components$Cabinets$Users$New$Model$availableClientsRights);
var $author$project$Components$Cabinets$Model$HandleCabinet = 0;
var $author$project$Components$Cabinets$Model$HandleUser = 1;
var $author$project$Components$Cabinets$Users$New$Model$availableUserRights = _List_fromArray(
	[0, 1]);
var $author$project$Components$Cabinets$Users$New$Model$showUserRights = function (r) {
	if (!r) {
		return 'Gérer mon cabinet';
	} else {
		return 'Gérer les utilisateurs';
	}
};
var $author$project$Components$Cabinets$Users$New$Model$userRights2Key = function (r) {
	if (!r) {
		return 'HandleCabinet';
	} else {
		return 'HandleUser';
	}
};
var $author$project$Components$Cabinets$Users$New$Model$initMultiMUserRights = A2(
	$elm$core$List$map,
	function (r) {
		return _Utils_Tuple2(
			$author$project$Components$Cabinets$Users$New$Model$userRights2Key(r),
			$author$project$Components$Cabinets$Users$New$Model$showUserRights(r));
	},
	$author$project$Components$Cabinets$Users$New$Model$availableUserRights);
var $author$project$Components$Cabinets$Users$New$Model$ongletId_ = 'id';
var $author$project$Components$Cabinets$Users$New$Model$populateMultiSelect = F2(
	function (selected, m) {
		return A3(
			$inkuzmin$elm_multiselect$Multiselect$populateValues,
			m,
			$inkuzmin$elm_multiselect$Multiselect$getValues(m),
			selected);
	});
var $author$project$Components$Cabinets$Users$New$Model$initModel = F5(
	function (editedUser, cabinet, conseillers, availableSyncUsersLink, createUserLink) {
		var z = A3(
			$inkuzmin$elm_multiselect$Multiselect$initModel,
			$author$project$Components$Cabinets$Users$New$Model$initAssistantUserLinkMultiS(conseillers),
			'assistantUserLink',
			0);
		var y = A3($inkuzmin$elm_multiselect$Multiselect$initModel, $author$project$Components$Cabinets$Users$New$Model$initMultiMActivitesRights, 'activites', 0);
		var x = A3($inkuzmin$elm_multiselect$Multiselect$initModel, $author$project$Components$Cabinets$Users$New$Model$initMultiMUserRights, 'users', 0);
		var status = A2(
			$elm$core$Maybe$withDefault,
			0,
			A2(
				$elm$core$Maybe$map,
				function (u) {
					return u.ox;
				},
				editedUser));
		var linkedToUsers = function (u) {
			var _v2 = u.mq;
			if (_v2.$ === 1) {
				var users = _v2.a;
				return users;
			} else {
				return _List_Nil;
			}
		};
		var selectedConseillers = function (u) {
			return A2(
				$elm$core$List$filter,
				function (_v1) {
					var k = _v1.a;
					return A2(
						$elm$core$List$any,
						$elm$core$Basics$eq(k),
						linkedToUsers(u));
				},
				$author$project$Components$Cabinets$Users$New$Model$initAssistantUserLinkMultiS(conseillers));
		};
		var filterRight = function (u) {
			var _v0 = u.mq;
			switch (_v0.$) {
				case 1:
					var users = _v0.a;
					return $elm$core$Maybe$Just(
						$author$project$Components$Cabinets$Model$LinkedToUser(users));
				case 2:
					return $elm$core$Maybe$Just($author$project$Components$Cabinets$Model$LinkedToDirigeant);
				default:
					return $elm$core$Maybe$Nothing;
			}
		};
		var droitUtilisateursMultiS = A2(
			$elm$core$Maybe$withDefault,
			x,
			A2(
				$elm$core$Maybe$map,
				function (u) {
					return A2(
						$author$project$Components$Cabinets$Users$New$Model$populateMultiSelect,
						A2(
							$elm$core$List$map,
							function (r) {
								return _Utils_Tuple2(
									$author$project$Components$Cabinets$Users$New$Model$userRights2Key(r),
									$author$project$Components$Cabinets$Users$New$Model$showUserRights(r));
							},
							u.k7.b8),
						x);
				},
				editedUser));
		var droitActivitesMultiS = A2(
			$elm$core$Maybe$withDefault,
			y,
			A2(
				$elm$core$Maybe$map,
				function (u) {
					return A2(
						$author$project$Components$Cabinets$Users$New$Model$populateMultiSelect,
						A2(
							$elm$core$List$map,
							function (r) {
								return _Utils_Tuple2(
									$author$project$Components$Cabinets$Users$New$Model$activitesRights2Key(r),
									$author$project$Components$Cabinets$Users$New$Model$showActivitesRights(r));
							},
							u.k7.aj),
						y);
				},
				editedUser));
		var deactivateLink = A2(
			$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
			function (u) {
				return u.lL;
			},
			editedUser);
		var c = A3($inkuzmin$elm_multiselect$Multiselect$initModel, $author$project$Components$Cabinets$Users$New$Model$initMultiMClientsRights, 'clients', 0);
		var droitClientsMultiS = A2(
			$elm$core$Maybe$withDefault,
			c,
			A2(
				$elm$core$Maybe$map,
				function (u) {
					return A2(
						$author$project$Components$Cabinets$Users$New$Model$populateMultiSelect,
						A2(
							$elm$core$List$map,
							function (r) {
								return _Utils_Tuple2(
									$author$project$Components$Cabinets$Users$New$Model$clientsRights2Key(r),
									$author$project$Components$Cabinets$Users$New$Model$showClientsRights(r));
							},
							u.k7.aU),
						c);
				},
				editedUser));
		var assistantUserLinkMultiS = A2(
			$elm$core$Maybe$withDefault,
			z,
			A2(
				$elm$core$Maybe$map,
				function (u) {
					return A2(
						$author$project$Components$Cabinets$Users$New$Model$populateMultiSelect,
						selectedConseillers(u),
						z);
				},
				editedUser));
		return {
			d4: assistantUserLinkMultiS,
			fN: availableSyncUsersLink,
			fO: A3($inkuzmin$elm_multiselect$Multiselect$initModel, _List_Nil, 'availableSyncUsersMultiS', 0),
			fZ: cabinet,
			gq: $author$project$Components$Cabinets$Users$New$Model$ongletId_,
			gD: $elm$core$Maybe$Nothing,
			gE: deactivateLink,
			c2: droitActivitesMultiS,
			c3: droitUtilisateursMultiS,
			c4: droitClientsMultiS,
			er: editedUser,
			eA: A2($ccapndave$elm_flat_map$Maybe$FlatMap$flatMap, filterRight, editedUser),
			ms: A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.ms;
					},
					editedUser)),
			na: A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.na;
					},
					editedUser)),
			nh: A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.nh;
					},
					editedUser)),
			oo: A2(
				$elm$core$Maybe$map,
				function ($) {
					return $.oo;
				},
				editedUser),
			oq: 0,
			jC: 0,
			jD: A2(
				$elm$core$Maybe$withDefault,
				createUserLink,
				A2(
					$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
					function ($) {
						return $.o_;
					},
					editedUser)),
			ox: status
		};
	});
var $author$project$Routes$ComponentBuilder$userUpsert = F3(
	function (_v0, rootApiLinks, previousPage) {
		var cabinetId = _v0.lj;
		var maybeUserId = _v0.eR;
		var editedUser = function (model) {
			return A2(
				$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
				function (userUid) {
					return A2(
						$elm_community$list_extra$List$Extra$find,
						function (u) {
							return _Utils_eq(u.kx, userUid);
						},
						model.o3);
				},
				maybeUserId);
		};
		var goToNew = function (model) {
			return A2(
				$elm$core$Maybe$withDefault,
				_Utils_Tuple2(
					$author$project$Routes$Pages$Error(0),
					$elm$core$Platform$Cmd$none),
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Tuple$mapBoth,
						$author$project$Routes$Pages$UserNew,
						$elm$core$Platform$Cmd$map($author$project$Main$Msg$UserNewComponentAction)),
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$Cabinets$Users$New$Update$init,
						A2(
							$elm$core$Maybe$map,
							A4(
								$author$project$Components$Cabinets$Users$New$Model$initModel,
								editedUser(model),
								model.fZ,
								$author$project$Components$Cabinets$Model$conseillersAbstract(model.o3),
								model.d5),
							model.eh))));
		};
		var goToTo = function (page) {
			if (page.$ === 5) {
				var subModel = page.b;
				return A2(
					$elm$core$Maybe$map,
					function (_v5) {
						return $elm$core$Result$Ok(
							goToNew(subModel));
					},
					editedUser(subModel));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		};
		var goTo = function (cabId) {
			return function (page) {
				if (page.$ === 3) {
					var subModel = page.b;
					return A2(
						$elm$core$Maybe$map,
						function (_v3) {
							return $elm$core$Result$Ok(
								A4(
									$author$project$Routes$ComponentBuilder$cabinetUsers,
									$elm$core$Maybe$Just(goToTo),
									cabId,
									rootApiLinks,
									page));
						},
						A2(
							$elm_community$list_extra$List$Extra$find,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.kx;
								},
								$elm$core$Basics$eq(cabId)),
							subModel.ll));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			};
		};
		if (previousPage.$ === 5) {
			var model = previousPage.b;
			return goToNew(model);
		} else {
			return A2(
				$author$project$Routes$ComponentBuilder$cabinets,
				$elm$core$Maybe$Just(
					goTo(cabinetId)),
				rootApiLinks);
		}
	});
var $author$project$Routes$ComponentBuilder$routeToPage = F2(
	function (timezone, _v0) {
		var route = _v0.jz;
		var rootApiLinks = _v0.aM;
		var previousPage = _v0.iZ;
		var appNavKey = _v0.fI;
		switch (route.$) {
			case 0:
				return $author$project$Routes$ComponentBuilder$landing(rootApiLinks);
			case 1:
				var record = route.a;
				return A2($author$project$Routes$ComponentBuilder$activeUserAccount, record, rootApiLinks);
			case 2:
				return $author$project$Routes$ComponentBuilder$forgotPassword(rootApiLinks);
			case 3:
				var record = route.a;
				return A2($author$project$Routes$ComponentBuilder$resetPassword, record, rootApiLinks);
			case 4:
				switch (route.a.$) {
					case 0:
						return A2($author$project$Routes$ComponentBuilder$cabinets, $elm$core$Maybe$Nothing, rootApiLinks);
					case 1:
						return A3($author$project$Routes$ComponentBuilder$cabinetUpsert, $elm$core$Maybe$Nothing, rootApiLinks, previousPage);
					case 2:
						var rawSubEntry = route.a.a;
						return A3($author$project$Routes$ComponentBuilder$cabinetSubEntry, appNavKey, rawSubEntry, rootApiLinks);
					case 3:
						var id = route.a.a;
						return A3(
							$author$project$Routes$ComponentBuilder$cabinetUpsert,
							$elm$core$Maybe$Just(id),
							rootApiLinks,
							previousPage);
					case 4:
						switch (route.a.a.$) {
							case 0:
								var id = route.a.a.a;
								return A4($author$project$Routes$ComponentBuilder$cabinetUsers, $elm$core$Maybe$Nothing, id, rootApiLinks, previousPage);
							case 1:
								var id = route.a.a.a;
								return A3(
									$author$project$Routes$ComponentBuilder$userUpsert,
									{lj: id, eR: $elm$core$Maybe$Nothing},
									rootApiLinks,
									previousPage);
							default:
								var record = route.a.a.a;
								return A3(
									$author$project$Routes$ComponentBuilder$userUpsert,
									{
										lj: record.lj,
										eR: $elm$core$Maybe$Just(record.kB)
									},
									rootApiLinks,
									previousPage);
						}
					default:
						if (!route.a.a.$) {
							var id = route.a.a.a;
							return A3(
								$author$project$Routes$ComponentBuilder$cabinetOffres,
								{lj: id, eW: $elm$core$Maybe$Nothing},
								rootApiLinks,
								previousPage);
						} else {
							var record = route.a.a.a;
							return A3(
								$author$project$Routes$ComponentBuilder$cabinetOffres,
								{
									lj: record.lj,
									eW: $elm$core$Maybe$Just(record.eW)
								},
								rootApiLinks,
								previousPage);
						}
				}
			case 5:
				switch (route.a.$) {
					case 0:
						return A2($author$project$Routes$ClientsComponentBuilder$clients, $elm$core$Maybe$Nothing, rootApiLinks);
					case 1:
						return A2($author$project$Routes$ClientsComponentBuilder$newClient, rootApiLinks, previousPage);
					default:
						var id = route.a.a;
						return A3($author$project$Routes$ClientsComponentBuilder$clientDetail, id, rootApiLinks, previousPage);
				}
			case 6:
				switch (route.a.$) {
					case 0:
						return A2($author$project$Routes$ProduitsComponentBuilder$produits, $elm$core$Maybe$Nothing, rootApiLinks);
					case 2:
						return A4($author$project$Routes$ProduitsComponentBuilder$produitDetails, timezone, $author$project$Routes$Model$CreateItem, rootApiLinks, previousPage);
					default:
						var id = route.a.a;
						return A4(
							$author$project$Routes$ProduitsComponentBuilder$produitDetails,
							timezone,
							$author$project$Routes$Model$UpdateItem(id),
							rootApiLinks,
							previousPage);
				}
			case 7:
				switch (route.a.$) {
					case 0:
						return A3($author$project$Routes$ComponentsBuilders$SupportsComponentBuilder$importSupports, $elm$core$Maybe$Nothing, timezone, rootApiLinks);
					case 1:
						var importId = route.a.a;
						return A4($author$project$Routes$ComponentsBuilders$SupportsComponentBuilder$importSupportDetail, importId, timezone, rootApiLinks, previousPage);
					case 2:
						return A3($author$project$Routes$ComponentsBuilders$SupportsComponentBuilder$listSupports, $elm$core$Maybe$Nothing, timezone, rootApiLinks);
					default:
						var supportId = route.a.a;
						return A4($author$project$Routes$ComponentsBuilders$SupportsComponentBuilder$supportDetails, supportId, timezone, rootApiLinks, previousPage);
				}
			default:
				var path = route.a.nX;
				return _Utils_Tuple2(
					$author$project$Routes$Pages$SubModule(path),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Routes$Routes$SubModule = function (a) {
	return {$: 8, a: a};
};
var $author$project$Routes$Routes$ActiveUserAccount = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routes$Routes$Cabinet = function (a) {
	return {$: 4, a: a};
};
var $author$project$Routes$Routes$CabinetCreate = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routes$Routes$CabinetInformations = function (a) {
	return {$: 3, a: a};
};
var $author$project$Routes$Routes$CabinetList = function (a) {
	return {$: 0, a: a};
};
var $author$project$Routes$Routes$CabinetOffre = function (a) {
	return {$: 5, a: a};
};
var $author$project$Routes$Routes$CabinetOffreGet = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routes$Routes$CabinetOffreList = function (a) {
	return {$: 0, a: a};
};
var $author$project$Routes$Routes$CabinetSubEntry = function (a) {
	return {$: 2, a: a};
};
var $author$project$Routes$Routes$CabinetUser = function (a) {
	return {$: 4, a: a};
};
var $author$project$Routes$Routes$CabinetUserCreate = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routes$Routes$CabinetUserGet = function (a) {
	return {$: 2, a: a};
};
var $author$project$Routes$Routes$CabinetUserList = function (a) {
	return {$: 0, a: a};
};
var $author$project$Routes$Routes$Client = function (a) {
	return {$: 5, a: a};
};
var $author$project$Routes$Routes$ClientCreate = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routes$Routes$ClientGet = function (a) {
	return {$: 2, a: a};
};
var $author$project$Routes$Routes$ClientList = function (a) {
	return {$: 0, a: a};
};
var $author$project$Routes$Routes$ForgotPassword = function (a) {
	return {$: 2, a: a};
};
var $author$project$Routes$Routes$ImportSupportDetail = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routes$Routes$ImportSupports = function (a) {
	return {$: 0, a: a};
};
var $author$project$Routes$Routes$LandingR = function (a) {
	return {$: 0, a: a};
};
var $author$project$Routes$Routes$ListSupports = function (a) {
	return {$: 2, a: a};
};
var $author$project$Routes$Routes$Produit = function (a) {
	return {$: 6, a: a};
};
var $author$project$Routes$Routes$ProduitCreate = function (a) {
	return {$: 2, a: a};
};
var $author$project$Routes$Routes$ProduitGet = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routes$Routes$ProduitList = function (a) {
	return {$: 0, a: a};
};
var $author$project$Routes$Routes$ResetPassword = function (a) {
	return {$: 3, a: a};
};
var $author$project$Routes$Routes$SupportGet = function (a) {
	return {$: 3, a: a};
};
var $author$project$Routes$Routes$Supports = function (a) {
	return {$: 7, a: a};
};
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $author$project$Routes$RouteBuilder$builtinRoutesParser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			$author$project$Routes$Model$routes.op.q($author$project$Routes$Routes$LandingR),
			$author$project$Routes$Model$routes.fy.q($author$project$Routes$Routes$ActiveUserAccount),
			$author$project$Routes$Model$routes.hb.q($author$project$Routes$Routes$ForgotPassword),
			$author$project$Routes$Model$routes.jt.q($author$project$Routes$Routes$ResetPassword),
			$author$project$Routes$Model$routes.ll.hY.q(
			A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Cabinet, $author$project$Routes$Routes$CabinetList)),
			$author$project$Routes$Model$routes.ll.lF.q(
			A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Cabinet, $author$project$Routes$Routes$CabinetCreate)),
			$author$project$Routes$Model$routes.ll.oB.q(
			A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Cabinet, $author$project$Routes$Routes$CabinetSubEntry)),
			$author$project$Routes$Model$routes.ll.mZ.q(
			A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Cabinet, $author$project$Routes$Routes$CabinetInformations)),
			$author$project$Routes$Model$routes.ll.o3.hY.q(
			A2(
				$elm$core$Basics$composeL,
				A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Cabinet, $author$project$Routes$Routes$CabinetUser),
				$author$project$Routes$Routes$CabinetUserList)),
			$author$project$Routes$Model$routes.ll.o3.lF.q(
			A2(
				$elm$core$Basics$composeL,
				A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Cabinet, $author$project$Routes$Routes$CabinetUser),
				$author$project$Routes$Routes$CabinetUserCreate)),
			$author$project$Routes$Model$routes.ll.o3.mG.q(
			A2(
				$elm$core$Basics$composeL,
				A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Cabinet, $author$project$Routes$Routes$CabinetUser),
				$author$project$Routes$Routes$CabinetUserGet)),
			$author$project$Routes$Model$routes.ll.nM.hY.q(
			A2(
				$elm$core$Basics$composeL,
				A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Cabinet, $author$project$Routes$Routes$CabinetOffre),
				$author$project$Routes$Routes$CabinetOffreList)),
			$author$project$Routes$Model$routes.ll.nM.mG.q(
			A2(
				$elm$core$Basics$composeL,
				A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Cabinet, $author$project$Routes$Routes$CabinetOffre),
				$author$project$Routes$Routes$CabinetOffreGet)),
			$author$project$Routes$Model$routes.ca.hY.q(
			A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Client, $author$project$Routes$Routes$ClientList)),
			$author$project$Routes$Model$routes.ca.lF.q(
			A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Client, $author$project$Routes$Routes$ClientCreate)),
			$author$project$Routes$Model$routes.ca.mG.q(
			A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Client, $author$project$Routes$Routes$ClientGet)),
			$author$project$Routes$Model$routes.n7.hY.q(
			A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Produit, $author$project$Routes$Routes$ProduitList)),
			$author$project$Routes$Model$routes.n7.lF.q(
			A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Produit, $author$project$Routes$Routes$ProduitCreate)),
			$author$project$Routes$Model$routes.n7.mG.q(
			A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Produit, $author$project$Routes$Routes$ProduitGet)),
			$author$project$Routes$Model$routes.fe.mQ.q(
			A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Supports, $author$project$Routes$Routes$ImportSupports)),
			$author$project$Routes$Model$routes.fe.lT.q(
			A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Supports, $author$project$Routes$Routes$ImportSupportDetail)),
			$author$project$Routes$Model$routes.fe.hY.q(
			A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Supports, $author$project$Routes$Routes$ListSupports)),
			$author$project$Routes$Model$routes.fe.mG.q(
			A2($elm$core$Basics$composeL, $author$project$Routes$Routes$Supports, $author$project$Routes$Routes$SupportGet))
		]));
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.aP;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.o6);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.o6);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.nX),
					$elm$url$Url$Parser$prepareQuery(url.of),
					url.mw,
					$elm$core$Basics$identity)));
	});
var $author$project$Routes$RouteBuilder$urlToRoute = F2(
	function (modules, url) {
		var subModule = function (path) {
			return A2(
				$elm$core$Maybe$map,
				function (moduleInfo) {
					return $author$project$Routes$Routes$SubModule(
						{h5: moduleInfo, nX: url.nX});
				},
				A2(
					$elm_community$list_extra$List$Extra$find,
					function (m) {
						return A2($elm$core$String$startsWith, m.jz, path);
					},
					modules));
		};
		var builtinRoutes = A2($elm$url$Url$Parser$parse, $author$project$Routes$RouteBuilder$builtinRoutesParser, url);
		return A2(
			$elm_community$maybe_extra$Maybe$Extra$orElse,
			subModule(url.nX),
			builtinRoutes);
	});
var $author$project$Main$MainUpdate$calculInitialRoute = function (model) {
	var route = A2($author$project$Routes$RouteBuilder$urlToRoute, _List_Nil, model.o$);
	var baseRoute = $author$project$Routes$Pages$Landing(
		$author$project$Components$Landing$Model$init(model.aM));
	var args = function (r) {
		return {fI: model.fI, iZ: baseRoute, aM: model.aM, jz: r};
	};
	var page = function () {
		_v0$3:
		while (true) {
			if (!route.$) {
				switch (route.a.$) {
					case 1:
						var r = route.a;
						return A2(
							$author$project$Routes$ComponentBuilder$routeToPage,
							model.aO,
							args(r));
					case 2:
						var r = route.a;
						return A2(
							$author$project$Routes$ComponentBuilder$routeToPage,
							model.aO,
							args(r));
					case 3:
						var r = route.a;
						return A2(
							$author$project$Routes$ComponentBuilder$routeToPage,
							model.aO,
							args(r));
					default:
						break _v0$3;
				}
			} else {
				break _v0$3;
			}
		}
		return _Utils_Tuple2(baseRoute, $elm$core$Platform$Cmd$none);
	}();
	return page;
};
var $author$project$Main$MainModel$getStartUrl = function (model) {
	var url = model.o$;
	return _Utils_update(
		url,
		{mw: $elm$core$Maybe$Nothing, nX: '/', of: $elm$core$Maybe$Nothing});
};
var $author$project$Main$MainUpdate$handleStep = F2(
	function (step, _v0) {
		var route = _v0.a;
		var cmd = _v0.b;
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(route, cmd),
			A2(
				$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
				function (f) {
					return A2(
						$elm$core$Maybe$map,
						function (r) {
							if (!r.$) {
								var result = r.a;
								return result;
							} else {
								var err = r.a;
								return _Utils_Tuple2(err, $elm$core$Platform$Cmd$none);
							}
						},
						f(route));
				},
				step));
	});
var $author$project$Components$Auth$Update$Logged = {$: 0};
var $author$project$Components$Auth$Update$init = function (piLinks) {
	var model = $author$project$Components$Auth$Model$init;
	var alreadyConnected = A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Platform$Cmd$none,
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$always(
				$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd($author$project$Components$Auth$Update$Logged)),
			A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'logout', piLinks)));
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{nf: piLinks}),
		alreadyConnected);
};
var $elm$core$Maybe$destruct = F3(
	function (_default, func, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return func(a);
		} else {
			return _default;
		}
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Main$MainUpdate$intercom_loggedin = _Platform_outgoingPort(
	'intercom_loggedin',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'company',
					function ($) {
						return A3(
							$elm$core$Maybe$destruct,
							$elm$json$Json$Encode$null,
							function ($) {
								return $elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'label',
											$elm$json$Json$Encode$string($.m9)),
											_Utils_Tuple2(
											'uid',
											$elm$json$Json$Encode$string($.kx))
										]));
							},
							$);
					}($.lx)),
					_Utils_Tuple2(
					'firstName',
					$elm$json$Json$Encode$string($.ms)),
					_Utils_Tuple2(
					'lastName',
					$elm$json$Json$Encode$string($.na)),
					_Utils_Tuple2(
					'login',
					$elm$json$Json$Encode$string($.nh)),
					_Utils_Tuple2(
					'uid',
					$elm$json$Json$Encode$string($.kx))
				]));
	});
var $author$project$Main$MainUpdate$intercom_logout = _Platform_outgoingPort(
	'intercom_logout',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Components$Auth$Update$isConnected = function (msg) {
	if (!msg.$) {
		return $elm$core$Maybe$Just(0);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Auth$Update$isLoginAnswered = function (msg) {
	_v0$2:
	while (true) {
		switch (msg.$) {
			case 4:
				if (!msg.a.$) {
					return $elm$core$Maybe$Just(0);
				} else {
					break _v0$2;
				}
			case 6:
				if (!msg.a.$) {
					return $elm$core$Maybe$Just(0);
				} else {
					break _v0$2;
				}
			default:
				break _v0$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$Main$MainUpdate$loadSubModule = _Platform_outgoingPort(
	'loadSubModule',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'directLinks',
					$elm$json$Json$Encode$list(
						function ($) {
							return $elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'label',
										$elm$json$Json$Encode$string($.m9)),
										_Utils_Tuple2(
										'route',
										$elm$json$Json$Encode$string($.jz))
									]));
						})($.lU)),
					_Utils_Tuple2(
					'flags',
					function ($) {
						return $elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'backHost',
									$elm$json$Json$Encode$string($.k9)),
									_Utils_Tuple2(
									'docGeneratorHost',
									$elm$json$Json$Encode$string($.l0)),
									_Utils_Tuple2(
									'parentAppPathPrefix',
									$elm$json$Json$Encode$string($.nV)),
									_Utils_Tuple2(
									'rootApiLinks',
									function ($) {
										return $elm$json$Json$Encode$object(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'activites',
													$elm$json$Json$Encode$list(
														function ($) {
															return $elm$json$Json$Encode$object(
																_List_fromArray(
																	[
																		_Utils_Tuple2(
																		'href',
																		$elm$json$Json$Encode$string($.hA)),
																		_Utils_Tuple2(
																		'method',
																		$elm$json$Json$Encode$string($.nq)),
																		_Utils_Tuple2(
																		'rel',
																		$elm$json$Json$Encode$string($.jm))
																	]));
														})($.d0)),
													_Utils_Tuple2(
													'others',
													$elm$json$Json$Encode$list(
														function ($) {
															return $elm$json$Json$Encode$object(
																_List_fromArray(
																	[
																		_Utils_Tuple2(
																		'href',
																		$elm$json$Json$Encode$string($.hA)),
																		_Utils_Tuple2(
																		'method',
																		$elm$json$Json$Encode$string($.nq)),
																		_Utils_Tuple2(
																		'rel',
																		$elm$json$Json$Encode$string($.jm))
																	]));
														})($.nU))
												]));
									}($.aM)),
									_Utils_Tuple2(
									'subAppContainerClass',
									$elm$json$Json$Encode$string($.oA)),
									_Utils_Tuple2(
									'url',
									function ($) {
										return $elm$json$Json$Encode$object(
											_List_fromArray(
												[
													_Utils_Tuple2(
													'fragment',
													function ($) {
														return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
													}($.mw)),
													_Utils_Tuple2(
													'host',
													$elm$json$Json$Encode$string($.mL)),
													_Utils_Tuple2(
													'https',
													$elm$json$Json$Encode$bool($.mN)),
													_Utils_Tuple2(
													'path',
													$elm$json$Json$Encode$string($.nX)),
													_Utils_Tuple2(
													'port_',
													function ($) {
														return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$int, $);
													}($.n2)),
													_Utils_Tuple2(
													'query',
													function ($) {
														return A3($elm$core$Maybe$destruct, $elm$json$Json$Encode$null, $elm$json$Json$Encode$string, $);
													}($.of))
												]));
									}($.o$))
								]));
					}($.aa)),
					_Utils_Tuple2(
					'label',
					$elm$json$Json$Encode$string($.m9)),
					_Utils_Tuple2(
					'moduleName',
					$elm$json$Json$Encode$string($.h6)),
					_Utils_Tuple2(
					'route',
					$elm$json$Json$Encode$string($.jz)),
					_Utils_Tuple2(
					'urlToJsFile',
					$elm$json$Json$Encode$string($.o0))
				]));
	});
var $author$project$Components$Cabinets$OffresCommerciales$Update$loading = function (msg) {
	switch (msg.$) {
		case 0:
			return $elm$core$Maybe$Just(0);
		case 1:
			return $elm$core$Maybe$Just(1);
		case 11:
			return $elm$core$Maybe$Just(0);
		case 12:
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Cabinets$Users$New$Update$loading = function (msg) {
	switch (msg.$) {
		case 17:
			return $elm$core$Maybe$Just(0);
		case 18:
			return $elm$core$Maybe$Just(1);
		case 20:
			return $elm$core$Maybe$Just(0);
		case 21:
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Clients$Detail$Update$loading = function (msg) {
	switch (msg.$) {
		case 0:
			return $elm$core$Maybe$Just(0);
		case 1:
			return $elm$core$Maybe$Just(1);
		case 6:
			return $elm$core$Maybe$Just(0);
		case 7:
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Clients$List$Update$loading = function (msg) {
	return $elm$core$Maybe$Nothing;
};
var $author$project$Components$Landing$Update$loading = function (msg) {
	switch (msg.$) {
		case 0:
			return $elm$core$Maybe$Just(0);
		case 1:
			return $elm$core$Maybe$Just(1);
		case 2:
			return $elm$core$Maybe$Just(0);
		case 3:
			return $elm$core$Maybe$Just(1);
		case 4:
			return $elm$core$Maybe$Just(0);
		case 5:
			return $elm$core$Maybe$Just(1);
		case 6:
			return $elm$core$Maybe$Just(0);
		default:
			return $elm$core$Maybe$Just(1);
	}
};
var $author$project$Main$MainUpdate$loading = function (msg0) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Platform$Cmd$none,
		A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeR, $author$project$Main$Msg$LoadingAction, $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd),
			function () {
				switch (msg0.$) {
					case 7:
						return $elm$core$Maybe$Just(0);
					case 8:
						return $elm$core$Maybe$Just(1);
					case 9:
						return $elm$core$Maybe$Just(0);
					case 10:
						return $elm$core$Maybe$Just(1);
					case 2:
						return $elm$core$Maybe$Just(0);
					case 3:
						return $elm$core$Maybe$Just(1);
					case 0:
						return $elm$core$Maybe$Just(0);
					case 1:
						return $elm$core$Maybe$Just(1);
					case 30:
						var subAction = msg0.a;
						return $author$project$Components$Landing$Update$loading(subAction);
					case 22:
						var subAction = msg0.a;
						return $author$project$Components$Clients$List$Update$loading(subAction);
					case 23:
						var subAction = msg0.a;
						return $author$project$Components$Clients$Detail$Update$loading(subAction);
					case 21:
						var subAction = msg0.a;
						return $author$project$Components$Cabinets$OffresCommerciales$Update$loading(subAction);
					case 20:
						var subAction = msg0.a;
						return $author$project$Components$Cabinets$Users$New$Update$loading(subAction);
					default:
						return $elm$core$Maybe$Nothing;
				}
			}()));
};
var $author$project$Main$Modules$Module = F5(
	function (route, urlToJsFile, moduleName, label, directLinks) {
		return {lU: directLinks, m9: label, h6: moduleName, jz: route, o0: urlToJsFile};
	});
var $author$project$Main$Modules$DirectLink = F2(
	function (route, label) {
		return {m9: label, jz: route};
	});
var $author$project$Main$Modules$directLinkDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'label',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'route',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Main$Modules$DirectLink)));
var $author$project$Main$Modules$moduleDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'directLinks',
	$elm$json$Json$Decode$list($author$project$Main$Modules$directLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'label',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'moduleName',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'urlToJsFile',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'route',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Main$Modules$Module))))));
var $author$project$Main$Modules$modulesDecoder = A2(
	$elm$json$Json$Decode$field,
	'modules',
	$elm$json$Json$Decode$list($author$project$Main$Modules$moduleDecoder));
var $author$project$Routes$ComponentNavigation$ActionCanceled = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routes$ComponentNavigation$ItemSaved = function (a) {
	return {$: 0, a: a};
};
var $author$project$Routes$ComponentNavigation$JobDone = function (a) {
	return {$: 0, a: a};
};
var $author$project$Routes$ComponentNavigation$NewCabinet = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Cabinets$New$Update$navigate = function (msg) {
	switch (msg.$) {
		case 6:
			var cabUid = msg.a;
			return $elm$core$Maybe$Just(
				$author$project$Routes$ComponentNavigation$JobDone(
					$author$project$Routes$ComponentNavigation$NewCabinet(
						$author$project$Routes$ComponentNavigation$ItemSaved(
							$author$project$Routes$ComponentNavigation$CabinetItem(cabUid)))));
		case 5:
			var upsertMode = msg.a;
			return $elm$core$Maybe$Just(
				$author$project$Routes$ComponentNavigation$JobDone(
					$author$project$Routes$ComponentNavigation$NewCabinet(
						$author$project$Routes$ComponentNavigation$ActionCanceled(upsertMode))));
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Routes$ComponentNavigation$OffreCommerciale = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Update$navigate = function (msg) {
	switch (msg.$) {
		case 13:
			var cabId = msg.a;
			var offre = msg.b;
			return $elm$core$Maybe$Just(
				$author$project$Routes$ComponentNavigation$JobDone(
					$author$project$Routes$ComponentNavigation$OffreCommerciale(
						$author$project$Routes$ComponentNavigation$ItemSaved(
							_Utils_Tuple2(
								$author$project$Routes$ComponentNavigation$CabinetItem(cabId),
								offre)))));
		case 15:
			var cabId = msg.a;
			var offre = msg.b;
			return $elm$core$Maybe$Just(
				$author$project$Routes$ComponentNavigation$JobDone(
					$author$project$Routes$ComponentNavigation$OffreCommerciale(
						$author$project$Routes$ComponentNavigation$ActionCanceled(
							_Utils_Tuple2(
								$author$project$Routes$ComponentNavigation$CabinetItem(cabId),
								offre)))));
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Routes$ComponentNavigation$NewUser = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Drawer$ConfirmationDrawer$requestIsDone = function (msg) {
	if (msg.$ === 3) {
		var result = msg.a;
		return $elm$core$Maybe$Just(result);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Cabinets$Users$New$Update$navigate = function (msg) {
	switch (msg.$) {
		case 12:
			var item = msg.a;
			return $elm$core$Maybe$Just(
				$author$project$Routes$ComponentNavigation$JobDone(
					$author$project$Routes$ComponentNavigation$NewUser(
						$author$project$Routes$ComponentNavigation$ItemSaved(item))));
		case 13:
			var item = msg.a;
			return $elm$core$Maybe$Just(
				$author$project$Routes$ComponentNavigation$JobDone(
					$author$project$Routes$ComponentNavigation$NewUser(
						$author$project$Routes$ComponentNavigation$ActionCanceled(item))));
		case 15:
			var item = msg.a;
			var msg_ = msg.b;
			var _v1 = $author$project$Components$Drawer$ConfirmationDrawer$requestIsDone(msg_);
			if ((!_v1.$) && (!_v1.a.$)) {
				return $elm$core$Maybe$Just(
					$author$project$Routes$ComponentNavigation$JobDone(
						$author$project$Routes$ComponentNavigation$NewUser(
							$author$project$Routes$ComponentNavigation$ActionCanceled(item))));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Routes$ComponentNavigation$ClientDetail = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Clients$Detail$Update$navigate = function (msg) {
	_v0$3:
	while (true) {
		switch (msg.$) {
			case 5:
				return $elm$core$Maybe$Just(
					$author$project$Routes$ComponentNavigation$JobDone(
						$author$project$Routes$ComponentNavigation$ClientDetail(
							$author$project$Routes$ComponentNavigation$ActionCanceled(0))));
			case 7:
				if (!msg.b.$) {
					var result = msg.b.a;
					return $elm$core$Maybe$Just(
						$author$project$Routes$ComponentNavigation$JobDone(
							$author$project$Routes$ComponentNavigation$ClientDetail(
								$author$project$Routes$ComponentNavigation$ItemSaved(result.kx))));
				} else {
					break _v0$3;
				}
			case 9:
				var msg_ = msg.a;
				var _v1 = $author$project$Components$Drawer$ConfirmationDrawer$requestIsDone(msg_);
				if ((!_v1.$) && (!_v1.a.$)) {
					return $elm$core$Maybe$Just(
						$author$project$Routes$ComponentNavigation$JobDone(
							$author$project$Routes$ComponentNavigation$ClientDetail(
								$author$project$Routes$ComponentNavigation$ActionCanceled(0))));
				} else {
					return $elm$core$Maybe$Nothing;
				}
			default:
				break _v0$3;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $author$project$Routes$ComponentNavigation$Clients = {$: 2};
var $author$project$Components$Clients$List$Update$navigate = function (msg) {
	if ((msg.$ === 17) && (!msg.a.$)) {
		return $elm$core$Maybe$Just(
			$author$project$Routes$ComponentNavigation$JobDone($author$project$Routes$ComponentNavigation$Clients));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Routes$ComponentNavigation$ProduitDetails = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Produits$Details$Update$navigate = function (msg) {
	switch (msg.$) {
		case 1:
			return $elm$core$Maybe$Just(
				$author$project$Routes$ComponentNavigation$JobDone(
					$author$project$Routes$ComponentNavigation$ProduitDetails(
						$author$project$Routes$ComponentNavigation$ActionCanceled(0))));
		case 3:
			return $elm$core$Maybe$Nothing;
		case 5:
			if (!msg.a.$) {
				var produitId = msg.a.a;
				return $elm$core$Maybe$Just(
					$author$project$Routes$ComponentNavigation$JobDone(
						$author$project$Routes$ComponentNavigation$ProduitDetails(
							$author$project$Routes$ComponentNavigation$ItemSaved(produitId.kx))));
			} else {
				return $elm$core$Maybe$Nothing;
			}
		case 0:
			return $elm$core$Maybe$Nothing;
		case 2:
			return $elm$core$Maybe$Nothing;
		case 4:
			return $elm$core$Maybe$Nothing;
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Routes$ComponentNavigation$SupportDetails = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Supports$Details$Update$navigate = function (msg) {
	switch (msg.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 2:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Just(
				$author$project$Routes$ComponentNavigation$JobDone(
					$author$project$Routes$ComponentNavigation$SupportDetails(
						$author$project$Routes$ComponentNavigation$ActionCanceled(0))));
		case 3:
			return $elm$core$Maybe$Nothing;
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Main$MainUpdate$navigate = F2(
	function (appNavKey, msg0) {
		var componentNav = function () {
			switch (msg0.$) {
				case 17:
					return $elm$core$Maybe$Nothing;
				case 18:
					return $elm$core$Maybe$Nothing;
				case 19:
					var msg = msg0.a;
					return $author$project$Components$Cabinets$New$Update$navigate(msg);
				case 20:
					var msg = msg0.a;
					return $author$project$Components$Cabinets$Users$New$Update$navigate(msg);
				case 22:
					var msg = msg0.a;
					return $author$project$Components$Clients$List$Update$navigate(msg);
				case 23:
					var msg = msg0.a;
					return $author$project$Components$Clients$Detail$Update$navigate(msg);
				case 25:
					var msg = msg0.a;
					return $author$project$Components$Produits$Details$Update$navigate(msg);
				case 29:
					var msg = msg0.a;
					return $author$project$Components$Supports$Details$Update$navigate(msg);
				case 21:
					var msg = msg0.a;
					return $author$project$Components$Cabinets$OffresCommerciales$Update$navigate(msg);
				default:
					return $elm$core$Maybe$Nothing;
			}
		}();
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$Maybe$map,
				$author$project$Routes$ComponentNavigation$navigateToComponent(
					$author$project$Routes$ComponentNavigation$PushUrl(appNavKey)),
				componentNav));
	});
var $author$project$Components$Errors$Errors$AlertAction = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Errors$Errors$MessageError = function (a) {
	return {$: 1, a: a};
};
var $mgold$elm_nonempty_list$List$Nonempty$Nonempty = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $mgold$elm_nonempty_list$List$Nonempty$fromList = function (ys) {
	if (ys.b) {
		var x = ys.a;
		var xs = ys.b;
		return $elm$core$Maybe$Just(
			A2($mgold$elm_nonempty_list$List$Nonempty$Nonempty, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$printHttpError = function (error) {
	switch (error.$) {
		case 0:
			var url = error.a;
			return 'bad url : ' + url;
		case 1:
			return 'timeout';
		case 2:
			return 'network error';
		case 3:
			var s = error.a;
			return 'bad status : ' + $elm$core$String$fromInt(s);
		default:
			var s = error.a;
			return 'bad body : ' + s;
	}
};
var $author$project$Components$Auth$Update$popError = function (msg) {
	_v0$3:
	while (true) {
		switch (msg.$) {
			case 6:
				if (msg.a.$ === 1) {
					var e = msg.a.a;
					return $elm$core$Maybe$Just(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$printHttpError(e));
				} else {
					break _v0$3;
				}
			case 4:
				if (msg.a.$ === 1) {
					var e = msg.a.a;
					if (!e.$) {
						var s = e.a;
						return $elm$core$Maybe$Just(s);
					} else {
						var _v2 = e.a;
						var s = _v2.b;
						if (s === 'InvalidCredentials') {
							return $elm$core$Maybe$Just('Identifiants non valides');
						} else {
							return $elm$core$Maybe$Just(s);
						}
					}
				} else {
					break _v0$3;
				}
			case 7:
				var s = msg.a;
				return $elm$core$Maybe$Just(s);
			default:
				break _v0$3;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $author$project$Components$Cabinets$New$Update$popError = function (msg) {
	if ((msg.$ === 3) && (msg.a.$ === 1)) {
		var e = msg.a.a;
		if (!e.$) {
			var string = e.a;
			return $elm$core$Maybe$Just('Une erreur a eu lieu. La création est impossible.');
		} else {
			var _v2 = e.a;
			var err = _v2.b;
			var _v3 = err.es;
			return $elm$core$Maybe$Just('Un Cabinet avec le même nom existe déjà.');
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Cabinets$OffresCommerciales$Update$popError = function (msg) {
	_v0$3:
	while (true) {
		switch (msg.$) {
			case 1:
				if (msg.a.$ === 1) {
					var e = msg.a.a;
					return $elm$core$Maybe$Just(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$printHttpError(e));
				} else {
					break _v0$3;
				}
			case 12:
				if (msg.a.$ === 1) {
					var e = msg.a.a;
					return $elm$core$Maybe$Just(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$printHttpError(e));
				} else {
					break _v0$3;
				}
			case 16:
				var s = msg.a;
				return $elm$core$Maybe$Just(s);
			default:
				break _v0$3;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $author$project$Components$Cabinets$Users$New$Update$popError = function (msg) {
	var hiddenErrorMessage = function (id) {
		return 'Une erreur s\'est produite. Contactez votre service client avec l\'id suivant : ' + id;
	};
	_v0$4:
	while (true) {
		switch (msg.$) {
			case 10:
				if (msg.a.$ === 1) {
					return $elm$core$Maybe$Just('Création impossible');
				} else {
					break _v0$4;
				}
			case 18:
				if (msg.a.$ === 1) {
					var e = msg.a.a;
					return $elm$core$Maybe$Just(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$printHttpError(e));
				} else {
					break _v0$4;
				}
			case 21:
				if (msg.a.$ === 1) {
					var e = msg.a.a;
					return $elm$core$Maybe$Just(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$printHttpError(e));
				} else {
					break _v0$4;
				}
			case 15:
				var msg_ = msg.b;
				var _v1 = $author$project$Components$Drawer$ConfirmationDrawer$requestIsDone(msg_);
				if ((!_v1.$) && (_v1.a.$ === 1)) {
					var err = _v1.a.a;
					if (!err.$) {
						var errorMsg = err.a;
						return $elm$core$Maybe$Just(errorMsg);
					} else {
						var _v3 = err.a;
						var bodyResponse = _v3.b;
						switch (bodyResponse.$) {
							case 0:
								var traceId = bodyResponse.a.fn;
								return $elm$core$Maybe$Just(
									hiddenErrorMessage(traceId));
							case 1:
								var traceId = bodyResponse.a.fn;
								var infos = bodyResponse.a.m0;
								return $elm$core$Maybe$Just(
									hiddenErrorMessage(traceId));
							case 2:
								var traceId = bodyResponse.a.fn;
								var infos = bodyResponse.a.m0;
								return $elm$core$Maybe$Just(
									hiddenErrorMessage(traceId));
							case 3:
								var traceId = bodyResponse.a.fn;
								var infos = bodyResponse.a.m0;
								return $elm$core$Maybe$Just(
									hiddenErrorMessage(traceId));
							default:
								var traceId = bodyResponse.a.fn;
								var infos = bodyResponse.a.m0;
								return $elm$core$Maybe$Just('Pour pouvoir désactiver le conseiller, commencez par vider son portefeuille clients');
						}
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			default:
				break _v0$4;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $author$project$Components$Clients$Detail$Update$popError = function (msg) {
	_v0$4:
	while (true) {
		switch (msg.$) {
			case 1:
				if (msg.a.$ === 1) {
					return $elm$core$Maybe$Just('Impossible de récupérer le client');
				} else {
					break _v0$4;
				}
			case 7:
				if (msg.b.$ === 1) {
					return $elm$core$Maybe$Just('Sauvegarde en échec');
				} else {
					var res = msg.b.a;
					var _v1 = res.jv;
					switch (_v1.$) {
						case 0:
							return $elm$core$Maybe$Nothing;
						case 1:
							var m = _v1.a;
							return $elm$core$Maybe$Just(m.a4);
						default:
							var m = _v1.a;
							return $elm$core$Maybe$Just(m.a4);
					}
				}
			case 9:
				var msg_ = msg.a;
				var _v2 = $author$project$Components$Drawer$ConfirmationDrawer$requestIsDone(msg_);
				if (!_v2.$) {
					if (_v2.a.$ === 1) {
						return $elm$core$Maybe$Just('Impossible de supprimer le client');
					} else {
						var res = _v2.a.a;
						var _v3 = res.jv;
						switch (_v3.$) {
							case 0:
								return $elm$core$Maybe$Nothing;
							case 1:
								var m = _v3.a;
								return $elm$core$Maybe$Just(m.a4);
							default:
								var m = _v3.a;
								return $elm$core$Maybe$Just(m.a4);
						}
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			default:
				break _v0$4;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $author$project$Components$Clients$List$Update$popError = function (msg) {
	_v0$4:
	while (true) {
		switch (msg.$) {
			case 1:
				if (msg.a.$ === 1) {
					var e = msg.a.a;
					return $elm$core$Maybe$Just('Une erreur s\'est produite lors de la récupération des clients');
				} else {
					break _v0$4;
				}
			case 17:
				if (msg.a.$ === 1) {
					return $elm$core$Maybe$Just('Une erreur s\'est produite lors de la demande de synchronisation');
				} else {
					break _v0$4;
				}
			case 6:
				if (msg.a.$ === 1) {
					var e = msg.a.a;
					return $elm$core$Maybe$Just('Impossible de récupérer la liste des clients externe pour synchronisation');
				} else {
					break _v0$4;
				}
			case 12:
				if (msg.c.$ === 1) {
					var e = msg.c.a;
					return $elm$core$Maybe$Just('Erreur lors de la synchronisation du client avec le système externe');
				} else {
					break _v0$4;
				}
			default:
				break _v0$4;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $author$project$Components$Landing$Update$popError = function (msg) {
	_v0$4:
	while (true) {
		switch (msg.$) {
			case 1:
				if (msg.a.$ === 1) {
					var e = msg.a.a;
					return $elm$core$Maybe$Just(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$printHttpError(e));
				} else {
					break _v0$4;
				}
			case 3:
				if (msg.a.$ === 1) {
					var e = msg.a.a;
					return $elm$core$Maybe$Just(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$printHttpError(e));
				} else {
					break _v0$4;
				}
			case 5:
				if (msg.a.$ === 1) {
					var e = msg.a.a;
					return $elm$core$Maybe$Just(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$printHttpError(e));
				} else {
					break _v0$4;
				}
			case 7:
				if (msg.a.$ === 1) {
					var e = msg.a.a;
					return $elm$core$Maybe$Just(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$printHttpError(e));
				} else {
					break _v0$4;
				}
			default:
				break _v0$4;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $author$project$Components$Produits$Details$Documents$Update$popError = function (msg) {
	_v0$2:
	while (true) {
		switch (msg.$) {
			case 0:
				if (msg.a.$ === 1) {
					var s = msg.a.a;
					return $elm$core$Maybe$Just('Erreur lors de l\'obtention des documents');
				} else {
					break _v0$2;
				}
			case 15:
				var msg_ = msg.a;
				var _v1 = $author$project$Components$Drawer$ConfirmationDrawer$requestIsDone(msg_);
				if (!_v1.$) {
					if (_v1.a.$ === 1) {
						return $elm$core$Maybe$Just('Impossible de supprimer le document');
					} else {
						var res = _v1.a.a;
						var _v2 = res.jv;
						switch (_v2.$) {
							case 0:
								return $elm$core$Maybe$Nothing;
							case 1:
								var m = _v2.a;
								return $elm$core$Maybe$Just(m.a4);
							default:
								var m = _v2.a;
								return $elm$core$Maybe$Just(m.a4);
						}
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			default:
				break _v0$2;
		}
	}
	return $elm$core$Maybe$Nothing;
};
var $author$project$Components$Produits$Details$Update$popError = function (msg) {
	switch (msg.$) {
		case 0:
			if (msg.a.$ === 1) {
				return $elm$core$Maybe$Just('Impossible de charger le produit');
			} else {
				return $elm$core$Maybe$Nothing;
			}
		case 1:
			return $elm$core$Maybe$Nothing;
		case 2:
			return $elm$core$Maybe$Nothing;
		case 3:
			return $elm$core$Maybe$Nothing;
		case 5:
			var result = msg.a;
			if (!result.$) {
				var res = result.a;
				var _v2 = res.jv;
				switch (_v2.$) {
					case 0:
						return $elm$core$Maybe$Nothing;
					case 1:
						var m = _v2.a;
						return $elm$core$Maybe$Just(m.a4);
					default:
						var m = _v2.a;
						return $elm$core$Maybe$Just(m.a4);
				}
			} else {
				return $elm$core$Maybe$Just('Erreur lors de la création du produit');
			}
		case 4:
			return $elm$core$Maybe$Nothing;
		default:
			var action = msg.a;
			return $author$project$Components$Produits$Details$Documents$Update$popError(action);
	}
};
var $author$project$Components$Produits$List$Update$popError = function (msg) {
	if (!msg.$) {
		return $elm$core$Maybe$Nothing;
	} else {
		if (msg.a.$ === 1) {
			return $elm$core$Maybe$Just('Impossible de charger la liste des produits');
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}
};
var $author$project$Components$Supports$ImportSupports$List$Update$tryToShowTraceId = function (error) {
	if (!error.$) {
		var raw = error.a;
		return ' Erreur non connue: ' + raw;
	} else {
		var _v1 = error.a;
		return '';
	}
};
var $author$project$Components$Supports$ImportSupports$List$Update$popError = function (msg) {
	switch (msg.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Nothing;
		case 2:
			return $elm$core$Maybe$Nothing;
		case 3:
			return $elm$core$Maybe$Nothing;
		case 4:
			return $elm$core$Maybe$Nothing;
		case 5:
			return $elm$core$Maybe$Nothing;
		case 6:
			return $elm$core$Maybe$Nothing;
		case 7:
			return $elm$core$Maybe$Nothing;
		case 8:
			return $elm$core$Maybe$Nothing;
		case 9:
			return $elm$core$Maybe$Nothing;
		case 10:
			return $elm$core$Maybe$Nothing;
		case 11:
			return $elm$core$Maybe$Nothing;
		case 12:
			if (!msg.a.$) {
				return $elm$core$Maybe$Nothing;
			} else {
				var err = msg.a.a;
				return $elm$core$Maybe$Just(
					'Import du fichier en échec. ' + $author$project$Components$Supports$ImportSupports$List$Update$tryToShowTraceId(err));
			}
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Errors$Errors$MessageInfo = function (a) {
	return {$: 0, a: a};
};
var $mgold$elm_nonempty_list$List$Nonempty$singleton = function (x) {
	return A2($mgold$elm_nonempty_list$List$Nonempty$Nonempty, x, _List_Nil);
};
var $author$project$Components$Errors$Errors$errorMessage = function (e) {
	return $author$project$Components$Errors$Errors$MessageError(
		$mgold$elm_nonempty_list$List$Nonempty$singleton(e));
};
var $PerformanceIMMO$elm_utils$Perfimmo$Collection$NonEmptyList$nel = $mgold$elm_nonempty_list$List$Nonempty$Nonempty;
var $author$project$Components$ActiveUser$Update$popMessage = function (msg) {
	var okMessage = function (asked) {
		if (!asked) {
			return A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Collection$NonEmptyList$nel,
				'Votre compte utilisateur a été activé',
				_List_fromArray(
					['Vous pouvez vous connecter']));
		} else {
			return A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Collection$NonEmptyList$nel,
				'Votre mot de passe a été réinitialisé',
				_List_fromArray(
					['Vous pouvez vous connecter']));
		}
	};
	var koMessage = function (asked) {
		if (!asked) {
			return 'Impossible d\'activer le compte utilisateur';
		} else {
			return 'Impossible de réinitialiser votre mot de passe';
		}
	};
	switch (msg.$) {
		case 5:
			return $elm$core$Maybe$Just(
				$author$project$Components$Errors$Errors$errorMessage('Vous devez confirmer votre mot de passe'));
		case 4:
			if (msg.b.$ === 1) {
				var asked = msg.a;
				var e = msg.b.a;
				if (!e.$) {
					return $elm$core$Maybe$Just(
						$author$project$Components$Errors$Errors$errorMessage(
							koMessage(asked)));
				} else {
					var _v2 = e.a;
					var err = _v2.b;
					return (err.ma === 'NotValidDemand') ? $elm$core$Maybe$Just(
						$author$project$Components$Errors$Errors$errorMessage('Demande expirée')) : $elm$core$Maybe$Just(
						$author$project$Components$Errors$Errors$errorMessage(
							koMessage(asked)));
				}
			} else {
				var asked = msg.a;
				return $elm$core$Maybe$Just(
					$author$project$Components$Errors$Errors$MessageInfo(
						okMessage(asked)));
			}
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$ForgotPassword$Update$popMessage = function (msg) {
	if (msg.$ === 2) {
		if (msg.a.$ === 1) {
			var e = msg.a.a;
			return $elm$core$Maybe$Just(
				$author$project$Components$Errors$Errors$errorMessage('Impossible de réinitialiser votre mot de passe'));
		} else {
			return $elm$core$Maybe$Just(
				$author$project$Components$Errors$Errors$MessageInfo(
					A2($PerformanceIMMO$elm_utils$Perfimmo$Collection$NonEmptyList$nel, 'Vous allez recevoir un e-mail pour réinitialiser votre mot de passe', _List_Nil)));
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Main$MainUpdate$popMessages = function (msg0) {
	var errorMess = $elm$core$Maybe$map(
		A2($elm$core$Basics$composeR, $mgold$elm_nonempty_list$List$Nonempty$singleton, $author$project$Components$Errors$Errors$MessageError));
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Platform$Cmd$none,
		A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Components$Errors$Errors$AlertAction,
				A2($elm$core$Basics$composeR, $author$project$Main$Msg$AlertAction, $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd)),
			function () {
				_v0$16:
				while (true) {
					switch (msg0.$) {
						case 4:
							var msg = msg0.a;
							return errorMess(
								$author$project$Components$Auth$Update$popError(msg));
						case 5:
							var msg = msg0.a;
							return $author$project$Components$ActiveUser$Update$popMessage(msg);
						case 6:
							var msg = msg0.a;
							return $author$project$Components$ForgotPassword$Update$popMessage(msg);
						case 21:
							var msg = msg0.a;
							return errorMess(
								$author$project$Components$Cabinets$OffresCommerciales$Update$popError(msg));
						case 10:
							if (msg0.a.$ === 1) {
								var e = msg0.a.a;
								return $elm$core$Maybe$Just(
									$author$project$Components$Errors$Errors$MessageError(
										$mgold$elm_nonempty_list$List$Nonempty$singleton(
											$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$printHttpError(e))));
							} else {
								break _v0$16;
							}
						case 30:
							var msg = msg0.a;
							return errorMess(
								$author$project$Components$Landing$Update$popError(msg));
						case 19:
							var msg = msg0.a;
							return errorMess(
								$author$project$Components$Cabinets$New$Update$popError(msg));
						case 20:
							var msg = msg0.a;
							return errorMess(
								$author$project$Components$Cabinets$Users$New$Update$popError(msg));
						case 22:
							var subAction = msg0.a;
							return errorMess(
								$author$project$Components$Clients$List$Update$popError(subAction));
						case 23:
							var subAction = msg0.a;
							return errorMess(
								$author$project$Components$Clients$Detail$Update$popError(subAction));
						case 24:
							var subAction = msg0.a;
							return errorMess(
								$author$project$Components$Produits$List$Update$popError(subAction));
						case 25:
							var subAction = msg0.a;
							return errorMess(
								$author$project$Components$Produits$Details$Update$popError(subAction));
						case 31:
							var s = msg0.a;
							return A2(
								$elm$core$Maybe$map,
								$author$project$Components$Errors$Errors$MessageError,
								$mgold$elm_nonempty_list$List$Nonempty$fromList(s));
						case 3:
							if (msg0.a.$ === 1) {
								var e = msg0.a.a;
								return $elm$core$Maybe$Just(
									$author$project$Components$Errors$Errors$MessageError(
										$mgold$elm_nonempty_list$List$Nonempty$singleton(
											$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$printHttpError(e))));
							} else {
								break _v0$16;
							}
						case 8:
							if (msg0.a.$ === 1) {
								var e = msg0.a.a;
								return $elm$core$Maybe$Just(
									$author$project$Components$Errors$Errors$MessageError(
										$mgold$elm_nonempty_list$List$Nonempty$singleton(
											$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$printHttpError(e))));
							} else {
								break _v0$16;
							}
						case 26:
							var msg = msg0.a;
							return errorMess(
								$author$project$Components$Supports$ImportSupports$List$Update$popError(msg));
						default:
							break _v0$16;
					}
				}
				return $elm$core$Maybe$Nothing;
			}()));
};
var $author$project$Main$Msg$LoadSubModule = function (a) {
	return {$: 13, a: a};
};
var $author$project$Main$Msg$UnloadSubModule = {$: 15};
var $author$project$Main$MainUpdate$prepareSubModuleLoading = F2(
	function (activeModule, page) {
		var unload = $elm$core$Maybe$Just(
			$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd($author$project$Main$Msg$UnloadSubModule));
		var _v0 = _Utils_Tuple2(page, activeModule);
		if (!_v0.b.$) {
			if ((!_v0.a.$) && (_v0.a.a.$ === 8)) {
				var moduleInfo = _v0.a.a.a.h5;
				var active = _v0.b.a;
				return _Utils_eq(moduleInfo.jz, active.jz) ? $elm$core$Maybe$Nothing : unload;
			} else {
				return unload;
			}
		} else {
			if ((!_v0.a.$) && (_v0.a.a.$ === 8)) {
				var moduleInfo = _v0.a.a.a.h5;
				var _v1 = _v0.b;
				return $elm$core$Maybe$Just(
					$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
						$author$project$Main$Msg$LoadSubModule(moduleInfo)));
			} else {
				var _v2 = _v0.b;
				return $elm$core$Maybe$Nothing;
			}
		}
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$A = function (links) {
	return {nf: links};
};
var $PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinksDecoder = A2(
	$elm$json$Json$Decode$map,
	function ($) {
		return $.nf;
	},
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'_links',
		$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
		$elm$json$Json$Decode$succeed($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$A)));
var $author$project$Main$MainUpdate$sendLinkClickedFromParentApp = _Platform_outgoingPort(
	'sendLinkClickedFromParentApp',
	function ($) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'url',
					$elm$json$Json$Encode$string($.o$))
				]));
	});
var $author$project$Main$Modules$ModuleFlags = F6(
	function (backHost, docGeneratorHost, url, parentAppPathPrefix, subAppContainerClass, rootApiLinks) {
		return {k9: backHost, l0: docGeneratorHost, nV: parentAppPathPrefix, aM: rootApiLinks, oA: subAppContainerClass, o$: url};
	});
var $author$project$Main$Modules$ModuleInfo = F6(
	function (route, urlToJsFile, moduleName, label, directLinks, flags) {
		return {lU: directLinks, aa: flags, m9: label, h6: moduleName, jz: route, o0: urlToJsFile};
	});
var $elm_community$list_extra$List$Extra$filterNot = F2(
	function (pred, list) {
		return A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, pred),
			list);
	});
var $author$project$Main$MainModel$subAppContainerClass = 'subAppContainer';
var $author$project$Main$Modules$SerializableRestNavigationLink = F3(
	function (rel, href, method) {
		return {hA: href, nq: method, jm: rel};
	});
var $author$project$Main$Modules$httpVerbToString = function (verb) {
	switch (verb) {
		case 0:
			return 'GET';
		case 1:
			return 'POST';
		case 2:
			return 'PATCH';
		case 3:
			return 'DELETE';
		default:
			return 'PUT';
	}
};
var $author$project$Main$Modules$toSerializedRestNavLink = function (link) {
	return A3(
		$author$project$Main$Modules$SerializableRestNavigationLink,
		link.jm,
		link.hA,
		$author$project$Main$Modules$httpVerbToString(link.nq));
};
var $author$project$Main$Modules$toSerializedRootAPILinks = function (links) {
	return {
		d0: A2($elm$core$List$map, $author$project$Main$Modules$toSerializedRestNavLink, links.d0),
		nU: A2($elm$core$List$map, $author$project$Main$Modules$toSerializedRestNavLink, links.kW)
	};
};
var $author$project$Main$Modules$SerializableUrl = F6(
	function (https, host, port_, path, query, fragment) {
		return {mw: fragment, mL: host, mN: https, nX: path, n2: port_, of: query};
	});
var $author$project$Main$Modules$toSerializedUrl = function (url) {
	var protocol = function () {
		var _v0 = url.ob;
		if (_v0 === 1) {
			return true;
		} else {
			return false;
		}
	}();
	return A6($author$project$Main$Modules$SerializableUrl, protocol, url.mL, url.n2, url.nX, url.of, url.mw);
};
var $author$project$Main$MainModel$setModules = F2(
	function (model, modules) {
		var info = function (m) {
			return A6(
				$author$project$Main$Modules$ModuleInfo,
				m.jz,
				m.o0,
				m.h6,
				m.m9,
				m.lU,
				A6(
					$author$project$Main$Modules$ModuleFlags,
					model.aa.k9,
					model.aa.l0,
					$author$project$Main$Modules$toSerializedUrl(model.o$),
					m.jz,
					$author$project$Main$MainModel$subAppContainerClass,
					$author$project$Main$Modules$toSerializedRootAPILinks(model.aM)));
		};
		return _Utils_update(
			model,
			{
				cq: A2(
					$elm$core$List$map,
					info,
					A2(
						$elm_community$list_extra$List$Extra$filterNot,
						function (m) {
							return A2(
								$elm$core$List$any,
								$elm$core$Basics$eq(m.h6),
								model.lV);
						},
						modules))
			});
	});
var $author$project$Main$MainModel$setMustComplianceApiLinks = F2(
	function (model, links) {
		var othersLinks = links;
		var clientsLinks = A2(
			$elm$core$List$filter,
			function (l) {
				return A2($elm$core$String$startsWith, 'clients', l.jm);
			},
			links);
		var activitesLinks = A2(
			$elm$core$List$filter,
			function (l) {
				return A2($elm$core$String$startsWith, 'activites', l.jm);
			},
			links);
		return _Utils_update(
			model,
			{
				d2: links,
				aM: A4($author$project$Main$CommonModel$RootAPILinks, activitesLinks, clientsLinks, model.aM.n0, othersLinks)
			});
	});
var $author$project$Main$MainModel$setPIApiLinks = F2(
	function (model, links) {
		var rootLinks = model.aM;
		return _Utils_update(
			model,
			{
				aM: _Utils_update(
					rootLinks,
					{n0: links})
			});
	});
var $author$project$Main$MainUpdate$setPage = F2(
	function (model, page) {
		return _Utils_update(
			model,
			{iy: page});
	});
var $author$project$Main$MainModel$setUrlAsked = F2(
	function (model, url) {
		var setUrl = function (m) {
			return _Utils_update(
				m,
				{
					aa: A6(
						$author$project$Main$Modules$ModuleFlags,
						m.aa.k9,
						m.aa.l0,
						$author$project$Main$Modules$toSerializedUrl(url),
						m.aa.nV,
						m.aa.oA,
						m.aa.aM)
				});
		};
		return _Utils_update(
			model,
			{
				cq: A2($elm$core$List$map, setUrl, model.cq),
				o$: url
			});
	});
var $author$project$Main$MainUpdate$unloadSubModule = _Platform_outgoingPort(
	'unloadSubModule',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Components$ActiveUser$Model$Error = 2;
var $author$project$Components$ActiveUser$Update$IncorrectPassword = {$: 5};
var $author$project$Components$ActiveUser$Model$ReadyToSave = 1;
var $author$project$Components$ActiveUser$Model$Saving = 3;
var $author$project$Components$ActiveUser$Update$UserActived = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Components$ActiveUser$Update$askActiveUserProcessUid = _Platform_outgoingPort(
	'askActiveUserProcessUid',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$BodyResponse = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$DomainError = function (a) {
	return {$: 1, a: a};
};
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$TechnicalError = function (a) {
	return {$: 0, a: a};
};
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$expectWhateverOkResult = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			function (response) {
				switch (response.$) {
					case 0:
						var url = response.a;
						return $elm$core$Result$Err(
							$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$TechnicalError('Http.BadUrl' + url));
					case 1:
						return $elm$core$Result$Err(
							$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$TechnicalError('Http.Timeout'));
					case 2:
						return $elm$core$Result$Err(
							$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$TechnicalError('Http.NetworkError'));
					case 3:
						var metadata = response.a;
						var body = response.b;
						var result = function () {
							var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
							if (!_v1.$) {
								var value = _v1.a;
								return $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$DomainError(
									A2($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$BodyResponse, metadata, value));
							} else {
								return $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$TechnicalError(body);
							}
						}();
						return $elm$core$Result$Err(result);
					default:
						var metadata = response.a;
						return $elm$core$Result$Ok(
							A2($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$BodyResponse, metadata, 0));
				}
			});
	});
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $author$project$Main$Bindings$Json$CommonJsonBindings$PIEventApiError = F2(
	function (errorType, message) {
		return {ma: errorType, a4: message};
	});
var $author$project$Main$Bindings$Json$CommonJsonBindings$piEventApiErrorDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'message',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'errorType',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Main$Bindings$Json$CommonJsonBindings$PIEventApiError)));
var $PerformanceIMMO$elm_utils$Perfimmo$Primitive$ExtendString$stringToNonEmptyString = function (s) {
	if (s === '') {
		return $elm$core$Maybe$Nothing;
	} else {
		return $elm$core$Maybe$Just(s);
	}
};
var $author$project$Components$ActiveUser$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var password = msg.a;
				var m = _Utils_update(
					model,
					{
						nW: $PerformanceIMMO$elm_utils$Perfimmo$Primitive$ExtendString$stringToNonEmptyString(password),
						oq: 1
					});
				return _Utils_Tuple2(m, $elm$core$Platform$Cmd$none);
			case 1:
				var password = msg.a;
				var m = _Utils_update(
					model,
					{
						ly: $PerformanceIMMO$elm_utils$Perfimmo$Primitive$ExtendString$stringToNonEmptyString(password),
						oq: 1
					});
				return _Utils_Tuple2(m, $elm$core$Platform$Cmd$none);
			case 2:
				return _Utils_Tuple2(
					model,
					$author$project$Components$ActiveUser$Update$askActiveUserProcessUid(0));
			case 3:
				var processUid = msg.a;
				var error = _Utils_Tuple2(
					_Utils_update(
						model,
						{oq: 2}),
					$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd($author$project$Components$ActiveUser$Update$IncorrectPassword));
				var _v1 = function () {
					var _v2 = model.k1;
					if (!_v2) {
						return _Utils_Tuple2('activationCode', 'ActiveUser');
					} else {
						return _Utils_Tuple2('verificationCode', 'ResetPassword');
					}
				}();
				var code = _v1.a;
				var commandType = _v1.b;
				var activationBody = function (pass) {
					return $elm$http$Http$jsonBody(
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'processUid',
									$elm$json$Json$Encode$string(processUid)),
									_Utils_Tuple2(
									'password',
									$elm$json$Json$Encode$string(pass)),
									_Utils_Tuple2(
									code,
									$elm$json$Json$Encode$string(model.lR)),
									_Utils_Tuple2(
									'commandType',
									$elm$json$Json$Encode$string(commandType))
								])));
				};
				var postActivation = function (pass) {
					return A3(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
						model.kQ,
						activationBody(pass),
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$expectWhateverOkResult(
							$author$project$Components$ActiveUser$Update$UserActived(model.k1))($author$project$Main$Bindings$Json$CommonJsonBindings$piEventApiErrorDecoder));
				};
				var _v3 = A2(
					$elm$core$Maybe$withDefault,
					error,
					A3(
						$elm$core$Maybe$map2,
						F2(
							function (p, c) {
								return _Utils_eq(p, c) ? _Utils_Tuple2(
									_Utils_update(
										model,
										{oq: 3}),
									postActivation(p)) : error;
							}),
						model.nW,
						model.ly));
				var m = _v3.a;
				var effect = _v3.b;
				return _Utils_Tuple2(m, effect);
			case 4:
				if (!msg.b.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{oq: 0}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{oq: 2}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Components$Auth$Update$Fail = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$Auth$Update$LogInResult = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Auth$Update$LogoutResult = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Errors$ApiErrorDecoder$ErrorTypeRecord = function (errorType) {
	return {ma: errorType};
};
var $author$project$Components$Errors$ApiErrorDecoder$eventAPIErrorDecoder = A2(
	$elm$json$Json$Decode$map,
	function ($) {
		return $.ma;
	},
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'errorType',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Components$Errors$ApiErrorDecoder$ErrorTypeRecord)));
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $author$project$Components$Auth$Update$update = F2(
	function (action, model) {
		switch (action.$) {
			case 0:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							k6: $elm$core$Maybe$Just(0)
						}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var s = action.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							mO: $PerformanceIMMO$elm_utils$Perfimmo$Primitive$ExtendString$stringToNonEmptyString(s)
						}),
					$elm$core$Platform$Cmd$none);
			case 2:
				var s = action.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							nW: $PerformanceIMMO$elm_utils$Perfimmo$Primitive$ExtendString$stringToNonEmptyString(s)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var credentials = _Utils_Tuple2(model.mO, model.nW);
				var result = function () {
					if ((!credentials.a.$) && (!credentials.b.$)) {
						var l = credentials.a.a;
						var p = credentials.b.a;
						var url = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'login', model.nf);
						var expected = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$expectWhateverOkResult, $author$project$Components$Auth$Update$LogInResult, $author$project$Components$Errors$ApiErrorDecoder$eventAPIErrorDecoder);
						var body = $elm$http$Http$jsonBody(
							$elm$json$Json$Encode$object(
								_List_fromArray(
									[
										_Utils_Tuple2(
										'login',
										$elm$json$Json$Encode$string(l)),
										_Utils_Tuple2(
										'password',
										$elm$json$Json$Encode$string(p))
									])));
						return A2(
							$elm$core$Maybe$withDefault,
							$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
								$author$project$Components$Auth$Update$Fail('Erreur système. Contacter votre administrateur.')),
							A2(
								$elm$core$Maybe$map,
								function (u) {
									return $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$withAuthReq(
										A3($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$Post, u.hA, body, expected));
								},
								url));
					} else {
						return $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
							$author$project$Components$Auth$Update$Fail('Identifiant ou mot de passe requis'));
					}
				}();
				return _Utils_Tuple2(model, result);
			case 4:
				if (!action.a.$) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 5:
				var href = action.a;
				return _Utils_Tuple2(
					model,
					$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$withAuthReq(
						A2(
							$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$Get,
							href,
							$elm$http$Http$expectWhatever($author$project$Components$Auth$Update$LogoutResult))));
			case 6:
				if (!action.a.$) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Components$Cabinets$List$Update$CabinetsFetched = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$List$Model$setCabinetsLinks = F2(
	function (links, apiLinks) {
		return _Utils_update(
			apiLinks,
			{
				d5: $author$project$Main$NavLinkRef$cabinetRel.mh.d5(links),
				gk: $author$project$Main$NavLinkRef$cabinetRel.lG(links),
				kg: $author$project$Main$NavLinkRef$cabinetRel.mh.oM(links)
			});
	});
var $author$project$Components$Cabinets$List$Update$update = F2(
	function (model, msg) {
		if (!msg.$) {
			var effect = A3(
				$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
				model.k_.ml,
				$elm$http$Http$emptyBody,
				$elm$http$Http$expectJson($author$project$Components$Cabinets$List$Update$CabinetsFetched)(
					$PerformanceIMMO$elm_utils$Perfimmo$Http$RestAPIUtils$seqAPIResultJsonDecoder($author$project$Components$Cabinets$APIModel$cabinetJsonDecoder)));
			return _Utils_Tuple2(model, effect);
		} else {
			if (!msg.a.$) {
				var result = msg.a.a;
				var withCabinetsModel = _Utils_update(
					model,
					{
						k_: A2($author$project$Components$Cabinets$List$Model$setCabinetsLinks, result.nf, model.k_),
						ll: result.ok
					});
				return _Utils_Tuple2(withCabinetsModel, $elm$core$Platform$Cmd$none);
			} else {
				var e = msg.a.a;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$Components$Cabinets$New$Update$CabinetSaved = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Cabinets$New$Update$Canceled = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$CreateExternalSync = {$: 4};
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$ResponseDecoder = F2(
	function (koDecoder, okDecoder) {
		return {hS: koDecoder, iw: okDecoder};
	});
var $author$project$Components$Cabinets$New$Update$SetExternalToolSync = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$APIModel$CabinetAPIError = function (error) {
	return {es: error};
};
var $author$project$Components$Cabinets$APIModel$CabinetWithSameNameAlreadyExist = 0;
var $author$project$Components$Cabinets$APIModel$cabinetAPIErrorTypeJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'CabinetWithSameNameAlreadyExist',
			$elm$json$Json$Decode$succeed(0))
		]));
var $author$project$Components$Cabinets$APIModel$cabinetAPIErrorJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'error',
	$author$project$Components$Cabinets$APIModel$cabinetAPIErrorTypeJsonDecoder,
	$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$APIModel$CabinetAPIError));
var $author$project$Components$Cabinets$New$Update$SaveIsDone = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Cabinets$New$ExternalSync$Model$isSavedDone = function (model) {
	if (!model.$) {
		return true;
	} else {
		var hsm = model.a;
		var _v1 = hsm.bQ;
		if (_v1.$ === 6) {
			return true;
		} else {
			return false;
		}
	}
};
var $author$project$Components$Cabinets$New$Model$isSaveDone = function (model) {
	if (!model.$) {
		var m = model.a;
		var _v1 = m.by;
		if (!_v1.$) {
			return $elm$core$Maybe$Nothing;
		} else {
			var cabId = _v1.a;
			return $elm$core$Maybe$Just(cabId);
		}
	} else {
		var m = model.a;
		var _v2 = m.by;
		if (!_v2.$) {
			return $elm$core$Maybe$Nothing;
		} else {
			var cabId = _v2.a;
			return A2(
				$elm$core$Maybe$withDefault,
				true,
				A2($elm$core$Maybe$map, $author$project$Components$Cabinets$New$ExternalSync$Model$isSavedDone, m.am)) ? $elm$core$Maybe$Just(cabId) : $elm$core$Maybe$Nothing;
		}
	}
};
var $author$project$Components$Cabinets$New$Update$checkSaveIsDone = function (m) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$core$Platform$Cmd$none,
		A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeL, $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd, $author$project$Components$Cabinets$New$Update$SaveIsDone),
			$author$project$Components$Cabinets$New$Model$isSaveDone(m)));
};
var $PerformanceIMMO$elm_utils$Perfimmo$Http$RestAPIUtils$CRUDResult = function (uid) {
	return {kx: uid};
};
var $PerformanceIMMO$elm_utils$Perfimmo$Http$RestAPIUtils$crudResultJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'uid',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($PerformanceIMMO$elm_utils$Perfimmo$Http$RestAPIUtils$CRUDResult));
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$decodeJsonBody = F3(
	function (body, metadata, decoders) {
		var _v0 = A2($elm$json$Json$Decode$decodeString, decoders.iw, body);
		if (!_v0.$) {
			var value = _v0.a;
			return $elm$core$Result$Ok(
				A2($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$BodyResponse, metadata, value));
		} else {
			var err = _v0.a;
			return $elm$core$Result$Err(
				$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$TechnicalError(
					$elm$json$Json$Decode$errorToString(err)));
		}
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$expectJsonResponse2 = F2(
	function (toMsg, decoders) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			function (response) {
				switch (response.$) {
					case 0:
						var url = response.a;
						return $elm$core$Result$Err(
							$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$TechnicalError('Http.BadUrl' + url));
					case 1:
						return $elm$core$Result$Err(
							$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$TechnicalError('Http.Timeout'));
					case 2:
						return $elm$core$Result$Err(
							$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$TechnicalError('Http.NetworkError'));
					case 3:
						var metadata = response.a;
						var body = response.b;
						var result = function () {
							var _v1 = A2($elm$json$Json$Decode$decodeString, decoders.hS, body);
							if (!_v1.$) {
								var value = _v1.a;
								return $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$DomainError(
									A2($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$BodyResponse, metadata, value));
							} else {
								return $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$TechnicalError('json parsing error');
							}
						}();
						return $elm$core$Result$Err(result);
					default:
						var metadata = response.a;
						var body = response.b;
						return A3($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$decodeJsonBody, body, metadata, decoders);
				}
			});
	});
var $author$project$Components$Cabinets$New$Model$getExternalSyncSubModel = function (model) {
	if (!model.$) {
		var m = model.a;
		return m.am;
	} else {
		var m = model.a;
		return m.am;
	}
};
var $author$project$Components$Cabinets$New$Model$CabInfoToSave = function (name) {
	return {nz: name};
};
var $author$project$Components$Cabinets$New$Model$getInfoToSave = function (model) {
	if (!model.$) {
		var m = model.a;
		return $author$project$Components$Cabinets$New$Model$CabInfoToSave(m.aw);
	} else {
		var m = model.a;
		return $author$project$Components$Cabinets$New$Model$CabInfoToSave(m.aw);
	}
};
var $author$project$Routes$ComponentNavigation$Create = {$: 0};
var $author$project$Routes$ComponentNavigation$Update = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$New$Update$getUpsertMode = function (model) {
	if (!model.$) {
		return $author$project$Routes$ComponentNavigation$Create;
	} else {
		var cab = model.a;
		return $author$project$Routes$ComponentNavigation$Update(cab.lj);
	}
};
var $author$project$Components$Cabinets$New$Model$InfoSaved = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$New$Model$infosIsSaved = F2(
	function (model, cabId) {
		if (!model.$) {
			var m = model.a;
			return $author$project$Components$Cabinets$New$Model$CreateCabinet(
				_Utils_update(
					m,
					{
						by: $author$project$Components$Cabinets$New$Model$InfoSaved(cabId)
					}));
		} else {
			var m = model.a;
			return $author$project$Components$Cabinets$New$Model$UpdateCabinet(
				_Utils_update(
					m,
					{
						by: $author$project$Components$Cabinets$New$Model$InfoSaved(cabId)
					}));
		}
	});
var $author$project$Components$Cabinets$New$Model$setExternalSyncSubModel = F2(
	function (model, extSync) {
		if (!model.$) {
			var m = model.a;
			return $author$project$Components$Cabinets$New$Model$CreateCabinet(
				_Utils_update(
					m,
					{
						am: $elm$core$Maybe$Just(extSync),
						ab: true
					}));
		} else {
			var m = model.a;
			return $author$project$Components$Cabinets$New$Model$UpdateCabinet(
				_Utils_update(
					m,
					{
						am: $elm$core$Maybe$Just(extSync),
						ab: true
					}));
		}
	});
var $author$project$Components$Cabinets$New$Model$setName = F2(
	function (model, cabName) {
		if (!model.$) {
			var m = model.a;
			return $author$project$Components$Cabinets$New$Model$CreateCabinet(
				_Utils_update(
					m,
					{aw: cabName, ab: true}));
		} else {
			var m = model.a;
			return $author$project$Components$Cabinets$New$Model$UpdateCabinet(
				_Utils_update(
					m,
					{aw: cabName, ab: true}));
		}
	});
var $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$ConnectionTested = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Cabinets$New$ExternalSync$Model$Error = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$ExternalSyncCreated = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Cabinets$New$ExternalSync$Model$NotVerified = {$: 0};
var $author$project$Components$Cabinets$New$ExternalSync$Model$Saving = {$: 5};
var $author$project$Components$Cabinets$APIModel$TechnicalError = 3;
var $author$project$Components$Cabinets$New$ExternalSync$Model$TestingConnection = {$: 4};
var $author$project$Components$Cabinets$New$ExternalSync$Model$Verified = {$: 2};
var $author$project$Components$Cabinets$APIModel$FailT = function (error) {
	return {es: error};
};
var $author$project$Components$Cabinets$APIModel$SuccessT = function (infos) {
	return {m0: infos};
};
var $author$project$Components$Cabinets$APIModel$SyncTestFail = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$APIModel$SyncTestSuccess = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Cabinets$APIModel$FailTestB = function (error) {
	return {es: error};
};
var $author$project$Components$Cabinets$APIModel$HarvestInfosFail = $elm$core$Basics$identity;
var $author$project$Components$Cabinets$APIModel$ConnectionFail = 0;
var $author$project$Components$Cabinets$APIModel$CredentialsAlreadyUsedByAnotherCabinet = 2;
var $author$project$Components$Cabinets$APIModel$ImpossibleToVerifyCabinet = 1;
var $author$project$Components$Cabinets$APIModel$failTestErrorJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'ConnectionFail',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'ImpossibleToVerifyCabinet',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'CredentialsAlreadyUsedByAnotherCabinet',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'TechnicalError',
			$elm$json$Json$Decode$succeed(3))
		]));
var $author$project$Components$Cabinets$APIModel$failTestJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$map,
			$elm$core$Basics$identity,
			A2(
				$elm$json$Json$Decode$field,
				'HarvestInfosFail',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'error',
					$author$project$Components$Cabinets$APIModel$failTestErrorJsonDecoder,
					$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$APIModel$FailTestB))))
		]));
var $author$project$Components$Cabinets$APIModel$HarvestInfosSuccess = $elm$core$Basics$identity;
var $author$project$Components$Cabinets$APIModel$SuccessTestB = function (cabinetId) {
	return {lj: cabinetId};
};
var $author$project$Components$Cabinets$APIModel$successTestJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$map,
			$elm$core$Basics$identity,
			A2(
				$elm$json$Json$Decode$field,
				'HarvestInfosSuccess',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'cabinetId',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$APIModel$SuccessTestB))))
		]));
var $author$project$Components$Cabinets$APIModel$externalSyncInfosAPITestResultJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$map,
			$author$project$Components$Cabinets$APIModel$SyncTestSuccess,
			A2(
				$elm$json$Json$Decode$field,
				'Success',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'infos',
					$author$project$Components$Cabinets$APIModel$successTestJsonDecoder,
					$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$APIModel$SuccessT)))),
			A2(
			$elm$json$Json$Decode$map,
			$author$project$Components$Cabinets$APIModel$SyncTestFail,
			A2(
				$elm$json$Json$Decode$field,
				'Fail',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'error',
					$author$project$Components$Cabinets$APIModel$failTestJsonDecoder,
					$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$APIModel$FailT))))
		]));
var $author$project$Components$Cabinets$New$ExternalSync$Update$getError = function (r) {
	var _v0 = r.es;
	var error = _v0;
	return error.es;
};
var $author$project$Components$Cabinets$New$ExternalSync$Model$ReadyToTest = {$: 1};
var $rtfeldman$elm_validate$Validate$isWhitespaceChar = function (_char) {
	return (_char === ' ') || ((_char === '\n') || ((_char === '\t') || (_char === '\u000D')));
};
var $rtfeldman$elm_validate$Validate$isBlank = function (str) {
	isBlank:
	while (true) {
		var _v0 = $elm$core$String$uncons(str);
		if (!_v0.$) {
			var _v1 = _v0.a;
			var _char = _v1.a;
			var rest = _v1.b;
			if ($rtfeldman$elm_validate$Validate$isWhitespaceChar(_char)) {
				var $temp$str = rest;
				str = $temp$str;
				continue isBlank;
			} else {
				return false;
			}
		} else {
			return true;
		}
	}
};
var $author$project$Components$Cabinets$New$ExternalSync$Model$calculSyncStatus = function (hSyncM) {
	var allFieldAreNonEmpty = (!$rtfeldman$elm_validate$Validate$isBlank(hSyncM.lu)) && ((!$rtfeldman$elm_validate$Validate$isBlank(hSyncM.lv)) && ((!$rtfeldman$elm_validate$Validate$isBlank(hSyncM.o2)) && (!$rtfeldman$elm_validate$Validate$isBlank(hSyncM.nW))));
	var status = allFieldAreNonEmpty ? $author$project$Components$Cabinets$New$ExternalSync$Model$ReadyToTest : $author$project$Components$Cabinets$New$ExternalSync$Model$NotVerified;
	return _Utils_update(
		hSyncM,
		{bQ: status});
};
var $author$project$Components$Cabinets$New$ExternalSync$Model$setInfo = F2(
	function (hSyncM, info) {
		switch (info.$) {
			case 0:
				var i = info.a;
				return _Utils_update(
					hSyncM,
					{hB: i});
			case 1:
				var i = info.a;
				return _Utils_update(
					hSyncM,
					{lu: i});
			case 2:
				var s = info.a;
				return _Utils_update(
					hSyncM,
					{lv: s});
			case 3:
				var u = info.a;
				return _Utils_update(
					hSyncM,
					{o2: u});
			default:
				var p = info.a;
				return _Utils_update(
					hSyncM,
					{nW: p});
		}
	});
var $author$project$Components$Cabinets$New$ExternalSync$Model$setHarvestInfos = F2(
	function (model, info) {
		if (!model.$) {
			var m = model.a;
			return $author$project$Components$Cabinets$New$ExternalSync$Model$NoSync(m);
		} else {
			var harvestSyncModel = model.a;
			return $author$project$Components$Cabinets$New$ExternalSync$Model$HarvestChoiceSyncModel(
				$author$project$Components$Cabinets$New$ExternalSync$Model$calculSyncStatus(
					A2($author$project$Components$Cabinets$New$ExternalSync$Model$setInfo, harvestSyncModel, info)));
		}
	});
var $author$project$Components$Cabinets$New$ExternalSync$Model$setSyncStatus = F2(
	function (model, status) {
		if (!model.$) {
			var l = model.a;
			return $author$project$Components$Cabinets$New$ExternalSync$Model$NoSync(l);
		} else {
			var m = model.a;
			return $author$project$Components$Cabinets$New$ExternalSync$Model$HarvestChoiceSyncModel(
				_Utils_update(
					m,
					{bQ: status}));
		}
	});
var $author$project$Components$Cabinets$New$ExternalSync$Model$toggleChoice = F2(
	function (model, choice) {
		var initHarvestChoice = F2(
			function (t, c) {
				return $author$project$Components$Cabinets$New$ExternalSync$Model$HarvestChoiceSyncModel(
					{lu: '', lv: '', cc: c, hB: '', nW: '', bQ: $author$project$Components$Cabinets$New$ExternalSync$Model$NotVerified, cF: t, o2: ''});
			});
		if (!model.$) {
			var m = model.a;
			return A2(initHarvestChoice, m.cF, m.cc);
		} else {
			var m = model.a;
			return $author$project$Components$Cabinets$New$ExternalSync$Model$NoSync(
				{cc: m.cc, cF: m.cF});
		}
	});
var $author$project$Components$Cabinets$New$ExternalSync$Update$update = F2(
	function (model, action) {
		switch (action.$) {
			case 0:
				var externalToolChoice = action.a;
				var newModel = A2($author$project$Components$Cabinets$New$ExternalSync$Model$toggleChoice, model, externalToolChoice);
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 1:
				var harvestSyncInfos = action.a;
				var newModel = A2($author$project$Components$Cabinets$New$ExternalSync$Model$setHarvestInfos, model, harvestSyncInfos);
				return _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
			case 2:
				if (!model.$) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var m = model.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'HarvestSyncInfos',
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'clientApiId',
											$elm$json$Json$Encode$string(m.lu)),
											_Utils_Tuple2(
											'clientApiSecret',
											$elm$json$Json$Encode$string(m.lv)),
											_Utils_Tuple2(
											'username',
											$elm$json$Json$Encode$string(m.o2)),
											_Utils_Tuple2(
											'password',
											$elm$json$Json$Encode$string(m.nW))
										])))
							]));
					var request = A3(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
						m.cF,
						$elm$http$Http$jsonBody(body),
						$elm$http$Http$expectJson($author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$ConnectionTested)($author$project$Components$Cabinets$APIModel$externalSyncInfosAPITestResultJsonDecoder));
					return _Utils_Tuple2(
						A2($author$project$Components$Cabinets$New$ExternalSync$Model$setSyncStatus, model, $author$project$Components$Cabinets$New$ExternalSync$Model$TestingConnection),
						request);
				}
			case 3:
				if (!action.a.$) {
					var result = action.a.a;
					var m = function () {
						if (!result.$) {
							return A2($author$project$Components$Cabinets$New$ExternalSync$Model$setSyncStatus, model, $author$project$Components$Cabinets$New$ExternalSync$Model$Verified);
						} else {
							var r = result.a;
							return A2(
								$author$project$Components$Cabinets$New$ExternalSync$Model$setSyncStatus,
								model,
								$author$project$Components$Cabinets$New$ExternalSync$Model$Error(
									$author$project$Components$Cabinets$New$ExternalSync$Update$getError(r)));
						}
					}();
					return _Utils_Tuple2(m, $elm$core$Platform$Cmd$none);
				} else {
					var m = A2(
						$author$project$Components$Cabinets$New$ExternalSync$Model$setSyncStatus,
						model,
						$author$project$Components$Cabinets$New$ExternalSync$Model$Error(3));
					return _Utils_Tuple2(m, $elm$core$Platform$Cmd$none);
				}
			case 4:
				if (!model.$) {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				} else {
					var m = model.a;
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'HarvestSyncInfos',
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'clientApiId',
											$elm$json$Json$Encode$string(m.lu)),
											_Utils_Tuple2(
											'clientApiSecret',
											$elm$json$Json$Encode$string(m.lv)),
											_Utils_Tuple2(
											'username',
											$elm$json$Json$Encode$string(m.o2)),
											_Utils_Tuple2(
											'password',
											$elm$json$Json$Encode$string(m.nW))
										])))
							]));
					var request = A3(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
						m.cc,
						$elm$http$Http$jsonBody(body),
						$elm$http$Http$expectJson($author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$ExternalSyncCreated)($PerformanceIMMO$elm_utils$Perfimmo$Http$RestAPIUtils$crudResultJsonDecoder));
					var _v4 = m.bQ;
					if (_v4.$ === 2) {
						return _Utils_Tuple2(
							A2($author$project$Components$Cabinets$New$ExternalSync$Model$setSyncStatus, model, $author$project$Components$Cabinets$New$ExternalSync$Model$Saving),
							request);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				}
			default:
				if (!action.a.$) {
					return _Utils_Tuple2(
						A2($author$project$Components$Cabinets$New$ExternalSync$Model$setSyncStatus, model, $author$project$Components$Cabinets$New$ExternalSync$Model$Saved),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						A2($author$project$Components$Cabinets$New$ExternalSync$Model$setSyncStatus, model, $author$project$Components$Cabinets$New$ExternalSync$Model$NotVerified),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Components$Cabinets$New$Update$update = F2(
	function (model, msg) {
		switch (msg.$) {
			case 0:
				var name = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Components$Cabinets$New$Model$setName, model, name),
					$elm$core$Platform$Cmd$none);
			case 1:
				var action = msg.a;
				var set = function (_v2) {
					var m = _v2.a;
					var c = _v2.b;
					return _Utils_Tuple2(
						A2($author$project$Components$Cabinets$New$Model$setExternalSyncSubModel, model, m),
						A2($elm$core$Platform$Cmd$map, $author$project$Components$Cabinets$New$Update$SetExternalToolSync, c));
				};
				var _v1 = A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Maybe$map,
						function (extM) {
							return set(
								A2($author$project$Components$Cabinets$New$ExternalSync$Update$update, extM, action));
						},
						$author$project$Components$Cabinets$New$Model$getExternalSyncSubModel(model)));
				var newModel = _v1.a;
				var extEffects = _v1.b;
				var isDoneEffect = $author$project$Components$Cabinets$New$Update$checkSaveIsDone(newModel);
				return _Utils_Tuple2(
					newModel,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[extEffects, isDoneEffect])));
			case 2:
				var saveLink = msg.a;
				var responseDecoder = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$ResponseDecoder, $author$project$Components$Cabinets$APIModel$cabinetAPIErrorJsonDecoder, $PerformanceIMMO$elm_utils$Perfimmo$Http$RestAPIUtils$crudResultJsonDecoder);
				var createExtSync = $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
					$author$project$Components$Cabinets$New$Update$SetExternalToolSync($author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$CreateExternalSync));
				var cabInfo = $author$project$Components$Cabinets$New$Model$getInfoToSave(model);
				var upsertCabinet = A3(
					$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
					saveLink,
					$elm$http$Http$jsonBody(
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'name',
									$elm$json$Json$Encode$string(cabInfo.nz))
								]))),
					A2($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$expectJsonResponse2, $author$project$Components$Cabinets$New$Update$CabinetSaved, responseDecoder));
				var effect = ($elm$core$String$length(cabInfo.nz) > 0) ? upsertCabinet : $elm$core$Platform$Cmd$none;
				return _Utils_Tuple2(
					model,
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[effect, createExtSync])));
			case 3:
				if (!msg.a.$) {
					var _v3 = msg.a.a;
					var result = _v3.b;
					var m = A2($author$project$Components$Cabinets$New$Model$infosIsSaved, model, result.kx);
					var effect = $author$project$Components$Cabinets$New$Update$checkSaveIsDone(m);
					return _Utils_Tuple2(m, effect);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				return _Utils_Tuple2(
					model,
					$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
						$author$project$Components$Cabinets$New$Update$Canceled(
							$author$project$Components$Cabinets$New$Update$getUpsertMode(model))));
			case 5:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Components$Cabinets$OffresCommerciales$Model$AddExisting = 0;
var $author$project$Components$Cabinets$OffresCommerciales$Model$AddToCopy = 1;
var $author$project$Components$Cabinets$OffresCommerciales$Action$Canceled = F2(
	function (a, b) {
		return {$: 15, a: a, b: b};
	});
var $author$project$Components$Cabinets$OffresCommerciales$Action$ComponentSaved = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$Components$Cabinets$OffresCommerciales$ModelSetters$ConfirmAllInclusive = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Action$OffreSaved = function (a) {
	return {$: 12, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Action$OffresCommercialesFetched = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$ModelSetters$SetNewOffre = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Model$Existing = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Action$Fail = function (a) {
	return {$: 16, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Model$Saved = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Model$ToCopy = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Model$chosenProduitUnpack = function (v) {
	switch (v.$) {
		case 0:
			var s = v.a;
			return s;
		case 1:
			var s = v.a;
			return s;
		default:
			var s = v.a;
			return s;
	}
};
var $author$project$Components$Cabinets$OffresCommerciales$Model$chosenProduitUidUnpack = A2(
	$elm$core$Basics$composeR,
	$author$project$Components$Cabinets$OffresCommerciales$Model$chosenProduitUnpack,
	function ($) {
		return $.kx;
	});
var $elm$core$Basics$ge = _Utils_ge;
var $author$project$Components$Cabinets$OffresCommerciales$Model$getMaxProduitByOffre = function (o) {
	switch (o) {
		case 0:
			return $elm$core$Maybe$Just(2);
		case 1:
			return $elm$core$Maybe$Just(20);
		case 2:
			return $elm$core$Maybe$Just(30);
		case 3:
			return $elm$core$Maybe$Nothing;
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Cabinets$OffresCommerciales$ModelSetters$updateDocGenInModel = F2(
	function (model, f) {
		var offres = model.eX;
		var offresUpdated = _Utils_update(
			offres,
			{l_: f});
		return _Utils_update(
			model,
			{eX: offresUpdated});
	});
var $author$project$Components$Cabinets$OffresCommerciales$ModelSetters$addProduit = F3(
	function (produitToAd, produitUid, model) {
		var docGen = model.eX.l_;
		var maxProduit = A2(
			$elm_community$maybe_extra$Maybe$Extra$orElse,
			A2($ccapndave$elm_flat_map$Maybe$FlatMap$flatMap, $author$project$Components$Cabinets$OffresCommerciales$Model$getMaxProduitByOffre, docGen.fo),
			A2($ccapndave$elm_flat_map$Maybe$FlatMap$flatMap, $author$project$Components$Cabinets$OffresCommerciales$Model$getMaxProduitByOffre, docGen.bE));
		var isFull = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (m) {
					return _Utils_cmp(
						$elm$core$List$length(docGen.e1),
						m) > -1;
				},
				maxProduit));
		var newProduitChoisis = $elm_community$maybe_extra$Maybe$Extra$toList(
			A2(
				$elm$core$Maybe$map,
				function (p) {
					if (!produitToAd) {
						return A2(
							$elm$core$List$any,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Components$Cabinets$OffresCommerciales$Model$chosenProduitUidUnpack,
								$elm$core$Basics$eq(p.kx)),
							docGen.cA) ? $author$project$Components$Cabinets$OffresCommerciales$Model$Saved(p) : $author$project$Components$Cabinets$OffresCommerciales$Model$Existing(p);
					} else {
						return $author$project$Components$Cabinets$OffresCommerciales$Model$ToCopy(p);
					}
				},
				A2(
					$elm_community$list_extra$List$Extra$find,
					function (p) {
						return _Utils_eq(p.kx, produitUid);
					},
					docGen.n9)));
		var newProduitChoisi = _Utils_ap(newProduitChoisis, docGen.e1);
		var newProduitResult = isFull ? docGen.e1 : newProduitChoisi;
		var update = _Utils_update(
			docGen,
			{
				e1: newProduitResult,
				e2: A2(
					$elm_community$list_extra$List$Extra$filterNot,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.kx;
						},
						$elm$core$Basics$eq(produitUid)),
					docGen.e2)
			});
		var newModel = A2($author$project$Components$Cabinets$OffresCommerciales$ModelSetters$updateDocGenInModel, model, update);
		return isFull ? _Utils_Tuple2(
			newModel,
			$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
				$author$project$Components$Cabinets$OffresCommerciales$Action$Fail('Nombre maximum de produits atteint'))) : _Utils_Tuple2(newModel, $elm$core$Platform$Cmd$none);
	});
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$docGenTypeOffresEncoder = function (offre) {
	switch (offre) {
		case 0:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'OffreTest',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 1:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'OffreJusque20Produits',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 2:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'OffreJusque30Produits',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 3:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'OffrePlus30Produits',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'AllInclusive',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
	}
};
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$produitToUpdateEncoding = function (produitToUpdate) {
	var _v0 = function () {
		if (!produitToUpdate.$) {
			var id = produitToUpdate.a;
			return _Utils_Tuple2('Existing', id);
		} else {
			var id = produitToUpdate.a;
			return _Utils_Tuple2('ToCopy', id);
		}
	}();
	var v = _v0.a;
	var uid = _v0.b;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				v,
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'uid',
							$elm$json$Json$Encode$string(uid))
						])))
			]));
};
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$docGenOffreEncoder = function (apiView) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'typeOffreChoisi',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Cabinets$OffresCommerciales$APIModel$docGenTypeOffresEncoder, apiView.fo))),
				_Utils_Tuple2(
				'produitsChoisis',
				A2($elm$json$Json$Encode$list, $author$project$Components$Cabinets$OffresCommerciales$APIModel$produitToUpdateEncoding, apiView.e1))
			]));
};
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$UpdateDocGeneratorOffreAPIView = F2(
	function (typeOffreChoisi, produitsChoisis) {
		return {e1: produitsChoisis, fo: typeOffreChoisi};
	});
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$Existing = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$ToCopy = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Update$produitChoisisToAPiView = function (chosen) {
	switch (chosen.$) {
		case 0:
			var produit = chosen.a;
			return $author$project$Components$Cabinets$OffresCommerciales$APIModel$Existing(produit.kx);
		case 1:
			var produit = chosen.a;
			return $author$project$Components$Cabinets$OffresCommerciales$APIModel$Existing(produit.kx);
		default:
			var produit = chosen.a;
			return $author$project$Components$Cabinets$OffresCommerciales$APIModel$ToCopy(produit.kx);
	}
};
var $author$project$Components$Cabinets$OffresCommerciales$Update$docGenToAPIView = function (model) {
	return A2(
		$author$project$Components$Cabinets$OffresCommerciales$APIModel$UpdateDocGeneratorOffreAPIView,
		model.bE,
		A2($elm$core$List$map, $author$project$Components$Cabinets$OffresCommerciales$Update$produitChoisisToAPiView, model.e1));
};
var $author$project$Components$Cabinets$OffresCommerciales$ModelSetters$filterProduitChoisis = F2(
	function (model, value) {
		var docGen = model.eX.l_;
		return A2(
			$author$project$Components$Cabinets$OffresCommerciales$ModelSetters$updateDocGenInModel,
			model,
			_Utils_update(
				docGen,
				{ex: value}));
	});
var $author$project$Components$Cabinets$OffresCommerciales$ModelSetters$filterProduitReferentiel = F2(
	function (model, value) {
		var docGen = model.eX.l_;
		return A2(
			$author$project$Components$Cabinets$OffresCommerciales$ModelSetters$updateDocGenInModel,
			model,
			_Utils_update(
				docGen,
				{ey: value}));
	});
var $author$project$Components$Cabinets$OffresCommerciales$ModelSetters$filterRemoved = function (model) {
	var docGen = model.eX.l_;
	return A2(
		$author$project$Components$Cabinets$OffresCommerciales$ModelSetters$updateDocGenInModel,
		model,
		_Utils_update(
			docGen,
			{ez: !docGen.ez}));
};
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$OffresCommercialesAPIView = function (docGen) {
	return {l_: docGen};
};
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$DocGenOffreChoisieAPIView = F4(
	function (typeOffreChoisi, produitsChoisis, produitsReferentiel, links) {
		return {nf: links, e1: produitsChoisis, n9: produitsReferentiel, fo: typeOffreChoisi};
	});
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$AllInclusive = 4;
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$OffreJusque20Produits = 1;
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$OffreJusque30Produits = 2;
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$OffrePlus30Produits = 3;
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$OffreTest = 0;
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$docGenTypeOffresDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'OffreTest',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'OffreJusque20Produits',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'OffreJusque30Produits',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'OffrePlus30Produits',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'AllInclusive',
			$elm$json$Json$Decode$succeed(4))
		]));
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$Produit = F4(
	function (uid, label, actif, duplicateFrom) {
		return {kP: actif, l7: duplicateFrom, m9: label, kx: uid};
	});
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$produitDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'duplicateFrom',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'actif',
		$elm$json$Json$Decode$bool,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'label',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'uid',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$OffresCommerciales$APIModel$Produit)))));
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$docGenDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'produitsReferentiel',
		$elm$json$Json$Decode$list($author$project$Components$Cabinets$OffresCommerciales$APIModel$produitDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'produitsChoisis',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'typeOffreChoisi',
				$elm$json$Json$Decode$nullable($author$project$Components$Cabinets$OffresCommerciales$APIModel$docGenTypeOffresDecoder),
				$elm$core$Maybe$Nothing,
				$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$OffresCommerciales$APIModel$DocGenOffreChoisieAPIView)))));
var $author$project$Components$Cabinets$OffresCommerciales$APIModel$offresCommercialesDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'docGen',
	$author$project$Components$Cabinets$OffresCommerciales$APIModel$docGenDecoder,
	$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$OffresCommerciales$APIModel$OffresCommercialesAPIView));
var $elm$core$List$partition = F2(
	function (pred, list) {
		var step = F2(
			function (x, _v0) {
				var trues = _v0.a;
				var falses = _v0.b;
				return pred(x) ? _Utils_Tuple2(
					A2($elm$core$List$cons, x, trues),
					falses) : _Utils_Tuple2(
					trues,
					A2($elm$core$List$cons, x, falses));
			});
		return A3(
			$elm$core$List$foldr,
			step,
			_Utils_Tuple2(_List_Nil, _List_Nil),
			list);
	});
var $author$project$Components$Cabinets$OffresCommerciales$ModelSetters$removeProduit = F2(
	function (model, produitUid) {
		var docGen = model.eX.l_;
		var _v0 = A2(
			$elm$core$List$partition,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Components$Cabinets$OffresCommerciales$Model$chosenProduitUidUnpack,
				$elm$core$Basics$eq(produitUid)),
			docGen.e1);
		var removedProduit = _v0.a;
		var produitToujoursChoisis = _v0.b;
		var toPutInRemovedProduit = A2(
			$elm$core$List$filterMap,
			function (p) {
				if (!p.$) {
					var pr = p.a;
					return $elm$core$Maybe$Just(pr);
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			removedProduit);
		var update = _Utils_update(
			docGen,
			{
				e1: produitToujoursChoisis,
				e2: _Utils_ap(docGen.e2, toPutInRemovedProduit)
			});
		return A2($author$project$Components$Cabinets$OffresCommerciales$ModelSetters$updateDocGenInModel, model, update);
	});
var $author$project$Components$Cabinets$OffresCommerciales$Action$SaveOffre = function (a) {
	return {$: 11, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Update$save = function (model) {
	var _v0 = model.gK;
	if (!_v0.$) {
		if (!_v0.a) {
			var _v1 = _v0.a;
			var effect = A2(
				$elm$core$Maybe$withDefault,
				$elm$core$Platform$Cmd$none,
				A2(
					$elm$core$Maybe$map,
					A2($elm$core$Basics$composeL, $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd, $author$project$Components$Cabinets$OffresCommerciales$Action$SaveOffre),
					model.eX.l_.fp));
			return _Utils_Tuple2(model, effect);
		} else {
			var _v2 = _v0.a;
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	} else {
		return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
	}
};
var $author$project$Components$Cabinets$OffresCommerciales$ModelSetters$setNewOffre = F2(
	function (model, myType) {
		if (!myType.$) {
			var offre = myType.a;
			var docGen = model.eX.l_;
			var newOffre = _Utils_eq(
				$elm$core$Maybe$Just(offre),
				docGen.fo) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(offre);
			var update = _Utils_update(
				docGen,
				{
					eb: (_Utils_eq(docGen.bE, $elm$core$Maybe$Nothing) && _Utils_eq(
						docGen.fo,
						$elm$core$Maybe$Just(4))) ? false : (_Utils_eq(
						docGen.bE,
						$elm$core$Maybe$Just(4)) ? false : (offre === 4)),
					bE: (offre !== 4) ? newOffre : docGen.bE
				});
			return A2($author$project$Components$Cabinets$OffresCommerciales$ModelSetters$updateDocGenInModel, model, update);
		} else {
			var isConfirmed = myType.a;
			var docGen = model.eX.l_;
			var update = _Utils_update(
				docGen,
				{
					eb: false,
					bE: isConfirmed ? $elm$core$Maybe$Just(4) : docGen.bE,
					e1: isConfirmed ? A2(
						$elm_community$list_extra$List$Extra$filterNot,
						A2(
							$elm$core$Basics$composeR,
							$author$project$Components$Cabinets$OffresCommerciales$Model$chosenProduitUnpack,
							A2(
								$elm$core$Basics$composeR,
								function ($) {
									return $.l7;
								},
								$elm$core$Basics$eq($elm$core$Maybe$Nothing))),
						docGen.e1) : docGen.e1
				});
			return A2($author$project$Components$Cabinets$OffresCommerciales$ModelSetters$updateDocGenInModel, model, update);
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$isJust = function (m) {
	if (m.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $author$project$Components$Cabinets$OffresCommerciales$Model$setOffres = F2(
	function (model, offres) {
		var updateDocGenOffreLink = $author$project$Main$NavLinkRef$offresCommercialesRel.l$.oW(offres);
		var docGenOffre = {
			eb: false,
			ex: '',
			ey: '',
			ez: false,
			eO: $elm_community$maybe_extra$Maybe$Extra$isJust(updateDocGenOffreLink),
			bE: $elm$core$Maybe$Nothing,
			cA: A2(
				$elm$core$List$map,
				$author$project$Components$Cabinets$OffresCommerciales$Model$Saved,
				A2(
					$elm$core$List$filterMap,
					function (uid) {
						return A2(
							$elm_community$list_extra$List$Extra$find,
							function (p) {
								return _Utils_eq(p.kx, uid);
							},
							offres.l_.n9);
					},
					offres.l_.e1)),
			e1: A2(
				$elm$core$List$map,
				$author$project$Components$Cabinets$OffresCommerciales$Model$Saved,
				A2(
					$elm$core$List$filterMap,
					function (uid) {
						return A2(
							$elm_community$list_extra$List$Extra$find,
							function (p) {
								return _Utils_eq(p.kx, uid);
							},
							offres.l_.n9);
					},
					offres.l_.e1)),
			n9: offres.l_.n9,
			e2: _List_Nil,
			fo: offres.l_.fo,
			fp: updateDocGenOffreLink
		};
		return _Utils_update(
			model,
			{
				eJ: true,
				eK: false,
				eX: $author$project$Components$Cabinets$OffresCommerciales$Model$OffresCommerciales(docGenOffre)
			});
	});
var $author$project$Components$Cabinets$OffresCommerciales$Update$update = F2(
	function (model, msg) {
		switch (msg.$) {
			case 0:
				var getOffresLink = msg.a;
				var effect = A3(
					$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
					getOffresLink,
					$elm$http$Http$emptyBody,
					$elm$http$Http$expectJson($author$project$Components$Cabinets$OffresCommerciales$Action$OffresCommercialesFetched)($author$project$Components$Cabinets$OffresCommerciales$APIModel$offresCommercialesDecoder));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{eK: true}),
					effect);
			case 1:
				if (!msg.a.$) {
					var offres = msg.a.a;
					return _Utils_Tuple2(
						A2($author$project$Components$Cabinets$OffresCommerciales$Model$setOffres, model, offres),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{eK: false}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var s = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Components$Cabinets$OffresCommerciales$ModelSetters$filterProduitReferentiel, model, s),
					$elm$core$Platform$Cmd$none);
			case 3:
				var s = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Components$Cabinets$OffresCommerciales$ModelSetters$filterProduitChoisis, model, s),
					$elm$core$Platform$Cmd$none);
			case 4:
				return _Utils_Tuple2(
					$author$project$Components$Cabinets$OffresCommerciales$ModelSetters$filterRemoved(model),
					$elm$core$Platform$Cmd$none);
			case 5:
				var typeOffre = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$Components$Cabinets$OffresCommerciales$ModelSetters$setNewOffre,
						model,
						$author$project$Components$Cabinets$OffresCommerciales$ModelSetters$SetNewOffre(typeOffre)),
					$elm$core$Platform$Cmd$none);
			case 6:
				var produitUid = msg.a;
				return A3($author$project$Components$Cabinets$OffresCommerciales$ModelSetters$addProduit, 0, produitUid, model);
			case 7:
				var produitUid = msg.a;
				return A3($author$project$Components$Cabinets$OffresCommerciales$ModelSetters$addProduit, 1, produitUid, model);
			case 8:
				var produitUid = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Components$Cabinets$OffresCommerciales$ModelSetters$removeProduit, model, produitUid),
					$elm$core$Platform$Cmd$none);
			case 9:
				var isConfirmed = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$Components$Cabinets$OffresCommerciales$ModelSetters$setNewOffre,
						model,
						$author$project$Components$Cabinets$OffresCommerciales$ModelSetters$ConfirmAllInclusive(isConfirmed)),
					$elm$core$Platform$Cmd$none);
			case 10:
				return $author$project$Components$Cabinets$OffresCommerciales$Update$save(model);
			case 11:
				var saveLink = msg.a;
				var json = $author$project$Components$Cabinets$OffresCommerciales$APIModel$docGenOffreEncoder(
					$author$project$Components$Cabinets$OffresCommerciales$Update$docGenToAPIView(model.eX.l_));
				var req = A3(
					$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
					saveLink,
					$elm$http$Http$jsonBody(json),
					$elm$http$Http$expectWhatever($author$project$Components$Cabinets$OffresCommerciales$Action$OffreSaved));
				return _Utils_Tuple2(model, req);
			case 12:
				if (!msg.a.$) {
					var effect = A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							A2(
								$elm$core$Basics$composeL,
								$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd,
								$author$project$Components$Cabinets$OffresCommerciales$Action$ComponentSaved(model.fZ.kx)),
							model.gK));
					return _Utils_Tuple2(model, effect);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 13:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hL: true}),
					$elm$core$Platform$Cmd$none);
			case 14:
				var effect = A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							$author$project$Components$Cabinets$OffresCommerciales$Action$Canceled(model.fZ.kx),
							$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd),
						model.gK));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{hL: true}),
					effect);
			case 15:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Components$Cabinets$Users$List$Update$ByActivationState = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$Users$List$Update$Filters = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Cabinets$Users$List$Update$UsersFetched = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$Users$List$Model$statusFromS = function (raw) {
	switch (raw) {
		case 'Activé':
			return $elm$core$Maybe$Just(0);
		case 'En attente d\'activation':
			return $elm$core$Maybe$Just(1);
		case 'Désactivé':
			return $elm$core$Maybe$Just(2);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Cabinets$Users$List$Model$applyFilters = function (model) {
	var selectedState = $inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.az.b5);
	var containsUserSearch = $elm$core$String$contains(
		$elm$core$String$toLower(model.az.cS));
	var filterByUserName = function (user) {
		return containsUserSearch(
			$elm$core$String$toLower(user.ms) + (' ' + $elm$core$String$toLower(user.na))) || containsUserSearch(
			$elm$core$String$toLower(user.na) + (' ' + $elm$core$String$toLower(user.ms)));
	};
	var checkState = F2(
		function (status, statusChosen) {
			if (!statusChosen.$) {
				var chosen = statusChosen.a;
				return _Utils_eq(status, chosen);
			} else {
				return false;
			}
		});
	var filterByStatus = function (status) {
		return $elm$core$List$isEmpty(selectedState) ? true : A2(
			$elm$core$List$any,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Tuple$first,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Components$Cabinets$Users$List$Model$statusFromS,
					checkState(status))),
			selectedState);
	};
	return _Utils_update(
		model,
		{
			eF: A2(
				$elm$core$List$filter,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.ox;
					},
					filterByStatus),
				A2($elm$core$List$filter, filterByUserName, model.o3))
		});
};
var $inkuzmin$elm_multiselect$Multiselect$Cleared = {$: 2};
var $inkuzmin$elm_multiselect$Multiselect$DisableProtection = {$: 3};
var $inkuzmin$elm_multiselect$Multiselect$FocusResult = function (a) {
	return {$: 8, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Hide = 1;
var $inkuzmin$elm_multiselect$Multiselect$NotFound = function (a) {
	return {$: 3, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$ScrollResult = function (a) {
	return {$: 9, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$ScrollY = function (a) {
	return {$: 14, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Selected = function (a) {
	return {$: 0, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Unselected = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$backspace = 8;
var $elm$core$Process$sleep = _Process_sleep;
var $inkuzmin$elm_multiselect$Multiselect$delayInMs = F2(
	function (ms, msg) {
		return A2(
			$elm$core$Task$perform,
			function (_v0) {
				return msg;
			},
			$elm$core$Process$sleep(ms));
	});
var $elm$browser$Browser$Dom$getViewportOf = _Browser_getViewportOf;
var $elm$browser$Browser$Dom$setViewportOf = _Browser_setViewportOf;
var $inkuzmin$elm_multiselect$Multiselect$domScrollToY = F2(
	function (id, y) {
		return A2(
			$elm$core$Task$andThen,
			function (vp) {
				return A3($elm$browser$Browser$Dom$setViewportOf, id, vp.kF.kM, y);
			},
			$elm$browser$Browser$Dom$getViewportOf(id));
	});
var $inkuzmin$elm_multiselect$Multiselect$domScrollY = function (id) {
	return A2(
		$elm$core$Task$map,
		function (vp) {
			return vp.kF.kN;
		},
		$elm$browser$Browser$Dom$getViewportOf(id));
};
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$downArrow = 40;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$end = 35;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$escape = 27;
var $inkuzmin$elm_multiselect$Multiselect$fitViewPort = F2(
	function (_v0, _v1) {
		var top = _v0.a;
		var bottom = _v0.b;
		var vpTop = _v1.a;
		var vpBottom = _v1.b;
		return (_Utils_cmp(top, vpTop) < 0) ? top : ((_Utils_cmp(bottom, vpBottom) > 0) ? (vpTop + (bottom - vpBottom)) : vpTop);
	});
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight = 32;
var $inkuzmin$elm_multiselect$Multiselect$getBoundaries = function (i) {
	return _Utils_Tuple2(i * $inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight, (i * $inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight) + $inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight);
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuHeight = 200;
var $inkuzmin$elm_multiselect$Multiselect$getViewPortBoundaries = function (i) {
	return _Utils_Tuple2(i, i + $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuHeight);
};
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$home = 36;
var $inkuzmin$elm_multiselect$Multiselect$indexOf = F2(
	function (el, list) {
		var helper = F2(
			function (l, index) {
				helper:
				while (true) {
					if (!l.b) {
						return $elm$core$Maybe$Nothing;
					} else {
						var x = l.a;
						var xs = l.b;
						if (_Utils_eq(x, el)) {
							return $elm$core$Maybe$Just(index);
						} else {
							var $temp$l = xs,
								$temp$index = index + 1;
							l = $temp$l;
							index = $temp$index;
							continue helper;
						}
					}
				}
			});
		return A2(helper, list, 0);
	});
var $inkuzmin$elm_multiselect$Multiselect$lastElem = A2(
	$elm$core$List$foldl,
	A2($elm$core$Basics$composeR, $elm$core$Maybe$Just, $elm$core$Basics$always),
	$elm$core$Maybe$Nothing);
var $inkuzmin$elm_multiselect$Multiselect$nextItem = F2(
	function (list, item) {
		var findNextInList = function (l) {
			findNextInList:
			while (true) {
				if (!l.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					if (!l.b.b) {
						var x = l.a;
						return _Utils_eq(x, item) ? $elm$core$List$head(list) : $elm$core$Maybe$Nothing;
					} else {
						var x = l.a;
						var _v1 = l.b;
						var y = _v1.a;
						var rest = _v1.b;
						if (_Utils_eq(x, item)) {
							return $elm$core$Maybe$Just(y);
						} else {
							var $temp$l = A2($elm$core$List$cons, y, rest);
							l = $temp$l;
							continue findNextInList;
						}
					}
				}
			}
		};
		return findNextInList(list);
	});
var $inkuzmin$elm_multiselect$Multiselect$nextSelectedItem = F2(
	function (list, item) {
		var takeLast = function (l) {
			if (!l.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				if (!l.b.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var _v3 = l.b;
					var y = _v3.a;
					return $elm$core$Maybe$Just(y);
				}
			}
		};
		var findNextInList = function (l) {
			findNextInList:
			while (true) {
				if (!l.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					if (!l.b.b) {
						var x = l.a;
						return _Utils_eq(x, item) ? takeLast(
							$elm$core$List$reverse(list)) : $elm$core$Maybe$Nothing;
					} else {
						var x = l.a;
						var _v1 = l.b;
						var y = _v1.a;
						var rest = _v1.b;
						if (_Utils_eq(x, item)) {
							return $elm$core$Maybe$Just(y);
						} else {
							var $temp$l = A2($elm$core$List$cons, y, rest);
							l = $temp$l;
							continue findNextInList;
						}
					}
				}
			}
		};
		return findNextInList(list);
	});
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$pageDown = 34;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$pageUp = 33;
var $inkuzmin$elm_multiselect$Multiselect$prevItem = F2(
	function (list, item) {
		return A2(
			$inkuzmin$elm_multiselect$Multiselect$nextItem,
			$elm$core$List$reverse(list),
			item);
	});
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$return = 13;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$tab = 9;
var $inkuzmin$elm_multiselect$Multiselect$Keycodes$upArrow = 38;
var $inkuzmin$elm_multiselect$Multiselect$update = F2(
	function (msg, _v0) {
		var model = _v0;
		switch (msg.$) {
			case 0:
				return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
			case 4:
				return (model.ox === 2) ? _Utils_Tuple3(
					_Utils_update(
						model,
						{ox: 0}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.fh))
							])),
					$elm$core$Maybe$Nothing) : _Utils_Tuple3(
					_Utils_update(
						model,
						{ox: 2}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.fh))
							])),
					$elm$core$Maybe$Nothing);
			case 1:
				return model.aq ? _Utils_Tuple3(
					_Utils_update(
						model,
						{aq: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing) : _Utils_Tuple3(
					_Utils_update(
						model,
						{ox: 0}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 3:
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{aq: false}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 2:
				return model.aq ? _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing) : _Utils_Tuple3(
					_Utils_update(
						model,
						{aq: true, ox: 2}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.fh)),
								A2($inkuzmin$elm_multiselect$Multiselect$delayInMs, 100, $inkuzmin$elm_multiselect$Multiselect$DisableProtection)
							])),
					$elm$core$Maybe$Nothing);
			case 9:
				var result = msg.a;
				if (result.$ === 1) {
					var id = result.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								es: $elm$core$Maybe$Just('Could not find dom id: ' + id)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var _v3 = model.a1;
					if (_v3.$ === 1) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									a1: $elm$core$Maybe$Just('')
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{es: $elm$core$Maybe$Nothing}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					}
				}
			case 8:
				var result = msg.a;
				if (result.$ === 1) {
					var id = result.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								es: $elm$core$Maybe$Just('Could not find dom id: ' + id)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var _v5 = model.a1;
					if (_v5.$ === 1) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									a1: $elm$core$Maybe$Just('')
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{es: $elm$core$Maybe$Nothing}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					}
				}
			case 11:
				var value = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{eM: value}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 10:
				var value = msg.a;
				if (model.aq) {
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{aq: false}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var lowerValue = $elm$core$String$toLower(value);
					var valuesMatchingSearch = A2(
						$elm$core$List$filter,
						function (_v8) {
							var val = _v8.b;
							return A2(
								$elm$core$String$contains,
								lowerValue,
								$elm$core$String$toLower(val));
						},
						model.o7);
					var maybePrefixedWithValue = ((model.eL === 1) || ((value === '') || A2(
						$elm$core$List$any,
						function (_v7) {
							var val = _v7.b;
							return _Utils_eq(
								$elm$core$String$toLower(val),
								value);
						},
						valuesMatchingSearch))) ? valuesMatchingSearch : A2(
						$elm$core$List$cons,
						_Utils_Tuple2(value, value),
						valuesMatchingSearch);
					var filtered = $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
						{o: model.o, o7: maybePrefixedWithValue});
					var _v6 = model.t;
					if (_v6.$ === 1) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									s: filtered,
									t: $elm$core$List$head(filtered),
									a1: $elm$core$Maybe$Just(value),
									ox: $elm$core$List$isEmpty(filtered) ? 0 : 2
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						var item = _v6.a;
						return (!$elm$core$List$length(
							A2(
								$elm$core$List$filter,
								function (i) {
									return _Utils_eq(i, item);
								},
								filtered))) ? _Utils_Tuple3(
							_Utils_update(
								model,
								{
									s: filtered,
									t: $elm$core$List$head(filtered),
									a1: $elm$core$Maybe$Just(value),
									ox: $elm$core$List$isEmpty(filtered) ? 0 : 2
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing) : _Utils_Tuple3(
							_Utils_update(
								model,
								{
									s: filtered,
									a1: $elm$core$Maybe$Just(value),
									ox: $elm$core$List$isEmpty(filtered) ? 0 : 2
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					}
				}
			case 5:
				var item = msg.a;
				var selected = _Utils_ap(
					model.o,
					_List_fromArray(
						[item]));
				var filtered = $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
					{o: selected, o7: model.o7});
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							s: filtered,
							t: A2($inkuzmin$elm_multiselect$Multiselect$nextSelectedItem, model.s, item),
							a1: $elm$core$Maybe$Nothing,
							o: selected,
							ox: $elm$core$List$isEmpty(filtered) ? 0 : 2
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.fh))
							])),
					$elm$core$Maybe$Just(
						$inkuzmin$elm_multiselect$Multiselect$Selected(item)));
			case 6:
				var item = msg.a;
				var selected = A2(
					$elm$core$List$filter,
					function (value) {
						return !_Utils_eq(value, item);
					},
					model.o);
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							s: $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
								{o: selected, o7: model.o7}),
							t: $elm$core$Maybe$Just(item),
							o: selected
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$ScrollY,
								$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.fh))
							])),
					$elm$core$Maybe$Just(
						$inkuzmin$elm_multiselect$Multiselect$Unselected(item)));
			case 7:
				var selected = _List_Nil;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							s: $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
								{o: selected, o7: model.o7}),
							a1: $elm$core$Maybe$Nothing,
							o: selected,
							ox: 0
						}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$core$Task$attempt,
								$inkuzmin$elm_multiselect$Multiselect$FocusResult,
								$elm$browser$Browser$Dom$focus('multiselectInput' + model.fh))
							])),
					$elm$core$Maybe$Just($inkuzmin$elm_multiselect$Multiselect$Cleared));
			case 12:
				var item = msg.a;
				return _Utils_Tuple3(
					_Utils_update(
						model,
						{
							t: $elm$core$Maybe$Just(item)
						}),
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Nothing);
			case 14:
				var result = msg.a;
				if (result.$ === 1) {
					var id = result.a;
					return _Utils_Tuple3(
						_Utils_update(
							model,
							{
								es: $elm$core$Maybe$Just('Could not find dom id: ' + id)
							}),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					var y = result.a;
					var _v10 = model.t;
					if (_v10.$ === 1) {
						return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
					} else {
						var item = _v10.a;
						var _v11 = A2($inkuzmin$elm_multiselect$Multiselect$indexOf, item, model.s);
						if (_v11.$ === 1) {
							return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
						} else {
							var idx = _v11.a;
							var vpBoundaries = $inkuzmin$elm_multiselect$Multiselect$getViewPortBoundaries(y);
							var boundaries = $inkuzmin$elm_multiselect$Multiselect$getBoundaries(idx);
							var scroll = A2($inkuzmin$elm_multiselect$Multiselect$fitViewPort, boundaries, vpBoundaries);
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{es: $elm$core$Maybe$Nothing}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2(
											$elm$core$Task$attempt,
											$inkuzmin$elm_multiselect$Multiselect$ScrollResult,
											A2($inkuzmin$elm_multiselect$Multiselect$domScrollToY, 'multiselectMenu' + model.fh, scroll))
										])),
								$elm$core$Maybe$Nothing);
						}
					}
				}
			default:
				var key = msg.a;
				if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$upArrow)) {
					var _v12 = model.t;
					if (_v12.$ === 1) {
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{
									t: $elm$core$List$head(model.s)
								}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						var item = _v12.a;
						var prev = A2($inkuzmin$elm_multiselect$Multiselect$prevItem, model.s, item);
						return _Utils_Tuple3(
							_Utils_update(
								model,
								{t: prev}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2(
										$elm$core$Task$attempt,
										$inkuzmin$elm_multiselect$Multiselect$ScrollY,
										$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.fh))
									])),
							$elm$core$Maybe$Nothing);
					}
				} else {
					if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$downArrow)) {
						var _v13 = model.t;
						if (_v13.$ === 1) {
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{
										t: $elm$core$List$head(model.s)
									}),
								$elm$core$Platform$Cmd$none,
								$elm$core$Maybe$Nothing);
						} else {
							var item = _v13.a;
							var next = A2($inkuzmin$elm_multiselect$Multiselect$nextItem, model.s, item);
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{t: next}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2(
											$elm$core$Task$attempt,
											$inkuzmin$elm_multiselect$Multiselect$ScrollY,
											$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.fh))
										])),
								$elm$core$Maybe$Nothing);
						}
					} else {
						if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$pageUp) || _Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$home)) {
							var first = $elm$core$List$head(model.s);
							return _Utils_Tuple3(
								_Utils_update(
									model,
									{t: first}),
								$elm$core$Platform$Cmd$batch(
									_List_fromArray(
										[
											A2(
											$elm$core$Task$attempt,
											$inkuzmin$elm_multiselect$Multiselect$ScrollY,
											$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.fh))
										])),
								$elm$core$Maybe$Nothing);
						} else {
							if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$pageDown) || _Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$end)) {
								var last = $inkuzmin$elm_multiselect$Multiselect$lastElem(model.s);
								return _Utils_Tuple3(
									_Utils_update(
										model,
										{t: last}),
									$elm$core$Platform$Cmd$batch(
										_List_fromArray(
											[
												A2(
												$elm$core$Task$attempt,
												$inkuzmin$elm_multiselect$Multiselect$ScrollY,
												$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.fh))
											])),
									$elm$core$Maybe$Nothing);
							} else {
								if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$return) && ((model.ox === 2) || $elm$core$List$isEmpty(model.s))) {
									var _v14 = model.t;
									if (_v14.$ === 1) {
										var _v15 = model.a1;
										if (_v15.$ === 1) {
											return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
										} else {
											if (_v15.a === '') {
												return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
											} else {
												var input_ = _v15.a;
												return _Utils_Tuple3(
													model,
													$elm$core$Platform$Cmd$none,
													$elm$core$Maybe$Just(
														$inkuzmin$elm_multiselect$Multiselect$NotFound(input_)));
											}
										}
									} else {
										var _v16 = _v14.a;
										var id = _v16.a;
										var val = _v16.b;
										var lowerVal = $elm$core$String$toLower(val);
										if ((!model.eL) && (_Utils_eq(
											$elm$core$Maybe$Just(val),
											model.a1) && A2(
											$elm$core$List$all,
											function (_v17) {
												var value = _v17.b;
												return !_Utils_eq(
													$elm$core$String$toLower(value),
													lowerVal);
											},
											model.o7))) {
											return _Utils_Tuple3(
												model,
												$elm$core$Platform$Cmd$none,
												$elm$core$Maybe$Just(
													$inkuzmin$elm_multiselect$Multiselect$NotFound(val)));
										} else {
											var item = _Utils_Tuple2(id, val);
											var selected = _Utils_ap(
												model.o,
												_List_fromArray(
													[item]));
											var filtered = $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
												{o: selected, o7: model.o7});
											return _Utils_Tuple3(
												_Utils_update(
													model,
													{
														s: filtered,
														t: A2($inkuzmin$elm_multiselect$Multiselect$nextSelectedItem, model.s, item),
														a1: $elm$core$Maybe$Nothing,
														o: selected,
														ox: $elm$core$List$isEmpty(filtered) ? 0 : 2
													}),
												$elm$core$Platform$Cmd$batch(
													_List_fromArray(
														[
															A2(
															$elm$core$Task$attempt,
															$inkuzmin$elm_multiselect$Multiselect$FocusResult,
															$elm$browser$Browser$Dom$focus('multiselectInput' + model.fh))
														])),
												$elm$core$Maybe$Just(
													$inkuzmin$elm_multiselect$Multiselect$Selected(item)));
										}
									}
								} else {
									if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$escape)) {
										return _Utils_Tuple3(
											_Utils_update(
												model,
												{aq: true, ox: 0}),
											$elm$core$Platform$Cmd$none,
											$elm$core$Maybe$Nothing);
									} else {
										if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$tab)) {
											return _Utils_Tuple3(
												_Utils_update(
													model,
													{ox: 0}),
												$elm$core$Platform$Cmd$none,
												$elm$core$Maybe$Nothing);
										} else {
											if (_Utils_eq(key, $inkuzmin$elm_multiselect$Multiselect$Keycodes$backspace)) {
												var _v18 = model.a1;
												if ((!_v18.$) && (_v18.a === '')) {
													var _v19 = $inkuzmin$elm_multiselect$Multiselect$lastElem(model.o);
													if (_v19.$ === 1) {
														return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
													} else {
														var item = _v19.a;
														var selected = A2(
															$elm$core$List$filter,
															function (value) {
																return !_Utils_eq(value, item);
															},
															model.o);
														return _Utils_Tuple3(
															_Utils_update(
																model,
																{
																	s: $inkuzmin$elm_multiselect$Multiselect$valuesWithoutSelected(
																		{o: selected, o7: model.o7}),
																	t: $elm$core$Maybe$Just(item),
																	o: selected
																}),
															$elm$core$Platform$Cmd$batch(
																_List_fromArray(
																	[
																		A2(
																		$elm$core$Task$attempt,
																		$inkuzmin$elm_multiselect$Multiselect$ScrollY,
																		$inkuzmin$elm_multiselect$Multiselect$domScrollY('multiselectMenu' + model.fh))
																	])),
															$elm$core$Maybe$Just(
																$inkuzmin$elm_multiselect$Multiselect$Unselected(item)));
													}
												} else {
													return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
												}
											} else {
												return _Utils_Tuple3(model, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
											}
										}
									}
								}
							}
						}
					}
				}
		}
	});
var $author$project$Components$Cabinets$Users$List$Model$filterByActivationState = F2(
	function (model, msg_) {
		var filters = model.az;
		var _v0 = A2($inkuzmin$elm_multiselect$Multiselect$update, msg_, filters.b5);
		var m = _v0.a;
		var c = _v0.b;
		var setFiltre = _Utils_update(
			model,
			{
				az: _Utils_update(
					filters,
					{b5: m})
			});
		var newModel = $author$project$Components$Cabinets$Users$List$Model$applyFilters(setFiltre);
		return _Utils_Tuple2(newModel, c);
	});
var $elm$core$String$trimLeft = _String_trimLeft;
var $author$project$Components$Cabinets$Users$List$Model$filterByUser = F2(
	function (model, search) {
		var filters = model.az;
		var cleanedSearch = $elm$core$String$trimLeft(search);
		return $author$project$Components$Cabinets$Users$List$Model$applyFilters(
			_Utils_update(
				model,
				{
					az: _Utils_update(
						filters,
						{cS: cleanedSearch})
				}));
	});
var $author$project$Components$Cabinets$Users$List$Model$AuthorizationRights = F3(
	function (activitesRights, cabinetRights, clientsRights) {
		return {aj: activitesRights, b8: cabinetRights, aU: clientsRights};
	});
var $author$project$Components$Cabinets$Users$List$Model$User = function (uid) {
	return function (firstName) {
		return function (lastName) {
			return function (login) {
				return function (role) {
					return function (authorizationRights) {
						return function (filterRight) {
							return function (isSynced) {
								return function (status) {
									return function (deactivateUserLink) {
										return function (updateUserLink) {
											return function (updateUserExternalSync) {
												return {k7: authorizationRights, lL: deactivateUserLink, mq: filterRight, ms: firstName, m5: isSynced, na: lastName, nh: login, oo: role, ox: status, kx: uid, oZ: updateUserExternalSync, o_: updateUserLink};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Cabinets$Users$List$Model$fromApiToUser = function (apiView) {
	var updateUserLink = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'update', apiView.nf);
	var updateUserExternalSync = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'updateUserExternalSync', apiView.nf);
	var f = F2(
		function (apiRight, m) {
			switch (apiRight.$) {
				case 1:
					switch (apiRight.a) {
						case 0:
							var _v1 = apiRight.a;
							return _Utils_update(
								m,
								{
									aj: _Utils_ap(
										m.aj,
										_List_fromArray(
											[0]))
								});
						case 1:
							var _v2 = apiRight.a;
							return _Utils_update(
								m,
								{
									aj: _Utils_ap(
										m.aj,
										_List_fromArray(
											[1]))
								});
						case 2:
							var _v3 = apiRight.a;
							return _Utils_update(
								m,
								{
									aj: _Utils_ap(
										m.aj,
										_List_fromArray(
											[2]))
								});
						default:
							var _v4 = apiRight.a;
							return _Utils_update(
								m,
								{
									aj: _Utils_ap(
										m.aj,
										_List_fromArray(
											[3]))
								});
					}
				case 0:
					if (apiRight.a === 1) {
						var _v5 = apiRight.a;
						return _Utils_update(
							m,
							{
								b8: _Utils_ap(
									m.b8,
									_List_fromArray(
										[1]))
							});
					} else {
						var _v6 = apiRight.a;
						return _Utils_update(
							m,
							{
								b8: _Utils_ap(
									m.b8,
									_List_fromArray(
										[0]))
							});
					}
				default:
					switch (apiRight.a) {
						case 0:
							var _v7 = apiRight.a;
							return _Utils_update(
								m,
								{
									aU: _Utils_ap(
										m.aU,
										_List_fromArray(
											[0]))
								});
						case 1:
							var _v8 = apiRight.a;
							return _Utils_update(
								m,
								{
									aU: _Utils_ap(
										m.aU,
										_List_fromArray(
											[1]))
								});
						default:
							var _v9 = apiRight.a;
							return _Utils_update(
								m,
								{
									aU: _Utils_ap(
										m.aU,
										_List_fromArray(
											[2]))
								});
					}
			}
		});
	var rights = A3(
		$elm$core$List$foldl,
		f,
		A3($author$project$Components$Cabinets$Users$List$Model$AuthorizationRights, _List_Nil, _List_Nil, _List_Nil),
		apiView.k7);
	var deactivateUserLink = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'deactivate', apiView.nf);
	return $author$project$Components$Cabinets$Users$List$Model$User(apiView.kx)(apiView.ms)(apiView.na)(apiView.nh)(apiView.oo)(rights)(apiView.mq)(apiView.m5)(apiView.ox)(deactivateUserLink)(updateUserLink)(updateUserExternalSync);
};
var $author$project$Components$Cabinets$Users$List$Model$setUsers = F2(
	function (model, result) {
		return $author$project$Components$Cabinets$Users$List$Model$applyFilters(
			_Utils_update(
				model,
				{
					eh: A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'create', result.nf),
					o3: A2($elm$core$List$map, $author$project$Components$Cabinets$Users$List$Model$fromApiToUser, result.ok)
				}));
	});
var $author$project$Components$Cabinets$APIModel$UserAPIView = function (uid) {
	return function (login) {
		return function (role) {
			return function (firstName) {
				return function (lastName) {
					return function (authorizationRights) {
						return function (filterRight) {
							return function (isSynced) {
								return function (status) {
									return function (links) {
										return {k7: authorizationRights, mq: filterRight, ms: firstName, m5: isSynced, na: lastName, nf: links, nh: login, oo: role, ox: status, kx: uid};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Cabinets$APIModel$DeleteClients = 2;
var $author$project$Components$Cabinets$APIModel$HandleCabinet = 0;
var $author$project$Components$Cabinets$APIModel$HandleProduction = 1;
var $author$project$Components$Cabinets$APIModel$HandleUser = 1;
var $author$project$Components$Cabinets$APIModel$ManageActivites = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$APIModel$ManageCabinet = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Cabinets$APIModel$ManageClients = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Cabinets$APIModel$SeeActivitesClients = 0;
var $author$project$Components$Cabinets$APIModel$SeeChiffreAffaire = 2;
var $author$project$Components$Cabinets$APIModel$SeeClients = 0;
var $author$project$Components$Cabinets$APIModel$SeeFraCif = 3;
var $author$project$Components$Cabinets$APIModel$UpdateClients = 1;
var $author$project$Components$Cabinets$APIModel$authorizationRightJsonDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		switch (s) {
			case 'ManageActivites.SeeClients':
				return $elm$json$Json$Decode$succeed(
					$elm$core$Maybe$Just(
						$author$project$Components$Cabinets$APIModel$ManageActivites(0)));
			case 'ManageActivites.HandleProduction':
				return $elm$json$Json$Decode$succeed(
					$elm$core$Maybe$Just(
						$author$project$Components$Cabinets$APIModel$ManageActivites(1)));
			case 'ManageActivites.SeeChiffreAffaire':
				return $elm$json$Json$Decode$succeed(
					$elm$core$Maybe$Just(
						$author$project$Components$Cabinets$APIModel$ManageActivites(2)));
			case 'ManageActivites.SeeFraCif':
				return $elm$json$Json$Decode$succeed(
					$elm$core$Maybe$Just(
						$author$project$Components$Cabinets$APIModel$ManageActivites(3)));
			case 'ManageCabinets.HandleCabinet':
				return $elm$json$Json$Decode$succeed(
					$elm$core$Maybe$Just(
						$author$project$Components$Cabinets$APIModel$ManageCabinet(0)));
			case 'ManageCabinets.HandleUser':
				return $elm$json$Json$Decode$succeed(
					$elm$core$Maybe$Just(
						$author$project$Components$Cabinets$APIModel$ManageCabinet(1)));
			case 'ManageClients.SeeClients':
				return $elm$json$Json$Decode$succeed(
					$elm$core$Maybe$Just(
						$author$project$Components$Cabinets$APIModel$ManageClients(0)));
			case 'ManageClients.UpdateClients':
				return $elm$json$Json$Decode$succeed(
					$elm$core$Maybe$Just(
						$author$project$Components$Cabinets$APIModel$ManageClients(1)));
			case 'ManageClients.DeleteClients':
				return $elm$json$Json$Decode$succeed(
					$elm$core$Maybe$Just(
						$author$project$Components$Cabinets$APIModel$ManageClients(2)));
			case 'ManageProduits.ManageDocuments.DownloadDocuments':
				return $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing);
			case 'ManageSupports.ManageDocuments.DownloadDocuments':
				return $elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing);
			default:
				return $elm$json$Json$Decode$fail('unknwon AuthorizationRight value: ' + s);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Components$Cabinets$APIModel$AllInCabinet = {$: 2};
var $author$project$Components$Cabinets$APIModel$CanSeeNothing = {$: 3};
var $author$project$Components$Cabinets$APIModel$FilterByMyUid = {$: 0};
var $author$project$Components$Cabinets$APIModel$FilterByUser = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$APIModel$filterAPIRightJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'FilterByMyUid',
			$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$APIModel$FilterByMyUid)),
			A2(
			$elm$json$Json$Decode$field,
			'FilterByUser',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'users',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
				$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$APIModel$FilterByUser))),
			A2(
			$elm$json$Json$Decode$field,
			'AllInCabinet',
			$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$APIModel$AllInCabinet)),
			A2(
			$elm$json$Json$Decode$field,
			'CanSeeNothing',
			$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$APIModel$CanSeeNothing))
		]));
var $author$project$Components$Cabinets$APIModel$Assistant = 2;
var $author$project$Components$Cabinets$APIModel$Dirigeant = 0;
var $author$project$Components$Cabinets$APIModel$userRoleJsonDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		switch (s) {
			case 'Dirigeant':
				return $elm$json$Json$Decode$succeed(0);
			case 'Conseiller':
				return $elm$json$Json$Decode$succeed(1);
			case 'Assistant':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('unknwon UserRole value: ' + s);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Components$Cabinets$APIModel$userStatusJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Activated',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'Pending',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'Deactivated',
			$elm$json$Json$Decode$succeed(2))
		]));
var $author$project$Components$Cabinets$APIModel$userApiViewJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'status',
		$author$project$Components$Cabinets$APIModel$userStatusJsonDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'isSynced',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'filterRight',
				$author$project$Components$Cabinets$APIModel$filterAPIRightJsonDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'authorizationRights',
					A2(
						$elm$json$Json$Decode$map,
						$elm$core$List$filterMap($elm$core$Basics$identity),
						$elm$json$Json$Decode$list($author$project$Components$Cabinets$APIModel$authorizationRightJsonDecoder)),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'lastName',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'firstName',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'role',
								$author$project$Components$Cabinets$APIModel$userRoleJsonDecoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'login',
									$elm$json$Json$Decode$string,
									A3(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
										'uid',
										$elm$json$Json$Decode$string,
										$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$APIModel$UserAPIView)))))))))));
var $author$project$Components$Cabinets$Users$List$Update$update = F2(
	function (model, msg) {
		switch (msg.$) {
			case 0:
				var fetchUsers = function (link) {
					return A3(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
						link,
						$elm$http$Http$emptyBody,
						$elm$http$Http$expectJson($author$project$Components$Cabinets$Users$List$Update$UsersFetched)(
							$PerformanceIMMO$elm_utils$Perfimmo$Http$RestAPIUtils$seqAPIResultJsonDecoder($author$project$Components$Cabinets$APIModel$userApiViewJsonDecoder)));
				};
				var effect = A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2($elm$core$Maybe$map, fetchUsers, model.g3));
				return _Utils_Tuple2(model, effect);
			case 1:
				if (!msg.a.$) {
					var result = msg.a.a;
					return _Utils_Tuple2(
						A2($author$project$Components$Cabinets$Users$List$Model$setUsers, model, result),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				var filterBy = msg.a;
				if (!filterBy.$) {
					var search = filterBy.a;
					return _Utils_Tuple2(
						A2($author$project$Components$Cabinets$Users$List$Model$filterByUser, model, search),
						$elm$core$Platform$Cmd$none);
				} else {
					var subMsg = filterBy.a;
					return A2(
						$elm$core$Tuple$mapSecond,
						$elm$core$Platform$Cmd$map(
							A2($elm$core$Basics$composeR, $author$project$Components$Cabinets$Users$List$Update$ByActivationState, $author$project$Components$Cabinets$Users$List$Update$Filters)),
						A2($author$project$Components$Cabinets$Users$List$Model$filterByActivationState, model, subMsg));
				}
		}
	});
var $author$project$Components$Cabinets$Users$New$Update$CheckAllReq = function (a) {
	return {$: 22, a: a};
};
var $author$project$Components$Cabinets$Users$New$Update$ComponentDone = function (a) {
	return {$: 12, a: a};
};
var $author$project$Components$Cabinets$Users$New$Update$CreationCancelled = function (a) {
	return {$: 13, a: a};
};
var $author$project$Components$Cabinets$Users$New$Update$DeactivateAction = F2(
	function (a, b) {
		return {$: 15, a: a, b: b};
	});
var $author$project$Components$Drawer$ConfirmationDrawer$ExpectedResult = $elm$core$Basics$identity;
var $author$project$Components$Cabinets$Users$New$Update$FetchUpdateUserExternalSync = F2(
	function (a, b) {
		return {$: 20, a: a, b: b};
	});
var $author$project$Components$Cabinets$Users$New$Update$FetchedAvailableSyncUsers = function (a) {
	return {$: 18, a: a};
};
var $author$project$Components$Cabinets$Users$New$Model$ReadyToSave = 1;
var $author$project$Components$Cabinets$Users$New$Model$SavingUser = 2;
var $author$project$Components$Cabinets$Users$New$Update$UpdateUserExternalSyncFetched = function (a) {
	return {$: 21, a: a};
};
var $author$project$Components$Cabinets$Users$New$Update$UserCreated = function (a) {
	return {$: 10, a: a};
};
var $author$project$Routes$ComponentNavigation$UserItem = F2(
	function (uid, cabinetUid) {
		return {f_: cabinetUid, kx: uid};
	});
var $author$project$Components$Cabinets$Users$New$Update$fromActDom2APIRight = function (r) {
	switch (r) {
		case 0:
			return $author$project$Components$Cabinets$APIModel$ManageActivites(0);
		case 1:
			return $author$project$Components$Cabinets$APIModel$ManageActivites(1);
		case 2:
			return $author$project$Components$Cabinets$APIModel$ManageActivites(2);
		default:
			return $author$project$Components$Cabinets$APIModel$ManageActivites(3);
	}
};
var $author$project$Components$Cabinets$Users$New$Update$fromS2ActivitesRight = function (s) {
	switch (s) {
		case 'SeeClients':
			return $elm$core$Maybe$Just(0);
		case 'HandleProduction':
			return $elm$core$Maybe$Just(1);
		case 'SeeChiffreAffaire':
			return $elm$core$Maybe$Just(2);
		case 'SeeFraCif':
			return $elm$core$Maybe$Just(3);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Cabinets$Users$New$Update$activitesRights2Json = function (model) {
	return A2(
		$elm$core$List$map,
		$author$project$Components$Cabinets$Users$New$Update$fromActDom2APIRight,
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			A2(
				$elm$core$List$map,
				$author$project$Components$Cabinets$Users$New$Update$fromS2ActivitesRight,
				A2(
					$elm$core$List$map,
					$elm$core$Tuple$first,
					$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model)))));
};
var $author$project$Components$Cabinets$APIModel$authorizationAPIRightStringEncoder = function (right) {
	switch (right.$) {
		case 1:
			switch (right.a) {
				case 0:
					var _v1 = right.a;
					return 'ManageActivites.SeeClients';
				case 1:
					var _v2 = right.a;
					return 'ManageActivites.HandleProduction';
				case 2:
					var _v3 = right.a;
					return 'ManageActivites.SeeChiffreAffaire';
				default:
					var _v4 = right.a;
					return 'ManageActivites.SeeFraCif';
			}
		case 0:
			if (!right.a) {
				var _v5 = right.a;
				return 'ManageCabinets.HandleCabinet';
			} else {
				var _v6 = right.a;
				return 'ManageCabinets.HandleUser';
			}
		default:
			switch (right.a) {
				case 0:
					var _v7 = right.a;
					return 'ManageClients.SeeClients';
				case 1:
					var _v8 = right.a;
					return 'ManageClients.UpdateClients';
				default:
					var _v9 = right.a;
					return 'ManageClients.DeleteClients';
			}
	}
};
var $author$project$Components$Cabinets$Users$New$Update$authorizationAPIRightJsonEncoder = function (r) {
	return $elm$json$Json$Encode$string(
		$author$project$Components$Cabinets$APIModel$authorizationAPIRightStringEncoder(r));
};
var $author$project$Components$Cabinets$Users$New$Update$fromClientDom2APIRight = function (r) {
	switch (r) {
		case 0:
			return $author$project$Components$Cabinets$APIModel$ManageClients(0);
		case 1:
			return $author$project$Components$Cabinets$APIModel$ManageClients(1);
		default:
			return $author$project$Components$Cabinets$APIModel$ManageClients(2);
	}
};
var $author$project$Components$Cabinets$Users$New$Update$fromS2ClientRight = function (s) {
	switch (s) {
		case 'SeeClients':
			return $elm$core$Maybe$Just(0);
		case 'UpdateClients':
			return $elm$core$Maybe$Just(1);
		case 'DeleteClients':
			return $elm$core$Maybe$Just(2);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Cabinets$Users$New$Update$clientsRights2Json = function (model) {
	return A2(
		$elm$core$List$map,
		$author$project$Components$Cabinets$Users$New$Update$fromClientDom2APIRight,
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			A2(
				$elm$core$List$map,
				$author$project$Components$Cabinets$Users$New$Update$fromS2ClientRight,
				A2(
					$elm$core$List$map,
					$elm$core$Tuple$first,
					$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model)))));
};
var $author$project$Components$Cabinets$APIModel$filterAPIRightJsonEncoder = function (right) {
	switch (right.$) {
		case 0:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'FilterByMyUid',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 1:
			var userUids = right.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'FilterByUser',
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'users',
									A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, userUids))
								])))
					]));
		case 2:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'AllInCabinet',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'CanSeeNothing',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
	}
};
var $author$project$Components$Cabinets$Users$New$Update$role2Json = function (role) {
	switch (role) {
		case 0:
			return $elm$json$Json$Encode$string('Dirigeant');
		case 1:
			return $elm$json$Json$Encode$string('Conseiller');
		default:
			return $elm$json$Json$Encode$string('Assistant');
	}
};
var $author$project$Components$Cabinets$Users$New$Update$fromS2UserRight = function (s) {
	switch (s) {
		case 'HandleCabinet':
			return $elm$core$Maybe$Just(0);
		case 'HandleUser':
			return $elm$core$Maybe$Just(1);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Cabinets$Users$New$Update$fromUserDom2APIRight = function (r) {
	if (!r) {
		return $author$project$Components$Cabinets$APIModel$ManageCabinet(0);
	} else {
		return $author$project$Components$Cabinets$APIModel$ManageCabinet(1);
	}
};
var $author$project$Components$Cabinets$Users$New$Update$userRights2Json = function (model) {
	return A2(
		$elm$core$List$map,
		$author$project$Components$Cabinets$Users$New$Update$fromUserDom2APIRight,
		A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			A2(
				$elm$core$List$map,
				$author$project$Components$Cabinets$Users$New$Update$fromS2UserRight,
				A2(
					$elm$core$List$map,
					$elm$core$Tuple$first,
					$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model)))));
};
var $author$project$Components$Cabinets$Users$New$Update$buildJsonBody = F2(
	function (model, role) {
		var jsonRights = A2(
			$elm$json$Json$Encode$list,
			$author$project$Components$Cabinets$Users$New$Update$authorizationAPIRightJsonEncoder,
			_Utils_ap(
				$author$project$Components$Cabinets$Users$New$Update$activitesRights2Json(model.c2),
				_Utils_ap(
					$author$project$Components$Cabinets$Users$New$Update$userRights2Json(model.c3),
					$author$project$Components$Cabinets$Users$New$Update$clientsRights2Json(model.c4))));
		var jsonFilterRight = $author$project$Components$Cabinets$APIModel$filterAPIRightJsonEncoder(
			function () {
				switch (role) {
					case 0:
						return $author$project$Components$Cabinets$APIModel$AllInCabinet;
					case 1:
						return $author$project$Components$Cabinets$APIModel$FilterByMyUid;
					default:
						var _v1 = model.eA;
						if (!_v1.$) {
							if (!_v1.a.$) {
								return $author$project$Components$Cabinets$APIModel$FilterByUser(
									A2(
										$elm$core$List$map,
										$elm$core$Tuple$first,
										$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.d4)));
							} else {
								var _v2 = _v1.a;
								return $author$project$Components$Cabinets$APIModel$AllInCabinet;
							}
						} else {
							return $author$project$Components$Cabinets$APIModel$CanSeeNothing;
						}
				}
			}());
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'login',
					$elm$json$Json$Encode$string(model.nh)),
					_Utils_Tuple2(
					'firstName',
					$elm$json$Json$Encode$string(model.ms)),
					_Utils_Tuple2(
					'lastName',
					$elm$json$Json$Encode$string(model.na)),
					_Utils_Tuple2(
					'role',
					$author$project$Components$Cabinets$Users$New$Update$role2Json(role)),
					_Utils_Tuple2('authorizationRights', jsonRights),
					_Utils_Tuple2('filterRight', jsonFilterRight)
				]));
	});
var $author$project$Components$Cabinets$Users$New$Model$isReadyToCreate = function (model) {
	return A2($elm$core$String$contains, '@', model.nh) && (($elm$core$String$length(model.ms) > 0) && (($elm$core$String$length(model.na) > 0) && ($elm_community$maybe_extra$Maybe$Extra$isJust(model.oo) && function () {
		if (_Utils_eq(
			model.oo,
			$elm$core$Maybe$Just(2))) {
			var _v0 = model.eA;
			if (!_v0.$) {
				if (_v0.a.$ === 1) {
					var _v1 = _v0.a;
					return true;
				} else {
					return !$elm$core$List$isEmpty(
						$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.d4));
				}
			} else {
				return false;
			}
		} else {
			return true;
		}
	}())));
};
var $author$project$Components$Cabinets$Users$New$Model$calculSaveState = function (model) {
	return _Utils_update(
		model,
		{
			oq: $author$project$Components$Cabinets$Users$New$Model$isReadyToCreate(model) ? 1 : model.oq
		});
};
var $author$project$Components$Drawer$ConfirmationDrawer$OnConfirmCallback = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Drawer$ConfirmationDrawer$callback = $author$project$Components$Drawer$ConfirmationDrawer$OnConfirmCallback;
var $elm$core$Maybe$map4 = F5(
	function (func, ma, mb, mc, md) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					if (md.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var d = md.a;
						return $elm$core$Maybe$Just(
							A4(func, a, b, c, d));
					}
				}
			}
		}
	});
var $author$project$Components$Cabinets$Users$New$Update$dataReadyToCreate = F2(
	function (createUserReq, model) {
		var ln = ($elm$core$String$length(model.na) > 0) ? $elm$core$Maybe$Just(model.na) : $elm$core$Maybe$Nothing;
		var l = ($elm$core$String$length(model.nh) > 0) ? $elm$core$Maybe$Just(model.nh) : $elm$core$Maybe$Nothing;
		var fn = ($elm$core$String$length(model.ms) > 0) ? $elm$core$Maybe$Just(model.ms) : $elm$core$Maybe$Nothing;
		var effect = A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A5(
				$elm$core$Maybe$map4,
				F4(
					function (_v0, _v1, _v2, role) {
						return createUserReq(role);
					}),
				fn,
				ln,
				l,
				model.oo));
		return effect;
	});
var $author$project$Components$Cabinets$Users$APIModel$TempContainerAPIError = function (error) {
	return {es: error};
};
var $author$project$Components$Cabinets$Users$APIModel$ImpossibleToDeactivateConseillerWithClients = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Cabinets$Users$APIModel$OnlyCabinetUserCanBeUpdated = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Cabinets$Users$APIModel$ServerError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Cabinets$Users$APIModel$TraceId_UserId_record = F2(
	function (traceId, infos) {
		return {m0: infos, fn: traceId};
	});
var $author$project$Components$Cabinets$Users$APIModel$UserIsNotFound = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$Users$APIModel$UserStatusTransitionInvalid = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Cabinets$Users$APIModel$deactivateUserAPIErrorJsonDecoderS = function (discriminator) {
	var traceId_userId_decode = A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'infos',
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'userUid',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed(
				function (x) {
					return {kB: x};
				})),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'traceId',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$Users$APIModel$TraceId_UserId_record)));
	switch (discriminator) {
		case 'ServerError':
			return A2(
				$elm$json$Json$Decode$map,
				$author$project$Components$Cabinets$Users$APIModel$ServerError,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'traceId',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed(
						function (id) {
							return {fn: id};
						})));
		case 'UserIsNotFound':
			return A2($elm$json$Json$Decode$map, $author$project$Components$Cabinets$Users$APIModel$UserIsNotFound, traceId_userId_decode);
		case 'OnlyCabinetUserCanBeUpdated':
			return A2($elm$json$Json$Decode$map, $author$project$Components$Cabinets$Users$APIModel$OnlyCabinetUserCanBeUpdated, traceId_userId_decode);
		case 'UserStatusTransitionInvalid':
			return A2(
				$elm$json$Json$Decode$map,
				$author$project$Components$Cabinets$Users$APIModel$UserStatusTransitionInvalid,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'infos',
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'to',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'from',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'userUid',
								$elm$json$Json$Decode$string,
								$elm$json$Json$Decode$succeed(
									F3(
										function (u, f, t) {
											return {my: f, oO: t, kB: u};
										}))))),
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'traceId',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed(
							F2(
								function (x, y) {
									return {m0: y, fn: x};
								})))));
		case 'ImpossibleToDeactivateConseillerWithClients':
			return A2($elm$json$Json$Decode$map, $author$project$Components$Cabinets$Users$APIModel$ImpossibleToDeactivateConseillerWithClients, traceId_userId_decode);
		default:
			return $elm$json$Json$Decode$fail('unknown DeactivateUserAPIError value: ' + discriminator);
	}
};
var $PerformanceIMMO$elm_utils$Perfimmo$Json$Decoder$subDecoderByType = F2(
	function (discriminator, subDecoder) {
		return A2(
			$elm$json$Json$Decode$andThen,
			subDecoder,
			A2($elm$json$Json$Decode$field, discriminator, $elm$json$Json$Decode$string));
	});
var $author$project$Components$Cabinets$Users$APIModel$deactivateUserAPIErrorJsonDecoder = A2(
	$elm$json$Json$Decode$map,
	function ($) {
		return $.es;
	},
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'error',
		A2($PerformanceIMMO$elm_utils$Perfimmo$Json$Decoder$subDecoderByType, 'errorType', $author$project$Components$Cabinets$Users$APIModel$deactivateUserAPIErrorJsonDecoderS),
		$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$Users$APIModel$TempContainerAPIError)));
var $author$project$Components$Cabinets$Users$New$Update$externalSyncBody = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$head,
	$elm$core$Maybe$map(
		function (_v0) {
			var v = _v0.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'userO2S',
						$elm$json$Json$Encode$string(v))
					]));
		}));
var $author$project$Components$Cabinets$Users$New$Model$fromSToFilterRight = function (key) {
	switch (key) {
		case 'LinkedToUser':
			return $elm$core$Maybe$Just(
				$author$project$Components$Cabinets$Model$LinkedToUser(_List_Nil));
		case 'LikendToDirigeant':
			return $elm$core$Maybe$Just($author$project$Components$Cabinets$Model$LinkedToDirigeant);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Cabinets$APIModel$IdLabel = F2(
	function (id, label) {
		return {hB: id, m9: label};
	});
var $author$project$Components$Cabinets$APIModel$idLabelDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'label',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Components$Cabinets$APIModel$IdLabel)));
var $author$project$Components$Drawer$Model$Opened = 0;
var $author$project$Components$Drawer$ConfirmationDrawer$init = F4(
	function (link, body, failureMessage, _v0) {
		var expectedResult = _v0;
		return _Utils_Tuple2(
			{
				be: $elm$core$Maybe$Nothing,
				ec: body,
				ed: failureMessage,
				ee: link,
				ep: {ow: 0},
				eu: expectedResult,
				aG: false
			},
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Components$Cabinets$Users$New$Model$PreconfiguredRights = F3(
	function (droitCabinet, droitActivites, droitClients) {
		return {gP: droitActivites, gQ: droitCabinet, gR: droitClients};
	});
var $author$project$Components$Cabinets$Users$New$Model$calculPreconfiguredRights = function (role) {
	var toTuple_U = function (r) {
		return _Utils_Tuple2(
			$author$project$Components$Cabinets$Users$New$Model$userRights2Key(r),
			$author$project$Components$Cabinets$Users$New$Model$showUserRights(r));
	};
	var toTuple_C = function (r) {
		return _Utils_Tuple2(
			$author$project$Components$Cabinets$Users$New$Model$clientsRights2Key(r),
			$author$project$Components$Cabinets$Users$New$Model$showClientsRights(r));
	};
	var toTuple_A = function (r) {
		return _Utils_Tuple2(
			$author$project$Components$Cabinets$Users$New$Model$activitesRights2Key(r),
			$author$project$Components$Cabinets$Users$New$Model$showActivitesRights(r));
	};
	switch (role) {
		case 0:
			return A3(
				$author$project$Components$Cabinets$Users$New$Model$PreconfiguredRights,
				A2(
					$elm$core$List$map,
					toTuple_U,
					_List_fromArray(
						[0, 1])),
				A2(
					$elm$core$List$map,
					toTuple_A,
					_List_fromArray(
						[0, 1, 2, 3])),
				A2(
					$elm$core$List$map,
					toTuple_C,
					_List_fromArray(
						[0, 1, 2])));
		case 1:
			return A3(
				$author$project$Components$Cabinets$Users$New$Model$PreconfiguredRights,
				A2($elm$core$List$map, toTuple_U, _List_Nil),
				A2(
					$elm$core$List$map,
					toTuple_A,
					_List_fromArray(
						[0, 1, 2])),
				A2(
					$elm$core$List$map,
					toTuple_C,
					_List_fromArray(
						[0, 1])));
		default:
			return A3(
				$author$project$Components$Cabinets$Users$New$Model$PreconfiguredRights,
				A2($elm$core$List$map, toTuple_U, _List_Nil),
				A2(
					$elm$core$List$map,
					toTuple_A,
					_List_fromArray(
						[0, 1, 2, 3])),
				A2(
					$elm$core$List$map,
					toTuple_C,
					_List_fromArray(
						[0, 1])));
	}
};
var $elm_community$maybe_extra$Maybe$Extra$isNothing = function (m) {
	if (m.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Components$Cabinets$Users$New$Model$setRole = F2(
	function (model, role) {
		var preConfigRights = function (r) {
			return $author$project$Components$Cabinets$Users$New$Model$calculPreconfiguredRights(r);
		};
		var updateRights = F2(
			function (m, r) {
				return _Utils_update(
					m,
					{
						c2: A2(
							$author$project$Components$Cabinets$Users$New$Model$populateMultiSelect,
							preConfigRights(r).gP,
							m.c2),
						c3: A2(
							$author$project$Components$Cabinets$Users$New$Model$populateMultiSelect,
							preConfigRights(r).gQ,
							m.c3),
						c4: A2(
							$author$project$Components$Cabinets$Users$New$Model$populateMultiSelect,
							preConfigRights(r).gR,
							m.c4)
					});
			});
		var editRole = _Utils_update(
			model,
			{oo: role});
		var result = $elm_community$maybe_extra$Maybe$Extra$isNothing(model.er) ? A2(
			$elm$core$Maybe$withDefault,
			editRole,
			A2(
				$elm$core$Maybe$map,
				updateRights(editRole),
				role)) : editRole;
		return result;
	});
var $author$project$Components$Cabinets$Users$New$Model$string2Role = function (s) {
	switch (s) {
		case 'Dirigeant':
			return $elm$core$Maybe$Just(0);
		case 'Conseiller':
			return $elm$core$Maybe$Just(1);
		case 'Assistant':
			return $elm$core$Maybe$Just(2);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Drawer$ConfirmationDrawer$update = F2(
	function (model, confirmationMsg) {
		update:
		while (true) {
			switch (confirmationMsg.$) {
				case 0:
					return function (updatedModel) {
						return A2($elm$core$Tuple$pair, updatedModel, $elm$core$Platform$Cmd$none);
					}(
						A2(
							$elm$core$Maybe$map,
							function (m) {
								return _Utils_update(
									m,
									{
										be: $elm$core$Maybe$Nothing,
										ep: {ow: 1},
										aG: false
									});
							},
							model));
				case 2:
					var modelSetAsLoading = A2(
						$elm$core$Maybe$map,
						function (m) {
							return _Utils_update(
								m,
								{aG: true});
						},
						model);
					var effect = A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							function (m) {
								return A3($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest, m.ee, m.ec, m.eu);
							},
							model));
					return _Utils_Tuple2(modelSetAsLoading, effect);
				case 1:
					var drawerMsg = confirmationMsg.a;
					if (!drawerMsg.$) {
						return function (updatedModel) {
							return A2($elm$core$Tuple$pair, updatedModel, $elm$core$Platform$Cmd$none);
						}(
							A2(
								$elm$core$Maybe$map,
								function (m) {
									return _Utils_update(
										m,
										{
											be: $elm$core$Maybe$Nothing,
											ep: {ow: 1},
											aG: false
										});
								},
								model));
					} else {
						var msg_ = drawerMsg.a;
						var $temp$model = model,
							$temp$confirmationMsg = msg_;
						model = $temp$model;
						confirmationMsg = $temp$confirmationMsg;
						continue update;
					}
				default:
					if (!confirmationMsg.a.$) {
						return _Utils_Tuple2(
							A2(
								$elm$core$Maybe$map,
								function (m) {
									return _Utils_update(
										m,
										{aG: false});
								},
								model),
							$elm$core$Platform$Cmd$none);
					} else {
						return function (updatedModel) {
							return A2($elm$core$Tuple$pair, updatedModel, $elm$core$Platform$Cmd$none);
						}(
							A2(
								$elm$core$Maybe$map,
								function (m) {
									return _Utils_update(
										m,
										{
											be: $elm$core$Maybe$Just(m.ed),
											aG: false
										});
								},
								model));
					}
			}
		}
	});
var $author$project$Components$Cabinets$Users$New$Update$update = F2(
	function (model, msg) {
		switch (msg.$) {
			case 0:
				var login = msg.a;
				return _Utils_Tuple2(
					$author$project$Components$Cabinets$Users$New$Model$calculSaveState(
						_Utils_update(
							model,
							{nh: login})),
					$elm$core$Platform$Cmd$none);
			case 1:
				var fn = msg.a;
				return _Utils_Tuple2(
					$author$project$Components$Cabinets$Users$New$Model$calculSaveState(
						_Utils_update(
							model,
							{ms: fn})),
					$elm$core$Platform$Cmd$none);
			case 2:
				var ln = msg.a;
				return _Utils_Tuple2(
					$author$project$Components$Cabinets$Users$New$Model$calculSaveState(
						_Utils_update(
							model,
							{na: ln})),
					$elm$core$Platform$Cmd$none);
			case 3:
				var raw = msg.a;
				return _Utils_Tuple2(
					$author$project$Components$Cabinets$Users$New$Model$calculSaveState(
						A2(
							$author$project$Components$Cabinets$Users$New$Model$setRole,
							model,
							$author$project$Components$Cabinets$Users$New$Model$string2Role(raw))),
					$elm$core$Platform$Cmd$none);
			case 4:
				var raw = msg.a;
				return _Utils_Tuple2(
					$author$project$Components$Cabinets$Users$New$Model$calculSaveState(
						_Utils_update(
							model,
							{
								eA: $author$project$Components$Cabinets$Users$New$Model$fromSToFilterRight(raw)
							})),
					$elm$core$Platform$Cmd$none);
			case 5:
				var subMsg = msg.a;
				var _v1 = A2($inkuzmin$elm_multiselect$Multiselect$update, subMsg, model.d4);
				var newModel = _v1.a;
				var newCmd = _v1.b;
				return _Utils_Tuple2(
					$author$project$Components$Cabinets$Users$New$Model$calculSaveState(
						_Utils_update(
							model,
							{d4: newModel})),
					A2($elm$core$Platform$Cmd$map, $author$project$Components$Cabinets$Users$New$Update$SetUsersLinkedToAssistant, newCmd));
			case 6:
				var subMsg = msg.a;
				var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, subMsg, model.c3);
				var newModel = _v2.a;
				var newCmd = _v2.b;
				return _Utils_Tuple2(
					$author$project$Components$Cabinets$Users$New$Model$calculSaveState(
						_Utils_update(
							model,
							{c3: newModel})),
					A2($elm$core$Platform$Cmd$map, $author$project$Components$Cabinets$Users$New$Update$SetCabinetRights, newCmd));
			case 7:
				var subMsg = msg.a;
				var _v3 = A2($inkuzmin$elm_multiselect$Multiselect$update, subMsg, model.c2);
				var newModel = _v3.a;
				var newCmd = _v3.b;
				return _Utils_Tuple2(
					$author$project$Components$Cabinets$Users$New$Model$calculSaveState(
						_Utils_update(
							model,
							{c2: newModel})),
					A2($elm$core$Platform$Cmd$map, $author$project$Components$Cabinets$Users$New$Update$SetActivitesRights, newCmd));
			case 8:
				var subMsg = msg.a;
				var _v4 = A2($inkuzmin$elm_multiselect$Multiselect$update, subMsg, model.c4);
				var newModel = _v4.a;
				var newCmd = _v4.b;
				return _Utils_Tuple2(
					$author$project$Components$Cabinets$Users$New$Model$calculSaveState(
						_Utils_update(
							model,
							{c4: newModel})),
					A2($elm$core$Platform$Cmd$map, $author$project$Components$Cabinets$Users$New$Update$SetClientsRights, newCmd));
			case 9:
				var updateUserExternalSync = A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd,
						A3(
							$elm$core$Maybe$map2,
							$author$project$Components$Cabinets$Users$New$Update$FetchUpdateUserExternalSync,
							$author$project$Components$Cabinets$Users$New$Update$externalSyncBody(
								$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.fO)),
							A2(
								$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
								function ($) {
									return $.oZ;
								},
								model.er))));
				var createUserReq = function (role) {
					return A3(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
						model.jD,
						$elm$http$Http$jsonBody(
							A2($author$project$Components$Cabinets$Users$New$Update$buildJsonBody, model, role)),
						$elm$http$Http$expectJson($author$project$Components$Cabinets$Users$New$Update$UserCreated)($PerformanceIMMO$elm_utils$Perfimmo$Http$RestAPIUtils$crudResultJsonDecoder));
				};
				var createCabinetCmd = A2($author$project$Components$Cabinets$Users$New$Update$dataReadyToCreate, createUserReq, model);
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{oq: 2}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[createCabinetCmd, updateUserExternalSync])));
			case 10:
				if (!msg.a.$) {
					var result = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{oq: 0}),
						$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
							$author$project$Components$Cabinets$Users$New$Update$CheckAllReq(
								A2($author$project$Routes$ComponentNavigation$UserItem, result.kx, model.fZ.kx))));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{oq: 1}),
						$elm$core$Platform$Cmd$none);
				}
			case 11:
				return _Utils_Tuple2(
					model,
					$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
						$author$project$Components$Cabinets$Users$New$Update$CreationCancelled(
							$author$project$Routes$ComponentNavigation$CabinetItem(model.fZ.kx))));
			case 12:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 13:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 16:
				var id_ = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{gq: id_}),
					$elm$core$Platform$Cmd$none);
			case 17:
				var l = msg.a;
				var s = msg.b;
				var cmd = A3(
					$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
					_Utils_update(
						l,
						{hA: l.hA + ('?cabinet=' + s)}),
					$elm$http$Http$emptyBody,
					A2(
						$elm$http$Http$expectJson,
						$author$project$Components$Cabinets$Users$New$Update$FetchedAvailableSyncUsers,
						$PerformanceIMMO$elm_utils$Perfimmo$Http$RestAPIUtils$seqAPIResultJsonDecoder($author$project$Components$Cabinets$APIModel$idLabelDecoder)));
				return _Utils_Tuple2(model, cmd);
			case 18:
				if (!msg.a.$) {
					var result = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								fO: A3(
									$inkuzmin$elm_multiselect$Multiselect$populateValues,
									model.fO,
									A2(
										$elm$core$List$map,
										function (r) {
											return _Utils_Tuple2(r.hB, r.m9);
										},
										result.ok),
									_List_Nil)
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 19:
				var subMsg = msg.a;
				var _v5 = A2($inkuzmin$elm_multiselect$Multiselect$update, subMsg, model.fO);
				var newSubModel = _v5.a;
				var newSubCmd = _v5.b;
				var newNewSubModel = A3(
					$inkuzmin$elm_multiselect$Multiselect$populateValues,
					newSubModel,
					$inkuzmin$elm_multiselect$Multiselect$getValues(newSubModel),
					$elm_community$maybe_extra$Maybe$Extra$toList(
						$elm$core$List$head(
							$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(newSubModel))));
				return _Utils_Tuple2(
					$author$project$Components$Cabinets$Users$New$Model$calculSaveState(
						_Utils_update(
							model,
							{fO: newNewSubModel})),
					A2($elm$core$Platform$Cmd$map, $author$project$Components$Cabinets$Users$New$Update$SetAvailableSyncUsers, newSubCmd));
			case 20:
				var v = msg.a;
				var l = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jC: 2}),
					A3(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
						l,
						$elm$http$Http$jsonBody(v),
						A2($elm$http$Http$expectJson, $author$project$Components$Cabinets$Users$New$Update$UpdateUserExternalSyncFetched, $PerformanceIMMO$elm_utils$Perfimmo$Http$RestAPIUtils$crudResultJsonDecoder)));
			case 21:
				if (!msg.a.$) {
					var result = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								er: A2(
									$elm$core$Maybe$map,
									function (u) {
										return _Utils_update(
											u,
											{m5: true, oZ: $elm$core$Maybe$Nothing});
									},
									model.er),
								jC: 0
							}),
						$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
							$author$project$Components$Cabinets$Users$New$Update$CheckAllReq(
								A2($author$project$Routes$ComponentNavigation$UserItem, result.kx, model.fZ.kx))));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{jC: 0}),
						$elm$core$Platform$Cmd$none);
				}
			case 22:
				var result = msg.a;
				var componentDone = $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
					$author$project$Components$Cabinets$Users$New$Update$ComponentDone(
						A2($author$project$Routes$ComponentNavigation$UserItem, result.kx, model.fZ.kx)));
				var _v6 = _Utils_Tuple2(model.oq, model.jC);
				if ((!_v6.a) && (!_v6.b)) {
					var _v7 = _v6.a;
					var _v8 = _v6.b;
					return _Utils_Tuple2(model, componentDone);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 14:
				var item = msg.a;
				var link = msg.b;
				var responseDecoder = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$ResponseDecoder, $author$project$Components$Cabinets$Users$APIModel$deactivateUserAPIErrorJsonDecoder, $PerformanceIMMO$elm_utils$Perfimmo$Http$RestAPIUtils$crudResultJsonDecoder);
				var confirmDrawer = A4(
					$author$project$Components$Drawer$ConfirmationDrawer$init,
					link,
					$elm$http$Http$jsonBody(
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'DeactivateUser',
									$elm$json$Json$Encode$object(_List_Nil))
								]))),
					'Erreur lors de la désactivation',
					A2($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$expectJsonResponse2, $author$project$Components$Drawer$ConfirmationDrawer$callback, responseDecoder));
				return A3(
					$elm$core$Tuple$mapBoth,
					function (dm) {
						return _Utils_update(
							model,
							{
								gD: $elm$core$Maybe$Just(dm)
							});
					},
					$elm$core$Platform$Cmd$map(
						$author$project$Components$Cabinets$Users$New$Update$DeactivateAction(item)),
					confirmDrawer);
			default:
				var item = msg.a;
				var confirmationMsg = msg.b;
				return A3(
					$elm$core$Tuple$mapBoth,
					function (newDeleteModel) {
						return _Utils_update(
							model,
							{gD: newDeleteModel});
					},
					$elm$core$Platform$Cmd$map(
						$author$project$Components$Cabinets$Users$New$Update$DeactivateAction(item)),
					A2($author$project$Components$Drawer$ConfirmationDrawer$update, model.gD, confirmationMsg));
		}
	});
var $author$project$Components$Clients$Detail$Action$ClientFetched = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$Action$ClientUpserted = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Components$Clients$Detail$Action$DeleteAction = function (a) {
	return {$: 9, a: a};
};
var $author$project$Components$Clients$Detail$SaveState$DisplayError = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Clients$Detail$SaveState$ReadyToSave = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$SaveState$SavingClient = {$: 4};
var $author$project$Components$Clients$Detail$Action$UpsertClient = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Components$Clients$Detail$SaveState$ErrorInForm = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$APIModel$Client = F7(
	function (uid, nature, contact, profilInvestisseur, profilExtraFinancier, conseillers, links) {
		return {ef: conseillers, cY: contact, nf: links, ic: nature, jg: profilExtraFinancier, jh: profilInvestisseur, kx: uid};
	});
var $author$project$Components$Clients$Detail$ModelForm$FormError$MissingFieldError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$APIModel$PersonneMorale = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$APIModel$PersonnePhysique = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$ModelForm$FormError$ShouldHaveAtLeastOneConseillerToHandleThisClient = {$: 3};
var $author$project$Components$Clients$APIModel$Contact = F3(
	function (email, telephones, adresseFiscale) {
		return {fE: adresseFiscale, gU: email, kf: telephones};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactAdresse = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactF = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Adresse_1 = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CodePostal = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Pays = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Ville = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$adresseAllFieldsMissing = _List_fromArray(
	[
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Adresse_1(''),
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CodePostal(''),
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Ville(''),
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Pays('')
	]);
var $author$project$Components$Clients$APIModel$Adresse = F5(
	function (addr1, addr2, codePostal, ville, pays) {
		return {fB: addr1, fC: addr2, gc: codePostal, dq: pays, d_: ville};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$adresseToApi = function (form) {
	var ville = A2(
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$nonEmptyStringE,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Ville(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.d_));
	var pays = A2(
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$nonEmptyStringE,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Pays(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.dq));
	var emptyAdresseIsValid = $author$project$Components$Clients$Detail$UpdateForm$FormValidator$isEmptyString(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.fB)) && ($author$project$Components$Clients$Detail$UpdateForm$FormValidator$isEmptyString(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.fC)) && ($author$project$Components$Clients$Detail$UpdateForm$FormValidator$isEmptyString(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.gc)) && ($author$project$Components$Clients$Detail$UpdateForm$FormValidator$isEmptyString(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.d_)) && $author$project$Components$Clients$Detail$UpdateForm$FormValidator$isEmptyString(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.dq)))));
	var codePostal = A2(
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$nonEmptyStringE,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CodePostal(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.gc));
	var addr1 = A2(
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$nonEmptyStringE,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Adresse_1(''),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.fB));
	var result = A5(
		$author$project$Components$Clients$ResultUtils$map4C,
		F4(
			function (add1, code, v, p) {
				return $elm$core$Maybe$Just(
					A5(
						$author$project$Components$Clients$APIModel$Adresse,
						add1,
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.fC),
						code,
						v,
						p));
			}),
		addr1,
		codePostal,
		ville,
		pays);
	return emptyAdresseIsValid ? $elm$core$Result$Ok($elm$core$Maybe$Nothing) : result;
};
var $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$getContactPhoneList = function (form) {
	return A2(
		$elm$core$List$filterMap,
		$elm$core$Basics$identity,
		_Utils_ap(
			_List_fromArray(
				[
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.fj)
				]),
			_Utils_ap(
				_List_fromArray(
					[
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.fk)
					]),
				_List_fromArray(
					[
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.fl)
					]))));
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$contactToApi = function (form) {
	var contactPhones = $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$getContactPhoneList(form.kf);
	var adresse = function () {
		var _v0 = $author$project$Components$Clients$Detail$UpdateForm$FormValidator$adresseToApi(form.fE);
		if (!_v0.$) {
			if (!_v0.a.$) {
				var v = _v0.a.a;
				return $elm$core$Result$Ok(v);
			} else {
				var _v1 = _v0.a;
				return $elm$core$Result$Err(
					A2(
						$elm$core$List$map,
						A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactAdresse, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactF),
						$author$project$Components$Clients$Detail$UpdateForm$FormValidator$adresseAllFieldsMissing));
			}
		} else {
			var errors = _v0.a;
			return $elm$core$Result$Err(
				A2(
					$elm$core$List$map,
					A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactAdresse, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactF),
					errors));
		}
	}();
	return A2(
		$elm$core$Result$map,
		function (add) {
			return A3(
				$author$project$Components$Clients$APIModel$Contact,
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.gU),
				contactPhones,
				add);
		},
		adresse);
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$BilanF = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CompteResultatF = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$FiscaliteF = function (a) {
	return {$: 8, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$FormeJuridique = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PM_Nom = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$APIModel$PersonneMoraleParams = function (nom) {
	return function (formeJuridique) {
		return function (siren) {
			return function (villeTribunalCommerce) {
				return function (capitalSocial) {
					return function (typologie) {
						return function (compteResultat) {
							return function (bilan) {
								return function (fiscalite) {
									return function (representantsLegaux) {
										return function (evaluationRisqueLCBFT) {
											return {fS: bilan, f2: capitalSocial, ge: compteResultat, c5: evaluationRisqueLCBFT, db: fiscalite, hd: formeJuridique, dl: nom, dA: representantsLegaux, jR: siren, dY: typologie, kG: villeTribunalCommerce};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Clients$Detail$ModelForm$FormError$PersonneMoraleShouldHasAtLeastOneRepresentantLegal = {$: 1};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Siren = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$VilleTribunalCommerce = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$fromFormeJuridiqueToCodeModified = function (forme) {
	return $elm$core$List$head(
		A2(
			$elm$core$List$filterMap,
			function (_v0) {
				var code = _v0.a;
				var p = _v0.b;
				return _Utils_eq(p, forme) ? $elm$core$Maybe$Just(code) : $elm$core$Maybe$Nothing;
			},
			$author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$modifiedFormeJuridiqueRef));
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalAdresse = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalFonction = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalIdentite = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalNaissance = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalPieceIdentite = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Components$Clients$APIModel$RepresentantLegal = function (identite) {
	return function (naissance) {
		return function (pieceIdentite) {
			return function (nationalite) {
				return function (autreNationalites) {
					return function (usPerson) {
						return function (adresse) {
							return function (mail) {
								return function (fonction) {
									return function (pseudoId) {
										return {fD: adresse, bf: autreNationalites, g9: fonction, aD: identite, h_: mail, aI: naissance, bD: nationalite, du: pieceIdentite, jj: pseudoId, bV: usPerson};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$representantsLegalToApi = F3(
	function (isPersonneMorale, n, form) {
		var no = n + 1;
		var pieceIdentite = A2(
			$elm$core$Result$mapError,
			$elm$core$List$map(
				$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalPieceIdentite(no)),
			$author$project$Components$Clients$Detail$UpdateForm$FormValidator$pieceIdentiteToApi(
				$author$project$Perfimmo$Form$FormModel$getBlock(form.du)));
		var naissance = $elm_community$result_extra$Result$Extra$join(
			A2(
				$elm$core$Result$map,
				$elm$core$Result$fromMaybe(
					A2(
						$elm$core$List$map,
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalNaissance(no),
						$author$project$Components$Clients$Detail$UpdateForm$FormValidator$naissanceAllFieldsMissing)),
				A2(
					$elm$core$Result$mapError,
					$elm$core$List$map(
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalNaissance(no)),
					$author$project$Components$Clients$Detail$UpdateForm$FormValidator$naissanceToApi(form.aI))));
		var mail = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.h_);
		var identite = $elm_community$result_extra$Result$Extra$join(
			A2(
				$elm$core$Result$map,
				$elm$core$Result$fromMaybe(
					A2(
						$elm$core$List$map,
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalIdentite(no),
						$author$project$Components$Clients$Detail$UpdateForm$FormValidator$identiteAllFieldsMissing)),
				A2(
					$elm$core$Result$mapError,
					$elm$core$List$map(
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalIdentite(no)),
					$author$project$Components$Clients$Detail$UpdateForm$FormValidator$identiteToApi(form.aD))));
		var g = A2(
			$elm$core$Result$mapError,
			$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
			A4(
				$author$project$Components$Clients$ResultUtils$map3C,
				F3(
					function (id, nai, piece) {
						return _Utils_Tuple3(id, nai, piece);
					}),
				identite,
				naissance,
				pieceIdentite));
		var fonction = isPersonneMorale ? A2(
			$elm$core$Result$map,
			$elm$core$Maybe$Just,
			A2(
				$elm$core$Result$fromMaybe,
				_List_fromArray(
					[
						A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalFonction, no, '')
					]),
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.g9))) : $elm$core$Result$Ok(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.g9));
		var adresse = $elm_community$result_extra$Result$Extra$join(
			A2(
				$elm$core$Result$map,
				$elm$core$Result$fromMaybe(
					A2(
						$elm$core$List$map,
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalAdresse(no),
						$author$project$Components$Clients$Detail$UpdateForm$FormValidator$adresseAllFieldsMissing)),
				A2(
					$elm$core$Result$mapError,
					$elm$core$List$map(
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalAdresse(no)),
					$author$project$Components$Clients$Detail$UpdateForm$FormValidator$adresseToApi(form.fD))));
		var h = A2(
			$elm$core$Result$mapError,
			$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
			A3(
				$author$project$Components$Clients$ResultUtils$map2C,
				F2(
					function (add, fon) {
						return _Utils_Tuple2(add, fon);
					}),
				adresse,
				fonction));
		var _v0 = A2(
			$author$project$Components$Clients$Detail$UpdateForm$FormValidator$nationaliteToApi,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.bD),
			form.bf);
		var nationalite = _v0.a;
		var autreNationalites = _v0.b;
		var usPerson = _v0.c;
		return A2(
			$elm$core$Result$mapError,
			$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
			A2(
				$elm$core$Result$map,
				function (_v1) {
					var _v2 = _v1.a;
					var id = _v2.a;
					var nai = _v2.b;
					var piece = _v2.c;
					var _v3 = _v1.b;
					var add = _v3.a;
					var fon = _v3.b;
					return $author$project$Components$Clients$APIModel$RepresentantLegal(id)(nai)(piece)(nationalite)(autreNationalites)(
						$elm$core$Maybe$Just(usPerson))(add)(mail)(fon)(form.jj);
				},
				A2($author$project$Components$Clients$ResultUtils$combineBothC, g, h)));
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$personneMoraleToApi = function (form) {
	var valuationRisqueLCBFT = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.c5);
	var typologie = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.dY);
	var missingError = A2(
		$elm$core$Basics$composeR,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonneMoraleF,
		A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique, $author$project$Components$Clients$Detail$ModelForm$FormError$MissingFieldError));
	var missingErrors = function (x) {
		return $elm$core$List$singleton(
			missingError(
				x('')));
	};
	var nom = A2(
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$nonEmptyStringE,
		missingErrors($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PM_Nom),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.dl));
	var siren = A2(
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$nonEmptyStringE,
		missingErrors($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Siren),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.jR));
	var villeTribunalCommerce = A2(
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$nonEmptyStringE,
		missingErrors($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$VilleTribunalCommerce),
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.kG));
	var reprFieldToError = A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PM_RepresentantLegal, missingError);
	var representantsLegaux = $elm_community$result_extra$Result$Extra$join(
		A2(
			$elm$core$Result$map,
			A2(
				$elm$core$Basics$composeR,
				$mgold$elm_nonempty_list$List$Nonempty$fromList,
				$elm$core$Result$fromMaybe(
					_List_fromArray(
						[$author$project$Components$Clients$Detail$ModelForm$FormError$PersonneMoraleShouldHasAtLeastOneRepresentantLegal]))),
			A2(
				$elm$core$Result$mapError,
				A2(
					$elm$core$Basics$composeR,
					$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
					$elm$core$List$map(reprFieldToError)),
				$author$project$Components$Clients$ResultUtils$combineC(
					A2(
						$elm$core$List$indexedMap,
						$author$project$Components$Clients$Detail$UpdateForm$FormValidator$representantsLegalToApi(true),
						form.dA)))));
	var g = A2(
		$elm$core$Result$mapError,
		$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
		A4(
			$author$project$Components$Clients$ResultUtils$map3C,
			F3(
				function (n, sir, ville) {
					return _Utils_Tuple3(n, sir, ville);
				}),
			nom,
			siren,
			villeTribunalCommerce));
	var formeJuridique = function () {
		var _v5 = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$getValue(form.hd);
		if (!_v5.$) {
			if (!_v5.a.$) {
				var x = _v5.a.a;
				return $elm$core$Result$Ok(
					$author$project$Components$Clients$Detail$ModelForm$OfficialDataRef$fromFormeJuridiqueToCodeModified(x));
			} else {
				return $elm$core$Result$Err(
					missingErrors($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$FormeJuridique));
			}
		} else {
			return $elm$core$Result$Ok($elm$core$Maybe$Nothing);
		}
	}();
	var fiscalite = A2(
		$elm$core$Result$mapError,
		$elm$core$List$map(
			A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$FiscaliteF, missingError)),
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$fiscaliteToApi(
			$author$project$Perfimmo$Form$FormModel$getBlock(form.db)));
	var compteResultat = A2(
		$elm$core$Result$mapError,
		$elm$core$List$map(
			A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CompteResultatF, missingError)),
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$compteResultToApi(
			$author$project$Perfimmo$Form$FormModel$getBlock(form.ge)));
	var i = A2(
		$elm$core$Result$mapError,
		$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
		A3(
			$author$project$Components$Clients$ResultUtils$map2C,
			F2(
				function (compte, forme) {
					return _Utils_Tuple2(compte, forme);
				}),
			compteResultat,
			formeJuridique));
	var capitalSocial = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(form.f2);
	var bilan = A2(
		$elm$core$Result$mapError,
		$elm$core$List$map(
			A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$BilanF, missingError)),
		$author$project$Components$Clients$Detail$UpdateForm$FormValidator$bilanToApi(
			$author$project$Perfimmo$Form$FormModel$getBlock(form.fS)));
	var h = A4(
		$author$project$Components$Clients$ResultUtils$map3C,
		F3(
			function (b, f, rl) {
				return _Utils_Tuple3(b, f, rl);
			}),
		bilan,
		fiscalite,
		representantsLegaux);
	return A2(
		$elm$core$Result$mapError,
		$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
		A2(
			$elm$core$Result$mapError,
			$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
			A2(
				$elm$core$Result$map,
				function (_v0) {
					var _v1 = _v0.a;
					var b = _v1.a;
					var f = _v1.b;
					var rl = _v1.c;
					var _v2 = _v0.b;
					var _v3 = _v2.a;
					var n = _v3.a;
					var sir = _v3.b;
					var ville = _v3.c;
					var _v4 = _v2.b;
					var compte = _v4.a;
					var forme = _v4.b;
					return $author$project$Components$Clients$APIModel$PersonneMoraleParams(n)(forme)(sir)(ville)(capitalSocial)(typologie)(compte)(b)(f)(rl)(valuationRisqueLCBFT);
				},
				A2(
					$author$project$Components$Clients$ResultUtils$combineBothC,
					h,
					A2($author$project$Components$Clients$ResultUtils$combineBothC, g, i)))));
};
var $author$project$Components$Clients$APIModel$Epargnants_Particulier = 0;
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$IdentitePersonneF = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NaissanceF = function (a) {
	return {$: 8, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PP_CapaciteJuridique = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PP_PieceIdentite = function (a) {
	return {$: 9, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PaysDeResidenceFiscale = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Clients$APIModel$PersonnePhysiqueParams = function (identite) {
	return function (naissance) {
		return function (pieceIdentite) {
			return function (deces) {
				return function (nationalite) {
					return function (autreNationalites) {
						return function (usPerson) {
							return function (paysDeResidenceFiscale) {
								return function (dateDepartRetraite) {
									return function (capaciteJuridique) {
										return function (profession) {
											return function (situationFamiliale) {
												return function (situationPatrimoniale) {
													return function (representantsLegaux) {
														return function (typologie) {
															return function (evaluationRisqueLCBFT) {
																return function (personnePolitiquementExposee) {
																	return function (personneVulnerable) {
																		return {bf: autreNationalites, f0: capaciteJuridique, gs: dateDepartRetraite, gF: deces, c5: evaluationRisqueLCBFT, aD: identite, aI: naissance, bD: nationalite, iE: paysDeResidenceFiscale, iO: personnePolitiquementExposee, iP: personneVulnerable, du: pieceIdentite, dw: profession, dA: representantsLegaux, jS: situationFamiliale, jV: situationPatrimoniale, dY: typologie, bV: usPerson};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Clients$Detail$ModelForm$FormError$PersonnePhysiqueShouldHasAtLeastOneRepresentantLegal = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SituationPatrimonialeF = function (a) {
	return {$: 11, a: a};
};
var $mgold$elm_nonempty_list$List$Nonempty$toList = function (_v0) {
	var x = _v0.a;
	var xs = _v0.b;
	return A2($elm$core$List$cons, x, xs);
};
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$personnePhysiqueToApi = F2(
	function (clients, form) {
		var profession = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.dw);
		var personneVulnerable = A2(
			$elm$core$Maybe$withDefault,
			false,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.iP));
		var personnePolitiquementExposee = A2(
			$elm$core$Maybe$withDefault,
			false,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.iO));
		var missingError = A2(
			$elm$core$Basics$composeR,
			$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonnePhysiqueF,
			A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique, $author$project$Components$Clients$Detail$ModelForm$FormError$MissingFieldError));
		var missingErrors = function (x) {
			return $elm$core$List$singleton(
				missingError(
					x('')));
		};
		var paysDeResidenceFiscale = A2(
			$elm$core$Result$fromMaybe,
			missingErrors($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PaysDeResidenceFiscale),
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.iE));
		var naissance = $elm_community$result_extra$Result$Extra$join(
			A2(
				$elm$core$Result$map,
				$elm$core$Result$fromMaybe(
					A2(
						$elm$core$List$map,
						A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NaissanceF, missingError),
						$author$project$Components$Clients$Detail$UpdateForm$FormValidator$naissanceAllFieldsMissing)),
				A2(
					$elm$core$Result$mapError,
					$elm$core$List$map(
						A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NaissanceF, missingError)),
					$author$project$Components$Clients$Detail$UpdateForm$FormValidator$naissanceToApi(form.aI))));
		var pieceIdentite = A2(
			$elm$core$Result$mapError,
			$elm$core$List$map(
				A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PP_PieceIdentite, missingError)),
			$author$project$Components$Clients$Detail$UpdateForm$FormValidator$pieceIdentiteToApi(
				$author$project$Perfimmo$Form$FormModel$getBlock(form.du)));
		var reprFieldToError = A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PP_RepresentantLegal, missingError);
		var representantsLegauxRaw = A2(
			$elm$core$Result$mapError,
			A2(
				$elm$core$Basics$composeR,
				$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
				$elm$core$List$map(reprFieldToError)),
			$author$project$Components$Clients$ResultUtils$combineC(
				A2(
					$elm$core$List$indexedMap,
					$author$project$Components$Clients$Detail$UpdateForm$FormValidator$representantsLegalToApi(false),
					form.dA)));
		var situationFamilliale = A2(
			$elm$core$Result$mapError,
			$elm$core$List$map(
				A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SituationFamillialeF, missingError)),
			A2(
				$author$project$Components$Clients$Detail$UpdateForm$FormValidator$situationFamilialeToApi,
				clients,
				$author$project$Perfimmo$Form$FormModel$getBlock(form.jU)));
		var situationPatrimoniale = A2(
			$elm$core$Result$mapError,
			$elm$core$List$map(
				A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SituationPatrimonialeF, missingError)),
			$author$project$Components$Clients$Detail$UpdateForm$FormValidator$situationPatrimonialeToApi(
				$author$project$Perfimmo$Form$FormModel$getBlock(form.jV)));
		var identite = $elm_community$result_extra$Result$Extra$join(
			A2(
				$elm$core$Result$map,
				$elm$core$Result$fromMaybe(
					A2(
						$elm$core$List$map,
						A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$IdentitePersonneF, missingError),
						$author$project$Components$Clients$Detail$UpdateForm$FormValidator$identiteAllFieldsMissing)),
				A2(
					$elm$core$Result$mapError,
					$elm$core$List$map(
						A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$IdentitePersonneF, missingError)),
					$author$project$Components$Clients$Detail$UpdateForm$FormValidator$identiteToApi(form.aD))));
		var g = A2(
			$elm$core$Result$mapError,
			$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
			A4(
				$author$project$Components$Clients$ResultUtils$map3C,
				F3(
					function (id, nai, piece) {
						return _Utils_Tuple3(id, nai, piece);
					}),
				identite,
				naissance,
				pieceIdentite));
		var evaluationRisqueLCBFT = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.c5);
		var deces = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.gF);
		var dateDepartRetraite = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.gs);
		var capaciteJuridique = A2(
			$elm$core$Result$fromMaybe,
			missingErrors($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PP_CapaciteJuridique),
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.f0));
		var h = A2(
			$elm$core$Result$mapError,
			$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
			A4(
				$author$project$Components$Clients$ResultUtils$map3C,
				F3(
					function (pays, cap, sitFam) {
						return _Utils_Tuple3(pays, cap, sitFam);
					}),
				paysDeResidenceFiscale,
				capaciteJuridique,
				situationFamilliale));
		var _v0 = A2(
			$author$project$Components$Clients$Detail$UpdateForm$FormValidator$nationaliteToApi,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.bD),
			form.bf);
		var nationalite = _v0.a;
		var autreNationalites = _v0.b;
		var usPerson = _v0.c;
		var _v1 = function () {
			var _v2 = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.f0);
			if (!_v2.$) {
				switch (_v2.a) {
					case 0:
						var _v3 = _v2.a;
						return _Utils_Tuple2(false, 0);
					case 1:
						var _v4 = _v2.a;
						return _Utils_Tuple2(true, 1);
					case 2:
						var _v5 = _v2.a;
						return _Utils_Tuple2(true, 2);
					case 3:
						var _v6 = _v2.a;
						return _Utils_Tuple2(false, 3);
					case 4:
						var _v7 = _v2.a;
						return _Utils_Tuple2(true, 4);
					case 5:
						var _v8 = _v2.a;
						return _Utils_Tuple2(true, 5);
					case 6:
						var _v9 = _v2.a;
						return _Utils_Tuple2(false, 6);
					case 7:
						var _v10 = _v2.a;
						return _Utils_Tuple2(true, 7);
					default:
						var _v11 = _v2.a;
						return _Utils_Tuple2(true, 8);
				}
			} else {
				return _Utils_Tuple2(false, 0);
			}
		}();
		var shouldHasAtLeastOneRepresentant = _v1.a;
		var status = _v1.b;
		var representantsLegaux = shouldHasAtLeastOneRepresentant ? A2(
			$elm$core$Result$map,
			$mgold$elm_nonempty_list$List$Nonempty$toList,
			$elm_community$result_extra$Result$Extra$join(
				A2(
					$elm$core$Result$map,
					A2(
						$elm$core$Basics$composeR,
						$mgold$elm_nonempty_list$List$Nonempty$fromList,
						$elm$core$Result$fromMaybe(
							_List_fromArray(
								[
									$author$project$Components$Clients$Detail$ModelForm$FormError$PersonnePhysiqueShouldHasAtLeastOneRepresentantLegal(status)
								]))),
					representantsLegauxRaw))) : representantsLegauxRaw;
		var i = A3(
			$author$project$Components$Clients$ResultUtils$map2C,
			F2(
				function (sitPat, repr) {
					return _Utils_Tuple2(sitPat, repr);
				}),
			situationPatrimoniale,
			representantsLegaux);
		return A2(
			$elm$core$Result$mapError,
			$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
			A2(
				$elm$core$Result$mapError,
				$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
				A2(
					$elm$core$Result$map,
					function (_v12) {
						var _v13 = _v12.a;
						var sitPat = _v13.a;
						var repr = _v13.b;
						var _v14 = _v12.b;
						var _v15 = _v14.a;
						var id = _v15.a;
						var nai = _v15.b;
						var piece = _v15.c;
						var _v16 = _v14.b;
						var pays = _v16.a;
						var cap = _v16.b;
						var sitFam = _v16.c;
						return $author$project$Components$Clients$APIModel$PersonnePhysiqueParams(id)(nai)(piece)(deces)(nationalite)(autreNationalites)(
							$elm$core$Maybe$Just(usPerson))(pays)(dateDepartRetraite)(cap)(profession)(sitFam)(sitPat)(repr)(0)(evaluationRisqueLCBFT)(personnePolitiquementExposee)(personneVulnerable);
					},
					A2(
						$author$project$Components$Clients$ResultUtils$combineBothC,
						i,
						A2($author$project$Components$Clients$ResultUtils$combineBothC, g, h)))));
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$fromFormToApiModel = F2(
	function (form, clients) {
		var profilInvestisseur = A2(
			$elm$core$Result$mapError,
			$elm$core$List$map($author$project$Components$Clients$Detail$ModelForm$FormError$MissingFieldError),
			$author$project$Components$Clients$Detail$UpdateForm$FormValidator$profilInvestisseurToApi(
				$author$project$Perfimmo$Form$FormModel$getBlock(form.jh)));
		var profilExtraFinancier = A2(
			$elm$core$Result$mapError,
			$elm$core$List$map($author$project$Components$Clients$Detail$ModelForm$FormError$MissingFieldError),
			$author$project$Components$Clients$Detail$UpdateForm$FormValidator$profilExtraFinancierToApi(
				$author$project$Perfimmo$Form$FormModel$getBlock(form.jg)));
		var natureJuridique = function () {
			var _v3 = form.ic;
			if (!_v3.$) {
				var params = _v3.a;
				return A2(
					$elm$core$Result$map,
					$author$project$Components$Clients$APIModel$PersonnePhysique,
					A2($author$project$Components$Clients$Detail$UpdateForm$FormValidator$personnePhysiqueToApi, clients, params));
			} else {
				var params = _v3.a;
				return A2(
					$elm$core$Result$map,
					$author$project$Components$Clients$APIModel$PersonneMorale,
					$author$project$Components$Clients$Detail$UpdateForm$FormValidator$personneMoraleToApi(params));
			}
		}();
		var contactApi = A2(
			$elm$core$Result$mapError,
			$elm$core$List$map($author$project$Components$Clients$Detail$ModelForm$FormError$MissingFieldError),
			$author$project$Components$Clients$Detail$UpdateForm$FormValidator$contactToApi(form.cY));
		var g = A2(
			$elm$core$Result$mapError,
			$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
			A4(
				$author$project$Components$Clients$ResultUtils$map3C,
				F3(
					function (nature, contact, profilInvest) {
						return _Utils_Tuple3(nature, contact, profilInvest);
					}),
				natureJuridique,
				contactApi,
				profilInvestisseur));
		var conseillers = A2(
			$elm$core$Result$fromMaybe,
			_List_fromArray(
				[$author$project$Components$Clients$Detail$ModelForm$FormError$ShouldHaveAtLeastOneConseillerToHandleThisClient]),
			$mgold$elm_nonempty_list$List$Nonempty$fromList(
				A2(
					$elm$core$List$map,
					$elm$core$Tuple$first,
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$getSelectedValues(form.ef))));
		var h = A2(
			$elm$core$Result$mapError,
			$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
			A3(
				$author$project$Components$Clients$ResultUtils$map2C,
				F2(
					function (cons, pef) {
						return _Utils_Tuple2(cons, pef);
					}),
				conseillers,
				profilExtraFinancier));
		return A2(
			$elm$core$Result$mapError,
			$PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten,
			A2(
				$elm$core$Result$map,
				function (_v0) {
					var _v1 = _v0.a;
					var nature = _v1.a;
					var contact = _v1.b;
					var profilInvest = _v1.c;
					var _v2 = _v0.b;
					var cons = _v2.a;
					var pef = _v2.b;
					return A7($author$project$Components$Clients$APIModel$Client, 'UID', nature, contact, profilInvest, pef, cons, _List_Nil);
				},
				A2($author$project$Components$Clients$ResultUtils$combineBothC, g, h)));
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormValidator$checkReadyToSave = F2(
	function (form, clients) {
		var result = A2($author$project$Components$Clients$Detail$UpdateForm$FormValidator$fromFormToApiModel, form, clients);
		if (!result.$) {
			var client = result.a;
			return $author$project$Components$Clients$Detail$SaveState$ReadyToSave(client);
		} else {
			var errors = result.a;
			return $author$project$Components$Clients$Detail$SaveState$ErrorInForm(errors);
		}
	});
var $author$project$Components$Clients$APIModel$adresseJsonEncoder = function (adresse) {
	return $elm$json$Json$Encode$object(
		_Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'addr1',
					$elm$json$Json$Encode$string(adresse.fB)),
					_Utils_Tuple2(
					'codePostal',
					$elm$json$Json$Encode$string(adresse.gc)),
					_Utils_Tuple2(
					'ville',
					$elm$json$Json$Encode$string(adresse.d_)),
					_Utils_Tuple2(
					'pays',
					$elm$json$Json$Encode$string(adresse.dq))
				]),
			$elm_community$maybe_extra$Maybe$Extra$toList(
				A2(
					$elm$core$Maybe$map,
					function (addr2) {
						return _Utils_Tuple2(
							'addr2',
							$elm$json$Json$Encode$string(addr2));
					},
					adresse.fC))));
};
var $author$project$Components$Clients$APIModel$jsonEnum = function (x) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				x,
				$elm$json$Json$Encode$object(_List_Nil))
			]));
};
var $author$project$Components$Clients$APIModel$capaciteSubirPertesJsonEncoder = function (s) {
	switch (s) {
		case 0:
			return $author$project$Components$Clients$APIModel$jsonEnum('AucunePerteToleree_0pct');
		case 1:
			return $author$project$Components$Clients$APIModel$jsonEnum('PerteFinanciereLimiteeInf_10pct');
		case 2:
			return $author$project$Components$Clients$APIModel$jsonEnum('PerteFinanciereSignificativeEntre_10et50pct');
		case 3:
			return $author$project$Components$Clients$APIModel$jsonEnum('PerteFinanciereAConcurrenceDesMontantsInvestis_100pct');
		default:
			return $author$project$Components$Clients$APIModel$jsonEnum('PerteFinanciereAuDelaDesMontantsInvestisSup_100pct');
	}
};
var $author$project$Components$Clients$APIModel$experienceFinanciereJsonEncoder = function (e) {
	switch (e) {
		case 0:
			return $author$project$Components$Clients$APIModel$jsonEnum('Novice');
		case 1:
			return $author$project$Components$Clients$APIModel$jsonEnum('Informe');
		default:
			return $author$project$Components$Clients$APIModel$jsonEnum('Experimente');
	}
};
var $author$project$Components$Clients$APIModel$horizonInvestissementJsonEncoder = function (s) {
	switch (s) {
		case 0:
			return $author$project$Components$Clients$APIModel$jsonEnum('HorizonTresCourtTermeInf_1an');
		case 1:
			return $author$project$Components$Clients$APIModel$jsonEnum('HorizonCourtTermeEntre_1et3an');
		case 2:
			return $author$project$Components$Clients$APIModel$jsonEnum('HorizonMoyenTermeEntre_3et5an');
		default:
			return $author$project$Components$Clients$APIModel$jsonEnum('HorizonLongTermeSup_5an');
	}
};
var $author$project$Components$Clients$APIModel$mifIITypeJsonEncoder = function (t) {
	switch (t) {
		case 0:
			return $author$project$Components$Clients$APIModel$jsonEnum('ClientProfessionnel');
		case 2:
			return $author$project$Components$Clients$APIModel$jsonEnum('ClientNonProfessionnel');
		case 4:
			return $author$project$Components$Clients$APIModel$jsonEnum('ContrepartieEligible');
		case 1:
			return $author$project$Components$Clients$APIModel$jsonEnum('ClientProfessionnelSurOption');
		default:
			return $author$project$Components$Clients$APIModel$jsonEnum('ClientNonProfessionnelSurOption');
	}
};
var $author$project$Components$Clients$APIModel$objectifInvestissementJsonEncoder = function (s) {
	switch (s) {
		case 0:
			return $author$project$Components$Clients$APIModel$jsonEnum('Conservation');
		case 1:
			return $author$project$Components$Clients$APIModel$jsonEnum('Croissance');
		case 2:
			return $author$project$Components$Clients$APIModel$jsonEnum('Rendement');
		default:
			return $author$project$Components$Clients$APIModel$jsonEnum('Couverture');
	}
};
var $elm$json$Json$Encode$float = _Json_wrap;
var $author$project$Components$Clients$APIModel$bilanJsonEncoder = function (b) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'actifAnnuel',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'brut',
							$elm$json$Json$Encode$float(b.cM.cR)),
							_Utils_Tuple2(
							'financier',
							$elm$json$Json$Encode$float(b.cM.da)),
							_Utils_Tuple2(
							'nonFinancier',
							$elm$json$Json$Encode$float(b.cM.im))
						]))),
				_Utils_Tuple2(
				'passifAnnuel',
				$elm$json$Json$Encode$float(b.iC)),
				_Utils_Tuple2(
				'capitauxPropres',
				$elm$json$Json$Encode$float(b.f3))
			]));
};
var $author$project$Components$Clients$APIModel$compteResultatJsonEncoder = function (c) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'produitsAnnuels',
				$elm$json$Json$Encode$float(c.jd)),
				_Utils_Tuple2(
				'charges_annuelles',
				$elm$json$Json$Encode$float(c.f6)),
				_Utils_Tuple2(
				'resultat_annuel',
				$elm$json$Json$Encode$float(c.jw))
			]));
};
var $author$project$Components$Clients$APIModel$fiscaliteJsonEncoder = function (f) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'tauxImposition',
				$elm$json$Json$Encode$float(f.kc)),
				_Utils_Tuple2(
				'montantsImpots',
				$elm$json$Json$Encode$float(f.h9))
			]));
};
var $author$project$Components$Clients$APIModel$civiliteJsonEncoder = function (c) {
	if (!c) {
		return $author$project$Components$Clients$APIModel$jsonEnum('Monsieur');
	} else {
		return $author$project$Components$Clients$APIModel$jsonEnum('Madame');
	}
};
var $author$project$Components$Clients$APIModel$identiteJsonEncoder = function (id) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'civilite',
				$author$project$Components$Clients$APIModel$civiliteJsonEncoder(id.f8)),
				_Utils_Tuple2(
				'nom',
				$elm$json$Json$Encode$string(id.dl)),
				_Utils_Tuple2(
				'nomNaissance',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, id.ii))),
				_Utils_Tuple2(
				'prenom',
				$elm$json$Json$Encode$string(id.iV))
			]));
};
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {gC: d, ia: m, kO: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		eZ: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		kO: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.kO, 0, date.eZ);
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.gC;
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.ia;
	});
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.eZ;
	});
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				'0',
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0;
	var wdn = $justinmimbs$date$Date$weekdayNumber(rd);
	var wy = $justinmimbs$date$Date$year(rd + (4 - wdn));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		kI: 1 + (((rd - week1Day1) / 7) | 0),
		kJ: wy,
		o9: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.kI;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.kJ;
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.dk(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.eT(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.dk(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.el(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.bd(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.bd(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.bd(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.fr(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.bd(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.bd(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4($justinmimbs$date$Date$formatField, language, 'E', length, date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (!token.$) {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.d, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 1) && _v0.b.b) && (_v0.b.a.$ === 1)) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (_char !== '\'') && (!$elm$core$Char$isAlpha(_char));
};
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq('\''))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq('\''))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('\''))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('\'')),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $justinmimbs$date$Date$weekdayToName = function (wd) {
	switch (wd) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $justinmimbs$date$Date$language_en = {
	el: $justinmimbs$date$Date$withOrdinalSuffix,
	eT: $justinmimbs$date$Date$monthToName,
	dk: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$monthToName,
		$elm$core$String$left(3)),
	fr: $justinmimbs$date$Date$weekdayToName,
	bd: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$weekdayToName,
		$elm$core$String$left(3))
};
var $justinmimbs$date$Date$format = function (pattern) {
	return A2($justinmimbs$date$Date$formatWithLanguage, $justinmimbs$date$Date$language_en, pattern);
};
var $justinmimbs$date$Date$toIsoString = $justinmimbs$date$Date$format('yyyy-MM-dd');
var $author$project$Components$Clients$APIModel$naissanceJsonEncoder = function (n) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'date',
				$elm$json$Json$Encode$string(
					$justinmimbs$date$Date$toIsoString(n.c$))),
				_Utils_Tuple2(
				'ville',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, n.d_))),
				_Utils_Tuple2(
				'pays',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, n.dq)))
			]));
};
var $author$project$Components$Clients$APIModel$pieceIdentiteTypeJsonEncoder = function (p) {
	switch (p) {
		case 0:
			return $author$project$Components$Clients$APIModel$jsonEnum('CarteNationaleIdentite');
		case 1:
			return $author$project$Components$Clients$APIModel$jsonEnum('Passeport');
		case 2:
			return $author$project$Components$Clients$APIModel$jsonEnum('CarteDeRessortissantUE_EEE');
		case 3:
			return $author$project$Components$Clients$APIModel$jsonEnum('CarteDeSejour');
		case 4:
			return $author$project$Components$Clients$APIModel$jsonEnum('PasseportNonResident');
		case 5:
			return $author$project$Components$Clients$APIModel$jsonEnum('OrdonnanceDeProtection');
		case 6:
			return $author$project$Components$Clients$APIModel$jsonEnum('CarteDeResident');
		case 7:
			return $author$project$Components$Clients$APIModel$jsonEnum('CarteDeCommercantEtranger');
		case 8:
			return $author$project$Components$Clients$APIModel$jsonEnum('PermisDeConduire');
		default:
			return $author$project$Components$Clients$APIModel$jsonEnum('AutrePieceIdentite');
	}
};
var $author$project$Components$Clients$APIModel$pieceIdentiteJsonEncoder = function (p) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'numero',
				$elm$json$Json$Encode$string(p.is)),
				_Utils_Tuple2(
				'typeDePiece',
				$author$project$Components$Clients$APIModel$pieceIdentiteTypeJsonEncoder(p.ks)),
				_Utils_Tuple2(
				'dateDelivrance',
				$elm$json$Json$Encode$string(
					$justinmimbs$date$Date$toIsoString(p.gr))),
				_Utils_Tuple2(
				'dateExpiration',
				$elm$json$Json$Encode$string(
					$justinmimbs$date$Date$toIsoString(p.gu)))
			]));
};
var $author$project$Components$Clients$APIModel$representantsLegalJsonEncoder = function (r) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			'identite',
			$author$project$Components$Clients$APIModel$identiteJsonEncoder(r.aD)),
			_Utils_Tuple2(
			'naissance',
			$author$project$Components$Clients$APIModel$naissanceJsonEncoder(r.aI)),
			_Utils_Tuple2(
			'pieceIdentite',
			A2(
				$elm$core$Maybe$withDefault,
				$elm$json$Json$Encode$null,
				A2($elm$core$Maybe$map, $author$project$Components$Clients$APIModel$pieceIdentiteJsonEncoder, r.du))),
			_Utils_Tuple2(
			'nationalite',
			A2(
				$elm$core$Maybe$withDefault,
				$elm$json$Json$Encode$null,
				A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, r.bD))),
			_Utils_Tuple2(
			'autreNationalites',
			A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, r.bf)),
			_Utils_Tuple2(
			'usPerson',
			$elm$json$Json$Encode$bool(
				A2($elm$core$Maybe$withDefault, false, r.bV))),
			_Utils_Tuple2(
			'adresse',
			$author$project$Components$Clients$APIModel$adresseJsonEncoder(r.fD)),
			_Utils_Tuple2(
			'mail',
			A2(
				$elm$core$Maybe$withDefault,
				$elm$json$Json$Encode$null,
				A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, r.h_))),
			_Utils_Tuple2(
			'fonction',
			A2(
				$elm$core$Maybe$withDefault,
				$elm$json$Json$Encode$null,
				A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, r.g9))),
			_Utils_Tuple2(
			'pseudoId',
			A2(
				$elm$core$Maybe$withDefault,
				$elm$json$Json$Encode$null,
				A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, r.jj)))
		]);
};
var $author$project$Components$Clients$APIModel$risqueLCBFTJsonEncoder = function (r) {
	switch (r) {
		case 0:
			return $author$project$Components$Clients$APIModel$jsonEnum('Faible');
		case 1:
			return $author$project$Components$Clients$APIModel$jsonEnum('Moyen');
		default:
			return $author$project$Components$Clients$APIModel$jsonEnum('Fort');
	}
};
var $author$project$Components$Clients$APIModel$typologiePersonneMoraleJsonEncoder = function (t) {
	switch (t) {
		case 0:
			return $author$project$Components$Clients$APIModel$jsonEnum('OrganismeSansButLucratif');
		case 1:
			return $author$project$Components$Clients$APIModel$jsonEnum('InvestisseursInstitutionnels');
		case 2:
			return $author$project$Components$Clients$APIModel$jsonEnum('IntermediairesFinanciers');
		default:
			return $author$project$Components$Clients$APIModel$jsonEnum('Entreprises_ChefEntreprise');
	}
};
var $author$project$Components$Clients$APIModel$personneMoraleJsonEncoder = function (params) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'nom',
				$elm$json$Json$Encode$string(params.dl)),
				_Utils_Tuple2(
				'formeJuridique',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, params.hd))),
				_Utils_Tuple2(
				'siren',
				$elm$json$Json$Encode$string(params.jR)),
				_Utils_Tuple2(
				'villeTribunalCommerce',
				$elm$json$Json$Encode$string(params.kG)),
				_Utils_Tuple2(
				'capitalSocial',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$float, params.f2))),
				_Utils_Tuple2(
				'typologie',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Clients$APIModel$typologiePersonneMoraleJsonEncoder, params.dY))),
				_Utils_Tuple2(
				'compteResultat',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Clients$APIModel$compteResultatJsonEncoder, params.ge))),
				_Utils_Tuple2(
				'bilan',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Clients$APIModel$bilanJsonEncoder, params.fS))),
				_Utils_Tuple2(
				'fiscalite',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Clients$APIModel$fiscaliteJsonEncoder, params.db))),
				_Utils_Tuple2(
				'representantsLegaux',
				A2(
					$elm$json$Json$Encode$list,
					$elm$json$Json$Encode$object,
					A2(
						$elm$core$List$map,
						$author$project$Components$Clients$APIModel$representantsLegalJsonEncoder,
						$mgold$elm_nonempty_list$List$Nonempty$toList(params.dA)))),
				_Utils_Tuple2(
				'evaluationRisqueLCBFT',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Clients$APIModel$risqueLCBFTJsonEncoder, params.c5)))
			]));
};
var $author$project$Components$Clients$APIModel$capaciteJuridiqueJsonEncoder = function (c) {
	switch (c) {
		case 0:
			return $author$project$Components$Clients$APIModel$jsonEnum('MajeurCapable');
		case 1:
			return $author$project$Components$Clients$APIModel$jsonEnum('MajeurProtegeSousTutelle');
		case 2:
			return $author$project$Components$Clients$APIModel$jsonEnum('MajeurProtegeSousCuratelle');
		case 3:
			return $author$project$Components$Clients$APIModel$jsonEnum('MajeurSousSauvegardeDeJustice');
		case 4:
			return $author$project$Components$Clients$APIModel$jsonEnum('AbsentOuAutreMajeurIncapable');
		case 5:
			return $author$project$Components$Clients$APIModel$jsonEnum('MineurSousTutelle');
		case 6:
			return $author$project$Components$Clients$APIModel$jsonEnum('MineurEmancipe');
		case 7:
			return $author$project$Components$Clients$APIModel$jsonEnum('MineurSousControleJudiciaire');
		default:
			return $author$project$Components$Clients$APIModel$jsonEnum('MineurNonEmancipe');
	}
};
var $author$project$Components$Clients$APIModel$personnePhysique_TypologieJsonEncoder = function (t) {
	return $author$project$Components$Clients$APIModel$jsonEnum('Epargnants_Particulier');
};
var $author$project$Components$Clients$APIModel$regimeMatrimonialeJsonEncoder = function (r) {
	switch (r) {
		case 0:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'CommunauteReduiteAuxAcquets',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 1:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'SeparationDesBiens',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 2:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'ParticipationAuxAcquets',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 3:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'CommunauteUniverselle',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 4:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'CommunauteUniverselleIntegrale',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 5:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'CommunauteDeMeublesEtAcquets',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 6:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'ParticipationOptionnelleAuxAcquets',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 7:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'SeparationDesBiensAvecSocieteAcquets',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'RegimeEtranger',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
	}
};
var $author$project$Components$Clients$APIModel$conjointJsonEncoder = function (c) {
	if (!c.$) {
		var params = c.a;
		var json = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'uid',
					$elm$json$Json$Encode$string(params.kx)),
					_Utils_Tuple2(
					'dateMariage',
					A2(
						$elm$core$Maybe$withDefault,
						$elm$json$Json$Encode$null,
						A2(
							$elm$core$Maybe$map,
							A2($elm$core$Basics$composeR, $justinmimbs$date$Date$toIsoString, $elm$json$Json$Encode$string),
							params.c0))),
					_Utils_Tuple2(
					'regimeMatrimoniale',
					A2(
						$elm$core$Maybe$withDefault,
						$elm$json$Json$Encode$null,
						A2($elm$core$Maybe$map, $author$project$Components$Clients$APIModel$regimeMatrimonialeJsonEncoder, params.dy)))
				]));
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2('ReferencedClient', json)
				]));
	} else {
		var params = c.a;
		var json = $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'identite',
					$author$project$Components$Clients$APIModel$identiteJsonEncoder(params.aD)),
					_Utils_Tuple2(
					'naissance',
					$author$project$Components$Clients$APIModel$naissanceJsonEncoder(params.aI)),
					_Utils_Tuple2(
					'nationalite',
					A2(
						$elm$core$Maybe$withDefault,
						$elm$json$Json$Encode$null,
						A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, params.bD))),
					_Utils_Tuple2(
					'autreNationalites',
					A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, params.bf)),
					_Utils_Tuple2(
					'usPerson',
					$elm$json$Json$Encode$bool(
						A2($elm$core$Maybe$withDefault, false, params.bV))),
					_Utils_Tuple2(
					'profession',
					A2(
						$elm$core$Maybe$withDefault,
						$elm$json$Json$Encode$null,
						A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, params.dw))),
					_Utils_Tuple2(
					'dateMariage',
					A2(
						$elm$core$Maybe$withDefault,
						$elm$json$Json$Encode$null,
						A2(
							$elm$core$Maybe$map,
							A2($elm$core$Basics$composeR, $justinmimbs$date$Date$toIsoString, $elm$json$Json$Encode$string),
							params.c0))),
					_Utils_Tuple2(
					'regimeMatrimoniale',
					A2(
						$elm$core$Maybe$withDefault,
						$elm$json$Json$Encode$null,
						A2($elm$core$Maybe$map, $author$project$Components$Clients$APIModel$regimeMatrimonialeJsonEncoder, params.dy)))
				]));
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2('UnreferencedPerson', json)
				]));
	}
};
var $author$project$Components$Clients$APIModel$enfantJsonEncoder = function (e) {
	return _List_fromArray(
		[
			_Utils_Tuple2(
			'identite',
			$author$project$Components$Clients$APIModel$identiteJsonEncoder(e.aD)),
			_Utils_Tuple2(
			'naissance',
			$author$project$Components$Clients$APIModel$naissanceJsonEncoder(e.aI))
		]);
};
var $author$project$Components$Clients$APIModel$situationFamilleJsonEncoder = function (s) {
	switch (s) {
		case 0:
			return $author$project$Components$Clients$APIModel$jsonEnum('Celibataire');
		case 1:
			return $author$project$Components$Clients$APIModel$jsonEnum('Marie');
		case 2:
			return $author$project$Components$Clients$APIModel$jsonEnum('Separe');
		case 3:
			return $author$project$Components$Clients$APIModel$jsonEnum('Divorce');
		case 4:
			return $author$project$Components$Clients$APIModel$jsonEnum('Veuf');
		case 5:
			return $author$project$Components$Clients$APIModel$jsonEnum('UnionLibre');
		case 6:
			return $author$project$Components$Clients$APIModel$jsonEnum('Pacse');
		default:
			return $author$project$Components$Clients$APIModel$jsonEnum('NonDefinie');
	}
};
var $author$project$Components$Clients$APIModel$situationFamilialeJsonEncoder = function (sitFam) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'situationFamille',
				$author$project$Components$Clients$APIModel$situationFamilleJsonEncoder(sitFam.jT)),
				_Utils_Tuple2(
				'conjointPartenaire',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Clients$APIModel$conjointJsonEncoder, sitFam.gf))),
				_Utils_Tuple2(
				'nombreEnfantACharge',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$int, sitFam.ij))),
				_Utils_Tuple2(
				'enfants',
				A2(
					$elm$json$Json$Encode$list,
					$elm$json$Json$Encode$object,
					A2($elm$core$List$map, $author$project$Components$Clients$APIModel$enfantJsonEncoder, sitFam.gX)))
			]));
};
var $author$project$Components$Clients$APIModel$budgetJsonEncoder = function (b) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'revenusAnnuels',
				$elm$json$Json$Encode$float(b.jy)),
				_Utils_Tuple2(
				'chargesAnnuelles',
				$elm$json$Json$Encode$float(b.f5))
			]));
};
var $author$project$Components$Clients$APIModel$fiscalitePatrimonialeJsonEncoder = function (f) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'nombrePartsFiscales',
				$elm$json$Json$Encode$float(f.ik)),
				_Utils_Tuple2(
				'montantIR',
				$elm$json$Json$Encode$float(f.h7)),
				_Utils_Tuple2(
				'montant_IFI',
				$elm$json$Json$Encode$float(f.h8)),
				_Utils_Tuple2(
				'tmi',
				$elm$json$Json$Encode$float(f.kj))
			]));
};
var $author$project$Components$Clients$APIModel$patrimoineJsonEncoder = function (p) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'actif',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'brut',
							$elm$json$Json$Encode$float(p.kP.cR)),
							_Utils_Tuple2(
							'financier',
							$elm$json$Json$Encode$float(p.kP.da)),
							_Utils_Tuple2(
							'non_financier',
							$elm$json$Json$Encode$float(p.kP.io))
						]))),
				_Utils_Tuple2(
				'passif',
				$elm$json$Json$Encode$float(p.iB))
			]));
};
var $author$project$Components$Clients$APIModel$situationPatrimonialeJsonEncoder = function (sitPat) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'fiscalite',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Clients$APIModel$fiscalitePatrimonialeJsonEncoder, sitPat.db))),
				_Utils_Tuple2(
				'patrimoine',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Clients$APIModel$patrimoineJsonEncoder, sitPat.iD))),
				_Utils_Tuple2(
				'budget',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Clients$APIModel$budgetJsonEncoder, sitPat.fX))),
				_Utils_Tuple2(
				'tauxEndettement',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$float, sitPat.kb)))
			]));
};
var $author$project$Components$Clients$APIModel$personnePhysiqueJsonEncoder = function (params) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'identite',
				$author$project$Components$Clients$APIModel$identiteJsonEncoder(params.aD)),
				_Utils_Tuple2(
				'naissance',
				$author$project$Components$Clients$APIModel$naissanceJsonEncoder(params.aI)),
				_Utils_Tuple2(
				'pieceIdentite',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Clients$APIModel$pieceIdentiteJsonEncoder, params.du))),
				_Utils_Tuple2(
				'deces',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2(
						$elm$core$Maybe$map,
						A2($elm$core$Basics$composeR, $justinmimbs$date$Date$toIsoString, $elm$json$Json$Encode$string),
						params.gF))),
				_Utils_Tuple2(
				'nationalite',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, params.bD))),
				_Utils_Tuple2(
				'autreNationalites',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, params.bf)),
				_Utils_Tuple2(
				'usPerson',
				$elm$json$Json$Encode$bool(
					A2($elm$core$Maybe$withDefault, false, params.bV))),
				_Utils_Tuple2(
				'paysDeResidenceFiscale',
				$elm$json$Json$Encode$string(params.iE)),
				_Utils_Tuple2(
				'dateDepartRetraite',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2(
						$elm$core$Maybe$map,
						A2($elm$core$Basics$composeR, $justinmimbs$date$Date$toIsoString, $elm$json$Json$Encode$string),
						params.gs))),
				_Utils_Tuple2(
				'capaciteJuridique',
				$author$project$Components$Clients$APIModel$capaciteJuridiqueJsonEncoder(params.f0)),
				_Utils_Tuple2(
				'profession',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, params.dw))),
				_Utils_Tuple2(
				'situationFamiliale',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Clients$APIModel$situationFamilialeJsonEncoder, params.jS))),
				_Utils_Tuple2(
				'situationPatrimoniale',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Clients$APIModel$situationPatrimonialeJsonEncoder, params.jV))),
				_Utils_Tuple2(
				'representantsLegaux',
				A2(
					$elm$json$Json$Encode$list,
					$elm$json$Json$Encode$object,
					A2($elm$core$List$map, $author$project$Components$Clients$APIModel$representantsLegalJsonEncoder, params.dA))),
				_Utils_Tuple2(
				'typologie',
				$author$project$Components$Clients$APIModel$personnePhysique_TypologieJsonEncoder(params.dY)),
				_Utils_Tuple2(
				'evaluationRisqueLCBFT',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Clients$APIModel$risqueLCBFTJsonEncoder, params.c5))),
				_Utils_Tuple2(
				'personnePolitiquementExposee',
				$elm$json$Json$Encode$bool(params.iO)),
				_Utils_Tuple2(
				'personneVulnerable',
				$elm$json$Json$Encode$bool(params.iP))
			]));
};
var $author$project$Components$Clients$APIModel$profilRisqueInvestisseurJsonEncoder = function (s) {
	switch (s) {
		case 0:
			return $author$project$Components$Clients$APIModel$jsonEnum('Securitaire');
		case 1:
			return $author$project$Components$Clients$APIModel$jsonEnum('Defensif');
		case 2:
			return $author$project$Components$Clients$APIModel$jsonEnum('Equilibre');
		case 3:
			return $author$project$Components$Clients$APIModel$jsonEnum('Dynamique');
		default:
			return $author$project$Components$Clients$APIModel$jsonEnum('Offensif');
	}
};
var $author$project$Components$Clients$APIModel$toleranceAuxRisquesJsonEncoder = function (s) {
	switch (s) {
		case 0:
			return $author$project$Components$Clients$APIModel$jsonEnum('Faible');
		case 1:
			return $author$project$Components$Clients$APIModel$jsonEnum('Moyenne');
		default:
			return $author$project$Components$Clients$APIModel$jsonEnum('Elevee');
	}
};
var $author$project$Components$Clients$APIModel$clientJsonEncoder = function (client) {
	var profilInvestisseur = function (p) {
		return _List_fromArray(
			[
				_Utils_Tuple2(
				'profilInvestisseur',
				$elm$json$Json$Encode$object(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'dateEntreeRelation',
								$elm$json$Json$Encode$string(
									$justinmimbs$date$Date$toIsoString(p.gt)))
							]),
						_Utils_ap(
							A2(
								$elm$core$Maybe$withDefault,
								_List_Nil,
								A2(
									$elm$core$Maybe$map,
									function (d) {
										return _List_fromArray(
											[
												_Utils_Tuple2(
												'dateTransformationRelation',
												$elm$json$Json$Encode$string(
													$justinmimbs$date$Date$toIsoString(d)))
											]);
									},
									p.gB)),
							_Utils_ap(
								A2(
									$elm$core$Maybe$withDefault,
									_List_Nil,
									A2(
										$elm$core$Maybe$map,
										function (d) {
											return _List_fromArray(
												[
													_Utils_Tuple2(
													'dateMajConnaissance',
													$elm$json$Json$Encode$string(
														$justinmimbs$date$Date$toIsoString(d)))
												]);
										},
										p.gw)),
								_Utils_ap(
									A2(
										$elm$core$Maybe$withDefault,
										_List_Nil,
										A2(
											$elm$core$Maybe$map,
											function (d) {
												return _List_fromArray(
													[
														_Utils_Tuple2(
														'dateMajProfilInvestisseur',
														$elm$json$Json$Encode$string(
															$justinmimbs$date$Date$toIsoString(d)))
													]);
											},
											p.gx)),
									_Utils_ap(
										A2(
											$elm$core$Maybe$withDefault,
											_List_Nil,
											A2(
												$elm$core$Maybe$map,
												function (d) {
													return _List_fromArray(
														[
															_Utils_Tuple2(
															'dateSignatureConventionReceptionTransmissionOrdre',
															$elm$json$Json$Encode$string(
																$justinmimbs$date$Date$toIsoString(d)))
														]);
												},
												p.gA)),
										_List_fromArray(
											[
												_Utils_Tuple2(
												'classificationMifII',
												$elm$json$Json$Encode$object(
													_List_fromArray(
														[
															_Utils_Tuple2(
															'type_',
															$author$project$Components$Clients$APIModel$mifIITypeJsonEncoder(p.ea.kv)),
															_Utils_Tuple2(
															'date',
															$elm$json$Json$Encode$string(
																$justinmimbs$date$Date$toIsoString(p.ea.c$)))
														]))),
												_Utils_Tuple2(
												'connaissanceExperienceFinanciere',
												$author$project$Components$Clients$APIModel$experienceFinanciereJsonEncoder(p.gg)),
												_Utils_Tuple2(
												'profilRisqueInvestisseur',
												$author$project$Components$Clients$APIModel$profilRisqueInvestisseurJsonEncoder(p.ji)),
												_Utils_Tuple2(
												'horizonInvestissement',
												$author$project$Components$Clients$APIModel$horizonInvestissementJsonEncoder(p.hz)),
												_Utils_Tuple2(
												'objectifInvestissement',
												$author$project$Components$Clients$APIModel$objectifInvestissementJsonEncoder(p.it)),
												_Utils_Tuple2(
												'capaciteSubirPertes',
												$author$project$Components$Clients$APIModel$capaciteSubirPertesJsonEncoder(p.f1)),
												_Utils_Tuple2(
												'toleranceAuxRisques',
												$author$project$Components$Clients$APIModel$toleranceAuxRisquesJsonEncoder(p.km))
											]))))))))
			]);
	};
	var produitArticleESGEncoder = function (esg) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'pourcentageAdequationEnvironnement',
					$elm$json$Json$Encode$int(esg.iR)),
					_Utils_Tuple2(
					'pourcentageAdequationSocial',
					$elm$json$Json$Encode$int(esg.iT)),
					_Utils_Tuple2(
					'pourcentageAdequationGouvernance',
					$elm$json$Json$Encode$int(esg.iS))
				]));
	};
	var paiEncoder = function (pai) {
		switch (pai) {
			case 0:
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'GazEffetDeSerre',
							$elm$json$Json$Encode$object(_List_Nil))
						]));
			case 1:
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'NiveauImpactBiodiversite',
							$elm$json$Json$Encode$object(_List_Nil))
						]));
			case 2:
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'EmissionsPolluantesEau',
							$elm$json$Json$Encode$object(_List_Nil))
						]));
			case 3:
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'GenerationDechetsDangereux',
							$elm$json$Json$Encode$object(_List_Nil))
						]));
			case 4:
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'InefficaciteEnergetiqueImmobilier',
							$elm$json$Json$Encode$object(_List_Nil))
						]));
			case 5:
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'RespectDesNormesInternationales',
							$elm$json$Json$Encode$object(_List_Nil))
						]));
			case 6:
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'ProcessusDeControleDesNormesInternationales',
							$elm$json$Json$Encode$object(_List_Nil))
						]));
			case 7:
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'EgaliteRemuneration',
							$elm$json$Json$Encode$object(_List_Nil))
						]));
			case 8:
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'DiversiteDesGenresConseilsAdministration',
							$elm$json$Json$Encode$object(_List_Nil))
						]));
			default:
				return $elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'ExpositionAuxArmesControversees',
							$elm$json$Json$Encode$object(_List_Nil))
						]));
		}
	};
	var profilExtraFinancierEncoder = function (pef) {
		return _List_fromArray(
			[
				_Utils_Tuple2(
				'profilExtraFinancier',
				$elm$json$Json$Encode$object(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'dateRecueilInfo',
							$elm$json$Json$Encode$string(
								$justinmimbs$date$Date$toIsoString(pef.gz))),
							_Utils_Tuple2(
							'priseEnCompte',
							function () {
								var _v1 = pef.i$;
								if (_v1.$ === 1) {
									return $elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'Non',
												$elm$json$Json$Encode$object(_List_Nil))
											]));
								} else {
									var priseEnCompteOui = _v1.a;
									return $elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'Oui',
												$elm$json$Json$Encode$object(
													_List_fromArray(
														[
															_Utils_Tuple2(
															'priseEnCompteTaxonomie',
															function () {
																var _v2 = priseEnCompteOui.i3;
																if (_v2.$ === 1) {
																	return $elm$json$Json$Encode$object(
																		_List_fromArray(
																			[
																				_Utils_Tuple2(
																				'Non',
																				$elm$json$Json$Encode$object(_List_Nil))
																			]));
																} else {
																	var taxonomie = _v2.a;
																	return $elm$json$Json$Encode$object(
																		_List_fromArray(
																			[
																				_Utils_Tuple2(
																				'Oui',
																				$elm$json$Json$Encode$object(
																					_List_fromArray(
																						[
																							_Utils_Tuple2(
																							'pourcentageAdequation',
																							$elm$json$Json$Encode$int(taxonomie.dv))
																						])))
																			]));
																}
															}()),
															_Utils_Tuple2(
															'priseEnCompteSdfr',
															function () {
																var _v3 = priseEnCompteOui.i2;
																if (_v3.$ === 1) {
																	return $elm$json$Json$Encode$object(
																		_List_fromArray(
																			[
																				_Utils_Tuple2(
																				'Non',
																				$elm$json$Json$Encode$object(_List_Nil))
																			]));
																} else {
																	var sdfr = _v3.a;
																	return $elm$json$Json$Encode$object(
																		_List_fromArray(
																			[
																				_Utils_Tuple2(
																				'Oui',
																				$elm$json$Json$Encode$object(
																					_List_fromArray(
																						[
																							_Utils_Tuple2(
																							'produitArticle9',
																							produitArticleESGEncoder(sdfr.jb)),
																							_Utils_Tuple2(
																							'produitArticle8',
																							produitArticleESGEncoder(sdfr.ja)),
																							_Utils_Tuple2(
																							'produitArticle6PourcentageAdequation',
																							$elm$json$Json$Encode$int(sdfr.i9))
																						])))
																			]));
																}
															}()),
															_Utils_Tuple2(
															'priseEnComptePai',
															function () {
																var _v4 = priseEnCompteOui.i1;
																if (_v4.$ === 1) {
																	return $elm$json$Json$Encode$object(
																		_List_fromArray(
																			[
																				_Utils_Tuple2(
																				'Non',
																				$elm$json$Json$Encode$object(_List_Nil))
																			]));
																} else {
																	var pai = _v4.a;
																	return $elm$json$Json$Encode$object(
																		_List_fromArray(
																			[
																				_Utils_Tuple2(
																				'Oui',
																				$elm$json$Json$Encode$object(
																					_List_fromArray(
																						[
																							_Utils_Tuple2(
																							'pourcentageAdequation',
																							$elm$json$Json$Encode$int(pai.dv)),
																							_Utils_Tuple2(
																							'paiChoisis',
																							A2($elm$json$Json$Encode$list, paiEncoder, pai.iz))
																						])))
																			]));
																}
															}())
														])))
											]));
								}
							}())
						])))
			]);
	};
	var nature = function () {
		var _v0 = client.ic;
		if (!_v0.$) {
			var personnePhysiqueParams = _v0.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'PersonnePhysique',
						$author$project$Components$Clients$APIModel$personnePhysiqueJsonEncoder(personnePhysiqueParams))
					]));
		} else {
			var personneMoraleParams = _v0.a;
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'PersonneMorale',
						$author$project$Components$Clients$APIModel$personneMoraleJsonEncoder(personneMoraleParams))
					]));
		}
	}();
	var contact = $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'email',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, client.cY.gU))),
				_Utils_Tuple2(
				'telephones',
				A2($elm$json$Json$Encode$list, $elm$json$Json$Encode$string, client.cY.kf)),
				_Utils_Tuple2(
				'adresseFiscale',
				$author$project$Components$Clients$APIModel$adresseJsonEncoder(client.cY.fE))
			]));
	return $elm$json$Json$Encode$object(
		_Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2('nature', nature),
					_Utils_Tuple2('contact', contact),
					_Utils_Tuple2(
					'conseillers',
					A2(
						$elm$json$Json$Encode$list,
						$elm$json$Json$Encode$string,
						$mgold$elm_nonempty_list$List$Nonempty$toList(client.ef)))
				]),
			_Utils_ap(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Maybe$map, profilInvestisseur, client.jh)),
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Maybe$map, profilExtraFinancierEncoder, client.jg)))));
};
var $author$project$Components$Clients$QueryAPIModel$ClientQueryView = F9(
	function (uid, nature, contact, profilInvestisseur, profilExtraFinancier, conseillers, toComplete, externalSystemSync, links) {
		return {ef: conseillers, cY: contact, mi: externalSystemSync, nf: links, ic: nature, jg: profilExtraFinancier, jh: profilInvestisseur, oR: toComplete, kx: uid};
	});
var $author$project$Components$Clients$QueryAPIModel$ContactQ = F3(
	function (email, telephones, adresseFiscale) {
		return {fE: adresseFiscale, gU: email, kf: telephones};
	});
var $author$project$Components$Clients$QueryAPIModel$AdresseQ = F5(
	function (addr1, addr2, codePostal, ville, pays) {
		return {fB: addr1, fC: addr2, gc: codePostal, dq: pays, d_: ville};
	});
var $author$project$Components$Clients$QueryAPIModel$adresseJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'pays',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'ville',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'codePostal',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'addr2',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'addr1',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
					$elm$core$Maybe$Nothing,
					$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$AdresseQ))))));
var $author$project$Components$Clients$QueryAPIModel$contactJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'adresseFiscale',
	$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$adresseJsonDecoder),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'telephones',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'email',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$ContactQ))));
var $author$project$Components$Clients$QueryAPIModel$Harvest = 0;
var $author$project$Components$Clients$QueryAPIModel$externalSystemSyncDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Harvest',
			$elm$json$Json$Decode$succeed(0))
		]));
var $author$project$Components$Clients$QueryAPIModel$PersonneMoraleQ = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$QueryAPIModel$PersonnePhysiqueQ = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$QueryAPIModel$PersonneMoraleParamsQ = function (nom) {
	return function (formeJuridique) {
		return function (siren) {
			return function (villeTribunalCommerce) {
				return function (capitalSocial) {
					return function (typologie) {
						return function (compteResultat) {
							return function (bilan) {
								return function (fiscalite) {
									return function (representantsLegaux) {
										return function (evaluationRisqueLCBFT) {
											return {fS: bilan, f2: capitalSocial, ge: compteResultat, c5: evaluationRisqueLCBFT, db: fiscalite, hd: formeJuridique, dl: nom, dA: representantsLegaux, jR: siren, dY: typologie, kG: villeTribunalCommerce};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Clients$QueryAPIModel$BilanQ = F3(
	function (actifAnnuel, passifAnnuel, capitauxPropres) {
		return {cM: actifAnnuel, f3: capitauxPropres, iC: passifAnnuel};
	});
var $author$project$Components$Clients$QueryAPIModel$ActifAnnuelQ = F3(
	function (brut, financier, nonFinancier) {
		return {cR: brut, da: financier, im: nonFinancier};
	});
var $author$project$Components$Clients$QueryAPIModel$actifAnnuelJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'nonFinancier',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'financier',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'brut',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
			$elm$core$Maybe$Nothing,
			$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$ActifAnnuelQ))));
var $author$project$Components$Clients$QueryAPIModel$bilanJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'capitauxPropres',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'passifAnnuel',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'actifAnnuel',
			$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$actifAnnuelJsonDecoder),
			$elm$core$Maybe$Nothing,
			$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$BilanQ))));
var $author$project$Components$Clients$QueryAPIModel$CompteResultatQ = F3(
	function (produitsAnnuels, charges_annuelles, resultat_annuel) {
		return {f6: charges_annuelles, jd: produitsAnnuels, jw: resultat_annuel};
	});
var $author$project$Components$Clients$QueryAPIModel$compteResultatJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'resultat_annuel',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'charges_annuelles',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'produitsAnnuels',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
			$elm$core$Maybe$Nothing,
			$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$CompteResultatQ))));
var $author$project$Components$Clients$QueryAPIModel$FiscaliteQ = F2(
	function (tauxImposition, montantsImpots) {
		return {h9: montantsImpots, kc: tauxImposition};
	});
var $author$project$Components$Clients$QueryAPIModel$fiscaliteJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'montantsImpots',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'tauxImposition',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$FiscaliteQ)));
var $author$project$Components$Clients$APIModel$personneMorale_TypologieJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'OrganismeSansButLucratif',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'InvestisseursInstitutionnels',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'IntermediairesFinanciers',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'Entreprises_ChefEntreprise',
			$elm$json$Json$Decode$succeed(3))
		]));
var $author$project$Components$Clients$QueryAPIModel$RepresentantLegalQ = function (identite) {
	return function (naissance) {
		return function (pieceIdentite) {
			return function (nationalite) {
				return function (autreNationalites) {
					return function (usPerson) {
						return function (adresse) {
							return function (mail) {
								return function (fonction) {
									return function (pseudoId) {
										return {fD: adresse, bf: autreNationalites, g9: fonction, aD: identite, h_: mail, aI: naissance, bD: nationalite, du: pieceIdentite, jj: pseudoId, bV: usPerson};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Clients$QueryAPIModel$IdentiteQ = F4(
	function (civilite, nom, nomNaissance, prenom) {
		return {f8: civilite, dl: nom, ii: nomNaissance, iV: prenom};
	});
var $author$project$Components$Clients$APIModel$civiliteJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Monsieur',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'Madame',
			$elm$json$Json$Decode$succeed(1))
		]));
var $author$project$Components$Clients$QueryAPIModel$identiteJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'prenom',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'nomNaissance',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'nom',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'civilite',
				$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$civiliteJsonDecoder),
				$elm$core$Maybe$Nothing,
				$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$IdentiteQ)))));
var $author$project$Components$Clients$QueryAPIModel$NaissanceQ = F3(
	function (date, ville, pays) {
		return {c$: date, dq: pays, d_: ville};
	});
var $author$project$Components$Clients$QueryAPIModel$naissanceJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'pays',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'ville',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'date',
			$elm$json$Json$Decode$nullable($author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder),
			$elm$core$Maybe$Nothing,
			$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$NaissanceQ))));
var $author$project$Components$Clients$QueryAPIModel$PieceIdentiteQ = F4(
	function (numero, typeDePiece, dateDelivrance, dateExpiration) {
		return {gr: dateDelivrance, gu: dateExpiration, is: numero, ks: typeDePiece};
	});
var $author$project$Components$Clients$APIModel$pieceIdentiteTypeJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'CarteNationaleIdentite',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'Passeport',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'CarteDeRessortissantUE_EEE',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'CarteDeSejour',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'PasseportNonResident',
			$elm$json$Json$Decode$succeed(4)),
			A2(
			$elm$json$Json$Decode$field,
			'OrdonnanceDeProtection',
			$elm$json$Json$Decode$succeed(5)),
			A2(
			$elm$json$Json$Decode$field,
			'CarteDeResident',
			$elm$json$Json$Decode$succeed(6)),
			A2(
			$elm$json$Json$Decode$field,
			'CarteDeCommercantEtranger',
			$elm$json$Json$Decode$succeed(7)),
			A2(
			$elm$json$Json$Decode$field,
			'PermisDeConduire',
			$elm$json$Json$Decode$succeed(8)),
			A2(
			$elm$json$Json$Decode$field,
			'AutrePieceIdentite',
			$elm$json$Json$Decode$succeed(9))
		]));
var $author$project$Components$Clients$QueryAPIModel$pieceIdentiteJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'dateExpiration',
	$elm$json$Json$Decode$nullable($author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'dateDelivrance',
		$elm$json$Json$Decode$nullable($author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'typeDePiece',
			$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$pieceIdentiteTypeJsonDecoder),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'numero',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
				$elm$core$Maybe$Nothing,
				$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$PieceIdentiteQ)))));
var $author$project$Components$Clients$QueryAPIModel$representantLegalJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'pseudoId',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'fonction',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'mail',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'adresse',
				$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$adresseJsonDecoder),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'usPerson',
					$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
					$elm$core$Maybe$Nothing,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'autreNationalites',
						$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'nationalite',
							$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'pieceIdentite',
								$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$pieceIdentiteJsonDecoder),
								$elm$core$Maybe$Nothing,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'naissance',
									$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$naissanceJsonDecoder),
									$elm$core$Maybe$Nothing,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'identite',
										$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$identiteJsonDecoder),
										$elm$core$Maybe$Nothing,
										$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$RepresentantLegalQ)))))))))));
var $author$project$Components$Clients$APIModel$risqueLCBFTJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Faible',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'Moyen',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'Fort',
			$elm$json$Json$Decode$succeed(2))
		]));
var $author$project$Components$Clients$QueryAPIModel$personeMoraleJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'evaluationRisqueLCBFT',
	$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$risqueLCBFTJsonDecoder),
	$elm$core$Maybe$Nothing,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'representantsLegaux',
		$elm$json$Json$Decode$list($author$project$Components$Clients$QueryAPIModel$representantLegalJsonDecoder),
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'fiscalite',
			$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$fiscaliteJsonDecoder),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'bilan',
				$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$bilanJsonDecoder),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'compteResultat',
					$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$compteResultatJsonDecoder),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'typologie',
						$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$personneMorale_TypologieJsonDecoder),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'capitalSocial',
							$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'villeTribunalCommerce',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
								$elm$core$Maybe$Nothing,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'siren',
									$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
									$elm$core$Maybe$Nothing,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'formeJuridique',
										$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
										$elm$core$Maybe$Nothing,
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'nom',
											$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
											$elm$core$Maybe$Nothing,
											$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$PersonneMoraleParamsQ))))))))))));
var $author$project$Components$Clients$QueryAPIModel$PersonnePhysiqueParamsQ = function (identite) {
	return function (naissance) {
		return function (pieceIdentite) {
			return function (deces) {
				return function (nationalite) {
					return function (autreNationalites) {
						return function (usPerson) {
							return function (paysDeResidenceFiscale) {
								return function (dateDepartRetraite) {
									return function (capaciteJuridique) {
										return function (profession) {
											return function (situationFamiliale) {
												return function (situationPatrimoniale) {
													return function (representantsLegaux) {
														return function (typologie) {
															return function (evaluationRisqueLCBFT) {
																return function (personnePolitiquementExposee) {
																	return function (personneVulnerable) {
																		return {bf: autreNationalites, f0: capaciteJuridique, gs: dateDepartRetraite, gF: deces, c5: evaluationRisqueLCBFT, aD: identite, aI: naissance, bD: nationalite, iE: paysDeResidenceFiscale, iO: personnePolitiquementExposee, iP: personneVulnerable, du: pieceIdentite, dw: profession, dA: representantsLegaux, jS: situationFamiliale, jV: situationPatrimoniale, dY: typologie, bV: usPerson};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Clients$APIModel$capaciteJuridiqueJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'MajeurCapable',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'MajeurProtegeSousTutelle',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'MajeurProtegeSousCuratelle',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'MajeurSousSauvegardeDeJustice',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'AbsentOuAutreMajeurIncapable',
			$elm$json$Json$Decode$succeed(4)),
			A2(
			$elm$json$Json$Decode$field,
			'MineurSousTutelle',
			$elm$json$Json$Decode$succeed(5)),
			A2(
			$elm$json$Json$Decode$field,
			'MineurEmancipe',
			$elm$json$Json$Decode$succeed(6)),
			A2(
			$elm$json$Json$Decode$field,
			'MineurSousControleJudiciaire',
			$elm$json$Json$Decode$succeed(7)),
			A2(
			$elm$json$Json$Decode$field,
			'MineurNonEmancipe',
			$elm$json$Json$Decode$succeed(8))
		]));
var $author$project$Components$Clients$APIModel$personnePhysique_TypologieJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Epargnants_Particulier',
			$elm$json$Json$Decode$succeed(0))
		]));
var $author$project$Components$Clients$QueryAPIModel$SituationFamilialeQ = F4(
	function (situationFamille, conjointPartenaire, nombreEnfantACharge, enfants) {
		return {gf: conjointPartenaire, gX: enfants, ij: nombreEnfantACharge, jT: situationFamille};
	});
var $author$project$Components$Clients$QueryAPIModel$ReferencedClientQ = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$QueryAPIModel$UnreferencedPersonQ = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$QueryAPIModel$ReferencedClientParamsQ = F3(
	function (uid, dateMariage, regimeMatrimoniale) {
		return {c0: dateMariage, dy: regimeMatrimoniale, kx: uid};
	});
var $author$project$Components$Clients$APIModel$regimeMatrimonialeJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'CommunauteReduiteAuxAcquets',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'SeparationDesBiens',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'ParticipationAuxAcquets',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'CommunauteUniverselle',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'CommunauteUniverselleIntegrale',
			$elm$json$Json$Decode$succeed(4)),
			A2(
			$elm$json$Json$Decode$field,
			'CommunauteDeMeublesEtAcquets',
			$elm$json$Json$Decode$succeed(5)),
			A2(
			$elm$json$Json$Decode$field,
			'ParticipationOptionnelleAuxAcquets',
			$elm$json$Json$Decode$succeed(6)),
			A2(
			$elm$json$Json$Decode$field,
			'SeparationDesBiensAvecSocieteAcquets',
			$elm$json$Json$Decode$succeed(7)),
			A2(
			$elm$json$Json$Decode$field,
			'RegimeEtranger',
			$elm$json$Json$Decode$succeed(8))
		]));
var $author$project$Components$Clients$QueryAPIModel$referencedClientParamsJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'regimeMatrimoniale',
	$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$regimeMatrimonialeJsonDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'dateMariage',
		$elm$json$Json$Decode$nullable($author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'uid',
			$elm$json$Json$Decode$string,
			$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$ReferencedClientParamsQ))));
var $author$project$Components$Clients$QueryAPIModel$UnreferencedPersonParamsQ = F8(
	function (identite, naissance, nationalite, autreNationalites, usPerson, profession, dateMariage, regimeMatrimoniale) {
		return {bf: autreNationalites, c0: dateMariage, aD: identite, aI: naissance, bD: nationalite, dw: profession, dy: regimeMatrimoniale, bV: usPerson};
	});
var $author$project$Components$Clients$QueryAPIModel$unreferencedPersonParamsJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'regimeMatrimoniale',
	$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$regimeMatrimonialeJsonDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'dateMariage',
		$elm$json$Json$Decode$nullable($author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'profession',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'usPerson',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
				$elm$core$Maybe$Nothing,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'autreNationalites',
					$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'nationalite',
						$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'naissance',
							$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$naissanceJsonDecoder),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'identite',
								$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$identiteJsonDecoder),
								$elm$core$Maybe$Nothing,
								$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$UnreferencedPersonParamsQ)))))))));
var $author$project$Components$Clients$QueryAPIModel$conjointPartenaireJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'ReferencedClient',
			A2($elm$json$Json$Decode$map, $author$project$Components$Clients$QueryAPIModel$ReferencedClientQ, $author$project$Components$Clients$QueryAPIModel$referencedClientParamsJsonDecoder)),
			A2(
			$elm$json$Json$Decode$field,
			'UnreferencedPerson',
			A2($elm$json$Json$Decode$map, $author$project$Components$Clients$QueryAPIModel$UnreferencedPersonQ, $author$project$Components$Clients$QueryAPIModel$unreferencedPersonParamsJsonDecoder))
		]));
var $author$project$Components$Clients$QueryAPIModel$EnfantQ = F2(
	function (identite, naissance) {
		return {aD: identite, aI: naissance};
	});
var $author$project$Components$Clients$QueryAPIModel$enfantJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'naissance',
	$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$naissanceJsonDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'identite',
		$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$identiteJsonDecoder),
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$EnfantQ)));
var $author$project$Components$Clients$APIModel$situationFamilleJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Celibataire',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'Marie',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'Separe',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'Divorce',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'Veuf',
			$elm$json$Json$Decode$succeed(4)),
			A2(
			$elm$json$Json$Decode$field,
			'UnionLibre',
			$elm$json$Json$Decode$succeed(5)),
			A2(
			$elm$json$Json$Decode$field,
			'Pacse',
			$elm$json$Json$Decode$succeed(6)),
			A2(
			$elm$json$Json$Decode$field,
			'NonDefinie',
			$elm$json$Json$Decode$succeed(7))
		]));
var $author$project$Components$Clients$QueryAPIModel$situationFamilialeJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'enfants',
	$elm$json$Json$Decode$list($author$project$Components$Clients$QueryAPIModel$enfantJsonDecoder),
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'nombreEnfantACharge',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'conjointPartenaire',
			$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$conjointPartenaireJsonDecoder),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'situationFamille',
				$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$situationFamilleJsonDecoder),
				$elm$core$Maybe$Nothing,
				$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$SituationFamilialeQ)))));
var $author$project$Components$Clients$QueryAPIModel$SituationPatrimonialeQ = F4(
	function (fiscalite, patrimoine, budget, tauxEndettement) {
		return {fX: budget, db: fiscalite, iD: patrimoine, kb: tauxEndettement};
	});
var $author$project$Components$Clients$QueryAPIModel$BudgetQ = F2(
	function (revenusAnnuels, chargesAnnuelles) {
		return {f5: chargesAnnuelles, jy: revenusAnnuels};
	});
var $author$project$Components$Clients$QueryAPIModel$budgetJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'chargesAnnuelles',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'revenusAnnuels',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$BudgetQ)));
var $author$project$Components$Clients$QueryAPIModel$FiscalitePatrimonialeQ = F4(
	function (nombrePartsFiscales, montantIR, montant_IFI, tmi) {
		return {h7: montantIR, h8: montant_IFI, ik: nombrePartsFiscales, kj: tmi};
	});
var $author$project$Components$Clients$QueryAPIModel$fiscalitePatrimonialeJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'tmi',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'montant_IFI',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'montantIR',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'nombrePartsFiscales',
				$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
				$elm$core$Maybe$Nothing,
				$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$FiscalitePatrimonialeQ)))));
var $author$project$Components$Clients$QueryAPIModel$PatrimoineQ = F2(
	function (actif, passif) {
		return {kP: actif, iB: passif};
	});
var $author$project$Components$Clients$QueryAPIModel$PatrimoineActifQ = F3(
	function (brut, financier, non_financier) {
		return {cR: brut, da: financier, io: non_financier};
	});
var $author$project$Components$Clients$QueryAPIModel$patrimoineActifJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'non_financier',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'financier',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'brut',
			$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
			$elm$core$Maybe$Nothing,
			$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$PatrimoineActifQ))));
var $author$project$Components$Clients$QueryAPIModel$patrimoineJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'passif',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'actif',
		$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$patrimoineActifJsonDecoder),
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$PatrimoineQ)));
var $author$project$Components$Clients$QueryAPIModel$situationPatrimonialeJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'tauxEndettement',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$float),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'budget',
		$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$budgetJsonDecoder),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'patrimoine',
			$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$patrimoineJsonDecoder),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'fiscalite',
				$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$fiscalitePatrimonialeJsonDecoder),
				$elm$core$Maybe$Nothing,
				$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$SituationPatrimonialeQ)))));
var $author$project$Components$Clients$QueryAPIModel$personePhysiqueJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'personneVulnerable',
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'personnePolitiquementExposee',
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'evaluationRisqueLCBFT',
			$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$risqueLCBFTJsonDecoder),
			$elm$core$Maybe$Nothing,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'typologie',
				$author$project$Components$Clients$APIModel$personnePhysique_TypologieJsonDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'representantsLegaux',
					$elm$json$Json$Decode$list($author$project$Components$Clients$QueryAPIModel$representantLegalJsonDecoder),
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'situationPatrimoniale',
						$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$situationPatrimonialeJsonDecoder),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'situationFamiliale',
							$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$situationFamilialeJsonDecoder),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'profession',
								$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
								$elm$core$Maybe$Nothing,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'capaciteJuridique',
									$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$capaciteJuridiqueJsonDecoder),
									$elm$core$Maybe$Nothing,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'dateDepartRetraite',
										$elm$json$Json$Decode$nullable($author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder),
										$elm$core$Maybe$Nothing,
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'paysDeResidenceFiscale',
											$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
											$elm$core$Maybe$Nothing,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'usPerson',
												$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool),
												$elm$core$Maybe$Nothing,
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'autreNationalites',
													$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
													A4(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
														'nationalite',
														$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string),
														$elm$core$Maybe$Nothing,
														A4(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
															'deces',
															$elm$json$Json$Decode$nullable($author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder),
															$elm$core$Maybe$Nothing,
															A4(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																'pieceIdentite',
																$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$pieceIdentiteJsonDecoder),
																$elm$core$Maybe$Nothing,
																A4(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																	'naissance',
																	$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$naissanceJsonDecoder),
																	$elm$core$Maybe$Nothing,
																	A4(
																		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																		'identite',
																		$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$identiteJsonDecoder),
																		$elm$core$Maybe$Nothing,
																		$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$PersonnePhysiqueParamsQ)))))))))))))))))));
var $author$project$Components$Clients$QueryAPIModel$natureJuridiqueJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'PersonnePhysique',
			A2($elm$json$Json$Decode$map, $author$project$Components$Clients$QueryAPIModel$PersonnePhysiqueQ, $author$project$Components$Clients$QueryAPIModel$personePhysiqueJsonDecoder)),
			A2(
			$elm$json$Json$Decode$field,
			'PersonneMorale',
			A2($elm$json$Json$Decode$map, $author$project$Components$Clients$QueryAPIModel$PersonneMoraleQ, $author$project$Components$Clients$QueryAPIModel$personeMoraleJsonDecoder))
		]));
var $author$project$Components$Clients$QueryAPIModel$ProfilExtraFinancierQ = F2(
	function (dateRecueilInfo, priseEnCompte) {
		return {gz: dateRecueilInfo, i$: priseEnCompte};
	});
var $author$project$Components$Clients$QueryAPIModel$PriseEnCompteESG_NonQ = {$: 1};
var $author$project$Components$Clients$QueryAPIModel$PriseEnCompteESG_OuiQ = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$QueryAPIModel$PriseEnCompteESG_Oui_RecordQ = F3(
	function (priseEnCompteTaxonomie, priseEnCompteSdfr, priseEnComptePai) {
		return {i1: priseEnComptePai, i2: priseEnCompteSdfr, i3: priseEnCompteTaxonomie};
	});
var $author$project$Components$Clients$QueryAPIModel$PriseEnComptePAI_NonQ = {$: 1};
var $author$project$Components$Clients$QueryAPIModel$PriseEnComptePAI_OuiQ = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$QueryAPIModel$PriseEnComptePAI_Oui_RecordQ = F2(
	function (pourcentageAdequation, paiChoisis) {
		return {iz: paiChoisis, dv: pourcentageAdequation};
	});
var $author$project$Components$Clients$QueryAPIModel$DiversiteDesGenresConseilsAdministration = 8;
var $author$project$Components$Clients$QueryAPIModel$EgaliteRemuneration = 7;
var $author$project$Components$Clients$QueryAPIModel$EmissionsPolluantesEau = 2;
var $author$project$Components$Clients$QueryAPIModel$ExpositionAuxArmesControversees = 9;
var $author$project$Components$Clients$QueryAPIModel$GazEffetDeSerre = 0;
var $author$project$Components$Clients$QueryAPIModel$GenerationDechetsDangereux = 3;
var $author$project$Components$Clients$QueryAPIModel$InefficaciteEnergetiqueImmobilier = 4;
var $author$project$Components$Clients$QueryAPIModel$NiveauImpactBiodiversite = 1;
var $author$project$Components$Clients$QueryAPIModel$ProcessusDeControleDesNormesInternationales = 6;
var $author$project$Components$Clients$QueryAPIModel$RespectDesNormesInternationales = 5;
var $author$project$Components$Clients$QueryAPIModel$paiQJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'GazEffetDeSerre',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'NiveauImpactBiodiversite',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'EmissionsPolluantesEau',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'GenerationDechetsDangereux',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'InefficaciteEnergetiqueImmobilier',
			$elm$json$Json$Decode$succeed(4)),
			A2(
			$elm$json$Json$Decode$field,
			'RespectDesNormesInternationales',
			$elm$json$Json$Decode$succeed(5)),
			A2(
			$elm$json$Json$Decode$field,
			'ProcessusDeControleDesNormesInternationales',
			$elm$json$Json$Decode$succeed(6)),
			A2(
			$elm$json$Json$Decode$field,
			'EgaliteRemuneration',
			$elm$json$Json$Decode$succeed(7)),
			A2(
			$elm$json$Json$Decode$field,
			'DiversiteDesGenresConseilsAdministration',
			$elm$json$Json$Decode$succeed(8)),
			A2(
			$elm$json$Json$Decode$field,
			'ExpositionAuxArmesControversees',
			$elm$json$Json$Decode$succeed(9))
		]));
var $author$project$Components$Clients$QueryAPIModel$priseEnComptePai_Oui_RecordQJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'paiChoisis',
	$elm$json$Json$Decode$list($author$project$Components$Clients$QueryAPIModel$paiQJsonDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'pourcentageAdequation',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$PriseEnComptePAI_Oui_RecordQ)));
var $author$project$Components$Clients$QueryAPIModel$priseEnComptePAIQJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Oui',
			A2($elm$json$Json$Decode$map, $author$project$Components$Clients$QueryAPIModel$PriseEnComptePAI_OuiQ, $author$project$Components$Clients$QueryAPIModel$priseEnComptePai_Oui_RecordQJsonDecoder)),
			A2(
			$elm$json$Json$Decode$field,
			'Non',
			$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$PriseEnComptePAI_NonQ))
		]));
var $author$project$Components$Clients$QueryAPIModel$PriseEnCompteSFDR_NonQ = {$: 1};
var $author$project$Components$Clients$QueryAPIModel$PriseEnCompteSFDR_OuiQ = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$QueryAPIModel$PriseEnCompteSFDR_Oui_RecordQ = F3(
	function (produitArticle9, produitArticle8, produitArticle6PourcentageAdequation) {
		return {i9: produitArticle6PourcentageAdequation, ja: produitArticle8, jb: produitArticle9};
	});
var $author$project$Components$Clients$QueryAPIModel$ProduitArticleESGQ = F3(
	function (pourcentageAdequationEnvironnement, pourcentageAdequationSocial, pourcentageAdequationGouvernance) {
		return {iR: pourcentageAdequationEnvironnement, iS: pourcentageAdequationGouvernance, iT: pourcentageAdequationSocial};
	});
var $author$project$Components$Clients$QueryAPIModel$produitArticleESGQJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'pourcentageAdequationGouvernance',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'pourcentageAdequationSocial',
		$elm$json$Json$Decode$int,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'pourcentageAdequationEnvironnement',
			$elm$json$Json$Decode$int,
			$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$ProduitArticleESGQ))));
var $author$project$Components$Clients$QueryAPIModel$priseEnCompteSdfrOuiQJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'produitArticle6PourcentageAdequation',
	$elm$json$Json$Decode$int,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'produitArticle8',
		$author$project$Components$Clients$QueryAPIModel$produitArticleESGQJsonDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'produitArticle9',
			$author$project$Components$Clients$QueryAPIModel$produitArticleESGQJsonDecoder,
			$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$PriseEnCompteSFDR_Oui_RecordQ))));
var $author$project$Components$Clients$QueryAPIModel$priseEnCompteSdfrQJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Oui',
			A2($elm$json$Json$Decode$map, $author$project$Components$Clients$QueryAPIModel$PriseEnCompteSFDR_OuiQ, $author$project$Components$Clients$QueryAPIModel$priseEnCompteSdfrOuiQJsonDecoder)),
			A2(
			$elm$json$Json$Decode$field,
			'Non',
			$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$PriseEnCompteSFDR_NonQ))
		]));
var $author$project$Components$Clients$QueryAPIModel$PriseEnCompteTaxonomie_NonQ = {$: 1};
var $author$project$Components$Clients$QueryAPIModel$PriseEnCompteTaxonomie_OuiQ = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$QueryAPIModel$PriseEnCompteTaxonomie_Oui_RecordQ = function (pourcentageAdequation) {
	return {dv: pourcentageAdequation};
};
var $author$project$Components$Clients$QueryAPIModel$priseEnCompteTaxonomie_Oui_RecordQJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'pourcentageAdequation',
	$elm$json$Json$Decode$int,
	$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$PriseEnCompteTaxonomie_Oui_RecordQ));
var $author$project$Components$Clients$QueryAPIModel$priseEnCompteTaxonomieQJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Oui',
			A2($elm$json$Json$Decode$map, $author$project$Components$Clients$QueryAPIModel$PriseEnCompteTaxonomie_OuiQ, $author$project$Components$Clients$QueryAPIModel$priseEnCompteTaxonomie_Oui_RecordQJsonDecoder)),
			A2(
			$elm$json$Json$Decode$field,
			'Non',
			$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$PriseEnCompteTaxonomie_NonQ))
		]));
var $author$project$Components$Clients$QueryAPIModel$priseEnCompteESGOuiRecordQJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'priseEnComptePai',
	$author$project$Components$Clients$QueryAPIModel$priseEnComptePAIQJsonDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'priseEnCompteSdfr',
		$author$project$Components$Clients$QueryAPIModel$priseEnCompteSdfrQJsonDecoder,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'priseEnCompteTaxonomie',
			$author$project$Components$Clients$QueryAPIModel$priseEnCompteTaxonomieQJsonDecoder,
			$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$PriseEnCompteESG_Oui_RecordQ))));
var $author$project$Components$Clients$QueryAPIModel$priseEnCompteESGQJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Oui',
			A2($elm$json$Json$Decode$map, $author$project$Components$Clients$QueryAPIModel$PriseEnCompteESG_OuiQ, $author$project$Components$Clients$QueryAPIModel$priseEnCompteESGOuiRecordQJsonDecoder)),
			A2(
			$elm$json$Json$Decode$field,
			'Non',
			$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$PriseEnCompteESG_NonQ))
		]));
var $author$project$Components$Clients$QueryAPIModel$profilExtraFinancierJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'priseEnCompte',
	$author$project$Components$Clients$QueryAPIModel$priseEnCompteESGQJsonDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'dateRecueilInfo',
		$author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder,
		$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$ProfilExtraFinancierQ)));
var $author$project$Components$Clients$QueryAPIModel$ProfilInvestisseurQ = function (dateEntreeRelation) {
	return function (dateTransformationRelation) {
		return function (dateMajConnaissance) {
			return function (dateMajProfilInvestisseur) {
				return function (dateSignatureConventionReceptionTransmissionOrdre) {
					return function (classificationMifII) {
						return function (connaissanceExperienceFinanciere) {
							return function (profilRisqueInvestisseur) {
								return function (horizonInvestissement) {
									return function (objectifInvestissement) {
										return function (capaciteSubirPertes) {
											return function (toleranceAuxRisques) {
												return {f1: capaciteSubirPertes, ea: classificationMifII, gg: connaissanceExperienceFinanciere, gt: dateEntreeRelation, gw: dateMajConnaissance, gx: dateMajProfilInvestisseur, gA: dateSignatureConventionReceptionTransmissionOrdre, gB: dateTransformationRelation, hz: horizonInvestissement, it: objectifInvestissement, ji: profilRisqueInvestisseur, km: toleranceAuxRisques};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Clients$APIModel$capaciteSubirPertesJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'AucunePerteToleree_0pct',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'PerteFinanciereLimiteeInf_10pct',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'PerteFinanciereSignificativeEntre_10et50pct',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'PerteFinanciereAConcurrenceDesMontantsInvestis_100pct',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'PerteFinanciereAuDelaDesMontantsInvestisSup_100pct',
			$elm$json$Json$Decode$succeed(4))
		]));
var $author$project$Components$Clients$QueryAPIModel$ClassificationMifIIQ = F2(
	function (type_, date) {
		return {c$: date, kv: type_};
	});
var $author$project$Components$Clients$APIModel$mifIITypeJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'ClientProfessionnel',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'ClientProfessionnelSurOption',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'ClientNonProfessionnel',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'ClientNonProfessionnelSurOption',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'ContrepartieEligible',
			$elm$json$Json$Decode$succeed(4))
		]));
var $author$project$Components$Clients$QueryAPIModel$classificationMifIIJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'date',
	$elm$json$Json$Decode$nullable($author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'type_',
		$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$mifIITypeJsonDecoder),
		$elm$core$Maybe$Nothing,
		$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$ClassificationMifIIQ)));
var $author$project$Components$Clients$APIModel$experienceFinanciereJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Novice',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'Informe',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'Experimente',
			$elm$json$Json$Decode$succeed(2))
		]));
var $author$project$Components$Clients$APIModel$horizonInvestissementJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'HorizonTresCourtTermeInf_1an',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'HorizonCourtTermeEntre_1et3an',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'HorizonMoyenTermeEntre_3et5an',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'HorizonLongTermeSup_5an',
			$elm$json$Json$Decode$succeed(3))
		]));
var $author$project$Components$Clients$APIModel$objectifInvestissementJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Conservation',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'Croissance',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'Rendement',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'Couverture',
			$elm$json$Json$Decode$succeed(3))
		]));
var $author$project$Components$Clients$APIModel$risqueInvestisseurJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Securitaire',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'Defensif',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'Equilibre',
			$elm$json$Json$Decode$succeed(2)),
			A2(
			$elm$json$Json$Decode$field,
			'Dynamique',
			$elm$json$Json$Decode$succeed(3)),
			A2(
			$elm$json$Json$Decode$field,
			'Offensif',
			$elm$json$Json$Decode$succeed(4))
		]));
var $author$project$Components$Clients$APIModel$toleranceAuxRisquesJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Faible',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'Moyenne',
			$elm$json$Json$Decode$succeed(1)),
			A2(
			$elm$json$Json$Decode$field,
			'Elevee',
			$elm$json$Json$Decode$succeed(2))
		]));
var $author$project$Components$Clients$QueryAPIModel$profilInvestisseurJsonDecoder = A4(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
	'toleranceAuxRisques',
	$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$toleranceAuxRisquesJsonDecoder),
	$elm$core$Maybe$Nothing,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'capaciteSubirPertes',
		$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$capaciteSubirPertesJsonDecoder),
		$elm$core$Maybe$Nothing,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'objectifInvestissement',
			$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$objectifInvestissementJsonDecoder),
			$elm$core$Maybe$Nothing,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'horizonInvestissement',
				$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$horizonInvestissementJsonDecoder),
				$elm$core$Maybe$Nothing,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'profilRisqueInvestisseur',
					$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$risqueInvestisseurJsonDecoder),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'connaissanceExperienceFinanciere',
						$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$experienceFinanciereJsonDecoder),
						$elm$core$Maybe$Nothing,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'classificationMifII',
							$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$classificationMifIIJsonDecoder),
							$elm$core$Maybe$Nothing,
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'dateSignatureConventionReceptionTransmissionOrdre',
								$elm$json$Json$Decode$nullable($author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder),
								$elm$core$Maybe$Nothing,
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'dateMajProfilInvestisseur',
									$elm$json$Json$Decode$nullable($author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder),
									$elm$core$Maybe$Nothing,
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'dateMajConnaissance',
										$elm$json$Json$Decode$nullable($author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder),
										$elm$core$Maybe$Nothing,
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'dateTransformationRelation',
											$elm$json$Json$Decode$nullable($author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder),
											$elm$core$Maybe$Nothing,
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'dateEntreeRelation',
												$elm$json$Json$Decode$nullable($author$project$Main$Bindings$Json$CommonJsonBindings$dateJsonDecoder),
												$elm$core$Maybe$Nothing,
												$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$ProfilInvestisseurQ)))))))))))));
var $author$project$Components$Clients$QueryAPIModel$clientQueryViewJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'externalSystemSync',
		$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$externalSystemSyncDecoder),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'toComplete',
			$elm$json$Json$Decode$bool,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'conseillers',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'profilExtraFinancier',
					$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$profilExtraFinancierJsonDecoder),
					$elm$core$Maybe$Nothing,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'profilInvestisseur',
						$elm$json$Json$Decode$nullable($author$project$Components$Clients$QueryAPIModel$profilInvestisseurJsonDecoder),
						$elm$core$Maybe$Nothing,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'contact',
							$author$project$Components$Clients$QueryAPIModel$contactJsonDecoder,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'nature',
								$author$project$Components$Clients$QueryAPIModel$natureJuridiqueJsonDecoder,
								A3(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
									'uid',
									$elm$json$Json$Decode$string,
									$elm$json$Json$Decode$succeed($author$project$Components$Clients$QueryAPIModel$ClientQueryView))))))))));
var $author$project$Perfimmo$Api$CRUDResultWithFeedback$CRUDResultWithFeedback = F2(
	function (uid, resultType) {
		return {jv: resultType, kx: uid};
	});
var $author$project$Perfimmo$Api$CRUDResultWithFeedback$Error = function (a) {
	return {$: 2, a: a};
};
var $author$project$Perfimmo$Api$CRUDResultWithFeedback$Success = function (a) {
	return {$: 0, a: a};
};
var $author$project$Perfimmo$Api$CRUDResultWithFeedback$Warning = function (a) {
	return {$: 1, a: a};
};
var $author$project$Perfimmo$Api$CRUDResultWithFeedback$ErrorResult = function (message) {
	return {a4: message};
};
var $author$project$Perfimmo$Api$CRUDResultWithFeedback$errorResultDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'message',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Perfimmo$Api$CRUDResultWithFeedback$ErrorResult));
var $author$project$Perfimmo$Api$CRUDResultWithFeedback$SuccessResult = {};
var $author$project$Perfimmo$Api$CRUDResultWithFeedback$successResultDecoder = $elm$json$Json$Decode$succeed($author$project$Perfimmo$Api$CRUDResultWithFeedback$SuccessResult);
var $author$project$Perfimmo$Api$CRUDResultWithFeedback$WarningResult = function (message) {
	return {a4: message};
};
var $author$project$Perfimmo$Api$CRUDResultWithFeedback$warningResultDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'message',
	$elm$json$Json$Decode$string,
	$elm$json$Json$Decode$succeed($author$project$Perfimmo$Api$CRUDResultWithFeedback$WarningResult));
var $author$project$Perfimmo$Api$CRUDResultWithFeedback$resultTypeJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Success',
			A2($elm$json$Json$Decode$map, $author$project$Perfimmo$Api$CRUDResultWithFeedback$Success, $author$project$Perfimmo$Api$CRUDResultWithFeedback$successResultDecoder)),
			A2(
			$elm$json$Json$Decode$field,
			'Warning',
			A2($elm$json$Json$Decode$map, $author$project$Perfimmo$Api$CRUDResultWithFeedback$Warning, $author$project$Perfimmo$Api$CRUDResultWithFeedback$warningResultDecoder)),
			A2(
			$elm$json$Json$Decode$field,
			'Error',
			A2($elm$json$Json$Decode$map, $author$project$Perfimmo$Api$CRUDResultWithFeedback$Error, $author$project$Perfimmo$Api$CRUDResultWithFeedback$errorResultDecoder))
		]));
var $author$project$Perfimmo$Api$CRUDResultWithFeedback$crudResultWithFeedbackJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'resultType',
	$author$project$Perfimmo$Api$CRUDResultWithFeedback$resultTypeJsonDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'uid',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Perfimmo$Api$CRUDResultWithFeedback$CRUDResultWithFeedback)));
var $author$project$Components$Clients$Detail$Model$Harvest = 0;
var $author$project$Components$Clients$Detail$Model$IsSync = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$Model$ShouldBeSync = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$NavLinkRef$getExtToolClientsRel = function (links) {
	return A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'clients_from_ext_system', links);
};
var $author$project$Main$NavLinkRef$syncClientWithExtToolRel = function (links) {
	return A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'sync_with_ext_system', links);
};
var $author$project$Components$Clients$Detail$Model$setClient = F2(
	function (model, client) {
		var extSyst = function () {
			var _v0 = model.am;
			if (!_v0.$) {
				var _v1 = _v0.a;
				return $elm$core$Maybe$Just(0);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var calculSyncStatus = function (c) {
			return A2(
				$elm$core$Maybe$withDefault,
				$author$project$Components$Clients$Detail$Model$ShouldNotBeSync,
				A2(
					$elm$core$Maybe$map,
					function (ext) {
						return A2(
							$elm$core$Maybe$withDefault,
							$author$project$Components$Clients$Detail$Model$IsSync(ext),
							A3(
								$elm$core$Maybe$map2,
								F2(
									function (sync, get) {
										return $author$project$Components$Clients$Detail$Model$ShouldBeSync(
											{g$: ext, ho: get, j8: sync});
									}),
								$author$project$Main$NavLinkRef$syncClientWithExtToolRel(c),
								$author$project$Main$NavLinkRef$getExtToolClientsRel(c)));
					},
					extSyst));
		};
		var appLink = model.d1;
		var abstractClients = A2(
			$elm_community$list_extra$List$Extra$filterNot,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.kx;
				},
				$elm$core$Basics$eq(client.kx)),
			model.d$);
		return _Utils_update(
			model,
			{
				d$: abstractClients,
				d1: _Utils_update(
					appLink,
					{
						gJ: A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'delete', client.nf),
						kA: A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'update', client.nf)
					}),
				gb: A4(
					$author$project$Components$Clients$Detail$ModelForm$ClientFormFromApiBuilders$fromClientQueryView,
					model.am,
					$elm$core$Maybe$Just(client),
					abstractClients,
					model.fu),
				lu: $elm$core$Maybe$Just(client.kx),
				bQ: calculSyncStatus(client.nf)
			});
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Left = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$ModelOut = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Right = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$ExternalSyncSystem$Model$SyncWithHarvest = 0;
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$WithEffectOut = function (a) {
	return {$: 1, a: a};
};
var $cmditch$elm_bigint$BigInt$BigIntNotNormalised = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $cmditch$elm_bigint$BigInt$MagnitudeNotNormalised = $elm$core$Basics$identity;
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $cmditch$elm_bigint$BigInt$isNegativeMagnitude = function (digits) {
	var _v0 = $elm_community$list_extra$List$Extra$last(digits);
	if (_v0.$ === 1) {
		return false;
	} else {
		var x = _v0.a;
		return x < 0;
	}
};
var $cmditch$elm_bigint$BigInt$mkBigIntNotNormalised = F2(
	function (s, digits) {
		return A2($cmditch$elm_bigint$BigInt$BigIntNotNormalised, s, digits);
	});
var $elm_community$list_extra$List$Extra$dropWhileRight = function (p) {
	return A2(
		$elm$core$List$foldr,
		F2(
			function (x, xs) {
				return (p(x) && $elm$core$List$isEmpty(xs)) ? _List_Nil : A2($elm$core$List$cons, x, xs);
			}),
		_List_Nil);
};
var $cmditch$elm_bigint$BigInt$dropZeroes = $elm_community$list_extra$List$Extra$dropWhileRight(
	$elm$core$Basics$eq(0));
var $elm$core$Basics$pow = _Basics_pow;
var $cmditch$elm_bigint$Constants$maxDigitValue = (-1) + A2($elm$core$Basics$pow, 10, $cmditch$elm_bigint$Constants$maxDigitMagnitude);
var $cmditch$elm_bigint$BigInt$baseDigit = $cmditch$elm_bigint$Constants$maxDigitValue + 1;
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $cmditch$elm_bigint$BigInt$normaliseDigit = function (x) {
	return (x < 0) ? A2(
		$elm$core$Tuple$mapFirst,
		$elm$core$Basics$add(-1),
		$cmditch$elm_bigint$BigInt$normaliseDigit(x + $cmditch$elm_bigint$BigInt$baseDigit)) : _Utils_Tuple2((x / $cmditch$elm_bigint$BigInt$baseDigit) | 0, x % $cmditch$elm_bigint$BigInt$baseDigit);
};
var $cmditch$elm_bigint$BigInt$normaliseDigitList = F2(
	function (carry, xs) {
		normaliseDigitList:
		while (true) {
			if (!xs.b) {
				if (_Utils_cmp(carry, $cmditch$elm_bigint$BigInt$baseDigit) > 0) {
					var $temp$carry = 0,
						$temp$xs = _List_fromArray(
						[carry]);
					carry = $temp$carry;
					xs = $temp$xs;
					continue normaliseDigitList;
				} else {
					return _List_fromArray(
						[carry]);
				}
			} else {
				var x = xs.a;
				var xs_ = xs.b;
				var _v1 = $cmditch$elm_bigint$BigInt$normaliseDigit(x + carry);
				var newCarry = _v1.a;
				var x_ = _v1.b;
				return A2(
					$elm$core$List$cons,
					x_,
					A2($cmditch$elm_bigint$BigInt$normaliseDigitList, newCarry, xs_));
			}
		}
	});
var $cmditch$elm_bigint$BigInt$normaliseMagnitude = function (_v0) {
	var xs = _v0;
	return $cmditch$elm_bigint$BigInt$dropZeroes(
		A2($cmditch$elm_bigint$BigInt$normaliseDigitList, 0, xs));
};
var $cmditch$elm_bigint$BigInt$reverseMagnitude = $elm$core$List$map($elm$core$Basics$negate);
var $cmditch$elm_bigint$BigInt$Zero = 2;
var $cmditch$elm_bigint$BigInt$signNegate = function (sign_) {
	switch (sign_) {
		case 0:
			return 1;
		case 1:
			return 0;
		default:
			return 2;
	}
};
var $cmditch$elm_bigint$BigInt$normalise = function (_v0) {
	normalise:
	while (true) {
		var s = _v0.a;
		var digits = _v0.b;
		var _v1 = $cmditch$elm_bigint$BigInt$normaliseMagnitude(digits);
		var normalisedMag = _v1;
		if ($cmditch$elm_bigint$BigInt$isNegativeMagnitude(normalisedMag)) {
			var $temp$_v0 = A2(
				$cmditch$elm_bigint$BigInt$mkBigIntNotNormalised,
				$cmditch$elm_bigint$BigInt$signNegate(s),
				$cmditch$elm_bigint$BigInt$reverseMagnitude(normalisedMag));
			_v0 = $temp$_v0;
			continue normalise;
		} else {
			return A2($cmditch$elm_bigint$BigInt$mkBigInt, s, normalisedMag);
		}
	}
};
var $cmditch$elm_bigint$BigInt$MagnitudePair = $elm$core$Basics$identity;
var $cmditch$elm_bigint$BigInt$sameSizeRaw = F2(
	function (xs, ys) {
		var _v0 = _Utils_Tuple2(xs, ys);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return _List_Nil;
			} else {
				var _v2 = _v0.b;
				var y = _v2.a;
				var ys_ = _v2.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(0, y),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, _List_Nil, ys_));
			}
		} else {
			if (!_v0.b.b) {
				var _v1 = _v0.a;
				var x = _v1.a;
				var xs_ = _v1.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(x, 0),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs_, _List_Nil));
			} else {
				var _v3 = _v0.a;
				var x = _v3.a;
				var xs_ = _v3.b;
				var _v4 = _v0.b;
				var y = _v4.a;
				var ys_ = _v4.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(x, y),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs_, ys_));
			}
		}
	});
var $cmditch$elm_bigint$BigInt$sameSizeNotNormalized = F2(
	function (_v0, _v1) {
		var xs = _v0;
		var ys = _v1;
		return A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs, ys);
	});
var $cmditch$elm_bigint$BigInt$toPositiveSign = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return A2($cmditch$elm_bigint$BigInt$mkBigIntNotNormalised, 2, _List_Nil);
		case 1:
			var digits = bigInt.a;
			return A2(
				$cmditch$elm_bigint$BigInt$mkBigIntNotNormalised,
				0,
				$cmditch$elm_bigint$BigInt$reverseMagnitude(digits));
		default:
			var digits = bigInt.a;
			return A2($cmditch$elm_bigint$BigInt$mkBigIntNotNormalised, 0, digits);
	}
};
var $cmditch$elm_bigint$BigInt$add = F2(
	function (a, b) {
		var _v0 = $cmditch$elm_bigint$BigInt$toPositiveSign(b);
		var mb = _v0.b;
		var _v1 = $cmditch$elm_bigint$BigInt$toPositiveSign(a);
		var ma = _v1.b;
		var _v2 = A2($cmditch$elm_bigint$BigInt$sameSizeNotNormalized, ma, mb);
		var pairs = _v2;
		var added = A2(
			$elm$core$List$map,
			function (_v3) {
				var a_ = _v3.a;
				var b_ = _v3.b;
				return a_ + b_;
			},
			pairs);
		return $cmditch$elm_bigint$BigInt$normalise(
			A2($cmditch$elm_bigint$BigInt$BigIntNotNormalised, 0, added));
	});
var $cmditch$elm_bigint$BigInt$signFromInt = function (x) {
	var _v0 = A2($elm$core$Basics$compare, x, 0);
	switch (_v0) {
		case 0:
			return 1;
		case 2:
			return 0;
		default:
			return 2;
	}
};
var $cmditch$elm_bigint$BigInt$fromInt = function (x) {
	return $cmditch$elm_bigint$BigInt$normalise(
		A2(
			$cmditch$elm_bigint$BigInt$BigIntNotNormalised,
			$cmditch$elm_bigint$BigInt$signFromInt(x),
			_List_fromArray(
				[
					$elm$core$Basics$abs(x)
				])));
};
var $cmditch$elm_bigint$BigInt$magnitude = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return _List_Nil;
		case 0:
			var mag = bigInt.a;
			return mag;
		default:
			var mag = bigInt.a;
			return mag;
	}
};
var $cmditch$elm_bigint$BigInt$mulSingleDigit = F2(
	function (_v0, d) {
		var xs = _v0;
		return $cmditch$elm_bigint$BigInt$normaliseMagnitude(
			A2(
				$elm$core$List$map,
				$elm$core$Basics$mul(d),
				xs));
	});
var $cmditch$elm_bigint$BigInt$mulMagnitudes = F2(
	function (_v0, _v1) {
		var mag1 = _v0;
		var mag2 = _v1;
		if (!mag1.b) {
			return _List_Nil;
		} else {
			if (!mag1.b.b) {
				var m = mag1.a;
				return A2($cmditch$elm_bigint$BigInt$mulSingleDigit, mag2, m);
			} else {
				var m = mag1.a;
				var mx = mag1.b;
				var accum = A2($cmditch$elm_bigint$BigInt$mulSingleDigit, mag2, m);
				var _v3 = A2($cmditch$elm_bigint$BigInt$mulMagnitudes, mx, mag2);
				var rest = _v3;
				var bigInt = A2(
					$cmditch$elm_bigint$BigInt$add,
					A2($cmditch$elm_bigint$BigInt$mkBigInt, 0, accum),
					A2(
						$cmditch$elm_bigint$BigInt$mkBigInt,
						0,
						A2($elm$core$List$cons, 0, rest)));
				return $cmditch$elm_bigint$BigInt$magnitude(bigInt);
			}
		}
	});
var $cmditch$elm_bigint$BigInt$sign = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return 2;
		case 0:
			return 0;
		default:
			return 1;
	}
};
var $cmditch$elm_bigint$BigInt$signProduct = F2(
	function (x, y) {
		return ((x === 2) || (y === 2)) ? 2 : (_Utils_eq(x, y) ? 0 : 1);
	});
var $cmditch$elm_bigint$BigInt$mul = F2(
	function (int1, int2) {
		return A2(
			$cmditch$elm_bigint$BigInt$mkBigInt,
			A2(
				$cmditch$elm_bigint$BigInt$signProduct,
				$cmditch$elm_bigint$BigInt$sign(int1),
				$cmditch$elm_bigint$BigInt$sign(int2)),
			A2(
				$cmditch$elm_bigint$BigInt$mulMagnitudes,
				$cmditch$elm_bigint$BigInt$magnitude(int1),
				$cmditch$elm_bigint$BigInt$magnitude(int2)));
	});
var $torreyatcitty$the_best_decimal$Decimal$addDecimals = F2(
	function (i, _v0) {
		addDecimals:
		while (true) {
			var m = _v0.a;
			var e = _v0.b;
			var mul10 = function (x) {
				return A2(
					$cmditch$elm_bigint$BigInt$mul,
					x,
					$cmditch$elm_bigint$BigInt$fromInt(10));
			};
			if (!i) {
				return A2($torreyatcitty$the_best_decimal$Decimal$Decimal, m, e);
			} else {
				if (i > 0) {
					var $temp$i = i - 1,
						$temp$_v0 = A2(
						$torreyatcitty$the_best_decimal$Decimal$Decimal,
						mul10(m),
						e - 1);
					i = $temp$i;
					_v0 = $temp$_v0;
					continue addDecimals;
				} else {
					return A2($torreyatcitty$the_best_decimal$Decimal$Decimal, m, e);
				}
			}
		}
	});
var $torreyatcitty$the_best_decimal$Decimal$toExponent = F2(
	function (e, _v0) {
		var md = _v0.a;
		var ed = _v0.b;
		return A2(
			$torreyatcitty$the_best_decimal$Decimal$addDecimals,
			ed - e,
			A2($torreyatcitty$the_best_decimal$Decimal$Decimal, md, ed));
	});
var $torreyatcitty$the_best_decimal$Decimal$toCommonExponent = function (_v0) {
	var a = _v0.a;
	var b = _v0.b;
	var _v1 = b;
	var mb = _v1.a;
	var eb = _v1.b;
	var _v2 = a;
	var ma = _v2.a;
	var ea = _v2.b;
	var exponent = A2($elm$core$Basics$min, ea, eb);
	return _Utils_Tuple2(
		A2($torreyatcitty$the_best_decimal$Decimal$toExponent, exponent, a),
		A2($torreyatcitty$the_best_decimal$Decimal$toExponent, exponent, b));
};
var $torreyatcitty$the_best_decimal$Decimal$add = F2(
	function (a, b) {
		var _v0 = $torreyatcitty$the_best_decimal$Decimal$toCommonExponent(
			_Utils_Tuple2(a, b));
		var ra = _v0.a;
		var rb = _v0.b;
		var _v1 = rb;
		var mb = _v1.a;
		var eb = _v1.b;
		var _v2 = ra;
		var ma = _v2.a;
		var ea = _v2.b;
		return A2(
			$torreyatcitty$the_best_decimal$Decimal$Decimal,
			A2($cmditch$elm_bigint$BigInt$add, ma, mb),
			ea);
	});
var $torreyatcitty$the_best_decimal$Decimal$fromFloat = function (f) {
	return $torreyatcitty$the_best_decimal$Decimal$fromString(
		$elm$core$String$fromFloat(f));
};
var $cmditch$elm_bigint$BigInt$compareMagnitude = F4(
	function (x, y, xs, ys) {
		compareMagnitude:
		while (true) {
			var _v0 = _Utils_Tuple2(xs, ys);
			if (!_v0.a.b) {
				if (!_v0.b.b) {
					return A2($elm$core$Basics$compare, x, y);
				} else {
					return 0;
				}
			} else {
				if (!_v0.b.b) {
					return 2;
				} else {
					var _v1 = _v0.a;
					var x_ = _v1.a;
					var xss = _v1.b;
					var _v2 = _v0.b;
					var y_ = _v2.a;
					var yss = _v2.b;
					if (_Utils_eq(x_, y_)) {
						var $temp$x = x,
							$temp$y = y,
							$temp$xs = xss,
							$temp$ys = yss;
						x = $temp$x;
						y = $temp$y;
						xs = $temp$xs;
						ys = $temp$ys;
						continue compareMagnitude;
					} else {
						var $temp$x = x_,
							$temp$y = y_,
							$temp$xs = xss,
							$temp$ys = yss;
						x = $temp$x;
						y = $temp$y;
						xs = $temp$xs;
						ys = $temp$ys;
						continue compareMagnitude;
					}
				}
			}
		}
	});
var $cmditch$elm_bigint$BigInt$orderNegate = function (x) {
	switch (x) {
		case 0:
			return 2;
		case 1:
			return 1;
		default:
			return 0;
	}
};
var $cmditch$elm_bigint$BigInt$compare = F2(
	function (int1, int2) {
		var _v0 = _Utils_Tuple2(int1, int2);
		switch (_v0.a.$) {
			case 0:
				if (!_v0.b.$) {
					var mag1 = _v0.a.a;
					var mag2 = _v0.b.a;
					return A4($cmditch$elm_bigint$BigInt$compareMagnitude, 0, 0, mag1, mag2);
				} else {
					return 2;
				}
			case 1:
				if (_v0.b.$ === 1) {
					var mag1 = _v0.a.a;
					var mag2 = _v0.b.a;
					return $cmditch$elm_bigint$BigInt$orderNegate(
						A4($cmditch$elm_bigint$BigInt$compareMagnitude, 0, 0, mag1, mag2));
				} else {
					return 0;
				}
			default:
				switch (_v0.b.$) {
					case 0:
						var _v1 = _v0.a;
						return 0;
					case 2:
						var _v2 = _v0.a;
						var _v3 = _v0.b;
						return 1;
					default:
						var _v4 = _v0.a;
						return 2;
				}
		}
	});
var $cmditch$elm_bigint$BigInt$lt = F2(
	function (x, y) {
		return !A2($cmditch$elm_bigint$BigInt$compare, x, y);
	});
var $cmditch$elm_bigint$BigInt$gte = F2(
	function (x, y) {
		return !A2($cmditch$elm_bigint$BigInt$lt, x, y);
	});
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $torreyatcitty$the_best_decimal$Decimal$insert_decimal_period = F2(
	function (pos, s) {
		var extra_zeros = pos - $elm$core$String$length(s);
		var padded_s = (extra_zeros >= 0) ? _Utils_ap(
			A2($elm$core$String$repeat, extra_zeros + 1, '0'),
			s) : s;
		var before = A2($elm$core$String$dropRight, pos, padded_s);
		var after = A2($elm$core$String$right, pos, padded_s);
		return before + ('.' + after);
	});
var $cmditch$elm_bigint$BigInt$negate = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return $cmditch$elm_bigint$BigInt$Zer;
		case 0:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$Neg(mag);
		default:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$Pos(mag);
	}
};
var $cmditch$elm_bigint$BigInt$fillZeroes = A2(
	$elm$core$Basics$composeL,
	A2($elm$core$String$padLeft, $cmditch$elm_bigint$Constants$maxDigitMagnitude, '0'),
	$elm$core$String$fromInt);
var $cmditch$elm_bigint$BigInt$revMagnitudeToString = function (_v0) {
	var digits = _v0;
	var _v1 = $elm$core$List$reverse(digits);
	if (!_v1.b) {
		return '0';
	} else {
		var x = _v1.a;
		var xs = _v1.b;
		return $elm$core$String$concat(
			A2(
				$elm$core$List$cons,
				$elm$core$String$fromInt(x),
				A2($elm$core$List$map, $cmditch$elm_bigint$BigInt$fillZeroes, xs)));
	}
};
var $cmditch$elm_bigint$BigInt$toString = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return '0';
		case 0:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$revMagnitudeToString(mag);
		default:
			var mag = bigInt.a;
			return '-' + $cmditch$elm_bigint$BigInt$revMagnitudeToString(mag);
	}
};
var $torreyatcitty$the_best_decimal$Decimal$toString = function (_v0) {
	var m = _v0.a;
	var e = _v0.b;
	var sign = A2(
		$cmditch$elm_bigint$BigInt$gte,
		m,
		$cmditch$elm_bigint$BigInt$fromInt(0)) ? '' : '-';
	var add_zeros = function (n) {
		return A2($elm$core$String$repeat, n, '0');
	};
	var abs_m = A2(
		$cmditch$elm_bigint$BigInt$gte,
		m,
		$cmditch$elm_bigint$BigInt$fromInt(0)) ? m : $cmditch$elm_bigint$BigInt$negate(m);
	var s = $cmditch$elm_bigint$BigInt$toString(abs_m);
	var _v1 = A2($elm$core$Basics$compare, e, 0);
	switch (_v1) {
		case 1:
			return _Utils_ap(sign, s);
		case 2:
			return _Utils_ap(
				sign,
				_Utils_ap(
					s,
					add_zeros(e)));
		default:
			return _Utils_ap(
				sign,
				A2($torreyatcitty$the_best_decimal$Decimal$insert_decimal_period, 0 - e, s));
	}
};
var $torreyatcitty$the_best_decimal$Decimal$toFloat = function (d) {
	var _v0 = $elm$core$String$toFloat(
		$torreyatcitty$the_best_decimal$Decimal$toString(d));
	if (!_v0.$) {
		var a = _v0.a;
		return a;
	} else {
		return 42.0;
	}
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$add = F2(
	function (_v0, _v1) {
		var f1 = _v0.a;
		var i1 = _v0.c;
		var f2 = _v1.a;
		var i2 = _v1.c;
		var i = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(
			_Utils_ap(i1, i2));
		var _v2 = _Utils_Tuple2(f1, f2);
		if (!_v2.a.$) {
			if (!_v2.b.$) {
				var x = _v2.a.a;
				var y = _v2.b.a;
				var d2 = $torreyatcitty$the_best_decimal$Decimal$fromFloat(y);
				var d1 = $torreyatcitty$the_best_decimal$Decimal$fromFloat(x);
				return A2(
					$elm$core$Maybe$withDefault,
					A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue, x + y, i),
					A2(
						$elm$core$Maybe$map,
						function (v) {
							return A2(
								$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue,
								$torreyatcitty$the_best_decimal$Decimal$toFloat(v),
								i);
						},
						A3($elm$core$Maybe$map2, $torreyatcitty$the_best_decimal$Decimal$add, d1, d2)));
			} else {
				var x = _v2.a.a;
				var _v3 = _v2.b;
				return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue, x, i);
			}
		} else {
			if (!_v2.b.$) {
				var _v4 = _v2.a;
				var x = _v2.b.a;
				return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValue, x, i);
			} else {
				var _v5 = _v2.a;
				var _v6 = _v2.b;
				return i;
			}
		}
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS = F2(
	function (val, _v0) {
		var infos = _v0.b;
		var nonemptycheckedVal = $elm$core$String$isEmpty(val) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(val);
		return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$FormField, nonemptycheckedVal, infos);
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS = F2(
	function (s, _v0) {
		var values = _v0.b;
		var infos = _v0.c;
		var builder = _v0.d;
		var toString = _v0.e;
		var _v1 = $elm$core$String$trim(s);
		if (_v1 === '') {
			return A5($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$FormSelect, $elm$core$Maybe$Nothing, values, infos, builder, toString);
		} else {
			return A5(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$FormSelect,
				builder(s),
				values,
				infos,
				builder,
				toString);
		}
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$adresseSetter = function (adresseFields) {
	switch (adresseFields.$) {
		case 0:
			var add1 = adresseFields.a;
			return function (a) {
				return _Utils_update(
					a,
					{
						fB: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, add1, a.fB)
					});
			};
		case 1:
			var add2 = adresseFields.a;
			return function (a) {
				return _Utils_update(
					a,
					{
						fC: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, add2, a.fC)
					});
			};
		case 2:
			var zipCode = adresseFields.a;
			return function (a) {
				return _Utils_update(
					a,
					{
						gc: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, zipCode, a.gc)
					});
			};
		case 3:
			var ville = adresseFields.a;
			return function (a) {
				return _Utils_update(
					a,
					{
						d_: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, ville, a.d_)
					});
			};
		default:
			var pays = adresseFields.a;
			return function (a) {
				return _Utils_update(
					a,
					{
						dq: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, pays, a.dq)
					});
			};
	}
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$defineNatureJuridique = F3(
	function (extSync, clients, client) {
		var _v0 = client.ic;
		if (!_v0.$) {
			return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$ModelOut(
				_Utils_update(
					client,
					{
						ic: $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleForm(
							$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initPersonneMoraleForm(extSync))
					}));
		} else {
			return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$ModelOut(
				_Utils_update(
					client,
					{
						ic: $author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
							A2(
								$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initPersonnePhysiqueForm,
								extSync,
								A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initSitFamForm, extSync, clients)))
					}));
		}
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$extractValue = function (v) {
	if (!v.$) {
		return $elm$core$Maybe$Nothing;
	} else {
		var msg = v.a;
		return $elm$core$Maybe$Just(msg);
	}
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleCheckbox = function (field) {
	var toggle = A2(
		$elm$core$Maybe$withDefault,
		true,
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$not,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(field)));
	return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, toggle, field);
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValueFromS = F2(
	function (raw, _v0) {
		var oldFloat = _v0.a;
		var oldRaw = _v0.b;
		var infos = _v0.c;
		var neutralFloat = A3($elm$core$String$replace, ',', '.', raw);
		var checkFloat = A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(oldFloat, oldRaw),
			A2(
				$elm$core$Maybe$map,
				function (f) {
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(f),
						raw);
				},
				$elm$core$String$toFloat(neutralFloat)));
		var _v1 = ($elm$core$String$trim(raw) === '') ? _Utils_Tuple2($elm$core$Maybe$Nothing, '') : checkFloat;
		var val = _v1.a;
		var s = _v1.b;
		return A3($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$FormFloat, val, s, infos);
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput = F2(
	function (raw, form) {
		return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValueFromS, raw, form);
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setValueFromS = F2(
	function (raw, form) {
		var _v0 = form;
		var infos = _v0.c;
		var update = A2(
			$elm$core$Maybe$withDefault,
			form,
			A2(
				$elm$core$Maybe$map,
				A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$testPourcent, raw, form),
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toFloat(
					A2(
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$setValueFromS,
						raw,
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$init(infos)))));
		return (raw === '') ? A3($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$FormPourcent, $elm$core$Maybe$Nothing, '', infos) : update;
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handlePourcentInput = F2(
	function (raw, form) {
		return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setValueFromS, raw, form);
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$identiteSetter = function (identiteFields) {
	switch (identiteFields.$) {
		case 0:
			var s = identiteFields.a;
			return function (i) {
				return _Utils_update(
					i,
					{
						f8: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, s, i.f8)
					});
			};
		case 1:
			var s = identiteFields.a;
			return function (i) {
				return _Utils_update(
					i,
					{
						dl: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, s, i.dl)
					});
			};
		case 2:
			var s = identiteFields.a;
			return function (i) {
				return _Utils_update(
					i,
					{
						ii: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, s, i.ii)
					});
			};
		default:
			var s = identiteFields.a;
			return function (i) {
				return _Utils_update(
					i,
					{
						iV: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, s, i.iV)
					});
			};
	}
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getInfos = function (_v0) {
	var infos = _v0.b;
	return infos;
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate = F2(
	function (rawDate, form) {
		var val = $elm$core$Result$toMaybe(
			$justinmimbs$date$Date$fromIsoString(rawDate));
		return A2(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
			val,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getInfos(form));
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$naissanceSetter = function (naissanceFields) {
	switch (naissanceFields.$) {
		case 0:
			var date = naissanceFields.a;
			return function (n) {
				return _Utils_update(
					n,
					{
						c$: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, date, n.c$)
					});
			};
		case 1:
			var ville = naissanceFields.a;
			return function (n) {
				return _Utils_update(
					n,
					{
						d_: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, ville, n.d_)
					});
			};
		default:
			var pays = naissanceFields.a;
			return function (n) {
				return _Utils_update(
					n,
					{
						dq: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, pays, n.dq)
					});
			};
	}
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$pieceIdentiteSetter = function (pieceIdentiteFields) {
	switch (pieceIdentiteFields.$) {
		case 0:
			var num = pieceIdentiteFields.a;
			return function (p) {
				return _Utils_update(
					p,
					{
						is: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, num, p.is)
					});
			};
		case 1:
			var type_ = pieceIdentiteFields.a;
			return function (p) {
				return _Utils_update(
					p,
					{
						ks: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, type_, p.ks)
					});
			};
		case 2:
			var date = pieceIdentiteFields.a;
			return function (p) {
				return _Utils_update(
					p,
					{
						gr: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, date, p.gr)
					});
			};
		default:
			var date = pieceIdentiteFields.a;
			return function (p) {
				return _Utils_update(
					p,
					{
						gu: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, date, p.gu)
					});
			};
	}
};
var $elm_community$list_extra$List$Extra$removeAt = F2(
	function (index, l) {
		if (index < 0) {
			return l;
		} else {
			var _v0 = A2($elm$core$List$drop, index, l);
			if (!_v0.b) {
				return l;
			} else {
				var rest = _v0.b;
				return _Utils_ap(
					A2($elm$core$List$take, index, l),
					rest);
			}
		}
	});
var $elm_community$list_extra$List$Extra$getAt = F2(
	function (idx, xs) {
		return (idx < 0) ? $elm$core$Maybe$Nothing : $elm$core$List$head(
			A2($elm$core$List$drop, idx, xs));
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$update = F2(
	function (msg, _v0) {
		var model = _v0.a;
		var infos = _v0.b;
		var _v1 = A2($inkuzmin$elm_multiselect$Multiselect$update, msg, model);
		var newModel = _v1.a;
		var newCmd = _v1.b;
		var out = _v1.c;
		return _Utils_Tuple3(
			A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$FormMultiSelect, newModel, infos),
			newCmd,
			out);
	});
var $elm_community$list_extra$List$Extra$updateAt = F3(
	function (index, fn, list) {
		if (index < 0) {
			return list;
		} else {
			var tail = A2($elm$core$List$drop, index, list);
			var head = A2($elm$core$List$take, index, list);
			if (tail.b) {
				var x = tail.a;
				var xs = tail.b;
				return _Utils_ap(
					head,
					A2(
						$elm$core$List$cons,
						fn(x),
						xs));
			} else {
				return list;
			}
		}
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$representantLegauxSetter = F5(
	function (extSync, isPM, params, representantLegalFields, autreNatReprAction) {
		var rightUpdate = F2(
			function (no, update) {
				return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Right(
					A3($elm_community$list_extra$List$Extra$updateAt, no, update, params.dA));
			});
		switch (representantLegalFields.$) {
			case 0:
				return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Right(
					_Utils_ap(
						params.dA,
						_List_fromArray(
							[
								A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initRepresentantLegalForm, extSync, isPM)
							])));
			case 1:
				var no = representantLegalFields.a;
				return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Right(
					A2($elm_community$list_extra$List$Extra$removeAt, no - 1, params.dA));
			case 2:
				var no = representantLegalFields.a;
				var identiteFields = representantLegalFields.b;
				var updateRepr = function (r) {
					return _Utils_update(
						r,
						{
							aD: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$identiteSetter, identiteFields, r.aD)
						});
				};
				var noRepr = no - 1;
				return A2(rightUpdate, noRepr, updateRepr);
			case 3:
				var no = representantLegalFields.a;
				var naissanceFields = representantLegalFields.b;
				var updateRepr = function (r) {
					return _Utils_update(
						r,
						{
							aI: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$naissanceSetter, naissanceFields, r.aI)
						});
				};
				var noRepr = no - 1;
				return A2(rightUpdate, noRepr, updateRepr);
			case 4:
				var no = representantLegalFields.a;
				var pieceIdentiteFields = representantLegalFields.b;
				var updateRepr = function (r) {
					return _Utils_update(
						r,
						{
							du: $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculPieceIdentiteFormBlockState(
								A2(
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$pieceIdentiteSetter,
									pieceIdentiteFields,
									$author$project$Perfimmo$Form$FormModel$getBlock(r.du)))
						});
				};
				var noRepr = no - 1;
				return A2(rightUpdate, noRepr, updateRepr);
			case 5:
				var no = representantLegalFields.a;
				var code = representantLegalFields.b;
				var updateRepr = function (r) {
					return _Utils_update(
						r,
						{
							bD: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, code, r.bD)
						});
				};
				var noRepr = no - 1;
				return A2(rightUpdate, noRepr, updateRepr);
			case 6:
				var no = representantLegalFields.a;
				var value = representantLegalFields.b;
				var updateRepr = function (r) {
					return A2(
						$elm$core$Maybe$map,
						function (_v3) {
							var newModel = _v3.a;
							var newCmd = _v3.b;
							var out = _v3.c;
							return _Utils_Tuple2(
								_Utils_update(
									r,
									{bf: newModel}),
								newCmd);
						},
						A2(
							$elm$core$Maybe$map,
							function (msg) {
								return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$update, msg, r.bf);
							},
							$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$extractValue(value)));
				};
				var noRepr = no - 1;
				var representant = A2($elm_community$list_extra$List$Extra$getAt, noRepr, params.dA);
				var result = A2($ccapndave$elm_flat_map$Maybe$FlatMap$flatMap, updateRepr, representant);
				var updateList = function (f) {
					return A3($elm_community$list_extra$List$Extra$updateAt, noRepr, f, params.dA);
				};
				return A2(
					$elm$core$Maybe$withDefault,
					$author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Right(params.dA),
					A2(
						$elm$core$Maybe$map,
						function (_v1) {
							var updatedRepr = _v1.a;
							var cmd = _v1.b;
							return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Left(
								_Utils_Tuple2(
									updateList(
										function (_v2) {
											return updatedRepr;
										}),
									A2(
										$elm$core$Platform$Cmd$map,
										A2(
											$elm$core$Basics$composeL,
											autreNatReprAction(no),
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Value),
										cmd)));
						},
						result));
			case 7:
				var no = representantLegalFields.a;
				var adresseFields = representantLegalFields.b;
				var updateRepr = function (r) {
					return _Utils_update(
						r,
						{
							fD: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$adresseSetter, adresseFields, r.fD)
						});
				};
				var noRepr = no - 1;
				return A2(rightUpdate, noRepr, updateRepr);
			case 8:
				var no = representantLegalFields.a;
				var v = representantLegalFields.b;
				var updateRepr = function (r) {
					return _Utils_update(
						r,
						{
							h_: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, v, r.h_)
						});
				};
				var noRepr = no - 1;
				return A2(rightUpdate, noRepr, updateRepr);
			default:
				var no = representantLegalFields.a;
				var v = representantLegalFields.b;
				var updateRepr = function (r) {
					return _Utils_update(
						r,
						{
							g9: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, v, r.g9)
						});
				};
				var noRepr = no - 1;
				return A2(rightUpdate, noRepr, updateRepr);
		}
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$setValueFromS = F2(
	function (raw, _v0) {
		var select = _v0;
		return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, raw, select);
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$setValueFromS = F2(
	function (raw, _v0) {
		var oldInt = _v0.a;
		var oldRaw = _v0.b;
		var infos = _v0.c;
		var checkInt = A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(oldInt, oldRaw),
			A2(
				$elm$core$Maybe$map,
				function (i) {
					return _Utils_Tuple2(
						$elm$core$Maybe$Just(i),
						raw);
				},
				$elm$core$String$toInt(raw)));
		var _v1 = ($elm$core$String$trim(raw) === '') ? _Utils_Tuple2($elm$core$Maybe$Nothing, '') : checkInt;
		var val = _v1.a;
		var s = _v1.b;
		return A3($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$FormInt, val, s, infos);
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$setField = F2(
	function (field, model) {
		var clientsName = A2(
			$elm$core$List$map,
			function ($) {
				return $.nz;
			},
			model.d$);
		var clientForm = model.gb;
		var setContact = function (f) {
			return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$ModelOut(
				_Utils_update(
					clientForm,
					{
						cY: f(clientForm.cY)
					}));
		};
		var setNatureJuridique = function (n) {
			return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$ModelOut(
				_Utils_update(
					clientForm,
					{ic: n}));
		};
		var setNatureJuridiqueEff = F2(
			function (n, eff) {
				return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$WithEffectOut(
					_Utils_Tuple2(
						_Utils_update(
							clientForm,
							{ic: n}),
						eff));
			});
		var autreNatReprActionPP = function (i) {
			return A2(
				$elm$core$Basics$composeR,
				$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalAutreNationalite(i),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PP_RepresentantLegal,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonnePhysiqueF,
						A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique, $author$project$Components$Clients$Detail$Action$SetFormFields))));
		};
		var autreNatReprActionPM = function (i) {
			return A2(
				$elm$core$Basics$composeR,
				$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalAutreNationalite(i),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PM_RepresentantLegal,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonneMoraleF,
						A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique, $author$project$Components$Clients$Detail$Action$SetFormFields))));
		};
		var autreNatAction = A2(
			$elm$core$Basics$composeR,
			$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$AutreNationalites,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonnePhysiqueF,
				A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique, $author$project$Components$Clients$Detail$Action$SetFormFields)));
		var setterResult = function () {
			switch (field.$) {
				case 0:
					var natureField = field.a;
					switch (natureField.$) {
						case 0:
							return A3(
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$defineNatureJuridique,
								model.am,
								A2(
									$elm$core$List$map,
									function ($) {
										return $.nz;
									},
									model.d$),
								clientForm);
						case 1:
							var ppFields = natureField.a;
							var _v4 = clientForm.ic;
							if (!_v4.$) {
								var params = _v4.a;
								switch (ppFields.$) {
									case 0:
										var identiteFields = ppFields.a;
										var setIdentite = function (f) {
											return setNatureJuridique(
												$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
													_Utils_update(
														params,
														{
															aD: f(params.aD)
														})));
										};
										return setIdentite(
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$identiteSetter(identiteFields));
									case 1:
										var code = ppFields.a;
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
												_Utils_update(
													params,
													{
														bD: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, code, params.bD)
													})));
									case 2:
										var value = ppFields.a;
										return A2(
											$elm$core$Maybe$withDefault,
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$ModelOut(clientForm),
											A2(
												$elm$core$Maybe$map,
												function (_v6) {
													var newModel = _v6.a;
													var newCmd = _v6.b;
													var out = _v6.c;
													return A2(
														setNatureJuridiqueEff,
														$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
															_Utils_update(
																params,
																{bf: newModel})),
														A2(
															$elm$core$Platform$Cmd$map,
															A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Value, autreNatAction),
															newCmd));
												},
												A2(
													$elm$core$Maybe$map,
													function (msg) {
														return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$update, msg, params.bf);
													},
													$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$extractValue(value))));
									case 3:
										var code = ppFields.a;
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
												_Utils_update(
													params,
													{
														iE: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, code, params.iE)
													})));
									case 4:
										var date = ppFields.a;
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
												_Utils_update(
													params,
													{
														gF: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, date, params.gF)
													})));
									case 5:
										var date = ppFields.a;
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
												_Utils_update(
													params,
													{
														gs: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, date, params.gs)
													})));
									case 6:
										var value = ppFields.a;
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
												_Utils_update(
													params,
													{
														f0: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, value, params.f0)
													})));
									case 7:
										var value = ppFields.a;
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
												_Utils_update(
													params,
													{
														dw: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, value, params.dw)
													})));
									case 8:
										var naissanceFields = ppFields.a;
										var setNaissance = function (f) {
											return setNatureJuridique(
												$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
													_Utils_update(
														params,
														{
															aI: f(params.aI)
														})));
										};
										return setNaissance(
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$naissanceSetter(naissanceFields));
									case 9:
										var pieceIdentiteFields = ppFields.a;
										var setPieceId = function (f) {
											return setNatureJuridique(
												$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
													_Utils_update(
														params,
														{
															du: $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculPieceIdentiteFormBlockState(
																f(
																	$author$project$Perfimmo$Form$FormModel$getBlock(params.du)))
														})));
										};
										return setPieceId(
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$pieceIdentiteSetter(pieceIdentiteFields));
									case 10:
										var situationFamillialeFields = ppFields.a;
										var sitFamForm = $author$project$Perfimmo$Form$FormModel$getBlock(params.jU);
										var setSitFamEffect = function (f) {
											return setNatureJuridiqueEff(
												$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
													_Utils_update(
														params,
														{
															jU: A2(
																$author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculSitFamFormBlockState,
																model.d$,
																f(
																	$author$project$Perfimmo$Form$FormModel$getBlock(params.jU)))
														})));
										};
										var setSitFam = function (f) {
											return setNatureJuridique(
												$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
													_Utils_update(
														params,
														{
															jU: A2(
																$author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculSitFamFormBlockState,
																model.d$,
																f(
																	$author$project$Perfimmo$Form$FormModel$getBlock(params.jU)))
														})));
										};
										switch (situationFamillialeFields.$) {
											case 0:
												var v = situationFamillialeFields.a;
												return setSitFam(
													function (sitFam) {
														return _Utils_update(
															sitFam,
															{
																jT: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, v, sitFam.jT)
															});
													});
											case 1:
												var conjointPartenaireFields = situationFamillialeFields.a;
												var conjointAction = A2(
													$elm$core$Basics$composeR,
													$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$UnreferencedConjointF,
													A2(
														$elm$core$Basics$composeR,
														$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointPartenaireF,
														A2(
															$elm$core$Basics$composeR,
															$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SituationFamillialeF,
															A2(
																$elm$core$Basics$composeR,
																$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonnePhysiqueF,
																A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique, $author$project$Components$Clients$Detail$Action$SetFormFields)))));
												var update = function (conjoint) {
													if (!conjointPartenaireFields.$) {
														var referencedFields = conjointPartenaireFields.a;
														if (!conjoint.$) {
															var ref = conjoint.a;
															switch (referencedFields.$) {
																case 0:
																	var userInput = referencedFields.a;
																	return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Right(
																		$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ReferencedClientForm(
																			_Utils_update(
																				ref,
																				{
																					ga: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$setValueFromS, userInput, ref.ga)
																				})));
																case 1:
																	var date = referencedFields.a;
																	return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Right(
																		$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ReferencedClientForm(
																			_Utils_update(
																				ref,
																				{
																					c0: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, date, ref.c0)
																				})));
																default:
																	var v = referencedFields.a;
																	return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Right(
																		$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$ReferencedClientForm(
																			_Utils_update(
																				ref,
																				{
																					dy: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, v, ref.dy)
																				})));
															}
														} else {
															return update(
																A2($author$project$Components$Clients$Detail$ModelForm$FormInitializers$initConjointPartenaireForm, model.am, clientsName));
														}
													} else {
														var unreferencedConjointFields = conjointPartenaireFields.a;
														if (conjoint.$ === 1) {
															var unRef = conjoint.a;
															switch (unreferencedConjointFields.$) {
																case 0:
																	var identiteFields = unreferencedConjointFields.a;
																	return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Right(
																		$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$UnreferencedPersonForm(
																			_Utils_update(
																				unRef,
																				{
																					aD: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$identiteSetter, identiteFields, unRef.aD)
																				})));
																case 1:
																	var naissanceFields = unreferencedConjointFields.a;
																	return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Right(
																		$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$UnreferencedPersonForm(
																			_Utils_update(
																				unRef,
																				{
																					aI: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$naissanceSetter, naissanceFields, unRef.aI)
																				})));
																case 2:
																	var v = unreferencedConjointFields.a;
																	return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Right(
																		$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$UnreferencedPersonForm(
																			_Utils_update(
																				unRef,
																				{
																					bD: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, v, unRef.bD)
																				})));
																case 3:
																	var value = unreferencedConjointFields.a;
																	return A2(
																		$elm$core$Maybe$withDefault,
																		$author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Right(
																			$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$UnreferencedPersonForm(unRef)),
																		A2(
																			$elm$core$Maybe$map,
																			function (_v14) {
																				var m = _v14.a;
																				var cmd = _v14.b;
																				return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Left(
																					_Utils_Tuple2(
																						m,
																						A2(
																							$elm$core$Platform$Cmd$map,
																							A2(
																								$elm$core$Basics$composeR,
																								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Value,
																								A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointAutreNationalite, conjointAction)),
																							cmd)));
																			},
																			A2(
																				$elm$core$Maybe$map,
																				function (_v13) {
																					var newModel = _v13.a;
																					var newCmd = _v13.b;
																					var out = _v13.c;
																					var r = _Utils_Tuple2(
																						$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$UnreferencedPersonForm(
																							_Utils_update(
																								unRef,
																								{bf: newModel})),
																						newCmd);
																					return r;
																				},
																				A2(
																					$elm$core$Maybe$map,
																					function (msg) {
																						return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$update, msg, unRef.bf);
																					},
																					$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$extractValue(value)))));
																case 4:
																	var v = unreferencedConjointFields.a;
																	return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Right(
																		$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$UnreferencedPersonForm(
																			_Utils_update(
																				unRef,
																				{
																					dw: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, v, unRef.dw)
																				})));
																case 5:
																	var date = unreferencedConjointFields.a;
																	return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Right(
																		$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$UnreferencedPersonForm(
																			_Utils_update(
																				unRef,
																				{
																					c0: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, date, unRef.c0)
																				})));
																default:
																	var v = unreferencedConjointFields.a;
																	return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$Right(
																		$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$UnreferencedPersonForm(
																			_Utils_update(
																				unRef,
																				{
																					dy: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, v, unRef.dy)
																				})));
															}
														} else {
															return update(
																$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$UnreferencedPersonForm(
																	$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initUnreferencedConjointPartenaireForm(model.am)));
														}
													}
												};
												var buildResult = function (conjoint) {
													return setSitFam(
														function (sitFam) {
															return _Utils_update(
																sitFam,
																{gf: conjoint});
														});
												};
												var _v15 = update(sitFamForm.gf);
												if (!_v15.$) {
													var _v16 = _v15.a;
													var conjoint = _v16.a;
													var effect = _v16.b;
													return A2(
														setSitFamEffect,
														function (sitFam) {
															return _Utils_update(
																sitFam,
																{gf: conjoint});
														},
														effect);
												} else {
													var conjoint = _v15.a;
													return buildResult(conjoint);
												}
											case 3:
												return setSitFam(
													function (sitFam) {
														return _Utils_update(
															sitFam,
															{
																gX: _Utils_ap(
																	_List_fromArray(
																		[
																			$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initEnfantForm(model.am)
																		]),
																	sitFam.gX)
															});
													});
											case 4:
												var no = situationFamillialeFields.a;
												var removeEnfant = function (enfants) {
													return A2($elm_community$list_extra$List$Extra$removeAt, no - 1, enfants);
												};
												return setSitFam(
													function (sitFam) {
														return _Utils_update(
															sitFam,
															{
																gX: removeEnfant(sitFam.gX)
															});
													});
											case 2:
												var nb = situationFamillialeFields.a;
												return setSitFam(
													function (sitFam) {
														return _Utils_update(
															sitFam,
															{
																ij: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$setValueFromS, nb, sitFam.ij)
															});
													});
											default:
												var no = situationFamillialeFields.a;
												var enfantFields = situationFamillialeFields.b;
												var updateEnfant = F2(
													function (f, g) {
														return A2(
															$elm$core$List$indexedMap,
															F2(
																function (i, e) {
																	return _Utils_eq(i, no - 1) ? _Utils_update(
																		e,
																		{
																			aD: f(e.aD),
																			aI: g(e.aI)
																		}) : e;
																}),
															sitFamForm.gX);
													});
												if (!enfantFields.$) {
													var identiteFields = enfantFields.a;
													return setSitFam(
														function (sitFam) {
															return _Utils_update(
																sitFam,
																{
																	gX: A2(
																		updateEnfant,
																		$author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$identiteSetter(identiteFields),
																		$elm$core$Basics$identity)
																});
														});
												} else {
													var naissanceFields = enfantFields.a;
													return setSitFam(
														function (sitFam) {
															return _Utils_update(
																sitFam,
																{
																	gX: A2(
																		updateEnfant,
																		$elm$core$Basics$identity,
																		$author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$naissanceSetter(naissanceFields))
																});
														});
												}
										}
									case 11:
										var situationPatrimonialeFields = ppFields.a;
										var sitPatForm = $author$project$Perfimmo$Form$FormModel$getBlock(params.jV);
										var setSitPat = function (f) {
											return setNatureJuridique(
												$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
													_Utils_update(
														params,
														{
															jV: $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculSitPatFormBlockState(
																f(
																	$author$project$Perfimmo$Form$FormModel$getBlock(params.jV)))
														})));
										};
										switch (situationPatrimonialeFields.$) {
											case 0:
												var fiscalitePatrimonialeFields = situationPatrimonialeFields.a;
												var fisc = $author$project$Perfimmo$Form$FormModel$getBlock(sitPatForm.db);
												var update = function () {
													switch (fiscalitePatrimonialeFields.$) {
														case 0:
															var v = fiscalitePatrimonialeFields.a;
															return _Utils_update(
																fisc,
																{
																	ik: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, fisc.ik)
																});
														case 1:
															var v = fiscalitePatrimonialeFields.a;
															return _Utils_update(
																fisc,
																{
																	h7: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, fisc.h7)
																});
														case 2:
															var v = fiscalitePatrimonialeFields.a;
															return _Utils_update(
																fisc,
																{
																	h8: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, fisc.h8)
																});
														default:
															var v = fiscalitePatrimonialeFields.a;
															return _Utils_update(
																fisc,
																{
																	kj: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handlePourcentInput, v, fisc.kj)
																});
													}
												}();
												return setSitPat(
													function (sitPat) {
														return _Utils_update(
															sitPat,
															{
																db: $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculFiscalitePatrimonialeBlockState(update)
															});
													});
											case 1:
												var patrimoineFields = situationPatrimonialeFields.a;
												var pat = $author$project$Perfimmo$Form$FormModel$getBlock(sitPatForm.iD);
												var actif = pat.kP;
												var update = function () {
													if (!patrimoineFields.$) {
														var patrimoineActifFields = patrimoineFields.a;
														switch (patrimoineActifFields.$) {
															case 0:
																var v = patrimoineActifFields.a;
																return _Utils_update(
																	pat,
																	{
																		kP: _Utils_update(
																			actif,
																			{
																				cR: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, actif.cR)
																			})
																	});
															case 1:
																var v = patrimoineActifFields.a;
																var pat2 = _Utils_update(
																	pat,
																	{
																		kP: _Utils_update(
																			actif,
																			{
																				da: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, actif.da)
																			})
																	});
																var actif2 = pat2.kP;
																return _Utils_update(
																	pat2,
																	{
																		kP: _Utils_update(
																			actif2,
																			{
																				cR: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$add, actif2.da, actif2.io)
																			})
																	});
															default:
																var v = patrimoineActifFields.a;
																var pat2 = _Utils_update(
																	pat,
																	{
																		kP: _Utils_update(
																			actif,
																			{
																				io: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, actif.io)
																			})
																	});
																var actif2 = pat2.kP;
																return _Utils_update(
																	pat2,
																	{
																		kP: _Utils_update(
																			actif2,
																			{
																				cR: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$add, actif2.da, actif2.io)
																			})
																	});
														}
													} else {
														var v = patrimoineFields.a;
														return _Utils_update(
															pat,
															{
																iB: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, pat.iB)
															});
													}
												}();
												return setSitPat(
													function (sitPat) {
														return _Utils_update(
															sitPat,
															{
																iD: $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculPatrimoineBlockState(update)
															});
													});
											case 2:
												var budgetFields = situationPatrimonialeFields.a;
												var budget = $author$project$Perfimmo$Form$FormModel$getBlock(sitPatForm.fX);
												var update = function () {
													if (!budgetFields.$) {
														var v = budgetFields.a;
														return _Utils_update(
															budget,
															{
																jy: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, budget.jy)
															});
													} else {
														var v = budgetFields.a;
														return _Utils_update(
															budget,
															{
																f5: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, budget.f5)
															});
													}
												}();
												return setSitPat(
													function (sitPat) {
														return _Utils_update(
															sitPat,
															{
																fX: $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculBudgetBlockState(update)
															});
													});
											default:
												var v = situationPatrimonialeFields.a;
												return setSitPat(
													function (sitPat) {
														return _Utils_update(
															sitPat,
															{
																kb: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handlePourcentInput, v, sitPat.kb)
															});
													});
										}
									case 12:
										var representantLegalFields = ppFields.a;
										var update = A5($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$representantLegauxSetter, model.am, false, params, representantLegalFields, autreNatReprActionPP);
										if (!update.$) {
											var _v24 = update.a;
											var newModel = _v24.a;
											var effect = _v24.b;
											return A2(
												setNatureJuridiqueEff,
												$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
													_Utils_update(
														params,
														{dA: newModel})),
												effect);
										} else {
											var r = update.a;
											return setNatureJuridique(
												$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
													_Utils_update(
														params,
														{dA: r})));
										}
									case 13:
										var v = ppFields.a;
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
												_Utils_update(
													params,
													{
														c5: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, v, params.c5)
													})));
									case 14:
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
												_Utils_update(
													params,
													{
														iO: $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleCheckbox(params.iO)
													})));
									default:
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonnePhysiqueForm(
												_Utils_update(
													params,
													{
														iP: $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleCheckbox(params.iP)
													})));
								}
							} else {
								return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$ModelOut(clientForm);
							}
						default:
							var pmFields = natureField.a;
							var _v25 = clientForm.ic;
							if (_v25.$ === 1) {
								var params = _v25.a;
								switch (pmFields.$) {
									case 0:
										var v = pmFields.a;
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleForm(
												_Utils_update(
													params,
													{
														dl: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, v, params.dl)
													})));
									case 1:
										var v = pmFields.a;
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleForm(
												_Utils_update(
													params,
													{
														hd: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$setValueFromS, v, params.hd)
													})));
									case 2:
										var v = pmFields.a;
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleForm(
												_Utils_update(
													params,
													{
														jR: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, v, params.jR)
													})));
									case 3:
										var v = pmFields.a;
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleForm(
												_Utils_update(
													params,
													{
														kG: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, v, params.kG)
													})));
									case 4:
										var v = pmFields.a;
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleForm(
												_Utils_update(
													params,
													{
														f2: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, params.f2)
													})));
									case 5:
										var v = pmFields.a;
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleForm(
												_Utils_update(
													params,
													{
														dY: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, v, params.dY)
													})));
									case 6:
										var compteResultatFields = pmFields.a;
										var compteResultat = $author$project$Perfimmo$Form$FormModel$getBlock(params.ge);
										var update = function () {
											switch (compteResultatFields.$) {
												case 0:
													var v = compteResultatFields.a;
													return _Utils_update(
														compteResultat,
														{
															jd: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, compteResultat.jd)
														});
												case 1:
													var v = compteResultatFields.a;
													return _Utils_update(
														compteResultat,
														{
															f6: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, compteResultat.f6)
														});
												default:
													var v = compteResultatFields.a;
													return _Utils_update(
														compteResultat,
														{
															jw: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, compteResultat.jw)
														});
											}
										}();
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleForm(
												_Utils_update(
													params,
													{
														ge: $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculCompteResultatBlockState(update)
													})));
									case 7:
										var bilanFields = pmFields.a;
										var bilan = $author$project$Perfimmo$Form$FormModel$getBlock(params.fS);
										var actifAnnuel = bilan.cM;
										var update = function () {
											switch (bilanFields.$) {
												case 0:
													var actifAnnuelFields = bilanFields.a;
													switch (actifAnnuelFields.$) {
														case 0:
															var v = actifAnnuelFields.a;
															return _Utils_update(
																bilan,
																{
																	cM: _Utils_update(
																		actifAnnuel,
																		{
																			cR: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, actifAnnuel.cR)
																		})
																});
														case 1:
															var v = actifAnnuelFields.a;
															return _Utils_update(
																bilan,
																{
																	cM: _Utils_update(
																		actifAnnuel,
																		{
																			da: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, actifAnnuel.da)
																		})
																});
														default:
															var v = actifAnnuelFields.a;
															return _Utils_update(
																bilan,
																{
																	cM: _Utils_update(
																		actifAnnuel,
																		{
																			im: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, actifAnnuel.im)
																		})
																});
													}
												case 1:
													var v = bilanFields.a;
													return _Utils_update(
														bilan,
														{
															iC: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, bilan.iC)
														});
												default:
													var v = bilanFields.a;
													return _Utils_update(
														bilan,
														{
															f3: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, bilan.f3)
														});
											}
										}();
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleForm(
												_Utils_update(
													params,
													{
														fS: $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculBilanBlockState(update)
													})));
									case 8:
										var fiscaliteFields = pmFields.a;
										var fiscalite = $author$project$Perfimmo$Form$FormModel$getBlock(params.db);
										var update = function () {
											if (!fiscaliteFields.$) {
												var v = fiscaliteFields.a;
												return _Utils_update(
													fiscalite,
													{
														kc: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handlePourcentInput, v, fiscalite.kc)
													});
											} else {
												var v = fiscaliteFields.a;
												return _Utils_update(
													fiscalite,
													{
														h9: A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$handleFloatInput, v, fiscalite.h9)
													});
											}
										}();
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleForm(
												_Utils_update(
													params,
													{
														db: $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculFiscaliteBlockState(update)
													})));
									case 9:
										var representantLegalFields = pmFields.a;
										var update = A5($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$representantLegauxSetter, model.am, true, params, representantLegalFields, autreNatReprActionPM);
										if (!update.$) {
											var _v32 = update.a;
											var newModel = _v32.a;
											var effect = _v32.b;
											return A2(
												setNatureJuridiqueEff,
												$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleForm(
													_Utils_update(
														params,
														{dA: newModel})),
												effect);
										} else {
											var r = update.a;
											return setNatureJuridique(
												$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleForm(
													_Utils_update(
														params,
														{dA: r})));
										}
									default:
										var v = pmFields.a;
										return setNatureJuridique(
											$author$project$Components$Clients$Detail$ModelForm$ClientFormModel$PersonneMoraleForm(
												_Utils_update(
													params,
													{
														c5: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, v, params.c5)
													})));
								}
							} else {
								return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$ModelOut(clientForm);
							}
					}
				case 1:
					var contactFields = field.a;
					switch (contactFields.$) {
						case 0:
							var email = contactFields.a;
							return setContact(
								function (c) {
									return _Utils_update(
										c,
										{
											gU: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, email, c.gU)
										});
								});
						case 1:
							var no = contactFields.a;
							var v = contactFields.b;
							var updateGoodPhone = F2(
								function (i, f) {
									return _Utils_eq(i, no) ? A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValueFromS, v, f) : f;
								});
							var phones = clientForm.cY.kf;
							var update = _Utils_update(
								phones,
								{
									fj: A2(updateGoodPhone, 1, phones.fj),
									fk: A2(updateGoodPhone, 2, phones.fk),
									fl: A2(updateGoodPhone, 3, phones.fl)
								});
							return setContact(
								function (c) {
									return _Utils_update(
										c,
										{kf: update});
								});
						default:
							var adresseFields = contactFields.a;
							var setAdresse = F2(
								function (c, f) {
									return _Utils_update(
										c,
										{
											fE: f(c.fE)
										});
								});
							var setAddressField = function (f) {
								return setContact(
									function (c) {
										return A2(setAdresse, c, f);
									});
							};
							return setAddressField(
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$adresseSetter(adresseFields));
					}
				case 2:
					var fields = field.a;
					var setProfilInvest = function (f) {
						return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$ModelOut(
							_Utils_update(
								clientForm,
								{
									jh: $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculProfilInvestisseurFormBlockState(
										f(
											$author$project$Perfimmo$Form$FormModel$getBlock(clientForm.jh)))
								}));
					};
					switch (fields.$) {
						case 0:
							var date = fields.a;
							return setProfilInvest(
								function (p) {
									return _Utils_update(
										p,
										{
											gt: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, date, p.gt)
										});
								});
						case 1:
							var date = fields.a;
							return setProfilInvest(
								function (p) {
									return _Utils_update(
										p,
										{
											gB: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, date, p.gB)
										});
								});
						case 2:
							var date = fields.a;
							return setProfilInvest(
								function (p) {
									return _Utils_update(
										p,
										{
											gw: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, date, p.gw)
										});
								});
						case 3:
							var date = fields.a;
							return setProfilInvest(
								function (p) {
									return _Utils_update(
										p,
										{
											gx: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, date, p.gx)
										});
								});
						case 4:
							var date = fields.a;
							return setProfilInvest(
								function (p) {
									return _Utils_update(
										p,
										{
											gA: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, date, p.gA)
										});
								});
						case 5:
							var classificationMifIIFields = fields.a;
							var classif = $author$project$Perfimmo$Form$FormModel$getBlock(clientForm.jh).ea;
							var update = function () {
								if (!classificationMifIIFields.$) {
									var v = classificationMifIIFields.a;
									return _Utils_update(
										classif,
										{
											kv: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, v, classif.kv)
										});
								} else {
									var date = classificationMifIIFields.a;
									return _Utils_update(
										classif,
										{
											c$: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, date, classif.c$)
										});
								}
							}();
							return setProfilInvest(
								function (p) {
									return _Utils_update(
										p,
										{ea: update});
								});
						case 6:
							var v = fields.a;
							return setProfilInvest(
								function (p) {
									return _Utils_update(
										p,
										{
											gg: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, v, p.gg)
										});
								});
						case 7:
							var v = fields.a;
							return setProfilInvest(
								function (p) {
									return _Utils_update(
										p,
										{
											ji: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, v, p.ji)
										});
								});
						case 8:
							var v = fields.a;
							return setProfilInvest(
								function (p) {
									return _Utils_update(
										p,
										{
											hz: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, v, p.hz)
										});
								});
						case 9:
							var v = fields.a;
							return setProfilInvest(
								function (p) {
									return _Utils_update(
										p,
										{
											it: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, v, p.it)
										});
								});
						case 10:
							var v = fields.a;
							return setProfilInvest(
								function (p) {
									return _Utils_update(
										p,
										{
											f1: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, v, p.f1)
										});
								});
						default:
							var v = fields.a;
							return setProfilInvest(
								function (p) {
									return _Utils_update(
										p,
										{
											km: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, v, p.km)
										});
								});
					}
				case 3:
					var fields = field.a;
					var setProfilExtraFinancier = function (f) {
						return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$ModelOut(
							_Utils_update(
								clientForm,
								{
									jg: $author$project$Components$Clients$Detail$UpdateForm$FormValidator$calculProfilExtraFinancierFormBlockState(
										f(
											$author$project$Perfimmo$Form$FormModel$getBlock(clientForm.jg)))
								}));
					};
					switch (fields.$) {
						case 0:
							var newState = fields.a;
							return setProfilExtraFinancier(
								function (p) {
									return _Utils_update(
										p,
										{
											gy: A2(
												$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
												$elm$core$Maybe$Nothing,
												A2(
													$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
													$elm$core$Maybe$Just(0),
													_List_fromArray(
														[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
											i0: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, newState, p.i0)
										});
								});
						case 1:
							var newState = fields.a;
							return setProfilExtraFinancier(
								function (p) {
									return _Utils_update(
										p,
										{
											gy: A2(
												$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
												$elm$core$Maybe$Nothing,
												A2(
													$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
													$elm$core$Maybe$Just(0),
													_List_fromArray(
														[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
											i3: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, newState, p.i3)
										});
								});
						case 12:
							var newState = fields.a;
							return setProfilExtraFinancier(
								function (p) {
									return _Utils_update(
										p,
										{
											gy: A2(
												$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
												$elm$core$Maybe$Nothing,
												A2(
													$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
													$elm$core$Maybe$Just(0),
													_List_fromArray(
														[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
											i2: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, newState, p.i2)
										});
								});
						case 13:
							var newState = fields.a;
							return setProfilExtraFinancier(
								function (p) {
									return _Utils_update(
										p,
										{
											gy: A2(
												$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
												$elm$core$Maybe$Nothing,
												A2(
													$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
													$elm$core$Maybe$Just(0),
													_List_fromArray(
														[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
											i1: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, newState, p.i1)
										});
								});
						case 2:
							var newState = fields.a;
							return setProfilExtraFinancier(
								function (p) {
									return _Utils_update(
										p,
										{
											gy: A2(
												$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
												$elm$core$Maybe$Nothing,
												A2(
													$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
													$elm$core$Maybe$Just(0),
													_List_fromArray(
														[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
											iN: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, newState, p.iN)
										});
								});
						case 3:
							var newState = fields.a;
							return setProfilExtraFinancier(
								function (p) {
									return _Utils_update(
										p,
										{
											gy: A2(
												$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
												$elm$core$Maybe$Nothing,
												A2(
													$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
													$elm$core$Maybe$Just(0),
													_List_fromArray(
														[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
											iK: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, newState, p.iK)
										});
								});
						case 4:
							var newState = fields.a;
							return setProfilExtraFinancier(
								function (p) {
									return _Utils_update(
										p,
										{
											gy: A2(
												$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
												$elm$core$Maybe$Nothing,
												A2(
													$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
													$elm$core$Maybe$Just(0),
													_List_fromArray(
														[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
											iM: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, newState, p.iM)
										});
								});
						case 5:
							var newState = fields.a;
							return setProfilExtraFinancier(
								function (p) {
									return _Utils_update(
										p,
										{
											gy: A2(
												$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
												$elm$core$Maybe$Nothing,
												A2(
													$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
													$elm$core$Maybe$Just(0),
													_List_fromArray(
														[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
											iL: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, newState, p.iL)
										});
								});
						case 6:
							var newState = fields.a;
							return setProfilExtraFinancier(
								function (p) {
									return _Utils_update(
										p,
										{
											gy: A2(
												$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
												$elm$core$Maybe$Nothing,
												A2(
													$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
													$elm$core$Maybe$Just(0),
													_List_fromArray(
														[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
											iH: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, newState, p.iH)
										});
								});
						case 7:
							var newState = fields.a;
							return setProfilExtraFinancier(
								function (p) {
									return _Utils_update(
										p,
										{
											gy: A2(
												$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
												$elm$core$Maybe$Nothing,
												A2(
													$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
													$elm$core$Maybe$Just(0),
													_List_fromArray(
														[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
											iJ: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, newState, p.iJ)
										});
								});
						case 8:
							var newState = fields.a;
							return setProfilExtraFinancier(
								function (p) {
									return _Utils_update(
										p,
										{
											gy: A2(
												$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
												$elm$core$Maybe$Nothing,
												A2(
													$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
													$elm$core$Maybe$Just(0),
													_List_fromArray(
														[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
											iI: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, newState, p.iI)
										});
								});
						case 9:
							var newState = fields.a;
							return setProfilExtraFinancier(
								function (p) {
									return _Utils_update(
										p,
										{
											gy: A2(
												$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
												$elm$core$Maybe$Nothing,
												A2(
													$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
													$elm$core$Maybe$Just(0),
													_List_fromArray(
														[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
											iG: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, newState, p.iG)
										});
								});
						case 10:
							var newState = fields.a;
							return setProfilExtraFinancier(
								function (p) {
									return _Utils_update(
										p,
										{
											gy: A2(
												$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
												$elm$core$Maybe$Nothing,
												A2(
													$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
													$elm$core$Maybe$Just(0),
													_List_fromArray(
														[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
											iF: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, newState, p.iF)
										});
								});
						case 11:
							var pais = fields.a;
							return setProfilExtraFinancier(
								function (p) {
									return _Utils_update(
										p,
										{
											gy: A2(
												$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$init,
												$elm$core$Maybe$Nothing,
												A2(
													$author$project$Components$Clients$Detail$ModelForm$FormInitializers$noSync,
													$elm$core$Maybe$Just(0),
													_List_fromArray(
														[$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory]))),
											cx: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, pais, p.cx)
										});
								});
						case 14:
							var date = fields.a;
							return setProfilExtraFinancier(
								function (p) {
									return _Utils_update(
										p,
										{
											gy: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, date, p.gy)
										});
								});
						case 16:
							return setProfilExtraFinancier(
								function (p) {
									return p;
								});
						default:
							return setProfilExtraFinancier(
								function (p) {
									return p;
								});
					}
				default:
					var value = field.a;
					return A2(
						$elm$core$Maybe$withDefault,
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$ModelOut(clientForm),
						A2(
							$elm$core$Maybe$map,
							function (_v37) {
								var newModel = _v37.a;
								var newCmd = _v37.b;
								var out = _v37.c;
								var m = _Utils_update(
									clientForm,
									{ef: newModel});
								var eff = A2(
									$elm$core$Platform$Cmd$map,
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Value,
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Conseillers, $author$project$Components$Clients$Detail$Action$SetFormFields)),
									newCmd);
								return $author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$WithEffectOut(
									_Utils_Tuple2(m, eff));
							},
							A2(
								$elm$core$Maybe$map,
								function (msg) {
									return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$update, msg, clientForm.ef);
								},
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$extractValue(value))));
			}
		}();
		if (!setterResult.$) {
			var clientFormRepr = setterResult.a;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{gb: clientFormRepr}),
				$elm$core$Platform$Cmd$none);
		} else {
			var _v1 = setterResult.a;
			var clientFormRepr = _v1.a;
			var cmd = _v1.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{gb: clientFormRepr}),
				cmd);
		}
	});
var $author$project$Components$Clients$Detail$Update$update = F2(
	function (model, msg) {
		switch (msg.$) {
			case 0:
				var link = msg.a;
				var fetchClient = A3(
					$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
					link,
					$elm$http$Http$emptyBody,
					$elm$http$Http$expectJson($author$project$Components$Clients$Detail$Action$ClientFetched)($author$project$Components$Clients$QueryAPIModel$clientQueryViewJsonDecoder));
				return _Utils_Tuple2(model, fetchClient);
			case 1:
				if (!msg.a.$) {
					var client = msg.a.a;
					return _Utils_Tuple2(
						A2($author$project$Components$Clients$Detail$Model$setClient, model, client),
						$elm$core$Platform$Cmd$none);
				} else {
					var e = msg.a.a;
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var onglet = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fx: onglet}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var field = msg.a;
				var _v1 = A2($author$project$Components$Clients$Detail$UpdateForm$FormFieldSetter$setField, field, model);
				var newModel = _v1.a;
				var effect = _v1.b;
				var saveState = A2($author$project$Components$Clients$Detail$UpdateForm$FormValidator$checkReadyToSave, newModel.gb, newModel.d$);
				var checkModel = _Utils_update(
					newModel,
					{oq: saveState});
				var result = function () {
					var _v2 = checkModel.oq;
					switch (_v2.$) {
						case 0:
							return checkModel;
						case 1:
							return _Utils_update(
								checkModel,
								{mv: _List_Nil});
						case 2:
							var formEErors = _v2.a;
							return _Utils_update(
								checkModel,
								{
									mv: $elm$core$List$isEmpty(checkModel.mv) ? _List_Nil : formEErors
								});
						case 3:
							var formEErors = _v2.a;
							return _Utils_update(
								checkModel,
								{mv: formEErors});
						default:
							return checkModel;
					}
				}();
				return _Utils_Tuple2(result, effect);
			case 4:
				var _v3 = model.oq;
				switch (_v3.$) {
					case 0:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					case 3:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					case 4:
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					case 1:
						var client = _v3.a;
						return A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(
								_Utils_update(
									model,
									{oq: $author$project$Components$Clients$Detail$SaveState$NotModified}),
								$elm$core$Platform$Cmd$none),
							A2(
								$elm$core$Maybe$map,
								function (l) {
									return _Utils_Tuple2(
										_Utils_update(
											model,
											{oq: $author$project$Components$Clients$Detail$SaveState$SavingClient}),
										$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
											A2($author$project$Components$Clients$Detail$Action$UpsertClient, client, l)));
								},
								model.d1.kA));
					default:
						var errors = _v3.a;
						var m = _Utils_update(
							model,
							{
								mv: errors,
								oq: $author$project$Components$Clients$Detail$SaveState$DisplayError(errors)
							});
						return _Utils_Tuple2(m, $elm$core$Platform$Cmd$none);
				}
			case 5:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 6:
				var client = msg.a;
				var upsertClientLink = msg.b;
				var effect = A3(
					$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
					upsertClientLink,
					$elm$http$Http$jsonBody(
						$author$project$Components$Clients$APIModel$clientJsonEncoder(client)),
					$elm$http$Http$expectJson(
						$author$project$Components$Clients$Detail$Action$ClientUpserted(client))($author$project$Perfimmo$Api$CRUDResultWithFeedback$crudResultWithFeedbackJsonDecoder));
				return _Utils_Tuple2(model, effect);
			case 7:
				if (!msg.b.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{oq: $author$project$Components$Clients$Detail$SaveState$NotModified}),
						$elm$core$Platform$Cmd$none);
				} else {
					var client = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								oq: $author$project$Components$Clients$Detail$SaveState$ReadyToSave(client)
							}),
						$elm$core$Platform$Cmd$none);
				}
			case 8:
				var link = msg.a;
				var confirmDrawer = A4(
					$author$project$Components$Drawer$ConfirmationDrawer$init,
					link,
					$elm$http$Http$emptyBody,
					'Erreur lors de la tentative de suppression',
					A2($elm$http$Http$expectJson, $author$project$Components$Drawer$ConfirmationDrawer$callback, $author$project$Perfimmo$Api$CRUDResultWithFeedback$crudResultWithFeedbackJsonDecoder));
				return A3(
					$elm$core$Tuple$mapBoth,
					function (dm) {
						return _Utils_update(
							model,
							{
								lQ: $elm$core$Maybe$Just(dm)
							});
					},
					$elm$core$Platform$Cmd$map($author$project$Components$Clients$Detail$Action$DeleteAction),
					confirmDrawer);
			default:
				var deletionMsg = msg.a;
				return A3(
					$elm$core$Tuple$mapBoth,
					function (newDeleteModel) {
						return _Utils_update(
							model,
							{lQ: newDeleteModel});
					},
					$elm$core$Platform$Cmd$map($author$project$Components$Clients$Detail$Action$DeleteAction),
					A2($author$project$Components$Drawer$ConfirmationDrawer$update, model.lQ, deletionMsg));
		}
	});
var $author$project$Components$Clients$List$Update$ClientIsSynchronisedWithExtTool = F3(
	function (a, b, c) {
		return {$: 12, a: a, b: b, c: c};
	});
var $author$project$Components$Clients$List$Model$ClientToSyncDrawer = 0;
var $author$project$Components$Clients$List$Update$ClientsFetched = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$List$Update$ClientsFromExtToolRetrieved = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Clients$List$Update$RetrieveClientsFromExtTool = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Clients$List$Update$SyncDrawerEvents = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Components$Clients$List$Update$SyncProcessIsFinished = function (a) {
	return {$: 17, a: a};
};
var $author$project$Components$Clients$List$Update$SynchroniseClientWithExtTool = F4(
	function (a, b, c, d) {
		return {$: 11, a: a, b: b, c: c, d: d};
	});
var $author$project$Components$Clients$APIModel$ExtSystemClient = F2(
	function (id, name) {
		return {hB: id, nz: name};
	});
var $author$project$Components$Clients$APIModel$extSystemClientJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'name',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'id',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Components$Clients$APIModel$ExtSystemClient)));
var $author$project$Components$Clients$List$Model$extSystemClientsAlreadySet = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		false,
		A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.br;
				},
				A2($elm$core$Basics$composeR, $elm$core$List$isEmpty, $elm$core$Basics$not)),
			model.k.ai));
};
var $author$project$Components$Clients$APIModel$ListClientsResult = F3(
	function (result, availableConseillers, links) {
		return {k8: availableConseillers, nf: links, ok: result};
	});
var $author$project$Components$Clients$APIModel$AvailableConseiller = F2(
	function (uid, label) {
		return {m9: label, kx: uid};
	});
var $author$project$Components$Clients$APIModel$availableConseillerJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'label',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'uid',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Components$Clients$APIModel$AvailableConseiller)));
var $author$project$Components$Clients$APIModel$ClientAbstract = F6(
	function (uid, name, natureJuridique, conseillers, externalSystemSync, links) {
		return {ef: conseillers, mi: externalSystemSync, nf: links, nz: name, nB: natureJuridique, kx: uid};
	});
var $author$project$Components$Clients$APIModel$Harvest = $elm$core$Basics$identity;
var $author$project$Components$Clients$APIModel$HarvestSystemSync = {};
var $author$project$Components$Clients$APIModel$harvestSystemSyncDecoder = $elm$json$Json$Decode$succeed($author$project$Components$Clients$APIModel$HarvestSystemSync);
var $author$project$Components$Clients$APIModel$externalSystemSyncJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Harvest',
			A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $author$project$Components$Clients$APIModel$harvestSystemSyncDecoder))
		]));
var $author$project$Components$Clients$APIModel$PersonneMoraleLabel = 1;
var $author$project$Components$Clients$APIModel$PersonnePhysiqueLabel = 0;
var $author$project$Components$Clients$APIModel$natureJuridiqueLabelJsonDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (s) {
		switch (s) {
			case 'PersonnePhysique':
				return $elm$json$Json$Decode$succeed(0);
			case 'PersonneMorale':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('unknwon NatureJuridique value: ' + s);
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Components$Clients$APIModel$clientAbstractJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'externalSystemSync',
		$elm$json$Json$Decode$nullable($author$project$Components$Clients$APIModel$externalSystemSyncJsonDecoder),
		$elm$core$Maybe$Nothing,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'conseillers',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'natureJuridique',
				$author$project$Components$Clients$APIModel$natureJuridiqueLabelJsonDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'name',
					$elm$json$Json$Decode$string,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'uid',
						$elm$json$Json$Decode$string,
						$elm$json$Json$Decode$succeed($author$project$Components$Clients$APIModel$ClientAbstract)))))));
var $author$project$Components$Clients$APIModel$listClientsResultJsonDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'availableConseillers',
		$elm$json$Json$Decode$list($author$project$Components$Clients$APIModel$availableConseillerJsonDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'result',
			$elm$json$Json$Decode$list($author$project$Components$Clients$APIModel$clientAbstractJsonDecoder),
			$elm$json$Json$Decode$succeed($author$project$Components$Clients$APIModel$ListClientsResult))));
var $author$project$Components$Clients$HttpAPI$fetchClients = function (link) {
	return A3(
		$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
		link,
		$elm$http$Http$emptyBody,
		$elm$http$Http$expectJson($elm$core$Basics$identity)($author$project$Components$Clients$APIModel$listClientsResultJsonDecoder));
};
var $author$project$Components$Clients$List$Update$filterClientByConseillers = F2(
	function (clients, conseillers) {
		return $elm$core$List$isEmpty(conseillers) ? clients : A2(
			$elm$core$List$filter,
			function (c) {
				return A2(
					$elm$core$List$any,
					function (cons) {
						return A2(
							$elm$core$List$any,
							function (cc) {
								return _Utils_eq(cc, cons);
							},
							conseillers);
					},
					c.ef);
			},
			clients);
	});
var $author$project$Components$Clients$List$Update$filterClientByName = F2(
	function (clients, search) {
		return A2(
			$elm$core$List$filter,
			function (c) {
				return A2(
					$elm$core$String$contains,
					$elm$core$String$toLower(search),
					$elm$core$String$toLower(c.nz));
			},
			clients);
	});
var $elm_community$list_extra$List$Extra$unique = function (list) {
	return A4($elm_community$list_extra$List$Extra$uniqueHelp, $elm$core$Basics$identity, $elm$core$Set$empty, list, _List_Nil);
};
var $author$project$Components$Clients$List$Model$filterExtClientsByName = F2(
	function (client, extClients) {
		var splitName = function (c) {
			return A2($elm$core$String$split, ' ', c.nz);
		};
		var filter = F2(
			function (label, xs) {
				return A2(
					$elm$core$List$any,
					function (x) {
						return A2(
							$elm$core$String$contains,
							$elm$core$String$toLower(x),
							$elm$core$String$toLower(label));
					},
					xs);
			});
		var exactMatch = $elm_community$maybe_extra$Maybe$Extra$toList(
			A2(
				$elm_community$list_extra$List$Extra$find,
				function (_v1) {
					var label = _v1.b;
					return _Utils_eq(
						$elm$core$String$toLower(client.nz),
						$elm$core$String$toLower(label));
				},
				extClients));
		return $elm_community$list_extra$List$Extra$unique(
			_Utils_ap(
				exactMatch,
				A2(
					$elm$core$List$filter,
					function (_v0) {
						var label = _v0.b;
						return A2(
							filter,
							label,
							splitName(client));
					},
					extClients)));
	});
var $author$project$Components$Clients$List$Model$limitToNElem = F2(
	function (i, xs) {
		return A2($elm$core$List$take, i, xs);
	});
var $author$project$Components$Clients$List$Model$updateSyncClient = F2(
	function (model, update) {
		var extSyncP = model.k;
		var syncClient = extSyncP.ai;
		return A2(
			$elm$core$Maybe$withDefault,
			model,
			A2(
				$elm$core$Maybe$map,
				function (s) {
					return _Utils_update(
						model,
						{
							k: update(s)
						});
				},
				syncClient));
	});
var $author$project$Components$Clients$List$Model$filterExtToolClients = F2(
	function (model, search) {
		var filterClients = function (extCs) {
			return A2(
				$elm$core$List$filter,
				function (_v1) {
					var n = _v1.b;
					return A2(
						$elm$core$String$contains,
						$elm$core$String$toLower(search),
						$elm$core$String$toLower(n));
				},
				extCs);
		};
		var whatFilter = function (s) {
			var _v0 = $elm$core$String$isEmpty(search);
			if (_v0) {
				return A2($author$project$Components$Clients$List$Model$filterExtClientsByName, s.ga, s.br);
			} else {
				return filterClients(s.br);
			}
		};
		var extSyncP = model.k;
		return A2(
			$author$project$Components$Clients$List$Model$updateSyncClient,
			model,
			function (s) {
				return _Utils_update(
					extSyncP,
					{
						ai: $elm$core$Maybe$Just(
							_Utils_update(
								s,
								{
									eC: search,
									c7: A2(
										$author$project$Components$Clients$List$Model$limitToNElem,
										4,
										whatFilter(s))
								}))
					});
			});
	});
var $author$project$Components$Drawer$Model$open = {ow: 0};
var $author$project$Components$Clients$List$Model$setExtSystemClients = F2(
	function (model, extClients) {
		var extSyncP = model.k;
		return A2(
			$author$project$Components$Clients$List$Model$updateSyncClient,
			model,
			function (s) {
				return _Utils_update(
					extSyncP,
					{
						bs: extClients,
						ai: $elm$core$Maybe$Just(
							_Utils_update(
								s,
								{
									br: extClients,
									c7: A2(
										$author$project$Components$Clients$List$Model$limitToNElem,
										4,
										A2($author$project$Components$Clients$List$Model$filterExtClientsByName, s.ga, extClients)),
									eQ: false
								}))
					});
			});
	});
var $author$project$Components$Clients$List$Model$initClientToSynchronise = F2(
	function (model, mat) {
		var ext = model.k;
		var extUpdate = _Utils_update(
			ext,
			{
				ai: $elm$core$Maybe$Just(
					{ga: mat.ga, eq: $author$project$Components$Drawer$Model$open, g$: mat.dR.g$, br: _List_Nil, eC: '', c7: _List_Nil, hn: mat.dR.ho, eQ: true, e5: $elm$core$Maybe$Nothing, j7: mat.dR.j8})
			});
		var updatedModel = _Utils_update(
			model,
			{k: extUpdate});
		return $elm$core$List$isEmpty(model.k.bs) ? updatedModel : A2($author$project$Components$Clients$List$Model$setExtSystemClients, updatedModel, model.k.bs);
	});
var $author$project$Perfimmo$Form$FormModel$RadioLeftChecked = 0;
var $author$project$Components$Clients$List$Model$initEndSyncProcess = F3(
	function (model, extSyst, pullExtToolClientsLink) {
		var ext = model.k;
		var endSyncProcessModel = {
			ep: $author$project$Components$Drawer$Model$open,
			jk: {g$: extSyst, oc: pullExtToolClientsLink},
			dx: 0
		};
		return _Utils_update(
			model,
			{
				k: _Utils_update(
					ext,
					{
						aW: $elm$core$Maybe$Just(endSyncProcessModel)
					})
			});
	});
var $author$project$Perfimmo$Form$FormModel$radioCheckedSideToBool = function (checkSide) {
	if (!checkSide) {
		return false;
	} else {
		return true;
	}
};
var $author$project$Components$Clients$List$Model$setAbstractClientsLinks = F2(
	function (links, model) {
		var set = function (l) {
			return _Utils_update(
				l,
				{
					gm: A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'create', links),
					ff: A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'external_system_sync', links)
				});
		};
		return _Utils_update(
			model,
			{
				k_: set(model.k_)
			});
	});
var $author$project$Components$Clients$APIModel$ConseillerAbstract = F2(
	function (uid, name) {
		return {nz: name, kx: uid};
	});
var $author$project$Components$Clients$List$Model$setAvailableConseillers = F2(
	function (conseillers, model) {
		var conseillersTuples = A2(
			$elm$core$List$map,
			function (c) {
				return _Utils_Tuple2(c.kx, c.m9);
			},
			conseillers);
		var availableConseillers = A2(
			$elm$core$List$map,
			function (c) {
				return A2($author$project$Components$Clients$APIModel$ConseillerAbstract, c.kx, c.m9);
			},
			conseillers);
		return _Utils_update(
			model,
			{
				k8: availableConseillers,
				cX: A3($inkuzmin$elm_multiselect$Multiselect$populateValues, model.cX, conseillersTuples, _List_Nil)
			});
	});
var $author$project$Components$Clients$List$Model$setChosenExtClient = F2(
	function (model, choice) {
		var extSyncP = model.k;
		return A2(
			$author$project$Components$Clients$List$Model$updateSyncClient,
			model,
			function (s) {
				return _Utils_update(
					extSyncP,
					{
						ai: $elm$core$Maybe$Just(
							_Utils_update(
								s,
								{
									e5: $elm$core$Maybe$Just(choice)
								}))
					});
			});
	});
var $author$project$Components$Clients$List$Model$IsSync = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$List$Model$isSync = function (c) {
	var _v0 = c.bQ;
	if (_v0.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Components$Clients$List$Model$isThereAllSync = function (mustClients) {
	return !A2(
		$elm$core$List$any,
		A2($elm$core$Basics$composeL, $elm$core$Basics$not, $author$project$Components$Clients$List$Model$isSync),
		mustClients);
};
var $elm_community$list_extra$List$Extra$updateIf = F3(
	function (predicate, update, list) {
		return A2(
			$elm$core$List$map,
			function (item) {
				return predicate(item) ? update(item) : item;
			},
			list);
	});
var $author$project$Components$Clients$List$Model$setClientIsSync = F3(
	function (model, clientId, extClientId) {
		var update = function (c) {
			var _v1 = c.bQ;
			if (_v1.$ === 2) {
				var shouldBeSyncPart = _v1.a;
				return _Utils_update(
					c,
					{
						bQ: $author$project$Components$Clients$List$Model$IsSync(shouldBeSyncPart.g$)
					});
			} else {
				return c;
			}
		};
		var model_withRemovedChosenExtClient = A2(
			$elm$core$Maybe$withDefault,
			model,
			A2(
				$elm$core$Maybe$map,
				function (extId) {
					return A2(
						$author$project$Components$Clients$List$Model$setExtSystemClients,
						model,
						A2(
							$elm$core$List$filter,
							function (_v0) {
								var id = _v0.a;
								return !_Utils_eq(id, extId);
							},
							model.k.bs));
				},
				extClientId));
		var extSyncP = model_withRemovedChosenExtClient.k;
		var changeSyncStatus = function (cs) {
			return A3(
				$elm_community$list_extra$List$Extra$updateIf,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.kx;
					},
					$elm$core$Basics$eq(clientId)),
				update,
				cs);
		};
		var result = changeSyncStatus(model.ca);
		return _Utils_update(
			model_withRemovedChosenExtClient,
			{
				ca: result,
				k: $author$project$Components$Clients$List$Model$isThereAllSync(result) ? _Utils_update(
					extSyncP,
					{dg: true}) : extSyncP,
				ck: changeSyncStatus(model.ck)
			});
	});
var $author$project$Components$Clients$List$Model$Harvest = 0;
var $author$project$Components$Clients$List$Model$ShouldBeSync = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$List$Model$ShouldNotBeSync = {$: 0};
var $author$project$Components$Clients$List$Model$setClients = F2(
	function (clients, model) {
		var extSyst = function () {
			var _v0 = model.k.eN;
			if (!_v0.$) {
				var _v1 = _v0.a;
				return $elm$core$Maybe$Just(0);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		var extSyncP = model.k;
		var calculSyncStatus = function (c) {
			return A2(
				$elm$core$Maybe$withDefault,
				$author$project$Components$Clients$List$Model$ShouldNotBeSync,
				A2(
					$elm$core$Maybe$map,
					function (ext) {
						return A2(
							$elm$core$Maybe$withDefault,
							$author$project$Components$Clients$List$Model$IsSync(ext),
							A3(
								$elm$core$Maybe$map2,
								F2(
									function (sync, get) {
										return $author$project$Components$Clients$List$Model$ShouldBeSync(
											{g$: ext, ho: get, j8: sync});
									}),
								$author$project$Main$NavLinkRef$syncClientWithExtToolRel(c),
								$author$project$Main$NavLinkRef$getExtToolClientsRel(c)));
					},
					extSyst));
		};
		var toMust = function (c) {
			return {
				ef: c.ef,
				nf: c.nf,
				nz: c.nz,
				nB: c.nB,
				bQ: calculSyncStatus(c.nf),
				kx: c.kx
			};
		};
		var mustClients = A2($elm$core$List$map, toMust, clients);
		return _Utils_update(
			model,
			{
				d$: clients,
				ca: mustClients,
				k: _Utils_update(
					extSyncP,
					{
						dg: $author$project$Components$Clients$List$Model$isThereAllSync(mustClients) ? true : false,
						e8: $elm_community$maybe_extra$Maybe$Extra$isJust(model.k_.ff)
					}),
				ck: mustClients
			});
	});
var $author$project$Components$Clients$List$Model$setSyncDrawerModel = F3(
	function (model, drawerModel, tag) {
		var extSyncP = model.k;
		var syncClient = extSyncP.ai;
		var endProcess = extSyncP.aW;
		if (!tag) {
			return A2(
				$elm$core$Maybe$withDefault,
				model,
				A2(
					$elm$core$Maybe$map,
					function (s) {
						return _Utils_update(
							model,
							{
								k: _Utils_update(
									extSyncP,
									{
										ai: $elm$core$Maybe$Just(
											_Utils_update(
												s,
												{eq: drawerModel}))
									})
							});
					},
					syncClient));
		} else {
			return A2(
				$elm$core$Maybe$withDefault,
				model,
				A2(
					$elm$core$Maybe$map,
					function (p) {
						return _Utils_update(
							model,
							{
								k: _Utils_update(
									extSyncP,
									{
										aW: $elm$core$Maybe$Just(
											_Utils_update(
												p,
												{ep: drawerModel}))
									})
							});
					},
					endProcess));
		}
	});
var $author$project$Components$Clients$List$Model$setSyncWithExtSystemStatus = function (cabinet) {
	return A2(
		$elm$core$Maybe$map,
		function (s) {
			return 0;
		},
		cabinet.mh);
};
var $author$project$Perfimmo$Form$FormModel$RadioRightChecked = 1;
var $author$project$Perfimmo$Form$FormModel$toggleRadioCheckedSide = function (checkSide) {
	if (!checkSide) {
		return 1;
	} else {
		return 0;
	}
};
var $author$project$Components$Clients$List$Model$toggleEndSyncProcessChoice = function (model) {
	var ext = model.k;
	var toggle = A2(
		$elm$core$Maybe$map,
		function (p) {
			return _Utils_update(
				p,
				{
					dx: $author$project$Perfimmo$Form$FormModel$toggleRadioCheckedSide(p.dx)
				});
		},
		ext.aW);
	return _Utils_update(
		model,
		{
			k: _Utils_update(
				ext,
				{aW: toggle})
		});
};
var $author$project$Components$Drawer$Update$update = F2(
	function (model, msg) {
		if (!msg.$) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{ow: 1}),
				$elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Components$Clients$List$Update$update = F2(
	function (model, action) {
		update:
		while (true) {
			switch (action.$) {
				case 0:
					if (!action.a.$) {
						var result = action.a.a;
						var ext = model.k;
						var m = _Utils_update(
							model,
							{
								k: _Utils_update(
									ext,
									{
										eN: A2(
											$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
											$author$project$Components$Clients$List$Model$setSyncWithExtSystemStatus,
											$elm$core$List$head(result.ok))
									})
							});
						var effect = A2(
							$elm$core$Platform$Cmd$map,
							$author$project$Components$Clients$List$Update$ClientsFetched,
							$author$project$Components$Clients$HttpAPI$fetchClients(model.k_.mm));
						return _Utils_Tuple2(m, effect);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 1:
					if (!action.a.$) {
						var result = action.a.a;
						var clientFetchedModel = A2(
							$author$project$Components$Clients$List$Model$setClients,
							result.ok,
							A2(
								$author$project$Components$Clients$List$Model$setAvailableConseillers,
								result.k8,
								A2($author$project$Components$Clients$List$Model$setAbstractClientsLinks, result.nf, model)));
						return _Utils_Tuple2(clientFetchedModel, $elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 2:
					var search = action.a;
					var searchModel = _Utils_update(
						model,
						{
							ck: A2($author$project$Components$Clients$List$Update$filterClientByName, model.ca, search),
							jG: $elm$core$Maybe$Just(search)
						});
					return _Utils_Tuple2(searchModel, $elm$core$Platform$Cmd$none);
				case 3:
					var msg = action.a;
					var _v1 = A2($inkuzmin$elm_multiselect$Multiselect$update, msg, model.cX);
					var multiModel = _v1.a;
					var multiCmd = _v1.b;
					var conseillersChosen = A2(
						$elm$core$List$map,
						$elm$core$Tuple$first,
						$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(multiModel));
					var filterClients = A2($author$project$Components$Clients$List$Update$filterClientByConseillers, model.ca, conseillersChosen);
					var updatedModel = _Utils_update(
						model,
						{cX: multiModel, ck: filterClients});
					return _Utils_Tuple2(
						updatedModel,
						A2($elm$core$Platform$Cmd$map, $author$project$Components$Clients$List$Update$FilterByConseillers, multiCmd));
				case 4:
					var sync = action.a;
					var initedClientToSync = A2($author$project$Components$Clients$List$Model$initClientToSynchronise, model, sync);
					var fetchExtClientOnceEffect = $author$project$Components$Clients$List$Model$extSystemClientsAlreadySet(initedClientToSync) ? $elm$core$Platform$Cmd$none : $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
						$author$project$Components$Clients$List$Update$RetrieveClientsFromExtTool(sync.dR.ho));
					return _Utils_Tuple2(initedClientToSync, fetchExtClientOnceEffect);
				case 5:
					var link = action.a;
					var effect = A3(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
						link,
						$elm$http$Http$emptyBody,
						$elm$http$Http$expectJson($author$project$Components$Clients$List$Update$ClientsFromExtToolRetrieved)(
							$PerformanceIMMO$elm_utils$Perfimmo$Http$RestAPIUtils$seqAPIResultJsonDecoder($author$project$Components$Clients$APIModel$extSystemClientJsonDecoder)));
					return _Utils_Tuple2(model, effect);
				case 6:
					if (!action.a.$) {
						var result = action.a.a;
						var extClients = A2(
							$elm$core$List$map,
							function (c) {
								return _Utils_Tuple2(c.hB, c.nz);
							},
							result.ok);
						return _Utils_Tuple2(
							A2($author$project$Components$Clients$List$Model$setExtSystemClients, model, extClients),
							$elm$core$Platform$Cmd$none);
					} else {
						var e = action.a.a;
						return _Utils_Tuple2(
							A2($author$project$Components$Clients$List$Model$setExtSystemClients, model, _List_Nil),
							$elm$core$Platform$Cmd$none);
					}
				case 7:
					var tag = action.a;
					var drawerMsg = action.b;
					if (drawerMsg.$ === 1) {
						var msg_ = drawerMsg.a;
						var $temp$model = model,
							$temp$action = msg_;
						model = $temp$model;
						action = $temp$action;
						continue update;
					} else {
						var internalMsg = drawerMsg;
						var execDrawerMsg = function (_v4) {
							var m = _v4.a;
							var msg_i = _v4.b;
							return _Utils_Tuple2(
								A3($author$project$Components$Clients$List$Model$setSyncDrawerModel, model, m, tag),
								A2(
									$elm$core$Platform$Cmd$map,
									$author$project$Components$Clients$List$Update$SyncDrawerEvents(tag),
									msg_i));
						};
						if (!tag) {
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
								A2(
									$elm$core$Maybe$map,
									function (c) {
										return execDrawerMsg(
											A2($author$project$Components$Drawer$Update$update, c.eq, internalMsg));
									},
									model.k.ai));
						} else {
							return A2(
								$elm$core$Maybe$withDefault,
								_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
								A2(
									$elm$core$Maybe$map,
									function (p) {
										return execDrawerMsg(
											A2($author$project$Components$Drawer$Update$update, p.ep, internalMsg));
									},
									model.k.aW));
						}
					}
				case 8:
					var search = action.a;
					return _Utils_Tuple2(
						A2($author$project$Components$Clients$List$Model$filterExtToolClients, model, search),
						$elm$core$Platform$Cmd$none);
				case 9:
					var choice = action.a;
					return _Utils_Tuple2(
						A2($author$project$Components$Clients$List$Model$setChosenExtClient, model, choice),
						$elm$core$Platform$Cmd$none);
				case 10:
					var clientToSync = action.a;
					var syncChoice = action.b;
					var effect = $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
						A4($author$project$Components$Clients$List$Update$SynchroniseClientWithExtTool, clientToSync.ga.kx, syncChoice, clientToSync.g$, clientToSync.j7));
					return _Utils_Tuple2(
						A3(
							$author$project$Components$Clients$List$Model$setSyncDrawerModel,
							model,
							{ow: 1},
							0),
						effect);
				case 11:
					var clientId = action.a;
					var syncChoice = action.b;
					var extSystem = action.c;
					var link = action.d;
					var extSystemLabel = 'Harvest';
					var _v5 = function () {
						if (!syncChoice.$) {
							return _Utils_Tuple2(
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'CreateNew',
											$elm$json$Json$Encode$object(_List_Nil))
										])),
								$elm$core$Maybe$Nothing);
						} else {
							var extClientId = syncChoice.a;
							return _Utils_Tuple2(
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'SyncWith',
											$elm$json$Json$Encode$object(
												_List_fromArray(
													[
														_Utils_Tuple2(
														'extClientId',
														$elm$json$Json$Encode$string(extClientId))
													])))
										])),
								$elm$core$Maybe$Just(extClientId));
						}
					}();
					var syncChoiceBody = _v5.a;
					var extSystClientId = _v5.b;
					var bodyReq = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'extSystem',
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											extSystemLabel,
											$elm$json$Json$Encode$object(_List_Nil))
										]))),
								_Utils_Tuple2('syncChoice', syncChoiceBody)
							]));
					var effect = A3(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
						link,
						$elm$http$Http$jsonBody(bodyReq),
						$elm$http$Http$expectWhatever(
							A2($author$project$Components$Clients$List$Update$ClientIsSynchronisedWithExtTool, clientId, extSystClientId)));
					return _Utils_Tuple2(model, effect);
				case 12:
					if (!action.c.$) {
						var clientId = action.a;
						var extClientId = action.b;
						return _Utils_Tuple2(
							A3($author$project$Components$Clients$List$Model$setClientIsSync, model, clientId, extClientId),
							$elm$core$Platform$Cmd$none);
					} else {
						var e = action.c.a;
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 13:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 14:
					var extTool = action.a;
					var restNavLink = action.b;
					return _Utils_Tuple2(
						A3($author$project$Components$Clients$List$Model$initEndSyncProcess, model, extTool, restNavLink),
						$elm$core$Platform$Cmd$none);
				case 15:
					return _Utils_Tuple2(
						$author$project$Components$Clients$List$Model$toggleEndSyncProcessChoice(model),
						$elm$core$Platform$Cmd$none);
				case 16:
					var endProcess = action.a;
					var restNavigationLink = endProcess.jk.oc;
					var pullChoice = $author$project$Perfimmo$Form$FormModel$radioCheckedSideToBool(endProcess.dx);
					var extTool = endProcess.jk.g$;
					var ext = model.k;
					var m = _Utils_update(
						model,
						{
							k: _Utils_update(
								ext,
								{aW: $elm$core$Maybe$Nothing, hU: true})
						});
					var body = $elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'extSystem',
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'Harvest',
											$elm$json$Json$Encode$object(_List_Nil))
										]))),
								_Utils_Tuple2(
								'pullingExtToolClientsChoice',
								$elm$json$Json$Encode$bool(pullChoice))
							]));
					var effect = A3(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
						restNavigationLink,
						$elm$http$Http$jsonBody(body),
						$elm$http$Http$expectWhatever($author$project$Components$Clients$List$Update$SyncProcessIsFinished));
					return _Utils_Tuple2(m, effect);
				default:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$Components$Errors$Errors$traceThis = _Platform_outgoingPort('traceThis', $elm$json$Json$Encode$string);
var $author$project$Components$Errors$Errors$update = F2(
	function (a, model) {
		if (!a.$) {
			var m = a.a;
			var newCmd = $elm$core$Platform$Cmd$none;
			var cmdTrace = function () {
				if (m.$ === 1) {
					var errors = m.a;
					return $author$project$Components$Errors$Errors$traceThis(
						A2(
							$elm$core$String$join,
							'\n',
							$mgold$elm_nonempty_list$List$Nonempty$toList(errors)));
				} else {
					var nel = m.a;
					return $elm$core$Platform$Cmd$none;
				}
			}();
			var cmds = $elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[newCmd, cmdTrace]));
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						cp: $elm$core$Maybe$Just(m)
					}),
				cmds);
		} else {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{cp: $elm$core$Maybe$Nothing}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Components$ForgotPassword$Model$Error = 2;
var $author$project$Components$ForgotPassword$Model$ReadyToSave = 1;
var $author$project$Components$ForgotPassword$Update$ResetPasswordAsked = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$ForgotPassword$Model$Saving = 3;
var $author$project$Components$ForgotPassword$Update$PIEventApiError = F2(
	function (errorType, message) {
		return {ma: errorType, a4: message};
	});
var $author$project$Components$ForgotPassword$Update$piEventApiErrorDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'message',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'errorType',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Components$ForgotPassword$Update$PIEventApiError)));
var $author$project$Components$ForgotPassword$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var password = msg.a;
				var m = _Utils_update(
					model,
					{
						nh: $PerformanceIMMO$elm_utils$Perfimmo$Primitive$ExtendString$stringToNonEmptyString(password),
						oq: 1
					});
				return _Utils_Tuple2(m, $elm$core$Platform$Cmd$none);
			case 1:
				var askResetpasswordBody = function (login) {
					return $elm$http$Http$jsonBody(
						$elm$json$Json$Encode$object(
							_List_fromArray(
								[
									_Utils_Tuple2(
									'email',
									$elm$json$Json$Encode$string(login))
								])));
				};
				var askResetPasswordEff = function (pass) {
					return A3(
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
						model.k0,
						askResetpasswordBody(pass),
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$expectWhateverOkResult($author$project$Components$ForgotPassword$Update$ResetPasswordAsked)($author$project$Components$ForgotPassword$Update$piEventApiErrorDecoder));
				};
				var _v1 = A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Maybe$map,
						function (login) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{oq: 3}),
								askResetPasswordEff(login));
						},
						model.nh));
				var m = _v1.a;
				var effect = _v1.b;
				return _Utils_Tuple2(m, effect);
			default:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{oq: 0}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{oq: 2}),
						$elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Components$Landing$Update$CaFetched = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Landing$Update$ClientsFetched = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Landing$Update$PreferredDocsFetched = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$Landing$Update$ProductionFetched = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Landing$Model$BasicCaResults = function (caProducteur) {
	return {li: caProducteur};
};
var $author$project$Components$Landing$Model$BasicValueResult = function (value) {
	return {o6: value};
};
var $author$project$Components$Landing$Bindings$valueResultDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'value',
	$elm$json$Json$Decode$float,
	$elm$json$Json$Decode$succeed($author$project$Components$Landing$Model$BasicValueResult));
var $author$project$Components$Landing$Bindings$caResultsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'caProducteur',
	$elm$json$Json$Decode$list($author$project$Components$Landing$Bindings$valueResultDecoder),
	$elm$json$Json$Decode$succeed($author$project$Components$Landing$Model$BasicCaResults));
var $author$project$Components$Landing$Model$EmptyRecord = {};
var $author$project$Components$Landing$Bindings$emptyRecordDecoder = $elm$json$Json$Decode$succeed($author$project$Components$Landing$Model$EmptyRecord);
var $author$project$Components$Landing$Model$PreferredDoc = F2(
	function (uid, name) {
		return {nz: name, kx: uid};
	});
var $author$project$Components$Landing$Bindings$preferredDocsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'name',
	$elm$json$Json$Decode$string,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'uid',
		$elm$json$Json$Decode$string,
		$elm$json$Json$Decode$succeed($author$project$Components$Landing$Model$PreferredDoc)));
var $author$project$Components$Landing$Model$BasicProductionResults = function (aggByActivite) {
	return {kU: aggByActivite};
};
var $author$project$Components$Landing$Bindings$productionResultsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'aggByActivite',
	$elm$json$Json$Decode$list($author$project$Components$Landing$Bindings$valueResultDecoder),
	$elm$json$Json$Decode$succeed($author$project$Components$Landing$Model$BasicProductionResults));
var $author$project$Components$Landing$Model$BasicSearchApiResult = function (result) {
	return {ok: result};
};
var $author$project$Components$Landing$Bindings$searchApiResultJsonDecoder = function (decA) {
	return A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'result',
		$elm$json$Json$Decode$list(decA),
		$elm$json$Json$Decode$succeed($author$project$Components$Landing$Model$BasicSearchApiResult));
};
var $author$project$Components$Landing$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var restNavigationLink = msg.a;
				var cmd = A3(
					$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
					restNavigationLink,
					$elm$http$Http$emptyBody,
					A2(
						$elm$http$Http$expectJson,
						$author$project$Components$Landing$Update$ClientsFetched,
						$author$project$Components$Landing$Bindings$searchApiResultJsonDecoder($author$project$Components$Landing$Bindings$emptyRecordDecoder)));
				return _Utils_Tuple2(model, cmd);
			case 1:
				if (!msg.a.$) {
					var r = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								lC: $elm$core$Maybe$Just(
									$elm$core$List$length(r.ok))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var restNavigationLink = msg.a;
				var cmd = A3(
					$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
					restNavigationLink,
					$elm$http$Http$emptyBody,
					A2($elm$http$Http$expectJson, $author$project$Components$Landing$Update$CaFetched, $author$project$Components$Landing$Bindings$caResultsDecoder));
				return _Utils_Tuple2(model, cmd);
			case 3:
				if (!msg.a.$) {
					var r = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								lh: $elm$core$Maybe$Just(
									A3(
										$elm$core$List$foldl,
										$elm$core$Basics$add,
										0,
										A2(
											$elm$core$List$map,
											function ($) {
												return $.o6;
											},
											r.li)))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 4:
				var restNavigationLink = msg.a;
				var cmd = A3(
					$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
					restNavigationLink,
					$elm$http$Http$emptyBody,
					A2($elm$http$Http$expectJson, $author$project$Components$Landing$Update$ProductionFetched, $author$project$Components$Landing$Bindings$productionResultsDecoder));
				return _Utils_Tuple2(model, cmd);
			case 5:
				if (!msg.a.$) {
					var r = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								n6: $elm$core$Maybe$Just(
									A3(
										$elm$core$List$foldl,
										$elm$core$Basics$add,
										0,
										A2(
											$elm$core$List$map,
											function ($) {
												return $.o6;
											},
											r.kU)))
							}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 6:
				var restNavigationLink = msg.a;
				var cmd = A3(
					$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
					restNavigationLink,
					$elm$http$Http$emptyBody,
					A2(
						$elm$http$Http$expectJson,
						$author$project$Components$Landing$Update$PreferredDocsFetched,
						$elm$json$Json$Decode$list($author$project$Components$Landing$Bindings$preferredDocsDecoder)));
				return _Utils_Tuple2(model, cmd);
			default:
				if (!msg.a.$) {
					var r = msg.a.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{n3: r}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Components$Loading$Update$update = F2(
	function (msg, model) {
		if (!msg) {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{ng: model.ng + 1}),
				$elm$core$Platform$Cmd$none);
		} else {
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{
						ng: (model.ng > 0) ? (model.ng - 1) : model.ng
					}),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Components$Produits$Details$Model$ForCabinet = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Components$Produits$Details$Update$ProduitSaved = function (a) {
	return {$: 5, a: a};
};
var $author$project$Perfimmo$Search$caseInsensitiveContains = function (_v0) {
	var text = _v0.kh;
	var substring = _v0.j5;
	return A2(
		$elm$core$String$contains,
		$elm$core$String$trim(
			$elm$core$String$toLower(substring)),
		$elm$core$String$toLower(text));
};
var $author$project$Perfimmo$Input$SelectInput$getSelectedValue = function (model) {
	return model.dO;
};
var $author$project$Components$Produits$Details$FormSetter$SupportsSetter$applyFilters = function (supportTab) {
	var supportsToFilter = supportTab.fe;
	var filterByNameOrSupportId = function (support) {
		return $author$project$Perfimmo$Search$caseInsensitiveContains(
			{j5: supportTab.e4, kh: support.nz}) || $author$project$Perfimmo$Search$caseInsensitiveContains(
			{j5: supportTab.e4, kh: support.oE});
	};
	var filterByModeDeGestion = function (support) {
		var _v3 = $author$project$Perfimmo$Input$SelectInput$getSelectedValue(supportTab.e3);
		if (_v3.$ === 1) {
			return true;
		} else {
			var selectedModeDeGestion = _v3.a;
			return _Utils_eq(support.h4, selectedModeDeGestion);
		}
	};
	var displayModeForCabinet = function (supports) {
		var _v0 = $author$project$Perfimmo$Input$SelectInput$getSelectedValue(supportTab.lW);
		if (_v0.$ === 1) {
			return supports;
		} else {
			if (!_v0.a.$) {
				var _v1 = _v0.a;
				return supports;
			} else {
				var _v2 = _v0.a;
				var cabId = _v2.a;
				return A2(
					$elm$core$List$filter,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.l4;
						},
						A2($elm$core$Basics$composeR, $elm$core$List$isEmpty, $elm$core$Basics$not)),
					A2(
						$elm$core$List$map,
						function (s) {
							return _Utils_update(
								s,
								{
									l4: A2(
										$elm$core$List$filter,
										A2(
											$elm$core$Basics$composeR,
											function ($) {
												return $.fZ;
											},
											A2(
												$elm$core$Basics$composeR,
												function ($) {
													return $.kx;
												},
												$elm$core$Basics$eq(cabId))),
										s.l4)
								});
						},
						supports));
			}
		}
	};
	return _Utils_update(
		supportTab,
		{
			mr: _Utils_update(
				supportsToFilter,
				{
					mC: displayModeForCabinet(
						A2(
							$elm$core$List$filter,
							filterByModeDeGestion,
							A2($elm$core$List$filter, filterByNameOrSupportId, supportsToFilter.mC))),
					mD: displayModeForCabinet(
						A2(
							$elm$core$List$filter,
							filterByModeDeGestion,
							A2($elm$core$List$filter, filterByNameOrSupportId, supportsToFilter.mD))),
					mE: displayModeForCabinet(
						A2(
							$elm$core$List$filter,
							filterByModeDeGestion,
							A2($elm$core$List$filter, filterByNameOrSupportId, supportsToFilter.mE))),
					mF: displayModeForCabinet(
						A2(
							$elm$core$List$filter,
							filterByModeDeGestion,
							A2($elm$core$List$filter, filterByNameOrSupportId, supportsToFilter.mF)))
				})
		});
};
var $author$project$Components$Produits$API$ToModel$setCommercialisation = F2(
	function (api, form) {
		var supportInvestissement = form.as;
		var fraisDuContrat = form.cn;
		return _Utils_update(
			form,
			{
				cn: _Utils_update(
					fraisDuContrat,
					{
						hf: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.cn.hf, fraisDuContrat.hf),
						hh: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.cn.hh, fraisDuContrat.hh),
						hi: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.cn.hi, fraisDuContrat.hi),
						hj: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.cn.hj, fraisDuContrat.hj)
					}),
				as: _Utils_update(
					supportInvestissement,
					{
						ha: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.as.ha, supportInvestissement.ha),
						he: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.as.he, supportInvestissement.he),
						hg: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.as.hg, supportInvestissement.hg),
						il: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.as.il, supportInvestissement.il),
						j6: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.as.j6, supportInvestissement.j6),
						j9: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.as.j9, supportInvestissement.j9),
						kd: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.as.kd, supportInvestissement.kd),
						ke: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.as.ke, supportInvestissement.ke)
					}),
				at: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setOptionalValue, api.kv, form.at)
			});
	});
var $author$project$Components$Produits$API$ToModel$setInformation = F2(
	function (api, form) {
		var documentsPresentation = form.cf;
		return _Utils_update(
			form,
			{
				k2: api.k2,
				f9: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setOptionalValue, api.f9.kR, form.f9),
				cf: _Utils_update(
					documentsPresentation,
					{
						gG: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.cf.gG, documentsPresentation.gG),
						iW: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.cf.iW, documentsPresentation.iW),
						iX: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.cf.iX, documentsPresentation.iX),
						iY: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.cf.iY, documentsPresentation.iY)
					}),
				g0: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setOptionalValue, api.f9.g0, form.g0),
				m9: api.m9,
				dl: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, api.dl, form.dl),
				iq: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$setOptionalValue, api.iq, form.iq),
				ix: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.f9.ix, form.ix),
				i7: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$setValue, api.i7.dl, form.i7),
				jZ: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setOptionalValue, api.f9.jZ, form.jZ),
				kD: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, api.kD, form.kD)
			});
	});
var $author$project$Components$Produits$API$ToModel$setObjectifs = F2(
	function (form, api) {
		return _Utils_update(
			form,
			{
				fM: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.fM, form.fM),
				gh: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.gh, form.gh),
				gj: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.gj, form.gj),
				gp: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.gp, form.gp),
				nS: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.nR, form.nS),
				jp: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.jp, form.jp),
				jx: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, api.jx, form.jx),
				at: function () {
					var selection = $author$project$Components$Produits$Common$MarcheCibleModel$objectifsSetBuilder.kk(api.o4);
					return $author$project$Perfimmo$Form$FormModel$listSelectFrom(
						{fP: $author$project$Components$Produits$Common$MarcheCibleModel$allObjectifs, jN: selection, dX: $author$project$Components$Produits$Common$MarcheCibleModel$objectifsToString});
				}()
			});
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setOptionalValue = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue;
var $author$project$Components$Produits$API$ToModel$setMarcheCible = F3(
	function (expAPI, marcheCibleAPi, form) {
		var ageLimite = form.fF;
		var typeInvestisseur = form.cH;
		var experience = form.ci;
		var profils = form.a6;
		var horizon = form.bx;
		var capaciteSubirPerte = form.bi;
		var synthese = form.fg;
		return _Utils_update(
			form,
			{
				fv: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.fv, form.fv),
				fF: _Utils_update(
					ageLimite,
					{
						h0: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$setOptionalValue, marcheCibleAPi.kT.h0, ageLimite.h0),
						h3: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$setOptionalValue, marcheCibleAPi.kT.h3, ageLimite.h3)
					}),
				bi: _Utils_update(
					capaciteSubirPerte,
					{
						k5: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.ln.k4, capaciteSubirPerte.k5),
						m7: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.ln.m6, capaciteSubirPerte.m7),
						hW: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.ln.hW, capaciteSubirPerte.hW),
						ir: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.ln.ir, capaciteSubirPerte.ir),
						jP: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.ln.jP, capaciteSubirPerte.jP),
						at: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setOptionalValue, marcheCibleAPi.ln.at, capaciteSubirPerte.at)
					}),
				lJ: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setOptionalValue, marcheCibleAPi.ej, form.lJ),
				ci: _Utils_update(
					experience,
					{
						me: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, expAPI.mf, experience.me),
						m_: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, expAPI.m$, experience.m_),
						nJ: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, expAPI.nK, experience.nJ),
						at: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setOptionalValue, expAPI.at, experience.at)
					}),
				bx: _Utils_update(
					horizon,
					{
						lE: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.bx.lD, horizon.lE),
						nj: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.bx.ni, horizon.nj),
						nw: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.bx.nv, horizon.nw),
						oU: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.bx.oT, horizon.oU),
						at: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setOptionalValue, marcheCibleAPi.bx.at, horizon.at)
					}),
				ap: A2($author$project$Components$Produits$API$ToModel$setObjectifs, form.ap, marcheCibleAPi.ap),
				a6: _Utils_update(
					profils,
					{
						cj: function () {
							var explicationProfils = profils.cj;
							return _Utils_update(
								explicationProfils,
								{
									gI: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.a6.gI, explicationProfils.gI),
									gT: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.a6.gT, explicationProfils.gT),
									gY: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.a6.gY, explicationProfils.gY),
									iv: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.a6.iv, explicationProfils.iv),
									jH: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.a6.jH, explicationProfils.jH)
								});
						}(),
						oa: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setOptionalValue, marcheCibleAPi.a6.at, form.a6.oa)
					}),
				fg: _Utils_update(
					synthese,
					{
						nn: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.fg.nm, synthese.nn),
						no: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.fg.nl, synthese.no)
					}),
				cH: _Utils_update(
					typeInvestisseur,
					{
						lB: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.cH.lA, typeInvestisseur.lB),
						$9: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.cH.$9, typeInvestisseur.$9),
						je: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.je, typeInvestisseur.je),
						jf: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.cH.jf, typeInvestisseur.jf)
					}),
				ku: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setOptionalValue, marcheCibleAPi.ku, form.ku),
				kE: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, marcheCibleAPi.kE, form.kE)
			});
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$getInfos = function (_v0) {
	var infos = _v0.c;
	return infos;
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setOptionalValue = F2(
	function (maybeFloat, form) {
		return A2(
			$elm$core$Maybe$withDefault,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$init(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$getInfos(form)),
			A2(
				$elm$core$Maybe$map,
				function (_float) {
					return A3(
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$testPourcent,
						$elm$core$String$fromFloat(_float),
						form,
						_float);
				},
				maybeFloat));
	});
var $author$project$Perfimmo$Form$FormView$SwitchWithMiddle_IsLeft = 1;
var $author$project$Perfimmo$Form$FormView$SwitchWithMiddle_IsMiddle = 2;
var $author$project$Perfimmo$Form$FormView$SwitchWithMiddle_IsRight = 0;
var $author$project$Perfimmo$Form$FormView$switchWithMiddleFromBool = function (maybeBool) {
	if (!maybeBool.$) {
		if (maybeBool.a) {
			return 0;
		} else {
			return 1;
		}
	} else {
		return 2;
	}
};
var $author$project$Components$Produits$API$ToModel$setInvestissementDurableSfdr = F2(
	function (form, api) {
		var categoriesProduit = form.Z;
		return _Utils_update(
			form,
			{
				Z: _Utils_update(
					categoriesProduit,
					{
						d3: function () {
							var article6Form = form.Z.d3;
							var article6Api = api.lq.d3;
							return _Utils_update(
								article6Form,
								{
									aX: A2(
										$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
										$author$project$Perfimmo$Form$FormView$switchWithMiddleFromBool(article6Api.et),
										article6Form.aX),
									bK: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setOptionalValue, api.lq.d3.bK, form.Z.d3.bK)
								});
						}(),
						b1: function () {
							var article8Form = form.Z.b1;
							var article8Api = api.lq.b1;
							return _Utils_update(
								article8Form,
								{
									aX: A2(
										$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
										$author$project$Perfimmo$Form$FormView$switchWithMiddleFromBool(article8Api.et),
										article8Form.aX),
									dr: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setOptionalValue, article8Api.dr, article8Form.dr),
									ds: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setOptionalValue, article8Api.ds, article8Form.ds),
									dt: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setOptionalValue, article8Api.dt, article8Form.dt)
								});
						}(),
						b2: function () {
							var article9Form = form.Z.b2;
							var article9Api = api.lq.b2;
							return _Utils_update(
								article9Form,
								{
									aX: A2(
										$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
										$author$project$Perfimmo$Form$FormView$switchWithMiddleFromBool(article9Api.et),
										article9Form.aX),
									dr: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setOptionalValue, article9Api.dr, article9Form.dr),
									ds: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setOptionalValue, article9Api.ds, article9Form.ds),
									dt: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setOptionalValue, article9Api.dt, article9Form.dt)
								});
						}()
					}),
				aX: A2(
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
					$author$project$Perfimmo$Form$FormView$switchWithMiddleFromBool(api.aX),
					form.aX)
			});
	});
var $author$project$Components$Produits$API$ToModel$setProfilExtraFinancier = F2(
	function (api, form) {
		var incidenceNegativePai = form.de;
		var taxonomie = form.fi;
		return _Utils_update(
			form,
			{
				ej: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setOptionalValue, api.ej, form.ej),
				de: _Utils_update(
					incidenceNegativePai,
					{
						hZ: function () {
							var selection = $author$project$Common$Referentiel$ProfilExtraFinancier$setBuilder.kk(api.de.hZ);
							return $author$project$Perfimmo$Form$FormModel$listSelectFrom(
								{fP: $author$project$Common$Referentiel$ProfilExtraFinancier$allPAIs, jN: selection, dX: $author$project$Common$Referentiel$ProfilExtraFinancier$paiToString});
						}(),
						bK: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setOptionalValue, api.de.bK, incidenceNegativePai.bK),
						iU: A2(
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
							$author$project$Perfimmo$Form$FormView$switchWithMiddleFromBool(api.de.iU),
							incidenceNegativePai.iU)
					}),
				Q: A2($author$project$Components$Produits$API$ToModel$setInvestissementDurableSfdr, form.Q, api.Q),
				i_: A2(
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
					$author$project$Perfimmo$Form$FormView$switchWithMiddleFromBool(api.i_),
					form.i_),
				fi: _Utils_update(
					taxonomie,
					{
						aX: A2(
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
							$author$project$Perfimmo$Form$FormView$switchWithMiddleFromBool(api.fi.aX),
							taxonomie.aX),
						bK: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setOptionalValue, api.fi.bK, taxonomie.bK)
					})
			});
	});
var $author$project$Components$Produits$API$ToModel$setSupports = F2(
	function (supportsFromApi, tab) {
		var build = F4(
			function (supportId, name, modeDeGestion, modeGestionVal) {
				return A2(
					$elm$core$List$map,
					function (mg) {
						return {
							l3: mg.mR,
							l4: A2(
								$elm$core$List$map,
								function (spe) {
									return {
										fZ: {dl: spe.lk, kx: spe.lj},
										l2: spe.l2
									};
								},
								mg.mS),
							h4: modeGestionVal,
							nz: name,
							oE: supportId
						};
					},
					$elm_community$maybe_extra$Maybe$Extra$toList(modeDeGestion));
			});
		var supports = A3(
			$elm$core$List$foldl,
			F2(
				function (support, acc) {
					return _Utils_update(
						acc,
						{
							mC: _Utils_ap(
								acc.mC,
								A4(build, support.oE, support.nz, support.nu.mC, 3)),
							mD: _Utils_ap(
								acc.mD,
								A4(build, support.oE, support.nz, support.nu.mD, 0)),
							mE: _Utils_ap(
								acc.mE,
								A4(build, support.oE, support.nz, support.nu.mE, 1)),
							mF: _Utils_ap(
								acc.mF,
								A4(build, support.oE, support.nz, support.nu.mF, 2))
						});
				}),
			$author$project$Components$Produits$Details$Model$initSupports,
			supportsFromApi);
		return _Utils_update(
			tab,
			{mr: supports, fe: supports});
	});
var $author$project$Components$Produits$API$ToModel$fromDetailProduitAPI = F2(
	function (api, form) {
		return _Utils_update(
			form,
			{
				k3: api.k2,
				lX: api.lY,
				mZ: A2($author$project$Components$Produits$API$ToModel$setInformation, api, form.mZ),
				c: A3($author$project$Components$Produits$API$ToModel$setMarcheCible, api.ci, api.c, form.c),
				jg: A2($author$project$Components$Produits$API$ToModel$setProfilExtraFinancier, api.jg, form.jg),
				fe: A2($author$project$Components$Produits$API$ToModel$setSupports, api.oH, form.fe),
				M: A2($author$project$Components$Produits$API$ToModel$setCommercialisation, api.lw, form.M)
			});
	});
var $author$project$Components$Produits$Details$Documents$Update$DocumentsFetched = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$API$DocumentAPIView$GetDocumentsResults = F3(
	function (result, allTags, links) {
		return {kX: allTags, nf: links, ok: result};
	});
var $author$project$Components$Produits$API$DocumentAPIView$GetDocumentMetadata = F8(
	function (uid, nom, filename, dateDeProduction, dateDePublication, tags, documentTypes, links) {
		return {lH: dateDeProduction, lI: dateDePublication, l1: documentTypes, mp: filename, nf: links, dl: nom, oK: tags, kx: uid};
	});
var $author$project$Components$Produits$API$DocumentAPIView$Annexe = 0;
var $author$project$Components$Produits$API$DocumentAPIView$documentTypeDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Annexe',
			$elm$json$Json$Decode$succeed(0))
		]));
var $author$project$Components$Produits$API$DocumentAPIView$getDocumentMetadataDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'documentTypes',
		$elm$json$Json$Decode$list($author$project$Components$Produits$API$DocumentAPIView$documentTypeDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'tags',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'dateDePublication',
				$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'dateDeProduction',
					$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'filename',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'nom',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'uid',
								$elm$json$Json$Decode$string,
								$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$DocumentAPIView$GetDocumentMetadata)))))))));
var $author$project$Components$Produits$API$DocumentAPIView$getDocumentResultsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'allTags',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'result',
			$elm$json$Json$Decode$list($author$project$Components$Produits$API$DocumentAPIView$getDocumentMetadataDecoder),
			$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$DocumentAPIView$GetDocumentsResults))));
var $author$project$Components$Produits$API$Requests$fetchDocuments = function (link) {
	return A3(
		$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
		link,
		$elm$http$Http$emptyBody,
		$elm$http$Http$expectJson($elm$core$Basics$identity)($author$project$Components$Produits$API$DocumentAPIView$getDocumentResultsDecoder));
};
var $author$project$Components$Produits$Details$Documents$Model$ListMode = {$: 0};
var $author$project$Components$Produits$Details$Documents$Model$initFiltres = function (tags) {
	return {
		cg: false,
		bG: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$init, $elm$core$Maybe$Nothing, _List_Nil),
		bH: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$init, $elm$core$Maybe$Nothing, _List_Nil),
		bI: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$init, $elm$core$Maybe$Nothing, _List_Nil),
		bJ: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$init, $elm$core$Maybe$Nothing, _List_Nil),
		dp: '',
		cu: A3($inkuzmin$elm_multiselect$Multiselect$initModel, tags, 'filtreParTags', 0)
	};
};
var $author$project$Components$Produits$Details$Documents$Model$init = F2(
	function (timezone, fetchDocumentLink) {
		return {
			lQ: $elm$core$Maybe$Nothing,
			ce: _List_Nil,
			bn: _List_Nil,
			ep: $author$project$Components$Drawer$Model$init,
			_: $author$project$Components$Produits$Details$Documents$Model$ListMode,
			g2: fetchDocumentLink,
			eB: _List_Nil,
			aY: $author$project$Components$Produits$Details$Documents$Model$initFiltres(_List_Nil),
			aO: timezone,
			fq: $elm$core$Maybe$Nothing
		};
	});
var $author$project$Components$Produits$Details$Documents$Update$init = F2(
	function (timezone, getDocumentsLink) {
		var effect = A2(
			$elm$core$Platform$Cmd$map,
			$author$project$Components$Produits$Details$Documents$Update$DocumentsFetched,
			$author$project$Components$Produits$API$Requests$fetchDocuments(getDocumentsLink));
		return _Utils_Tuple2(
			A2($author$project$Components$Produits$Details$Documents$Model$init, timezone, getDocumentsLink),
			effect);
	});
var $author$project$Perfimmo$Input$SelectInput$populateItems = F2(
	function (items, model) {
		return _Utils_update(
			model,
			{aE: items});
	});
var $author$project$Components$Produits$Details$Model$selectTab = F2(
	function (tabId, model) {
		return _Utils_update(
			model,
			{e6: tabId});
	});
var $author$project$Components$Produits$Details$Model$Disabled = 0;
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$addInfo = F2(
	function (info, _v0) {
		var val = _v0.a;
		var values = _v0.b;
		var infos = _v0.c;
		var builder = _v0.d;
		var toString = _v0.e;
		return A5(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$FormSelect,
			val,
			values,
			A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$addFormFieldInfo, infos, info),
			builder,
			toString);
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$addInfo = F2(
	function (info, _v0) {
		var select = _v0;
		return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$addInfo, info, select);
	});
var $author$project$Components$Produits$Details$Model$formCommaratorToS = function (comp) {
	return 'disabled';
};
var $author$project$Components$Produits$Details$Model$setProduit = F4(
	function (produitId, produit, createOrUpdateLink, model) {
		var infos = produit.mZ;
		var manageDuplicatedProduit = function (p) {
			var _v0 = p.k3;
			if (!_v0.$) {
				if (!_v0.a.$) {
					return _Utils_update(
						p,
						{
							mZ: _Utils_update(
								infos,
								{
									i7: A2(
										$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$addInfo,
										A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$CustomDecorator, 0, $author$project$Components$Produits$Details$Model$formCommaratorToS),
										infos.i7)
								})
						});
				} else {
					return p;
				}
			} else {
				return p;
			}
		};
		return _Utils_update(
			model,
			{
				eg: createOrUpdateLink,
				aG: false,
				i8: manageDuplicatedProduit(produit),
				e0: $elm$core$Maybe$Just(produitId)
			});
	});
var $author$project$Components$Produits$Details$Model$MissingNom = 0;
var $author$project$Components$Produits$Details$Model$MissingProducteur = 1;
var $elm_community$dict_extra$Dict$Extra$filterMap = F2(
	function (f, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, acc) {
					var _v0 = A2(f, k, v);
					if (!_v0.$) {
						var newVal = _v0.a;
						return A3($elm$core$Dict$insert, k, newVal, acc);
					} else {
						return acc;
					}
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $elm_community$dict_extra$Dict$Extra$find = F2(
	function (predicate, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, acc) {
					if (!acc.$) {
						return acc;
					} else {
						return A2(predicate, k, v) ? $elm$core$Maybe$Just(
							_Utils_Tuple2(k, v)) : $elm$core$Maybe$Nothing;
					}
				}),
			$elm$core$Maybe$Nothing,
			dict);
	});
var $author$project$Components$Produits$API$FromModel$swicthWithMiddleToBool = function (_switch) {
	if (!_switch.$) {
		switch (_switch.a) {
			case 0:
				var _v1 = _switch.a;
				return $elm$core$Maybe$Just(true);
			case 1:
				var _v2 = _switch.a;
				return $elm$core$Maybe$Just(false);
			default:
				var _v3 = _switch.a;
				return $elm$core$Maybe$Nothing;
		}
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Produits$API$FromModel$toDetailProduitAPI = F2(
	function (producteurs, form) {
		var valideParProducteur = A2(
			$elm$core$Maybe$withDefault,
			false,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.mZ.kD));
		var profilExtraFinancier = {
			ej: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.jg.ej),
			de: {
				hZ: A2(
					$elm$core$Maybe$withDefault,
					$author$project$Common$Referentiel$ProfilExtraFinancier$setBuilder.gV,
					A2(
						$elm$core$Maybe$map,
						$author$project$Common$Referentiel$ProfilExtraFinancier$setBuilder.hk,
						A2(
							$elm$core$Maybe$map,
							$elm$core$Dict$values,
							A2(
								$elm$core$Maybe$map,
								$elm_community$dict_extra$Dict$Extra$filterMap(
									F2(
										function (_v8, v) {
											var k = _v8.a;
											return v ? $author$project$Common$Referentiel$ProfilExtraFinancier$parsePai(k) : $elm$core$Maybe$Nothing;
										})),
								$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.jg.de.hZ))))),
				bK: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$toFloat(form.jg.de.bK),
				iU: $author$project$Components$Produits$API$FromModel$swicthWithMiddleToBool(
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.jg.de.iU))
			},
			Q: {
				lq: {
					d3: {
						et: $author$project$Components$Produits$API$FromModel$swicthWithMiddleToBool(
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.jg.Q.Z.d3.aX)),
						bK: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$toFloat(form.jg.Q.Z.d3.bK)
					},
					b1: {
						et: $author$project$Components$Produits$API$FromModel$swicthWithMiddleToBool(
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.jg.Q.Z.b1.aX)),
						dr: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$toFloat(form.jg.Q.Z.b1.dr),
						ds: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$toFloat(form.jg.Q.Z.b1.ds),
						dt: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$toFloat(form.jg.Q.Z.b1.dt)
					},
					b2: {
						et: $author$project$Components$Produits$API$FromModel$swicthWithMiddleToBool(
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.jg.Q.Z.b2.aX)),
						dr: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$toFloat(form.jg.Q.Z.b2.dr),
						ds: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$toFloat(form.jg.Q.Z.b2.ds),
						dt: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$toFloat(form.jg.Q.Z.b2.dt)
					}
				},
				aX: $author$project$Components$Produits$API$FromModel$swicthWithMiddleToBool(
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.jg.Q.aX))
			},
			i_: $author$project$Components$Produits$API$FromModel$swicthWithMiddleToBool(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.jg.i_)),
			fi: {
				aX: $author$project$Components$Produits$API$FromModel$swicthWithMiddleToBool(
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.jg.fi.aX)),
				bK: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$toFloat(form.jg.fi.bK)
			}
		};
		var producteur = A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Result$Err(1),
			A2(
				$elm$core$Maybe$map,
				$elm$core$Result$Ok,
				A2(
					$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
					function (result) {
						if (!result.$) {
							var chosenProducteur = result.a;
							return A2(
								$elm$core$Maybe$map,
								$elm$core$Tuple$second,
								A2(
									$elm_community$dict_extra$Dict$Extra$find,
									F2(
										function (_v7, p) {
											return _Utils_eq(p.dl, chosenProducteur);
										}),
									producteurs));
						} else {
							return $elm$core$Maybe$Nothing;
						}
					},
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$getValue(form.mZ.i7))));
		var noteRisqueProduit = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$toInt(form.mZ.iq);
		var nom = A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Result$Err(0),
			A2(
				$elm$core$Maybe$map,
				function (n) {
					return $elm$core$String$isEmpty(
						$elm$core$String$trim(n)) ? $elm$core$Result$Err(0) : $elm$core$Result$Ok(n);
				},
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.mZ.dl)));
		var marcheCible = {
			fv: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.fv),
			kT: function () {
				var min = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$toInt(form.c.fF.h3);
				var max = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$toInt(form.c.fF.h0);
				return {h0: max, h3: min};
			}(),
			ln: {
				k4: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.bi.k5),
				m6: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.bi.m7),
				hW: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.bi.hW),
				ir: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.bi.ir),
				jP: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.bi.jP),
				at: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.c.bi.at)
			},
			ej: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.lJ),
			bx: {
				lD: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.bx.lE),
				ni: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.bx.nj),
				nv: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.bx.nw),
				oT: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.bx.oU),
				at: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.c.bx.at)
			},
			ap: {
				fM: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.ap.fM),
				gh: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.ap.gh),
				gj: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.ap.gj),
				gp: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.ap.gp),
				nR: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.ap.nS),
				jp: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.ap.jp),
				jx: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.ap.jx),
				o4: A2(
					$elm$core$Maybe$withDefault,
					$author$project$Components$Produits$Common$MarcheCibleModel$objectifsSetBuilder.gV,
					A2(
						$elm$core$Maybe$map,
						$author$project$Components$Produits$Common$MarcheCibleModel$objectifsSetBuilder.hk,
						A2(
							$elm$core$Maybe$map,
							$elm$core$Dict$values,
							A2(
								$elm$core$Maybe$map,
								$elm_community$dict_extra$Dict$Extra$filterMap(
									F2(
										function (_v5, v) {
											var k = _v5.a;
											return v ? $author$project$Components$Produits$Common$MarcheCibleModel$parseObjectifs(k) : $elm$core$Maybe$Nothing;
										})),
								$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.ap.at)))))
			},
			je: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.cH.je),
			a6: {
				gI: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.a6.cj.gI),
				gT: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.a6.cj.gT),
				gY: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.a6.cj.gY),
				iv: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.a6.cj.iv),
				jH: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.a6.cj.jH),
				at: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.c.a6.oa)
			},
			fg: {
				nl: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.fg.no),
				nm: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.fg.nn)
			},
			cH: {
				lA: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.cH.lB),
				$9: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.cH.$9),
				jf: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.cH.jf)
			},
			ku: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.c.ku),
			kE: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.kE)
		};
		var experience = {
			mf: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.ci.me),
			m$: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.ci.m_),
			nK: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c.ci.nJ),
			at: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.c.ci.at)
		};
		var documentsPresentation = {
			gG: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.mZ.cf.gG),
			iW: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.mZ.cf.iW),
			iX: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.mZ.cf.iX),
			iY: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.mZ.cf.iY)
		};
		var disponiblePourLaGeneration = form.lX;
		var commercialisation = {
			cn: {
				hf: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.M.cn.hf),
				hh: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.M.cn.hh),
				hi: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.M.cn.hi),
				hj: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.M.cn.hj)
			},
			as: {
				ha: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.M.as.ha),
				he: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.M.as.he),
				hg: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.M.as.hg),
				il: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.M.as.il),
				j6: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.M.as.j6),
				j9: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.M.as.j9),
				kd: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.M.as.kd),
				ke: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.M.as.ke)
			},
			kv: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.M.at)
		};
		var classification = {
			kR: function () {
				var _v0 = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.mZ.f9);
				if (!_v0.$) {
					switch (_v0.a) {
						case 0:
							var _v1 = _v0.a;
							return $elm$core$Maybe$Just(0);
						case 1:
							var _v2 = _v0.a;
							return $elm$core$Maybe$Just(1);
						case 2:
							var _v3 = _v0.a;
							return $elm$core$Maybe$Just(2);
						default:
							var _v4 = _v0.a;
							return $elm$core$Maybe$Just(3);
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}(),
			g0: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.mZ.g0),
			ix: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.mZ.ix),
			jZ: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.mZ.jZ)
		};
		return A3(
			$author$project$Components$Clients$ResultUtils$map2C,
			F2(
				function (nomResult, producteurResult) {
					return {f9: classification, lw: commercialisation, lY: disponiblePourLaGeneration, cf: documentsPresentation, ci: experience, c: marcheCible, dl: nomResult, iq: noteRisqueProduit, i7: producteurResult.kx, jg: profilExtraFinancier, kD: valideParProducteur};
				}),
			nom,
			producteur);
	});
var $author$project$Components$Produits$Details$Documents$Update$DeleteAction = function (a) {
	return {$: 15, a: a};
};
var $author$project$Components$Produits$Details$Documents$Update$DocumentMetadataUpdated = function (a) {
	return {$: 17, a: a};
};
var $author$project$Components$Produits$Details$Documents$Update$DrawerEvents = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Produits$Details$Documents$Update$FileSelected = function (a) {
	return {$: 11, a: a};
};
var $author$project$Components$Produits$Details$Documents$Update$Uploaded = function (a) {
	return {$: 13, a: a};
};
var $author$project$Components$Produits$Details$Documents$Update$acceptOnlyPdf = _List_fromArray(
	['application/pdf']);
var $author$project$Components$Produits$Details$Documents$Model$asDrawerModelIn = F2(
	function (model, drawer) {
		return _Utils_update(
			model,
			{ep: drawer});
	});
var $elm$file$File$Select$file = F2(
	function (mimes, toMsg) {
		return A2(
			$elm$core$Task$perform,
			toMsg,
			_File_uploadOne(mimes));
	});
var $author$project$Components$Produits$API$ToModel$fromDocumentMetadataAPI = function (apiDoc) {
	return {
		lH: apiDoc.lH,
		lI: apiDoc.lI,
		lP: A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'DeleteDocument', apiDoc.nf),
		l6: A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'DownloadDocument', apiDoc.nf),
		mp: apiDoc.mp,
		m3: A2(
			$elm$core$List$any,
			$elm$core$Basics$eq(0),
			apiDoc.l1),
		m9: apiDoc.dl,
		oK: apiDoc.oK,
		kx: apiDoc.kx,
		fp: A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'UpdateDocument', apiDoc.nf)
	};
};
var $PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$NonEmptyString = $elm$core$Basics$identity;
var $PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$fromString = function (input) {
	return A2(
		$elm$core$Maybe$map,
		$elm$core$Basics$identity,
		$PerformanceIMMO$elm_utils$Perfimmo$Primitive$ExtendString$stringToNonEmptyString(input));
};
var $author$project$Components$Produits$Details$Documents$Update$getFormTags = function (model) {
	return A2(
		$elm$core$List$filterMap,
		$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$fromString,
		A2(
			$elm$core$List$map,
			$elm$core$Tuple$second,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$elm$core$Maybe$map,
					$inkuzmin$elm_multiselect$Multiselect$getSelectedValues,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.oK;
						},
						$author$project$Components$Produits$Details$Documents$Model$getEditModel(model))))));
};
var $author$project$Components$Produits$Details$Documents$Update$getFormTypes = function (model) {
	return A2(
		$elm$core$List$filterMap,
		function (_v0) {
			var keyVal = _v0.a;
			var enabled = _v0.b;
			return enabled ? $elm$core$Maybe$Just(keyVal.b) : $elm$core$Maybe$Nothing;
		},
		A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Dict$toList,
				A2(
					$elm$core$Maybe$andThen,
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.kw;
						},
						$author$project$Components$Produits$Details$Documents$Model$getEditModel(model))))));
};
var $elm$file$File$name = _File_name;
var $author$project$Components$Produits$Details$Documents$Model$CreateMode = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$Documents$Model$Create = 0;
var $author$project$Components$Produits$Details$Documents$Model$initCreateMode = F2(
	function (tags, forbiddenTitles) {
		return {
			ek: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$addInfo, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty),
			lK: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
			l9: $elm$core$Maybe$Nothing,
			ew: $elm$core$Maybe$Nothing,
			mu: forbiddenTitles,
			aG: false,
			dj: 0,
			dl: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$addInfo, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty),
			oK: A3($inkuzmin$elm_multiselect$Multiselect$initModel, tags, 'documentTags', 0),
			kw: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
				$elm$core$Dict$fromList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							_Utils_Tuple2('Annexe', 'Annexe'),
							false)
						])),
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty)
		};
	});
var $author$project$Components$Produits$Details$Documents$Model$setDrawerModel = F2(
	function (drawer, model) {
		return _Utils_update(
			model,
			{ep: drawer});
	});
var $author$project$Components$Produits$Details$Documents$Model$openEditDrawer = $author$project$Components$Produits$Details$Documents$Model$setDrawerModel($author$project$Components$Drawer$Model$open);
var $author$project$Components$Produits$Details$Documents$Model$setEditModel = F2(
	function (editMode, model) {
		return _Utils_update(
			model,
			{_: editMode});
	});
var $author$project$Components$Produits$Details$Documents$Model$openCreateDrawer = function (model) {
	var forbiddenTitles = A2(
		$elm$core$List$map,
		function ($) {
			return $.m9;
		},
		model.bn);
	return $author$project$Components$Produits$Details$Documents$Model$openEditDrawer(
		A2(
			$author$project$Components$Produits$Details$Documents$Model$setEditModel,
			$author$project$Components$Produits$Details$Documents$Model$CreateMode(
				A2($author$project$Components$Produits$Details$Documents$Model$initCreateMode, model.ce, forbiddenTitles)),
			model));
};
var $author$project$Components$Produits$Details$Documents$Model$Edit = 1;
var $author$project$Components$Produits$Details$Documents$Model$UpdateMode = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.fb, posixMinutes) < 0) {
					return posixMinutes + era.d;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		gC: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		ia: month,
		kO: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).gC;
	});
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).ia;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).kO;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $author$project$Components$Produits$Details$Documents$Model$openUpdateDrawer = F2(
	function (doc, model) {
		var selectedTags = A2(
			$elm$core$List$map,
			function (t) {
				return _Utils_Tuple2(t, t);
			},
			doc.oK);
		var forbiddenTitles = A2(
			$elm$core$List$filter,
			function (title) {
				return !_Utils_eq(title, doc.m9);
			},
			A2(
				$elm$core$List$map,
				function ($) {
					return $.m9;
				},
				model.bn));
		var createMode = A2($author$project$Components$Produits$Details$Documents$Model$initCreateMode, model.ce, forbiddenTitles);
		var unselectedTags = A2(
			$elm$core$List$filter,
			function (tag) {
				return !A2($elm$core$List$member, tag, selectedTags);
			},
			$inkuzmin$elm_multiselect$Multiselect$getValues(createMode.oK));
		var allTags = $elm$core$List$concat(
			_List_fromArray(
				[selectedTags, unselectedTags]));
		var updateMode = _Utils_update(
			createMode,
			{
				ek: A2(
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
					A2($justinmimbs$date$Date$fromPosix, model.aO, doc.lH),
					createMode.ek),
				lK: A2(
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
					A2($justinmimbs$date$Date$fromPosix, model.aO, doc.lI),
					createMode.lK),
				dj: 1,
				dl: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, doc.m9, createMode.dl),
				oK: A3($inkuzmin$elm_multiselect$Multiselect$populateValues, createMode.oK, allTags, selectedTags),
				kw: A2(
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
					$elm$core$Dict$fromList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								_Utils_Tuple2('Annexe', 'Annexe'),
								doc.m3)
							])),
					createMode.kw)
			});
		var editModel = $author$project$Components$Produits$Details$Documents$Model$openEditDrawer(
			A2(
				$author$project$Components$Produits$Details$Documents$Model$setEditModel,
				A2($author$project$Components$Produits$Details$Documents$Model$UpdateMode, doc, updateMode),
				model));
		return editModel;
	});
var $author$project$Components$Produits$Details$Documents$Model$setDocumentEditModel = F2(
	function (updatedEditModel, model) {
		var _v0 = model._;
		switch (_v0.$) {
			case 0:
				return model;
			case 1:
				return _Utils_update(
					model,
					{
						_: $author$project$Components$Produits$Details$Documents$Model$CreateMode(updatedEditModel)
					});
			default:
				var document = _v0.a;
				return _Utils_update(
					model,
					{
						_: A2($author$project$Components$Produits$Details$Documents$Model$UpdateMode, document, updatedEditModel)
					});
		}
	});
var $justinmimbs$date$Date$compare = F2(
	function (_v0, _v1) {
		var a = _v0;
		var b = _v1;
		return A2($elm$core$Basics$compare, a, b);
	});
var $author$project$Components$Produits$Details$Documents$Model$dateCompare = F3(
	function (date, timezone, otherDate) {
		return A2(
			$elm$core$Maybe$map,
			function (d) {
				return A2(
					$justinmimbs$date$Date$compare,
					A2($justinmimbs$date$Date$fromPosix, timezone, date),
					d);
			},
			otherDate);
	});
var $author$project$Components$Produits$Details$Documents$Model$appliquerLesFiltres = function (model) {
	var filtres = model.aY;
	var filtreParTags = $inkuzmin$elm_multiselect$Multiselect$getSelectedValues(filtres.cu);
	var filtreParNom = filtres.dp;
	var dateDePublicationMustBeBefore = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(filtres.bJ);
	var dateDePublicationMustBeAfter = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(filtres.bI);
	var dateDeProductionMustBeBefore = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(filtres.bH);
	var dateDeProductionMustBeAfter = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(filtres.bG);
	var filteredDocuments = A2(
		$elm$core$List$filter,
		function (doc) {
			var _v13 = A3($author$project$Components$Produits$Details$Documents$Model$dateCompare, doc.lI, model.aO, dateDePublicationMustBeBefore);
			if (!_v13.$) {
				switch (_v13.a) {
					case 0:
						var _v14 = _v13.a;
						return true;
					case 1:
						var _v15 = _v13.a;
						return true;
					default:
						var _v16 = _v13.a;
						return false;
				}
			} else {
				return true;
			}
		},
		A2(
			$elm$core$List$filter,
			function (doc) {
				var _v9 = A3($author$project$Components$Produits$Details$Documents$Model$dateCompare, doc.lI, model.aO, dateDePublicationMustBeAfter);
				if (!_v9.$) {
					switch (_v9.a) {
						case 0:
							var _v10 = _v9.a;
							return false;
						case 1:
							var _v11 = _v9.a;
							return true;
						default:
							var _v12 = _v9.a;
							return true;
					}
				} else {
					return true;
				}
			},
			A2(
				$elm$core$List$filter,
				function (doc) {
					var _v5 = A3($author$project$Components$Produits$Details$Documents$Model$dateCompare, doc.lH, model.aO, dateDeProductionMustBeBefore);
					if (!_v5.$) {
						switch (_v5.a) {
							case 0:
								var _v6 = _v5.a;
								return true;
							case 1:
								var _v7 = _v5.a;
								return true;
							default:
								var _v8 = _v5.a;
								return false;
						}
					} else {
						return true;
					}
				},
				A2(
					$elm$core$List$filter,
					function (doc) {
						var _v1 = A3($author$project$Components$Produits$Details$Documents$Model$dateCompare, doc.lH, model.aO, dateDeProductionMustBeAfter);
						if (!_v1.$) {
							switch (_v1.a) {
								case 0:
									var _v2 = _v1.a;
									return false;
								case 1:
									var _v3 = _v1.a;
									return true;
								default:
									var _v4 = _v1.a;
									return true;
							}
						} else {
							return true;
						}
					},
					A2(
						$elm$core$List$filter,
						function (doc) {
							return model.aY.cg ? doc.m3 : true;
						},
						A2(
							$elm$core$List$filter,
							function (doc) {
								return $elm$core$List$isEmpty(filtreParTags) ? true : A2(
									$elm$core$List$any,
									function (_v0) {
										var tag = _v0.a;
										return A2(
											$elm$core$List$any,
											function (docTag) {
												return _Utils_eq(docTag, tag);
											},
											doc.oK);
									},
									filtreParTags);
							},
							A2(
								$elm$core$List$filter,
								function (doc) {
									return A2(
										$elm$core$String$contains,
										$elm$core$String$toLower(filtreParNom),
										$elm$core$String$toLower(doc.m9));
								},
								model.bn)))))));
	return _Utils_update(
		model,
		{eB: filteredDocuments});
};
var $author$project$Components$Produits$Details$Documents$Model$setDocuments = F3(
	function (documents, documentTags, model) {
		var tags = A2(
			$elm$core$List$map,
			function (t) {
				return _Utils_Tuple2(t, t);
			},
			documentTags);
		var tagsInList = A2(
			$elm$core$List$filter,
			function (t) {
				return A2(
					$elm$core$List$member,
					t.a,
					A2(
						$ccapndave$elm_flat_map$List$FlatMap$flatMap,
						function ($) {
							return $.oK;
						},
						documents));
			},
			tags);
		return $author$project$Components$Produits$Details$Documents$Model$appliquerLesFiltres(
			_Utils_update(
				model,
				{
					ce: tags,
					bn: A2(
						$elm$core$List$sortWith,
						F2(
							function (d1, d2) {
								return (_Utils_cmp(
									$elm$core$String$toLower(d1.m9),
									$elm$core$String$toLower(d2.m9)) > 0) ? 2 : ((_Utils_cmp(
									$elm$core$String$toLower(d1.m9),
									$elm$core$String$toLower(d2.m9)) < 0) ? 0 : 1);
							}),
						documents),
					aY: $author$project$Components$Produits$Details$Documents$Model$initFiltres(tagsInList)
				}));
	});
var $author$project$Components$Produits$Details$Documents$Model$setErrorMessage = F2(
	function (message, model) {
		return _Utils_update(
			model,
			{l9: message});
	});
var $author$project$Components$Produits$Details$Documents$Model$updateEditModel = F2(
	function (update, model) {
		var _v0 = model._;
		switch (_v0.$) {
			case 0:
				return model;
			case 1:
				var documentEditModel = _v0.a;
				return _Utils_update(
					model,
					{
						_: $author$project$Components$Produits$Details$Documents$Model$CreateMode(
							update(documentEditModel))
					});
			default:
				var document = _v0.a;
				var documentEditModel = _v0.b;
				return _Utils_update(
					model,
					{
						_: A2(
							$author$project$Components$Produits$Details$Documents$Model$UpdateMode,
							document,
							update(documentEditModel))
					});
		}
	});
var $author$project$Components$Produits$Details$Documents$Model$setFile = function (file) {
	return $author$project$Components$Produits$Details$Documents$Model$updateEditModel(
		function (model) {
			return _Utils_update(
				model,
				{
					ew: $elm$core$Maybe$Just(file)
				});
		});
};
var $author$project$Components$Produits$Details$Documents$Model$setFiltre = F2(
	function (filtre, model) {
		var filtres = model.aY;
		var _v0 = function () {
			switch (filtre.$) {
				case 0:
					var search = filtre.a;
					return _Utils_Tuple2(
						_Utils_update(
							filtres,
							{dp: search}),
						$elm$core$Platform$Cmd$none);
				case 1:
					var msg = filtre.a;
					var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, msg, filtres.cu);
					var m = _v2.a;
					var c = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							filtres,
							{cu: m}),
						A2($elm$core$Platform$Cmd$map, $author$project$Components$Produits$Details$Documents$Model$FiltrerParTags, c));
				case 2:
					return _Utils_Tuple2(
						_Utils_update(
							filtres,
							{cg: !filtres.cg}),
						$elm$core$Platform$Cmd$none);
				case 3:
					var date = filtre.a;
					var updatedFiltres = A2(
						$elm$core$Result$withDefault,
						_Utils_update(
							filtres,
							{bG: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty}),
						A2(
							$elm$core$Result$map,
							function (d) {
								return _Utils_update(
									filtres,
									{
										bG: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, d, filtres.bG)
									});
							},
							$justinmimbs$date$Date$fromIsoString(date)));
					return _Utils_Tuple2(updatedFiltres, $elm$core$Platform$Cmd$none);
				case 4:
					var date = filtre.a;
					var updatedFiltres = A2(
						$elm$core$Result$withDefault,
						_Utils_update(
							filtres,
							{bH: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty}),
						A2(
							$elm$core$Result$map,
							function (d) {
								return _Utils_update(
									filtres,
									{
										bH: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, d, filtres.bH)
									});
							},
							$justinmimbs$date$Date$fromIsoString(date)));
					return _Utils_Tuple2(updatedFiltres, $elm$core$Platform$Cmd$none);
				case 5:
					var date = filtre.a;
					var updatedFiltres = A2(
						$elm$core$Result$withDefault,
						_Utils_update(
							filtres,
							{bI: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty}),
						A2(
							$elm$core$Result$map,
							function (d) {
								return _Utils_update(
									filtres,
									{
										bI: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, d, filtres.bI)
									});
							},
							$justinmimbs$date$Date$fromIsoString(date)));
					return _Utils_Tuple2(updatedFiltres, $elm$core$Platform$Cmd$none);
				default:
					var date = filtre.a;
					var updatedFiltres = A2(
						$elm$core$Result$withDefault,
						_Utils_update(
							filtres,
							{bJ: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty}),
						A2(
							$elm$core$Result$map,
							function (d) {
								return _Utils_update(
									filtres,
									{
										bJ: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, d, filtres.bJ)
									});
							},
							$justinmimbs$date$Date$fromIsoString(date)));
					return _Utils_Tuple2(updatedFiltres, $elm$core$Platform$Cmd$none);
			}
		}();
		var newFiltres = _v0.a;
		var effect = _v0.b;
		var updatedModel = $author$project$Components$Produits$Details$Documents$Model$appliquerLesFiltres(
			_Utils_update(
				model,
				{aY: newFiltres}));
		return _Utils_Tuple2(updatedModel, effect);
	});
var $author$project$Components$Produits$Details$Documents$Model$setLoading = F2(
	function (isUploading, model) {
		return _Utils_update(
			model,
			{aG: isUploading});
	});
var $author$project$Components$Produits$Details$Documents$Model$setUploadLink = F2(
	function (link, model) {
		return _Utils_update(
			model,
			{fq: link});
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$toString = function (_v0) {
	var input = _v0;
	return input;
};
var $author$project$Components$Produits$API$UpdateDocument$encodeDocType = function (docType) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				docType,
				$elm$json$Json$Encode$object(_List_Nil))
			]));
};
var $author$project$Components$Produits$API$UpdateDocument$encodeUpdateDocumentData = function (updateData) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'nom',
				$elm$json$Json$Encode$string(
					$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$toString(updateData.dl))),
				_Utils_Tuple2(
				'dateDeProduction',
				$elm$json$Json$Encode$string(
					$justinmimbs$date$Date$toIsoString(updateData.lH))),
				_Utils_Tuple2(
				'tags',
				A2(
					$elm$json$Json$Encode$list,
					$elm$json$Json$Encode$string,
					A2($elm$core$List$map, $PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$toString, updateData.oK))),
				_Utils_Tuple2(
				'documentTypes',
				A2($elm$json$Json$Encode$list, $author$project$Components$Produits$API$UpdateDocument$encodeDocType, updateData.kw))
			]));
};
var $author$project$Components$Produits$API$Requests$updateDocument = F2(
	function (link, params) {
		var body = $elm$http$Http$jsonBody(
			$author$project$Components$Produits$API$UpdateDocument$encodeUpdateDocumentData(params));
		return A3(
			$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
			link,
			body,
			$elm$http$Http$expectJson($elm$core$Basics$identity)($author$project$Perfimmo$Api$CRUDResultWithFeedback$crudResultWithFeedbackJsonDecoder));
	});
var $elm$http$Http$filePart = _Http_pair;
var $author$project$Components$Produits$API$Errors$UploadDocumentError$ProduitNotExist = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$API$Errors$UploadDocumentError$fileUploadErrorsDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'ProduitNotExist',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'produitUid',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Components$Produits$API$Errors$UploadDocumentError$ProduitNotExist)))
		]));
var $elm$http$Http$multipartBody = function (parts) {
	return A2(
		_Http_pair,
		'',
		_Http_toFormData(parts));
};
var $elm$http$Http$stringPart = _Http_pair;
var $author$project$Components$Produits$API$Requests$uploadFile = F2(
	function (params, msg) {
		var body = $elm$http$Http$multipartBody(
			_Utils_ap(
				_List_fromArray(
					[
						A2($elm$http$Http$filePart, 'file', params.ew),
						A2(
						$elm$http$Http$stringPart,
						'nom',
						$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$toString(params.dl)),
						A2(
						$elm$http$Http$stringPart,
						'filename',
						$elm$file$File$name(params.ew)),
						A2(
						$elm$http$Http$stringPart,
						'dateDeProduction',
						$justinmimbs$date$Date$toIsoString(params.lH))
					]),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$elm$http$Http$stringPart('documentTypes'),
						params.kw),
					A2(
						$elm$core$List$map,
						$elm$http$Http$stringPart('tags'),
						A2($elm$core$List$map, $PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$toString, params.oK)))));
		return A3(
			$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
			params.hX,
			body,
			A2($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$expectWhateverOkResult, msg, $author$project$Components$Produits$API$Errors$UploadDocumentError$fileUploadErrorsDecoder));
	});
var $author$project$Components$Produits$Details$Documents$Update$update = F2(
	function (model, msg) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var result = msg.a.a;
					var maybeUploadLink = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'UploadDocument', result.nf);
					return _Utils_Tuple2(
						A3(
							$author$project$Components$Produits$Details$Documents$Model$setDocuments,
							A2($elm$core$List$map, $author$project$Components$Produits$API$ToModel$fromDocumentMetadataAPI, result.ok),
							result.kX,
							A2($author$project$Components$Produits$Details$Documents$Model$setUploadLink, maybeUploadLink, model)),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var filtre = msg.a;
				return A2(
					$elm$core$Tuple$mapSecond,
					$elm$core$Platform$Cmd$map($author$project$Components$Produits$Details$Documents$Update$Filtrer),
					A2($author$project$Components$Produits$Details$Documents$Model$setFiltre, filtre, model));
			case 2:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					$author$project$Components$Produits$Details$Documents$Model$openCreateDrawer(model),
					$elm$core$Platform$Cmd$none);
			case 4:
				var doc = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Components$Produits$Details$Documents$Model$openUpdateDrawer, doc, model),
					$elm$core$Platform$Cmd$none);
			case 5:
				var drawerMsg = msg.a;
				var _v1 = A3(
					$elm$core$Tuple$mapBoth,
					$author$project$Components$Produits$Details$Documents$Model$asDrawerModelIn(model),
					$elm$core$Platform$Cmd$map($author$project$Components$Produits$Details$Documents$Update$DrawerEvents),
					A2($author$project$Components$Drawer$Update$update, model.ep, drawerMsg));
				var updatedModel = _v1.a;
				var cmd = _v1.b;
				if (!drawerMsg.$) {
					return _Utils_Tuple2(updatedModel, cmd);
				} else {
					var wrapperMsg = drawerMsg.a;
					return A2(
						$elm$core$Tuple$mapSecond,
						function (cmd1) {
							return $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[cmd1, cmd]));
						},
						A2($author$project$Components$Produits$Details$Documents$Update$update, updatedModel, wrapperMsg));
				}
			case 6:
				var value = msg.a;
				return ($elm$core$String$trim(value) === '') ? _Utils_Tuple2(
					A2(
						$author$project$Components$Produits$Details$Documents$Model$updateEditModel,
						function (editModel) {
							return _Utils_update(
								editModel,
								{
									dl: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, $elm$core$Maybe$Nothing, editModel.dl)
								});
						},
						model),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					A2(
						$author$project$Components$Produits$Details$Documents$Model$updateEditModel,
						function (editModel) {
							return _Utils_update(
								editModel,
								{
									dl: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, editModel.dl)
								});
						},
						model),
					$elm$core$Platform$Cmd$none);
			case 7:
				var value = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$Components$Produits$Details$Documents$Model$updateEditModel,
						function (editModel) {
							return _Utils_update(
								editModel,
								{
									ek: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, value, editModel.ek)
								});
						},
						model),
					$elm$core$Platform$Cmd$none);
			case 8:
				var multiselectMsg = msg.a;
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Maybe$map,
						function (editModel) {
							var _v3 = A2($inkuzmin$elm_multiselect$Multiselect$update, multiselectMsg, editModel.oK);
							var editedTags = _v3.a;
							var multiSelectCmd = _v3.b;
							var editedDocumentModel = _Utils_update(
								editModel,
								{oK: editedTags});
							var editedModel = A2($author$project$Components$Produits$Details$Documents$Model$setDocumentEditModel, editedDocumentModel, model);
							var multiSelectAction = A2($elm$core$Platform$Cmd$map, $author$project$Components$Produits$Details$Documents$Update$SetTags, multiSelectCmd);
							return _Utils_Tuple2(editedModel, multiSelectAction);
						},
						$author$project$Components$Produits$Details$Documents$Model$getEditModel(model)));
			case 9:
				var types = msg.a;
				var updatedModel = A2(
					$author$project$Components$Produits$Details$Documents$Model$updateEditModel,
					function (editModel) {
						return _Utils_update(
							editModel,
							{
								kw: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, types, editModel.kw)
							});
					},
					model);
				return _Utils_Tuple2(updatedModel, $elm$core$Platform$Cmd$none);
			case 10:
				var effect = A2($elm$file$File$Select$file, $author$project$Components$Produits$Details$Documents$Update$acceptOnlyPdf, $author$project$Components$Produits$Details$Documents$Update$FileSelected);
				return _Utils_Tuple2(model, effect);
			case 11:
				var file = msg.a;
				var modelWithFile = A2($author$project$Components$Produits$Details$Documents$Model$setFile, file, model);
				var modelWithFilenameSetAsName = A2(
					$author$project$Components$Produits$Details$Documents$Model$updateEditModel,
					function (editModel) {
						return _Utils_update(
							editModel,
							{
								dl: A2(
									$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
									A2(
										$elm$core$Maybe$withDefault,
										$elm$file$File$name(file),
										A2(
											$elm$core$Maybe$map,
											function (existing) {
												return ($elm$core$String$trim(existing) === '') ? $elm$file$File$name(file) : existing;
											},
											$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(editModel.dl))),
									editModel.dl)
							});
					},
					modelWithFile);
				return _Utils_Tuple2(modelWithFilenameSetAsName, $elm$core$Platform$Cmd$none);
			case 12:
				var link = msg.a;
				var file = msg.b;
				var modelSetAsUploading = A2(
					$author$project$Components$Produits$Details$Documents$Model$updateEditModel,
					function (editModel) {
						return A2($author$project$Components$Produits$Details$Documents$Model$setLoading, true, editModel);
					},
					model);
				var maybeNom = A2(
					$elm$core$Maybe$andThen,
					$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$fromString,
					A2(
						$elm$core$Maybe$andThen,
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue,
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.dl;
							},
							$author$project$Components$Produits$Details$Documents$Model$getEditModel(model))));
				var maybeDateDeProduction = A2(
					$elm$core$Maybe$andThen,
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.ek;
						},
						$author$project$Components$Produits$Details$Documents$Model$getEditModel(model)));
				var uploadFileParams = A3(
					$elm$core$Maybe$map2,
					F2(
						function (nom, dateDeProduction) {
							return {
								lH: dateDeProduction,
								ew: file,
								hX: link,
								dl: nom,
								oK: A2(
									$elm$core$List$filterMap,
									$elm$core$Basics$identity,
									A2(
										$elm$core$List$map,
										$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$fromString,
										A2(
											$elm$core$List$map,
											$elm$core$String$toLower,
											A2(
												$elm$core$List$map,
												$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$toString,
												$author$project$Components$Produits$Details$Documents$Update$getFormTags(model))))),
								kw: $author$project$Components$Produits$Details$Documents$Update$getFormTypes(model)
							};
						}),
					maybeNom,
					maybeDateDeProduction);
				var doUpload = function (params) {
					return A2($author$project$Components$Produits$API$Requests$uploadFile, params, $author$project$Components$Produits$Details$Documents$Update$Uploaded);
				};
				if (!uploadFileParams.$) {
					var params = uploadFileParams.a;
					return _Utils_Tuple2(
						modelSetAsUploading,
						doUpload(params));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 13:
				if (!msg.a.$) {
					return A2($author$project$Components$Produits$Details$Documents$Update$init, model.aO, model.g2);
				} else {
					var e = msg.a.a;
					var modelSetAsNonUploading = A2(
						$author$project$Components$Produits$Details$Documents$Model$updateEditModel,
						$author$project$Components$Produits$Details$Documents$Model$setLoading(false),
						model);
					var errorMessage = function () {
						if (!e.$) {
							var err = e.a;
							return 'Erreur lors de l\'envoi du fichier... [' + (err + ']');
						} else {
							var _v6 = e.a;
							var metadata = _v6.a;
							var a = _v6.b;
							if (!a.$) {
								var produitId = a.a;
								return 'Impossible d\'envoyer le fichier, le produit [' + (produitId + '] n\'existe pas');
							} else {
								return 'Erreur technique (' + ($elm$core$String$fromInt(metadata.oy) + (':' + (metadata.oz + ') lors de l\'envoi du fichier ')));
							}
						}
					}();
					var modelSetWithError = A2(
						$author$project$Components$Produits$Details$Documents$Model$updateEditModel,
						$author$project$Components$Produits$Details$Documents$Model$setErrorMessage(
							$elm$core$Maybe$Just(errorMessage)),
						modelSetAsNonUploading);
					return _Utils_Tuple2(modelSetWithError, $elm$core$Platform$Cmd$none);
				}
			case 14:
				var link = msg.a;
				var confirmDrawer = A4(
					$author$project$Components$Drawer$ConfirmationDrawer$init,
					link,
					$elm$http$Http$emptyBody,
					'Erreur lors de la tentative de suppression',
					A2($elm$http$Http$expectJson, $author$project$Components$Drawer$ConfirmationDrawer$callback, $author$project$Perfimmo$Api$CRUDResultWithFeedback$crudResultWithFeedbackJsonDecoder));
				return A3(
					$elm$core$Tuple$mapBoth,
					function (dm) {
						return _Utils_update(
							model,
							{
								lQ: $elm$core$Maybe$Just(dm)
							});
					},
					$elm$core$Platform$Cmd$map($author$project$Components$Produits$Details$Documents$Update$DeleteAction),
					confirmDrawer);
			case 15:
				var deletionMsg = msg.a;
				var _v8 = $author$project$Components$Drawer$ConfirmationDrawer$requestIsDone(deletionMsg);
				if ((!_v8.$) && (!_v8.a.$)) {
					return A2($author$project$Components$Produits$Details$Documents$Update$init, model.aO, model.g2);
				} else {
					return A3(
						$elm$core$Tuple$mapBoth,
						function (newDeleteModel) {
							return _Utils_update(
								model,
								{lQ: newDeleteModel});
						},
						$elm$core$Platform$Cmd$map($author$project$Components$Produits$Details$Documents$Update$DeleteAction),
						A2($author$project$Components$Drawer$ConfirmationDrawer$update, model.lQ, deletionMsg));
				}
			case 16:
				var link = msg.a;
				var modelSetAsUpdating = A2(
					$author$project$Components$Produits$Details$Documents$Model$updateEditModel,
					function (editModel) {
						return A2($author$project$Components$Produits$Details$Documents$Model$setLoading, true, editModel);
					},
					model);
				var maybeNom = A2(
					$elm$core$Maybe$andThen,
					$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$fromString,
					A2(
						$elm$core$Maybe$andThen,
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue,
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.dl;
							},
							$author$project$Components$Produits$Details$Documents$Model$getEditModel(model))));
				var maybeDateDeProduction = A2(
					$elm$core$Maybe$andThen,
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.ek;
						},
						$author$project$Components$Produits$Details$Documents$Model$getEditModel(model)));
				var updateParams = A3(
					$elm$core$Maybe$map2,
					F2(
						function (nom, dateDeProduction) {
							return {
								lH: dateDeProduction,
								dl: nom,
								oK: A2(
									$elm$core$List$filterMap,
									$elm$core$Basics$identity,
									A2(
										$elm$core$List$map,
										$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$fromString,
										A2(
											$elm$core$List$map,
											$elm$core$String$toLower,
											A2(
												$elm$core$List$map,
												$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$toString,
												$author$project$Components$Produits$Details$Documents$Update$getFormTags(model))))),
								kw: $author$project$Components$Produits$Details$Documents$Update$getFormTypes(model)
							};
						}),
					maybeNom,
					maybeDateDeProduction);
				var doUpdate = function (params) {
					return A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Components$Produits$Details$Documents$Update$DocumentMetadataUpdated,
						A2($author$project$Components$Produits$API$Requests$updateDocument, link, params));
				};
				if (!updateParams.$) {
					var params = updateParams.a;
					return _Utils_Tuple2(
						modelSetAsUpdating,
						doUpdate(params));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				if (!msg.a.$) {
					return A2($author$project$Components$Produits$Details$Documents$Update$init, model.aO, model.g2);
				} else {
					var modelSetAsNonUploading = A2(
						$author$project$Components$Produits$Details$Documents$Model$updateEditModel,
						$author$project$Components$Produits$Details$Documents$Model$setLoading(false),
						model);
					var errorMessage = 'Erreur lors de la mise à jour du document';
					var modelSetWithError = A2(
						$author$project$Components$Produits$Details$Documents$Model$updateEditModel,
						$author$project$Components$Produits$Details$Documents$Model$setErrorMessage(
							$elm$core$Maybe$Just(errorMessage)),
						modelSetAsNonUploading);
					return _Utils_Tuple2(modelSetWithError, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setProduitField = F2(
	function (setter, record) {
		return _Utils_update(
			record,
			{
				i8: setter(record.i8)
			});
	});
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setCommercialisationTab = function (setter) {
	var setCommercialisationTab_ = function (produit) {
		return _Utils_update(
			produit,
			{
				M: setter(produit.M)
			});
	};
	return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setProduitField(setCommercialisationTab_);
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setFraisDuContrat = function (setter) {
	var setFraisDuContrat_ = function (commercialisation) {
		return _Utils_update(
			commercialisation,
			{
				cn: setter(commercialisation.cn)
			});
	};
	return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setCommercialisationTab(setFraisDuContrat_);
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setSupportInvestissement = function (setter) {
	var setSupportInvestissement_ = function (commercialisation) {
		return _Utils_update(
			commercialisation,
			{
				as: setter(commercialisation.as)
			});
	};
	return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setCommercialisationTab(setSupportInvestissement_);
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$set = function (field) {
	switch (field.$) {
		case 0:
			var valeur = field.a;
			return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setCommercialisationTab(
				function (xp) {
					return _Utils_update(
						xp,
						{
							at: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, valeur, xp.at)
						});
				});
		case 1:
			switch (field.a.$) {
				case 0:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setSupportInvestissement(
						function (si) {
							return _Utils_update(
								si,
								{
									ke: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, si.ke)
								});
						});
				case 1:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setSupportInvestissement(
						function (si) {
							return _Utils_update(
								si,
								{
									j9: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, si.j9)
								});
						});
				case 2:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setSupportInvestissement(
						function (si) {
							return _Utils_update(
								si,
								{
									hg: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, si.hg)
								});
						});
				case 3:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setSupportInvestissement(
						function (si) {
							return _Utils_update(
								si,
								{
									he: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, si.he)
								});
						});
				case 4:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setSupportInvestissement(
						function (si) {
							return _Utils_update(
								si,
								{
									ha: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, si.ha)
								});
						});
				case 5:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setSupportInvestissement(
						function (si) {
							return _Utils_update(
								si,
								{
									kd: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, si.kd)
								});
						});
				case 6:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setSupportInvestissement(
						function (si) {
							return _Utils_update(
								si,
								{
									il: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, si.il)
								});
						});
				default:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setSupportInvestissement(
						function (si) {
							return _Utils_update(
								si,
								{
									j6: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, si.j6)
								});
						});
			}
		default:
			switch (field.a.$) {
				case 0:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setFraisDuContrat(
						function (si) {
							return _Utils_update(
								si,
								{
									hf: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, si.hf)
								});
						});
				case 1:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setFraisDuContrat(
						function (si) {
							return _Utils_update(
								si,
								{
									hh: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, si.hh)
								});
						});
				case 2:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setFraisDuContrat(
						function (si) {
							return _Utils_update(
								si,
								{
									hj: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, si.hj)
								});
						});
				default:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$setFraisDuContrat(
						function (si) {
							return _Utils_update(
								si,
								{
									hi: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, si.hi)
								});
						});
			}
	}
};
var $author$project$Perfimmo$Form$FormView$handleCheckbox = function (field) {
	var toggle = A2(
		$elm$core$Maybe$withDefault,
		true,
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$not,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(field)));
	return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, toggle, field);
};
var $author$project$Components$Produits$Details$FormSetter$InformationsSetter$setInInformations = F2(
	function (f, produit) {
		var inf = produit.mZ;
		return _Utils_update(
			produit,
			{
				mZ: f(inf)
			});
	});
var $author$project$Components$Produits$Details$FormSetter$InformationsSetter$setProduitField = F2(
	function (setter, record) {
		return _Utils_update(
			record,
			{
				i8: setter(record.i8)
			});
	});
var $author$project$Components$Produits$Details$FormSetter$InformationsSetter$setIt = F2(
	function (model, informationsSetter) {
		var setter = $author$project$Components$Produits$Details$FormSetter$InformationsSetter$setInInformations(informationsSetter);
		return A2($author$project$Components$Produits$Details$FormSetter$InformationsSetter$setProduitField, setter, model);
	});
var $author$project$Components$Produits$Details$FormSetter$InformationsSetter$set = F2(
	function (informationsField, model) {
		switch (informationsField.$) {
			case 0:
				var value = informationsField.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$InformationsSetter$setIt,
					model,
					function (informations) {
						return _Utils_update(
							informations,
							{
								dl: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, informations.dl)
							});
					});
			case 1:
				var value = informationsField.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$InformationsSetter$setIt,
					model,
					function (informations) {
						return _Utils_update(
							informations,
							{m9: value});
					});
			case 2:
				var value = informationsField.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$InformationsSetter$setIt,
					model,
					function (informations) {
						return _Utils_update(
							informations,
							{
								i7: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$setValueFromS, value, informations.i7)
							});
					});
			case 3:
				var value = informationsField.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$InformationsSetter$setIt,
					model,
					function (informations) {
						return _Utils_update(
							informations,
							{
								iq: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$setValueFromS, value, informations.iq)
							});
					});
			case 4:
				return A2(
					$author$project$Components$Produits$Details$FormSetter$InformationsSetter$setIt,
					model,
					function (informations) {
						return _Utils_update(
							informations,
							{
								kD: $author$project$Perfimmo$Form$FormView$handleCheckbox(informations.kD)
							});
					});
			case 5:
				return A2(
					$author$project$Components$Produits$Details$FormSetter$InformationsSetter$setIt,
					model,
					function (informations) {
						return _Utils_update(
							informations,
							{
								ix: $author$project$Perfimmo$Form$FormView$handleCheckbox(informations.ix)
							});
					});
			case 6:
				var value = informationsField.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$InformationsSetter$setIt,
					model,
					function (informations) {
						return _Utils_update(
							informations,
							{
								f9: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, value, informations.f9)
							});
					});
			case 8:
				var value = informationsField.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$InformationsSetter$setIt,
					model,
					function (informations) {
						return _Utils_update(
							informations,
							{
								g0: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, value, informations.g0)
							});
					});
			case 7:
				var value = informationsField.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$InformationsSetter$setIt,
					model,
					function (informations) {
						return _Utils_update(
							informations,
							{
								jZ: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, value, informations.jZ)
							});
					});
			case 9:
				var value = informationsField.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$InformationsSetter$setIt,
					model,
					function (informations) {
						var documentsPresentation = informations.cf;
						return _Utils_update(
							informations,
							{
								cf: _Utils_update(
									documentsPresentation,
									{
										iY: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, documentsPresentation.iY)
									})
							});
					});
			case 10:
				var value = informationsField.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$InformationsSetter$setIt,
					model,
					function (informations) {
						var documentsPresentation = informations.cf;
						return _Utils_update(
							informations,
							{
								cf: _Utils_update(
									documentsPresentation,
									{
										iX: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, documentsPresentation.iX)
									})
							});
					});
			case 11:
				var value = informationsField.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$InformationsSetter$setIt,
					model,
					function (informations) {
						var documentsPresentation = informations.cf;
						return _Utils_update(
							informations,
							{
								cf: _Utils_update(
									documentsPresentation,
									{
										iW: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, documentsPresentation.iW)
									})
							});
					});
			case 12:
				var value = informationsField.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$InformationsSetter$setIt,
					model,
					function (informations) {
						var documentsPresentation = informations.cf;
						return _Utils_update(
							informations,
							{
								cf: _Utils_update(
									documentsPresentation,
									{
										gG: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, documentsPresentation.gG)
									})
							});
					});
			default:
				var value = informationsField.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$InformationsSetter$setIt,
					model,
					function (informations) {
						return _Utils_update(
							informations,
							{k2: value});
					});
		}
	});
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setInMarcheCible = F2(
	function (f, produit) {
		var mc = produit.c;
		return _Utils_update(
			produit,
			{
				c: f(mc)
			});
	});
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setProduitField = F2(
	function (setter, record) {
		return _Utils_update(
			record,
			{
				i8: setter(record.i8)
			});
	});
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt = F2(
	function (model, mcSetter) {
		var setter = $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setInMarcheCible(mcSetter);
		return A2($author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setProduitField, setter, model);
	});
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$set = F2(
	function (field, model) {
		switch (field.$) {
			case 0:
				var val = field.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
					model,
					function (mc) {
						return _Utils_update(
							mc,
							{
								ku: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, val, mc.ku)
							});
					});
			case 1:
				var val = field.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
					model,
					function (mc) {
						return _Utils_update(
							mc,
							{
								lJ: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, val, mc.lJ)
							});
					});
			case 2:
				var val = field.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
					model,
					function (mc) {
						return _Utils_update(
							mc,
							{
								kE: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, val, mc.kE)
							});
					});
			case 3:
				return A2(
					$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
					model,
					function (mc) {
						var toggle = A2(
							$elm$core$Maybe$withDefault,
							true,
							A2(
								$elm$core$Maybe$map,
								$elm$core$Basics$not,
								$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(mc.fv)));
						return _Utils_update(
							mc,
							{
								fv: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, toggle, mc.fv)
							});
					});
			case 4:
				var val = field.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
					model,
					function (mc) {
						var ageLimit = mc.fF;
						return _Utils_update(
							mc,
							{
								fF: _Utils_update(
									ageLimit,
									{
										h3: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$setValueFromS, val, mc.fF.h3)
									})
							});
					});
			case 5:
				var val = field.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
					model,
					function (mc) {
						var ageLimit = mc.fF;
						return _Utils_update(
							mc,
							{
								fF: _Utils_update(
									ageLimit,
									{
										h0: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$setValueFromS, val, mc.fF.h0)
									})
							});
					});
			case 6:
				var field_ = field.a;
				switch (field_.$) {
					case 0:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var typeInvestisseur = mc.cH;
								return _Utils_update(
									mc,
									{
										cH: _Utils_update(
											typeInvestisseur,
											{
												jf: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.cH.jf)
											})
									});
							});
					case 1:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var typeInvestisseur = mc.cH;
								return _Utils_update(
									mc,
									{
										cH: _Utils_update(
											typeInvestisseur,
											{
												$9: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.cH.$9)
											})
									});
							});
					case 2:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var typeInvestisseur = mc.cH;
								return _Utils_update(
									mc,
									{
										cH: _Utils_update(
											typeInvestisseur,
											{
												lB: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.cH.lB)
											})
									});
							});
					default:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var typeInvestisseur = mc.cH;
								return _Utils_update(
									mc,
									{
										cH: _Utils_update(
											typeInvestisseur,
											{
												je: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.cH.je)
											})
									});
							});
				}
			case 7:
				var experienceField = field.a;
				switch (experienceField.$) {
					case 0:
						var valeur = experienceField.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var xp = mc.ci;
								return _Utils_update(
									mc,
									{
										ci: _Utils_update(
											xp,
											{
												at: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, valeur, xp.at)
											})
									});
							});
					case 1:
						var texte = experienceField.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var xp = mc.ci;
								return _Utils_update(
									mc,
									{
										ci: _Utils_update(
											xp,
											{
												nJ: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, xp.nJ)
											})
									});
							});
					case 2:
						var texte = experienceField.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var xp = mc.ci;
								return _Utils_update(
									mc,
									{
										ci: _Utils_update(
											xp,
											{
												m_: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, xp.m_)
											})
									});
							});
					default:
						var texte = experienceField.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var xp = mc.ci;
								return _Utils_update(
									mc,
									{
										ci: _Utils_update(
											xp,
											{
												me: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, xp.me)
											})
									});
							});
				}
			case 8:
				var field_ = field.a;
				switch (field_.$) {
					case 0:
						var val = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var profils = mc.a6;
								return _Utils_update(
									mc,
									{
										a6: _Utils_update(
											profils,
											{
												oa: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, val, mc.a6.oa)
											})
									});
							});
					case 1:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var profils = mc.a6;
								var explications = profils.cj;
								return _Utils_update(
									mc,
									{
										a6: _Utils_update(
											profils,
											{
												cj: _Utils_update(
													explications,
													{
														jH: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.a6.cj.jH)
													})
											})
									});
							});
					case 2:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var profils = mc.a6;
								var explications = profils.cj;
								return _Utils_update(
									mc,
									{
										a6: _Utils_update(
											profils,
											{
												cj: _Utils_update(
													explications,
													{
														gI: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.a6.cj.gI)
													})
											})
									});
							});
					case 3:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var profils = mc.a6;
								var explications = profils.cj;
								return _Utils_update(
									mc,
									{
										a6: _Utils_update(
											profils,
											{
												cj: _Utils_update(
													explications,
													{
														gY: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.a6.cj.gY)
													})
											})
									});
							});
					case 4:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var profils = mc.a6;
								var explications = profils.cj;
								return _Utils_update(
									mc,
									{
										a6: _Utils_update(
											profils,
											{
												cj: _Utils_update(
													explications,
													{
														gT: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.a6.cj.gT)
													})
											})
									});
							});
					default:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var profils = mc.a6;
								var explications = profils.cj;
								return _Utils_update(
									mc,
									{
										a6: _Utils_update(
											profils,
											{
												cj: _Utils_update(
													explications,
													{
														iv: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.a6.cj.iv)
													})
											})
									});
							});
				}
			case 9:
				var field_ = field.a;
				switch (field_.$) {
					case 0:
						var val = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var horizon = mc.bx;
								return _Utils_update(
									mc,
									{
										bx: _Utils_update(
											horizon,
											{
												at: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, val, mc.bx.at)
											})
									});
							});
					case 1:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var horizon = mc.bx;
								return _Utils_update(
									mc,
									{
										bx: _Utils_update(
											horizon,
											{
												oU: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.bx.oU)
											})
									});
							});
					case 2:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var horizon = mc.bx;
								return _Utils_update(
									mc,
									{
										bx: _Utils_update(
											horizon,
											{
												lE: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.bx.lE)
											})
									});
							});
					case 3:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var horizon = mc.bx;
								return _Utils_update(
									mc,
									{
										bx: _Utils_update(
											horizon,
											{
												nw: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.bx.nw)
											})
									});
							});
					default:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var horizon = mc.bx;
								return _Utils_update(
									mc,
									{
										bx: _Utils_update(
											horizon,
											{
												nj: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.bx.nj)
											})
									});
							});
				}
			case 10:
				var field_ = field.a;
				switch (field_.$) {
					case 0:
						var val = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var objectifs = mc.ap;
								return _Utils_update(
									mc,
									{
										ap: _Utils_update(
											objectifs,
											{
												at: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, val, mc.ap.at)
											})
									});
							});
					case 1:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var objectifs = mc.ap;
								return _Utils_update(
									mc,
									{
										ap: _Utils_update(
											objectifs,
											{
												gh: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.ap.gh)
											})
									});
							});
					case 2:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var objectifs = mc.ap;
								return _Utils_update(
									mc,
									{
										ap: _Utils_update(
											objectifs,
											{
												gp: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.ap.gp)
											})
									});
							});
					case 3:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var objectifs = mc.ap;
								return _Utils_update(
									mc,
									{
										ap: _Utils_update(
											objectifs,
											{
												jp: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.ap.jp)
											})
									});
							});
					case 4:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var objectifs = mc.ap;
								return _Utils_update(
									mc,
									{
										ap: _Utils_update(
											objectifs,
											{
												jx: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.ap.jx)
											})
									});
							});
					case 5:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var objectifs = mc.ap;
								return _Utils_update(
									mc,
									{
										ap: _Utils_update(
											objectifs,
											{
												gj: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.ap.gj)
											})
									});
							});
					case 6:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var objectifs = mc.ap;
								return _Utils_update(
									mc,
									{
										ap: _Utils_update(
											objectifs,
											{
												nS: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.ap.nS)
											})
									});
							});
					default:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var objectifs = mc.ap;
								return _Utils_update(
									mc,
									{
										ap: _Utils_update(
											objectifs,
											{
												fM: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.ap.fM)
											})
									});
							});
				}
			case 11:
				var field_ = field.a;
				switch (field_.$) {
					case 0:
						var value = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var capaciteSubirPerte = mc.bi;
								return _Utils_update(
									mc,
									{
										bi: _Utils_update(
											capaciteSubirPerte,
											{
												at: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$setValueFromS, value, mc.bi.at)
											})
									});
							});
					case 1:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var capaciteSubirPerte = mc.bi;
								return _Utils_update(
									mc,
									{
										bi: _Utils_update(
											capaciteSubirPerte,
											{
												ir: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.bi.ir)
											})
									});
							});
					case 2:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var capaciteSubirPerte = mc.bi;
								return _Utils_update(
									mc,
									{
										bi: _Utils_update(
											capaciteSubirPerte,
											{
												hW: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.bi.hW)
											})
									});
							});
					case 3:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var capaciteSubirPerte = mc.bi;
								return _Utils_update(
									mc,
									{
										bi: _Utils_update(
											capaciteSubirPerte,
											{
												jP: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.bi.jP)
											})
									});
							});
					case 4:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var capaciteSubirPerte = mc.bi;
								return _Utils_update(
									mc,
									{
										bi: _Utils_update(
											capaciteSubirPerte,
											{
												m7: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.bi.m7)
											})
									});
							});
					default:
						var texte = field_.a;
						return A2(
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
							model,
							function (mc) {
								var capaciteSubirPerte = mc.bi;
								return _Utils_update(
									mc,
									{
										bi: _Utils_update(
											capaciteSubirPerte,
											{
												k5: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.bi.k5)
											})
									});
							});
				}
			default:
				var field_ = field.a;
				if (!field_.$) {
					var texte = field_.a;
					return A2(
						$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
						model,
						function (mc) {
							var synthese = mc.fg;
							return _Utils_update(
								mc,
								{
									fg: _Utils_update(
										synthese,
										{
											no: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.fg.no)
										})
								});
						});
				} else {
					var texte = field_.a;
					return A2(
						$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$setIt,
						model,
						function (mc) {
							var synthese = mc.fg;
							return _Utils_update(
								mc,
								{
									fg: _Utils_update(
										synthese,
										{
											nn: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, texte, mc.fg.nn)
										})
								});
						});
				}
		}
	});
var $author$project$Components$Produits$Details$FormSetter$Utils$handlePourcentInput = F2(
	function (raw, form) {
		return A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$setValueFromS, raw, form);
	});
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setProduitField = F2(
	function (setter, record) {
		return _Utils_update(
			record,
			{
				i8: setter(record.i8)
			});
	});
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setProfilExtraFinancierTab = function (setter) {
	var setProfilExtraFinancierTab_ = function (produit) {
		return _Utils_update(
			produit,
			{
				jg: setter(produit.jg)
			});
	};
	return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setProduitField(setProfilExtraFinancierTab_);
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setIncidenceNegativePai = function (setter) {
	var setIncidenceNegativePai_ = function (pef) {
		return _Utils_update(
			pef,
			{
				de: setter(pef.de)
			});
	};
	return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setProfilExtraFinancierTab(setIncidenceNegativePai_);
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdr = function (setter) {
	var setInvestissementDurableSfdr_ = function (pef) {
		return _Utils_update(
			pef,
			{
				Q: setter(pef.Q)
			});
	};
	return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setProfilExtraFinancierTab(setInvestissementDurableSfdr_);
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategorieProduit = function (setter) {
	var setInvestissementDurableSfdrCategorieProduit_ = function (investissementDurableSfdr) {
		return _Utils_update(
			investissementDurableSfdr,
			{
				Z: setter(investissementDurableSfdr.Z)
			});
	};
	return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdr(setInvestissementDurableSfdrCategorieProduit_);
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategoriesProduitArticle6 = function (setter) {
	var setInvestissementDurableSfdrCategoriesProduitArticle6_ = function (investissementDurableSfdrCategoriesProduit) {
		return _Utils_update(
			investissementDurableSfdrCategoriesProduit,
			{
				d3: setter(investissementDurableSfdrCategoriesProduit.d3)
			});
	};
	return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategorieProduit(setInvestissementDurableSfdrCategoriesProduitArticle6_);
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategoriesProduitArticle8 = function (setter) {
	var setInvestissementDurableSfdrCategoriesProduitArticle8_ = function (investissementDurableSfdrCategoriesProduit) {
		return _Utils_update(
			investissementDurableSfdrCategoriesProduit,
			{
				b1: setter(investissementDurableSfdrCategoriesProduit.b1)
			});
	};
	return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategorieProduit(setInvestissementDurableSfdrCategoriesProduitArticle8_);
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategoriesProduitArticle9 = function (setter) {
	var setInvestissementDurableSfdrCategoriesProduitArticle9_ = function (investissementDurableSfdrCategoriesProduit) {
		return _Utils_update(
			investissementDurableSfdrCategoriesProduit,
			{
				b2: setter(investissementDurableSfdrCategoriesProduit.b2)
			});
	};
	return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategorieProduit(setInvestissementDurableSfdrCategoriesProduitArticle9_);
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setTaxonomie = function (setter) {
	var setTaxonomie_ = function (pef) {
		return _Utils_update(
			pef,
			{
				fi: setter(pef.fi)
			});
	};
	return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setProfilExtraFinancierTab(setTaxonomie_);
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$set = function (field) {
	switch (field.$) {
		case 0:
			var value = field.a;
			return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setProfilExtraFinancierTab(
				function (pef) {
					return _Utils_update(
						pef,
						{
							i_: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, pef.i_)
						});
				});
		case 1:
			var value = field.a;
			return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setProfilExtraFinancierTab(
				function (pef) {
					return _Utils_update(
						pef,
						{
							ej: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, value, pef.ej)
						});
				});
		case 2:
			if (!field.a.$) {
				var value = field.a.a;
				return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setTaxonomie(
					function (taxonomie) {
						return _Utils_update(
							taxonomie,
							{
								aX: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, taxonomie.aX)
							});
					});
			} else {
				var value = field.a.a;
				return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setTaxonomie(
					function (taxonomie) {
						return _Utils_update(
							taxonomie,
							{
								bK: A2($author$project$Components$Produits$Details$FormSetter$Utils$handlePourcentInput, value, taxonomie.bK)
							});
					});
			}
		case 3:
			switch (field.a.$) {
				case 0:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdr(
						function (investissement) {
							return _Utils_update(
								investissement,
								{
									aX: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, investissement.aX)
								});
						});
				case 1:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategoriesProduitArticle9(
						function (article) {
							return _Utils_update(
								article,
								{
									aX: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, article.aX)
								});
						});
				case 2:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategoriesProduitArticle9(
						function (article) {
							return _Utils_update(
								article,
								{
									dr: A2($author$project$Components$Produits$Details$FormSetter$Utils$handlePourcentInput, value, article.dr)
								});
						});
				case 4:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategoriesProduitArticle9(
						function (article) {
							return _Utils_update(
								article,
								{
									ds: A2($author$project$Components$Produits$Details$FormSetter$Utils$handlePourcentInput, value, article.ds)
								});
						});
				case 3:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategoriesProduitArticle9(
						function (article) {
							return _Utils_update(
								article,
								{
									dt: A2($author$project$Components$Produits$Details$FormSetter$Utils$handlePourcentInput, value, article.dt)
								});
						});
				case 5:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategoriesProduitArticle8(
						function (article) {
							return _Utils_update(
								article,
								{
									aX: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, article.aX)
								});
						});
				case 6:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategoriesProduitArticle8(
						function (article) {
							return _Utils_update(
								article,
								{
									dr: A2($author$project$Components$Produits$Details$FormSetter$Utils$handlePourcentInput, value, article.dr)
								});
						});
				case 8:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategoriesProduitArticle8(
						function (article) {
							return _Utils_update(
								article,
								{
									ds: A2($author$project$Components$Produits$Details$FormSetter$Utils$handlePourcentInput, value, article.ds)
								});
						});
				case 7:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategoriesProduitArticle8(
						function (article) {
							return _Utils_update(
								article,
								{
									dt: A2($author$project$Components$Produits$Details$FormSetter$Utils$handlePourcentInput, value, article.dt)
								});
						});
				case 9:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategoriesProduitArticle6(
						function (article) {
							return _Utils_update(
								article,
								{
									aX: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, article.aX)
								});
						});
				default:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setInvestissementDurableSfdrCategoriesProduitArticle6(
						function (article) {
							return _Utils_update(
								article,
								{
									bK: A2($author$project$Components$Produits$Details$FormSetter$Utils$handlePourcentInput, value, article.bK)
								});
						});
			}
		default:
			switch (field.a.$) {
				case 0:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setIncidenceNegativePai(
						function (incidence) {
							return _Utils_update(
								incidence,
								{
									iU: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, incidence.iU)
								});
						});
				case 2:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setIncidenceNegativePai(
						function (incidence) {
							return _Utils_update(
								incidence,
								{
									bK: A2($author$project$Components$Produits$Details$FormSetter$Utils$handlePourcentInput, value, incidence.bK)
								});
						});
				default:
					var value = field.a.a;
					return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$setIncidenceNegativePai(
						function (incidence) {
							return _Utils_update(
								incidence,
								{
									hZ: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, incidence.hZ)
								});
						});
			}
	}
};
var $author$project$Components$Produits$Details$FormSetter$SupportsSetter$changeDisplayMode = F2(
	function (selectInput, supportTab) {
		return $author$project$Components$Produits$Details$FormSetter$SupportsSetter$applyFilters(
			_Utils_update(
				supportTab,
				{lW: selectInput}));
	});
var $author$project$Perfimmo$Input$SelectInput$clearSelect = function (model) {
	return _Utils_update(
		model,
		{aV: 0, dO: $elm$core$Maybe$Nothing});
};
var $author$project$Components$Produits$Details$FormSetter$SupportsSetter$searchByModeDeGestion = F2(
	function (select, supportTab) {
		return $author$project$Components$Produits$Details$FormSetter$SupportsSetter$applyFilters(
			_Utils_update(
				supportTab,
				{e3: select}));
	});
var $author$project$Components$Produits$Details$FormSetter$SupportsSetter$searchByNameOrSupportId = F2(
	function (input, supportTab) {
		var result = $author$project$Components$Produits$Details$FormSetter$SupportsSetter$applyFilters(
			_Utils_update(
				supportTab,
				{e4: input}));
		var checkIfOnlyOne = ($elm$core$List$length(result.mr.mD) <= 1) && (($elm$core$List$length(result.mr.mE) <= 1) && (($elm$core$List$length(result.mr.mF) <= 1) && ($elm$core$List$length(result.mr.mC) <= 1)));
		return _Utils_update(
			result,
			{nP: checkIfOnlyOne});
	});
var $author$project$Components$Produits$Details$FormSetter$SupportsSetter$setProduitField = F2(
	function (setter, record) {
		return _Utils_update(
			record,
			{
				i8: setter(record.i8)
			});
	});
var $author$project$Components$Produits$Details$FormSetter$SupportsSetter$setSupportField = function (setter) {
	var setSupportField_ = function (produit) {
		return _Utils_update(
			produit,
			{
				fe: setter(produit.fe)
			});
	};
	return $author$project$Components$Produits$Details$FormSetter$SupportsSetter$setProduitField(setSupportField_);
};
var $author$project$Perfimmo$Input$SelectInput$ShowItems = 1;
var $author$project$Perfimmo$Input$SelectInput$toggleDropdown = function (model) {
	var newStatus = function () {
		var _v0 = model.aV;
		if (!_v0) {
			return 1;
		} else {
			return 0;
		}
	}();
	return _Utils_update(
		model,
		{aV: newStatus});
};
var $author$project$Components$Produits$Details$FormSetter$SupportsSetter$set = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				var input = msg.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$SupportsSetter$setSupportField,
					$author$project$Components$Produits$Details$FormSetter$SupportsSetter$searchByNameOrSupportId(input),
					model);
			case 1:
				return A2(
					$author$project$Components$Produits$Details$FormSetter$SupportsSetter$setSupportField,
					$author$project$Components$Produits$Details$FormSetter$SupportsSetter$searchByNameOrSupportId(''),
					model);
			case 2:
				var selectInput = msg.a;
				var selectModel = model.i8.fe.e3;
				var _v1 = function () {
					switch (selectInput.$) {
						case 0:
							return _Utils_Tuple2(
								$author$project$Perfimmo$Input$SelectInput$toggleDropdown(selectModel),
								$elm$core$Maybe$Nothing);
						case 1:
							var importType = selectInput.a;
							return _Utils_Tuple2(
								A2(
									$author$project$Perfimmo$Input$SelectInput$selectItem,
									$elm$core$Maybe$Just(importType),
									selectModel),
								$elm$core$Maybe$Just(importType));
						default:
							return _Utils_Tuple2(
								$author$project$Perfimmo$Input$SelectInput$clearSelect(selectModel),
								$elm$core$Maybe$Nothing);
					}
				}();
				var updatedModel = _v1.a;
				return A2(
					$author$project$Components$Produits$Details$FormSetter$SupportsSetter$setSupportField,
					$author$project$Components$Produits$Details$FormSetter$SupportsSetter$searchByModeDeGestion(updatedModel),
					model);
			default:
				var selectInput = msg.a;
				var selectModel = model.i8.fe.lW;
				var updatedModel = function () {
					switch (selectInput.$) {
						case 0:
							return $author$project$Perfimmo$Input$SelectInput$toggleDropdown(selectModel);
						case 1:
							var importType = selectInput.a;
							return A2(
								$author$project$Perfimmo$Input$SelectInput$selectItem,
								$elm$core$Maybe$Just(importType),
								selectModel);
						default:
							return $author$project$Perfimmo$Input$SelectInput$clearSelect(selectModel);
					}
				}();
				return A2(
					$author$project$Components$Produits$Details$FormSetter$SupportsSetter$setSupportField,
					$author$project$Components$Produits$Details$FormSetter$SupportsSetter$changeDisplayMode(updatedModel),
					model);
		}
	});
var $author$project$Components$Produits$Details$Update$updateProduit = F2(
	function (setter, model) {
		return function () {
			switch (setter.$) {
				case 0:
					return function (m) {
						var produitForm = m.i8;
						return _Utils_update(
							m,
							{
								i8: _Utils_update(
									produitForm,
									{lX: !produitForm.lX})
							});
					};
				case 1:
					var field = setter.a;
					return $author$project$Components$Produits$Details$FormSetter$InformationsSetter$set(field);
				case 2:
					var field = setter.a;
					return $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$set(field);
				case 3:
					var field = setter.a;
					return $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$set(field);
				case 4:
					var field = setter.a;
					return $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$set(field);
				default:
					var msg = setter.a;
					return $author$project$Components$Produits$Details$FormSetter$SupportsSetter$set(msg);
			}
		}()(model);
	});
var $author$project$Components$Produits$API$UpsertRequest$encodeDocumentsPresentation = function (presentations) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'presentationProducteur',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, presentations.iX))),
				_Utils_Tuple2(
				'presentationProduit',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, presentations.iY))),
				_Utils_Tuple2(
				'presentationPreconisation',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, presentations.iW))),
				_Utils_Tuple2(
				'declarationAdequation',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, presentations.gG)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeClassificationActivite = function (item) {
	switch (item) {
		case 0:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'IAS',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 1:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'CIF',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 2:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'IOBSP',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'ITI',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
	}
};
var $author$project$Components$Produits$API$UpsertRequest$encodeClassificationFamille = function (item) {
	switch (item) {
		case 0:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'AssuranceRisquesDivers',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 1:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'AssuranceVie',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 2:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'AssuranceCapi',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 3:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'CompteTitres',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 4:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'CompteTitresPea',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 5:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'DefiscalisationIsf',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 6:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'EpargneSalariale',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 7:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'FipFcpiFcpr',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 8:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'GestionSousMandat',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 9:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Gff',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 10:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'GirardinIs',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 11:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Honoraires',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 12:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Lmnp',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 13:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'MandatDeGestion',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 14:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Opci',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 15:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Oppci',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 16:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Pinel',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 17:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'PinelOutreMer',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 18:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Prevoyance',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 19:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Retraite',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Scpi',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
	}
};
var $author$project$Components$Produits$API$UpsertRequest$encodeSousClassification = function (item) {
	switch (item) {
		case 0:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'ConseilSurBiensDiversEtAutresProduitsDePlacement',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 1:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'ConseilSurInstrumentsFinanciers',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 2:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'ConseilSurLesServicesDInvestissement',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 3:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'HonorairesCIF',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'HonorairesIAS',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
	}
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitClassification = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'activite',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Produits$API$UpsertRequest$encodeClassificationActivite, item.kR))),
				_Utils_Tuple2(
				'famille',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Produits$API$UpsertRequest$encodeClassificationFamille, item.g0))),
				_Utils_Tuple2(
				'OPC',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$bool, item.ix))),
				_Utils_Tuple2(
				'sous_classification',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Produits$API$UpsertRequest$encodeSousClassification, item.jZ)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeCommercialisationType = function (activite) {
	switch (activite) {
		case 0:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'PER',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 1:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'PERP',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 2:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Madelin',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 3:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Article83',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 4:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'PERE',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 5:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'PREFON',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 6:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'CRH',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 7:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'COREMC',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'PERCO',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
	}
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitCommercialisationFraisDuContrat = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'frais_gestion_fonds_Euro',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.hf))),
				_Utils_Tuple2(
				'frais_gestion_UC',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.hh))),
				_Utils_Tuple2(
				'frais_transfert_sortant',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.hi))),
				_Utils_Tuple2(
				'frais_versement',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.hj)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitCommercialisationSupportInvestissement = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'fonds_euro',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.ha))),
				_Utils_Tuple2(
				'frais_arrerage',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.he))),
				_Utils_Tuple2(
				'frais_gestion_phase_rente',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.hg))),
				_Utils_Tuple2(
				'nombre_uc',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.il))),
				_Utils_Tuple2(
				'supports_immobiliers',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.j6))),
				_Utils_Tuple2(
				'table_mortalite',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.j9))),
				_Utils_Tuple2(
				'taux_minimum_garanti',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.kd))),
				_Utils_Tuple2(
				'taux_technique',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.ke)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitCommercialisation = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'frais_du_contrat',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitCommercialisationFraisDuContrat(item.cn)),
				_Utils_Tuple2(
				'support_investissement',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitCommercialisationSupportInvestissement(item.as)),
				_Utils_Tuple2(
				'type',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Produits$API$UpsertRequest$encodeCommercialisationType, item.kv)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeExperienceValeur = function (item) {
	switch (item) {
		case 0:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Novice',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 1:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Informe',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Experimente',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
	}
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitExperience = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'experimente_texte',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.mf))),
				_Utils_Tuple2(
				'informe_texte',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.m$))),
				_Utils_Tuple2(
				'novice_texte',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.nK))),
				_Utils_Tuple2(
				'valeurs',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Produits$API$UpsertRequest$encodeExperienceValeur, item.at)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleAgeLimiteClient = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'max',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$int, item.h0))),
				_Utils_Tuple2(
				'min',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$int, item.h3)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeCapaciteSubirPerteValeur = function (item) {
	switch (item) {
		case 0:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Nulle',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 1:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Limitees',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 2:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Significatives',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 3:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'JusquaMontants',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'AuDelaMontants',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
	}
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleCapacitePerte = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'au_dela_montants',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.k4))),
				_Utils_Tuple2(
				'jusqua_montants',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.m6))),
				_Utils_Tuple2(
				'limitées',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.hW))),
				_Utils_Tuple2(
				'nulle',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.ir))),
				_Utils_Tuple2(
				'significatives',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.jP))),
				_Utils_Tuple2(
				'valeurs',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Produits$API$UpsertRequest$encodeCapaciteSubirPerteValeur, item.at)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleHorizonValeur = function (item) {
	switch (item) {
		case 0:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'TresCourtTerme',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 1:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'CourtTerme',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 2:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'MoyenTerme',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'LongTerme',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
	}
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleHorizon = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'court_terme',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.lD))),
				_Utils_Tuple2(
				'long_terme',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.ni))),
				_Utils_Tuple2(
				'moyen_terme',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.nv))),
				_Utils_Tuple2(
				'tres_court_terme',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.oT))),
				_Utils_Tuple2(
				'valeur',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleHorizonValeur, item.at)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleObjectifsValeur = function (item) {
	switch (item) {
		case 0:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Conservation',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 1:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Croissance',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 2:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Rendement',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 3:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Revenus',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 4:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Couverture',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 5:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'OptionnelOuEffetLevier',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Autre',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
	}
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleObjectifs = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'autre',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.fM))),
				_Utils_Tuple2(
				'conservation',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.gh))),
				_Utils_Tuple2(
				'couverture',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.gj))),
				_Utils_Tuple2(
				'croissance',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.gp))),
				_Utils_Tuple2(
				'optionnel_ou_à_effet_de_levier',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.nR))),
				_Utils_Tuple2(
				'rendement',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.jp))),
				_Utils_Tuple2(
				'revenus',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.jx))),
				_Utils_Tuple2(
				'valeurs',
				A2(
					$elm$json$Json$Encode$list,
					$author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleObjectifsValeur,
					$author$project$Components$Produits$Common$MarcheCibleModel$objectifsSetBuilder.kk(item.o4)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleProfilsValeur = function (item) {
	switch (item) {
		case 0:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Securitaire',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 1:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Defensif',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 2:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Equilibre',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 3:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Dynamique',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'Offensif',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
	}
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleProfils = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'defensif',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.gI))),
				_Utils_Tuple2(
				'dynamique',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.gT))),
				_Utils_Tuple2(
				'equilibre',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.gY))),
				_Utils_Tuple2(
				'offensif',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.iv))),
				_Utils_Tuple2(
				'securitaire',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.jH))),
				_Utils_Tuple2(
				'valeur',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleProfilsValeur, item.at)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleSynthese = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'marche_cible_du_produit_par_le_cabinet',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.nl))),
				_Utils_Tuple2(
				'marche_cible_négatif_du_produit_par_le_cabinet',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.nm)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleType = function (item) {
	if (!item) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Producteur',
					$elm$json$Json$Encode$object(_List_Nil))
				]));
	} else {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'Distributeur',
					$elm$json$Json$Encode$object(_List_Nil))
				]));
	}
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleTypeInvestisseur = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'contrepartie_eligible',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.lA))),
				_Utils_Tuple2(
				'professionnel',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.jf))),
				_Utils_Tuple2(
				'non_professionnel',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.$9)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$formatToDocgenDate = $justinmimbs$date$Date$format('yyyy-MM-dd');
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCible = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'accessible_personnes_vulnerables',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$bool, item.fv))),
				_Utils_Tuple2(
				'age_limite_client',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleAgeLimiteClient(item.kT)),
				_Utils_Tuple2(
				'capacite_perte',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleCapacitePerte(item.ln)),
				_Utils_Tuple2(
				'date_mise_a_jour',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2(
						$elm$core$Maybe$map,
						$elm$json$Json$Encode$string,
						A2($elm$core$Maybe$map, $author$project$Components$Produits$API$UpsertRequest$formatToDocgenDate, item.ej)))),
				_Utils_Tuple2(
				'horizon',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleHorizon(item.bx)),
				_Utils_Tuple2(
				'objectifs',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleObjectifs(item.ap)),
				_Utils_Tuple2(
				'profession_exclues',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.je))),
				_Utils_Tuple2(
				'profils',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleProfils(item.a6)),
				_Utils_Tuple2(
				'synthese',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleSynthese(item.fg)),
				_Utils_Tuple2(
				'type_investisseur',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleTypeInvestisseur(item.cH)),
				_Utils_Tuple2(
				'type_marche_cible',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCibleType, item.ku))),
				_Utils_Tuple2(
				'version',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$string, item.kE)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodePai = function (item) {
	switch (item) {
		case 0:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'GazEffetDeSerre',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 1:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'NiveauImpactBiodiversite',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 2:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'EmissionsPolluantesEau',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 3:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'GenerationDechetsDangereux',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 4:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'InefficaciteEnergetiqueImmobilier',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 5:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'RespectDesNormesInternationales',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 6:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'ProcessusDeControleDesNormesInternationales',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 7:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'EgaliteRemuneration',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		case 8:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'DiversiteDesGenresConseilsAdministration',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
		default:
			return $elm$json$Json$Encode$object(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'ExpositionAuxArmesControversees',
						$elm$json$Json$Encode$object(_List_Nil))
					]));
	}
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitProfilExtraFinancierIncidenceNegativePai = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'pct_alignement',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$float, item.bK))),
				_Utils_Tuple2(
				'prend_en_compte_pai',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$bool, item.iU))),
				_Utils_Tuple2(
				'liste_pai_pris_en_compte',
				A2(
					$elm$json$Json$Encode$list,
					$author$project$Components$Produits$API$UpsertRequest$encodePai,
					$author$project$Common$Referentiel$ProfilExtraFinancier$setBuilder.kk(item.hZ)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle6 = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'est_aligne',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$bool, item.et))),
				_Utils_Tuple2(
				'pct_alignement',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$float, item.bK)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle8 = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'est_aligne',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$bool, item.et))),
				_Utils_Tuple2(
				'pct_alignement_environnemental',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$float, item.dr))),
				_Utils_Tuple2(
				'pct_alignement_gouvernance',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$float, item.ds))),
				_Utils_Tuple2(
				'pct_alignement_social',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$float, item.dt)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle9 = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'est_aligne',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$bool, item.et))),
				_Utils_Tuple2(
				'pct_alignement_environnemental',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$float, item.dr))),
				_Utils_Tuple2(
				'pct_alignement_gouvernance',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$float, item.ds))),
				_Utils_Tuple2(
				'pct_alignement_social',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$float, item.dt)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduits = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'article_6',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle6(item.d3)),
				_Utils_Tuple2(
				'article_8',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle8(item.b1)),
				_Utils_Tuple2(
				'article_9',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduitsArticle9(item.b2))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitProfilExtraFinancierInvestissementDurableSfdr = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'categories_produits',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitProfilExtraFinancierInvestissementDurableSfdrCategoriesProduits(item.lq)),
				_Utils_Tuple2(
				'est_aligne',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$bool, item.aX)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitProfilExtraFinancierTaxonomie = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'est_aligne',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$bool, item.aX))),
				_Utils_Tuple2(
				'pct_alignement',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$float, item.bK)))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitProfilExtraFinancier = function (item) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'date_mise_a_jour',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2(
						$elm$core$Maybe$map,
						$elm$json$Json$Encode$string,
						A2($elm$core$Maybe$map, $author$project$Components$Produits$API$UpsertRequest$formatToDocgenDate, item.ej)))),
				_Utils_Tuple2(
				'incidence_negative_pai',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitProfilExtraFinancierIncidenceNegativePai(item.de)),
				_Utils_Tuple2(
				'investissement_durable_sfdr',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitProfilExtraFinancierInvestissementDurableSfdr(item.Q)),
				_Utils_Tuple2(
				'pris_en_compte',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$bool, item.i_))),
				_Utils_Tuple2(
				'taxonomie',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitProfilExtraFinancierTaxonomie(item.fi))
			]));
};
var $author$project$Components$Produits$API$UpsertRequest$encodeProduitToUpsert = function (produit) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'nom',
				$elm$json$Json$Encode$string(produit.dl)),
				_Utils_Tuple2(
				'actif',
				$elm$json$Json$Encode$bool(produit.lY)),
				_Utils_Tuple2(
				'valideParProducteur',
				$elm$json$Json$Encode$bool(produit.kD)),
				_Utils_Tuple2(
				'documentsPresentation',
				$author$project$Components$Produits$API$UpsertRequest$encodeDocumentsPresentation(produit.cf)),
				_Utils_Tuple2(
				'classification',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitClassification(produit.f9)),
				_Utils_Tuple2(
				'commercialisation',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitCommercialisation(produit.lw)),
				_Utils_Tuple2(
				'experience',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitExperience(produit.ci)),
				_Utils_Tuple2(
				'marcheCible',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitMarcheCible(produit.c)),
				_Utils_Tuple2(
				'noteRisqueProduit',
				A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Encode$null,
					A2($elm$core$Maybe$map, $elm$json$Json$Encode$int, produit.iq))),
				_Utils_Tuple2(
				'producteur',
				$elm$json$Json$Encode$string(produit.i7)),
				_Utils_Tuple2(
				'profilExtraFinancier',
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitProfilExtraFinancier(produit.jg))
			]));
};
var $author$project$Components$Produits$API$Requests$upsert = F2(
	function (link, produit) {
		return A3(
			$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
			link,
			$elm$http$Http$jsonBody(
				$author$project$Components$Produits$API$UpsertRequest$encodeProduitToUpsert(produit)),
			$elm$http$Http$expectJson($elm$core$Basics$identity)($author$project$Perfimmo$Api$CRUDResultWithFeedback$crudResultWithFeedbackJsonDecoder));
	});
var $author$project$Components$Produits$Details$Update$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var result = msg.a.a;
					var produitForm = A2($author$project$Components$Produits$API$ToModel$fromDetailProduitAPI, result, model.i8);
					var produitSetted = A4(
						$author$project$Components$Produits$Details$Model$setProduit,
						result.kx,
						produitForm,
						A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'update', result.nf),
						model);
					var produitFormBis = produitSetted.i8;
					var supportsTab = produitSetted.i8.fe;
					var populateDisplayMode = function (supports) {
						return A2(
							$elm$core$List$map,
							function (cabinet) {
								return _Utils_Tuple2(
									A2($author$project$Components$Produits$Details$Model$ForCabinet, cabinet.kx, cabinet.dl),
									cabinet.dl);
							},
							A2(
								$elm_community$list_extra$List$Extra$uniqueBy,
								function ($) {
									return $.kx;
								},
								A2(
									$elm$core$List$map,
									function ($) {
										return $.fZ;
									},
									A2(
										$ccapndave$elm_flat_map$List$FlatMap$flatMap,
										function ($) {
											return $.l4;
										},
										_Utils_ap(
											supports.fe.mD,
											_Utils_ap(
												supports.fe.mE,
												_Utils_ap(supports.fe.mF, supports.fe.mC)))))));
					};
					var documentsLink = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'documents', result.nf);
					var displayModeSetted = _Utils_update(
						produitSetted,
						{
							i8: _Utils_update(
								produitFormBis,
								{
									fe: _Utils_update(
										supportsTab,
										{
											lW: A2(
												$author$project$Perfimmo$Input$SelectInput$populateItems,
												_Utils_ap(
													_List_fromArray(
														[
															_Utils_Tuple2(
															$author$project$Components$Produits$Details$Model$General,
															$author$project$Components$Produits$Details$Model$displayModeToString($author$project$Components$Produits$Details$Model$General))
														]),
													populateDisplayMode(supportsTab)),
												supportsTab.lW)
										})
								})
						});
					var produitFormTer = displayModeSetted.i8;
					var filtersAppliedToModel = _Utils_update(
						displayModeSetted,
						{
							i8: _Utils_update(
								produitFormTer,
								{
									fe: $author$project$Components$Produits$Details$FormSetter$SupportsSetter$applyFilters(produitFormTer.fe)
								})
						});
					var _v1 = A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2(filtersAppliedToModel, $elm$core$Platform$Cmd$none),
						A2(
							$elm$core$Maybe$map,
							function (_v2) {
								var documentModel = _v2.a;
								var documentsCmd = _v2.b;
								return _Utils_Tuple2(
									_Utils_update(
										filtersAppliedToModel,
										{
											gO: $elm$core$Maybe$Just(documentModel)
										}),
									documentsCmd);
							},
							A2(
								$elm$core$Maybe$map,
								function (l) {
									return A2(
										$elm$core$Tuple$mapSecond,
										$elm$core$Platform$Cmd$map($author$project$Components$Produits$Details$Update$DocumentsActions),
										A2($author$project$Components$Produits$Details$Documents$Update$init, model.aO, l));
								},
								documentsLink)));
					var initializedModel = _v1.a;
					var effects = _v1.b;
					return _Utils_Tuple2(initializedModel, effects);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aG: false}),
						$elm$core$Platform$Cmd$none);
				}
			case 2:
				var tabId = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Components$Produits$Details$Model$selectTab, tabId, model),
					$elm$core$Platform$Cmd$none);
			case 4:
				var setter = msg.a;
				var validForm = function (m) {
					var _v3 = A2($author$project$Components$Produits$API$FromModel$toDetailProduitAPI, m.n5, m.i8);
					if (!_v3.$) {
						var upsert = _v3.a;
						return _Utils_update(
							m,
							{
								jc: $elm$core$Maybe$Just(upsert),
								kC: _List_Nil
							});
					} else {
						var errors = _v3.a;
						return _Utils_update(
							m,
							{jc: $elm$core$Maybe$Nothing, kC: errors});
					}
				};
				var updatedModel = A2($author$project$Components$Produits$Details$Update$updateProduit, setter, model);
				var produitEstModifie = function (m) {
					return _Utils_update(
						m,
						{ip: false});
				};
				return _Utils_Tuple2(
					validForm(
						produitEstModifie(updatedModel)),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				var link = msg.a;
				var produitToUpsert = msg.b;
				var effect = A2(
					$elm$core$Platform$Cmd$map,
					$author$project$Components$Produits$Details$Update$ProduitSaved,
					A2($author$project$Components$Produits$API$Requests$upsert, link, produitToUpsert));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jE: true}),
					effect);
			case 5:
				if (!msg.a.$) {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ip: true, jE: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{jE: false}),
						$elm$core$Platform$Cmd$none);
				}
			default:
				var documentMsg = msg.a;
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Maybe$map,
						function (_v4) {
							var m = _v4.a;
							var c = _v4.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										gO: $elm$core$Maybe$Just(m)
									}),
								A2($elm$core$Platform$Cmd$map, $author$project$Components$Produits$Details$Update$DocumentsActions, c));
						},
						A2(
							$elm$core$Maybe$map,
							function (docModel) {
								return A2($author$project$Components$Produits$Details$Documents$Update$update, docModel, documentMsg);
							},
							model.gO)));
		}
	});
var $author$project$Components$Produits$List$Model$Loaded = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Components$Produits$API$ToModel$fromListAPI = function (api) {
	return {k2: api.k2, f9: api.f9, lX: api.lX, g0: api.g0, hB: api.hB, m9: api.m9, nf: api.nf, dl: api.dl, i7: api.i7, jZ: api.jZ};
};
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $author$project$Components$Produits$List$Model$init = F3(
	function (produitsLinks, producteurs, produits) {
		var producteursById = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (producteur) {
					return _Utils_Tuple2(producteur.kx, producteur);
				},
				producteurs));
		var createProduitLink = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'create', produitsLinks);
		return {
			go: createProduitLink,
			eE: produits,
			n5: producteursById,
			n7: produits,
			dL: '',
			bM: A3(
				$author$project$Perfimmo$Input$SelectInput$initSelect,
				'searchByActivite',
				'',
				A2(
					$elm$core$List$map,
					function (c) {
						return _Utils_Tuple2(c, c);
					},
					A2($elm$core$List$map, $author$project$Components$Produits$Common$Classification$classificationToString, $author$project$Components$Produits$Common$Classification$allClassifications))),
			bN: A3(
				$author$project$Perfimmo$Input$SelectInput$initSelect,
				'searchByFamille',
				'',
				A2(
					$elm$core$List$map,
					function (c) {
						return _Utils_Tuple2(c, c);
					},
					A2($elm$core$List$map, $author$project$Components$Produits$Common$Famille$familleToString, $author$project$Components$Produits$Common$Famille$allFamilles))),
			dM: function (values) {
				return A3($inkuzmin$elm_multiselect$Multiselect$initModel, values, 'filtre-producteur', 0);
			}(
				A2(
					$elm$core$List$sortBy,
					$elm$core$Tuple$second,
					$elm$core$Dict$toList(
						A2(
							$elm$core$Dict$map,
							F2(
								function (_v0, produit) {
									return produit.dl;
								}),
							producteursById))))
		};
	});
var $author$project$Components$Produits$List$Model$applyFilters = function (model) {
	var filtrerParFamille = function (produit) {
		return A2(
			$elm$core$Maybe$withDefault,
			true,
			A2(
				$elm_community$maybe_extra$Maybe$Extra$orElse,
				A2(
					$elm$core$Maybe$map,
					function (_v2) {
						return false;
					},
					model.bN.dO),
				A2(
					$elm$core$Maybe$map,
					function (c) {
						return A2(
							$elm$core$Maybe$withDefault,
							true,
							A2(
								$elm$core$Maybe$map,
								function (selectedActivite) {
									return _Utils_eq(
										$author$project$Components$Produits$Common$Famille$familleToString(c),
										selectedActivite);
								},
								model.bN.dO));
					},
					produit.g0)));
	};
	var filtrerParActivite = function (produit) {
		return A2(
			$elm$core$Maybe$withDefault,
			true,
			A2(
				$elm_community$maybe_extra$Maybe$Extra$orElse,
				A2(
					$elm$core$Maybe$map,
					function (_v1) {
						return false;
					},
					model.bM.dO),
				A2(
					$elm$core$Maybe$map,
					function (c) {
						return A2(
							$elm$core$Maybe$withDefault,
							true,
							A2(
								$elm$core$Maybe$map,
								function (selectedActivite) {
									return _Utils_eq(
										$author$project$Components$Produits$Common$Classification$classificationToString(c),
										selectedActivite);
								},
								model.bM.dO));
					},
					produit.f9)));
	};
	var filterByProducteurs = function (produit) {
		var _v0 = A2(
			$elm$core$List$map,
			$elm$core$Tuple$first,
			$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.dM));
		if (!_v0.b) {
			return true;
		} else {
			var producteurs = _v0;
			return A2($elm$core$List$member, produit.i7.kx, producteurs);
		}
	};
	var filterByNom = function (produit) {
		return $author$project$Perfimmo$Search$caseInsensitiveContains(
			{j5: model.dL, kh: produit.dl});
	};
	return _Utils_update(
		model,
		{
			eE: A2(
				$elm$core$List$filter,
				filtrerParFamille,
				A2(
					$elm$core$List$filter,
					filtrerParActivite,
					A2(
						$elm$core$List$filter,
						filterByProducteurs,
						A2($elm$core$List$filter, filterByNom, model.n7))))
		});
};
var $author$project$Components$Produits$List$Model$searchByActivite = F2(
	function (selectAction, model) {
		var selectModel = model.bM;
		var updatedSelect = function () {
			switch (selectAction.$) {
				case 0:
					return $author$project$Perfimmo$Input$SelectInput$toggleDropdown(selectModel);
				case 1:
					var key = selectAction.a;
					return A2(
						$author$project$Perfimmo$Input$SelectInput$selectItem,
						$elm$core$Maybe$Just(key),
						selectModel);
				default:
					return $author$project$Perfimmo$Input$SelectInput$clearSelect(selectModel);
			}
		}();
		return $author$project$Components$Produits$List$Model$applyFilters(
			_Utils_update(
				model,
				{bM: updatedSelect}));
	});
var $author$project$Components$Produits$List$Model$searchByFamille = F2(
	function (selectAction, model) {
		var selectModel = model.bN;
		var updatedSelect = function () {
			switch (selectAction.$) {
				case 0:
					return $author$project$Perfimmo$Input$SelectInput$toggleDropdown(selectModel);
				case 1:
					var key = selectAction.a;
					return A2(
						$author$project$Perfimmo$Input$SelectInput$selectItem,
						$elm$core$Maybe$Just(key),
						selectModel);
				default:
					return $author$project$Perfimmo$Input$SelectInput$clearSelect(selectModel);
			}
		}();
		return $author$project$Components$Produits$List$Model$applyFilters(
			_Utils_update(
				model,
				{bN: updatedSelect}));
	});
var $author$project$Components$Produits$List$Model$searchNoms = F2(
	function (input, model) {
		return $author$project$Components$Produits$List$Model$applyFilters(
			_Utils_update(
				model,
				{dL: input}));
	});
var $author$project$Components$Produits$List$Model$searchProducteurs = F2(
	function (multiselect, model) {
		return $author$project$Components$Produits$List$Model$applyFilters(
			_Utils_update(
				model,
				{dM: multiselect}));
	});
var $author$project$Components$Produits$List$Update$update = F2(
	function (msg, state) {
		var _v0 = _Utils_Tuple2(msg, state);
		if (!_v0.a.$) {
			if (!_v0.b.$) {
				return _Utils_Tuple2(state, $elm$core$Platform$Cmd$none);
			} else {
				switch (_v0.a.a.$) {
					case 0:
						var input = _v0.a.a.a;
						var _v1 = _v0.b;
						var model = _v1.a;
						var listLink = _v1.b;
						return _Utils_Tuple2(
							A2(
								$author$project$Components$Produits$List$Model$Loaded,
								A2($author$project$Components$Produits$List$Model$searchNoms, input, model),
								listLink),
							$elm$core$Platform$Cmd$none);
					case 1:
						var multiselect = _v0.a.a.a;
						var _v2 = _v0.b;
						var model = _v2.a;
						var listLink = _v2.b;
						var _v3 = A2($inkuzmin$elm_multiselect$Multiselect$update, multiselect, model.dM);
						var multiModel = _v3.a;
						var multiCmd = _v3.b;
						return _Utils_Tuple2(
							A2(
								$author$project$Components$Produits$List$Model$Loaded,
								A2($author$project$Components$Produits$List$Model$searchProducteurs, multiModel, model),
								listLink),
							A2(
								$elm$core$Platform$Cmd$map,
								A2($elm$core$Basics$composeL, $author$project$Components$Produits$List$Update$SearchProduits, $author$project$Components$Produits$List$Update$ByProducteur),
								multiCmd));
					case 2:
						var selectAction = _v0.a.a.a;
						var _v4 = _v0.b;
						var model = _v4.a;
						var listLink = _v4.b;
						return _Utils_Tuple2(
							A2(
								$author$project$Components$Produits$List$Model$Loaded,
								A2($author$project$Components$Produits$List$Model$searchByActivite, selectAction, model),
								listLink),
							$elm$core$Platform$Cmd$none);
					default:
						var selectAction = _v0.a.a.a;
						var _v5 = _v0.b;
						var model = _v5.a;
						var listLink = _v5.b;
						return _Utils_Tuple2(
							A2(
								$author$project$Components$Produits$List$Model$Loaded,
								A2($author$project$Components$Produits$List$Model$searchByFamille, selectAction, model),
								listLink),
							$elm$core$Platform$Cmd$none);
				}
			}
		} else {
			if (!_v0.a.a.$) {
				var result = _v0.a.a.a;
				var model = _v0.b;
				var produits = A2($elm$core$List$map, $author$project$Components$Produits$API$ToModel$fromListAPI, result.ok);
				var listProduitLink = $author$project$Components$Produits$List$Model$getListLink(model);
				return _Utils_Tuple2(
					A2(
						$author$project$Components$Produits$List$Model$Loaded,
						A3($author$project$Components$Produits$List$Model$init, result.nf, result.n5, produits),
						listProduitLink),
					$elm$core$Platform$Cmd$none);
			} else {
				var model = _v0.b;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$Components$Supports$Details$Model$MustCompliance = 1;
var $author$project$Components$Supports$API$ToModel$categorieActifToString = function (categorie) {
	switch (categorie) {
		case 0:
			return 'Fonds actions';
		case 1:
			return 'Fonds obligations';
		case 2:
			return 'Fonds mixtes';
		case 3:
			return 'Fonds immobiliers';
		case 4:
			return 'Fonds spéculatifs';
		case 5:
			return 'Fonds de capital investissement';
		case 6:
			return 'Fonds monétaires';
		case 7:
			return 'Fonds euros';
		case 8:
			return 'Fonds structurés';
		case 9:
			return 'Autres';
		default:
			return 'Inconnu';
	}
};
var $author$project$Components$Supports$API$ToModel$fromDetailAPI = function (api) {
	return {
		nf: api.nf,
		n7: A2(
			$elm$core$List$map,
			function (p) {
				return {
					hB: p.hB,
					dl: p.dl,
					i7: p.i7,
					jZ: A2($elm$core$Maybe$map, $author$project$Components$Produits$Common$Famille$familleToString, p.jZ)
				};
			},
			api.mX),
		oF: {
			lp: A2($elm$core$Maybe$map, $author$project$Components$Supports$API$ToModel$categorieActifToString, api.lp),
			mx: api.mx,
			nd: api.nz,
			nZ: api.nY,
			n$: api.n_,
			ov: api.ov,
			oE: api.oE
		}
	};
};
var $author$project$Components$Supports$Details$Documents$Update$DocumentsFetched = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$API$DocumentAPIView$GetDocumentsResults = F3(
	function (result, allTags, links) {
		return {kX: allTags, nf: links, ok: result};
	});
var $author$project$Components$Supports$API$DocumentAPIView$GetDocumentMetadata = F8(
	function (uid, nom, filename, dateDeProduction, dateDePublication, tags, documentTypes, links) {
		return {lH: dateDeProduction, lI: dateDePublication, l1: documentTypes, mp: filename, nf: links, dl: nom, oK: tags, kx: uid};
	});
var $author$project$Components$Supports$API$DocumentAPIView$Annexe = 0;
var $author$project$Components$Supports$API$DocumentAPIView$DIC = 1;
var $author$project$Components$Supports$API$DocumentAPIView$documentTypeDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'Annexe',
			$elm$json$Json$Decode$succeed(0)),
			A2(
			$elm$json$Json$Decode$field,
			'DIC',
			$elm$json$Json$Decode$succeed(1))
		]));
var $author$project$Components$Supports$API$DocumentAPIView$getDocumentMetadataDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'documentTypes',
		$elm$json$Json$Decode$list($author$project$Components$Supports$API$DocumentAPIView$documentTypeDecoder),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'tags',
			$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'dateDePublication',
				$rtfeldman$elm_iso8601_date_strings$Iso8601$decoder,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'dateDeProduction',
					$elm$json$Json$Decode$nullable(
						A2(
							$elm$json$Json$Decode$andThen,
							function (raw) {
								var _v0 = $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime(raw);
								if (!_v0.$) {
									var posix = _v0.a;
									return $elm$json$Json$Decode$succeed(posix);
								} else {
									return $elm$json$Json$Decode$succeed(
										$elm$time$Time$millisToPosix(0));
								}
							},
							$elm$json$Json$Decode$string)),
					$elm$core$Maybe$Nothing,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'filename',
						$elm$json$Json$Decode$string,
						A3(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
							'nom',
							$elm$json$Json$Decode$string,
							A3(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
								'uid',
								$elm$json$Json$Decode$string,
								$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$DocumentAPIView$GetDocumentMetadata)))))))));
var $author$project$Components$Supports$API$DocumentAPIView$getDocumentResultsDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'_links',
	$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder),
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'allTags',
		$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'result',
			$elm$json$Json$Decode$list($author$project$Components$Supports$API$DocumentAPIView$getDocumentMetadataDecoder),
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$DocumentAPIView$GetDocumentsResults))));
var $author$project$Components$Supports$API$Requests$fetchDocuments = function (link) {
	return A3(
		$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
		link,
		$elm$http$Http$emptyBody,
		$elm$http$Http$expectJson($elm$core$Basics$identity)($author$project$Components$Supports$API$DocumentAPIView$getDocumentResultsDecoder));
};
var $author$project$Components$Supports$Details$Documents$Update$init = F2(
	function (timezone, getDocumentsLink) {
		var effect = A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Components$Supports$API$Requests$fetchDocuments,
					$elm$core$Platform$Cmd$map($author$project$Components$Supports$Details$Documents$Update$DocumentsFetched)),
				getDocumentsLink));
		return _Utils_Tuple2(
			A2($author$project$Components$Supports$Details$Documents$Model$init, timezone, getDocumentsLink),
			effect);
	});
var $author$project$Components$Supports$Details$Model$selectTab = F2(
	function (tabId, model) {
		return _Utils_update(
			model,
			{e6: tabId});
	});
var $author$project$Components$Supports$Details$Model$setDocumentData = F2(
	function (support, updatedDocumentModel) {
		return _Utils_update(
			support,
			{bn: updatedDocumentModel});
	});
var $author$project$Components$Supports$Details$Model$setSupport = F2(
	function (support, model) {
		return _Utils_update(
			model,
			{
				m9: support.oF.nd,
				aG: false,
				fd: A4(
					$author$project$Components$Supports$Details$Model$initForm,
					$elm$core$Maybe$Just(support.oF),
					support.n7,
					model.aO,
					model.eo)
			});
	});
var $author$project$Components$Supports$Details$Documents$Update$DeleteAction = function (a) {
	return {$: 15, a: a};
};
var $author$project$Components$Supports$Details$Documents$Update$DocumentMetadataUpdated = function (a) {
	return {$: 17, a: a};
};
var $author$project$Components$Supports$Details$Documents$Update$DrawerEvents = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Supports$Details$Documents$Update$FileSelected = function (a) {
	return {$: 11, a: a};
};
var $author$project$Components$Supports$Details$Documents$Update$Uploaded = function (a) {
	return {$: 13, a: a};
};
var $author$project$Components$Supports$Details$Documents$Update$acceptOnlyPdf = _List_fromArray(
	['application/pdf']);
var $author$project$Components$Supports$Details$Documents$Model$asDrawerModelIn = F2(
	function (model, drawer) {
		return _Utils_update(
			model,
			{ep: drawer});
	});
var $author$project$Components$Supports$API$ToModel$fromDocumentMetadataAPI = function (apiDoc) {
	return {
		lH: apiDoc.lH,
		lI: apiDoc.lI,
		lP: A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'DeleteDocument', apiDoc.nf),
		l6: A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'DownloadDocument', apiDoc.nf),
		mp: apiDoc.mp,
		m3: A2(
			$elm$core$List$any,
			$elm$core$Basics$eq(0),
			apiDoc.l1),
		m4: A2(
			$elm$core$List$any,
			$elm$core$Basics$eq(1),
			apiDoc.l1),
		m9: apiDoc.dl,
		oK: apiDoc.oK,
		kx: apiDoc.kx,
		fp: A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'UpdateDocument', apiDoc.nf)
	};
};
var $author$project$Components$Supports$Details$Documents$Update$getFormTags = function (model) {
	return A2(
		$elm$core$List$filterMap,
		$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$fromString,
		A2(
			$elm$core$List$map,
			$elm$core$Tuple$second,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				A2(
					$elm$core$Maybe$map,
					$inkuzmin$elm_multiselect$Multiselect$getSelectedValues,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.oK;
						},
						$author$project$Components$Supports$Details$Documents$Model$getEditModel(model))))));
};
var $author$project$Components$Supports$Details$Documents$Update$getFormTypes = function (model) {
	return A2(
		$elm$core$List$filterMap,
		function (_v0) {
			var keyVal = _v0.a;
			var enabled = _v0.b;
			return enabled ? $elm$core$Maybe$Just(keyVal.b) : $elm$core$Maybe$Nothing;
		},
		A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Dict$toList,
				A2(
					$elm$core$Maybe$andThen,
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.kw;
						},
						$author$project$Components$Supports$Details$Documents$Model$getEditModel(model))))));
};
var $author$project$Components$Supports$Details$Documents$Model$CreateMode = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Supports$Details$Documents$Model$Create = 0;
var $author$project$Components$Supports$Details$Documents$Model$initCreateMode = F2(
	function (tags, forbiddenTitles) {
		return {
			ek: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$addInfo, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty),
			lK: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty,
			l9: $elm$core$Maybe$Nothing,
			ew: $elm$core$Maybe$Nothing,
			mu: forbiddenTitles,
			aG: false,
			dj: 0,
			dl: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$addInfo, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$Common$FieldIsMandatory, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty),
			oK: A3($inkuzmin$elm_multiselect$Multiselect$initModel, tags, 'documentTags', 0),
			kw: A2(
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
				$elm$core$Dict$fromList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							_Utils_Tuple2('Annexe', 'Annexe'),
							false),
							_Utils_Tuple2(
							_Utils_Tuple2('DIC', 'DIC'),
							false)
						])),
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty)
		};
	});
var $author$project$Components$Supports$Details$Documents$Model$setDrawerModel = F2(
	function (drawer, model) {
		return _Utils_update(
			model,
			{ep: drawer});
	});
var $author$project$Components$Supports$Details$Documents$Model$openEditDrawer = $author$project$Components$Supports$Details$Documents$Model$setDrawerModel($author$project$Components$Drawer$Model$open);
var $author$project$Components$Supports$Details$Documents$Model$setEditModel = F2(
	function (editMode, model) {
		return _Utils_update(
			model,
			{_: editMode});
	});
var $author$project$Components$Supports$Details$Documents$Model$openCreateDrawer = function (model) {
	var forbiddenTitles = A2(
		$elm$core$List$map,
		function ($) {
			return $.m9;
		},
		model.bn);
	var createMode = A2($author$project$Components$Supports$Details$Documents$Model$initCreateMode, model.ce, forbiddenTitles);
	var createModel = $author$project$Components$Supports$Details$Documents$Model$openEditDrawer(
		A2(
			$author$project$Components$Supports$Details$Documents$Model$setEditModel,
			$author$project$Components$Supports$Details$Documents$Model$CreateMode(createMode),
			model));
	return createModel;
};
var $author$project$Components$Supports$Details$Documents$Model$Edit = 1;
var $author$project$Components$Supports$Details$Documents$Model$UpdateMode = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Components$Supports$Details$Documents$Model$openUpdateDrawer = F2(
	function (doc, model) {
		var selectedTags = A2(
			$elm$core$List$map,
			function (t) {
				return _Utils_Tuple2(t, t);
			},
			doc.oK);
		var forbiddenTitles = A2(
			$elm$core$List$filter,
			function (title) {
				return !_Utils_eq(title, doc.m9);
			},
			A2(
				$elm$core$List$map,
				function ($) {
					return $.m9;
				},
				model.bn));
		var createMode = A2($author$project$Components$Supports$Details$Documents$Model$initCreateMode, model.ce, forbiddenTitles);
		var unselectedTags = A2(
			$elm$core$List$filter,
			function (tag) {
				return !A2($elm$core$List$member, tag, selectedTags);
			},
			$inkuzmin$elm_multiselect$Multiselect$getValues(createMode.oK));
		var allTags = $elm$core$List$concat(
			_List_fromArray(
				[selectedTags, unselectedTags]));
		var updateMode = _Utils_update(
			createMode,
			{
				ek: A2(
					$elm$core$Maybe$withDefault,
					createMode.ek,
					A2(
						$elm$core$Maybe$map,
						function (dateProduction) {
							return A2(
								$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
								A2($justinmimbs$date$Date$fromPosix, model.aO, dateProduction),
								createMode.ek);
						},
						doc.lH)),
				lK: A2(
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
					A2($justinmimbs$date$Date$fromPosix, model.aO, doc.lI),
					createMode.lK),
				dj: 1,
				dl: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, doc.m9, createMode.dl),
				oK: A3($inkuzmin$elm_multiselect$Multiselect$populateValues, createMode.oK, allTags, selectedTags),
				kw: A2(
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
					$elm$core$Dict$fromList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								_Utils_Tuple2('Annexe', 'Annexe'),
								doc.m3),
								_Utils_Tuple2(
								_Utils_Tuple2('DIC', 'DIC'),
								doc.m4)
							])),
					createMode.kw)
			});
		var editModel = $author$project$Components$Supports$Details$Documents$Model$openEditDrawer(
			A2(
				$author$project$Components$Supports$Details$Documents$Model$setEditModel,
				A2($author$project$Components$Supports$Details$Documents$Model$UpdateMode, doc, updateMode),
				model));
		return editModel;
	});
var $author$project$Components$Supports$Details$Documents$Model$setDocumentEditModel = F2(
	function (updatedEditModel, model) {
		var _v0 = model._;
		switch (_v0.$) {
			case 0:
				return model;
			case 1:
				return _Utils_update(
					model,
					{
						_: $author$project$Components$Supports$Details$Documents$Model$CreateMode(updatedEditModel)
					});
			default:
				var document = _v0.a;
				return _Utils_update(
					model,
					{
						_: A2($author$project$Components$Supports$Details$Documents$Model$UpdateMode, document, updatedEditModel)
					});
		}
	});
var $author$project$Components$Supports$Details$Documents$Model$dateCompare = F3(
	function (date, timezone, otherDate) {
		return A2(
			$elm$core$Maybe$map,
			function (d) {
				return A2(
					$justinmimbs$date$Date$compare,
					A2($justinmimbs$date$Date$fromPosix, timezone, date),
					d);
			},
			otherDate);
	});
var $author$project$Components$Supports$Details$Documents$Model$appliquerLesFiltres = function (model) {
	var filtres = model.aY;
	var filtreParTags = $inkuzmin$elm_multiselect$Multiselect$getSelectedValues(filtres.cu);
	var filtreParNom = filtres.dp;
	var dateDePublicationMustBeBefore = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(filtres.bJ);
	var dateDePublicationMustBeAfter = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(filtres.bI);
	var dateDeProductionMustBeBefore = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(filtres.bH);
	var dateDeProductionMustBeAfter = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(filtres.bG);
	var filteredDocuments = A2(
		$elm$core$List$filter,
		function (doc) {
			var _v15 = A3($author$project$Components$Supports$Details$Documents$Model$dateCompare, doc.lI, model.aO, dateDePublicationMustBeBefore);
			if (!_v15.$) {
				switch (_v15.a) {
					case 0:
						var _v16 = _v15.a;
						return true;
					case 1:
						var _v17 = _v15.a;
						return true;
					default:
						var _v18 = _v15.a;
						return false;
				}
			} else {
				return true;
			}
		},
		A2(
			$elm$core$List$filter,
			function (doc) {
				var _v11 = A3($author$project$Components$Supports$Details$Documents$Model$dateCompare, doc.lI, model.aO, dateDePublicationMustBeAfter);
				if (!_v11.$) {
					switch (_v11.a) {
						case 0:
							var _v12 = _v11.a;
							return false;
						case 1:
							var _v13 = _v11.a;
							return true;
						default:
							var _v14 = _v11.a;
							return true;
					}
				} else {
					return true;
				}
			},
			A2(
				$elm$core$List$filter,
				function (doc) {
					return A2(
						$elm$core$Maybe$withDefault,
						A2(
							$elm$core$Maybe$withDefault,
							true,
							A2(
								$elm$core$Maybe$map,
								function (_v10) {
									return false;
								},
								dateDeProductionMustBeBefore)),
						A2(
							$elm$core$Maybe$map,
							function (dateProduction) {
								var _v6 = A3($author$project$Components$Supports$Details$Documents$Model$dateCompare, dateProduction, model.aO, dateDeProductionMustBeBefore);
								if (!_v6.$) {
									switch (_v6.a) {
										case 0:
											var _v7 = _v6.a;
											return true;
										case 1:
											var _v8 = _v6.a;
											return true;
										default:
											var _v9 = _v6.a;
											return false;
									}
								} else {
									return true;
								}
							},
							doc.lH));
				},
				A2(
					$elm$core$List$filter,
					function (doc) {
						return A2(
							$elm$core$Maybe$withDefault,
							A2(
								$elm$core$Maybe$withDefault,
								true,
								A2(
									$elm$core$Maybe$map,
									function (_v5) {
										return false;
									},
									dateDeProductionMustBeAfter)),
							A2(
								$elm$core$Maybe$map,
								function (dateProduction) {
									var _v1 = A3($author$project$Components$Supports$Details$Documents$Model$dateCompare, dateProduction, model.aO, dateDeProductionMustBeAfter);
									if (!_v1.$) {
										switch (_v1.a) {
											case 0:
												var _v2 = _v1.a;
												return false;
											case 1:
												var _v3 = _v1.a;
												return true;
											default:
												var _v4 = _v1.a;
												return true;
										}
									} else {
										return true;
									}
								},
								doc.lH));
					},
					A2(
						$elm$core$List$filter,
						function (doc) {
							return model.aY.bo ? doc.m4 : true;
						},
						A2(
							$elm$core$List$filter,
							function (doc) {
								return model.aY.cg ? doc.m3 : true;
							},
							A2(
								$elm$core$List$filter,
								function (doc) {
									return $elm$core$List$isEmpty(filtreParTags) ? true : A2(
										$elm$core$List$any,
										function (_v0) {
											var tag = _v0.a;
											return A2(
												$elm$core$List$any,
												function (docTag) {
													return _Utils_eq(docTag, tag);
												},
												doc.oK);
										},
										filtreParTags);
								},
								A2(
									$elm$core$List$filter,
									function (doc) {
										return A2(
											$elm$core$String$contains,
											$elm$core$String$toLower(filtreParNom),
											$elm$core$String$toLower(doc.m9));
									},
									model.bn))))))));
	return _Utils_update(
		model,
		{eB: filteredDocuments});
};
var $author$project$Components$Supports$Details$Documents$Model$setDocuments = F3(
	function (documents, documentTags, model) {
		var tags = A2(
			$elm$core$List$map,
			function (t) {
				return _Utils_Tuple2(t, t);
			},
			documentTags);
		var tagsInList = A2(
			$elm$core$List$filter,
			function (t) {
				return A2(
					$elm$core$List$member,
					t.a,
					A2(
						$ccapndave$elm_flat_map$List$FlatMap$flatMap,
						function ($) {
							return $.oK;
						},
						documents));
			},
			tags);
		return $author$project$Components$Supports$Details$Documents$Model$appliquerLesFiltres(
			_Utils_update(
				model,
				{
					ce: tags,
					bn: A2(
						$elm$core$List$sortWith,
						F2(
							function (d1, d2) {
								return (_Utils_cmp(
									$elm$core$String$toLower(d1.m9),
									$elm$core$String$toLower(d2.m9)) > 0) ? 2 : ((_Utils_cmp(
									$elm$core$String$toLower(d1.m9),
									$elm$core$String$toLower(d2.m9)) < 0) ? 0 : 1);
							}),
						documents),
					aY: $author$project$Components$Supports$Details$Documents$Model$initFiltres(tagsInList)
				}));
	});
var $author$project$Components$Supports$Details$Documents$Model$setErrorMessage = F2(
	function (message, model) {
		return _Utils_update(
			model,
			{l9: message});
	});
var $author$project$Components$Supports$Details$Documents$Model$updateEditModel = F2(
	function (update, model) {
		var _v0 = model._;
		switch (_v0.$) {
			case 0:
				return model;
			case 1:
				var documentEditModel = _v0.a;
				return _Utils_update(
					model,
					{
						_: $author$project$Components$Supports$Details$Documents$Model$CreateMode(
							update(documentEditModel))
					});
			default:
				var document = _v0.a;
				var documentEditModel = _v0.b;
				return _Utils_update(
					model,
					{
						_: A2(
							$author$project$Components$Supports$Details$Documents$Model$UpdateMode,
							document,
							update(documentEditModel))
					});
		}
	});
var $author$project$Components$Supports$Details$Documents$Model$setFile = function (file) {
	return $author$project$Components$Supports$Details$Documents$Model$updateEditModel(
		function (model) {
			return _Utils_update(
				model,
				{
					ew: $elm$core$Maybe$Just(file)
				});
		});
};
var $author$project$Components$Supports$Details$Documents$Model$Annexe = 0;
var $author$project$Components$Supports$Details$Documents$Model$DIC = 1;
var $author$project$Components$Supports$Details$Documents$Model$setFiltre = F2(
	function (filtre, model) {
		var filtres = model.aY;
		var _v0 = function () {
			switch (filtre.$) {
				case 0:
					var search = filtre.a;
					return _Utils_Tuple2(
						_Utils_update(
							filtres,
							{dp: search}),
						$elm$core$Platform$Cmd$none);
				case 1:
					var msg = filtre.a;
					var _v2 = A2($inkuzmin$elm_multiselect$Multiselect$update, msg, filtres.cu);
					var m = _v2.a;
					var c = _v2.b;
					return _Utils_Tuple2(
						_Utils_update(
							filtres,
							{cu: m}),
						A2($elm$core$Platform$Cmd$map, $author$project$Components$Supports$Details$Documents$Model$FiltrerParTags, c));
				case 2:
					var typeDoc = filtre.a;
					return _Utils_Tuple2(
						_Utils_update(
							filtres,
							{
								bo: (typeDoc === 1) ? (!filtres.bo) : filtres.bo,
								cg: (!typeDoc) ? (!filtres.cg) : filtres.cg
							}),
						$elm$core$Platform$Cmd$none);
				case 3:
					var date = filtre.a;
					var updatedFiltres = A2(
						$elm$core$Result$withDefault,
						_Utils_update(
							filtres,
							{bG: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty}),
						A2(
							$elm$core$Result$map,
							function (d) {
								return _Utils_update(
									filtres,
									{
										bG: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, d, filtres.bG)
									});
							},
							$justinmimbs$date$Date$fromIsoString(date)));
					return _Utils_Tuple2(updatedFiltres, $elm$core$Platform$Cmd$none);
				case 4:
					var date = filtre.a;
					var updatedFiltres = A2(
						$elm$core$Result$withDefault,
						_Utils_update(
							filtres,
							{bH: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty}),
						A2(
							$elm$core$Result$map,
							function (d) {
								return _Utils_update(
									filtres,
									{
										bH: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, d, filtres.bH)
									});
							},
							$justinmimbs$date$Date$fromIsoString(date)));
					return _Utils_Tuple2(updatedFiltres, $elm$core$Platform$Cmd$none);
				case 5:
					var date = filtre.a;
					var updatedFiltres = A2(
						$elm$core$Result$withDefault,
						_Utils_update(
							filtres,
							{bI: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty}),
						A2(
							$elm$core$Result$map,
							function (d) {
								return _Utils_update(
									filtres,
									{
										bI: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, d, filtres.bI)
									});
							},
							$justinmimbs$date$Date$fromIsoString(date)));
					return _Utils_Tuple2(updatedFiltres, $elm$core$Platform$Cmd$none);
				default:
					var date = filtre.a;
					var updatedFiltres = A2(
						$elm$core$Result$withDefault,
						_Utils_update(
							filtres,
							{bJ: $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$empty}),
						A2(
							$elm$core$Result$map,
							function (d) {
								return _Utils_update(
									filtres,
									{
										bJ: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, d, filtres.bJ)
									});
							},
							$justinmimbs$date$Date$fromIsoString(date)));
					return _Utils_Tuple2(updatedFiltres, $elm$core$Platform$Cmd$none);
			}
		}();
		var newFiltres = _v0.a;
		var effect = _v0.b;
		var updatedModel = $author$project$Components$Supports$Details$Documents$Model$appliquerLesFiltres(
			_Utils_update(
				model,
				{aY: newFiltres}));
		return _Utils_Tuple2(updatedModel, effect);
	});
var $author$project$Components$Supports$Details$Documents$Model$setLoading = F2(
	function (isUploading, model) {
		return _Utils_update(
			model,
			{aG: isUploading});
	});
var $author$project$Components$Supports$Details$Documents$Model$setUploadLink = F2(
	function (link, model) {
		return _Utils_update(
			model,
			{fq: link});
	});
var $author$project$Components$Supports$API$UpdateDocument$encodeDocType = function (docType) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				docType,
				$elm$json$Json$Encode$object(_List_Nil))
			]));
};
var $author$project$Components$Supports$API$UpdateDocument$encodeUpdateDocumentData = function (updateData) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'nom',
				$elm$json$Json$Encode$string(
					$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$toString(updateData.dl))),
				_Utils_Tuple2(
				'dateDeProduction',
				$elm$json$Json$Encode$string(
					$justinmimbs$date$Date$toIsoString(updateData.lH))),
				_Utils_Tuple2(
				'tags',
				A2(
					$elm$json$Json$Encode$list,
					$elm$json$Json$Encode$string,
					A2($elm$core$List$map, $PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$toString, updateData.oK))),
				_Utils_Tuple2(
				'documentTypes',
				A2($elm$json$Json$Encode$list, $author$project$Components$Supports$API$UpdateDocument$encodeDocType, updateData.kw))
			]));
};
var $author$project$Components$Supports$API$Requests$updateDocument = F2(
	function (link, params) {
		var body = $elm$http$Http$jsonBody(
			$author$project$Components$Supports$API$UpdateDocument$encodeUpdateDocumentData(params));
		return A3(
			$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
			link,
			body,
			$elm$http$Http$expectJson($elm$core$Basics$identity)($author$project$Perfimmo$Api$CRUDResultWithFeedback$crudResultWithFeedbackJsonDecoder));
	});
var $author$project$Components$Supports$API$Errors$UploadDocumentError$ProduitNotExist = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$API$Errors$UploadDocumentError$fileUploadErrorsDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'ProduitNotExist',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'produitUid',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$UploadDocumentError$ProduitNotExist)))
		]));
var $author$project$Components$Supports$API$Requests$uploadFile = F2(
	function (params, msg) {
		var body = $elm$http$Http$multipartBody(
			_Utils_ap(
				_List_fromArray(
					[
						A2($elm$http$Http$filePart, 'file', params.ew),
						A2(
						$elm$http$Http$stringPart,
						'nom',
						$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$toString(params.dl)),
						A2(
						$elm$http$Http$stringPart,
						'filename',
						$elm$file$File$name(params.ew)),
						A2(
						$elm$http$Http$stringPart,
						'dateDeProduction',
						$justinmimbs$date$Date$toIsoString(params.lH))
					]),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$elm$http$Http$stringPart('documentTypes'),
						params.kw),
					A2(
						$elm$core$List$map,
						$elm$http$Http$stringPart('tags'),
						A2($elm$core$List$map, $PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$toString, params.oK)))));
		return A3(
			$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
			params.hX,
			body,
			A2($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$expectWhateverOkResult, msg, $author$project$Components$Supports$API$Errors$UploadDocumentError$fileUploadErrorsDecoder));
	});
var $author$project$Components$Supports$Details$Documents$Update$update = F2(
	function (model, msg) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var result = msg.a.a;
					var maybeUploadLink = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'UploadDocument', result.nf);
					return _Utils_Tuple2(
						A3(
							$author$project$Components$Supports$Details$Documents$Model$setDocuments,
							A2($elm$core$List$map, $author$project$Components$Supports$API$ToModel$fromDocumentMetadataAPI, result.ok),
							result.kX,
							A2($author$project$Components$Supports$Details$Documents$Model$setUploadLink, maybeUploadLink, model)),
						$elm$core$Platform$Cmd$none);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 1:
				var filtre = msg.a;
				return A2(
					$elm$core$Tuple$mapSecond,
					$elm$core$Platform$Cmd$map($author$project$Components$Supports$Details$Documents$Update$Filtrer),
					A2($author$project$Components$Supports$Details$Documents$Model$setFiltre, filtre, model));
			case 2:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(
					$author$project$Components$Supports$Details$Documents$Model$openCreateDrawer(model),
					$elm$core$Platform$Cmd$none);
			case 4:
				var doc = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Components$Supports$Details$Documents$Model$openUpdateDrawer, doc, model),
					$elm$core$Platform$Cmd$none);
			case 5:
				var drawerMsg = msg.a;
				var _v1 = A3(
					$elm$core$Tuple$mapBoth,
					$author$project$Components$Supports$Details$Documents$Model$asDrawerModelIn(model),
					$elm$core$Platform$Cmd$map($author$project$Components$Supports$Details$Documents$Update$DrawerEvents),
					A2($author$project$Components$Drawer$Update$update, model.ep, drawerMsg));
				var updatedModel = _v1.a;
				var cmd = _v1.b;
				if (!drawerMsg.$) {
					return _Utils_Tuple2(updatedModel, cmd);
				} else {
					var wrapperMsg = drawerMsg.a;
					return A2(
						$elm$core$Tuple$mapSecond,
						function (cmd1) {
							return $elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[cmd1, cmd]));
						},
						A2($author$project$Components$Supports$Details$Documents$Update$update, updatedModel, wrapperMsg));
				}
			case 6:
				var value = msg.a;
				return ($elm$core$String$trim(value) === '') ? _Utils_Tuple2(
					A2(
						$author$project$Components$Supports$Details$Documents$Model$updateEditModel,
						function (editModel) {
							return _Utils_update(
								editModel,
								{
									dl: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setOptionalValue, $elm$core$Maybe$Nothing, editModel.dl)
								});
						},
						model),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					A2(
						$author$project$Components$Supports$Details$Documents$Model$updateEditModel,
						function (editModel) {
							return _Utils_update(
								editModel,
								{
									dl: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, value, editModel.dl)
								});
						},
						model),
					$elm$core$Platform$Cmd$none);
			case 7:
				var value = msg.a;
				return _Utils_Tuple2(
					A2(
						$author$project$Components$Supports$Details$Documents$Model$updateEditModel,
						function (editModel) {
							return _Utils_update(
								editModel,
								{
									ek: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDate$setRawDate, value, editModel.ek)
								});
						},
						model),
					$elm$core$Platform$Cmd$none);
			case 8:
				var multiselectMsg = msg.a;
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(model, $elm$core$Platform$Cmd$none),
					A2(
						$elm$core$Maybe$map,
						function (editModel) {
							var _v3 = A2($inkuzmin$elm_multiselect$Multiselect$update, multiselectMsg, editModel.oK);
							var editedTags = _v3.a;
							var multiSelectCmd = _v3.b;
							var editedDocumentModel = _Utils_update(
								editModel,
								{oK: editedTags});
							var editedModel = A2($author$project$Components$Supports$Details$Documents$Model$setDocumentEditModel, editedDocumentModel, model);
							var multiSelectAction = A2($elm$core$Platform$Cmd$map, $author$project$Components$Supports$Details$Documents$Update$SetTags, multiSelectCmd);
							return _Utils_Tuple2(editedModel, multiSelectAction);
						},
						$author$project$Components$Supports$Details$Documents$Model$getEditModel(model)));
			case 9:
				var types = msg.a;
				var updatedModel = A2(
					$author$project$Components$Supports$Details$Documents$Model$updateEditModel,
					function (editModel) {
						return _Utils_update(
							editModel,
							{
								kw: A2($PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue, types, editModel.kw)
							});
					},
					model);
				return _Utils_Tuple2(updatedModel, $elm$core$Platform$Cmd$none);
			case 10:
				var effect = A2($elm$file$File$Select$file, $author$project$Components$Supports$Details$Documents$Update$acceptOnlyPdf, $author$project$Components$Supports$Details$Documents$Update$FileSelected);
				return _Utils_Tuple2(model, effect);
			case 11:
				var file = msg.a;
				var modelWithFile = A2($author$project$Components$Supports$Details$Documents$Model$setFile, file, model);
				var modelWithFilenameSetAsName = A2(
					$author$project$Components$Supports$Details$Documents$Model$updateEditModel,
					function (editModel) {
						return _Utils_update(
							editModel,
							{
								dl: A2(
									$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$setValue,
									A2(
										$elm$core$Maybe$withDefault,
										$elm$file$File$name(file),
										A2(
											$elm$core$Maybe$map,
											function (existing) {
												return ($elm$core$String$trim(existing) === '') ? $elm$file$File$name(file) : existing;
											},
											$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(editModel.dl))),
									editModel.dl)
							});
					},
					modelWithFile);
				return _Utils_Tuple2(modelWithFilenameSetAsName, $elm$core$Platform$Cmd$none);
			case 12:
				var link = msg.a;
				var file = msg.b;
				var modelSetAsUploading = A2(
					$author$project$Components$Supports$Details$Documents$Model$updateEditModel,
					function (editModel) {
						return A2($author$project$Components$Supports$Details$Documents$Model$setLoading, true, editModel);
					},
					model);
				var maybeNom = A2(
					$elm$core$Maybe$andThen,
					$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$fromString,
					A2(
						$elm$core$Maybe$andThen,
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue,
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.dl;
							},
							$author$project$Components$Supports$Details$Documents$Model$getEditModel(model))));
				var maybeDateDeProduction = A2(
					$elm$core$Maybe$andThen,
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.ek;
						},
						$author$project$Components$Supports$Details$Documents$Model$getEditModel(model)));
				var uploadFileParams = A3(
					$elm$core$Maybe$map2,
					F2(
						function (nom, dateDeProduction) {
							return {
								lH: dateDeProduction,
								ew: file,
								hX: link,
								dl: nom,
								oK: A2(
									$elm$core$List$filterMap,
									$elm$core$Basics$identity,
									A2(
										$elm$core$List$map,
										$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$fromString,
										A2(
											$elm$core$List$map,
											$elm$core$String$toLower,
											A2(
												$elm$core$List$map,
												$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$toString,
												$author$project$Components$Supports$Details$Documents$Update$getFormTags(model))))),
								kw: $author$project$Components$Supports$Details$Documents$Update$getFormTypes(model)
							};
						}),
					maybeNom,
					maybeDateDeProduction);
				var doUpload = function (params) {
					return A2($author$project$Components$Supports$API$Requests$uploadFile, params, $author$project$Components$Supports$Details$Documents$Update$Uploaded);
				};
				if (!uploadFileParams.$) {
					var params = uploadFileParams.a;
					return _Utils_Tuple2(
						modelSetAsUploading,
						doUpload(params));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 13:
				if (!msg.a.$) {
					return A2($author$project$Components$Supports$Details$Documents$Update$init, model.aO, model.g2);
				} else {
					var e = msg.a.a;
					var modelSetAsNonUploading = A2(
						$author$project$Components$Supports$Details$Documents$Model$updateEditModel,
						$author$project$Components$Supports$Details$Documents$Model$setLoading(false),
						model);
					var errorMessage = function () {
						if (!e.$) {
							var err = e.a;
							return 'Erreur lors de l\'envoi du fichier... [' + (err + ']');
						} else {
							var _v6 = e.a;
							var metadata = _v6.a;
							var a = _v6.b;
							if (!a.$) {
								var produitId = a.a;
								return 'Impossible d\'envoyer le fichier, le produit [' + (produitId + '] n\'existe pas');
							} else {
								return 'Erreur technique (' + ($elm$core$String$fromInt(metadata.oy) + (':' + (metadata.oz + ') lors de l\'envoi du fichier ')));
							}
						}
					}();
					var modelSetWithError = A2(
						$author$project$Components$Supports$Details$Documents$Model$updateEditModel,
						$author$project$Components$Supports$Details$Documents$Model$setErrorMessage(
							$elm$core$Maybe$Just(errorMessage)),
						modelSetAsNonUploading);
					return _Utils_Tuple2(modelSetWithError, $elm$core$Platform$Cmd$none);
				}
			case 14:
				var link = msg.a;
				var confirmDrawer = A4(
					$author$project$Components$Drawer$ConfirmationDrawer$init,
					link,
					$elm$http$Http$emptyBody,
					'Erreur lors de la tentative de suppression',
					A2($elm$http$Http$expectJson, $author$project$Components$Drawer$ConfirmationDrawer$callback, $author$project$Perfimmo$Api$CRUDResultWithFeedback$crudResultWithFeedbackJsonDecoder));
				return A3(
					$elm$core$Tuple$mapBoth,
					function (dm) {
						return _Utils_update(
							model,
							{
								lQ: $elm$core$Maybe$Just(dm)
							});
					},
					$elm$core$Platform$Cmd$map($author$project$Components$Supports$Details$Documents$Update$DeleteAction),
					confirmDrawer);
			case 15:
				var deletionMsg = msg.a;
				var _v8 = $author$project$Components$Drawer$ConfirmationDrawer$requestIsDone(deletionMsg);
				if ((!_v8.$) && (!_v8.a.$)) {
					return A2($author$project$Components$Supports$Details$Documents$Update$init, model.aO, model.g2);
				} else {
					return A3(
						$elm$core$Tuple$mapBoth,
						function (newDeleteModel) {
							return _Utils_update(
								model,
								{lQ: newDeleteModel});
						},
						$elm$core$Platform$Cmd$map($author$project$Components$Supports$Details$Documents$Update$DeleteAction),
						A2($author$project$Components$Drawer$ConfirmationDrawer$update, model.lQ, deletionMsg));
				}
			case 16:
				var link = msg.a;
				var modelSetAsUpdating = A2(
					$author$project$Components$Supports$Details$Documents$Model$updateEditModel,
					function (editModel) {
						return A2($author$project$Components$Supports$Details$Documents$Model$setLoading, true, editModel);
					},
					model);
				var maybeNom = A2(
					$elm$core$Maybe$andThen,
					$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$fromString,
					A2(
						$elm$core$Maybe$andThen,
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue,
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.dl;
							},
							$author$project$Components$Supports$Details$Documents$Model$getEditModel(model))));
				var maybeDateDeProduction = A2(
					$elm$core$Maybe$andThen,
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.ek;
						},
						$author$project$Components$Supports$Details$Documents$Model$getEditModel(model)));
				var updateParams = A3(
					$elm$core$Maybe$map2,
					F2(
						function (nom, dateDeProduction) {
							return {
								lH: dateDeProduction,
								dl: nom,
								oK: A2(
									$elm$core$List$filterMap,
									$elm$core$Basics$identity,
									A2(
										$elm$core$List$map,
										$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$fromString,
										A2(
											$elm$core$List$map,
											$elm$core$String$toLower,
											A2(
												$elm$core$List$map,
												$PerformanceIMMO$elm_utils$Perfimmo$Primitive$NonEmptyString$toString,
												$author$project$Components$Supports$Details$Documents$Update$getFormTags(model))))),
								kw: $author$project$Components$Supports$Details$Documents$Update$getFormTypes(model)
							};
						}),
					maybeNom,
					maybeDateDeProduction);
				var doUpdate = function (params) {
					return A2(
						$elm$core$Platform$Cmd$map,
						$author$project$Components$Supports$Details$Documents$Update$DocumentMetadataUpdated,
						A2($author$project$Components$Supports$API$Requests$updateDocument, link, params));
				};
				if (!updateParams.$) {
					var params = updateParams.a;
					return _Utils_Tuple2(
						modelSetAsUpdating,
						doUpdate(params));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			default:
				if (!msg.a.$) {
					return A2($author$project$Components$Supports$Details$Documents$Update$init, model.aO, model.g2);
				} else {
					var modelSetAsNonUploading = A2(
						$author$project$Components$Supports$Details$Documents$Model$updateEditModel,
						$author$project$Components$Supports$Details$Documents$Model$setLoading(false),
						model);
					var errorMessage = 'Erreur lors de la mise à jour du document';
					var modelSetWithError = A2(
						$author$project$Components$Supports$Details$Documents$Model$updateEditModel,
						$author$project$Components$Supports$Details$Documents$Model$setErrorMessage(
							$elm$core$Maybe$Just(errorMessage)),
						modelSetAsNonUploading);
					return _Utils_Tuple2(modelSetWithError, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Components$Supports$Details$Update$update = F2(
	function (model, msg) {
		switch (msg.$) {
			case 0:
				if (!msg.a.$) {
					var result = msg.a.a;
					var support = $author$project$Components$Supports$API$ToModel$fromDetailAPI(result);
					var documentsLink = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'documents', support.nf);
					var modelWithDocumentsLink = _Utils_update(
						model,
						{eo: documentsLink});
					var modelWithSupport = A2($author$project$Components$Supports$Details$Model$setSupport, support, modelWithDocumentsLink);
					var modelWithImportType = _Utils_update(
						modelWithSupport,
						{
							mV: function () {
								var _v2 = result.mV;
								if (!_v2) {
									return 0;
								} else {
									return 1;
								}
							}()
						});
					var _v1 = A2(
						$elm$core$Tuple$mapSecond,
						$elm$core$Platform$Cmd$map($author$project$Components$Supports$Details$Update$DocumentsActions),
						A2($author$project$Components$Supports$Details$Documents$Update$init, model.aO, documentsLink));
					var documentModel = _v1.a;
					var documentsCmd = _v1.b;
					var modelWithDocuments = _Utils_update(
						modelWithImportType,
						{
							fd: A2($author$project$Components$Supports$Details$Model$setDocumentData, modelWithImportType.fd, documentModel)
						});
					return _Utils_Tuple2(modelWithDocuments, documentsCmd);
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
			case 2:
				var tabId = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Components$Supports$Details$Model$selectTab, tabId, model),
					$elm$core$Platform$Cmd$none);
			case 1:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			case 3:
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			default:
				var documentMsg = msg.a;
				var _v3 = A2($author$project$Components$Supports$Details$Documents$Update$update, model.fd.bn, documentMsg);
				var updatedDocumentModel = _v3.a;
				var c = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fd: {bn: updatedDocumentModel, mZ: model.fd.mZ, n7: model.fd.n7}
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$Components$Supports$Details$Update$DocumentsActions, c));
		}
	});
var $author$project$Components$Supports$API$ToModel$fromImportDetailAPI = function (api) {
	return {gv: api.c$, j$: api.mU, oG: api.oG, kt: api.mV, kx: api.kx};
};
var $author$project$Components$Supports$ImportSupports$Detail$Model$Erreur = {$: 2};
var $author$project$Components$Supports$ImportSupports$Detail$Model$pageContentNotLoad = function (model) {
	return _Utils_update(
		model,
		{ow: $author$project$Components$Supports$ImportSupports$Detail$Model$Erreur});
};
var $author$project$Components$Supports$ImportSupports$Detail$Model$Loaded = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Components$Supports$ImportSupports$Detail$Model$setImportPasse = F2(
	function (detail, model) {
		return _Utils_update(
			model,
			{
				ow: A2(
					$author$project$Components$Supports$ImportSupports$Detail$Model$Loaded,
					detail,
					{g5: detail.oG})
			});
	});
var $author$project$Components$Supports$ImportSupports$Detail$Model$filterSupports = function (supports) {
	return A2(
		$elm$core$List$filter,
		function (s) {
			var _v0 = s.ox;
			if (!_v0.$) {
				return false;
			} else {
				return true;
			}
		},
		supports);
};
var $author$project$Components$Supports$ImportSupports$Detail$Model$toggleSupportsNonImportesFilter = function (model) {
	var newFilter = !model.c9;
	var updatedState = function () {
		var _v0 = model.ow;
		switch (_v0.$) {
			case 0:
				return $author$project$Components$Supports$ImportSupports$Detail$Model$Loading;
			case 1:
				var importPasseDetail = _v0.a;
				return A2(
					$author$project$Components$Supports$ImportSupports$Detail$Model$Loaded,
					importPasseDetail,
					{
						g5: newFilter ? $author$project$Components$Supports$ImportSupports$Detail$Model$filterSupports(importPasseDetail.oG) : importPasseDetail.oG
					});
			default:
				return $author$project$Components$Supports$ImportSupports$Detail$Model$Erreur;
		}
	}();
	return _Utils_update(
		model,
		{c9: newFilter, ow: updatedState});
};
var $author$project$Components$Supports$ImportSupports$Detail$Update$update = F2(
	function (model, msg) {
		if (!msg.$) {
			if (!msg.a.$) {
				var result = msg.a.a;
				return _Utils_Tuple2(
					A2(
						$author$project$Components$Supports$ImportSupports$Detail$Model$setImportPasse,
						$author$project$Components$Supports$API$ToModel$fromImportDetailAPI(result),
						model),
					$elm$core$Platform$Cmd$none);
			} else {
				var err = msg.a.a;
				return _Utils_Tuple2(
					$author$project$Components$Supports$ImportSupports$Detail$Model$pageContentNotLoad(model),
					$elm$core$Platform$Cmd$none);
			}
		} else {
			return _Utils_Tuple2(
				$author$project$Components$Supports$ImportSupports$Detail$Model$toggleSupportsNonImportesFilter(model),
				$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Components$Supports$ImportSupports$List$Update$Fichier = function (a) {
	return {$: 8, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Update$ImportFile = F4(
	function (a, b, c, d) {
		return {$: 11, a: a, b: b, c: c, d: d};
	});
var $author$project$Components$Supports$ImportSupports$List$Update$SyncDrawerEvents = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Update$WorkFile = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Components$Supports$ImportSupports$List$Model$askImport = function (model) {
	var importFile = model.w;
	return _Utils_update(
		model,
		{
			w: _Utils_update(
				importFile,
				{aG: true})
		});
};
var $author$project$Components$Drawer$Model$close = {ow: 1};
var $author$project$Components$Supports$ImportSupports$List$Model$setSyncDrawerModel = F2(
	function (drawerModel, model) {
		var importFile = $author$project$Components$Supports$ImportSupports$List$Model$initImportFile;
		return _Utils_update(
			model,
			{
				w: _Utils_update(
					importFile,
					{eq: drawerModel})
			});
	});
var $author$project$Components$Supports$ImportSupports$List$Model$closeDrawer = function (model) {
	return A2($author$project$Components$Supports$ImportSupports$List$Model$setSyncDrawerModel, $author$project$Components$Drawer$Model$close, model);
};
var $author$project$Components$Supports$ImportSupports$List$Model$filtrerImportsPasses = F2(
	function (filtre, model) {
		var filtreModel = model.aY;
		var _v0 = function () {
			switch (filtre.$) {
				case 0:
					var msg = filtre.a;
					return _Utils_Tuple3(
						A2($inkuzmin$elm_multiselect$Multiselect$update, msg, filtreModel.b6),
						function (m) {
							return _Utils_update(
								filtreModel,
								{b6: m});
						},
						$author$project$Components$Supports$ImportSupports$List$Model$FiltrerParTypes);
				case 1:
					var msg = filtre.a;
					return _Utils_Tuple3(
						A2($inkuzmin$elm_multiselect$Multiselect$update, msg, filtreModel.aT),
						function (m) {
							return _Utils_update(
								filtreModel,
								{aT: m});
						},
						$author$project$Components$Supports$ImportSupports$List$Model$FiltrerParProduits);
				case 2:
					var msg = filtre.a;
					return _Utils_Tuple3(
						A2($inkuzmin$elm_multiselect$Multiselect$update, msg, filtreModel.aS),
						function (m) {
							return _Utils_update(
								filtreModel,
								{aS: m});
						},
						$author$project$Components$Supports$ImportSupports$List$Model$FiltrerParCabinets);
				default:
					var msg = filtre.a;
					return _Utils_Tuple3(
						A2($inkuzmin$elm_multiselect$Multiselect$update, msg, filtreModel.b7),
						function (m) {
							return _Utils_update(
								filtreModel,
								{b7: m});
						},
						$author$project$Components$Supports$ImportSupports$List$Model$FiltrerParStatuts);
			}
		}();
		var _v1 = _v0.a;
		var newMultiModel = _v1.a;
		var newMsg = _v1.b;
		var modelUpdater = _v0.b;
		var msgWrapper = _v0.c;
		var updatedModel = $author$project$Components$Supports$ImportSupports$List$Model$appliquerLesFiltres(
			_Utils_update(
				model,
				{
					aY: modelUpdater(newMultiModel)
				}));
		return _Utils_Tuple2(
			updatedModel,
			A2($elm$core$Platform$Cmd$map, msgWrapper, newMsg));
	});
var $author$project$Components$Supports$ImportSupports$List$Model$ImportCompletDonneesCabinet = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Components$Supports$ImportSupports$List$Model$ImportCompletSupportsDansProduit = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Model$ImportPartielDonneesCabinet = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Components$Supports$ImportSupports$List$Model$ImportPartielSupportsDansProduit = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Model$ImportSupportsDansReferentiel = {$: 4};
var $author$project$Components$Supports$API$ToModel$fromImportListAPI = function (api) {
	var getProduitInfo = function (id) {
		return A2(
			$elm$core$Maybe$withDefault,
			{m9: 'produit non trouvé', kx: id},
			A2(
				$elm$core$Maybe$map,
				function (p) {
					return {m9: p.m9, kx: p.kx};
				},
				A2(
					$elm_community$list_extra$List$Extra$find,
					function (p) {
						return _Utils_eq(p.kx, id);
					},
					api.n8)));
	};
	var getCabInfo = function (id) {
		return A2(
			$elm$core$Maybe$withDefault,
			{m9: 'cabinet non trouvé', kx: id},
			A2(
				$elm$core$Maybe$map,
				function (p) {
					return {m9: p.m9, kx: p.kx};
				},
				A2(
					$elm_community$list_extra$List$Extra$find,
					function (p) {
						return _Utils_eq(p.kx, id);
					},
					api.lm)));
	};
	var importsPasses = A2(
		$elm$core$List$map,
		function (imp) {
			return {
				gv: imp.c$,
				mI: A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'self', imp.nf),
				j$: imp.mU,
				kt: function () {
					var _v0 = imp.mV;
					switch (_v0.$) {
						case 0:
							var produitUid = _v0.a;
							return $author$project$Components$Supports$ImportSupports$List$Model$ImportCompletSupportsDansProduit(
								getProduitInfo(produitUid));
						case 1:
							var produitUid = _v0.a;
							return $author$project$Components$Supports$ImportSupports$List$Model$ImportPartielSupportsDansProduit(
								getProduitInfo(produitUid));
						case 2:
							var produitUid = _v0.a;
							var cabUid = _v0.b;
							return A2(
								$author$project$Components$Supports$ImportSupports$List$Model$ImportCompletDonneesCabinet,
								getProduitInfo(produitUid),
								getCabInfo(cabUid));
						case 3:
							var produitUid = _v0.a;
							var cabUid = _v0.b;
							return A2(
								$author$project$Components$Supports$ImportSupports$List$Model$ImportPartielDonneesCabinet,
								getProduitInfo(produitUid),
								getCabInfo(cabUid));
						default:
							return $author$project$Components$Supports$ImportSupports$List$Model$ImportSupportsDansReferentiel;
					}
				}(),
				kx: imp.kx
			};
		},
		api.mW);
	return {
		lm: api.lm,
		mP: A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'import_supports_dans_referentiel', api.nf),
		mW: importsPasses,
		n8: api.n8
	};
};
var $elm$random$Random$Generate = $elm$core$Basics$identity;
var $elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$random$Random$next = function (_v0) {
	var state0 = _v0.a;
	var incr = _v0.b;
	return A2($elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var $elm$random$Random$initialSeed = function (x) {
	var _v0 = $elm$random$Random$next(
		A2($elm$random$Random$Seed, 0, 1013904223));
	var state1 = _v0.a;
	var incr = _v0.b;
	var state2 = (state1 + x) >>> 0;
	return $elm$random$Random$next(
		A2($elm$random$Random$Seed, state2, incr));
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $elm$random$Random$init = A2(
	$elm$core$Task$andThen,
	function (time) {
		return $elm$core$Task$succeed(
			$elm$random$Random$initialSeed(
				$elm$time$Time$posixToMillis(time)));
	},
	$elm$time$Time$now);
var $elm$random$Random$step = F2(
	function (_v0, seed) {
		var generator = _v0;
		return generator(seed);
	});
var $elm$random$Random$onEffects = F3(
	function (router, commands, seed) {
		if (!commands.b) {
			return $elm$core$Task$succeed(seed);
		} else {
			var generator = commands.a;
			var rest = commands.b;
			var _v1 = A2($elm$random$Random$step, generator, seed);
			var value = _v1.a;
			var newSeed = _v1.b;
			return A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$random$Random$onEffects, router, rest, newSeed);
				},
				A2($elm$core$Platform$sendToApp, router, value));
		}
	});
var $elm$random$Random$onSelfMsg = F3(
	function (_v0, _v1, seed) {
		return $elm$core$Task$succeed(seed);
	});
var $elm$random$Random$Generator = $elm$core$Basics$identity;
var $elm$random$Random$map = F2(
	function (func, _v0) {
		var genA = _v0;
		return function (seed0) {
			var _v1 = genA(seed0);
			var a = _v1.a;
			var seed1 = _v1.b;
			return _Utils_Tuple2(
				func(a),
				seed1);
		};
	});
var $elm$random$Random$cmdMap = F2(
	function (func, _v0) {
		var generator = _v0;
		return A2($elm$random$Random$map, func, generator);
	});
_Platform_effectManagers['Random'] = _Platform_createManager($elm$random$Random$init, $elm$random$Random$onEffects, $elm$random$Random$onSelfMsg, $elm$random$Random$cmdMap);
var $elm$random$Random$command = _Platform_leaf('Random');
var $elm$random$Random$generate = F2(
	function (tagger, generator) {
		return $elm$random$Random$command(
			A2($elm$random$Random$map, tagger, generator));
	});
var $TSFoster$elm_uuid$UUID$UUID = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$random$Random$map4 = F5(
	function (func, _v0, _v1, _v2, _v3) {
		var genA = _v0;
		var genB = _v1;
		var genC = _v2;
		var genD = _v3;
		return function (seed0) {
			var _v4 = genA(seed0);
			var a = _v4.a;
			var seed1 = _v4.b;
			var _v5 = genB(seed1);
			var b = _v5.a;
			var seed2 = _v5.b;
			var _v6 = genC(seed2);
			var c = _v6.a;
			var seed3 = _v6.b;
			var _v7 = genD(seed3);
			var d = _v7.a;
			var seed4 = _v7.b;
			return _Utils_Tuple2(
				A4(func, a, b, c, d),
				seed4);
		};
	});
var $TSFoster$elm_uuid$UUID$forceUnsigned = $elm$core$Bitwise$shiftRightZfBy(0);
var $elm$random$Random$peel = function (_v0) {
	var state = _v0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var $elm$random$Random$int = F2(
	function (a, b) {
		return function (seed0) {
			var _v0 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
			var lo = _v0.a;
			var hi = _v0.b;
			var range = (hi - lo) + 1;
			if (!((range - 1) & range)) {
				return _Utils_Tuple2(
					(((range - 1) & $elm$random$Random$peel(seed0)) >>> 0) + lo,
					$elm$random$Random$next(seed0));
			} else {
				var threshhold = (((-range) >>> 0) % range) >>> 0;
				var accountForBias = function (seed) {
					accountForBias:
					while (true) {
						var x = $elm$random$Random$peel(seed);
						var seedN = $elm$random$Random$next(seed);
						if (_Utils_cmp(x, threshhold) < 0) {
							var $temp$seed = seedN;
							seed = $temp$seed;
							continue accountForBias;
						} else {
							return _Utils_Tuple2((x % range) + lo, seedN);
						}
					}
				};
				return accountForBias(seed0);
			}
		};
	});
var $elm$random$Random$maxInt = 2147483647;
var $elm$random$Random$minInt = -2147483648;
var $TSFoster$elm_uuid$UUID$randomU32 = A2(
	$elm$random$Random$map,
	$TSFoster$elm_uuid$UUID$forceUnsigned,
	A2($elm$random$Random$int, $elm$random$Random$minInt, $elm$random$Random$maxInt));
var $TSFoster$elm_uuid$UUID$toVariant1 = function (_v0) {
	var a = _v0.a;
	var b = _v0.b;
	var c = _v0.c;
	var d = _v0.d;
	return A4(
		$TSFoster$elm_uuid$UUID$UUID,
		a,
		b,
		$TSFoster$elm_uuid$UUID$forceUnsigned(2147483648 | (1073741823 & c)),
		d);
};
var $TSFoster$elm_uuid$UUID$toVersion = F2(
	function (v, _v0) {
		var a = _v0.a;
		var b = _v0.b;
		var c = _v0.c;
		var d = _v0.d;
		return A4(
			$TSFoster$elm_uuid$UUID$UUID,
			a,
			$TSFoster$elm_uuid$UUID$forceUnsigned((v << 12) | (4294905855 & b)),
			c,
			d);
	});
var $TSFoster$elm_uuid$UUID$generator = A2(
	$elm$random$Random$map,
	A2(
		$elm$core$Basics$composeR,
		$TSFoster$elm_uuid$UUID$toVersion(4),
		$TSFoster$elm_uuid$UUID$toVariant1),
	A5($elm$random$Random$map4, $TSFoster$elm_uuid$UUID$UUID, $TSFoster$elm_uuid$UUID$randomU32, $TSFoster$elm_uuid$UUID$randomU32, $TSFoster$elm_uuid$UUID$randomU32, $TSFoster$elm_uuid$UUID$randomU32));
var $author$project$Components$Supports$ImportSupports$List$Update$Imported = function (a) {
	return {$: 12, a: a};
};
var $elm$http$Http$bytesPart = F3(
	function (key, mime, bytes) {
		return A2(
			_Http_pair,
			key,
			A2(_Http_bytesToBlob, mime, bytes));
	});
var $elm$file$File$mime = _File_mime;
var $TSFoster$elm_uuid$UUID$toHex = F2(
	function (acc, _int) {
		toHex:
		while (true) {
			if (!_int) {
				return $elm$core$String$fromList(acc);
			} else {
				var _char = function () {
					var _v0 = 15 & _int;
					switch (_v0) {
						case 0:
							return '0';
						case 1:
							return '1';
						case 2:
							return '2';
						case 3:
							return '3';
						case 4:
							return '4';
						case 5:
							return '5';
						case 6:
							return '6';
						case 7:
							return '7';
						case 8:
							return '8';
						case 9:
							return '9';
						case 10:
							return 'a';
						case 11:
							return 'b';
						case 12:
							return 'c';
						case 13:
							return 'd';
						case 14:
							return 'e';
						default:
							return 'f';
					}
				}();
				var $temp$acc = A2($elm$core$List$cons, _char, acc),
					$temp$int = _int >>> 4;
				acc = $temp$acc;
				_int = $temp$int;
				continue toHex;
			}
		}
	});
var $TSFoster$elm_uuid$UUID$toStringWith = F2(
	function (sep, _v0) {
		var a = _v0.a;
		var b = _v0.b;
		var c = _v0.c;
		var d = _v0.d;
		return _Utils_ap(
			A3(
				$elm$core$String$padLeft,
				8,
				'0',
				A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, a)),
			_Utils_ap(
				sep,
				_Utils_ap(
					A3(
						$elm$core$String$padLeft,
						4,
						'0',
						A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, b >>> 16)),
					_Utils_ap(
						sep,
						_Utils_ap(
							A3(
								$elm$core$String$padLeft,
								4,
								'0',
								A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, 65535 & b)),
							_Utils_ap(
								sep,
								_Utils_ap(
									A3(
										$elm$core$String$padLeft,
										4,
										'0',
										A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, c >>> 16)),
									_Utils_ap(
										sep,
										_Utils_ap(
											A3(
												$elm$core$String$padLeft,
												4,
												'0',
												A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, 65535 & c)),
											A3(
												$elm$core$String$padLeft,
												8,
												'0',
												A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, d)))))))))));
	});
var $TSFoster$elm_uuid$UUID$toString = $TSFoster$elm_uuid$UUID$toStringWith('-');
var $author$project$Components$Supports$ImportSupports$List$Update$buildBodyByParameters = F3(
	function (importType, file, processUid) {
		var cabToString = function (_v1) {
			var uid = _v1;
			return uid;
		};
		var additionalBodyParts = function () {
			switch (importType.$) {
				case 0:
					return _List_Nil;
				case 1:
					return _List_Nil;
				case 2:
					var abstractCabinet = importType.b;
					return _List_fromArray(
						[
							A2(
							$elm$http$Http$stringPart,
							'cabinetUid',
							cabToString(abstractCabinet.kx))
						]);
				case 3:
					var abstractCabinet = importType.b;
					return _List_fromArray(
						[
							A2(
							$elm$http$Http$stringPart,
							'cabinetUid',
							cabToString(abstractCabinet.kx))
						]);
				default:
					return _List_Nil;
			}
		}();
		return $elm$http$Http$multipartBody(
			_Utils_ap(
				_List_fromArray(
					[
						A3(
						$elm$http$Http$bytesPart,
						'file',
						$elm$file$File$mime(file.ew),
						file.mo),
						A2(
						$elm$http$Http$stringPart,
						'processUid',
						$TSFoster$elm_uuid$UUID$toString(processUid))
					]),
				additionalBodyParts));
	});
var $author$project$Components$Supports$ImportSupports$List$Update$buildImportBody = F3(
	function (importType, fileToImport, processUid) {
		return A3($author$project$Components$Supports$ImportSupports$List$Update$buildBodyByParameters, importType, fileToImport, processUid);
	});
var $author$project$Components$Supports$API$Errors$ImportFileError$ImportAPIErrors = F2(
	function (traceId, errorType) {
		return {ma: errorType, fn: traceId};
	});
var $author$project$Components$Supports$API$Errors$ImportFileError$ServerError = {$: 8};
var $author$project$Components$Supports$API$Errors$ImportFileError$CabinetNotFound = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$Supports$API$Errors$ImportFileError$ImportPartielRequiertImportComplet = {$: 3};
var $author$project$Components$Supports$API$Errors$ImportFileError$ImportPourCabinetRequiertImportPourProduit = {$: 2};
var $author$project$Components$Supports$API$Errors$ImportFileError$ImportRequiertMinimumUnElement = {$: 1};
var $author$project$Components$Supports$API$Errors$ImportFileError$ImportedFileParsingError = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$API$Errors$ImportFileError$IntegrateSupportDataRequiertImportComplet = {$: 4};
var $author$project$Components$Supports$API$Errors$ImportFileError$ProduitNotFound = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Supports$API$Errors$ImportFileError$SupportIdsNonImportedPourProduit = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Supports$API$Errors$ImportFileError$EmptyFile = {$: 0};
var $author$project$Components$Supports$API$Errors$ImportFileError$LineParsingError = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Supports$API$Errors$ImportFileError$DataNotInformed = {$: 7};
var $author$project$Components$Supports$API$Errors$ImportFileError$FraisGestionIncorrect = {$: 5};
var $author$project$Components$Supports$API$Errors$ImportFileError$InvalidInt = function (a) {
	return {$: 9, a: a};
};
var $author$project$Components$Supports$API$Errors$ImportFileError$ModeDeGestionIncorrect = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$API$Errors$ImportFileError$ModeDeGestionManquant = {$: 1};
var $author$project$Components$Supports$API$Errors$ImportFileError$PerformanceNetteIncorrect = {$: 11};
var $author$project$Components$Supports$API$Errors$ImportFileError$RemunerationFraisDeGestionIncorrect = {$: 3};
var $author$project$Components$Supports$API$Errors$ImportFileError$RemunerationFraisGestionIncorrect = {$: 6};
var $author$project$Components$Supports$API$Errors$ImportFileError$StringIsBlank = {$: 8};
var $author$project$Components$Supports$API$Errors$ImportFileError$SupportIdIncorrect = {$: 10};
var $author$project$Components$Supports$API$Errors$ImportFileError$SupportIdManquant = {$: 2};
var $author$project$Components$Supports$API$Errors$ImportFileError$TauxRetrocessionIncorrect = {$: 4};
var $author$project$Components$Supports$API$Errors$ImportFileError$analyzedErrorDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$map,
			$author$project$Components$Supports$API$Errors$ImportFileError$ModeDeGestionIncorrect,
			A2(
				$elm$json$Json$Decode$field,
				'ModeDeGestionIncorrect',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'badInput',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed(
						function (badInput) {
							return {la: badInput};
						})))),
			A2(
			$elm$json$Json$Decode$field,
			'ModeDeGestionManquant',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$ModeDeGestionManquant)),
			A2(
			$elm$json$Json$Decode$field,
			'SupportIdManquant',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$SupportIdManquant)),
			A2(
			$elm$json$Json$Decode$field,
			'RemunerationFraisDeGestionIncorrect',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$RemunerationFraisDeGestionIncorrect)),
			A2(
			$elm$json$Json$Decode$field,
			'TauxRetrocessionIncorrect',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$TauxRetrocessionIncorrect)),
			A2(
			$elm$json$Json$Decode$field,
			'FraisGestionIncorrect',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$FraisGestionIncorrect)),
			A2(
			$elm$json$Json$Decode$field,
			'RemunerationFraisGestionIncorrect',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$RemunerationFraisGestionIncorrect)),
			A2(
			$elm$json$Json$Decode$field,
			'DataNotInformed',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$DataNotInformed)),
			A2(
			$elm$json$Json$Decode$field,
			'StringIsBlank',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$StringIsBlank)),
			A2(
			$elm$json$Json$Decode$map,
			$author$project$Components$Supports$API$Errors$ImportFileError$InvalidInt,
			A2(
				$elm$json$Json$Decode$field,
				'InvalidInt',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'validValues',
					$elm$json$Json$Decode$list($elm$json$Json$Decode$int),
					$elm$json$Json$Decode$succeed(
						function (validValues) {
							return {o5: validValues};
						})))),
			A2(
			$elm$json$Json$Decode$field,
			'SupportIdIncorrect',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$SupportIdIncorrect)),
			A2(
			$elm$json$Json$Decode$field,
			'PerformanceNetteIncorrect',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$PerformanceNetteIncorrect))
		]));
var $author$project$Components$Supports$API$Errors$ImportFileError$lineParsingContentDecoder = A3(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
	'error',
	$author$project$Components$Supports$API$Errors$ImportFileError$analyzedErrorDecoder,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'line',
		$elm$json$Json$Decode$int,
		$elm$json$Json$Decode$succeed(
			F2(
				function (line, error) {
					return {es: error, ne: line};
				}))));
var $author$project$Components$Supports$API$Errors$ImportFileError$fileParsingErrorJsonDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'EmptyFile',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$EmptyFile)),
			A2(
			$elm$json$Json$Decode$field,
			'LineParsingError',
			A2($elm$json$Json$Decode$map, $author$project$Components$Supports$API$Errors$ImportFileError$LineParsingError, $author$project$Components$Supports$API$Errors$ImportFileError$lineParsingContentDecoder))
		]));
var $author$project$Components$Supports$API$Errors$ImportFileError$fileImportErrorsDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$map,
			$author$project$Components$Supports$API$Errors$ImportFileError$ImportedFileParsingError,
			A2(
				$elm$json$Json$Decode$field,
				'ImportedFileParsingError',
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'errors',
					$elm$json$Json$Decode$list($author$project$Components$Supports$API$Errors$ImportFileError$fileParsingErrorJsonDecoder),
					$elm$json$Json$Decode$succeed($elm$core$Basics$identity)))),
			A2(
			$elm$json$Json$Decode$field,
			'ImportRequiertMinimumUnElement',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$ImportRequiertMinimumUnElement)),
			A2(
			$elm$json$Json$Decode$field,
			'ImportPourCabinetRequiertImportPourProduit',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$ImportPourCabinetRequiertImportPourProduit)),
			A2(
			$elm$json$Json$Decode$field,
			'ImportPartielRequiertImportComplet',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$ImportPartielRequiertImportComplet)),
			A2(
			$elm$json$Json$Decode$field,
			'IntegrateSupportDataRequiertImportComplet',
			$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$IntegrateSupportDataRequiertImportComplet)),
			A2(
			$elm$json$Json$Decode$field,
			'SupportIdsNonImportedPourProduit',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'supportIds',
				$elm$json$Json$Decode$list($elm$json$Json$Decode$string),
				$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$SupportIdsNonImportedPourProduit))),
			A2(
			$elm$json$Json$Decode$field,
			'ProduitNotFound',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'produitUid',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$ProduitNotFound))),
			A2(
			$elm$json$Json$Decode$field,
			'CabinetNotFound',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'cabinetUid',
				$elm$json$Json$Decode$string,
				$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$CabinetNotFound)))
		]));
var $author$project$Components$Supports$API$Errors$ImportFileError$importErrorsDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$json$Json$Decode$field,
			'error',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'errorType',
				$author$project$Components$Supports$API$Errors$ImportFileError$fileImportErrorsDecoder,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'traceId',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$ImportAPIErrors)))),
			A2(
			$elm$json$Json$Decode$field,
			'error',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'errorType',
				A2(
					$elm$json$Json$Decode$map,
					function (_v0) {
						return $author$project$Components$Supports$API$Errors$ImportFileError$ServerError;
					},
					$elm$json$Json$Decode$string),
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'traceId',
					$elm$json$Json$Decode$string,
					$elm$json$Json$Decode$succeed($author$project$Components$Supports$API$Errors$ImportFileError$ImportAPIErrors))))
		]));
var $author$project$Components$Supports$API$Requests$importFile = F2(
	function (link, body) {
		return A3(
			$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
			link,
			body,
			A2($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$expectWhateverOkResult, $elm$core$Basics$identity, $author$project$Components$Supports$API$Errors$ImportFileError$importErrorsDecoder));
	});
var $author$project$Components$Supports$ImportSupports$List$Update$importFile = F4(
	function (link, importType, fileToImport, processUid) {
		return A2(
			$elm$core$Platform$Cmd$map,
			$author$project$Components$Supports$ImportSupports$List$Update$Imported,
			A2(
				$author$project$Components$Supports$API$Requests$importFile,
				link,
				A3($author$project$Components$Supports$ImportSupports$List$Update$buildImportBody, importType, fileToImport, processUid)));
	});
var $author$project$Components$Supports$ImportSupports$List$Model$importMimeTypes = _List_fromArray(
	['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv']);
var $author$project$Components$Supports$ImportSupports$List$Model$openImportDrawer = function (model) {
	return A2($author$project$Components$Supports$ImportSupports$List$Model$setSyncDrawerModel, $author$project$Components$Drawer$Model$open, model);
};
var $author$project$Components$Supports$ImportSupports$List$Model$ImportCompletCabinetModel = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Model$ImportCompletSupportsModel = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Model$ImportDansReferentielModel = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Model$ImportPartielCabinetModel = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Model$ImportPartielSupportsModel = function (a) {
	return {$: 1, a: a};
};
var $author$project$Perfimmo$Input$DatalistInput$HideItems = 0;
var $author$project$Perfimmo$Input$DatalistInput$Input = 0;
var $author$project$Perfimmo$Input$DatalistInput$initSelect = F3(
	function (id, placeholder, items) {
		return {aV: 0, c8: items, hB: id, aE: items, cv: placeholder, bL: '', dN: 0, dO: $elm$core$Maybe$Nothing};
	});
var $author$project$Perfimmo$Input$DatalistInput$clearSelect = function (model) {
	return A3($author$project$Perfimmo$Input$DatalistInput$initSelect, model.hB, model.cv, model.aE);
};
var $author$project$Perfimmo$Input$DatalistInput$ShowItems = 1;
var $author$project$Perfimmo$Input$DatalistInput$filterItem = F2(
	function (search, model) {
		return _Utils_update(
			model,
			{
				c8: A2(
					$elm$core$List$filter,
					function (_v0) {
						var itemLabel = _v0.b;
						return A2(
							$elm$core$String$contains,
							$elm$core$String$toLower(search),
							$elm$core$String$toLower(itemLabel));
					},
					model.aE),
				bL: search
			});
	});
var $author$project$Perfimmo$Input$DatalistInput$searchItem = F2(
	function (search, model) {
		return A2(
			$author$project$Perfimmo$Input$DatalistInput$filterItem,
			search,
			_Utils_update(
				model,
				{aV: 1}));
	});
var $author$project$Perfimmo$Input$DatalistInput$Button = 1;
var $elm_community$list_extra$List$Extra$findMap = F2(
	function (f, list) {
		findMap:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var a = list.a;
				var tail = list.b;
				var _v1 = f(a);
				if (!_v1.$) {
					var b = _v1.a;
					return $elm$core$Maybe$Just(b);
				} else {
					var $temp$f = f,
						$temp$list = tail;
					f = $temp$f;
					list = $temp$list;
					continue findMap;
				}
			}
		}
	});
var $author$project$Perfimmo$Input$DatalistInput$calculSearch = F2(
	function (model, selectedItem) {
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm_community$list_extra$List$Extra$findMap,
				function (_v0) {
					var id = _v0.a;
					var label = _v0.b;
					return A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						function (selected) {
							return _Utils_eq(selected, id) ? $elm$core$Maybe$Just(label) : $elm$core$Maybe$Nothing;
						},
						selectedItem);
				},
				model.aE));
	});
var $author$project$Perfimmo$Input$DatalistInput$selectItem = F2(
	function (selectedItem, model) {
		return A2(
			$author$project$Perfimmo$Input$DatalistInput$filterItem,
			A2($author$project$Perfimmo$Input$DatalistInput$calculSearch, model, selectedItem),
			_Utils_update(
				model,
				{aV: 0, dN: 1, dO: selectedItem}));
	});
var $author$project$Perfimmo$Input$DatalistInput$toggleDropdown = function (model) {
	var newStatus = function () {
		var _v0 = model.aV;
		if (!_v0) {
			return 1;
		} else {
			return 0;
		}
	}();
	return _Utils_update(
		model,
		{aV: newStatus});
};
var $author$project$Components$Supports$ImportSupports$List$Model$setCabinetChoix = F3(
	function (selectInput, importTypeModel, model) {
		var updateRecord = function (datalistModel) {
			switch (selectInput.$) {
				case 0:
					return $author$project$Perfimmo$Input$DatalistInput$toggleDropdown(datalistModel);
				case 1:
					var search = selectInput.a;
					return A2($author$project$Perfimmo$Input$DatalistInput$searchItem, search, datalistModel);
				case 2:
					var produitId = selectInput.a;
					return A2(
						$author$project$Perfimmo$Input$DatalistInput$selectItem,
						$elm$core$Maybe$Just(produitId),
						datalistModel);
				default:
					return $author$project$Perfimmo$Input$DatalistInput$clearSelect(datalistModel);
			}
		};
		var updater = function (record) {
			return _Utils_update(
				record,
				{
					d7: updateRecord(record.b9).dO,
					b9: updateRecord(record.b9)
				});
		};
		var updatedImportTypeModel = function () {
			switch (importTypeModel.$) {
				case 0:
					var record = importTypeModel.a;
					return $author$project$Components$Supports$ImportSupports$List$Model$ImportCompletSupportsModel(record);
				case 1:
					var record = importTypeModel.a;
					return $author$project$Components$Supports$ImportSupports$List$Model$ImportPartielSupportsModel(record);
				case 2:
					var record = importTypeModel.a;
					return $author$project$Components$Supports$ImportSupports$List$Model$ImportCompletCabinetModel(
						updater(record));
				case 3:
					var record = importTypeModel.a;
					return $author$project$Components$Supports$ImportSupports$List$Model$ImportPartielCabinetModel(
						updater(record));
				default:
					var record = importTypeModel.a;
					return $author$project$Components$Supports$ImportSupports$List$Model$ImportDansReferentielModel(record);
			}
		}();
		var importFile = model.w;
		var updatedImportFile = _Utils_update(
			importFile,
			{
				a0: $elm$core$Maybe$Just(updatedImportTypeModel)
			});
		return _Utils_update(
			model,
			{w: updatedImportFile});
	});
var $author$project$Components$Supports$ImportSupports$List$Model$FileToImport = F2(
	function (file, fileBytes) {
		return {ew: file, mo: fileBytes};
	});
var $author$project$Components$Supports$ImportSupports$List$Model$setFileToImport = F3(
	function (file, fileBytes, model) {
		var updater = function (record) {
			return _Utils_update(
				record,
				{
					ew: $elm$core$Maybe$Just(
						A2($author$project$Components$Supports$ImportSupports$List$Model$FileToImport, file, fileBytes))
				});
		};
		var updateImportTypeModel = function (importTypeModel) {
			switch (importTypeModel.$) {
				case 0:
					var record = importTypeModel.a;
					return $author$project$Components$Supports$ImportSupports$List$Model$ImportCompletSupportsModel(
						updater(record));
				case 1:
					var record = importTypeModel.a;
					return $author$project$Components$Supports$ImportSupports$List$Model$ImportPartielSupportsModel(
						updater(record));
				case 2:
					var record = importTypeModel.a;
					return $author$project$Components$Supports$ImportSupports$List$Model$ImportCompletCabinetModel(
						updater(record));
				case 3:
					var record = importTypeModel.a;
					return $author$project$Components$Supports$ImportSupports$List$Model$ImportPartielCabinetModel(
						updater(record));
				default:
					var record = importTypeModel.a;
					return $author$project$Components$Supports$ImportSupports$List$Model$ImportDansReferentielModel(
						updater(record));
			}
		};
		var importFile = model.w;
		var updatedImportFile = _Utils_update(
			importFile,
			{
				a0: A2($elm$core$Maybe$map, updateImportTypeModel, importFile.a0)
			});
		return _Utils_update(
			model,
			{w: updatedImportFile});
	});
var $author$project$Components$Supports$ImportSupports$List$Model$setImportErrors = F2(
	function (apiErrors, model) {
		var importFile = model.w;
		return _Utils_update(
			model,
			{
				w: _Utils_update(
					importFile,
					{
						ch: $elm$core$Maybe$Just(
							{hC: apiErrors, e9: false}),
						aG: false
					})
			});
	});
var $author$project$Components$Supports$ImportSupports$List$Model$setFiltresData = F3(
	function (cabinets, produits, filtres) {
		var produitsData = A2(
			$elm$core$List$map,
			function (produit) {
				return _Utils_Tuple2(produit.kx, produit.m9);
			},
			produits);
		var cabinetsData = A2(
			$elm$core$List$map,
			function (_v0) {
				var id = _v0.a;
				var label = _v0.b;
				return _Utils_Tuple2(id, label);
			},
			A2(
				$elm$core$List$map,
				function (cab) {
					return _Utils_Tuple2(cab.kx, cab.m9);
				},
				cabinets));
		return _Utils_update(
			filtres,
			{
				aS: A3($inkuzmin$elm_multiselect$Multiselect$populateValues, filtres.aS, cabinetsData, _List_Nil),
				aT: A3($inkuzmin$elm_multiselect$Multiselect$populateValues, filtres.aT, produitsData, _List_Nil)
			});
	});
var $author$project$Components$Supports$ImportSupports$List$Model$setImportPasses = F2(
	function (serverData, model) {
		return $author$project$Components$Supports$ImportSupports$List$Model$appliquerLesFiltres(
			_Utils_update(
				model,
				{
					aY: A3($author$project$Components$Supports$ImportSupports$List$Model$setFiltresData, serverData.lm, serverData.n8, model.aY),
					aG: false,
					a8: serverData
				}));
	});
var $author$project$Components$Supports$ImportSupports$List$Model$initImportTypeModel = F4(
	function (produits, cabinets, type_, importSupportsDansReferentielLink) {
		var filterProduits = function (rel) {
			return A2(
				$elm$core$List$filterMap,
				function (_v1) {
					var _v2 = _v1.a;
					var produitId = _v2.a;
					var produitLabel = _v2.b;
					var links = _v1.b;
					return A2(
						$elm$core$Maybe$map,
						function (link) {
							return {eI: link, m9: produitLabel, kx: produitId};
						},
						A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, rel, links));
				},
				produits);
		};
		switch (type_) {
			case 0:
				return $author$project$Components$Supports$ImportSupports$List$Model$ImportCompletSupportsModel(
					function () {
						var filteredProduits = filterProduits('import_supports_pour_produit');
						return {
							ax: A3(
								$author$project$Perfimmo$Input$DatalistInput$initSelect,
								'produitSelector',
								'Choisir un produit',
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.kx, p.m9);
									},
									filteredProduits)),
							ew: $elm$core$Maybe$Nothing,
							cy: $elm$core$Maybe$Nothing,
							cz: $elm$core$Maybe$Nothing,
							cB: filteredProduits
						};
					}());
			case 1:
				return $author$project$Components$Supports$ImportSupports$List$Model$ImportPartielSupportsModel(
					function () {
						var filteredProduits = filterProduits('import_partiel_supports_pour_produit');
						return {
							ax: A3(
								$author$project$Perfimmo$Input$DatalistInput$initSelect,
								'produitSelector',
								'Choisir un produit',
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.kx, p.m9);
									},
									filteredProduits)),
							ew: $elm$core$Maybe$Nothing,
							cy: $elm$core$Maybe$Nothing,
							cz: $elm$core$Maybe$Nothing,
							cB: filteredProduits
						};
					}());
			case 2:
				return $author$project$Components$Supports$ImportSupports$List$Model$ImportCompletCabinetModel(
					function () {
						var filteredProduits = filterProduits('import_supports_pour_cabinet');
						return {
							d7: $elm$core$Maybe$Nothing,
							b9: A3($author$project$Perfimmo$Input$DatalistInput$initSelect, 'cabinetSelector', 'Choisir un cabinet', cabinets),
							ax: A3(
								$author$project$Perfimmo$Input$DatalistInput$initSelect,
								'produitSelector',
								'Choisir un produit',
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.kx, p.m9);
									},
									filteredProduits)),
							ew: $elm$core$Maybe$Nothing,
							cy: $elm$core$Maybe$Nothing,
							cz: $elm$core$Maybe$Nothing,
							cB: filteredProduits
						};
					}());
			case 3:
				return $author$project$Components$Supports$ImportSupports$List$Model$ImportPartielCabinetModel(
					function () {
						var filteredProduits = filterProduits('import_partiel_supports_pour_cabinet');
						return {
							d7: $elm$core$Maybe$Nothing,
							b9: A3($author$project$Perfimmo$Input$DatalistInput$initSelect, 'cabinetSelector', 'Choisir un cabinet', cabinets),
							ax: A3(
								$author$project$Perfimmo$Input$DatalistInput$initSelect,
								'produitSelector',
								'Choisir un produit',
								A2(
									$elm$core$List$map,
									function (p) {
										return _Utils_Tuple2(p.kx, p.m9);
									},
									filteredProduits)),
							ew: $elm$core$Maybe$Nothing,
							cy: $elm$core$Maybe$Nothing,
							cz: $elm$core$Maybe$Nothing,
							cB: filteredProduits
						};
					}());
			default:
				return $author$project$Components$Supports$ImportSupports$List$Model$ImportDansReferentielModel(
					{ew: $elm$core$Maybe$Nothing, hX: importSupportsDansReferentielLink});
		}
	});
var $author$project$Components$Supports$ImportSupports$List$Model$setImportTypeSelection = F2(
	function (selectInput, model) {
		var produits = A2(
			$elm$core$List$map,
			function (p) {
				return _Utils_Tuple2(
					_Utils_Tuple2(p.kx, p.m9),
					p.nf);
			},
			model.a8.n8);
		var importFile = model.w;
		var selectModel = importFile.dd;
		var cabinets = A2(
			$elm$core$List$map,
			function (p) {
				return _Utils_Tuple2(p.kx, p.m9);
			},
			model.a8.lm);
		var _v0 = function () {
			switch (selectInput.$) {
				case 0:
					return _Utils_Tuple2(
						$author$project$Perfimmo$Input$SelectInput$toggleDropdown(selectModel),
						$elm$core$Maybe$Nothing);
				case 1:
					var importType = selectInput.a;
					return _Utils_Tuple2(
						A2(
							$author$project$Perfimmo$Input$SelectInput$selectItem,
							$elm$core$Maybe$Just(importType),
							selectModel),
						$elm$core$Maybe$Just(
							A4($author$project$Components$Supports$ImportSupports$List$Model$initImportTypeModel, produits, cabinets, importType, model.a8.mP)));
				default:
					return _Utils_Tuple2(
						$author$project$Perfimmo$Input$SelectInput$clearSelect(selectModel),
						$elm$core$Maybe$Nothing);
			}
		}();
		var updatedSelect = _v0.a;
		var importTypeModel = _v0.b;
		return _Utils_update(
			model,
			{
				w: _Utils_update(
					importFile,
					{a0: importTypeModel, dd: updatedSelect})
			});
	});
var $author$project$Components$Supports$ImportSupports$List$Model$setProduitChoix = F3(
	function (selectInput, importTypeModel, model) {
		var updateRecord = function (datalistModel) {
			switch (selectInput.$) {
				case 0:
					return $author$project$Perfimmo$Input$DatalistInput$toggleDropdown(datalistModel);
				case 1:
					var search = selectInput.a;
					return A2($author$project$Perfimmo$Input$DatalistInput$searchItem, search, datalistModel);
				case 2:
					var produitId = selectInput.a;
					return A2(
						$author$project$Perfimmo$Input$DatalistInput$selectItem,
						$elm$core$Maybe$Just(produitId),
						datalistModel);
				default:
					return $author$project$Perfimmo$Input$DatalistInput$clearSelect(datalistModel);
			}
		};
		var updater = function (record) {
			return _Utils_update(
				record,
				{
					ax: updateRecord(record.ax),
					cy: updateRecord(record.ax).dO,
					cz: A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						function (produitId) {
							return A2(
								$elm$core$Maybe$map,
								function ($) {
									return $.eI;
								},
								A2(
									$elm_community$list_extra$List$Extra$find,
									function (p) {
										return _Utils_eq(p.kx, produitId);
									},
									record.cB));
						},
						updateRecord(record.ax).dO)
				});
		};
		var updatedImportTypeModel = function () {
			switch (importTypeModel.$) {
				case 0:
					var record = importTypeModel.a;
					return $author$project$Components$Supports$ImportSupports$List$Model$ImportCompletSupportsModel(
						updater(record));
				case 1:
					var record = importTypeModel.a;
					return $author$project$Components$Supports$ImportSupports$List$Model$ImportPartielSupportsModel(
						updater(record));
				case 2:
					var record = importTypeModel.a;
					return $author$project$Components$Supports$ImportSupports$List$Model$ImportCompletCabinetModel(
						updater(record));
				case 3:
					var record = importTypeModel.a;
					return $author$project$Components$Supports$ImportSupports$List$Model$ImportPartielCabinetModel(
						updater(record));
				default:
					var record = importTypeModel.a;
					return $author$project$Components$Supports$ImportSupports$List$Model$ImportDansReferentielModel(record);
			}
		}();
		var importFile = model.w;
		var updatedImportFile = _Utils_update(
			importFile,
			{
				a0: $elm$core$Maybe$Just(updatedImportTypeModel)
			});
		return _Utils_update(
			model,
			{w: updatedImportFile});
	});
var $author$project$Components$Supports$ImportSupports$List$Model$setUnkownErrors = F2(
	function (error, model) {
		var importFile = model.w;
		return _Utils_update(
			model,
			{
				w: _Utils_update(
					importFile,
					{aG: false})
			});
	});
var $author$project$Components$Supports$ImportSupports$List$Model$showError = function (model) {
	var importFile = model.w;
	var errors = importFile.ch;
	var showTheError = A2(
		$elm$core$Maybe$map,
		function (err) {
			return _Utils_update(
				err,
				{e9: true});
		},
		errors);
	return _Utils_update(
		model,
		{
			w: _Utils_update(
				importFile,
				{ch: showTheError})
		});
};
var $elm$file$File$size = _File_size;
var $author$project$Components$Supports$ImportSupports$List$Update$size10_MO = (10 * 1024) * 1024;
var $elm$file$File$toBytes = _File_toBytes;
var $author$project$Components$Supports$ImportSupports$List$Update$update = F2(
	function (model, msg) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					if (!msg.a.$) {
						var result = msg.a.a;
						return _Utils_Tuple2(
							A2(
								$author$project$Components$Supports$ImportSupports$List$Model$setImportPasses,
								$author$project$Components$Supports$API$ToModel$fromImportListAPI(result),
								model),
							$elm$core$Platform$Cmd$none);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 1:
					return _Utils_Tuple2(
						$author$project$Components$Supports$ImportSupports$List$Model$openImportDrawer(model),
						$elm$core$Platform$Cmd$none);
				case 2:
					var filtre = msg.a;
					return A2(
						$elm$core$Tuple$mapSecond,
						function (c) {
							return A2($elm$core$Platform$Cmd$map, $author$project$Components$Supports$ImportSupports$List$Update$Filtrer, c);
						},
						A2($author$project$Components$Supports$ImportSupports$List$Model$filtrerImportsPasses, filtre, model));
				case 3:
					var drawerMsg = msg.a;
					if (drawerMsg.$ === 1) {
						var msg_ = drawerMsg.a;
						var $temp$model = model,
							$temp$msg = msg_;
						model = $temp$model;
						msg = $temp$msg;
						continue update;
					} else {
						var internalMsg = drawerMsg;
						var execDrawerMsg = function (_v2) {
							var m = _v2.a;
							var msg_i = _v2.b;
							return _Utils_Tuple2(
								A2($author$project$Components$Supports$ImportSupports$List$Model$setSyncDrawerModel, m, model),
								A2($elm$core$Platform$Cmd$map, $author$project$Components$Supports$ImportSupports$List$Update$SyncDrawerEvents, msg_i));
						};
						return execDrawerMsg(
							A2($author$project$Components$Drawer$Update$update, model.w.eq, internalMsg));
					}
				case 4:
					var selectAction = msg.a;
					return _Utils_Tuple2(
						A2($author$project$Components$Supports$ImportSupports$List$Model$setImportTypeSelection, selectAction, model),
						$elm$core$Platform$Cmd$none);
				case 5:
					var importTypeModel = msg.a;
					var selectAction = msg.b;
					return _Utils_Tuple2(
						A3($author$project$Components$Supports$ImportSupports$List$Model$setProduitChoix, selectAction, importTypeModel, model),
						$elm$core$Platform$Cmd$none);
				case 6:
					var importTypeModel = msg.a;
					var selectAction = msg.b;
					return _Utils_Tuple2(
						A3($author$project$Components$Supports$ImportSupports$List$Model$setCabinetChoix, selectAction, importTypeModel, model),
						$elm$core$Platform$Cmd$none);
				case 7:
					var effect = A2($elm$file$File$Select$file, $author$project$Components$Supports$ImportSupports$List$Model$importMimeTypes, $author$project$Components$Supports$ImportSupports$List$Update$Fichier);
					return _Utils_Tuple2(model, effect);
				case 8:
					var file = msg.a;
					return (_Utils_cmp(
						$elm$file$File$size(file),
						$author$project$Components$Supports$ImportSupports$List$Update$size10_MO) > 0) ? _Utils_Tuple2(model, $elm$core$Platform$Cmd$none) : _Utils_Tuple2(
						model,
						A2(
							$elm$core$Task$perform,
							$author$project$Components$Supports$ImportSupports$List$Update$WorkFile(file),
							$elm$file$File$toBytes(file)));
				case 9:
					var file = msg.a;
					var fileBytes = msg.b;
					return _Utils_Tuple2(
						A3($author$project$Components$Supports$ImportSupports$List$Model$setFileToImport, file, fileBytes, model),
						$elm$core$Platform$Cmd$none);
				case 10:
					var importType = msg.a;
					var link = msg.b;
					var fileToImport = msg.c;
					var effect = A2(
						$elm$random$Random$generate,
						A3($author$project$Components$Supports$ImportSupports$List$Update$ImportFile, importType, link, fileToImport),
						$TSFoster$elm_uuid$UUID$generator);
					return _Utils_Tuple2(
						$author$project$Components$Supports$ImportSupports$List$Model$askImport(model),
						effect);
				case 11:
					var importType = msg.a;
					var link = msg.b;
					var fileToImport = msg.c;
					var processUid = msg.d;
					var effect = A4($author$project$Components$Supports$ImportSupports$List$Update$importFile, link, importType, fileToImport, processUid);
					return _Utils_Tuple2(model, effect);
				case 12:
					if (!msg.a.$) {
						return $author$project$Components$Supports$ImportSupports$List$Update$init(
							$author$project$Components$Supports$ImportSupports$List$Model$closeDrawer(
								_Utils_update(
									model,
									{aG: true})));
					} else {
						var e = msg.a.a;
						if (!e.$) {
							return _Utils_Tuple2(
								A2($author$project$Components$Supports$ImportSupports$List$Model$setUnkownErrors, '', model),
								$elm$core$Platform$Cmd$none);
						} else {
							var _v4 = e.a;
							var err = _v4.b;
							return _Utils_Tuple2(
								A2($author$project$Components$Supports$ImportSupports$List$Model$setImportErrors, err, model),
								$elm$core$Platform$Cmd$none);
						}
					}
				default:
					return _Utils_Tuple2(
						$author$project$Components$Supports$ImportSupports$List$Model$showError(model),
						$elm$core$Platform$Cmd$none);
			}
		}
	});
var $author$project$Components$Supports$List$Model$Loaded = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Components$Supports$List$Model$CgpConseil = 0;
var $author$project$Components$Supports$List$Model$MustCompliance = 1;
var $author$project$Components$Supports$API$ToModel$fromListAPI = function (api) {
	return {
		mV: function () {
			var _v0 = api.mV;
			if (!_v0) {
				return 0;
			} else {
				return 1;
			}
		}(),
		nf: api.nf,
		nz: api.nz,
		ov: api.ov,
		oE: api.oE
	};
};
var $author$project$Components$Supports$List$Model$applyFilters = function (model) {
	var filterByNameOrSupportId = function (support) {
		return $author$project$Perfimmo$Search$caseInsensitiveContains(
			{j5: model.e4, kh: support.nz}) || $author$project$Perfimmo$Search$caseInsensitiveContains(
			{j5: model.e4, kh: support.oE});
	};
	var filterByCompany = function (support) {
		var _v0 = A2(
			$elm$core$List$map,
			$elm$core$Tuple$first,
			$inkuzmin$elm_multiselect$Multiselect$getSelectedValues(model.dK));
		if (!_v0.b) {
			return true;
		} else {
			var company = _v0;
			return A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					function (p) {
						return A2($elm$core$List$member, p, company);
					},
					support.ov));
		}
	};
	return _Utils_update(
		model,
		{
			mr: A2(
				$elm$core$List$filter,
				filterByCompany,
				A2($elm$core$List$filter, filterByNameOrSupportId, model.fe))
		});
};
var $elm$core$List$sort = function (xs) {
	return A2($elm$core$List$sortBy, $elm$core$Basics$identity, xs);
};
var $author$project$Components$Supports$List$Model$getSupportCompanies = function (supports) {
	return A2(
		$elm$core$List$map,
		function (societeDeGestion) {
			return _Utils_Tuple2(societeDeGestion, societeDeGestion);
		},
		$elm_community$list_extra$List$Extra$unique(
			$elm$core$List$sort(
				A2(
					$elm$core$List$filterMap,
					function ($) {
						return $.ov;
					},
					supports))));
};
var $author$project$Components$Supports$List$Model$init = function (supports) {
	return $author$project$Components$Supports$List$Model$applyFilters(
		{
			mr: supports,
			dK: A3(
				$inkuzmin$elm_multiselect$Multiselect$initModel,
				$author$project$Components$Supports$List$Model$getSupportCompanies(supports),
				'filtre-company',
				0),
			e4: '',
			fe: supports
		});
};
var $author$project$Components$Supports$List$Model$searchByNameOrSupportId = F2(
	function (input, model) {
		return $author$project$Components$Supports$List$Model$applyFilters(
			_Utils_update(
				model,
				{e4: input}));
	});
var $author$project$Components$Supports$List$Model$searchCompany = F2(
	function (multiselect, model) {
		return $author$project$Components$Supports$List$Model$applyFilters(
			_Utils_update(
				model,
				{dK: multiselect}));
	});
var $author$project$Components$Supports$List$Update$update = F2(
	function (state, msg) {
		var _v0 = _Utils_Tuple2(msg, state);
		if (!_v0.a.$) {
			if (!_v0.b.$) {
				return _Utils_Tuple2(state, $elm$core$Platform$Cmd$none);
			} else {
				if (!_v0.a.a.$) {
					var input = _v0.a.a.a;
					var _v1 = _v0.b;
					var model = _v1.a;
					var listLink = _v1.b;
					return _Utils_Tuple2(
						A2(
							$author$project$Components$Supports$List$Model$Loaded,
							A2($author$project$Components$Supports$List$Model$searchByNameOrSupportId, input, model),
							listLink),
						$elm$core$Platform$Cmd$none);
				} else {
					var multiselect = _v0.a.a.a;
					var _v2 = _v0.b;
					var model = _v2.a;
					var listLink = _v2.b;
					var _v3 = A2($inkuzmin$elm_multiselect$Multiselect$update, multiselect, model.dK);
					var multiModel = _v3.a;
					var multiCmd = _v3.b;
					return _Utils_Tuple2(
						A2(
							$author$project$Components$Supports$List$Model$Loaded,
							A2($author$project$Components$Supports$List$Model$searchCompany, multiModel, model),
							listLink),
						A2(
							$elm$core$Platform$Cmd$map,
							A2($elm$core$Basics$composeL, $author$project$Components$Supports$List$Update$SearchSupports, $author$project$Components$Supports$List$Update$ByCompany),
							multiCmd));
				}
			}
		} else {
			if (!_v0.a.a.$) {
				var result = _v0.a.a.a;
				var model = _v0.b;
				var supports = A2($elm$core$List$map, $author$project$Components$Supports$API$ToModel$fromListAPI, result.ok);
				var listSupportLink = $author$project$Components$Supports$List$Model$getListLink(model);
				return _Utils_Tuple2(
					A2(
						$author$project$Components$Supports$List$Model$Loaded,
						$author$project$Components$Supports$List$Model$init(supports),
						listSupportLink),
					$elm$core$Platform$Cmd$none);
			} else {
				var model = _v0.b;
				return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		}
	});
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $author$project$Routes$RouteBuilder$urlToCmd = F2(
	function (key, urlRequest) {
		if (!urlRequest.$) {
			var url = urlRequest.a;
			return A2(
				$elm$browser$Browser$Navigation$pushUrl,
				key,
				$elm$url$Url$toString(url));
		} else {
			var href = urlRequest.a;
			return $elm$browser$Browser$Navigation$load(href);
		}
	});
var $author$project$Components$ActiveUser$Update$userActived = function (msg) {
	if ((msg.$ === 4) && (!msg.b.$)) {
		return $elm$core$Maybe$Just(0);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Main$MainUpdate$update = F2(
	function (msg, model) {
		var _v0 = function () {
			switch (msg.$) {
				case 0:
					var url = model.aa.k9 + '/api/v1';
					var expected = A2($elm$http$Http$expectJson, $author$project$Main$Msg$PIRootApiLinksFetched, $PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinksDecoder);
					var cmd = $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$withAuthReq(
						A2($PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$Get, url, expected));
					return _Utils_Tuple2(model, cmd);
				case 1:
					if (!msg.a.$) {
						var links = msg.a.a;
						var newCmd = A2(
							$elm$core$Maybe$withDefault,
							$elm$core$Platform$Cmd$none,
							A2(
								$elm$core$Maybe$map,
								A2($elm$core$Basics$composeR, $author$project$Main$Msg$FetchAuthInfo, $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd),
								A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'myInfos', links)));
						var m = A2($author$project$Main$MainModel$setPIApiLinks, model, links);
						var _v2 = $author$project$Main$MainUpdate$calculInitialRoute(m);
						var startingRoute = _v2.a;
						var startingEffect = _v2.b;
						var _v3 = $author$project$Components$Auth$Update$init(links);
						var authModel = _v3.a;
						var effect = _v3.b;
						var authEffect = A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg$AuthAction, effect);
						return _Utils_Tuple2(
							_Utils_update(
								m,
								{fK: authModel, iy: startingRoute}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[authEffect, newCmd, startingEffect])));
					} else {
						var e = msg.a.a;
						return _Utils_Tuple2(
							model,
							$author$project$Main$MainUpdate$popMessages(
								$author$project$Main$Msg$Fail(
									_List_fromArray(
										[
											$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$printHttpError(e)
										]))));
					}
				case 2:
					var l = msg.a;
					return _Utils_Tuple2(
						model,
						A3(
							$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$authRequest,
							l,
							$elm$http$Http$emptyBody,
							A2($elm$http$Http$expectJson, $author$project$Main$Msg$AuthInfoFetched, $author$project$Routes$Auth$authInfoDecoder)));
				case 3:
					if (!msg.a.$) {
						var r = msg.a.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									fL: $elm$core$Maybe$Just(r)
								}),
							$author$project$Main$MainUpdate$intercom_loggedin(r));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 4:
					var m = msg.a;
					var fetchRef = A2(
						$elm$core$Maybe$withDefault,
						$author$project$Main$MainUpdate$intercom_logout(0),
						A2(
							$elm$core$Maybe$map,
							function (_v6) {
								return $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd($author$project$Main$Msg$FetchMustComplianceRootApiLinks);
							},
							$author$project$Components$Auth$Update$isConnected(m)));
					var fetchPI = A2(
						$elm$core$Maybe$withDefault,
						$elm$core$Platform$Cmd$none,
						A2(
							$elm$core$Maybe$map,
							function (_v5) {
								return $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd($author$project$Main$Msg$FetchPIRootApiLinks);
							},
							$author$project$Components$Auth$Update$isLoginAnswered(m)));
					var _v4 = A2($author$project$Components$Auth$Update$update, m, model.fK);
					var newModel = _v4.a;
					var newCmd = _v4.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{fK: newModel}),
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg$AuthAction, newCmd),
									fetchRef,
									fetchPI
								])));
				case 5:
					var action = msg.a;
					var _v7 = model.iy;
					if (_v7.$ === 1) {
						var subModel = _v7.a;
						var startUrl = $author$project$Main$MainModel$getStartUrl(model);
						var userActivedEff = $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
							$author$project$Main$Msg$LinkClicked(
								$elm$browser$Browser$Internal(startUrl)));
						var _v8 = A3(
							$elm$core$Tuple$mapBoth,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Routes$Pages$ActiveUserAccount,
								$author$project$Main$MainUpdate$setPage(model)),
							$elm$core$Platform$Cmd$map($author$project$Main$Msg$ActiveUserComponentAction),
							A2($author$project$Components$ActiveUser$Update$update, action, subModel));
						var m = _v8.a;
						var effect = _v8.b;
						var _v9 = A2(
							$elm$core$Maybe$withDefault,
							_Utils_Tuple2(m, $elm$core$Platform$Cmd$none),
							A2(
								$elm$core$Maybe$map,
								function (_v10) {
									return _Utils_Tuple2(
										_Utils_update(
											m,
											{o$: startUrl}),
										userActivedEff);
								},
								$author$project$Components$ActiveUser$Update$userActived(action)));
						var newModel = _v9.a;
						var userActived = _v9.b;
						return _Utils_Tuple2(
							newModel,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[effect, userActived])));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 6:
					var action = msg.a;
					var _v11 = model.iy;
					if (_v11.$ === 2) {
						var subModel = _v11.a;
						var _v12 = A3(
							$elm$core$Tuple$mapBoth,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Routes$Pages$ForgotPassword,
								$author$project$Main$MainUpdate$setPage(model)),
							$elm$core$Platform$Cmd$map($author$project$Main$Msg$ForgotPasswordComponentAction),
							A2($author$project$Components$ForgotPassword$Update$update, action, subModel));
						var m = _v12.a;
						var effect = _v12.b;
						return _Utils_Tuple2(m, effect);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 7:
					var url = model.aa.ny + '/api/v1';
					var cmd = $PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$withAuthReq(
						A2(
							$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$Get,
							url,
							A2(
								$elm$http$Http$expectJson,
								$author$project$Main$Msg$MustComplianceRootApiLinksFetched,
								$elm$json$Json$Decode$list($PerformanceIMMO$elm_utils$Perfimmo$Http$RestNavigationLinkDecoder$restNavigationLinkDecoder))));
					return _Utils_Tuple2(model, cmd);
				case 8:
					if (!msg.a.$) {
						var links = msg.a.a;
						return _Utils_Tuple2(
							A2($author$project$Main$MainModel$setMustComplianceApiLinks, model, links),
							$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd($author$project$Main$Msg$FetchModulesInfo));
					} else {
						return _Utils_Tuple2(
							model,
							$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd($author$project$Main$Msg$FetchModulesInfo));
					}
				case 9:
					return A2(
						$elm$core$Tuple$pair,
						model,
						$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$withAuthReq(
							A2(
								$PerformanceIMMO$elm_utils$Perfimmo$Http$HttpRequest$Get,
								'/modules.json',
								A2($elm$http$Http$expectJson, $author$project$Main$Msg$ModulesInfoFetched, $author$project$Main$Modules$modulesDecoder))));
				case 10:
					if (!msg.a.$) {
						var modules = msg.a.a;
						return _Utils_Tuple2(
							A2($author$project$Main$MainModel$setModules, model, modules),
							$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
								$author$project$Main$Msg$UrlChanged(model.o$)));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 11:
					var urlRequest = msg.a;
					return _Utils_Tuple2(
						model,
						A2($author$project$Routes$RouteBuilder$urlToCmd, model.fI, urlRequest));
				case 12:
					var url = msg.a;
					var modelWithUpdatedModuleInfo = A2($author$project$Main$MainModel$setUrlAsked, model, url);
					var maybeRoute = A2($author$project$Routes$RouteBuilder$urlToRoute, modelWithUpdatedModuleInfo.cq, url);
					var _v13 = A2(
						$elm$core$Maybe$withDefault,
						_Utils_Tuple2($author$project$Routes$Pages$NotFound, $elm$core$Platform$Cmd$none),
						A2(
							$elm$core$Maybe$map,
							function (route) {
								return A2(
									$author$project$Routes$ComponentBuilder$routeToPage,
									model.aO,
									{fI: model.fI, iZ: model.iy, aM: model.aM, jz: route});
							},
							maybeRoute));
					var page = _v13.a;
					var effect = _v13.b;
					var m = _Utils_update(
						modelWithUpdatedModuleInfo,
						{iy: page});
					var passUrlToSubModule = function () {
						if (page.$ === 16) {
							return $PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
								$author$project$Main$Msg$PassUrlToSubModule(
									$author$project$Main$Msg$LinkClickedInfo(
										$elm$url$Url$toString(url))));
						} else {
							return $elm$core$Platform$Cmd$none;
						}
					}();
					var subModuleUnloadingEffect = A2(
						$elm$core$Maybe$withDefault,
						passUrlToSubModule,
						A2($author$project$Main$MainUpdate$prepareSubModuleLoading, modelWithUpdatedModuleInfo.fw, maybeRoute));
					return _Utils_Tuple2(
						m,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[effect, subModuleUnloadingEffect])));
				case 13:
					var module_ = msg.a;
					var effects = $elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Main$MainUpdate$loadSubModule(module_),
								$PerformanceIMMO$elm_utils$Perfimmo$Cmd$ActionToCmd$msgToElmCmd(
								$author$project$Main$Msg$SubModuleLoaded(module_))
							]));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ig: $elm$core$Maybe$Just(module_)
							}),
						effects);
				case 14:
					var subModule = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								fw: $elm$core$Maybe$Just(subModule),
								ig: $elm$core$Maybe$Nothing
							}),
						$elm$core$Platform$Cmd$none);
				case 15:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{fw: $elm$core$Maybe$Nothing}),
						$author$project$Main$MainUpdate$unloadSubModule(0));
				case 16:
					var info = msg.a;
					return _Utils_Tuple2(
						model,
						$author$project$Main$MainUpdate$sendLinkClickedFromParentApp(info));
				case 17:
					var action = msg.a;
					var _v15 = function () {
						var _v16 = model.iy;
						if (_v16.$ === 3) {
							var step = _v16.a;
							var subModel = _v16.b;
							var _v17 = A2($author$project$Components$Cabinets$List$Update$update, subModel, action);
							var m = _v17.a;
							var c = _v17.b;
							var listComponentResult = _Utils_Tuple2(
								A2($author$project$Routes$Pages$Cabinets, step, m),
								A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg$CabinetsListComponentAction, c));
							var _v18 = A2($author$project$Main$MainUpdate$handleStep, step, listComponentResult);
							var newRoute = _v18.a;
							var newCmd = _v18.b;
							return _Utils_Tuple2(
								A2($author$project$Main$MainUpdate$setPage, model, newRoute),
								newCmd);
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}();
					var updatedModel = _v15.a;
					var effect = _v15.b;
					return _Utils_Tuple2(updatedModel, effect);
				case 18:
					var action = msg.a;
					var _v19 = function () {
						var _v20 = model.iy;
						if (_v20.$ === 5) {
							var step = _v20.a;
							var subModel = _v20.b;
							var _v21 = A2($author$project$Components$Cabinets$Users$List$Update$update, subModel, action);
							var m = _v21.a;
							var c = _v21.b;
							var listComponentResult = _Utils_Tuple2(
								A2($author$project$Routes$Pages$CabinetUsers, step, m),
								A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg$CabinetUsersComponentAction, c));
							var _v22 = A2($author$project$Main$MainUpdate$handleStep, step, listComponentResult);
							var newRoute = _v22.a;
							var newCmd = _v22.b;
							return _Utils_Tuple2(
								A2($author$project$Main$MainUpdate$setPage, model, newRoute),
								newCmd);
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}();
					var updatedModel = _v19.a;
					var effect = _v19.b;
					return _Utils_Tuple2(updatedModel, effect);
				case 19:
					var action = msg.a;
					var _v23 = function () {
						var _v24 = model.iy;
						if (_v24.$ === 4) {
							var subModel = _v24.a;
							return A3(
								$elm$core$Tuple$mapBoth,
								A2(
									$elm$core$Basics$composeR,
									$author$project$Routes$Pages$CabinetNew,
									$author$project$Main$MainUpdate$setPage(model)),
								$elm$core$Platform$Cmd$map($author$project$Main$Msg$CabinetNewComponentAction),
								A2($author$project$Components$Cabinets$New$Update$update, subModel, action));
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}();
					var updatedModel = _v23.a;
					var effect = _v23.b;
					return _Utils_Tuple2(updatedModel, effect);
				case 20:
					var action = msg.a;
					var _v25 = function () {
						var _v26 = model.iy;
						if (_v26.$ === 6) {
							var subModel = _v26.a;
							return A3(
								$elm$core$Tuple$mapBoth,
								A2(
									$elm$core$Basics$composeR,
									$author$project$Routes$Pages$UserNew,
									$author$project$Main$MainUpdate$setPage(model)),
								$elm$core$Platform$Cmd$map($author$project$Main$Msg$UserNewComponentAction),
								A2($author$project$Components$Cabinets$Users$New$Update$update, subModel, action));
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}();
					var updatedModel = _v25.a;
					var effect = _v25.b;
					return _Utils_Tuple2(updatedModel, effect);
				case 21:
					var action = msg.a;
					var _v27 = model.iy;
					if (_v27.$ === 7) {
						var subModel = _v27.a;
						return A3(
							$elm$core$Tuple$mapBoth,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Routes$Pages$CabinetOffresCommerciales,
								$author$project$Main$MainUpdate$setPage(model)),
							$elm$core$Platform$Cmd$map($author$project$Main$Msg$CabinetOffresComponentAction),
							A2($author$project$Components$Cabinets$OffresCommerciales$Update$update, subModel, action));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 22:
					var action = msg.a;
					var _v28 = function () {
						var _v29 = model.iy;
						if (_v29.$ === 8) {
							var step = _v29.a;
							var subModel = _v29.b;
							var _v30 = A2($author$project$Components$Clients$List$Update$update, subModel, action);
							var m = _v30.a;
							var c = _v30.b;
							var listComponentResult = _Utils_Tuple2(
								A2($author$project$Routes$Pages$Clients, step, m),
								A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg$ClientsListComponentAction, c));
							var _v31 = A2($author$project$Main$MainUpdate$handleStep, step, listComponentResult);
							var newRoute = _v31.a;
							var newCmd = _v31.b;
							return _Utils_Tuple2(
								A2($author$project$Main$MainUpdate$setPage, model, newRoute),
								newCmd);
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}();
					var updatedModel = _v28.a;
					var effect = _v28.b;
					return _Utils_Tuple2(updatedModel, effect);
				case 23:
					var action = msg.a;
					var _v32 = function () {
						var _v33 = model.iy;
						if (_v33.$ === 9) {
							var subModel = _v33.a;
							return A3(
								$elm$core$Tuple$mapBoth,
								A2(
									$elm$core$Basics$composeR,
									$author$project$Routes$Pages$ClientDetail,
									$author$project$Main$MainUpdate$setPage(model)),
								$elm$core$Platform$Cmd$map($author$project$Main$Msg$ClientsDetailComponentAction),
								A2($author$project$Components$Clients$Detail$Update$update, subModel, action));
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}();
					var updatedModel = _v32.a;
					var effect = _v32.b;
					return _Utils_Tuple2(updatedModel, effect);
				case 30:
					var action = msg.a;
					var _v34 = model.iy;
					if (!_v34.$) {
						var subModel = _v34.a;
						return A3(
							$elm$core$Tuple$mapBoth,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Routes$Pages$Landing,
								$author$project$Main$MainUpdate$setPage(model)),
							$elm$core$Platform$Cmd$map($author$project$Main$Msg$LandingComponentAction),
							A2($author$project$Components$Landing$Update$update, action, subModel));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 24:
					var action = msg.a;
					var _v35 = model.iy;
					if (_v35.$ === 10) {
						var step = _v35.a;
						var subModel = _v35.b;
						var _v36 = A2($author$project$Components$Produits$List$Update$update, action, subModel);
						var m = _v36.a;
						var c = _v36.b;
						var listComponentResult = _Utils_Tuple2(
							A2($author$project$Routes$Pages$Produits, step, m),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg$ProduitsAction, c));
						var _v37 = A2($author$project$Main$MainUpdate$handleStep, step, listComponentResult);
						var newRoute = _v37.a;
						var newCmd = _v37.b;
						return _Utils_Tuple2(
							A2($author$project$Main$MainUpdate$setPage, model, newRoute),
							newCmd);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 25:
					var action = msg.a;
					var _v38 = model.iy;
					if (_v38.$ === 11) {
						var subModel = _v38.a;
						var _v39 = A2($author$project$Components$Produits$Details$Update$update, action, subModel);
						var updatedModel = _v39.a;
						var effect = _v39.b;
						return _Utils_Tuple2(
							A2(
								$author$project$Main$MainUpdate$setPage,
								model,
								$author$project$Routes$Pages$ProduitDetails(updatedModel)),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg$ProduitDetailsAction, effect));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 26:
					var action = msg.a;
					var _v40 = model.iy;
					if (_v40.$ === 12) {
						var step = _v40.a;
						var subModel = _v40.b;
						var _v41 = A2($author$project$Components$Supports$ImportSupports$List$Update$update, subModel, action);
						var m = _v41.a;
						var c = _v41.b;
						var listComponentResult = _Utils_Tuple2(
							A2($author$project$Routes$Pages$ImportSupports, step, m),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg$ImportSupportsAction, c));
						var _v42 = A2($author$project$Main$MainUpdate$handleStep, step, listComponentResult);
						var newRoute = _v42.a;
						var newCmd = _v42.b;
						return _Utils_Tuple2(
							A2($author$project$Main$MainUpdate$setPage, model, newRoute),
							newCmd);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 27:
					var action = msg.a;
					var _v43 = function () {
						var _v44 = model.iy;
						if (_v44.$ === 13) {
							var subModel = _v44.a;
							return A3(
								$elm$core$Tuple$mapBoth,
								A2(
									$elm$core$Basics$composeR,
									$author$project$Routes$Pages$ImportSupportDetail,
									$author$project$Main$MainUpdate$setPage(model)),
								$elm$core$Platform$Cmd$map($author$project$Main$Msg$ImportSupportDetailAction),
								A2($author$project$Components$Supports$ImportSupports$Detail$Update$update, subModel, action));
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}();
					var updatedModel = _v43.a;
					var effect = _v43.b;
					return _Utils_Tuple2(updatedModel, effect);
				case 28:
					var action = msg.a;
					var _v45 = model.iy;
					if (_v45.$ === 14) {
						var step = _v45.a;
						var subModel = _v45.b;
						var _v46 = A2($author$project$Components$Supports$List$Update$update, subModel, action);
						var m = _v46.a;
						var c = _v46.b;
						var listComponentResult = _Utils_Tuple2(
							A2($author$project$Routes$Pages$ListSupports, step, m),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg$ListSupportsAction, c));
						var _v47 = A2($author$project$Main$MainUpdate$handleStep, step, listComponentResult);
						var newRoute = _v47.a;
						var newCmd = _v47.b;
						return _Utils_Tuple2(
							A2($author$project$Main$MainUpdate$setPage, model, newRoute),
							newCmd);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 29:
					var action = msg.a;
					var _v48 = model.iy;
					if (_v48.$ === 15) {
						var subModel = _v48.a;
						var _v49 = A2($author$project$Components$Supports$Details$Update$update, subModel, action);
						var m = _v49.a;
						var c = _v49.b;
						var listComponentResult = _Utils_Tuple2(
							$author$project$Routes$Pages$SupportDetail(m),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg$SupportDetailAction, c));
						var _v50 = A2($author$project$Main$MainUpdate$handleStep, $elm$core$Maybe$Nothing, listComponentResult);
						var newRoute = _v50.a;
						var newCmd = _v50.b;
						return _Utils_Tuple2(
							A2($author$project$Main$MainUpdate$setPage, model, newRoute),
							newCmd);
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 31:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 32:
					var action = msg.a;
					var _v51 = A2($author$project$Components$Errors$Errors$update, action, model.ch);
					var newModel = _v51.a;
					var newCmd = _v51.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{ch: newModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg$AlertAction, newCmd));
				case 33:
					var m = msg.a;
					var _v52 = A2($author$project$Components$Loading$Update$update, m, model.aG);
					var newModel = _v52.a;
					var newCmd = _v52.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aG: newModel}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$Msg$LoadingAction, newCmd));
				default:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
			}
		}();
		var newNewModel = _v0.a;
		var newNewCmd = _v0.b;
		return _Utils_Tuple2(
			newNewModel,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						newNewCmd,
						$author$project$Main$MainUpdate$loading(msg),
						$author$project$Main$MainUpdate$popMessages(msg),
						A2($author$project$Main$MainUpdate$navigate, model.fI, msg)
					])));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$Node = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$Node;
var $rtfeldman$elm_css$Html$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$node;
var $rtfeldman$elm_css$Html$Styled$div = $rtfeldman$elm_css$Html$Styled$node('div');
var $rtfeldman$elm_css$VirtualDom$Styled$Attribute = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$property = F2(
	function (key, value) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$property, key, value),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$id = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('id');
var $rtfeldman$elm_css$VirtualDom$Styled$KeyedNode = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$KeyedNodeNS = F4(
	function (a, b, c, d) {
		return {$: 3, a: a, b: b, c: c, d: d};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$NodeNS = F4(
	function (a, b, c, d) {
		return {$: 1, a: a, b: b, c: c, d: d};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$Unstyled = function (a) {
	return {$: 4, a: a};
};
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $rtfeldman$elm_css$VirtualDom$Styled$mapAttribute = F2(
	function (transform, _v0) {
		var prop = _v0.a;
		var styles = _v0.b;
		var classname = _v0.c;
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$mapAttribute, transform, prop),
			styles,
			classname);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$map = F2(
	function (transform, vdomNode) {
		switch (vdomNode.$) {
			case 0:
				var elemType = vdomNode.a;
				var properties = vdomNode.b;
				var children = vdomNode.c;
				return A3(
					$rtfeldman$elm_css$VirtualDom$Styled$Node,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$map(transform),
						children));
			case 1:
				var ns = vdomNode.a;
				var elemType = vdomNode.b;
				var properties = vdomNode.c;
				var children = vdomNode.d;
				return A4(
					$rtfeldman$elm_css$VirtualDom$Styled$NodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$map(transform),
						children));
			case 2:
				var elemType = vdomNode.a;
				var properties = vdomNode.b;
				var children = vdomNode.c;
				return A3(
					$rtfeldman$elm_css$VirtualDom$Styled$KeyedNode,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						function (_v1) {
							var key = _v1.a;
							var child = _v1.b;
							return _Utils_Tuple2(
								key,
								A2($rtfeldman$elm_css$VirtualDom$Styled$map, transform, child));
						},
						children));
			case 3:
				var ns = vdomNode.a;
				var elemType = vdomNode.b;
				var properties = vdomNode.c;
				var children = vdomNode.d;
				return A4(
					$rtfeldman$elm_css$VirtualDom$Styled$KeyedNodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						function (_v2) {
							var key = _v2.a;
							var child = _v2.b;
							return _Utils_Tuple2(
								key,
								A2($rtfeldman$elm_css$VirtualDom$Styled$map, transform, child));
						},
						children));
			default:
				var vdom = vdomNode.a;
				return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
					A2($elm$virtual_dom$VirtualDom$map, transform, vdom));
		}
	});
var $rtfeldman$elm_css$Html$Styled$map = $rtfeldman$elm_css$VirtualDom$Styled$map;
var $author$project$Components$Errors$Errors$ClearAlertAction = {$: 1};
var $rtfeldman$elm_css$Css$Preprocess$ApplyStyles = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$batch = $rtfeldman$elm_css$Css$Preprocess$ApplyStyles;
var $rtfeldman$elm_css$Html$Styled$button = $rtfeldman$elm_css$Html$Styled$node('button');
var $rtfeldman$elm_css$Html$Styled$Attributes$class = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('className');
var $rtfeldman$elm_css$VirtualDom$Styled$murmurSeed = 15739;
var $rtfeldman$elm_css$VirtualDom$Styled$getClassname = function (styles) {
	return $elm$core$List$isEmpty(styles) ? 'unstyled' : A2(
		$elm$core$String$cons,
		'_',
		$rtfeldman$elm_hex$Hex$toString(
			A2(
				$rtfeldman$elm_css$ElmCssVendor$Murmur3$hashString,
				$rtfeldman$elm_css$VirtualDom$Styled$murmurSeed,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
					$elm$core$List$singleton(
						$rtfeldman$elm_css$Css$Preprocess$stylesheet(
							$elm$core$List$singleton(
								A2(
									$rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
									styles,
									$rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(_List_Nil)))))))));
};
var $rtfeldman$elm_css$Html$Styled$Internal$css = function (styles) {
	var classname = $rtfeldman$elm_css$VirtualDom$Styled$getClassname(styles);
	var classProperty = A2(
		$elm$virtual_dom$VirtualDom$property,
		'className',
		$elm$json$Json$Encode$string(classname));
	return A3($rtfeldman$elm_css$VirtualDom$Styled$Attribute, classProperty, styles, classname);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$css = $rtfeldman$elm_css$Html$Styled$Internal$css;
var $rtfeldman$elm_css$Html$Styled$h3 = $rtfeldman$elm_css$Html$Styled$node('h3');
var $rtfeldman$elm_css$Css$Transitions$Linear = {$: 1};
var $rtfeldman$elm_css$Css$Transitions$linear = $rtfeldman$elm_css$Css$Transitions$Linear;
var $rtfeldman$elm_css$Css$Structure$Compatible = 0;
var $rtfeldman$elm_css$Css$UnitlessFloat = 0;
var $rtfeldman$elm_css$Css$num = function (val) {
	return {
		a3: 0,
		an: 0,
		nL: 0,
		dm: 0,
		aJ: val,
		bU: '',
		cJ: 0,
		o6: $elm$core$String$fromFloat(val)
	};
};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $rtfeldman$elm_css$VirtualDom$Styled$on = F2(
	function (eventName, handler) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$on, eventName, handler),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Events$on = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $rtfeldman$elm_css$Html$Styled$Events$onClick = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $rtfeldman$elm_css$Css$Preprocess$AppendProperty = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$property = F2(
	function (key, value) {
		return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var $rtfeldman$elm_css$Css$prop1 = F2(
	function (key, arg) {
		return A2($rtfeldman$elm_css$Css$property, key, arg.o6);
	});
var $rtfeldman$elm_css$Css$opacity = $rtfeldman$elm_css$Css$prop1('opacity');
var $rtfeldman$elm_css$Css$Transitions$Opacity = 73;
var $rtfeldman$elm_css$Css$Transitions$Transition = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$Transitions$fullTransition = F4(
	function (animation, duration, delay, timing) {
		return {
			cO: animation,
			c1: $elm$core$Maybe$Just(delay),
			gS: duration,
			dV: $elm$core$Maybe$Just(timing)
		};
	});
var $rtfeldman$elm_css$Css$Transitions$opacity3 = $rtfeldman$elm_css$Css$Transitions$fullTransition(73);
var $rtfeldman$elm_css$Html$Styled$p = $rtfeldman$elm_css$Html$Styled$node('p');
var $rtfeldman$elm_css$VirtualDom$Styled$text = function (str) {
	return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
		$elm$virtual_dom$VirtualDom$text(str));
};
var $rtfeldman$elm_css$Html$Styled$text = $rtfeldman$elm_css$VirtualDom$Styled$text;
var $rtfeldman$elm_css$Css$Transitions$propToString = function (prop) {
	switch (prop) {
		case 0:
			return 'background';
		case 1:
			return 'background-color';
		case 2:
			return 'background-position';
		case 3:
			return 'background-size';
		case 4:
			return 'border';
		case 5:
			return 'border-bottom';
		case 6:
			return 'border-bottom-color';
		case 7:
			return 'border-bottom-left-radius';
		case 8:
			return 'border-bottom-right-radius';
		case 9:
			return 'border-bottom-width';
		case 10:
			return 'border-color';
		case 11:
			return 'border-left';
		case 12:
			return 'border-left-color';
		case 13:
			return 'border-left-width';
		case 14:
			return 'border-radius';
		case 15:
			return 'border-right';
		case 16:
			return 'border-right-color';
		case 17:
			return 'border-right-width';
		case 18:
			return 'border-top';
		case 19:
			return 'border-top-color';
		case 20:
			return 'border-top-left-radius';
		case 21:
			return 'border-top-right-radius';
		case 22:
			return 'border-top-width';
		case 23:
			return 'border-width';
		case 24:
			return 'bottom';
		case 25:
			return 'box-shadow';
		case 26:
			return 'caret-color';
		case 27:
			return 'clip';
		case 28:
			return 'clip-path';
		case 29:
			return 'color';
		case 30:
			return 'column-count';
		case 31:
			return 'column-gap';
		case 32:
			return 'column-rule';
		case 33:
			return 'column-rule-color';
		case 34:
			return 'column-rule-width';
		case 35:
			return 'column-width';
		case 36:
			return 'columns';
		case 37:
			return 'filter';
		case 38:
			return 'flex';
		case 39:
			return 'flex-basis';
		case 40:
			return 'flex-grow';
		case 41:
			return 'flex-shrink';
		case 42:
			return 'font';
		case 43:
			return 'font-size';
		case 44:
			return 'font-size-adjust';
		case 45:
			return 'font-stretch';
		case 46:
			return 'font-variation-settings';
		case 47:
			return 'font-weight';
		case 48:
			return 'grid-column-gap';
		case 49:
			return 'grid-gap';
		case 50:
			return 'grid-row-gap';
		case 51:
			return 'height';
		case 52:
			return 'left';
		case 53:
			return 'letter-spacing';
		case 54:
			return 'line-height';
		case 55:
			return 'margin';
		case 56:
			return 'margin-bottom';
		case 57:
			return 'margin-left';
		case 58:
			return 'margin-right';
		case 59:
			return 'margin-top';
		case 60:
			return 'mask';
		case 61:
			return 'mask-position';
		case 62:
			return 'mask-size';
		case 63:
			return 'max-height';
		case 64:
			return 'max-width';
		case 65:
			return 'min-height';
		case 66:
			return 'min-width';
		case 67:
			return 'object-position';
		case 68:
			return 'offset';
		case 69:
			return 'offset-anchor';
		case 70:
			return 'offset-distance';
		case 71:
			return 'offset-path';
		case 72:
			return 'offset-rotate';
		case 73:
			return 'opacity';
		case 74:
			return 'order';
		case 75:
			return 'outline';
		case 76:
			return 'outline-color';
		case 77:
			return 'outline-offset';
		case 78:
			return 'outline-width';
		case 79:
			return 'padding';
		case 80:
			return 'padding-bottom';
		case 81:
			return 'padding-left';
		case 82:
			return 'padding-right';
		case 83:
			return 'padding-top';
		case 84:
			return 'right';
		case 85:
			return 'tab-size';
		case 86:
			return 'text-indent';
		case 87:
			return 'text-shadow';
		case 88:
			return 'top';
		case 89:
			return 'transform';
		case 90:
			return 'transform-origin';
		case 91:
			return 'vertical-align';
		case 92:
			return 'visibility';
		case 93:
			return 'width';
		case 94:
			return 'word-spacing';
		default:
			return 'z-index';
	}
};
var $rtfeldman$elm_css$Css$Transitions$timeToString = function (time) {
	return $elm$core$String$fromFloat(time) + 'ms';
};
var $rtfeldman$elm_css$Css$Transitions$timingFunctionToString = function (tf) {
	switch (tf.$) {
		case 0:
			return 'ease';
		case 1:
			return 'linear';
		case 2:
			return 'ease-in';
		case 3:
			return 'ease-out';
		case 4:
			return 'ease-in-out';
		case 5:
			return 'step-start';
		case 6:
			return 'step-end';
		default:
			var _float = tf.a;
			var float2 = tf.b;
			var float3 = tf.c;
			var float4 = tf.d;
			return 'cubic-bezier(' + ($elm$core$String$fromFloat(_float) + (' , ' + ($elm$core$String$fromFloat(float2) + (' , ' + ($elm$core$String$fromFloat(float3) + (' , ' + ($elm$core$String$fromFloat(float4) + ')')))))));
	}
};
var $rtfeldman$elm_css$Css$Transitions$transition = function (options) {
	var v = A3(
		$elm$core$String$slice,
		0,
		-1,
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, s) {
					var animation = _v0.cO;
					var duration = _v0.gS;
					var delay = _v0.c1;
					var timing = _v0.dV;
					return s + (A2(
						$elm$core$String$join,
						' ',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Transitions$propToString(animation),
								$rtfeldman$elm_css$Css$Transitions$timeToString(duration),
								A2(
								$elm$core$Maybe$withDefault,
								'',
								A2($elm$core$Maybe$map, $rtfeldman$elm_css$Css$Transitions$timeToString, delay)),
								A2(
								$elm$core$Maybe$withDefault,
								'',
								A2($elm$core$Maybe$map, $rtfeldman$elm_css$Css$Transitions$timingFunctionToString, timing))
							])) + ',');
				}),
			'',
			options));
	return A2($rtfeldman$elm_css$Css$property, 'transition', v);
};
var $rtfeldman$elm_css$Css$UnitlessInteger = 0;
var $rtfeldman$elm_css$Css$zero = {y: 0, bA: 0, aF: 0, bB: 0, bC: 0, a2: 0, a3: 0, nL: 0, aJ: 0, $7: 0, bU: '', cJ: 0, o6: '0'};
var $author$project$Components$Errors$Errors$view = function (model) {
	var _v0 = function () {
		var _v1 = model.cp;
		if (!_v1.$) {
			if (!_v1.a.$) {
				var messages = _v1.a.a;
				var y = A2(
					$rtfeldman$elm_css$Html$Styled$h3,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Info')
						]));
				var x = A2(
					$elm$core$List$map,
					function (err) {
						return A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(err)
								]));
					},
					$mgold$elm_nonempty_list$List$Nonempty$toList(messages));
				return _Utils_Tuple3(y, x, ' levelInfo');
			} else {
				var errors = _v1.a.a;
				var y = A2(
					$rtfeldman$elm_css$Html$Styled$h3,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Erreur')
						]));
				var x = A2(
					$elm$core$List$map,
					function (err) {
						return A2(
							$rtfeldman$elm_css$Html$Styled$p,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(err)
								]));
					},
					$mgold$elm_nonempty_list$List$Nonempty$toList(errors));
				return _Utils_Tuple3(y, x, '');
			}
		} else {
			return _Utils_Tuple3(
				$rtfeldman$elm_css$Html$Styled$text(''),
				_List_Nil,
				'');
		}
	}();
	var displayHeader = _v0.a;
	var displayErrors = _v0.b;
	var levelClass = _v0.c;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('messageZone' + levelClass),
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$opacity($rtfeldman$elm_css$Css$zero),
						$rtfeldman$elm_css$Css$Transitions$transition(
						_List_fromArray(
							[
								A3($rtfeldman$elm_css$Css$Transitions$opacity3, 1000, 0, $rtfeldman$elm_css$Css$Transitions$linear)
							])),
						$rtfeldman$elm_css$Css$batch(
						A2(
							$elm$core$Maybe$withDefault,
							_List_Nil,
							A2(
								$elm$core$Maybe$map,
								$elm$core$Basics$always(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$opacity(
											$rtfeldman$elm_css$Css$num(1))
										])),
								model.cp)))
					]))
			]),
		_List_fromArray(
			[
				displayHeader,
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('messageZoneContent')
					]),
				displayErrors),
				A2(
				$rtfeldman$elm_css$Html$Styled$button,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('close'),
						$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Errors$Errors$ClearAlertAction)
					]),
				_List_Nil)
			]));
};
var $author$project$Main$MainView$errorsView = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('errors')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$author$project$Main$Msg$AlertAction,
				$author$project$Components$Errors$Errors$view(model.ch))
			]));
};
var $author$project$Components$Auth$Update$Logout = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_css$Html$Styled$a = $rtfeldman$elm_css$Html$Styled$node('a');
var $rtfeldman$elm_css$Html$Styled$header = $rtfeldman$elm_css$Html$Styled$node('header');
var $rtfeldman$elm_css$Html$Styled$Attributes$href = function (url) {
	return A2($rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'href', url);
};
var $rtfeldman$elm_css$Html$Styled$li = $rtfeldman$elm_css$Html$Styled$node('li');
var $rtfeldman$elm_css$Html$Styled$span = $rtfeldman$elm_css$Html$Styled$node('span');
var $author$project$Main$MenuView$classicMenu = function (m) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$a,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$href(m.jz)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(m.m9)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(m.m9)
					]))
			]));
};
var $elm$core$String$endsWith = _String_endsWith;
var $rtfeldman$elm_css$Html$Styled$ul = $rtfeldman$elm_css$Html$Styled$node('ul');
var $author$project$Main$MenuView$subMenuDirectLink = F3(
	function (urlPath, module_, rootApiLinks) {
		var subEntry = function (l) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$li,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						_Utils_eq(urlPath, l.jz) ? 'current' : '')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$href(l.jz)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(l.m9)
							]))
					]));
		};
		var hasRightTo = F2(
			function (links, link) {
				return (module_.jz === '/activites') ? A2(
					$elm$core$List$any,
					function (l) {
						return A2($elm$core$String$endsWith, l.jm, link.jz);
					},
					links) : true;
			});
		var displayMenuEntry = function (block) {
			return (module_.jz === '/activites') ? ($elm$core$List$isEmpty(rootApiLinks.d0) ? _List_Nil : block) : block;
		};
		return displayMenuEntry(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$button,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(module_.m9)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(module_.m9)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$ul,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('subMenuEntries')
						]),
					A2(
						$elm$core$List$map,
						subEntry,
						A2(
							$elm$core$List$filter,
							hasRightTo(rootApiLinks.d0),
							module_.lU)))
				]));
	});
var $author$project$Main$MenuView$menu = F4(
	function (urlPath, activeModule, rootApiLinks, module_) {
		var current = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (active) {
					return _Utils_eq(module_.jz, active.jz) ? ' current' : '';
				},
				activeModule));
		var childs = $elm$core$List$isEmpty(module_.lU) ? _List_fromArray(
			[
				$author$project$Main$MenuView$classicMenu(module_)
			]) : A3($author$project$Main$MenuView$subMenuDirectLink, urlPath, module_, rootApiLinks);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('menuEntry'),
					$rtfeldman$elm_css$Html$Styled$Attributes$class(current)
				]),
			childs);
	});
var $author$project$Main$MenuView$menuAccueil = function (model) {
	var root = $author$project$Routes$Model$routes.op.dX(0);
	var currentPath = model.o$.nX;
	return _List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('menuEntry'),
					$rtfeldman$elm_css$Html$Styled$Attributes$class(
					_Utils_eq(currentPath, root) ? 'current' : '')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$href(root)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Accueil')
						]))
				]))
		]);
};
var $author$project$Main$MenuView$MenuEntry = F3(
	function (route, label, link) {
		return {m9: label, hX: link, jz: route};
	});
var $author$project$Main$MenuView$MenuWithSubEntry = F2(
	function (label, subEntries) {
		return {m9: label, j2: subEntries};
	});
var $author$project$Main$MenuView$subMenuDirectLink2 = F2(
	function (currentPath, menuEntry) {
		var subEntry = function (subMenu) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$li,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class(
						_Utils_eq(currentPath, subMenu.jz) ? 'current' : '')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$href(subMenu.jz)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(subMenu.m9)
							]))
					]));
		};
		return _List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$button,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(menuEntry.m9)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(menuEntry.m9)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$ul,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('subMenuEntries')
					]),
				A2($elm$core$List$map, subEntry, menuEntry.j2))
			]);
	});
var $author$project$Main$MenuView$oneCabinetMenu = F2(
	function (links, currentPath) {
		var subMenuRoute = function (r) {
			return '/cabinets' + r;
		};
		var menuEntry = A2(
			$author$project$Main$MenuView$MenuWithSubEntry,
			'Cabinet',
			A2(
				$elm$core$List$filter,
				function (m) {
					return A2(
						$elm$core$List$member,
						m.hX,
						A2(
							$elm$core$List$map,
							function ($) {
								return $.jm;
							},
							links.kW));
				},
				_List_fromArray(
					[
						A3(
						$author$project$Main$MenuView$MenuEntry,
						subMenuRoute('/informations'),
						'Informations',
						'cabinets'),
						A3(
						$author$project$Main$MenuView$MenuEntry,
						subMenuRoute('/utilisateurs'),
						'Utilisateurs',
						'mycabinet/users'),
						A3(
						$author$project$Main$MenuView$MenuEntry,
						subMenuRoute('/offres'),
						'Offres commerciales',
						'mycabinet/offres_commerciales')
					])));
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('menuEntry'),
					$rtfeldman$elm_css$Html$Styled$Attributes$class(
					A2($elm$core$String$startsWith, '/cabinets', currentPath) ? 'current' : '')
				]),
			A2($author$project$Main$MenuView$subMenuDirectLink2, currentPath, menuEntry));
	});
var $author$project$Main$MenuView$rootMenuEntry = F3(
	function (navigationLink, model, m) {
		var isCurrent = A2(
			$elm$core$Maybe$withDefault,
			$rtfeldman$elm_css$Html$Styled$Attributes$class(''),
			A2(
				$elm$core$Maybe$map,
				function (_v1) {
					return $rtfeldman$elm_css$Html$Styled$Attributes$class('current');
				},
				A2(
					$elm_community$list_extra$List$Extra$find,
					function (r) {
						return A2($elm$core$String$startsWith, r, model.o$.nX);
					},
					m.dD)));
		var menuEntry = function (_v0) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('menuEntry'),
						isCurrent
					]),
				_List_fromArray(
					[
						$author$project$Main$MenuView$classicMenu(m)
					]));
		};
		return A2($elm$core$Maybe$map, menuEntry, navigationLink);
	});
var $author$project$Main$MenuView$menuCabinets = function (model) {
	var rightTo = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'cabinets', model.aM.kW);
	var severalCabinetsEntry = A3(
		$author$project$Main$MenuView$rootMenuEntry,
		rightTo,
		model,
		{
			m9: 'Cabinets',
			jz: $author$project$Routes$Model$routes.ll.hY.dX(0),
			dD: _List_fromArray(
				[
					$author$project$Routes$Model$routes.ll.hY.dX(0)
				])
		});
	return $elm$core$List$singleton(
		A2(
			$elm$core$Maybe$withDefault,
			A2($author$project$Main$MenuView$oneCabinetMenu, model.aM, model.o$.nX),
			A2(
				$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
				function (_v0) {
					return severalCabinetsEntry;
				},
				A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'cabinets/create', model.aM.kW))));
};
var $author$project$Main$MenuView$menuMyClients = function (model) {
	var rightTo = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'clients', model.aM.ca);
	return $elm_community$maybe_extra$Maybe$Extra$toList(
		A3(
			$author$project$Main$MenuView$rootMenuEntry,
			rightTo,
			model,
			{
				m9: 'Clients',
				jz: $author$project$Routes$Model$routes.ca.hY.dX(0),
				dD: _List_fromArray(
					[
						$author$project$Routes$Model$routes.ca.hY.dX(0)
					])
			}));
};
var $rtfeldman$elm_css$Html$Styled$nav = $rtfeldman$elm_css$Html$Styled$node('nav');
var $author$project$Main$MenuView$produitsMenu = function (model) {
	var rightTo = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'produits', model.aM.kW);
	var menuLabel = 'Produits & Supports';
	return $elm_community$maybe_extra$Maybe$Extra$toList(
		A3(
			$author$project$Main$MenuView$rootMenuEntry,
			rightTo,
			model,
			{
				m9: menuLabel,
				jz: $author$project$Routes$Model$routes.n7.hY.dX(0),
				dD: _List_fromArray(
					[
						$author$project$Routes$Model$routes.n7.hY.dX(0),
						$author$project$Routes$Model$routes.fe.hY.dX(0)
					])
			}));
};
var $author$project$Main$MenuView$menuView = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$nav,
		_List_Nil,
		_Utils_ap(
			$author$project$Main$MenuView$menuAccueil(model),
			_Utils_ap(
				$author$project$Main$MenuView$menuCabinets(model),
				_Utils_ap(
					$author$project$Main$MenuView$menuMyClients(model),
					_Utils_ap(
						$author$project$Main$MenuView$produitsMenu(model),
						A2(
							$elm$core$List$map,
							A3($author$project$Main$MenuView$menu, model.o$.nX, model.fw, model.aM),
							model.cq))))));
};
var $rtfeldman$elm_css$Html$Styled$Attributes$title = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('title');
var $author$project$Components$Loading$View$view = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('loading')
			]),
		_List_fromArray(
			[
				function () {
				var _v0 = model.ng;
				if (!_v0) {
					return $rtfeldman$elm_css$Html$Styled$text('');
				} else {
					return $rtfeldman$elm_css$Html$Styled$text('Chargement en cours...');
				}
			}()
			]));
};
var $author$project$Main$MainView$headerView = F2(
	function (model, logoutRel) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$header,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('header-left')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('logo'),
									$rtfeldman$elm_css$Html$Styled$Attributes$href('/')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$span,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('MustCompliance')
										]))
								])),
							$author$project$Components$Loading$View$view(model.aG)
						])),
					$author$project$Main$MenuView$menuView(model),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('header-right')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('account')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$button,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$title(
											A2(
												$elm$core$Maybe$withDefault,
												'',
												A2(
													$elm$core$Maybe$map,
													function ($) {
														return $.nh;
													},
													model.fL)))
										]),
									_List_Nil),
									A2(
									$rtfeldman$elm_css$Html$Styled$ul,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$li,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$button,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('logout'),
															$rtfeldman$elm_css$Html$Styled$Events$onClick(
															$author$project$Main$Msg$AuthAction(
																$author$project$Components$Auth$Update$Logout(logoutRel.hA)))
														]),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('Déconnexion')
														]))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Components$ActiveUser$Update$AskActiveUser = {$: 2};
var $author$project$Components$ActiveUser$Update$SetConfirmPassword = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$ActiveUser$Update$SetPassword = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$disabled = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('disabled');
var $rtfeldman$elm_css$Html$Styled$form = $rtfeldman$elm_css$Html$Styled$node('form');
var $rtfeldman$elm_css$Html$Styled$h1 = $rtfeldman$elm_css$Html$Styled$node('h1');
var $rtfeldman$elm_css$Html$Styled$input = $rtfeldman$elm_css$Html$Styled$node('input');
var $rtfeldman$elm_css$Html$Styled$label = $rtfeldman$elm_css$Html$Styled$node('label');
var $rtfeldman$elm_css$Html$Styled$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $rtfeldman$elm_css$Html$Styled$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $rtfeldman$elm_css$Html$Styled$Events$onInput = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$rtfeldman$elm_css$Html$Styled$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$targetValue)));
};
var $rtfeldman$elm_css$Html$Styled$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $rtfeldman$elm_css$Html$Styled$Events$onSubmit = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$rtfeldman$elm_css$Html$Styled$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $rtfeldman$elm_css$Html$Styled$Attributes$property = $rtfeldman$elm_css$VirtualDom$Styled$property;
var $rtfeldman$elm_css$Html$Styled$Attributes$type_ = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('type');
var $rtfeldman$elm_css$Html$Styled$Attributes$value = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('value');
var $rtfeldman$elm_css$Html$Styled$footer = $rtfeldman$elm_css$Html$Styled$node('footer');
var $rtfeldman$elm_css$Html$Styled$h6 = $rtfeldman$elm_css$Html$Styled$node('h6');
var $rtfeldman$elm_css$Html$Styled$hr = $rtfeldman$elm_css$Html$Styled$node('hr');
var $rtfeldman$elm_css$Html$Styled$img = $rtfeldman$elm_css$Html$Styled$node('img');
var $rtfeldman$elm_css$Html$Styled$Attributes$src = function (url) {
	return A2($rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'src', url);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$target = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('target');
var $author$project$Components$Website$FooterView$view = A2(
	$rtfeldman$elm_css$Html$Styled$footer,
	_List_Nil,
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$img,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$src('https://res.cloudinary.com/performance-immo/image/upload/v1693485141/incomon-prod/wave.svg')
				]),
			_List_Nil),
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('content')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('top')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('logo'),
									$rtfeldman$elm_css$Html$Styled$Attributes$href('/')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$span,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('MustCompliance')
										]))
								])),
							A2($rtfeldman$elm_css$Html$Styled$hr, _List_Nil, _List_Nil),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('contact')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$a,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mail'),
											$rtfeldman$elm_css$Html$Styled$Attributes$href('mailto:contact@mustcompliance.fr'),
											$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
										]),
									_List_Nil),
									A2(
									$rtfeldman$elm_css$Html$Styled$a,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('linkedin'),
											$rtfeldman$elm_css$Html$Styled$Attributes$href('https://www.linkedin.com/company/mustcompliance/'),
											$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
										]),
									_List_Nil)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('bottom')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('baseline')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$span,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('MustCompliance, votre compliance ')
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$span,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('officer virtuel au quotidien')
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$nav,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('col')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$h6,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Notre offre')
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$ul,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$li,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$a,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$href('https://mustcompliance.fr/generation-de-documents/')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('Génération de documents')
																]))
														])),
													A2(
													$rtfeldman$elm_css$Html$Styled$li,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$a,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$href('https://mustcompliance.fr/tarifs/')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('Tarifs')
																]))
														])),
													A2(
													$rtfeldman$elm_css$Html$Styled$li,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$a,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$href('https://mustcompliance.fr/demo/')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('Demander une démo')
																]))
														]))
												]))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('col')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$h6,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Notre société')
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$ul,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$li,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$a,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$href('https://mustcompliance.fr/qui-sommes-nous/')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('Qui sommes-nous ?')
																]))
														])),
													A2(
													$rtfeldman$elm_css$Html$Styled$li,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$a,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$href('https://mustcompliance.fr/contact/')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('Nous contacter')
																]))
														])),
													A2(
													$rtfeldman$elm_css$Html$Styled$li,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$a,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$href('https://mustcompliance.fr/formulaire_webinaires_conformite/')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('News – Webinaires conformité')
																]))
														])),
													A2(
													$rtfeldman$elm_css$Html$Styled$li,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$a,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$href('https://mustcompliance.fr/mentions-legales/')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('Mentions légales et politique de confidentialité')
																]))
														])),
													A2(
													$rtfeldman$elm_css$Html$Styled$li,
													_List_Nil,
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$a,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$href('https://mustcompliance.fr/cgu/')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('Conditions générales d’utilisation')
																]))
														]))
												]))
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('copyright')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Copyright © 2025 MustCompliance, façonné par InComon')
								]))
						]))
				]))
		]));
var $author$project$Components$Website$HeaderView$view = A2(
	$rtfeldman$elm_css$Html$Styled$header,
	_List_Nil,
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('header-left')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('logo'),
							$rtfeldman$elm_css$Html$Styled$Attributes$href('/')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('MustCompliance')
								]))
						]))
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$nav,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('menuEntry')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$href('https://mustcompliance.fr/')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Accueil')
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('menuEntry')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$href('https://mustcompliance.fr/generation-de-documents/')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Offre')
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('menuEntry')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$href('https://mustcompliance.fr/tarifs/')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Tarifs')
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('menuEntry')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$href('https://mustcompliance.fr/qui-sommes-nous/')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Qui sommes-nous ?')
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('menuEntry')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$href('https://mustcompliance.fr/contact/')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Contact')
								]))
						]))
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('header-right')
				]),
			_List_Nil)
		]));
var $author$project$Components$ActiveUser$View$view = function (model) {
	var _v0 = function () {
		var _v1 = model.k1;
		if (!_v1) {
			return _Utils_Tuple2('Activation de votre compte utilisateur', 'Activer mon compte utilisateur');
		} else {
			return _Utils_Tuple2('Réinitialisation de votre mot de passe', 'Réinitialiser mon mot de passe');
		}
	}();
	var pageTitle = _v0.a;
	var submitButtonLabel = _v0.b;
	var _v2 = function () {
		var _v3 = model.oq;
		switch (_v3) {
			case 0:
				return _Utils_Tuple2(false, ' notModified');
			case 1:
				return _Utils_Tuple2(true, ' readyToSave');
			case 3:
				return _Utils_Tuple2(false, ' saving');
			default:
				return _Utils_Tuple2(false, ' error');
		}
	}();
	var readyToCreate = _v2.a;
	var saveStateClass = _v2.b;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('layout')
			]),
		_List_fromArray(
			[
				$author$project$Components$Website$HeaderView$view,
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('page-new-password')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('top')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$h1,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(pageTitle)
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$form,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onSubmit($author$project$Components$ActiveUser$Update$AskActiveUser)
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item mandatory')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$label,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Mot de passe')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$input,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$value(
														A2($elm$core$Maybe$withDefault, '', model.nW)),
														$rtfeldman$elm_css$Html$Styled$Attributes$type_('password'),
														A2(
														$rtfeldman$elm_css$Html$Styled$Attributes$property,
														'autocomplete',
														$elm$json$Json$Encode$string('password')),
														$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Components$ActiveUser$Update$SetPassword)
													]),
												_List_Nil)
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item mandatory')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$label,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Confirmation du mot de passe')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$input,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$value(
														A2($elm$core$Maybe$withDefault, '', model.ly)),
														$rtfeldman$elm_css$Html$Styled$Attributes$type_('password'),
														A2(
														$rtfeldman$elm_css$Html$Styled$Attributes$property,
														'autocomplete',
														$elm$json$Json$Encode$string('current-password')),
														$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Components$ActiveUser$Update$SetConfirmPassword)
													]),
												_List_Nil)
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('actions')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$button,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$type_('submit'),
														$rtfeldman$elm_css$Html$Styled$Attributes$class('primary'),
														$rtfeldman$elm_css$Html$Styled$Attributes$class(saveStateClass),
														$rtfeldman$elm_css$Html$Styled$Attributes$disabled(!readyToCreate)
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(submitButtonLabel)
													]))
											]))
									]))
							]))
					])),
				$author$project$Components$Website$FooterView$view
			]));
};
var $author$project$Components$Auth$Update$IdentifierUpdated = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Auth$Update$LogIn = {$: 3};
var $author$project$Components$Auth$Update$PasswordUpdated = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Auth$View$view = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('layout')
			]),
		_List_fromArray(
			[
				$author$project$Components$Website$HeaderView$view,
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('page-login')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('top')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$h1,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Connexion à votre compte')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$form,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onSubmit($author$project$Components$Auth$Update$LogIn)
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item mandatory')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$label,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('E-mail')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$input,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$value(
														A2($elm$core$Maybe$withDefault, '', model.mO)),
														$rtfeldman$elm_css$Html$Styled$Attributes$type_('email'),
														A2(
														$rtfeldman$elm_css$Html$Styled$Attributes$property,
														'autocomplete',
														$elm$json$Json$Encode$string('username')),
														$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Components$Auth$Update$IdentifierUpdated)
													]),
												_List_Nil)
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item mandatory')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$label,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Mot de passe')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$input,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$value(
														A2($elm$core$Maybe$withDefault, '', model.nW)),
														$rtfeldman$elm_css$Html$Styled$Attributes$type_('password'),
														A2(
														$rtfeldman$elm_css$Html$Styled$Attributes$property,
														'autocomplete',
														$elm$json$Json$Encode$string('current-password')),
														$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Components$Auth$Update$PasswordUpdated)
													]),
												_List_Nil),
												A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('forgotPassword')
													]),
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$a,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$href('/forgotpassword')
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('Mot de passe oublié ?')
															]))
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('actions')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$button,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('primary'),
														$rtfeldman$elm_css$Html$Styled$Attributes$type_('submit')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Valider')
													]))
											]))
									]))
							]))
					])),
				$author$project$Components$Website$FooterView$view
			]));
};
var $author$project$Components$ForgotPassword$Update$AskResetPassword = {$: 1};
var $author$project$Components$ForgotPassword$Update$SetLogin = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$ForgotPassword$View$view = function (model) {
	var _v0 = function () {
		var _v1 = model.oq;
		switch (_v1) {
			case 0:
				return _Utils_Tuple2(false, ' notModified');
			case 1:
				return _Utils_Tuple2(true, ' readyToSave');
			case 3:
				return _Utils_Tuple2(false, ' saving');
			default:
				return _Utils_Tuple2(false, ' error');
		}
	}();
	var readyToCreate = _v0.a;
	var saveStateClass = _v0.b;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('layout')
			]),
		_List_fromArray(
			[
				$author$project$Components$Website$HeaderView$view,
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('page-forgot-password')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('top')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$h1,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Mot de passe oublié')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-info')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Saisissez l\'e-mail avec lequel vous êtes inscrit sur MustCompliance pour réinitialiser votre mot de passe')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$form,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onSubmit($author$project$Components$ForgotPassword$Update$AskResetPassword)
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item mandatory')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$label,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('E-mail')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$input,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$value(
														A2($elm$core$Maybe$withDefault, '', model.nh)),
														$rtfeldman$elm_css$Html$Styled$Attributes$type_('email'),
														$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Components$ForgotPassword$Update$SetLogin)
													]),
												_List_Nil)
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('actions')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$a,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('button secondary back'),
														$rtfeldman$elm_css$Html$Styled$Attributes$href('/')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Retour')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$button,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$type_('submit'),
														$rtfeldman$elm_css$Html$Styled$Attributes$class('primary'),
														$rtfeldman$elm_css$Html$Styled$Attributes$class(saveStateClass),
														$rtfeldman$elm_css$Html$Styled$Attributes$disabled(!readyToCreate)
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Envoyer')
													]))
											]))
									]))
							]))
					])),
				$author$project$Components$Website$FooterView$view
			]));
};
var $author$project$Main$MainView$logoutViewsOrAuth = function (model) {
	var _v0 = model.iy;
	switch (_v0.$) {
		case 1:
			var subModel = _v0.a;
			return A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$author$project$Main$Msg$ActiveUserComponentAction,
				$author$project$Components$ActiveUser$View$view(subModel));
		case 2:
			var subModel = _v0.a;
			return A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$author$project$Main$Msg$ForgotPasswordComponentAction,
				$author$project$Components$ForgotPassword$View$view(subModel));
		default:
			return A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$author$project$Main$Msg$AuthAction,
				$author$project$Components$Auth$View$view(model.fK));
	}
};
var $rtfeldman$elm_css$VirtualDom$Styled$keyedNode = $rtfeldman$elm_css$VirtualDom$Styled$KeyedNode;
var $rtfeldman$elm_css$Html$Styled$Keyed$node = $rtfeldman$elm_css$VirtualDom$Styled$keyedNode;
var $author$project$Components$Cabinets$List$View$displayCabinets = function (model) {
	var displayOneLink = function (cabinet) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('item')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('cartouche-cabinet')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('main')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('logo')
										]),
									_List_Nil),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('title')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(cabinet.nz)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$ul,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('actions')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$li,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('infos')
												]),
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$a,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$href(
															$author$project$Routes$Model$routes.ll.mZ.dX(cabinet.kx))
														]),
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$span,
															_List_Nil,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('Informations')
																]))
														]))
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$li,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('users')
												]),
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$a,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$href(
															$author$project$Routes$Model$routes.ll.o3.hY.dX(cabinet.kx))
														]),
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$span,
															_List_Nil,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('Utilisateurs')
																]))
														]))
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$li,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('offres')
												]),
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$a,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$href(
															$author$project$Routes$Model$routes.ll.nM.hY.dX(cabinet.kx))
														]),
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$span,
															_List_Nil,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('Offres commerciales')
																]))
														]))
												]))
										]))
								]))
						]))
				]));
	};
	var addCabinet = A2(
		$elm$core$Maybe$map,
		function (_v0) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$href(
						$author$project$Routes$Model$routes.ll.lF.dX(0))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Créer un nouveau cabinet')
							]))
					]));
		},
		model.k_.gk);
	var displayFirst = A2(
		$elm$core$List$map,
		function (link) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$li,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('new')
					]),
				_List_fromArray(
					[link]));
		},
		$elm_community$maybe_extra$Maybe$Extra$toList(addCabinet));
	return _Utils_ap(
		displayFirst,
		A2($elm$core$List$map, displayOneLink, model.ll));
};
var $author$project$Components$Cabinets$List$View$view = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('page-cabinets-list')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('top')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$h1,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Tous les cabinets')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$ul,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('list')
							]),
						$author$project$Components$Cabinets$List$View$displayCabinets(model))
					]))
			]));
};
var $author$project$Components$Cabinets$Utils$CabinetOnglets$Informations = 0;
var $author$project$Components$Cabinets$Utils$CabinetOnglets$Utilisateurs = 1;
var $author$project$Components$Cabinets$Utils$CabinetOnglets$cabinetOnglets = F2(
	function (cabId, onglet) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('tabs')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class(
							(!onglet) ? 'active' : ''),
							$rtfeldman$elm_css$Html$Styled$Attributes$href('/cabinets/' + (cabId + '/informations'))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Informations')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$a,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class(
							(onglet === 1) ? 'active' : ''),
							$rtfeldman$elm_css$Html$Styled$Attributes$href('/cabinets/' + (cabId + '/utilisateurs'))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Utilisateurs')
						]))
				]));
	});
var $author$project$Components$Cabinets$New$Model$ReadMode = 1;
var $author$project$Components$Cabinets$New$Model$UpdateMode = 0;
var $author$project$Components$Cabinets$New$Model$calculViewMode = function (model) {
	if (!model.$) {
		var m = model.a;
		return A2(
			$elm$core$Maybe$withDefault,
			1,
			A2(
				$elm$core$Maybe$map,
				function (_v1) {
					return 0;
				},
				m.gl));
	} else {
		var m = model.a;
		return A2(
			$elm$core$Maybe$withDefault,
			1,
			A2(
				$elm$core$Maybe$map,
				function (_v2) {
					return 0;
				},
				m.kz));
	}
};
var $author$project$Components$Cabinets$New$Update$Cancel = {$: 4};
var $author$project$Components$Cabinets$New$Update$UpsertCabinet = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Cabinets$New$ExternalSync$Model$isReadyToUpdate = function (model) {
	if (!model.$) {
		return true;
	} else {
		var hsm = model.a;
		var _v1 = hsm.bQ;
		switch (_v1.$) {
			case 0:
				return false;
			case 1:
				return false;
			case 2:
				return true;
			case 3:
				return false;
			case 4:
				return false;
			case 5:
				return false;
			default:
				return true;
		}
	}
};
var $author$project$Components$Cabinets$New$Model$readyToCreateOrUpdate = function (model) {
	if (!model.$) {
		var m = model.a;
		return m.ab && (($elm$core$String$length(m.aw) >= 1) && A2(
			$elm$core$Maybe$withDefault,
			true,
			A2($elm$core$Maybe$map, $author$project$Components$Cabinets$New$ExternalSync$Model$isReadyToUpdate, m.am)));
	} else {
		var m = model.a;
		return m.ab && (($elm$core$String$length(m.aw) >= 1) && A2(
			$elm$core$Maybe$withDefault,
			true,
			A2($elm$core$Maybe$map, $author$project$Components$Cabinets$New$ExternalSync$Model$isReadyToUpdate, m.am)));
	}
};
var $author$project$Components$Cabinets$New$View$updateMenu = F3(
	function (model, simpleModel, saveLink) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('fixed')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$button,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Cabinets$New$Update$Cancel),
							$rtfeldman$elm_css$Html$Styled$Attributes$class('button secondary'),
							$rtfeldman$elm_css$Html$Styled$Attributes$disabled(
							!$author$project$Components$Cabinets$New$Model$isModified(model))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Annuler')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$button,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Events$onClick(
							$author$project$Components$Cabinets$New$Update$UpsertCabinet(saveLink)),
							$rtfeldman$elm_css$Html$Styled$Attributes$class('button primary'),
							simpleModel.dH,
							$rtfeldman$elm_css$Html$Styled$Attributes$disabled(
							!$author$project$Components$Cabinets$New$Model$readyToCreateOrUpdate(model))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(simpleModel.dI)
						]))
				]));
	});
var $author$project$Components$Cabinets$New$View$displayUpdateMenu = F2(
	function (model, simpleModel) {
		return A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					A2($author$project$Components$Cabinets$New$View$updateMenu, model, simpleModel),
					$elm$core$List$singleton),
				simpleModel.cZ));
	});
var $author$project$Components$Cabinets$New$Update$SetCabinetName = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Attributes$for = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('htmlFor');
var $rtfeldman$elm_css$Html$Styled$h2 = $rtfeldman$elm_css$Html$Styled$node('h2');
var $rtfeldman$elm_css$Html$Styled$section = $rtfeldman$elm_css$Html$Styled$node('section');
var $author$project$Components$Cabinets$New$View$formView = F2(
	function (updateOrReadMode, simpleModel) {
		var disableInput = function () {
			if (!updateOrReadMode) {
				return false;
			} else {
				return true;
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$section,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('cabinet')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('section-content')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h2,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Identité')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item mandatory')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$label,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$for('cabName')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Nom du cabinet')
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$input,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$id('cabName'),
											$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
											$rtfeldman$elm_css$Html$Styled$Attributes$value(simpleModel.cU),
											$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Components$Cabinets$New$Update$SetCabinetName),
											$rtfeldman$elm_css$Html$Styled$Attributes$disabled(disableInput)
										]),
									_List_Nil)
								]))
						]))
				]));
	});
var $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$HarvestToolSync = $elm$core$Basics$identity;
var $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$SetExternalToolChoice = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Cabinets$New$ExternalSync$View$calculError = function (hsm) {
	var _v0 = hsm.bQ;
	switch (_v0.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			return $elm$core$Maybe$Nothing;
		case 2:
			return $elm$core$Maybe$Nothing;
		case 3:
			var err = _v0.a;
			switch (err) {
				case 0:
					return $elm$core$Maybe$Just('Les identifiants de connexion sont incorrects');
				case 1:
					return $elm$core$Maybe$Just('Il n\'a pas été possible de vérifier l\'existence de votre cabinet sur 02S');
				case 2:
					return $elm$core$Maybe$Just('Ces identifiants sont déjà utilisés par un autre cabinet');
				default:
					return $elm$core$Maybe$Just('Un problème technique s\'est produit. Réessayer. Si le problème persiste contacter le service client');
			}
		case 4:
			return $elm$core$Maybe$Nothing;
		case 5:
			return $elm$core$Maybe$Nothing;
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$SetHarvestCabinetApiPassword = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$SetHarvestCabinetApiUsername = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$SetHarvestCabinetClientId = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$SetHarvestCabinetClientSecret = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$SetHarvestSyncInfos = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Attributes$placeholder = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('placeholder');
var $author$project$Components$Cabinets$New$ExternalSync$View$decorateInput = F6(
	function (id_, label_, action, placeholder_, val, disable) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item mandatory')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$label,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$for(id_)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(label_)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$input,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$id(id_),
							$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
							$rtfeldman$elm_css$Html$Styled$Attributes$value(val),
							$rtfeldman$elm_css$Html$Styled$Attributes$placeholder(placeholder_),
							$rtfeldman$elm_css$Html$Styled$Attributes$disabled(disable),
							$rtfeldman$elm_css$Html$Styled$Events$onInput(action)
						]),
					_List_Nil)
				]));
	});
var $author$project$Components$Cabinets$New$ExternalSync$View$harvestSyncFormView = function (m) {
	var initialPlaceHolder = function (tick) {
		return 'Renseigner le ' + (tick + ' de votre cabinet pour accéder à l\'API Harvest');
	};
	var _v0 = function () {
		var _v1 = m.bQ;
		switch (_v1.$) {
			case 0:
				return _Utils_Tuple2(initialPlaceHolder, false);
			case 1:
				return _Utils_Tuple2(initialPlaceHolder, false);
			case 2:
				return _Utils_Tuple2(initialPlaceHolder, false);
			case 3:
				return _Utils_Tuple2(initialPlaceHolder, false);
			case 4:
				return _Utils_Tuple2(initialPlaceHolder, false);
			case 5:
				return _Utils_Tuple2(initialPlaceHolder, false);
			default:
				return _Utils_Tuple2(
					function (_v2) {
						return 'Mot de passe à renseigner uniquement si changement';
					},
					true);
		}
	}();
	var placeholder = _v0.a;
	var disable = _v0.b;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('form')
			]),
		_List_fromArray(
			[
				A6(
				$author$project$Components$Cabinets$New$ExternalSync$View$decorateInput,
				'clientid',
				'Harvest Client Id',
				A2($elm$core$Basics$composeL, $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$SetHarvestSyncInfos, $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$SetHarvestCabinetClientId),
				'Renseigner le clientId de votre cabinet pour accéder à l\'API Harvest',
				m.lu,
				disable),
				A6(
				$author$project$Components$Cabinets$New$ExternalSync$View$decorateInput,
				'clientsecret',
				'Harvest Client secret',
				A2($elm$core$Basics$composeL, $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$SetHarvestSyncInfos, $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$SetHarvestCabinetClientSecret),
				placeholder('client secret'),
				m.lv,
				disable),
				A6(
				$author$project$Components$Cabinets$New$ExternalSync$View$decorateInput,
				'username',
				'Harvest API login',
				A2($elm$core$Basics$composeL, $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$SetHarvestSyncInfos, $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$SetHarvestCabinetApiUsername),
				'Renseigner le username de votre cabinet pour accéder à l\'API Harvest',
				m.o2,
				disable),
				A6(
				$author$project$Components$Cabinets$New$ExternalSync$View$decorateInput,
				'password',
				'Harvest API password',
				A2($elm$core$Basics$composeL, $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$SetHarvestSyncInfos, $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$SetHarvestCabinetApiPassword),
				placeholder('password'),
				m.nW,
				disable)
			]));
};
var $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$TestConnection = {$: 2};
var $author$project$Components$Cabinets$New$ExternalSync$View$testConnectionButton = function (syncStatus) {
	var loading = _Utils_eq(syncStatus, $author$project$Components$Cabinets$New$ExternalSync$Model$TestingConnection) ? 'loading' : '';
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('verifyConnection')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$button,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('button primary'),
						$rtfeldman$elm_css$Html$Styled$Attributes$disabled(
						!_Utils_eq(syncStatus, $author$project$Components$Cabinets$New$ExternalSync$Model$ReadyToTest)),
						$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$TestConnection),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(loading)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Vérifier la connexion')
					]))
			]));
};
var $author$project$Components$Cabinets$New$ExternalSync$View$calculHarvest = F2(
	function (updateOrReadMode, model) {
		var isUpdateMode = function () {
			if (!updateOrReadMode) {
				return true;
			} else {
				return false;
			}
		}();
		if (!model.$) {
			return {
				cb: false,
				es: $elm$core$Maybe$Nothing,
				cm: $rtfeldman$elm_css$Html$Styled$text(''),
				bz: false,
				cD: false,
				cE: $rtfeldman$elm_css$Html$Styled$text('')
			};
		} else {
			var harvestSyncModel = model.a;
			return isUpdateMode ? {
				cb: _Utils_eq(harvestSyncModel.bQ, $author$project$Components$Cabinets$New$ExternalSync$Model$Verified),
				es: $author$project$Components$Cabinets$New$ExternalSync$View$calculError(harvestSyncModel),
				cm: $author$project$Components$Cabinets$New$ExternalSync$View$harvestSyncFormView(harvestSyncModel),
				bz: true,
				cD: _Utils_eq(harvestSyncModel.bQ, $author$project$Components$Cabinets$New$ExternalSync$Model$Saved),
				cE: $author$project$Components$Cabinets$New$ExternalSync$View$testConnectionButton(harvestSyncModel.bQ)
			} : {
				cb: _Utils_eq(harvestSyncModel.bQ, $author$project$Components$Cabinets$New$ExternalSync$Model$Verified),
				es: $author$project$Components$Cabinets$New$ExternalSync$View$calculError(harvestSyncModel),
				cm: $rtfeldman$elm_css$Html$Styled$text(''),
				bz: true,
				cD: _Utils_eq(harvestSyncModel.bQ, $author$project$Components$Cabinets$New$ExternalSync$Model$Saved),
				cE: $rtfeldman$elm_css$Html$Styled$text('')
			};
		}
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$checked = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('checked');
var $rtfeldman$elm_css$Html$Styled$Attributes$classList = function (classes) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $author$project$Components$Cabinets$New$ExternalSync$View$harvestSyncView = F2(
	function (updateOrReadMode, model) {
		var result = A2($author$project$Components$Cabinets$New$ExternalSync$View$calculHarvest, updateOrReadMode, model);
		var infos = result.cD ? {
			a4: {
				bk: $rtfeldman$elm_css$Html$Styled$Attributes$class('alert-success'),
				bm: 'Synchronisation opérationnelle'
			},
			bW: 'saved'
		} : (result.cb ? {
			a4: {
				bk: $rtfeldman$elm_css$Html$Styled$Attributes$class('alert-success'),
				bm: 'Connexion vérifiée'
			},
			bW: 'verified'
		} : ($elm_community$maybe_extra$Maybe$Extra$isJust(result.es) ? {
			a4: {
				bk: $rtfeldman$elm_css$Html$Styled$Attributes$class('alert-error'),
				bm: A2($elm$core$Maybe$withDefault, '', result.es)
			},
			bW: 'failure'
		} : (result.bz ? {
			a4: {
				bk: $rtfeldman$elm_css$Html$Styled$Attributes$class('alert-info'),
				bm: 'Saisissez vos identifiants Harvest (02S Success)'
			},
			bW: ''
		} : {
			a4: {
				bk: $rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('', false)
						])),
				bm: ''
			},
			bW: ''
		})));
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('external-sync'),
					$rtfeldman$elm_css$Html$Styled$Attributes$class(infos.bW)
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('toggleButton')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$id('harvestChoice'),
									$rtfeldman$elm_css$Html$Styled$Attributes$type_('checkbox'),
									$rtfeldman$elm_css$Html$Styled$Attributes$checked(result.bz),
									$rtfeldman$elm_css$Html$Styled$Events$onInput(
									A2($elm$core$Basics$composeL, $author$project$Components$Cabinets$New$ExternalSync$ExternalToolSync$SetExternalToolChoice, $elm$core$Basics$identity))
								]),
							_List_Nil),
							A2(
							$rtfeldman$elm_css$Html$Styled$label,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$for('harvestChoice')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Synchroniser avec O2S')
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('external-sync-expand')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[infos.a4.bk]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(infos.a4.bm)
								])),
							result.cm,
							result.cE
						]))
				]));
	});
var $author$project$Components$Cabinets$New$ExternalSync$View$view = F2(
	function (updateOrReadMode, model) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$section,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('sync')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('section-content')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h2,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Synchronisation')
								])),
							A2($author$project$Components$Cabinets$New$ExternalSync$View$harvestSyncView, updateOrReadMode, model)
						]))
				]));
	});
var $author$project$Components$Cabinets$New$View$view = function (model) {
	var updateOrReadMode = $author$project$Components$Cabinets$New$Model$calculViewMode(model);
	var syncView = function (m) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$map,
			$author$project$Components$Cabinets$New$Update$SetExternalToolSync,
			A2($author$project$Components$Cabinets$New$ExternalSync$View$view, updateOrReadMode, m));
	};
	var simpleModel = function () {
		if (!model.$) {
			var m = model.a;
			return {
				cU: m.aw,
				cZ: m.gl,
				dH: $rtfeldman$elm_css$Html$Styled$Attributes$class('create'),
				dI: 'Sauvegarder',
				dQ: $elm$core$Maybe$Nothing,
				dW: 'Créer un nouveau cabinet'
			};
		} else {
			var m = model.a;
			return {
				cU: m.aw,
				cZ: m.kz,
				dH: $rtfeldman$elm_css$Html$Styled$Attributes$class('update'),
				dI: 'Sauvegarder',
				dQ: $elm$core$Maybe$Just(
					A2($author$project$Components$Cabinets$Utils$CabinetOnglets$cabinetOnglets, m.lj, 0)),
				dW: m.aw
			};
		}
	}();
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('page-cabinet-infos')
			]),
		_Utils_ap(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$id('top')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h1,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(simpleModel.dW)
								])),
							A2(
							$elm$core$Maybe$withDefault,
							$rtfeldman$elm_css$Html$Styled$text(''),
							simpleModel.dQ)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
						]),
					_List_fromArray(
						[
							A2($author$project$Components$Cabinets$New$View$formView, updateOrReadMode, simpleModel),
							A2(
							$elm$core$Maybe$withDefault,
							$rtfeldman$elm_css$Html$Styled$text(''),
							A2(
								$elm$core$Maybe$map,
								syncView,
								$author$project$Components$Cabinets$New$Model$getExternalSyncSubModel(model)))
						]))
				]),
			A2($author$project$Components$Cabinets$New$View$displayUpdateMenu, model, simpleModel)));
};
var $author$project$Components$Cabinets$OffresCommerciales$View$docGenOffreBlock = function (model) {
	var placeHolder = A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('generateDoc loading')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h3,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Génération de documents')
					]))
			]));
	var offreSouscriteContainer = function (txt) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$a,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('generateDoc'),
					$rtfeldman$elm_css$Html$Styled$Attributes$href(
					$author$project$Routes$Model$routes.ll.nM.ht.dX(model.fZ.kx))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h3,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Génération de documents')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('description')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(txt)
						]))
				]));
	};
	var offreNONSouscriteContainer = function (txt) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$a,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('generateDoc'),
					$rtfeldman$elm_css$Html$Styled$Attributes$href(
					$author$project$Routes$Model$routes.ll.nM.ht.dX(model.fZ.kx))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h3,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Génération de documents')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('description')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(txt)
						]))
				]));
	};
	var block = function () {
		var _v0 = model.eX.l_.fo;
		if (!_v0.$) {
			switch (_v0.a) {
				case 0:
					var _v1 = _v0.a;
					return offreSouscriteContainer('Offre de Test. Nombre réduit de produits disponibles pour génération');
				case 1:
					var _v2 = _v0.a;
					return offreSouscriteContainer('Nombre de produits disponibles pour génération limités à 20 max');
				case 2:
					var _v3 = _v0.a;
					return offreSouscriteContainer('Nombre de produits disponibles pour génération limités à 30 max');
				case 3:
					var _v4 = _v0.a;
					return offreSouscriteContainer('Nombre de produits disponibles illimités pour génération');
				default:
					var _v5 = _v0.a;
					return offreSouscriteContainer('Accès à tout le référentiel de produits MustCompliance pour génération');
			}
		} else {
			return offreNONSouscriteContainer('Offre non encore souscrite');
		}
	}();
	return A2(
		$rtfeldman$elm_css$Html$Styled$li,
		_List_Nil,
		_List_fromArray(
			[
				model.eK ? placeHolder : block
			]));
};
var $author$project$Components$Cabinets$OffresCommerciales$View$menuView = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('offresCommerciales')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h1,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Offres commerciales')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$ul,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('list')
					]),
				_List_fromArray(
					[
						$author$project$Components$Cabinets$OffresCommerciales$View$docGenOffreBlock(model)
					]))
			]));
};
var $author$project$Components$Cabinets$OffresCommerciales$Model$Error = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Model$NotReady = {$: 1};
var $author$project$Components$Cabinets$OffresCommerciales$Model$Ready = {$: 0};
var $author$project$Components$Cabinets$OffresCommerciales$Model$somethingHasChanged = function (params) {
	var offreHasChanged = $elm_community$maybe_extra$Maybe$Extra$isJust(params.bE) && (!_Utils_eq(params.bE, params.fo));
	var isSameProduct = F2(
		function (produit1, produit2) {
			var _v0 = _Utils_Tuple2(produit1, produit2);
			_v0$5:
			while (true) {
				switch (_v0.a.$) {
					case 1:
						if (!_v0.b.$) {
							var p1 = _v0.a.a;
							var p2 = _v0.b.a;
							return _Utils_eq(p1.kx, p2.kx);
						} else {
							break _v0$5;
						}
					case 2:
						if (!_v0.b.$) {
							var p1 = _v0.a.a;
							var p2 = _v0.b.a;
							return _Utils_eq(p1.kx, p2.kx);
						} else {
							break _v0$5;
						}
					default:
						switch (_v0.b.$) {
							case 0:
								var p1 = _v0.a.a;
								var p2 = _v0.b.a;
								return _Utils_eq(p1.kx, p2.kx);
							case 1:
								var p1 = _v0.a.a;
								var p2 = _v0.b.a;
								return _Utils_eq(p1.kx, p2.kx);
							default:
								var p1 = _v0.a.a;
								var p2 = _v0.b.a;
								return _Utils_eq(p1.kx, p2.kx);
						}
				}
			}
			return false;
		});
	var produitsChoisisHaveNotChanged = _Utils_eq(
		$elm$core$List$length(params.cA),
		$elm$core$List$length(params.e1)) && A2(
		$elm$core$List$all,
		function (p) {
			return A2(
				$elm$core$List$any,
				isSameProduct(p),
				params.cA);
		},
		params.e1);
	return offreHasChanged || (!produitsChoisisHaveNotChanged);
};
var $author$project$Components$Cabinets$OffresCommerciales$Model$calculSaveState = function (docGen) {
	var maxProduit = A2(
		$elm_community$maybe_extra$Maybe$Extra$orElse,
		A2($ccapndave$elm_flat_map$Maybe$FlatMap$flatMap, $author$project$Components$Cabinets$OffresCommerciales$Model$getMaxProduitByOffre, docGen.fo),
		A2($ccapndave$elm_flat_map$Maybe$FlatMap$flatMap, $author$project$Components$Cabinets$OffresCommerciales$Model$getMaxProduitByOffre, docGen.bE));
	var isExceedTheOffreLimit = A2(
		$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
		function (m) {
			return (_Utils_cmp(
				$elm$core$List$length(docGen.e1),
				m) > 0) ? $elm$core$Maybe$Just(m) : $elm$core$Maybe$Nothing;
		},
		maxProduit);
	var hasChanged = $author$project$Components$Cabinets$OffresCommerciales$Model$somethingHasChanged(docGen);
	var errorMsg = function (i) {
		return 'Vous ne pouvez attribuer que ' + ($elm$core$String$fromInt(i) + ' produits maximum pour cette offre');
	};
	return hasChanged ? A2(
		$elm$core$Maybe$withDefault,
		$author$project$Components$Cabinets$OffresCommerciales$Model$Ready,
		A2(
			$elm$core$Maybe$map,
			A2($elm$core$Basics$composeR, errorMsg, $author$project$Components$Cabinets$OffresCommerciales$Model$Error),
			isExceedTheOffreLimit)) : $author$project$Components$Cabinets$OffresCommerciales$Model$NotReady;
};
var $author$project$Components$Cabinets$OffresCommerciales$Action$ConfirmAllInclusive = function (a) {
	return {$: 9, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$confirmAllInclusiveDrawer = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('drawer opened')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('drawerClose')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$button,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('drawerClose'),
								$rtfeldman$elm_css$Html$Styled$Events$onClick(
								$author$project$Components$Cabinets$OffresCommerciales$Action$ConfirmAllInclusive(false))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Fermer')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('hider')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('scroll')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('drawer-confirm-all-inclusive')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$h2,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Confirmer choix de l\'offre All Inclusive')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$p,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('explain')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Choisir l\'offre All Inclusive implique que le cabinet ait accès à tous ' + 'les produits du référentiel MustCompliance.')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$p,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('explain')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Seules les copies privées sont attribuées.')
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('drawerActions')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$button,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('button secondary'),
														$rtfeldman$elm_css$Html$Styled$Events$onClick(
														$author$project$Components$Cabinets$OffresCommerciales$Action$ConfirmAllInclusive(false))
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Annuler')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$button,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('button primary'),
														$rtfeldman$elm_css$Html$Styled$Events$onClick(
														$author$project$Components$Cabinets$OffresCommerciales$Action$ConfirmAllInclusive(true))
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Confirmer')
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Components$Cabinets$OffresCommerciales$Action$Cancel = {$: 14};
var $author$project$Components$Cabinets$OffresCommerciales$Action$Save = {$: 10};
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$updateMenu = function (isReadyToSave) {
	var disableCancel = function () {
		switch (isReadyToSave.$) {
			case 0:
				return false;
			case 1:
				return true;
			default:
				return false;
		}
	}();
	var _v0 = function () {
		switch (isReadyToSave.$) {
			case 0:
				return _Utils_Tuple2(
					false,
					$rtfeldman$elm_css$Html$Styled$Attributes$class(''));
			case 1:
				return _Utils_Tuple2(
					true,
					$rtfeldman$elm_css$Html$Styled$Attributes$class(''));
			default:
				return _Utils_Tuple2(
					true,
					$rtfeldman$elm_css$Html$Styled$Attributes$class('error'));
		}
	}();
	var disableSave = _v0.a;
	var saveClass = _v0.b;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('fixed')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$button,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Cabinets$OffresCommerciales$Action$Cancel),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('button secondary'),
						$rtfeldman$elm_css$Html$Styled$Attributes$disabled(disableCancel)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Annuler')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$button,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Cabinets$OffresCommerciales$Action$Save),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('button primary'),
						saveClass,
						$rtfeldman$elm_css$Html$Styled$Attributes$disabled(disableSave)
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Sauvegarder')
							]))
					]))
			]));
};
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$displayUpdateMenu = F2(
	function (model, isReadyToSave) {
		return model.eO ? $elm$core$List$singleton(
			$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$updateMenu(isReadyToSave)) : _List_Nil;
	});
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$Chosen = 1;
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$OldChosen = 2;
var $author$project$Components$Cabinets$OffresCommerciales$Action$SetOffreDocGenOffre = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$docGenOffreBlock = F2(
	function (isEditable, offre) {
		var isClickable = isEditable && ((!(offre.cV === 1)) || (!(offre.cV === 2)));
		var onClick_ = isClickable ? $rtfeldman$elm_css$Html$Styled$Events$onClick(
			$author$project$Components$Cabinets$OffresCommerciales$Action$SetOffreDocGenOffre(offre.eW)) : $rtfeldman$elm_css$Html$Styled$Attributes$class('');
		var chosenClass = function () {
			var _v0 = offre.cV;
			switch (_v0) {
				case 0:
					return '';
				case 1:
					return 'offreChosen';
				default:
					return 'oldChosen';
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class(chosenClass),
					onClick_,
					$rtfeldman$elm_css$Html$Styled$Attributes$class(offre.bk)
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('container')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('inner')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('logo')
										]),
									_List_Nil),
									A2(
									$rtfeldman$elm_css$Html$Styled$h3,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(offre.m9)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$p,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(offre.j3)
										]))
								]))
						]))
				]));
	});
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$No = 0;
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$Offre = F5(
	function (offre, label, subLabel, _class, chosen) {
		return {cV: chosen, bk: _class, m9: label, eW: offre, j3: subLabel};
	});
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$docGenOffres = function (model) {
	var checkOffre = F2(
		function (offre, r) {
			var _v6 = model.fo;
			if (!_v6.$) {
				switch (_v6.a) {
					case 0:
						var _v7 = _v6.a;
						return (!offre) ? r : 0;
					case 1:
						var _v8 = _v6.a;
						return (offre === 1) ? r : 0;
					case 2:
						var _v9 = _v6.a;
						return (offre === 2) ? r : 0;
					case 3:
						var _v10 = _v6.a;
						return (offre === 3) ? r : 0;
					default:
						var _v11 = _v6.a;
						return (offre === 4) ? r : 0;
				}
			} else {
				return 0;
			}
		});
	var calculChosenOffre = function (offre) {
		var _v0 = model.bE;
		if (!_v0.$) {
			switch (_v0.a) {
				case 0:
					var _v1 = _v0.a;
					return (!offre) ? 1 : A2(checkOffre, offre, 2);
				case 1:
					var _v2 = _v0.a;
					return (offre === 1) ? 1 : A2(checkOffre, offre, 2);
				case 2:
					var _v3 = _v0.a;
					return (offre === 2) ? 1 : A2(checkOffre, offre, 2);
				case 3:
					var _v4 = _v0.a;
					return (offre === 3) ? 1 : A2(checkOffre, offre, 2);
				default:
					var _v5 = _v0.a;
					return (offre === 4) ? 1 : A2(checkOffre, offre, 2);
			}
		} else {
			return A2(checkOffre, offre, 1);
		}
	};
	return A2(
		$elm_community$list_extra$List$Extra$filterNot,
		function (off) {
			return ((!_Utils_eq(
				model.fo,
				$elm$core$Maybe$Just(0))) && $elm_community$maybe_extra$Maybe$Extra$isJust(model.fo)) ? (!off.eW) : false;
		},
		_List_fromArray(
			[
				A5(
				$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$Offre,
				0,
				'Offre de Test',
				'Nombre réduit de produits disponibles pour génération',
				'offreTest',
				calculChosenOffre(0)),
				A5(
				$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$Offre,
				1,
				'20 Produits',
				'Nombre de produits disponibles pour génération limités à 20 maximum',
				'offreJusque20Produits',
				calculChosenOffre(1)),
				A5(
				$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$Offre,
				2,
				'30 Produits',
				'Nombre de produits disponibles pour génération limités à 30 maximum',
				'offreJusque30Produits',
				calculChosenOffre(2)),
				A5(
				$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$Offre,
				3,
				'Illimité',
				'Nombre de produits disponibles pour génération illimités',
				'offrePlus30Produits',
				calculChosenOffre(3)),
				A5(
				$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$Offre,
				4,
				'All Inclusive',
				'Accès à tout le référentiel de produits MustCompliance pour génération',
				'allInclusive',
				calculChosenOffre(4))
			]));
};
var $author$project$Components$Cabinets$OffresCommerciales$Action$FilterProduitChoisis = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Action$RemoveProduit = function (a) {
	return {$: 8, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$goToProduit = function (produitUid) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$a,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('edit'),
				$rtfeldman$elm_css$Html$Styled$Attributes$href(
				$author$project$Routes$Model$routes.n7.mG.dX(produitUid)),
				$rtfeldman$elm_css$Html$Styled$Attributes$title('Éditer le produit')
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text('')
			]));
};
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$actionsChoisies = function (produit) {
	var removeAction = A2(
		$elm$core$Maybe$withDefault,
		A2(
			$rtfeldman$elm_css$Html$Styled$button,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('remove'),
					$rtfeldman$elm_css$Html$Styled$Attributes$title('Retirer le produit'),
					$rtfeldman$elm_css$Html$Styled$Attributes$disabled(
					A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							$elm$core$Basics$always(true),
							produit.l7))),
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
					$author$project$Components$Cabinets$OffresCommerciales$Action$RemoveProduit(produit.kx))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('')
				])),
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$always(
				A2(
					$rtfeldman$elm_css$Html$Styled$button,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('delete'),
							$rtfeldman$elm_css$Html$Styled$Attributes$title('Supprimer le produit copié'),
							$rtfeldman$elm_css$Html$Styled$Attributes$disabled(
							A2(
								$elm$core$Maybe$withDefault,
								true,
								A2(
									$elm$core$Maybe$map,
									$elm$core$Basics$always(false),
									produit.l7))),
							$rtfeldman$elm_css$Html$Styled$Events$onClick(
							$author$project$Components$Cabinets$OffresCommerciales$Action$RemoveProduit(produit.kx))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('')
						]))),
			produit.l7));
	return _List_fromArray(
		[
			removeAction,
			$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$goToProduit(produit.kx)
		]);
};
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$ProduitCardDecoration = F2(
	function (classes, title) {
		return {cW: classes, dW: title};
	});
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitActifDecoration = function (produit) {
	return produit.kP ? _Utils_Tuple2('active', 'actif') : _Utils_Tuple2('inactive', 'inactif');
};
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitDuplicateDecoration = F2(
	function (produit, tocopy) {
		return ($elm_community$maybe_extra$Maybe$Extra$isJust(produit.l7) || tocopy) ? _Utils_Tuple2('duplicate', 'dupliqué') : _Utils_Tuple2('original', '');
	});
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$decorateProduitChosenCard = function (chosen) {
	switch (chosen.$) {
		case 0:
			var produit = chosen.a;
			var _v1 = A2($author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitDuplicateDecoration, produit, false);
			var duplicateCLass = _v1.a;
			var duplicateTitle = _v1.b;
			var _v2 = $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitActifDecoration(produit);
			var actifClass = _v2.a;
			var actifTitle = _v2.b;
			return A2(
				$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$ProduitCardDecoration,
				A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[actifClass, duplicateCLass])),
				'produit ' + A2(
					$elm$core$String$join,
					', ',
					A2(
						$elm_community$list_extra$List$Extra$filterNot,
						$elm$core$String$isEmpty,
						_List_fromArray(
							[actifTitle, duplicateTitle]))));
		case 1:
			var produit = chosen.a;
			var _v3 = A2($author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitDuplicateDecoration, produit, false);
			var duplicateCLass = _v3.a;
			var duplicateTitle = _v3.b;
			var _v4 = _Utils_Tuple2('added', 'à sauvegarder');
			var addedClass = _v4.a;
			var addedTitle = _v4.b;
			var _v5 = $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitActifDecoration(produit);
			var actifClass = _v5.a;
			var actifTitle = _v5.b;
			return A2(
				$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$ProduitCardDecoration,
				A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[addedClass, actifClass, duplicateCLass])),
				'produit ' + A2(
					$elm$core$String$join,
					', ',
					A2(
						$elm_community$list_extra$List$Extra$filterNot,
						$elm$core$String$isEmpty,
						_List_fromArray(
							[addedTitle, actifTitle, duplicateTitle]))));
		default:
			var produit = chosen.a;
			var _v6 = A2($author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitDuplicateDecoration, produit, true);
			var duplicateCLass = _v6.a;
			var duplicateTitle = _v6.b;
			var _v7 = _Utils_Tuple2('added', 'à sauvegarder');
			var addedClass = _v7.a;
			var addedTitle = _v7.b;
			var _v8 = $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitActifDecoration(produit);
			var actifClass = _v8.a;
			var actifTitle = _v8.b;
			return A2(
				$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$ProduitCardDecoration,
				A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[addedClass, actifClass, duplicateCLass])),
				'produit ' + A2(
					$elm$core$String$join,
					', ',
					A2(
						$elm_community$list_extra$List$Extra$filterNot,
						$elm$core$String$isEmpty,
						_List_fromArray(
							[addedTitle, actifTitle, duplicateTitle]))));
	}
};
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$lineChoisis = function (produit) {
	var decoration = $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$decorateProduitChosenCard(produit);
	return A2(
		$rtfeldman$elm_css$Html$Styled$li,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class(decoration.cW),
				$rtfeldman$elm_css$Html$Styled$Attributes$title(decoration.dW)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('container')
					]),
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('picto')
								]),
							_List_Nil),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									$author$project$Components$Cabinets$OffresCommerciales$Model$chosenProduitUnpack(produit).m9)
								]))
						]),
					$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$actionsChoisies(
						$author$project$Components$Cabinets$OffresCommerciales$Model$chosenProduitUnpack(produit))))
			]));
};
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitsChoisis = F2(
	function (model, isReadyToSave) {
		var produits = A2(
			$elm$core$List$filter,
			function (p) {
				return A2(
					$elm$core$String$contains,
					$elm$core$String$toLower(model.ex),
					$elm$core$String$toLower(
						$author$project$Components$Cabinets$OffresCommerciales$Model$chosenProduitUnpack(p).m9));
			},
			model.e1);
		var n = $elm$core$String$fromInt(
			$elm$core$List$length(model.e1));
		var title = _Utils_eq(
			model.bE,
			$elm$core$Maybe$Just(4)) ? ('Copies de produits attribuées au cabinet (' + (n + ')')) : ((_Utils_eq(model.bE, $elm$core$Maybe$Nothing) && _Utils_eq(
			model.fo,
			$elm$core$Maybe$Just(4))) ? ('Copies de produits attribuées au cabinet (' + (n + ')')) : ('Produits attribués au cabinet (' + (n + ')')));
		var errorMsg = function () {
			if (isReadyToSave.$ === 2) {
				var e = isReadyToSave.a;
				return $elm$core$Maybe$Just(e);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('produitsChoisis')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('top')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h3,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$span,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(title)
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('errorMessage')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									A2($elm$core$Maybe$withDefault, '', errorMsg))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
									$rtfeldman$elm_css$Html$Styled$Attributes$placeholder('Filtrer par nom'),
									$rtfeldman$elm_css$Html$Styled$Attributes$value(model.ex),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Components$Cabinets$OffresCommerciales$Action$FilterProduitChoisis)
								]),
							_List_Nil)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('scroll')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$ul,
							_List_Nil,
							A2($elm$core$List$map, $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$lineChoisis, produits))
						]))
				]));
	});
var $author$project$Components$Cabinets$OffresCommerciales$Action$FilterProduitReferentiel = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Action$FilterRemoved = {$: 4};
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$Ref = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$RemovedProduit = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Action$AddCopyProduit = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Action$AddProduit = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$actionsReferentiel = F2(
	function (model, selectedProduit) {
		var selectedOriginal = A2(
			$elm_community$list_extra$List$Extra$find,
			function (p) {
				return A2(
					$elm$core$Maybe$withDefault,
					false,
					A2(
						$elm$core$Maybe$map,
						$elm$core$Basics$eq(selectedProduit.kx),
						p.l7));
			},
			model.n9);
		var noButton = $rtfeldman$elm_css$Html$Styled$text('');
		var disableCopy = $elm_community$maybe_extra$Maybe$Extra$isJust(selectedProduit.l7) || A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Basics$always(true),
				selectedOriginal));
		var checkOrignalWasNotChosen = F2(
			function (uid, elem) {
				return A2(
					$elm$core$Maybe$withDefault,
					elem,
					A2(
						$elm$core$Maybe$map,
						function (_v5) {
							return noButton;
						},
						A2(
							$elm_community$list_extra$List$Extra$find,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Components$Cabinets$OffresCommerciales$Model$chosenProduitUidUnpack,
								$elm$core$Basics$eq(uid)),
							model.e1)));
			});
		var allInclusiveDisplayPredicate = A2($elm_community$maybe_extra$Maybe$Extra$orElse, model.fo, model.bE);
		var shouldAdd = function (elem) {
			var _v0 = _Utils_Tuple2(selectedProduit.l7, allInclusiveDisplayPredicate);
			if (!_v0.a.$) {
				if ((!_v0.b.$) && (_v0.b.a === 4)) {
					var _v1 = _v0.b.a;
					return elem;
				} else {
					var uid = _v0.a.a;
					return A2(checkOrignalWasNotChosen, uid, elem);
				}
			} else {
				if ((!_v0.b.$) && (_v0.b.a === 4)) {
					var _v2 = _v0.a;
					var _v3 = _v0.b.a;
					return noButton;
				} else {
					var _v4 = _v0.a;
					return elem;
				}
			}
		};
		return _List_fromArray(
			[
				shouldAdd(
				A2(
					$rtfeldman$elm_css$Html$Styled$button,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('add'),
							$rtfeldman$elm_css$Html$Styled$Attributes$title('Attribuer le produit'),
							$rtfeldman$elm_css$Html$Styled$Events$onClick(
							$author$project$Components$Cabinets$OffresCommerciales$Action$AddProduit(selectedProduit.kx))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('')
						]))),
				A2(
				$rtfeldman$elm_css$Html$Styled$button,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('copy'),
						$rtfeldman$elm_css$Html$Styled$Attributes$title('Attribuer une copie du produit'),
						$rtfeldman$elm_css$Html$Styled$Attributes$disabled(disableCopy),
						$rtfeldman$elm_css$Html$Styled$Events$onClick(
						$author$project$Components$Cabinets$OffresCommerciales$Action$AddCopyProduit(selectedProduit.kx))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('')
					])),
				$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$goToProduit(selectedProduit.kx)
			]);
	});
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$decorateProduitRefCard = F2(
	function (isRemoved, chosen) {
		var _v0 = isRemoved ? _Utils_Tuple2('removed', 'retiré') : _Utils_Tuple2('', '');
		var removedClass = _v0.a;
		var removedTitle = _v0.b;
		var _v1 = A2($author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitDuplicateDecoration, chosen, false);
		var duplicateCLass = _v1.a;
		var duplicateTitle = _v1.b;
		var _v2 = $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitActifDecoration(chosen);
		var actifClass = _v2.a;
		var actifTitle = _v2.b;
		return A2(
			$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$ProduitCardDecoration,
			A2(
				$elm$core$String$join,
				' ',
				A2(
					$elm_community$list_extra$List$Extra$filterNot,
					$elm$core$String$isEmpty,
					_List_fromArray(
						[removedClass, actifClass, duplicateCLass]))),
			'produit ' + A2(
				$elm$core$String$join,
				', ',
				A2(
					$elm_community$list_extra$List$Extra$filterNot,
					$elm$core$String$isEmpty,
					_List_fromArray(
						[actifTitle, removedTitle, duplicateTitle]))));
	});
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$lineReferentiel = F2(
	function (model, refProduit) {
		var _v0 = function () {
			if (!refProduit.$) {
				var p = refProduit.a;
				return _Utils_Tuple2(true, p);
			} else {
				var p = refProduit.a;
				return _Utils_Tuple2(false, p);
			}
		}();
		var isRemoved = _v0.a;
		var produit = _v0.b;
		var decoration = A2($author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$decorateProduitRefCard, isRemoved, produit);
		var style = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Basics$always(_List_Nil),
				produit.l7));
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class(decoration.cW),
					$rtfeldman$elm_css$Html$Styled$Attributes$title(decoration.dW)
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('container')
						]),
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('picto')
									]),
								_List_Nil),
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$css(style)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(produit.m9)
									]))
							]),
						A2($author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$actionsReferentiel, model, produit)))
				]));
	});
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitsReferentiel = function (model) {
	var removedProduitButActive = model.ez ? $rtfeldman$elm_css$Html$Styled$Attributes$class('active') : $rtfeldman$elm_css$Html$Styled$Attributes$class('');
	var fullTextSearch = function (p) {
		return A2(
			$elm$core$String$contains,
			$elm$core$String$toLower(model.ey),
			$elm$core$String$toLower(p.m9));
	};
	var dictProduitReferentiel = $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (p) {
				return _Utils_Tuple2(p.kx, p);
			},
			model.n9));
	var getSelectedProduit = function (uid) {
		return A2($elm$core$Dict$get, uid, dictProduitReferentiel);
	};
	var dictProduitDuplicate = $elm$core$Dict$fromList(
		A2(
			$elm$core$List$filterMap,
			function (p) {
				return A2(
					$elm$core$Maybe$map,
					function (d) {
						return _Utils_Tuple2(d, p);
					},
					p.l7);
			},
			model.n9));
	var getSelectedDuplicate = function (uid) {
		return A2($elm$core$Dict$get, uid, dictProduitDuplicate);
	};
	var chosenProduitsUnpack = A2($elm$core$List$map, $author$project$Components$Cabinets$OffresCommerciales$Model$chosenProduitUidUnpack, model.e1);
	var produitChosen = function (p) {
		return A2(
			$elm$core$List$any,
			$elm$core$Basics$eq(p.kx),
			chosenProduitsUnpack);
	};
	var chosenOriginalDuplicate = A2(
		$ccapndave$elm_flat_map$List$FlatMap$flatMap,
		A2(
			$elm$core$Basics$composeR,
			getSelectedProduit,
			A2(
				$elm$core$Basics$composeR,
				$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap(
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.kx;
						},
						getSelectedDuplicate)),
				$elm_community$maybe_extra$Maybe$Extra$toList)),
		chosenProduitsUnpack);
	var chosenDuplicateOriginal = A2(
		$ccapndave$elm_flat_map$List$FlatMap$flatMap,
		A2(
			$elm$core$Basics$composeR,
			getSelectedProduit,
			A2(
				$elm$core$Basics$composeR,
				$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap(
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.l7;
						},
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap(getSelectedProduit))),
				$elm_community$maybe_extra$Maybe$Extra$toList)),
		chosenProduitsUnpack);
	var produits = A2(
		$elm_community$list_extra$List$Extra$filterNot,
		function (p) {
			return A2(
				$elm$core$List$any,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.kx;
					},
					$elm$core$Basics$eq(p.kx)),
				model.e2);
		},
		A2(
			$elm_community$list_extra$List$Extra$filterNot,
			function (p) {
				return A2(
					$elm$core$List$any,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.kx;
						},
						$elm$core$Basics$eq(p.kx)),
					chosenOriginalDuplicate);
			},
			A2(
				$elm_community$list_extra$List$Extra$filterNot,
				function (p) {
					return A2(
						$elm$core$List$any,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.kx;
							},
							$elm$core$Basics$eq(p.kx)),
						chosenDuplicateOriginal);
				},
				A2(
					$elm_community$list_extra$List$Extra$filterNot,
					produitChosen,
					A2($elm$core$List$filter, fullTextSearch, model.n9)))));
	var refProduits = model.ez ? A2($elm$core$List$map, $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$RemovedProduit, model.e2) : _Utils_ap(
		A2($elm$core$List$map, $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$RemovedProduit, model.e2),
		A2($elm$core$List$map, $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$Ref, produits));
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('produitsSource')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('top')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$h3,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Produits disponibles')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$button,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('removedFilter'),
								removedProduitButActive,
								$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Cabinets$OffresCommerciales$Action$FilterRemoved)
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('removeCounter')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(
										$elm$core$String$fromInt(
											$elm$core$List$length(model.e2)))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$input,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
								$rtfeldman$elm_css$Html$Styled$Attributes$placeholder('Filtrer par nom'),
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.ey),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Components$Cabinets$OffresCommerciales$Action$FilterProduitReferentiel)
							]),
						_List_Nil)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('scroll')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$ul,
						_List_Nil,
						A2(
							$elm$core$List$map,
							$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$lineReferentiel(model),
							refProduits))
					]))
			]));
};
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitsEditDisplay = F2(
	function (model, isReadyToSave) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('produits')
				]),
			_List_fromArray(
				[
					$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitsReferentiel(model),
					A2($author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitsChoisis, model, isReadyToSave)
				]));
	});
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitsSimpleDisplay = function (model) {
	var decoration = function (p) {
		return A2($author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$decorateProduitRefCard, false, p);
	};
	var show = function (p) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('item'),
					$rtfeldman$elm_css$Html$Styled$Attributes$class(
					decoration(p).cW),
					$rtfeldman$elm_css$Html$Styled$Attributes$title(
					decoration(p).dW)
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('container')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('picto')
								]),
							_List_Nil),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(p.m9)
								]))
						]))
				]));
	};
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('produitChoisisSimpleDisplay')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$ul,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('list')
					]),
				A2(
					$elm$core$List$map,
					A2($elm$core$Basics$composeR, $author$project$Components$Cabinets$OffresCommerciales$Model$chosenProduitUnpack, show),
					model.e1))
			]));
};
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitsView = F2(
	function (model, isReadyToSave) {
		var titleSuffix = 'utilisables pour la génération de document';
		var displayMode = model.eO ? A2($author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitsEditDisplay, model, isReadyToSave) : $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitsSimpleDisplay(model);
		var counter = ' (' + ($elm$core$String$fromInt(
			$elm$core$List$length(model.e1)) + ')');
		var titleNotEditableAllInclusive = 'Produits copiés du référentiel spécifiquement pour le Cabinet ' + counter;
		var titleNotEditableNotAllInclusive = 'Produits ' + (titleSuffix + counter);
		var titleNotEditable = _Utils_eq(
			model.fo,
			$elm$core$Maybe$Just(4)) ? titleNotEditableAllInclusive : titleNotEditableNotAllInclusive;
		var title = model.eO ? ('Sélectionner les produits ' + titleSuffix) : titleNotEditable;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('produitsView')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(title)
						])),
					displayMode
				]));
	});
var $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$view = F2(
	function (cabinet, model) {
		var isReadyToSave = $author$project$Components$Cabinets$OffresCommerciales$Model$calculSaveState(model);
		var editableOffreMode = model.eO ? 'editable' : '';
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('page-offre-commerciale-docgen')
				]),
			_Utils_ap(
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('offres'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class(editableOffreMode)
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$h1,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(cabinet.nz + ' - Offre commerciale pour la génération de documents')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$ul,
								_List_Nil,
								A2(
									$elm$core$List$map,
									$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$docGenOffreBlock(model.eO),
									$author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$docGenOffres(model)))
							])),
						A2($author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$produitsView, model, isReadyToSave),
						model.eb ? $author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$confirmAllInclusiveDrawer(model) : A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('simpleDrawer')
							]),
						_List_Nil)
					]),
				A2($author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$displayUpdateMenu, model, isReadyToSave)));
	});
var $author$project$Components$Cabinets$OffresCommerciales$View$view = function (model) {
	var _v0 = model.gK;
	if (_v0.$ === 1) {
		return $author$project$Components$Cabinets$OffresCommerciales$View$menuView(model);
	} else {
		if (!_v0.a) {
			var _v1 = _v0.a;
			return A2($author$project$Components$Cabinets$OffresCommerciales$Views$DocGeneratorView$view, model.fZ, model.eX.l_);
		} else {
			var _v2 = _v0.a;
			return $rtfeldman$elm_css$Html$Styled$text('not implemented');
		}
	}
};
var $author$project$Components$Cabinets$Users$List$Update$ByUser = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$unstyledNode = $rtfeldman$elm_css$VirtualDom$Styled$Unstyled;
var $rtfeldman$elm_css$Html$Styled$fromUnstyled = $rtfeldman$elm_css$VirtualDom$Styled$unstyledNode;
var $inkuzmin$elm_multiselect$Multiselect$ClickOnComponent = {$: 2};
var $inkuzmin$elm_multiselect$Multiselect$Focused = 1;
var $inkuzmin$elm_multiselect$Multiselect$Toggle = {$: 4};
var $rtfeldman$elm_css$Css$borderBottomWidth = $rtfeldman$elm_css$Css$prop1('border-bottom-width');
var $rtfeldman$elm_css$Css$borderColor3 = F3(
	function (c1, c2, c3) {
		var value = A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[c1.o6, c2.o6, c3.o6]));
		return A2($rtfeldman$elm_css$Css$property, 'border-color', value);
	});
var $rtfeldman$elm_css$Css$borderLeftWidth = $rtfeldman$elm_css$Css$prop1('border-left-width');
var $rtfeldman$elm_css$Css$borderRightWidth = $rtfeldman$elm_css$Css$prop1('border-right-width');
var $rtfeldman$elm_css$Css$borderStyle = $rtfeldman$elm_css$Css$prop1('border-style');
var $rtfeldman$elm_css$Css$borderTopWidth = $rtfeldman$elm_css$Css$prop1('border-top-width');
var $rtfeldman$elm_css$Css$display = $rtfeldman$elm_css$Css$prop1('display');
var $rtfeldman$elm_css$Css$height = $rtfeldman$elm_css$Css$prop1('height');
var $rtfeldman$elm_css$Css$withPrecedingHash = function (str) {
	return A2($elm$core$String$startsWith, '#', str) ? str : A2($elm$core$String$cons, '#', str);
};
var $rtfeldman$elm_css$Css$erroneousHex = function (str) {
	return {
		b0: 1,
		b4: 0,
		al: 0,
		co: 0,
		cC: 0,
		o6: $rtfeldman$elm_css$Css$withPrecedingHash(str)
	};
};
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $rtfeldman$elm_css$Css$validHex = F5(
	function (str, _v0, _v1, _v2, _v3) {
		var r1 = _v0.a;
		var r2 = _v0.b;
		var g1 = _v1.a;
		var g2 = _v1.b;
		var b1 = _v2.a;
		var b2 = _v2.b;
		var a1 = _v3.a;
		var a2 = _v3.b;
		var toResult = A2(
			$elm$core$Basics$composeR,
			$elm$core$String$fromList,
			A2($elm$core$Basics$composeR, $elm$core$String$toLower, $rtfeldman$elm_hex$Hex$fromString));
		var results = _Utils_Tuple2(
			_Utils_Tuple2(
				toResult(
					_List_fromArray(
						[r1, r2])),
				toResult(
					_List_fromArray(
						[g1, g2]))),
			_Utils_Tuple2(
				toResult(
					_List_fromArray(
						[b1, b2])),
				toResult(
					_List_fromArray(
						[a1, a2]))));
		if ((((!results.a.a.$) && (!results.a.b.$)) && (!results.b.a.$)) && (!results.b.b.$)) {
			var _v5 = results.a;
			var red = _v5.a.a;
			var green = _v5.b.a;
			var _v6 = results.b;
			var blue = _v6.a.a;
			var alpha = _v6.b.a;
			return {
				b0: alpha / 255,
				b4: blue,
				al: 0,
				co: green,
				cC: red,
				o6: $rtfeldman$elm_css$Css$withPrecedingHash(str)
			};
		} else {
			return $rtfeldman$elm_css$Css$erroneousHex(str);
		}
	});
var $rtfeldman$elm_css$Css$hex = function (str) {
	var withoutHash = A2($elm$core$String$startsWith, '#', str) ? A2($elm$core$String$dropLeft, 1, str) : str;
	var _v0 = $elm$core$String$toList(withoutHash);
	_v0$4:
	while (true) {
		if ((_v0.b && _v0.b.b) && _v0.b.b.b) {
			if (!_v0.b.b.b.b) {
				var r = _v0.a;
				var _v1 = _v0.b;
				var g = _v1.a;
				var _v2 = _v1.b;
				var b = _v2.a;
				return A5(
					$rtfeldman$elm_css$Css$validHex,
					str,
					_Utils_Tuple2(r, r),
					_Utils_Tuple2(g, g),
					_Utils_Tuple2(b, b),
					_Utils_Tuple2('f', 'f'));
			} else {
				if (!_v0.b.b.b.b.b) {
					var r = _v0.a;
					var _v3 = _v0.b;
					var g = _v3.a;
					var _v4 = _v3.b;
					var b = _v4.a;
					var _v5 = _v4.b;
					var a = _v5.a;
					return A5(
						$rtfeldman$elm_css$Css$validHex,
						str,
						_Utils_Tuple2(r, r),
						_Utils_Tuple2(g, g),
						_Utils_Tuple2(b, b),
						_Utils_Tuple2(a, a));
				} else {
					if (_v0.b.b.b.b.b.b) {
						if (!_v0.b.b.b.b.b.b.b) {
							var r1 = _v0.a;
							var _v6 = _v0.b;
							var r2 = _v6.a;
							var _v7 = _v6.b;
							var g1 = _v7.a;
							var _v8 = _v7.b;
							var g2 = _v8.a;
							var _v9 = _v8.b;
							var b1 = _v9.a;
							var _v10 = _v9.b;
							var b2 = _v10.a;
							return A5(
								$rtfeldman$elm_css$Css$validHex,
								str,
								_Utils_Tuple2(r1, r2),
								_Utils_Tuple2(g1, g2),
								_Utils_Tuple2(b1, b2),
								_Utils_Tuple2('f', 'f'));
						} else {
							if (_v0.b.b.b.b.b.b.b.b && (!_v0.b.b.b.b.b.b.b.b.b)) {
								var r1 = _v0.a;
								var _v11 = _v0.b;
								var r2 = _v11.a;
								var _v12 = _v11.b;
								var g1 = _v12.a;
								var _v13 = _v12.b;
								var g2 = _v13.a;
								var _v14 = _v13.b;
								var b1 = _v14.a;
								var _v15 = _v14.b;
								var b2 = _v15.a;
								var _v16 = _v15.b;
								var a1 = _v16.a;
								var _v17 = _v16.b;
								var a2 = _v17.a;
								return A5(
									$rtfeldman$elm_css$Css$validHex,
									str,
									_Utils_Tuple2(r1, r2),
									_Utils_Tuple2(g1, g2),
									_Utils_Tuple2(b1, b2),
									_Utils_Tuple2(a1, a2));
							} else {
								break _v0$4;
							}
						}
					} else {
						break _v0$4;
					}
				}
			}
		} else {
			break _v0$4;
		}
	}
	return $rtfeldman$elm_css$Css$erroneousHex(str);
};
var $rtfeldman$elm_css$Css$inlineBlock = {v: 0, o6: 'inline-block'};
var $rtfeldman$elm_css$Css$position = $rtfeldman$elm_css$Css$prop1('position');
var $rtfeldman$elm_css$Css$PxUnits = 0;
var $rtfeldman$elm_css$Css$Internal$lengthConverter = F3(
	function (units, unitLabel, numericValue) {
		return {
			ft: 0,
			f$: 0,
			bt: 0,
			K: 0,
			y: 0,
			bA: 0,
			aF: 0,
			bB: 0,
			bC: 0,
			a2: 0,
			a3: 0,
			an: 0,
			aJ: numericValue,
			bS: 0,
			bU: unitLabel,
			cJ: units,
			o6: _Utils_ap(
				$elm$core$String$fromFloat(numericValue),
				unitLabel)
		};
	});
var $rtfeldman$elm_css$Css$px = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'px');
var $rtfeldman$elm_css$Css$relative = {cw: 0, o6: 'relative'};
var $rtfeldman$elm_css$Css$solid = {N: 0, a9: 0, o6: 'solid'};
var $rtfeldman$elm_css$Css$transparent = {al: 0, o6: 'transparent'};
var $rtfeldman$elm_css$Css$width = $rtfeldman$elm_css$Css$prop1('width');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$arrow = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A3(
			$rtfeldman$elm_css$Css$borderColor3,
			$rtfeldman$elm_css$Css$hex('#999'),
			$rtfeldman$elm_css$Css$transparent,
			$rtfeldman$elm_css$Css$transparent),
			$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
			$rtfeldman$elm_css$Css$borderTopWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderLeftWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderRightWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderBottomWidth(
			$rtfeldman$elm_css$Css$px(2.5)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative)
		]));
var $rtfeldman$elm_css$Css$top = $rtfeldman$elm_css$Css$prop1('top');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$arrowUpside = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A3(
			$rtfeldman$elm_css$Css$borderColor3,
			$rtfeldman$elm_css$Css$transparent,
			$rtfeldman$elm_css$Css$transparent,
			$rtfeldman$elm_css$Css$hex('#999')),
			$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
			$rtfeldman$elm_css$Css$borderTopWidth(
			$rtfeldman$elm_css$Css$px(2.5)),
			$rtfeldman$elm_css$Css$borderLeftWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderRightWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$borderBottomWidth(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$width($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$top(
			$rtfeldman$elm_css$Css$px(-2.5))
		]));
var $rtfeldman$elm_css$Css$center = $rtfeldman$elm_css$Css$prop1('center');
var $rtfeldman$elm_css$Css$cursor = $rtfeldman$elm_css$Css$prop1('cursor');
var $rtfeldman$elm_css$Css$middle = $rtfeldman$elm_css$Css$prop1('middle');
var $rtfeldman$elm_css$Css$paddingRight = $rtfeldman$elm_css$Css$prop1('padding-right');
var $rtfeldman$elm_css$Css$pointer = {e: 0, o6: 'pointer'};
var $rtfeldman$elm_css$Css$tableCell = {v: 0, o6: 'table-cell'};
var $rtfeldman$elm_css$Css$Internal$property = F2(
	function (key, value) {
		return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var $rtfeldman$elm_css$Css$Internal$getOverloadedProperty = F3(
	function (functionName, desiredKey, style) {
		getOverloadedProperty:
		while (true) {
			switch (style.$) {
				case 0:
					var str = style.a;
					var key = A2(
						$elm$core$Maybe$withDefault,
						'',
						$elm$core$List$head(
							A2($elm$core$String$split, ':', str)));
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, key);
				case 1:
					var selector = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-selector'));
				case 2:
					var combinator = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-combinator'));
				case 3:
					var pseudoElement = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-pseudo-element setter'));
				case 4:
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-media-query'));
				case 5:
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-keyframes'));
				default:
					if (!style.a.b) {
						return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-empty-Style'));
					} else {
						if (!style.a.b.b) {
							var _v1 = style.a;
							var only = _v1.a;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = only;
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						} else {
							var _v2 = style.a;
							var first = _v2.a;
							var rest = _v2.b;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = $rtfeldman$elm_css$Css$Preprocess$ApplyStyles(rest);
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						}
					}
			}
		}
	});
var $rtfeldman$elm_css$Css$Internal$IncompatibleUnits = 0;
var $rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty = A3($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '', 0);
var $rtfeldman$elm_css$Css$textAlign = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'textAlign',
		'text-align',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Css$verticalAlign = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'verticalAlign',
		'vertical-align',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$arrowWrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$tableCell),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$px(25)),
			$rtfeldman$elm_css$Css$paddingRight(
			$rtfeldman$elm_css$Css$px(5))
		]));
var $elm$virtual_dom$VirtualDom$Custom = function (a) {
	return {$: 3, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Events$custom = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$Custom(decoder));
	});
var $inkuzmin$elm_multiselect$Multiselect$withOptions = F2(
	function (options, decoder) {
		return A2(
			$elm$json$Json$Decode$map,
			function (m) {
				return {a4: m, e$: options.e$, fc: options.fc};
			},
			decoder);
	});
var $inkuzmin$elm_multiselect$Multiselect$onClickNoDefault = function (message) {
	var config = {e$: true, fc: true};
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$custom,
		'click',
		A2(
			$inkuzmin$elm_multiselect$Multiselect$withOptions,
			config,
			$elm$json$Json$Decode$succeed(message)));
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$attribute = F2(
	function (key, value) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$attribute, key, value),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$rel = $rtfeldman$elm_css$VirtualDom$Styled$attribute('rel');
var $inkuzmin$elm_multiselect$Multiselect$arrow = function (_v0) {
	var model = _v0;
	var arrowRel = (model.ox === 2) ? 'arrowUpside' : 'arrow';
	var arrowCss = (model.ox === 2) ? _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$arrowUpside]) : _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$arrow]);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$arrowWrap])),
				$inkuzmin$elm_multiselect$Multiselect$onClickNoDefault($inkuzmin$elm_multiselect$Multiselect$Toggle)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(arrowCss),
						$rtfeldman$elm_css$Html$Styled$Attributes$rel(arrowRel)
					]),
				_List_Nil)
			]));
};
var $inkuzmin$elm_multiselect$Multiselect$Clear = {$: 7};
var $rtfeldman$elm_css$Css$fontSize = $rtfeldman$elm_css$Css$prop1('font-size');
var $rtfeldman$elm_css$Css$lineHeight = $rtfeldman$elm_css$Css$prop1('line-height');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$clear = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$fontSize(
			$rtfeldman$elm_css$Css$px(18)),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$num(1))
		]));
var $rtfeldman$elm_css$Css$color = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'color', c.o6);
};
var $rtfeldman$elm_css$Css$Preprocess$ExtendSelector = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$PseudoClassSelector = function (a) {
	return {$: 2, a: a};
};
var $rtfeldman$elm_css$Css$pseudoClass = function (_class) {
	return $rtfeldman$elm_css$Css$Preprocess$ExtendSelector(
		$rtfeldman$elm_css$Css$Structure$PseudoClassSelector(_class));
};
var $rtfeldman$elm_css$Css$hover = $rtfeldman$elm_css$Css$pseudoClass('hover');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$clearWrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$px(17)),
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#999')),
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$tableCell),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle),
			$rtfeldman$elm_css$Css$hover(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$color(
					$rtfeldman$elm_css$Css$hex('#D0021B'))
				]))
		]));
var $inkuzmin$elm_multiselect$Multiselect$clear = function (_v0) {
	var model = _v0;
	return (!$elm$core$List$isEmpty(model.o)) ? A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$clearWrap])),
				$inkuzmin$elm_multiselect$Multiselect$onClickNoDefault($inkuzmin$elm_multiselect$Multiselect$Clear)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[$inkuzmin$elm_multiselect$Multiselect$SelectCss$clear]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('×')
					]))
			])) : A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
};
var $rtfeldman$elm_css$Css$backgroundColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'background-color', c.o6);
};
var $rtfeldman$elm_css$Css$prop3 = F4(
	function (key, argA, argB, argC) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.o6, argB.o6, argC.o6])));
	});
var $rtfeldman$elm_css$Css$border3 = $rtfeldman$elm_css$Css$prop3('border');
var $rtfeldman$elm_css$Css$borderRadius = $rtfeldman$elm_css$Css$prop1('border-radius');
var $rtfeldman$elm_css$Css$PercentageUnits = 0;
var $rtfeldman$elm_css$Css$pct = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '%');
var $rtfeldman$elm_css$Css$table = {v: 0, o6: 'table'};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$container = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A3(
			$rtfeldman$elm_css$Css$border3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid,
			$rtfeldman$elm_css$Css$hex('#ccc')),
			$rtfeldman$elm_css$Css$borderRadius(
			$rtfeldman$elm_css$Css$px(4)),
			A3(
			$rtfeldman$elm_css$Css$borderColor3,
			$rtfeldman$elm_css$Css$hex('#d9d9d9'),
			$rtfeldman$elm_css$Css$hex('#ccc'),
			$rtfeldman$elm_css$Css$hex('#b3b3b3')),
			$rtfeldman$elm_css$Css$backgroundColor(
			$rtfeldman$elm_css$Css$hex('#fff')),
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#333')),
			$rtfeldman$elm_css$Css$height(
			$rtfeldman$elm_css$Css$px(34)),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$table)
		]));
var $rtfeldman$elm_css$Css$borderColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'border-color', c.o6);
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$boxShadowCustom = function (p) {
	return A2($rtfeldman$elm_css$Css$property, 'box-shadow', p);
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$focused = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderColor(
			$rtfeldman$elm_css$Css$hex('#007eff')),
			$inkuzmin$elm_multiselect$Multiselect$SelectCss$boxShadowCustom('inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1)')
		]));
var $inkuzmin$elm_multiselect$Multiselect$Adjust = function (a) {
	return {$: 11, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Filter = function (a) {
	return {$: 10, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$Shortcut = function (a) {
	return {$: 13, a: a};
};
var $rtfeldman$elm_css$Css$border = $rtfeldman$elm_css$Css$prop1('border');
var $rtfeldman$elm_css$Css$initial = {fH: 0, C: 0, b3: 0, fQ: 0, bg: 0, fR: 0, bh: 0, av: 0, N: 0, cQ: 0, al: 0, e: 0, v: 0, bt: 0, eG: 0, cl: 0, dc: 0, aZ: 0, K: 0, bu: 0, l: 0, aC: 0, df: 0, hO: 0, eP: 0, y: 0, bA: 0, aF: 0, bB: 0, bC: 0, a2: 0, a3: 0, an: 0, dh: 0, g: 0, f: 0, eV: 0, nL: 0, aJ: 0, $7: 0, bF: 0, ag: 0, dS: 0, bR: 0, a9: 0, bS: 0, bT: 0, ba: 0, R: 0, bU: '', cJ: 0, o6: 'initial', cK: 0, aQ: 0};
var $rtfeldman$elm_css$Css$inherit = _Utils_update(
	$rtfeldman$elm_css$Css$initial,
	{o6: 'inherit'});
var $rtfeldman$elm_css$Css$int = function (val) {
	return {
		aC: 0,
		df: 0,
		a3: 0,
		an: 0,
		nL: 0,
		dm: 0,
		aJ: val,
		bU: '',
		cJ: 0,
		o6: $elm$core$String$fromInt(val)
	};
};
var $rtfeldman$elm_css$Css$none = {bg: 0, fU: 0, N: 0, e: 0, v: 0, mM: 0, hG: 0, eP: 0, bC: 0, a2: 0, an: 0, g: 0, f: 0, eV: 0, $7: 0, n1: 0, ag: 0, dB: 0, or: 0, bR: 0, ba: 0, R: 0, p: 0, oX: 0, o6: 'none'};
var $rtfeldman$elm_css$Css$outlineStyle = $rtfeldman$elm_css$Css$prop1('outline-style');
var $rtfeldman$elm_css$Css$padding = $rtfeldman$elm_css$Css$prop1('padding');
var $rtfeldman$elm_css$Css$paddingTop = $rtfeldman$elm_css$Css$prop1('padding-top');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$input = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$none),
			$rtfeldman$elm_css$Css$border($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$px(14)),
			$rtfeldman$elm_css$Css$outlineStyle($rtfeldman$elm_css$Css$none),
			$rtfeldman$elm_css$Css$fontSize($rtfeldman$elm_css$Css$inherit),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$int(1)),
			$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$paddingTop(
			$rtfeldman$elm_css$Css$px(8))
		]));
var $rtfeldman$elm_css$Css$absolute = {cw: 0, o6: 'absolute'};
var $rtfeldman$elm_css$Css$fontStyle = $rtfeldman$elm_css$Css$prop1('font-style');
var $rtfeldman$elm_css$Css$fontWeight = function (_v0) {
	var value = _v0.o6;
	return A2($rtfeldman$elm_css$Css$property, 'font-weight', value);
};
var $rtfeldman$elm_css$Css$left = $rtfeldman$elm_css$Css$prop1('left');
var $rtfeldman$elm_css$Css$normal = {ev: 0, bu: 0, aC: 0, e_: 0, o6: 'normal', aQ: 0};
var $rtfeldman$elm_css$Css$overflow = $rtfeldman$elm_css$Css$prop1('overflow');
var $rtfeldman$elm_css$Css$scroll = {b3: 0, fU: 0, hG: 0, bF: 0, os: 0, o6: 'scroll'};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$inputMirrow = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
			$rtfeldman$elm_css$Css$top(
			$rtfeldman$elm_css$Css$px(-100)),
			$rtfeldman$elm_css$Css$left(
			$rtfeldman$elm_css$Css$px(-100)),
			$rtfeldman$elm_css$Css$height($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$scroll),
			$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$normal),
			$rtfeldman$elm_css$Css$fontStyle($rtfeldman$elm_css$Css$normal),
			$rtfeldman$elm_css$Css$fontSize($rtfeldman$elm_css$Css$inherit),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$int(1))
		]));
var $rtfeldman$elm_css$Css$marginLeft = $rtfeldman$elm_css$Css$prop1('margin-left');
var $rtfeldman$elm_css$Css$paddingBottom = $rtfeldman$elm_css$Css$prop1('padding-bottom');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$inputWrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$marginLeft(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$padding($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle),
			$rtfeldman$elm_css$Css$paddingBottom(
			$rtfeldman$elm_css$Css$px(8))
		]));
var $debois$elm_dom$DOM$offsetWidth = A2($elm$json$Json$Decode$field, 'offsetWidth', $elm$json$Json$Decode$float);
var $debois$elm_dom$DOM$previousSibling = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'previousSibling', decoder);
};
var $debois$elm_dom$DOM$target = function (decoder) {
	return A2($elm$json$Json$Decode$field, 'target', decoder);
};
var $inkuzmin$elm_multiselect$Multiselect$onKeyDown = function (tagger) {
	var domF = $debois$elm_dom$DOM$target(
		$debois$elm_dom$DOM$previousSibling($debois$elm_dom$DOM$offsetWidth));
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'keypress',
		A2($elm$json$Json$Decode$map, tagger, domF));
};
var $rtfeldman$elm_css$Html$Styled$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $inkuzmin$elm_multiselect$Multiselect$onKeyPress = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'keydown',
		A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$keyCode));
};
var $inkuzmin$elm_multiselect$Multiselect$onKeyUp = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'keyup',
		A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$targetValue));
};
var $inkuzmin$elm_multiselect$Multiselect$Start = {$: 0};
var $inkuzmin$elm_multiselect$Multiselect$fromResult = function (result) {
	if (!result.$) {
		var successValue = result.a;
		return $elm$json$Json$Decode$succeed(successValue);
	} else {
		var errorMessage = result.a;
		return $elm$json$Json$Decode$fail(errorMessage);
	}
};
var $inkuzmin$elm_multiselect$Multiselect$preventDefaultButtons = function () {
	var options = {e$: true, fc: false};
	var filterKey = function (code) {
		return (_Utils_eq(code, $inkuzmin$elm_multiselect$Multiselect$Keycodes$upArrow) || _Utils_eq(code, $inkuzmin$elm_multiselect$Multiselect$Keycodes$downArrow)) ? $elm$core$Result$Ok(code) : $elm$core$Result$Err('ignored input');
	};
	var decoder = A2(
		$elm$json$Json$Decode$map,
		$elm$core$Basics$always($inkuzmin$elm_multiselect$Multiselect$Start),
		A2(
			$elm$json$Json$Decode$andThen,
			A2($elm$core$Basics$composeR, filterKey, $inkuzmin$elm_multiselect$Multiselect$fromResult),
			$rtfeldman$elm_css$Html$Styled$Events$keyCode));
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$custom,
		'keydown',
		A2($inkuzmin$elm_multiselect$Multiselect$withOptions, options, decoder));
}();
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $rtfeldman$elm_css$VirtualDom$Styled$style = F2(
	function (key, val) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$style, key, val),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$style = $rtfeldman$elm_css$VirtualDom$Styled$style;
var $inkuzmin$elm_multiselect$Multiselect$input = function (_v0) {
	var model = _v0;
	var w = $elm$core$String$fromFloat(model.eM + 23.0);
	var inputStyle = A2($rtfeldman$elm_css$Html$Styled$Attributes$style, 'width', w + 'px');
	var forceClear = function () {
		var _v1 = model.a1;
		if (_v1.$ === 1) {
			return $rtfeldman$elm_css$Html$Styled$Attributes$value('');
		} else {
			return $rtfeldman$elm_css$Html$Styled$Attributes$classList(_List_Nil);
		}
	}();
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$inkuzmin$elm_multiselect$Multiselect$preventDefaultButtons,
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$inputWrap]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[$inkuzmin$elm_multiselect$Multiselect$SelectCss$inputMirrow]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						A2($elm$core$Maybe$withDefault, '', model.a1))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$input,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('multiselectInput' + model.fh),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[$inkuzmin$elm_multiselect$Multiselect$SelectCss$input])),
						$inkuzmin$elm_multiselect$Multiselect$onKeyDown($inkuzmin$elm_multiselect$Multiselect$Adjust),
						$inkuzmin$elm_multiselect$Multiselect$onKeyPress($inkuzmin$elm_multiselect$Multiselect$Shortcut),
						$inkuzmin$elm_multiselect$Multiselect$onKeyUp($inkuzmin$elm_multiselect$Multiselect$Filter),
						inputStyle,
						A2(
						$rtfeldman$elm_css$Html$Styled$Attributes$property,
						'type',
						$elm$json$Json$Encode$string('text')),
						forceClear
					]),
				_List_Nil)
			]));
};
var $inkuzmin$elm_multiselect$Multiselect$OnHover = function (a) {
	return {$: 12, a: a};
};
var $inkuzmin$elm_multiselect$Multiselect$OnSelect = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_css$Css$borderBottomLeftRadius = $rtfeldman$elm_css$Css$prop1('border-bottom-left-radius');
var $rtfeldman$elm_css$Css$borderBottomRightRadius = $rtfeldman$elm_css$Css$prop1('border-bottom-right-radius');
var $rtfeldman$elm_css$Css$borderTopColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'border-top-color', c.o6);
};
var $rtfeldman$elm_css$Css$prop4 = F5(
	function (key, argA, argB, argC, argD) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.o6, argB.o6, argC.o6, argD.o6])));
	});
var $rtfeldman$elm_css$Css$boxShadow4 = $rtfeldman$elm_css$Css$prop4('box-shadow');
var $rtfeldman$elm_css$Css$marginTop = $rtfeldman$elm_css$Css$prop1('margin-top');
var $rtfeldman$elm_css$Css$maxHeight = $rtfeldman$elm_css$Css$prop1('max-height');
var $rtfeldman$elm_css$Css$overflowY = $rtfeldman$elm_css$Css$prop1('overflow-y');
var $rtfeldman$elm_css$Css$cssFunction = F2(
	function (funcName, args) {
		return funcName + ('(' + (A2($elm$core$String$join, ', ', args) + ')'));
	});
var $rtfeldman$elm_css$Css$rgba = F4(
	function (r, g, b, alpha) {
		return {
			b0: alpha,
			b4: b,
			al: 0,
			co: g,
			cC: r,
			o6: A2(
				$rtfeldman$elm_css$Css$cssFunction,
				'rgba',
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$elm$core$String$fromInt,
						_List_fromArray(
							[r, g, b])),
					_List_fromArray(
						[
							$elm$core$String$fromFloat(alpha)
						])))
		};
	});
var $rtfeldman$elm_css$Css$zIndex = $rtfeldman$elm_css$Css$prop1('z-index');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$menu = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderBottomRightRadius(
			$rtfeldman$elm_css$Css$px(4)),
			$rtfeldman$elm_css$Css$borderBottomLeftRadius(
			$rtfeldman$elm_css$Css$px(4)),
			$rtfeldman$elm_css$Css$backgroundColor(
			$rtfeldman$elm_css$Css$hex('#fff')),
			A3(
			$rtfeldman$elm_css$Css$border3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid,
			$rtfeldman$elm_css$Css$hex('#ccc')),
			$rtfeldman$elm_css$Css$borderTopColor(
			$rtfeldman$elm_css$Css$hex('#e6e6e6')),
			A4(
			$rtfeldman$elm_css$Css$boxShadow4,
			$rtfeldman$elm_css$Css$zero,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$zero,
			A4($rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0.06)),
			$rtfeldman$elm_css$Css$marginTop(
			$rtfeldman$elm_css$Css$px(-1)),
			$rtfeldman$elm_css$Css$maxHeight(
			$rtfeldman$elm_css$Css$px($inkuzmin$elm_multiselect$Multiselect$SelectCss$menuHeight)),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$zIndex(
			$rtfeldman$elm_css$Css$int(1)),
			$rtfeldman$elm_css$Css$overflowY($rtfeldman$elm_css$Css$scroll)
		]));
var $rtfeldman$elm_css$Css$prop2 = F3(
	function (key, argA, argB) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.o6, argB.o6])));
	});
var $rtfeldman$elm_css$Css$padding2 = $rtfeldman$elm_css$Css$prop2('padding');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItem = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#666')),
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			A2(
			$rtfeldman$elm_css$Css$padding2,
			$rtfeldman$elm_css$Css$px(8),
			$rtfeldman$elm_css$Css$px(10)),
			$rtfeldman$elm_css$Css$maxHeight(
			$rtfeldman$elm_css$Css$px($inkuzmin$elm_multiselect$Multiselect$SelectCss$itemHeight))
		]));
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItemHovered = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$backgroundColor(
			A4($rtfeldman$elm_css$Css$rgba, 0, 126, 255, 0.08)),
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#333'))
		]));
var $rtfeldman$elm_css$Html$Styled$Events$onMouseOver = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'mouseover',
		$elm$json$Json$Decode$succeed(msg));
};
var $inkuzmin$elm_multiselect$Multiselect$menu = function (_v0) {
	var model = _v0;
	var _v1 = model.ox;
	if (_v1 === 2) {
		var hovered = function () {
			var _v3 = model.t;
			if (_v3.$ === 1) {
				return '';
			} else {
				var _v4 = _v3.a;
				var id = _v4.a;
				return id;
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[$inkuzmin$elm_multiselect$Multiselect$SelectCss$menu])),
					$rtfeldman$elm_css$Html$Styled$Attributes$id('multiselectMenu' + model.fh)
				]),
			A2(
				$elm$core$List$map,
				function (_v2) {
					var name = _v2.a;
					var value = _v2.b;
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_Utils_eq(name, hovered) ? _List_fromArray(
									[$inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItemHovered, $inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItem]) : _List_fromArray(
									[$inkuzmin$elm_multiselect$Multiselect$SelectCss$menuItem])),
								$inkuzmin$elm_multiselect$Multiselect$onClickNoDefault(
								$inkuzmin$elm_multiselect$Multiselect$OnSelect(
									_Utils_Tuple2(name, value))),
								$rtfeldman$elm_css$Html$Styled$Events$onMouseOver(
								$inkuzmin$elm_multiselect$Multiselect$OnHover(
									_Utils_Tuple2(name, value)))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(value)
							]));
				},
				model.s));
	} else {
		return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
	}
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$opened = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$borderBottomLeftRadius($rtfeldman$elm_css$Css$zero),
			$rtfeldman$elm_css$Css$borderBottomRightRadius($rtfeldman$elm_css$Css$zero)
		]));
var $inkuzmin$elm_multiselect$Multiselect$RemoveItem = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$border2 = $rtfeldman$elm_css$Css$prop2('border');
var $rtfeldman$elm_css$Css$EmUnits = 0;
var $rtfeldman$elm_css$Css$em = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'em');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$tag = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('#007eff')),
			A2(
			$rtfeldman$elm_css$Css$border2,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid),
			$rtfeldman$elm_css$Css$borderColor(
			A4($rtfeldman$elm_css$Css$rgba, 0, 126, 255, 0.24)),
			$rtfeldman$elm_css$Css$borderRadius(
			$rtfeldman$elm_css$Css$px(2)),
			$rtfeldman$elm_css$Css$backgroundColor(
			A4($rtfeldman$elm_css$Css$rgba, 0, 126, 255, 0.08)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$fontSize(
			$rtfeldman$elm_css$Css$em(0.9)),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$num(1.4)),
			$rtfeldman$elm_css$Css$marginLeft(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$marginTop(
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$top)
		]));
var $rtfeldman$elm_css$Css$borderRight3 = $rtfeldman$elm_css$Css$prop3('border-right');
var $rtfeldman$elm_css$Css$padding3 = $rtfeldman$elm_css$Css$prop3('padding');
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$tagIcon = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$hover(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$backgroundColor(
					$rtfeldman$elm_css$Css$hex('#d8eafd'))
				])),
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			A3(
			$rtfeldman$elm_css$Css$borderRight3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$solid,
			A4($rtfeldman$elm_css$Css$rgba, 0, 126, 255, 0.24)),
			A3(
			$rtfeldman$elm_css$Css$padding3,
			$rtfeldman$elm_css$Css$px(1),
			$rtfeldman$elm_css$Css$px(5),
			$rtfeldman$elm_css$Css$px(3)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle)
		]));
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$tagLabel = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Css$padding2,
			$rtfeldman$elm_css$Css$px(2),
			$rtfeldman$elm_css$Css$px(5)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
			$rtfeldman$elm_css$Css$verticalAlign($rtfeldman$elm_css$Css$middle)
		]));
var $inkuzmin$elm_multiselect$Multiselect$tag = F2(
	function (name, value) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[$inkuzmin$elm_multiselect$Multiselect$SelectCss$tag]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[$inkuzmin$elm_multiselect$Multiselect$SelectCss$tagIcon])),
							$rtfeldman$elm_css$Html$Styled$Events$onClick(
							$inkuzmin$elm_multiselect$Multiselect$RemoveItem(
								_Utils_Tuple2(name, value)))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('×')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[$inkuzmin$elm_multiselect$Multiselect$SelectCss$tagLabel]))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(value)
						]))
				]));
	});
var $rtfeldman$elm_css$Css$inline = {v: 0, o6: 'inline'};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$tagWrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inline)
		]));
var $inkuzmin$elm_multiselect$Multiselect$tags = function (_v0) {
	var model = _v0;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$tagWrap]))
			]),
		A2(
			$elm$core$List$map,
			function (_v1) {
				var name = _v1.a;
				var value = _v1.b;
				return A2($inkuzmin$elm_multiselect$Multiselect$tag, name, value);
			},
			model.o));
};
var $inkuzmin$elm_multiselect$Multiselect$SelectCss$wrap = $rtfeldman$elm_css$Css$batch(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100))
		]));
var $inkuzmin$elm_multiselect$Multiselect$styledView = function (_v0) {
	var model = _v0;
	var inputCss = (model.ox === 1) ? _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$container, $inkuzmin$elm_multiselect$Multiselect$SelectCss$focused]) : ((model.ox === 2) ? _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$container, $inkuzmin$elm_multiselect$Multiselect$SelectCss$opened]) : _List_fromArray(
		[$inkuzmin$elm_multiselect$Multiselect$SelectCss$container]));
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[$inkuzmin$elm_multiselect$Multiselect$SelectCss$wrap])),
				$rtfeldman$elm_css$Html$Styled$Events$onClick($inkuzmin$elm_multiselect$Multiselect$ClickOnComponent)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(inputCss)
					]),
				_List_fromArray(
					[
						$inkuzmin$elm_multiselect$Multiselect$tags(model),
						$inkuzmin$elm_multiselect$Multiselect$input(model),
						$inkuzmin$elm_multiselect$Multiselect$clear(model),
						$inkuzmin$elm_multiselect$Multiselect$arrow(model)
					])),
				$inkuzmin$elm_multiselect$Multiselect$menu(model)
			]));
};
var $inkuzmin$elm_multiselect$Multiselect$view = A2($elm$core$Basics$composeR, $inkuzmin$elm_multiselect$Multiselect$styledView, $rtfeldman$elm_css$Html$Styled$toUnstyled);
var $author$project$Components$Cabinets$Users$List$View$filters = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('filters')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Recherche par nom d\'utilisateur')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$input,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput(
								A2($elm$core$Basics$composeL, $author$project$Components$Cabinets$Users$List$Update$Filters, $author$project$Components$Cabinets$Users$List$Update$ByUser)),
								$rtfeldman$elm_css$Html$Styled$Attributes$class('search'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.az.cS)
							]),
						_List_Nil)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Filtrer par statut d\'activation')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								A2($elm$core$Basics$composeL, $author$project$Components$Cabinets$Users$List$Update$Filters, $author$project$Components$Cabinets$Users$List$Update$ByActivationState),
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.az.b5)))
							]))
					]))
			]));
};
var $author$project$Components$Cabinets$Users$List$View$footerButtons = function (model) {
	var cabUid = model.fZ.kx;
	var but = F3(
		function (url, label, decorate) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class(decorate),
						$rtfeldman$elm_css$Html$Styled$Attributes$href(url)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(label)
					]));
		});
	var createButton = A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			function (_v0) {
				return _List_fromArray(
					[
						A3(
						but,
						$author$project$Routes$Model$routes.ll.o3.lF.dX(cabUid),
						'Créer un nouvel utilisateur',
						'button primary add')
					]);
			},
			model.eh));
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('fixed')
			]),
		createButton);
};
var $author$project$Components$Cabinets$Users$List$View$showActivationStatus = function (user) {
	return (!user.ox) ? A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('status activated')
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text('Activé')
			])) : ((user.ox === 1) ? A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('status pending')
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text('En attente d\'activation')
			])) : A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('status disabled')
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text('Désactivé')
			])));
};
var $author$project$Components$Cabinets$Users$List$View$showSyncStatus = F2(
	function (cabinet, user) {
		var _v0 = user.m5 ? _Utils_Tuple2('synced', 'Associé à un compte') : _Utils_Tuple2('notsynced', 'Non associé à un compte');
		var class_ = _v0.a;
		var text_ = _v0.b;
		return A2(
			$elm$core$Maybe$withDefault,
			$rtfeldman$elm_css$Html$Styled$text(''),
			A2(
				$elm$core$Maybe$map,
				function (_v1) {
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('o2sSyncStatus')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class(class_),
										$rtfeldman$elm_css$Html$Styled$Attributes$title(text_)
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('O2S')
									]))
							]));
				},
				cabinet.mh));
	});
var $author$project$Components$Cabinets$Users$List$View$userView = F2(
	function (cabinet, user) {
		var updateLink = A2(
			$elm$core$Maybe$withDefault,
			$rtfeldman$elm_css$Html$Styled$text(''),
			A2(
				$elm$core$Maybe$map,
				function (_v1) {
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('action')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$a,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$href(
										$author$project$Routes$Model$routes.ll.o3.mG.dX(
											{lj: cabinet.kx, kB: user.kx}))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Voir l\'utilisateur')
									]))
							]));
				},
				user.o_));
		var role = function () {
			var _v0 = user.oo;
			switch (_v0) {
				case 0:
					return 'Dirigeant';
				case 1:
					return 'Conseiller';
				default:
					return 'Assistant';
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('item')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('cartouche-user')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('main')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('logo')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(
											_Utils_ap(
												A3($elm$core$String$slice, 0, 1, user.ms),
												A3($elm$core$String$slice, 0, 1, user.na)))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('title')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(user.ms + (' ' + user.na))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('infos')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$ul,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$li,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('role')
														]),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text(role)
														])),
													A2(
													$rtfeldman$elm_css$Html$Styled$li,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('email')
														]),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text(user.nh)
														]))
												]))
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('aside')
								]),
							_List_fromArray(
								[
									(!user.ox) ? A2($author$project$Components$Cabinets$Users$List$View$showSyncStatus, cabinet, user) : $rtfeldman$elm_css$Html$Styled$text(''),
									$author$project$Components$Cabinets$Users$List$View$showActivationStatus(user),
									updateLink
								]))
						]))
				]));
	});
var $author$project$Components$Cabinets$Users$List$View$view = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('page-cabinet-users')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('top')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$h1,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(model.fZ.nz)
							])),
						A2($author$project$Components$Cabinets$Utils$CabinetOnglets$cabinetOnglets, model.fZ.kx, 1)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
					]),
				_List_fromArray(
					[
						$author$project$Components$Cabinets$Users$List$View$filters(model),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('main')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$ul,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('list')
									]),
								A2(
									$elm$core$List$map,
									$author$project$Components$Cabinets$Users$List$View$userView(model.fZ),
									model.eF))
							]))
					])),
				$author$project$Components$Cabinets$Users$List$View$footerButtons(model)
			]));
};
var $author$project$Components$Cabinets$Users$New$View$CreateOrUpdate = F3(
	function (formTitle, saveButtonLabel, disabledLogin) {
		return {gM: disabledLogin, hc: formTitle, dI: saveButtonLabel};
	});
var $author$project$Components$Cabinets$Users$New$Update$CancelCreation = {$: 11};
var $author$project$Components$Cabinets$Users$New$Update$CreateUser = {$: 9};
var $author$project$Components$Cabinets$Users$New$Update$DeactivateUser = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $author$project$Components$Drawer$ConfirmationDrawer$Messages = $elm$core$Basics$identity;
var $author$project$Components$Cabinets$Users$New$Update$SetFilterRight = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Cabinets$Users$New$Update$SetFirstName = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Cabinets$Users$New$Update$SetLastName = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Cabinets$Users$New$Update$SetRole = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Cabinets$Users$New$Update$SetUserLogin = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Drawer$ConfirmationDrawer$Title = $elm$core$Basics$identity;
var $author$project$Components$Cabinets$Users$New$View$activitesRightsView = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Droits sur les activités du cabinet')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Components$Cabinets$Users$New$Update$SetActivitesRights,
						$rtfeldman$elm_css$Html$Styled$fromUnstyled(
							$inkuzmin$elm_multiselect$Multiselect$view(model.c2)))
					]))
			]));
};
var $author$project$Components$Cabinets$Users$New$Model$availableFilterRights = _List_fromArray(
	[
		$author$project$Components$Cabinets$Model$LinkedToDirigeant,
		$author$project$Components$Cabinets$Model$LinkedToUser(_List_Nil)
	]);
var $author$project$Components$Cabinets$Users$New$Model$filterRightKey = function (right) {
	if (!right.$) {
		return 'LinkedToUser';
	} else {
		return 'LikendToDirigeant';
	}
};
var $author$project$Components$Cabinets$Users$New$Model$showFilterRight = function (right) {
	if (!right.$) {
		return 'Paramétrer les portefeuilles visibles';
	} else {
		return 'Voir l\'ensemble des portefeuilles';
	}
};
var $author$project$Components$Cabinets$Users$New$View$buildFilterRightOptions = A2(
	$elm$core$List$map,
	function (r) {
		return _Utils_Tuple2(
			$author$project$Components$Cabinets$Users$New$Model$filterRightKey(r),
			$author$project$Components$Cabinets$Users$New$Model$showFilterRight(r));
	},
	$author$project$Components$Cabinets$Users$New$Model$availableFilterRights);
var $rtfeldman$elm_css$Html$Styled$option = $rtfeldman$elm_css$Html$Styled$node('option');
var $rtfeldman$elm_css$Html$Styled$Attributes$selected = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('selected');
var $PerformanceIMMO$elm_utils$Perfimmo$Html$Dom$buildOption = F2(
	function (v0, _v0) {
		var id = _v0.a;
		var nom = _v0.b;
		return A2(
			$rtfeldman$elm_css$Html$Styled$option,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$value(id),
					$rtfeldman$elm_css$Html$Styled$Attributes$selected(
					A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							$elm$core$Basics$eq(id),
							v0)))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(nom)
				]));
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Html$Dom$buildOptions = F2(
	function (value, values) {
		return _Utils_ap(
			A2(
				$elm$core$Maybe$withDefault,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$option,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Choisir une valeur')
							]))
					]),
				A2(
					$elm$core$Maybe$map,
					$elm$core$Basics$always(_List_Nil),
					value)),
			A2(
				$elm$core$List$map,
				$PerformanceIMMO$elm_utils$Perfimmo$Html$Dom$buildOption(value),
				values));
	});
var $author$project$Components$Cabinets$Users$New$Model$availableRoles = _List_fromArray(
	[0, 1, 2]);
var $author$project$Components$Cabinets$Users$New$Model$showRole = function (role) {
	switch (role) {
		case 0:
			return 'Dirigeant';
		case 1:
			return 'Conseiller';
		default:
			return 'Assistant';
	}
};
var $author$project$Components$Cabinets$Users$New$View$buildRoleOptions = A2(
	$elm$core$List$map,
	function (r) {
		return _Utils_Tuple2(
			$author$project$Components$Cabinets$Users$New$Model$showRole(r),
			$author$project$Components$Cabinets$Users$New$Model$showRole(r));
	},
	$author$project$Components$Cabinets$Users$New$Model$availableRoles);
var $author$project$Components$Cabinets$Users$New$View$handleCabinetRightView = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Droits sur les utilisateurs')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Components$Cabinets$Users$New$Update$SetCabinetRights,
						$rtfeldman$elm_css$Html$Styled$fromUnstyled(
							$inkuzmin$elm_multiselect$Multiselect$view(model.c3)))
					]))
			]));
};
var $author$project$Components$Cabinets$Users$New$View$handleClientsRightsView = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Droits sur les clients')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Components$Cabinets$Users$New$Update$SetClientsRights,
						$rtfeldman$elm_css$Html$Styled$fromUnstyled(
							$inkuzmin$elm_multiselect$Multiselect$view(model.c4)))
					]))
			]));
};
var $author$project$Components$Cabinets$Users$New$View$onglet = F2(
	function (model, id_) {
		var active = _Utils_eq(model.gq, id_) ? 'active' : '';
		return $rtfeldman$elm_css$Html$Styled$div(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('onglet'),
					$rtfeldman$elm_css$Html$Styled$Attributes$id(id_),
					$rtfeldman$elm_css$Html$Styled$Attributes$class(active)
				]));
	});
var $author$project$Components$Cabinets$Users$New$Update$SetTab = function (a) {
	return {$: 16, a: a};
};
var $author$project$Components$Cabinets$Users$New$Model$ongletRoleetDroits_ = 'role_et_droits';
var $author$project$Components$Cabinets$Users$New$View$ongletButton = F2(
	function (model, id_) {
		var ongletLabel = _Utils_eq(id_, $author$project$Components$Cabinets$Users$New$Model$ongletRoleetDroits_) ? 'Rôles & Droits' : (_Utils_eq(id_, $author$project$Components$Cabinets$Users$New$Model$ongletId_) ? 'Informations' : '');
		var active = _Utils_eq(model.gq, id_) ? 'active' : '';
		return A2(
			$rtfeldman$elm_css$Html$Styled$button,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
					$author$project$Components$Cabinets$Users$New$Update$SetTab(id_)),
					$rtfeldman$elm_css$Html$Styled$Attributes$class(active)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(ongletLabel)
				]));
	});
var $rtfeldman$elm_css$Html$Styled$select = $rtfeldman$elm_css$Html$Styled$node('select');
var $author$project$Components$Cabinets$Users$New$View$showSyncAlready = function (u) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('already-synced')
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						u.m5 ? 'Associé à un compte' : '')
					]))
			]));
};
var $author$project$Components$Cabinets$Users$New$View$showSyncTodo = F2(
	function (model, u) {
		return A2(
			$elm$core$Maybe$map,
			function (_v0) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$label,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Choisir un utilisateur O2S à associer')
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$map,
											$author$project$Components$Cabinets$Users$New$Update$SetAvailableSyncUsers,
											$rtfeldman$elm_css$Html$Styled$fromUnstyled(
												$inkuzmin$elm_multiselect$Multiselect$view(model.fO)))
										]))
								]))
						]));
			},
			u.oZ);
	});
var $author$project$Components$Cabinets$Users$New$View$showSyncInfo = function (model) {
	return $PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten(
		$elm_community$maybe_extra$Maybe$Extra$toList(
			A2(
				$elm$core$Maybe$map,
				function (_v0) {
					return A2(
						$elm$core$Maybe$withDefault,
						_List_Nil,
						A2(
							$elm$core$Maybe$map,
							function (u) {
								return _List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
												$rtfeldman$elm_css$Html$Styled$Attributes$id('o2sSynchro')
											]),
										_Utils_ap(
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$h2,
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('Synchronisation O2S')
														]))
												]),
											_List_fromArray(
												[
													A2(
													$elm$core$Maybe$withDefault,
													$author$project$Components$Cabinets$Users$New$View$showSyncAlready(u),
													A2($author$project$Components$Cabinets$Users$New$View$showSyncTodo, model, u))
												])))
									]);
							},
							model.er));
				},
				model.fZ.mh)));
};
var $author$project$Components$Drawer$ConfirmationDrawer$CloseConfirmationDrawer = {$: 0};
var $author$project$Components$Drawer$ConfirmationDrawer$ConfirmationDrawerEvents = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Drawer$ConfirmationDrawer$OnConfirm = {$: 2};
var $author$project$Components$Drawer$Update$Close = {$: 0};
var $author$project$Components$Drawer$Update$ContentWrapperMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Drawer$View$contentWrapper = function (content) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('hider')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('scroll')
					]),
				_List_fromArray(
					[content]))
			]));
};
var $author$project$Components$Drawer$View$view = F2(
	function (model, content) {
		var openDrawer = function () {
			var _v0 = model.ow;
			if (!_v0) {
				return true;
			} else {
				return false;
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('drawer'),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('opened', openDrawer)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('drawerClose')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$button,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Drawer$Update$Close)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Fermer')
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$map,
					$author$project$Components$Drawer$Update$ContentWrapperMsg,
					$author$project$Components$Drawer$View$contentWrapper(content))
				]));
	});
var $author$project$Components$Drawer$ConfirmationDrawer$view = F4(
	function (model, _v0, _v1, warning) {
		var title = _v0;
		var messages = _v1;
		var htmlWarning = _List_fromArray(
			[
				A2(
				$elm$core$Maybe$withDefault,
				A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil),
				A2(
					$elm$core$Maybe$map,
					function (_v2) {
						var w = _v2;
						return A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-warning')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(w)
								]));
					},
					warning))
			]);
		var htmlTitle = _List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h2,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(title)
					]))
			]);
		var htmlError = _List_fromArray(
			[
				A2(
				$elm$core$Maybe$withDefault,
				A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
				A2(
					$elm$core$Maybe$map,
					function (err) {
						return A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('errorMessage')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(err)
								]));
					},
					A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						function (m) {
							return m.be;
						},
						model)))
			]);
		var htmlDrawerAction = _List_fromArray(
			[
				A2(
				$elm$core$Maybe$withDefault,
				$rtfeldman$elm_css$Html$Styled$text(''),
				A2(
					$elm$core$Maybe$map,
					function (m) {
						return A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('drawerActions')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$button,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('button secondary'),
											$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Drawer$ConfirmationDrawer$CloseConfirmationDrawer)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Annuler')
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$button,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('button primary done'),
											$rtfeldman$elm_css$Html$Styled$Attributes$classList(
											_List_fromArray(
												[
													_Utils_Tuple2('loading', m.aG)
												])),
											$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Drawer$ConfirmationDrawer$OnConfirm)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Valider')
										]))
								]));
					},
					model))
			]);
		var content = A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('drawer-confirmation')
				]),
			_Utils_ap(
				htmlTitle,
				_Utils_ap(
					A2(
						$elm$core$List$map,
						function (m) {
							return A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(m)
									]));
						},
						messages),
					_Utils_ap(
						htmlWarning,
						_Utils_ap(htmlError, htmlDrawerAction)))));
		return A2(
			$elm$core$Maybe$withDefault,
			A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil),
			A2(
				$elm$core$Maybe$map,
				function (m) {
					return A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Components$Drawer$ConfirmationDrawer$ConfirmationDrawerEvents,
						A2($author$project$Components$Drawer$View$view, m.ep, content));
				},
				model));
	});
var $author$project$Components$Cabinets$Users$New$View$formView = F2(
	function (model, createOrUpdateInfo) {
		var filterRightUser = function () {
			var _v20 = model.eA;
			if ((!_v20.$) && (!_v20.a.$)) {
				return _List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item rightForAssistant')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Portefeuille utilisateur de l\'assistant')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$map,
										$author$project$Components$Cabinets$Users$New$Update$SetUsersLinkedToAssistant,
										$rtfeldman$elm_css$Html$Styled$fromUnstyled(
											$inkuzmin$elm_multiselect$Multiselect$view(model.d4)))
									]))
							]))
					]);
			} else {
				return _List_Nil;
			}
		}();
		var filterRight = _Utils_eq(
			model.oo,
			$elm$core$Maybe$Just(2)) ? _Utils_ap(
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$label,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$for('filterRight')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Droits de l\'assistant')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$select,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$id('filterRight'),
									$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Components$Cabinets$Users$New$Update$SetFilterRight)
								]),
							A2(
								$PerformanceIMMO$elm_utils$Perfimmo$Html$Dom$buildOptions,
								A2($elm$core$Maybe$map, $author$project$Components$Cabinets$Users$New$Model$filterRightKey, model.eA),
								$author$project$Components$Cabinets$Users$New$View$buildFilterRightOptions))
						]))
				]),
			filterRightUser) : _List_Nil;
		var _v0 = function () {
			var _v1 = _Utils_Tuple2(model.oq, model.jC);
			switch (_v1.a) {
				case 0:
					switch (_v1.b) {
						case 0:
							var _v2 = _v1.a;
							var _v3 = _v1.b;
							return _Utils_Tuple2(false, 'notModified');
						case 1:
							var _v4 = _v1.a;
							var _v5 = _v1.b;
							return _Utils_Tuple2(true, 'readyToSave');
						default:
							var _v6 = _v1.a;
							var _v7 = _v1.b;
							return _Utils_Tuple2(false, 'saving');
					}
				case 1:
					switch (_v1.b) {
						case 1:
							var _v8 = _v1.a;
							var _v9 = _v1.b;
							return _Utils_Tuple2(true, 'readyToSave');
						case 2:
							var _v10 = _v1.a;
							var _v11 = _v1.b;
							return _Utils_Tuple2(false, 'saving');
						default:
							var _v12 = _v1.a;
							var _v13 = _v1.b;
							return _Utils_Tuple2(true, 'readyToSave');
					}
				default:
					switch (_v1.b) {
						case 2:
							var _v14 = _v1.a;
							var _v15 = _v1.b;
							return _Utils_Tuple2(false, 'saving');
						case 1:
							var _v16 = _v1.a;
							var _v17 = _v1.b;
							return _Utils_Tuple2(false, 'saving');
						default:
							var _v18 = _v1.a;
							var _v19 = _v1.b;
							return _Utils_Tuple2(false, 'saving');
					}
			}
		}();
		var readyToCreate = _v0.a;
		var saveStateClass = _v0.b;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$map,
					$author$project$Components$Cabinets$Users$New$Update$DeactivateAction(
						{kx: model.fZ.kx}),
					A4(
						$author$project$Components$Drawer$ConfirmationDrawer$view,
						model.gD,
						'Désactiver l’utilisateur',
						_List_fromArray(
							['Confirmez-vous la désactivation de l’utilisateur ' + (model.ms + (' ' + (model.na + ' ?'))), 'Une fois désactivé, celui-ci n’aura plus accès à MustCompliance.', 'Vous pourrez le réactiver à tout moment en lui renvoyant un e-mail d’activation.']),
						$elm$core$Maybe$Nothing)),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$id('top')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$button,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('back'),
									$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Cabinets$Users$New$Update$CancelCreation)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Tous les utilisateurs du cabinet ')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('top-content')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$h1,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(createOrUpdateInfo.hc)
										])),
									(!model.ox) ? A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('status activated')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Activé')
										])) : ((model.ox === 1) ? A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('status pending')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('En attente d\'activation')
										])) : A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('status disabled')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Désactivé')
										])))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('tabs')
								]),
							_List_fromArray(
								[
									A2($author$project$Components$Cabinets$Users$New$View$ongletButton, model, $author$project$Components$Cabinets$Users$New$Model$ongletId_),
									A2($author$project$Components$Cabinets$Users$New$View$ongletButton, model, $author$project$Components$Cabinets$Users$New$Model$ongletRoleetDroits_)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Components$Cabinets$Users$New$View$onglet,
							model,
							$author$project$Components$Cabinets$Users$New$Model$ongletId_,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
											$rtfeldman$elm_css$Html$Styled$Attributes$id('identity')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$h2,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Identité')
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
												]),
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$div,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item login mandatory')
														]),
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$label,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$for('userLogin')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('Identifiant de l\'utilisateur (e-mail)')
																])),
															A2(
															$rtfeldman$elm_css$Html$Styled$input,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$id('userLogin'),
																	$rtfeldman$elm_css$Html$Styled$Attributes$type_('email'),
																	$rtfeldman$elm_css$Html$Styled$Attributes$value(model.nh),
																	$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Components$Cabinets$Users$New$Update$SetUserLogin),
																	$rtfeldman$elm_css$Html$Styled$Attributes$disabled(createOrUpdateInfo.gM)
																]),
															_List_Nil)
														])),
													A2(
													$rtfeldman$elm_css$Html$Styled$div,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item mandatory')
														]),
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$label,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$for('firstName')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('Prénom')
																])),
															A2(
															$rtfeldman$elm_css$Html$Styled$input,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$id('firstName'),
																	$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
																	$rtfeldman$elm_css$Html$Styled$Attributes$value(model.ms),
																	$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Components$Cabinets$Users$New$Update$SetFirstName)
																]),
															_List_Nil)
														])),
													A2(
													$rtfeldman$elm_css$Html$Styled$div,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item mandatory')
														]),
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$label,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$for('lastName')
																]),
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text('Nom')
																])),
															A2(
															$rtfeldman$elm_css$Html$Styled$input,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$id('lastName'),
																	$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
																	$rtfeldman$elm_css$Html$Styled$Attributes$value(model.na),
																	$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Components$Cabinets$Users$New$Update$SetLastName)
																]),
															_List_Nil)
														]))
												]))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_Nil,
									$author$project$Components$Cabinets$Users$New$View$showSyncInfo(model))
								])),
							A3(
							$author$project$Components$Cabinets$Users$New$View$onglet,
							model,
							$author$project$Components$Cabinets$Users$New$Model$ongletRoleetDroits_,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
											$rtfeldman$elm_css$Html$Styled$Attributes$id('role')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$h2,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Rôle')
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
												]),
											_Utils_ap(
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$div,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item ')
															]),
														_List_fromArray(
															[
																A2(
																$rtfeldman$elm_css$Html$Styled$label,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$for('role')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text('Rôle')
																	])),
																A2(
																$rtfeldman$elm_css$Html$Styled$select,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$id('role'),
																		$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Components$Cabinets$Users$New$Update$SetRole)
																	]),
																A2(
																	$PerformanceIMMO$elm_utils$Perfimmo$Html$Dom$buildOptions,
																	A2($elm$core$Maybe$map, $author$project$Components$Cabinets$Users$New$Model$showRole, model.oo),
																	$author$project$Components$Cabinets$Users$New$View$buildRoleOptions))
															]))
													]),
												filterRight))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
											$rtfeldman$elm_css$Html$Styled$Attributes$id('rights')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$h2,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Droits')
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
												]),
											_List_fromArray(
												[
													$author$project$Components$Cabinets$Users$New$View$handleCabinetRightView(model),
													$author$project$Components$Cabinets$Users$New$View$activitesRightsView(model),
													$author$project$Components$Cabinets$Users$New$View$handleClientsRightsView(model)
												]))
										]))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$id('fixed')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$button,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Cabinets$Users$New$Update$CancelCreation),
									$rtfeldman$elm_css$Html$Styled$Attributes$class('back')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Retour')
								])),
							A2(
							$elm$core$Maybe$withDefault,
							A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil),
							A2(
								$elm$core$Maybe$map,
								function (link) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('optionsMenu')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$button,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('toggle')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text('Options')
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('menu')
													]),
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$button,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Events$onClick(
																A2(
																	$author$project$Components$Cabinets$Users$New$Update$DeactivateUser,
																	{kx: model.fZ.kx},
																	link)),
																$rtfeldman$elm_css$Html$Styled$Attributes$class('deactivate')
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text('Désactiver l\'utilisateur')
															]))
													]))
											]));
								},
								model.gE)),
							A2(
							$rtfeldman$elm_css$Html$Styled$button,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Cabinets$Users$New$Update$CreateUser),
									$rtfeldman$elm_css$Html$Styled$Attributes$class('save'),
									$rtfeldman$elm_css$Html$Styled$Attributes$class(saveStateClass),
									$rtfeldman$elm_css$Html$Styled$Attributes$disabled(!readyToCreate)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(createOrUpdateInfo.dI)
								]))
						]))
				]));
	});
var $author$project$Components$Cabinets$Users$New$View$view = function (model) {
	var createOrUpdateInfo = function () {
		var _v0 = model.er;
		if (!_v0.$) {
			var u = _v0.a;
			return A3($author$project$Components$Cabinets$Users$New$View$CreateOrUpdate, u.ms + (' ' + u.na), 'Sauvegarder', true);
		} else {
			return A3($author$project$Components$Cabinets$Users$New$View$CreateOrUpdate, 'Créer un utilisateur', 'Sauvegarder', false);
		}
	}();
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('page-user')
			]),
		_List_fromArray(
			[
				A2($author$project$Components$Cabinets$Users$New$View$formView, model, createOrUpdateInfo)
			]));
};
var $author$project$Components$Clients$Detail$Action$ActiveOnglet = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$Action$Cancel = {$: 5};
var $author$project$Components$Clients$Detail$Model$Conseillers = 7;
var $author$project$Components$Clients$Detail$Model$InfoComplementaire = 1;
var $author$project$Components$Clients$Detail$Model$ProfilExtraFinancier = 6;
var $author$project$Components$Clients$Detail$Model$ProfilInvestisseur = 5;
var $author$project$Components$Clients$Detail$Model$RepresentantsLegaux = 4;
var $author$project$Components$Clients$Detail$Model$SituationFamilialePersonnePhysique = 2;
var $author$project$Components$Clients$Detail$Model$SituationPatrimonialePersonnePhysique = 3;
var $author$project$Components$Drawer$ConfirmationDrawer$Warning = $elm$core$Basics$identity;
var $author$project$Components$Clients$Detail$Action$DeleteClient = function (a) {
	return {$: 8, a: a};
};
var $author$project$Components$Clients$Detail$View$deleteButton = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil),
		A2(
			$elm$core$Maybe$map,
			function (link) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('optionsMenu')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$button,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('toggle')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Options')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('menu')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$button,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Events$onClick(
											$author$project$Components$Clients$Detail$Action$DeleteClient(link)),
											$rtfeldman$elm_css$Html$Styled$Attributes$class('delete')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Supprimer le client')
										]))
								]))
						]));
			},
			model.d1.gJ));
};
var $author$project$Components$Clients$Detail$UpdateForm$DisplayError$checkOneError = F2(
	function (onglet, error) {
		switch (error.$) {
			case 3:
				return onglet === 7;
			case 1:
				return onglet === 4;
			case 2:
				return onglet === 4;
			default:
				var formFieldDescriptor = error.a;
				switch (formFieldDescriptor.$) {
					case 0:
						var natureJuridiqueFields = formFieldDescriptor.a;
						switch (natureJuridiqueFields.$) {
							case 0:
								return false;
							case 1:
								var personnePhysiqueFields = natureJuridiqueFields.a;
								switch (personnePhysiqueFields.$) {
									case 0:
										return !onglet;
									case 1:
										return onglet === 1;
									case 2:
										return onglet === 1;
									case 3:
										return onglet === 1;
									case 4:
										return onglet === 1;
									case 5:
										return onglet === 1;
									case 6:
										return onglet === 1;
									case 7:
										return onglet === 1;
									case 8:
										return onglet === 1;
									case 9:
										return onglet === 1;
									case 10:
										return onglet === 2;
									case 11:
										return onglet === 3;
									case 12:
										return onglet === 4;
									case 13:
										return onglet === 1;
									case 14:
										return onglet === 1;
									default:
										return onglet === 1;
								}
							default:
								var personneMoraleFields = natureJuridiqueFields.a;
								switch (personneMoraleFields.$) {
									case 0:
										return !onglet;
									case 1:
										return !onglet;
									case 2:
										return !onglet;
									case 3:
										return !onglet;
									case 4:
										return onglet === 1;
									case 5:
										return onglet === 1;
									case 6:
										return onglet === 1;
									case 7:
										return onglet === 1;
									case 8:
										return onglet === 1;
									case 9:
										return onglet === 4;
									default:
										return onglet === 1;
								}
						}
					case 1:
						return !onglet;
					case 2:
						return onglet === 5;
					case 3:
						return onglet === 6;
					default:
						return onglet === 7;
				}
		}
	});
var $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayOngletErrors = F2(
	function (onglet, errors) {
		return A2(
			$elm$core$List$any,
			$author$project$Components$Clients$Detail$UpdateForm$DisplayError$checkOneError(onglet),
			errors) ? $elm$core$Maybe$Just('error') : $elm$core$Maybe$Nothing;
	});
var $author$project$Perfimmo$Dom$Utils$DomId = $elm$core$Basics$identity;
var $author$project$Perfimmo$Dom$Utils$DomLabel = $elm$core$Basics$identity;
var $author$project$Perfimmo$Form$FormModel$Mandatory = 0;
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NoValue = {$: 0};
var $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayOngletErrorMessage = F2(
	function (onglet, errors) {
		var atLeastOneRepresenantmessage = 'Vous devez saisir au moins un représentant légal pour';
		var show = function (e) {
			switch (e.$) {
				case 1:
					return (onglet === 4) ? $elm$core$Maybe$Just(atLeastOneRepresenantmessage + ' une personne morale') : $elm$core$Maybe$Nothing;
				case 2:
					var capaciteJuridique = e.a;
					if (onglet === 4) {
						switch (capaciteJuridique) {
							case 0:
								return $elm$core$Maybe$Just(atLeastOneRepresenantmessage + ' un majeur capable');
							case 1:
								return $elm$core$Maybe$Just(atLeastOneRepresenantmessage + ' un majeur protégé sous tutelle');
							case 2:
								return $elm$core$Maybe$Just(atLeastOneRepresenantmessage + ' un majeur protégé sous curatelle');
							case 3:
								return $elm$core$Maybe$Just(atLeastOneRepresenantmessage + ' un majeur protégé sous sauvegarde de justice');
							case 4:
								return $elm$core$Maybe$Just(atLeastOneRepresenantmessage + ' un majeur protégé incapable');
							case 5:
								return $elm$core$Maybe$Just(atLeastOneRepresenantmessage + ' un mineur sous tutelle');
							case 6:
								return $elm$core$Maybe$Just(atLeastOneRepresenantmessage + ' un mineur émancipé');
							case 7:
								return $elm$core$Maybe$Just(atLeastOneRepresenantmessage + ' un mineur sous contrôle judiciaire');
							default:
								return $elm$core$Maybe$Just(atLeastOneRepresenantmessage + ' un mineur non emancipé');
						}
					} else {
						return $elm$core$Maybe$Nothing;
					}
				case 3:
					return (onglet === 7) ? $elm$core$Maybe$Just('Vous devez mettre ce client dans le portefeuille d\'au moins un conseiller') : $elm$core$Maybe$Nothing;
				default:
					return $elm$core$Maybe$Nothing;
			}
		};
		return A2($elm$core$List$filterMap, show, errors);
	});
var $author$project$Components$Clients$Detail$ViewForm$Utils$MissingFieldInError = 0;
var $author$project$Components$Clients$Detail$UpdateForm$DisplayError2$displayMissingFieldErrorBis = F2(
	function (errors, field) {
		var checkPath = function (error) {
			if (!error.$) {
				var path = error.a;
				return _Utils_eq(field, path);
			} else {
				return false;
			}
		};
		return A2(
			$elm$core$Maybe$map,
			function (_v0) {
				return 0;
			},
			A2($elm_community$list_extra$List$Extra$find, checkPath, errors));
	});
var $author$project$Perfimmo$Collection$Set$Set = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Perfimmo$Collection$Set$fromMaybe = F2(
	function (comparable, value) {
		if (!value.$) {
			var x = value.a;
			return A2(
				$author$project$Perfimmo$Collection$Set$Set,
				_List_fromArray(
					[x]),
				comparable);
		} else {
			return A2($author$project$Perfimmo$Collection$Set$Set, _List_Nil, comparable);
		}
	});
var $author$project$Components$Clients$Detail$UpdateForm$DisplayError2$errors_ = function (errors) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Components$Clients$Detail$UpdateForm$DisplayError2$displayMissingFieldErrorBis(errors),
		$author$project$Perfimmo$Collection$Set$fromMaybe($elm$core$Basics$eq));
};
var $author$project$Components$Clients$Detail$ViewForm$Utils$ItemAttributes = F3(
	function (formItem, input, inputLabel) {
		return {m: formItem, a1: input, x: inputLabel};
	});
var $author$project$Components$Clients$Detail$ViewForm$Utils$calculFormItemAttributes = F2(
	function (blockState, infs) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (i, acc) {
					if (!i.$) {
						return _Utils_update(
							acc,
							{
								m: _Utils_ap(
									acc.m,
									(!blockState) ? _List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mandatory')
										]) : _List_Nil)
							});
					} else {
						var decoration = i.a;
						switch (decoration.$) {
							case 0:
								return acc;
							case 1:
								var extSystem = decoration.a;
								return _Utils_update(
									acc,
									{
										m: _Utils_ap(
											acc.m,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('field-ext-sync-none')
												])),
										x: _Utils_ap(
											acc.x,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$title('n\'est pas synchronisé avec O2S')
												]))
									});
							default:
								var extSystem = decoration.a;
								var syncDirection = decoration.b;
								switch (syncDirection) {
									case 0:
										return _Utils_update(
											acc,
											{
												m: _Utils_ap(
													acc.m,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('field-ext-sync-both')
														])),
												x: _Utils_ap(
													acc.x,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$title('synchronisé avec O2S dans les deux sens')
														]))
											});
									case 1:
										return _Utils_update(
											acc,
											{
												m: _Utils_ap(
													acc.m,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('field-ext-sync-from_ext')
														])),
												a1: acc.a1,
												x: _Utils_ap(
													acc.x,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$title('ne récupère que la donnée depuis O2S, ne peut pas l\'envoyer')
														]))
											});
									default:
										return _Utils_update(
											acc,
											{
												m: _Utils_ap(
													acc.m,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('field-ext-sync-from_must')
														])),
												x: _Utils_ap(
													acc.x,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$title('n\'envoie la donnée que vers 02S')
														]))
											});
								}
						}
					}
				}),
			A3($author$project$Components$Clients$Detail$ViewForm$Utils$ItemAttributes, _List_Nil, _List_Nil, _List_Nil),
			infs);
	});
var $author$project$Perfimmo$Collection$Set$find = F2(
	function (predicate, _v0) {
		var list = _v0.a;
		return A2($elm_community$list_extra$List$Extra$find, predicate, list);
	});
var $author$project$Components$Clients$Detail$ViewForm$Utils$errorClass2 = function (errors) {
	return $elm_community$maybe_extra$Maybe$Extra$toList(
		A2(
			$elm$core$Maybe$map,
			function (_v0) {
				return $rtfeldman$elm_css$Html$Styled$Attributes$class('error');
			},
			A2(
				$author$project$Perfimmo$Collection$Set$find,
				$elm$core$Basics$eq(0),
				errors)));
};
var $author$project$Components$Clients$Detail$ViewForm$Utils$formItem = F2(
	function (attributes, items) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				attributes),
			items);
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$getInfos = function (_v0) {
	var infos = _v0.b;
	return infos;
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$view = function (_v0) {
	var model = _v0.a;
	return $rtfeldman$elm_css$Html$Styled$fromUnstyled(
		$inkuzmin$elm_multiselect$Multiselect$view(model));
};
var $author$project$Components$Clients$Detail$ViewForm$Utils$multiSelectForm = F5(
	function (errors, blockState, _v0, _v1, formField) {
		var domId = _v0;
		var domLabel = _v1;
		var itemAttributes = A2(
			$author$project$Components$Clients$Detail$ViewForm$Utils$calculFormItemAttributes,
			blockState,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$getInfos(formField));
		return A2(
			$author$project$Components$Clients$Detail$ViewForm$Utils$formItem,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('multiple')
					]),
				_Utils_ap(
					itemAttributes.m,
					$author$project$Components$Clients$Detail$ViewForm$Utils$errorClass2(errors))),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$label,
					_Utils_ap(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for(domId)
							]),
						itemAttributes.x),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(domLabel)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$id(domId),
							$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
						]),
					_List_fromArray(
						[
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$view(formField)
						]))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$ConseillersFormView$conseillerFormView = F2(
	function (errors, conseillersForm) {
		var errorMessages = A2(
			$elm$core$List$map,
			function (m) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('errorMessage')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(m)
						]));
			},
			A2($author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayOngletErrorMessage, 7, errors));
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section conseillers')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Portefeuilles conseillers')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$map,
							A2(
								$elm$core$Basics$composeL,
								A2($elm$core$Basics$composeL, $author$project$Components$Clients$Detail$Action$SetFormFields, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Conseillers),
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Value),
							A5(
								$author$project$Components$Clients$Detail$ViewForm$Utils$multiSelectForm,
								A2(
									$author$project$Components$Clients$Detail$UpdateForm$DisplayError2$errors_,
									errors,
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Conseillers($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NoValue)),
								0,
								'conseillers',
								'Choisir les portefeuilles de conseillers gérant ce client',
								conseillersForm)),
							A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, errorMessages)
						]))
				]));
	});
var $author$project$Components$Clients$Detail$Model$ongletIsActive = F2(
	function (onglet, model) {
		return _Utils_eq(onglet, model.fx);
	});
var $author$project$Components$Clients$Detail$View$ongletConseillers = function (model) {
	var isActive = A2($author$project$Components$Clients$Detail$Model$ongletIsActive, 7, model) ? ' active' : '';
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('conseillers'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('onglet' + isActive)
			]),
		_List_fromArray(
			[
				A2($author$project$Components$Clients$Detail$ViewForm$ConseillersFormView$conseillerFormView, model.mv, model.gb.ef)
			]));
};
var $author$project$Perfimmo$Form$FormModel$BooleanSwitch = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateDeces = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateDepartRetraite = function (a) {
	return {$: 5, a: a};
};
var $author$project$Perfimmo$Form$FormModel$InputDate = function (a) {
	return {$: 2, a: a};
};
var $author$project$Perfimmo$Form$FormModel$InputText = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Nationalite = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PP_RisqueLCBFTField = function (a) {
	return {$: 13, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Profession = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$TogglePersonnePolitiquementExposee = function (a) {
	return {$: 14, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$TogglePersonneVulnerable = function (a) {
	return {$: 15, a: a};
};
var $author$project$Components$Clients$Detail$ViewForm$Utils$decorator = F2(
	function (decoration, itemAttrs) {
		switch (decoration.$) {
			case 0:
				return itemAttrs;
			case 1:
				var extSystem = decoration.a;
				return _Utils_update(
					itemAttrs,
					{
						m: _Utils_ap(
							itemAttrs.m,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('field-ext-sync-none')
								])),
						x: _Utils_ap(
							itemAttrs.x,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$title('n\'est pas synchronisé avec O2S')
								]))
					});
			default:
				var extSystem = decoration.a;
				var syncDirection = decoration.b;
				switch (syncDirection) {
					case 0:
						return _Utils_update(
							itemAttrs,
							{
								m: _Utils_ap(
									itemAttrs.m,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('field-ext-sync-both')
										])),
								x: _Utils_ap(
									itemAttrs.x,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$title('synchronisé avec O2S dans les deux sens')
										]))
							});
					case 1:
						return _Utils_update(
							itemAttrs,
							{
								m: _Utils_ap(
									itemAttrs.m,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('field-ext-sync-from_ext')
										])),
								a1: itemAttrs.a1,
								x: _Utils_ap(
									itemAttrs.x,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$title('ne récupère que la donnée depuis O2S, ne peut pas l\'envoyer')
										]))
							});
					default:
						return _Utils_update(
							itemAttrs,
							{
								m: _Utils_ap(
									itemAttrs.m,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('field-ext-sync-from_must')
										])),
								x: _Utils_ap(
									itemAttrs.x,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$title('n\'envoie la donnée que vers 02S')
										]))
							});
				}
		}
	});
var $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError = F2(
	function (field, errors) {
		var checkPath = function (error) {
			if (!error.$) {
				var path = error.a;
				return _Utils_eq(field, path);
			} else {
				return false;
			}
		};
		return A2(
			$elm$core$Maybe$map,
			function (_v0) {
				return 'error';
			},
			A2($elm_community$list_extra$List$Extra$find, checkPath, errors));
	});
var $author$project$Perfimmo$Form$FormView$calculClasses = function (inputType) {
	switch (inputType.$) {
		case 10:
			var attrs = inputType.b;
			return $PerformanceIMMO$elm_utils$Perfimmo$Collection$List$flatten(
				A2(
					$elm$core$List$filterMap,
					function (attr) {
						if (!attr.$) {
							var classes = attr.a;
							return $elm$core$Maybe$Just(classes);
						} else {
							return $elm$core$Maybe$Nothing;
						}
					},
					attrs));
		case 9:
			return _List_fromArray(
				['percent']);
		default:
			return _List_Nil;
	}
};
var $author$project$Perfimmo$Dom$Utils$DisabledAttr = {$: 1};
var $author$project$Perfimmo$Form$FormView$calculDisabledInput = function (inputType) {
	if (inputType.$ === 10) {
		var attrs = inputType.b;
		return A2(
			$elm$core$List$any,
			$elm$core$Basics$eq($author$project$Perfimmo$Dom$Utils$DisabledAttr),
			attrs);
	} else {
		return false;
	}
};
var $author$project$Perfimmo$Form$FormView$ItemAttributes = F3(
	function (formItem, input, inputLabel) {
		return {m: formItem, a1: input, x: inputLabel};
	});
var $author$project$Perfimmo$Form$FormView$calculFormItemAttributes = F3(
	function (blockState, infs, decorator) {
		var accumulate = F2(
			function (i, acc) {
				if (!i.$) {
					return _Utils_update(
						acc,
						{
							m: _Utils_ap(
								acc.m,
								(!blockState) ? _List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('mandatory')
									]) : _List_Nil)
						});
				} else {
					var decoration = i.a;
					return A2(decorator, decoration, acc);
				}
			});
		return A3(
			$elm$core$List$foldl,
			accumulate,
			A3($author$project$Perfimmo$Form$FormView$ItemAttributes, _List_Nil, _List_Nil, _List_Nil),
			infs);
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$getInfos = function (_v0) {
	var infos = _v0.c;
	return infos;
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$getInfos = function (_v0) {
	var infos = _v0.c;
	return infos;
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toString = function (_v0) {
	var s = _v0.b;
	return s;
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$toString = function (_v0) {
	var s = _v0.b;
	return s;
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$toString = function (_v0) {
	var s = _v0.b;
	return s;
};
var $author$project$Perfimmo$Form$FormView$calculInputParams = F3(
	function (blockState, inputType, decorator) {
		calculInputParams:
		while (true) {
			switch (inputType.$) {
				case 0:
					var formField = inputType.a;
					return {
						S: '',
						V: A3(
							$author$project$Perfimmo$Form$FormView$calculFormItemAttributes,
							blockState,
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getInfos(formField),
							decorator),
						kv: 'checkbox',
						Y: $rtfeldman$elm_css$Html$Styled$Attributes$checked(
							A2(
								$elm$core$Maybe$withDefault,
								false,
								$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(formField)))
					};
				case 1:
					var formField = inputType.a;
					var itemAttrs = A3(
						$author$project$Perfimmo$Form$FormView$calculFormItemAttributes,
						blockState,
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getInfos(formField),
						decorator);
					var withBool = _Utils_update(
						itemAttrs,
						{
							m: _Utils_ap(
								itemAttrs.m,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('boolean-switch')
									]))
						});
					return {
						S: '',
						V: withBool,
						kv: 'checkbox',
						Y: $rtfeldman$elm_css$Html$Styled$Attributes$checked(
							A2(
								$elm$core$Maybe$withDefault,
								false,
								$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(formField)))
					};
				case 2:
					var formField = inputType.a;
					return {
						S: '',
						V: A3(
							$author$project$Perfimmo$Form$FormView$calculFormItemAttributes,
							blockState,
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getInfos(formField),
							decorator),
						kv: 'date',
						Y: $rtfeldman$elm_css$Html$Styled$Attributes$value(
							A2(
								$elm$core$Maybe$withDefault,
								'',
								A2(
									$elm$core$Maybe$map,
									$justinmimbs$date$Date$toIsoString,
									$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(formField))))
					};
				case 3:
					var formField = inputType.a;
					return {
						S: '',
						V: A3(
							$author$project$Perfimmo$Form$FormView$calculFormItemAttributes,
							blockState,
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getInfos(formField),
							decorator),
						kv: 'text',
						Y: $rtfeldman$elm_css$Html$Styled$Attributes$value(
							A2(
								$elm$core$Maybe$withDefault,
								'',
								$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(formField)))
					};
				case 4:
					var formField = inputType.a;
					return {
						S: '',
						V: A3(
							$author$project$Perfimmo$Form$FormView$calculFormItemAttributes,
							blockState,
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getInfos(formField),
							decorator),
						kv: 'text',
						Y: $rtfeldman$elm_css$Html$Styled$Attributes$value(
							A2(
								$elm$core$Maybe$withDefault,
								'',
								A2(
									$elm$core$Maybe$map,
									$torreyatcitty$the_best_decimal$Decimal$toString,
									$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(formField))))
					};
				case 5:
					var formField = inputType.a;
					return {
						S: '',
						V: A3(
							$author$project$Perfimmo$Form$FormView$calculFormItemAttributes,
							blockState,
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getInfos(formField),
							decorator),
						kv: 'email',
						Y: $rtfeldman$elm_css$Html$Styled$Attributes$value(
							A2(
								$elm$core$Maybe$withDefault,
								'',
								$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(formField)))
					};
				case 6:
					var formField = inputType.a;
					return {
						S: '',
						V: A3(
							$author$project$Perfimmo$Form$FormView$calculFormItemAttributes,
							blockState,
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getInfos(formField),
							decorator),
						kv: 'tel',
						Y: $rtfeldman$elm_css$Html$Styled$Attributes$value(
							A2(
								$elm$core$Maybe$withDefault,
								'',
								$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(formField)))
					};
				case 7:
					var formField = inputType.a;
					return {
						S: '',
						V: A3(
							$author$project$Perfimmo$Form$FormView$calculFormItemAttributes,
							blockState,
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$getInfos(formField),
							decorator),
						kv: 'text',
						Y: $rtfeldman$elm_css$Html$Styled$Attributes$value(
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormFloat$toString(formField))
					};
				case 8:
					var formField = inputType.a;
					return {
						S: '',
						V: A3(
							$author$project$Perfimmo$Form$FormView$calculFormItemAttributes,
							blockState,
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$getInfos(formField),
							decorator),
						kv: 'text',
						Y: $rtfeldman$elm_css$Html$Styled$Attributes$value(
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormInt$toString(formField))
					};
				case 9:
					var formField = inputType.a;
					return {
						S: '',
						V: A3(
							$author$project$Perfimmo$Form$FormView$calculFormItemAttributes,
							blockState,
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$getInfos(formField),
							decorator),
						kv: 'text',
						Y: $rtfeldman$elm_css$Html$Styled$Attributes$value(
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormPourcent$toString(formField))
					};
				default:
					var type_ = inputType.a;
					var $temp$blockState = blockState,
						$temp$inputType = type_,
						$temp$decorator = decorator;
					blockState = $temp$blockState;
					inputType = $temp$inputType;
					decorator = $temp$decorator;
					continue calculInputParams;
			}
		}
	});
var $author$project$Perfimmo$Dom$Utils$formItem = F2(
	function (attributes, items) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				attributes),
			items);
	});
var $author$project$Perfimmo$Form$FormView$inputForm = F8(
	function (errors, blockState, displayMissingFieldError, decorator, inputType, _v0, _v1, fieldBuilder) {
		var domId = _v0;
		var domLabel = _v1;
		var linkToDomId = _List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class(domId)
			]);
		var itemParams = A3($author$project$Perfimmo$Form$FormView$calculInputParams, blockState, inputType, decorator);
		var label_ = A2(
			$rtfeldman$elm_css$Html$Styled$label,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$for(domId)
					]),
				itemParams.V.x),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(domLabel)
				]));
		var errorClass = $elm_community$maybe_extra$Maybe$Extra$toList(
			A2(
				$elm$core$Maybe$map,
				function (_v3) {
					return $rtfeldman$elm_css$Html$Styled$Attributes$class('error');
				},
				A2(
					displayMissingFieldError,
					fieldBuilder(''),
					errors)));
		var disabledAttr = $author$project$Perfimmo$Form$FormView$calculDisabledInput(inputType);
		var classes = A2(
			$elm$core$String$join,
			' ',
			$author$project$Perfimmo$Form$FormView$calculClasses(inputType));
		var input_ = A2(
			$rtfeldman$elm_css$Html$Styled$input,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id(domId),
						$rtfeldman$elm_css$Html$Styled$Attributes$type_(itemParams.kv),
						itemParams.Y,
						$rtfeldman$elm_css$Html$Styled$Events$onInput(fieldBuilder),
						$rtfeldman$elm_css$Html$Styled$Attributes$class(classes),
						$rtfeldman$elm_css$Html$Styled$Attributes$disabled(disabledAttr)
					]),
				itemParams.V.a1),
			_List_Nil);
		var disposition = function (inp) {
			disposition:
			while (true) {
				switch (inp.$) {
					case 0:
						return _List_fromArray(
							[input_, label_]);
					case 1:
						return _List_fromArray(
							[input_, label_]);
					case 10:
						var subInput = inp.a;
						var $temp$inp = subInput;
						inp = $temp$inp;
						continue disposition;
					default:
						return _List_fromArray(
							[label_, input_]);
				}
			}
		};
		return A2(
			$author$project$Perfimmo$Dom$Utils$formItem,
			_Utils_ap(
				itemParams.V.m,
				_Utils_ap(errorClass, linkToDomId)),
			disposition(inputType));
	});
var $author$project$Components$Clients$Detail$ViewForm$CommonFormView$multipleNationalitesForm = F3(
	function (errors, formField, action) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$map,
			A2($elm$core$Basics$composeL, action, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Value),
			A5(
				$author$project$Components$Clients$Detail$ViewForm$Utils$multiSelectForm,
				A2(
					$author$project$Components$Clients$Detail$UpdateForm$DisplayError2$errors_,
					errors,
					action($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NoValue)),
				0,
				'autreNationalite',
				'Autres nationalités',
				formField));
	});
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getInfos = function (_v0) {
	var infos = _v0.c;
	return infos;
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getSelectValues = function (_v0) {
	var val = _v0.a;
	var values = _v0.b;
	var infos = _v0.c;
	var toString = _v0.e;
	return _Utils_Tuple2(
		A2($elm$core$Maybe$map, toString, val),
		values);
};
var $author$project$Perfimmo$Form$FormView$selectForm = F9(
	function (errors, blockState, displayMissingFieldError, decorator, selectIsClearable, _v0, _v1, formSelectField, fieldBuilder) {
		var domId = _v0;
		var domLabel = _v1;
		var linkToDomId = _List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class(domId)
			]);
		var itemAttributes = A3(
			$author$project$Perfimmo$Form$FormView$calculFormItemAttributes,
			blockState,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getInfos(formSelectField),
			decorator);
		var errorClass = $elm_community$maybe_extra$Maybe$Extra$toList(
			A2(
				$elm$core$Maybe$map,
				function (_v3) {
					return $rtfeldman$elm_css$Html$Styled$Attributes$class('error');
				},
				A2(
					displayMissingFieldError,
					fieldBuilder(''),
					errors)));
		var _v2 = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getSelectValues(formSelectField);
		var val = _v2.a;
		var tuples = _v2.b;
		var clearable = _Utils_eq(val, $elm$core$Maybe$Nothing) ? ' hidden' : (selectIsClearable(
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getInfos(formSelectField)) ? ' hidden' : '');
		return A2(
			$author$project$Perfimmo$Dom$Utils$formItem,
			_Utils_ap(
				itemAttributes.m,
				_Utils_ap(errorClass, linkToDomId)),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$label,
					_Utils_ap(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for(domId)
							]),
						itemAttributes.x),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(domLabel)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('clearableSelect')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$select,
							_Utils_ap(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id(domId),
										$rtfeldman$elm_css$Html$Styled$Events$onInput(fieldBuilder)
									]),
								itemAttributes.a1),
							A2($PerformanceIMMO$elm_utils$Perfimmo$Html$Dom$buildOptions, val, tuples)),
							A2(
							$rtfeldman$elm_css$Html$Styled$button,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('clearSelect' + clearable),
									$rtfeldman$elm_css$Html$Styled$Events$onClick(
									fieldBuilder(''))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('x')
								]))
						]))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$Utils$selectIsClearable = function (infos) {
	return A2(
		$elm$core$List$any,
		function (i) {
			if (((i.$ === 1) && (i.a.$ === 2)) && (i.a.b === 1)) {
				var _v1 = i.a;
				var _v2 = _v1.b;
				return true;
			} else {
				return false;
			}
		},
		infos);
};
var $author$project$Components$Clients$Detail$ViewForm$CommonFormView$nationaliteForm = F3(
	function (errors, value, field) {
		return A9($author$project$Perfimmo$Form$FormView$selectForm, errors, 0, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator, $author$project$Components$Clients$Detail$ViewForm$Utils$selectIsClearable, 'nationalite', 'Nationalité', value, field);
	});
var $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueField = A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonnePhysiqueF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique);
var $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueForm = F2(
	function (errors, params) {
		var selectE = A5($author$project$Perfimmo$Form$FormView$selectForm, errors, 0, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator, $author$project$Components$Clients$Detail$ViewForm$Utils$selectIsClearable);
		var inputE = A4($author$project$Perfimmo$Form$FormView$inputForm, errors, 0, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		return _List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('selectable-tags')
					]),
				_List_fromArray(
					[
						A4(
						inputE,
						$author$project$Perfimmo$Form$FormModel$BooleanSwitch(params.iP),
						'personneVulnerable',
						'Personne vulnérable',
						A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$TogglePersonneVulnerable, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueField)),
						A4(
						inputE,
						$author$project$Perfimmo$Form$FormModel$BooleanSwitch(params.iO),
						'personneExposee',
						'Personne politiquement exposée',
						A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$TogglePersonnePolitiquementExposee, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueField))
					])),
				A4(
				selectE,
				'capaciteJuridique',
				'Capacité juridique',
				params.f0,
				A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PP_CapaciteJuridique, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueField)),
				A9(
				$author$project$Perfimmo$Form$FormView$selectForm,
				errors,
				0,
				$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
				$author$project$Components$Clients$Detail$ViewForm$Utils$decorator,
				$author$project$Components$Clients$Detail$ViewForm$Utils$selectIsClearable,
				'paysDeResidenceFiscale',
				'Pays de résidence fiscale',
				params.iE,
				A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PaysDeResidenceFiscale, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueField)),
				A4(
				inputE,
				$author$project$Perfimmo$Form$FormModel$InputText(params.dw),
				'profession',
				'Profession',
				A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Profession, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueField)),
				A4(
				inputE,
				$author$project$Perfimmo$Form$FormModel$InputDate(params.gs),
				'dateDepartRetraite',
				'Date de départ en retraite',
				A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateDepartRetraite, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueField)),
				A3(
				$author$project$Components$Clients$Detail$ViewForm$CommonFormView$nationaliteForm,
				errors,
				params.bD,
				A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Nationalite, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueField)),
				A4(
				inputE,
				$author$project$Perfimmo$Form$FormModel$InputDate(params.gF),
				'deces',
				'Date de décès',
				A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateDeces, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueField)),
				A3(
				$author$project$Components$Clients$Detail$ViewForm$CommonFormView$multipleNationalitesForm,
				errors,
				params.bf,
				A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$AutreNationalites, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueField)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('risks')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$h3,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Risques')
							])),
						A4(
						selectE,
						'evaluationRisqueLCBFT',
						'Évaluation des risques LCBFT',
						params.c5,
						A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PP_RisqueLCBFTField, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueField))
					]))
			]);
	});
var $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$infos = F2(
	function (errors, params) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section infos')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Informations')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					A2($author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueForm, errors, params))
				]));
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PaysNaissance = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$VilleNaissance = function (a) {
	return {$: 1, a: a};
};
var $author$project$Perfimmo$Form$FormModel$Optional = 1;
var $author$project$Perfimmo$Form$FormModel$blockState = function (block) {
	if (!block.$) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Components$Clients$Detail$ViewForm$CommonFormView$pieceIdentiteForm = F4(
	function (errors, formState, form, field) {
		var selectE = A5($author$project$Perfimmo$Form$FormView$selectForm, errors, formState, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator, $author$project$Components$Clients$Detail$ViewForm$Utils$selectIsClearable);
		var inputE = A4($author$project$Perfimmo$Form$FormView$inputForm, errors, formState, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Pièce d\'identité')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A4(
							inputE,
							$author$project$Perfimmo$Form$FormModel$InputText(form.is),
							'numero',
							'Numéro de carte',
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Numero, field)),
							A4(
							selectE,
							'typeDePiece',
							'Type de pièce',
							form.ks,
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$TypeDePiece, field)),
							A4(
							inputE,
							$author$project$Perfimmo$Form$FormModel$InputDate(form.gr),
							'dateDelivrance',
							'Date de délivrance',
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateDelivrance, field)),
							A4(
							inputE,
							$author$project$Perfimmo$Form$FormModel$InputDate(form.gu),
							'dateExpiration',
							'Date d\'expiration',
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateExpiration, field))
						]))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$naissanceEtPieceIdentite = F2(
	function (errors, params) {
		var inputE = A4($author$project$Perfimmo$Form$FormView$inputForm, errors, 0, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('section')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h2,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Naissance')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
								]),
							_List_fromArray(
								[
									A4(
									inputE,
									$author$project$Perfimmo$Form$FormModel$InputDate(params.aI.c$),
									'dateNaissance',
									'Date de naissance',
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateNaissance,
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NaissanceF, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueField))),
									A4(
									inputE,
									$author$project$Perfimmo$Form$FormModel$InputText(params.aI.d_),
									'ville',
									'Ville de naissance',
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$VilleNaissance,
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NaissanceF, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueField))),
									A9(
									$author$project$Perfimmo$Form$FormView$selectForm,
									errors,
									0,
									$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
									$author$project$Components$Clients$Detail$ViewForm$Utils$decorator,
									$author$project$Components$Clients$Detail$ViewForm$Utils$selectIsClearable,
									'paysDeNaissance',
									'Pays de naissance',
									params.aI.dq,
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PaysNaissance,
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NaissanceF, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueField)))
								]))
						])),
					A4(
					$author$project$Components$Clients$Detail$ViewForm$CommonFormView$pieceIdentiteForm,
					errors,
					$author$project$Perfimmo$Form$FormModel$blockState(params.du),
					$author$project$Perfimmo$Form$FormModel$getBlock(params.du),
					A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PP_PieceIdentite, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personnePhysiqueField))
				]));
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CapitalSocial = function (a) {
	return {$: 4, a: a};
};
var $author$project$Perfimmo$Form$FormModel$DecoratedInput = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Perfimmo$Form$FormModel$InputFormFloat = function (a) {
	return {$: 7, a: a};
};
var $author$project$Perfimmo$Form$FormModel$InputFormPourcent = function (a) {
	return {$: 9, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PM_RisqueLCBFTField = function (a) {
	return {$: 10, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PM_Typologie = function (a) {
	return {$: 5, a: a};
};
var $author$project$Perfimmo$Dom$Utils$ClassesAttr = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$ViewForm$Utils$euroClass = $author$project$Perfimmo$Dom$Utils$ClassesAttr(
	_List_fromArray(
		['euro']));
var $author$project$Components$Clients$Detail$ViewForm$Utils$percentClass = $author$project$Perfimmo$Dom$Utils$ClassesAttr(
	_List_fromArray(
		['percent']));
var $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personMoraleField = A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonneMoraleF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique);
var $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$pmForm = F2(
	function (errors, params) {
		var selectE = A5($author$project$Perfimmo$Form$FormView$selectForm, errors, 0, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator, $author$project$Components$Clients$Detail$ViewForm$Utils$selectIsClearable);
		var inputFiscalite = A4(
			$author$project$Perfimmo$Form$FormView$inputForm,
			errors,
			$author$project$Perfimmo$Form$FormModel$blockState(params.db),
			$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
			$author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		var inputE = A4($author$project$Perfimmo$Form$FormView$inputForm, errors, 0, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		var inputCompteResultat = A4(
			$author$project$Perfimmo$Form$FormView$inputForm,
			errors,
			$author$project$Perfimmo$Form$FormModel$blockState(params.ge),
			$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
			$author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		var inputBilan = A4(
			$author$project$Perfimmo$Form$FormView$inputForm,
			errors,
			$author$project$Perfimmo$Form$FormModel$blockState(params.fS),
			$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
			$author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		var fiscalite = $author$project$Perfimmo$Form$FormModel$getBlock(params.db);
		var compteResultatForm = $author$project$Perfimmo$Form$FormModel$getBlock(params.ge);
		var bilanForm = $author$project$Perfimmo$Form$FormModel$getBlock(params.fS);
		return _List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('section infos-pm')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
							]),
						_List_fromArray(
							[
								A4(
								selectE,
								'typologie',
								'Type de personne morale',
								params.dY,
								A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PM_Typologie, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personMoraleField)),
								A4(
								inputE,
								A2(
									$author$project$Perfimmo$Form$FormModel$DecoratedInput,
									$author$project$Perfimmo$Form$FormModel$InputFormFloat(params.f2),
									_List_fromArray(
										[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
								'capitalSocial',
								'Capital social',
								A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CapitalSocial, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personMoraleField)),
								A4(
								selectE,
								'evaluationRisqueLCBFT',
								'Évaluation des risques LCBFT',
								params.c5,
								A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PM_RisqueLCBFTField, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personMoraleField))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('section bilan')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$h2,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Bilan')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('actif')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$h3,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Actif annuel')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
									]),
								_List_fromArray(
									[
										A4(
										inputBilan,
										A2(
											$author$project$Perfimmo$Form$FormModel$DecoratedInput,
											$author$project$Perfimmo$Form$FormModel$InputFormFloat(bilanForm.cM.da),
											_List_fromArray(
												[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
										'financier',
										'Financier',
										A2(
											$elm$core$Basics$composeR,
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Financier,
											A2(
												$elm$core$Basics$composeR,
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$B_ActifAnnuel,
												A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$BilanF, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personMoraleField)))),
										A4(
										inputBilan,
										A2(
											$author$project$Perfimmo$Form$FormModel$DecoratedInput,
											$author$project$Perfimmo$Form$FormModel$InputFormFloat(bilanForm.cM.im),
											_List_fromArray(
												[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
										'nonFinancier',
										'Non financier',
										A2(
											$elm$core$Basics$composeR,
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NonFinancier,
											A2(
												$elm$core$Basics$composeR,
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$B_ActifAnnuel,
												A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$BilanF, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personMoraleField)))),
										A4(
										inputBilan,
										A2(
											$author$project$Perfimmo$Form$FormModel$DecoratedInput,
											$author$project$Perfimmo$Form$FormModel$InputFormFloat(bilanForm.cM.cR),
											_List_fromArray(
												[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
										'brut',
										'Brut',
										A2(
											$elm$core$Basics$composeR,
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Brut,
											A2(
												$elm$core$Basics$composeR,
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$B_ActifAnnuel,
												A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$BilanF, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personMoraleField))))
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('passif')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$h3,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Passif')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
									]),
								_List_fromArray(
									[
										A4(
										inputBilan,
										A2(
											$author$project$Perfimmo$Form$FormModel$DecoratedInput,
											$author$project$Perfimmo$Form$FormModel$InputFormFloat(bilanForm.iC),
											_List_fromArray(
												[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
										'passifAnnuel',
										'Passif annuel',
										A2(
											$elm$core$Basics$composeR,
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PassifAnnuel,
											A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$BilanF, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personMoraleField))),
										A4(
										inputBilan,
										A2(
											$author$project$Perfimmo$Form$FormModel$DecoratedInput,
											$author$project$Perfimmo$Form$FormModel$InputFormFloat(bilanForm.f3),
											_List_fromArray(
												[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
										'capitauxPropres',
										'Capitaux propres',
										A2(
											$elm$core$Basics$composeR,
											$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CapitauxPropres,
											A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$BilanF, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personMoraleField)))
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('section')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$h2,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Compte de résultat')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
							]),
						_List_fromArray(
							[
								A4(
								inputCompteResultat,
								A2(
									$author$project$Perfimmo$Form$FormModel$DecoratedInput,
									$author$project$Perfimmo$Form$FormModel$InputFormFloat(compteResultatForm.jd),
									_List_fromArray(
										[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
								'produitsAnnuels',
								'Produits annuels',
								A2(
									$elm$core$Basics$composeR,
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProduitsAnnuels,
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CompteResultatF, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personMoraleField))),
								A4(
								inputCompteResultat,
								A2(
									$author$project$Perfimmo$Form$FormModel$DecoratedInput,
									$author$project$Perfimmo$Form$FormModel$InputFormFloat(compteResultatForm.f6),
									_List_fromArray(
										[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
								'charges_annuelles',
								'Charges annuelles',
								A2(
									$elm$core$Basics$composeR,
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PM_ChargesAnnuelles,
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CompteResultatF, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personMoraleField))),
								A4(
								inputCompteResultat,
								A2(
									$author$project$Perfimmo$Form$FormModel$DecoratedInput,
									$author$project$Perfimmo$Form$FormModel$InputFormFloat(compteResultatForm.jw),
									_List_fromArray(
										[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
								'resultat_annuel',
								'Résultat annuel',
								A2(
									$elm$core$Basics$composeR,
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ResultatAnnuel,
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CompteResultatF, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personMoraleField)))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('section')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$h2,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Fiscalité')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
							]),
						_List_fromArray(
							[
								A4(
								inputFiscalite,
								A2(
									$author$project$Perfimmo$Form$FormModel$DecoratedInput,
									$author$project$Perfimmo$Form$FormModel$InputFormPourcent(fiscalite.kc),
									_List_fromArray(
										[$author$project$Components$Clients$Detail$ViewForm$Utils$percentClass])),
								'tauxImposition',
								'Taux d\'imposition',
								A2(
									$elm$core$Basics$composeR,
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$TauxImposition,
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$FiscaliteF, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personMoraleField))),
								A4(
								inputFiscalite,
								A2(
									$author$project$Perfimmo$Form$FormModel$DecoratedInput,
									$author$project$Perfimmo$Form$FormModel$InputFormFloat(fiscalite.h9),
									_List_fromArray(
										[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
								'montantsImpots',
								'Montants des impôts',
								A2(
									$elm$core$Basics$composeR,
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$MontantsImpots,
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$FiscaliteF, $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$personMoraleField)))
							]))
					]))
			]);
	});
var $author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$infosComplementaireForm = F2(
	function (errors, client) {
		var specific = function () {
			var _v0 = client.ic;
			if (!_v0.$) {
				var params = _v0.a;
				return _List_fromArray(
					[
						A2($author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$infos, errors, params),
						A2($author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$naissanceEtPieceIdentite, errors, params)
					]);
			} else {
				var params = _v0.a;
				return A2($author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$pmForm, errors, params);
			}
		}();
		return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, specific);
	});
var $author$project$Components$Clients$Detail$View$ongletInfoComplementaires = function (model) {
	var isActive = A2($author$project$Components$Clients$Detail$Model$ongletIsActive, 1, model) ? ' active' : '';
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('infosComplementaires'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('onglet' + isActive)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$author$project$Components$Clients$Detail$Action$SetFormFields,
				A2($author$project$Components$Clients$Detail$ViewForm$InfosComplementaireFormView$infosComplementaireForm, model.mv, model.gb))
			]));
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactEmail = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactTelephone = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Perfimmo$Form$FormModel$InputEmail = function (a) {
	return {$: 5, a: a};
};
var $author$project$Perfimmo$Form$FormModel$InputTel = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Adresse_2 = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$ViewForm$CommonFormView$adresseForm = F3(
	function (errors, adresse, field) {
		var inputE = A4($author$project$Perfimmo$Form$FormView$inputForm, errors, 0, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
				]),
			_List_fromArray(
				[
					A4(
					inputE,
					$author$project$Perfimmo$Form$FormModel$InputText(adresse.fB),
					'addr1',
					'Adresse 1',
					A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Adresse_1, field)),
					A4(
					inputE,
					$author$project$Perfimmo$Form$FormModel$InputText(adresse.fC),
					'addr2',
					'Adresse 2',
					A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Adresse_2, field)),
					A4(
					inputE,
					$author$project$Perfimmo$Form$FormModel$InputText(adresse.gc),
					'codePostal',
					'Code postal',
					A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CodePostal, field)),
					A4(
					inputE,
					$author$project$Perfimmo$Form$FormModel$InputText(adresse.d_),
					'ville',
					'Ville',
					A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Ville, field)),
					A9(
					$author$project$Perfimmo$Form$FormView$selectForm,
					errors,
					0,
					$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
					$author$project$Components$Clients$Detail$ViewForm$Utils$decorator,
					$author$project$Components$Clients$Detail$ViewForm$Utils$selectIsClearable,
					'pays',
					'Pays',
					adresse.dq,
					A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Pays, field))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$ContactFormView$contactForm = F2(
	function (errors, contact) {
		var inputE = A4($author$project$Perfimmo$Form$FormView$inputForm, errors, 0, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('section contact')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h2,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Contacts')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
								]),
							_List_fromArray(
								[
									A4(
									inputE,
									$author$project$Perfimmo$Form$FormModel$InputEmail(contact.gU),
									'email',
									'E-mail',
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactEmail, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactF)),
									A4(
									inputE,
									$author$project$Perfimmo$Form$FormModel$InputTel(contact.kf.fj),
									'telephone1',
									'Téléphone 1',
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactTelephone(1),
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactF)),
									A4(
									inputE,
									$author$project$Perfimmo$Form$FormModel$InputTel(contact.kf.fk),
									'telephone2',
									'Téléphone 2',
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactTelephone(2),
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactF)),
									A4(
									inputE,
									$author$project$Perfimmo$Form$FormModel$InputTel(contact.kf.fl),
									'telephone3',
									'Téléphone 3',
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactTelephone(3),
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactF))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('section adresse')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h2,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Adresse fiscale')
								])),
							A3(
							$author$project$Components$Clients$Detail$ViewForm$CommonFormView$adresseForm,
							errors,
							contact.fE,
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactAdresse, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ContactF))
						]))
				]));
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ToggleNatureJuridique = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Html$Styled$datalist = $rtfeldman$elm_css$Html$Styled$node('datalist');
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$getInfos = function (_v0) {
	var select = _v0;
	return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getInfos(select);
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$getSelectValues = function (_v0) {
	var select = _v0;
	var _v1 = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getSelectValues(select);
	var x = _v1.a;
	var values = _v1.b;
	var untupledValues = A2($elm$core$List$map, $elm$core$Tuple$first, values);
	var state = function () {
		var _v2 = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$getValue(select);
		if (!_v2.$) {
			if (!_v2.a.$) {
				return A2($elm$core$Maybe$map, $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$ChosenItem, x);
			} else {
				var i = _v2.a.a;
				return $elm$core$Maybe$Just(
					$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$InputSearch(i));
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	}();
	return _Utils_Tuple2(state, untupledValues);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$list = $rtfeldman$elm_css$VirtualDom$Styled$attribute('list');
var $author$project$Components$Clients$Detail$ViewForm$Utils$dataListForm = F6(
	function (errors, blockState, _v0, _v1, formSelectField, fieldBuilder) {
		var domId = _v0;
		var domLabel = _v1;
		var itemAttributes = A2(
			$author$project$Components$Clients$Detail$ViewForm$Utils$calculFormItemAttributes,
			blockState,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$getInfos(formSelectField));
		var _v2 = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$getSelectValues(formSelectField);
		var val = _v2.a;
		var availablesValues = _v2.b;
		var _v3 = function () {
			if (!val.$) {
				if (!val.a.$) {
					var x = val.a.a;
					return $author$project$Components$Clients$Detail$ViewForm$Utils$selectIsClearable(
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$getInfos(formSelectField)) ? _Utils_Tuple2(
						' hidden',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(x)
							])) : _Utils_Tuple2(
						'',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(x)
							]));
				} else {
					var s = val.a.a;
					return $author$project$Components$Clients$Detail$ViewForm$Utils$selectIsClearable(
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$getInfos(formSelectField)) ? _Utils_Tuple2(
						' hidden',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(s)
							])) : _Utils_Tuple2(
						'',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(s)
							]));
				}
			} else {
				return _Utils_Tuple2(
					' hidden',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$value('')
						]));
			}
		}();
		var clearable = _v3.a;
		var valueOnInput = _v3.b;
		return A2(
			$author$project$Components$Clients$Detail$ViewForm$Utils$formItem,
			_Utils_ap(
				itemAttributes.m,
				$author$project$Components$Clients$Detail$ViewForm$Utils$errorClass2(errors)),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$label,
					_Utils_ap(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for(domId)
							]),
						itemAttributes.x),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(domLabel)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('clearableSelect')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$input,
							_Utils_ap(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id(domId),
										$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
										$rtfeldman$elm_css$Html$Styled$Attributes$list(domId + '_list'),
										$rtfeldman$elm_css$Html$Styled$Events$onInput(fieldBuilder)
									]),
								_Utils_ap(valueOnInput, itemAttributes.a1)),
							_List_Nil),
							A2(
							$rtfeldman$elm_css$Html$Styled$datalist,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$id(domId + '_list')
								]),
							A2(
								$elm$core$List$map,
								function (v) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$option,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$value(v)
											]),
										_List_Nil);
								},
								availablesValues)),
							A2(
							$rtfeldman$elm_css$Html$Styled$button,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('clearSelect' + clearable),
									$rtfeldman$elm_css$Html$Styled$Events$onClick(
									fieldBuilder(''))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('x')
								]))
						]))
				]));
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NomNaissance = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$ViewForm$CommonFormView$identiteForm = F4(
	function (errors, formState, form, field) {
		var selectE = A5($author$project$Perfimmo$Form$FormView$selectForm, errors, formState, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator, $author$project$Components$Clients$Detail$ViewForm$Utils$selectIsClearable);
		var inputE = A4($author$project$Perfimmo$Form$FormView$inputForm, errors, formState, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Identité')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A4(
							selectE,
							'civilite',
							'Civilité',
							form.f8,
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CiviliteF, field)),
							A4(
							inputE,
							$author$project$Perfimmo$Form$FormModel$InputText(form.iV),
							'prenom',
							'Prénom',
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Prenom, field)),
							A4(
							inputE,
							$author$project$Perfimmo$Form$FormModel$InputText(form.dl),
							'nom',
							'Nom',
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Nom, field)),
							A4(
							inputE,
							$author$project$Perfimmo$Form$FormModel$InputText(form.ii),
							'nomNaissance',
							'Nom de naissance',
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NomNaissance, field))
						]))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$PresentationInfoFormView$natureInfoForm = F2(
	function (errors, client) {
		var inputE = A4($author$project$Perfimmo$Form$FormView$inputForm, errors, 0, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		var identiteField = A2(
			$elm$core$Basics$composeR,
			$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$IdentitePersonneF,
			A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonnePhysiqueF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique));
		var dataListE = function (fieldDescriptor) {
			return A2(
				$author$project$Components$Clients$Detail$ViewForm$Utils$dataListForm,
				A2(
					$author$project$Components$Clients$Detail$UpdateForm$DisplayError2$errors_,
					errors,
					fieldDescriptor('')),
				0);
		};
		var showIt = function () {
			var _v0 = client.ic;
			if (!_v0.$) {
				var params = _v0.a;
				return A4($author$project$Components$Clients$Detail$ViewForm$CommonFormView$identiteForm, errors, 0, params.aD, identiteField);
			} else {
				var params = _v0.a;
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A5(
							dataListE,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$FormeJuridique,
								A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonneMoraleF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique)),
							'formeJuridique',
							'Forme juridique',
							params.hd,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$FormeJuridique,
								A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonneMoraleF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique))),
							A4(
							inputE,
							$author$project$Perfimmo$Form$FormModel$InputText(params.dl),
							'name',
							'Nom',
							A2(
								$elm$core$Basics$composeR,
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PM_Nom,
								A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonneMoraleF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique))),
							A4(
							inputE,
							$author$project$Perfimmo$Form$FormModel$InputText(params.jR),
							'siren',
							'SIRET (14 caractères)',
							A2(
								$elm$core$Basics$composeR,
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Siren,
								A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonneMoraleF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique))),
							A4(
							inputE,
							$author$project$Perfimmo$Form$FormModel$InputText(params.kG),
							'tribunalCommerce',
							'Ville du tribunal de commerce',
							A2(
								$elm$core$Basics$composeR,
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$VilleTribunalCommerce,
								A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonneMoraleF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique)))
						]));
			}
		}();
		return showIt;
	});
var $author$project$Components$Clients$Detail$ViewForm$Utils$switchForm = F5(
	function (_v0, _v1, _v2, checkedSide, action) {
		var domId = _v0;
		var labelLeft = _v1;
		var labelRight = _v2;
		var idRight = domId + '_right';
		var idLeft = domId + '_left';
		var caseChecked = function (x) {
			return $rtfeldman$elm_css$Html$Styled$Attributes$checked(
				_Utils_eq(x, checkedSide));
		};
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('switchButton')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$input,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$id(idLeft),
							$rtfeldman$elm_css$Html$Styled$Attributes$type_('radio'),
							caseChecked(0),
							$rtfeldman$elm_css$Html$Styled$Events$onInput(action)
						]),
					_List_Nil),
					A2(
					$rtfeldman$elm_css$Html$Styled$label,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$for(idLeft)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(labelLeft)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$input,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$id(idRight),
							$rtfeldman$elm_css$Html$Styled$Attributes$type_('radio'),
							caseChecked(1),
							$rtfeldman$elm_css$Html$Styled$Events$onInput(action)
						]),
					_List_Nil),
					A2(
					$rtfeldman$elm_css$Html$Styled$label,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$for(idRight)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(labelRight)
						]))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$PresentationInfoFormView$presentationInfoForm = F2(
	function (errors, client) {
		var _v0 = function () {
			var _v1 = client.ic;
			if (!_v1.$) {
				return _Utils_Tuple2(' pp', 0);
			} else {
				return _Utils_Tuple2(' pm', 1);
			}
		}();
		var natureClass = _v0.a;
		var natureJuridiqueRadio = _v0.b;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section presentationInfo' + natureClass)
				]),
			_List_fromArray(
				[
					A5(
					$author$project$Components$Clients$Detail$ViewForm$Utils$switchForm,
					'role',
					'Personne physique',
					'Personne morale',
					natureJuridiqueRadio,
					A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ToggleNatureJuridique, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique)),
					A2($author$project$Components$Clients$Detail$ViewForm$PresentationInfoFormView$natureInfoForm, errors, client)
				]));
	});
var $author$project$Components$Clients$Detail$View$ongletPresentation = function (model) {
	var isActive = A2($author$project$Components$Clients$Detail$Model$ongletIsActive, 0, model) ? ' active' : '';
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('presentation'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('onglet' + isActive)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$author$project$Components$Clients$Detail$Action$SetFormFields,
				A2($author$project$Components$Clients$Detail$ViewForm$PresentationInfoFormView$presentationInfoForm, model.mv, model.gb)),
				A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$author$project$Components$Clients$Detail$Action$SetFormFields,
				A2($author$project$Components$Clients$Detail$ViewForm$ContactFormView$contactForm, model.mv, model.gb.cY))
			]));
};
var $author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$displayMissingFieldMessage = F3(
	function (field, errors, message) {
		var checkPath = function (error) {
			if (!error.$) {
				var path = error.a;
				return _Utils_eq(field, path);
			} else {
				return false;
			}
		};
		return A2(
			$elm$core$Maybe$withDefault,
			$rtfeldman$elm_css$Html$Styled$text(''),
			A2(
				$elm$core$Maybe$map,
				function (_v0) {
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('errorMessage')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(message)
							]));
				},
				A2($elm_community$list_extra$List$Extra$find, checkPath, errors)));
	});
var $author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$foldSwitchToView = F4(
	function (_switch, left, middle, right) {
		return A2(
			$elm$core$Maybe$withDefault,
			middle,
			A2(
				$elm$core$Maybe$map,
				function (val) {
					return (val === 1) ? left : ((val === 2) ? middle : right);
				},
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(_switch)));
	});
var $author$project$Components$Clients$Detail$ViewForm$Utils$switchBool = function (bool) {
	return bool ? false : true;
};
var $author$project$Components$Clients$Detail$ViewForm$Utils$listSelectForm = F6(
	function (errors, blockState, currentVal, _v0, _v1, fieldBuilder) {
		var domId = _v0;
		var domLabel = _v1;
		var vals = A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Dict$fromList(_List_Nil),
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(currentVal));
		var errorClass = $elm_community$maybe_extra$Maybe$Extra$toList(
			A2(
				$elm$core$Maybe$map,
				function (_v2) {
					return $rtfeldman$elm_css$Html$Styled$Attributes$class('error');
				},
				A2(
					$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
					fieldBuilder(
						$elm$core$Dict$fromList(_List_Nil)),
					errors)));
		var compName = 'slider_' + domId;
		var additionalAttributes = A2(
			$author$project$Components$Clients$Detail$ViewForm$Utils$calculFormItemAttributes,
			blockState,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getInfos(currentVal));
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('listSelectForm')
					]),
				errorClass),
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$withDefault,
					$rtfeldman$elm_css$Html$Styled$text(''),
					A2(
						$elm$core$Maybe$map,
						function (pais) {
							return A2(
								$rtfeldman$elm_css$Html$Styled$ul,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('listSelectForm')
									]),
								pais);
						},
						A2(
							$elm$core$Maybe$map,
							function (pais) {
								return A2(
									$elm$core$List$map,
									function (item) {
										var title = item.a.a;
										var selected = item.b;
										var key = item.a.b;
										var onClickUpdate = $elm$core$Dict$fromList(
											A2(
												$elm$core$List$map,
												function (pai) {
													var actualK = pai.a.b;
													return _Utils_eq(actualK, key) ? _Utils_Tuple2(
														_Utils_Tuple2(title, key),
														$author$project$Components$Clients$Detail$ViewForm$Utils$switchBool(pai.b)) : pai;
												},
												pais));
										var classVal = selected ? 'selected' : 'not_selected';
										return A2(
											$rtfeldman$elm_css$Html$Styled$li,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class(classVal),
													$rtfeldman$elm_css$Html$Styled$Events$onClick(
													fieldBuilder(onClickUpdate))
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(item.a.a)
												]));
									},
									pais);
							},
							A2(
								$elm$core$Maybe$map,
								function (pais) {
									return $elm$core$Dict$toList(pais);
								},
								$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(currentVal)))))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$Utils$AppliedStep = $elm$core$Basics$identity;
var $author$project$Components$Clients$Detail$ViewForm$Utils$ValDecorator = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Html$Styled$Attributes$attribute = $rtfeldman$elm_css$VirtualDom$Styled$attribute;
var $rtfeldman$elm_css$Html$Styled$i = $rtfeldman$elm_css$Html$Styled$node('i');
var $rtfeldman$elm_css$Html$Styled$Attributes$max = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('max');
var $rtfeldman$elm_css$Html$Styled$Attributes$min = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('min');
var $rtfeldman$elm_css$Html$Styled$Attributes$step = function (n) {
	return A2($rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'step', n);
};
var $author$project$Components$Clients$Detail$ViewForm$Utils$sliderForm = function (errors) {
	return function (_v0) {
		return function (_v1) {
			return function (min) {
				return function (max) {
					return function (blockState) {
						return function (currentVal) {
							return function (_v2) {
								return function (_v3) {
									return function (fieldBuilder) {
										var valDecorator = _v0;
										var appliedStep = _v1;
										var domId = _v2;
										var domLabel = _v3;
										var val = A2(
											$elm$core$Maybe$withDefault,
											'50',
											$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(currentVal));
										var errorClass = $elm_community$maybe_extra$Maybe$Extra$toList(
											A2(
												$elm$core$Maybe$map,
												function (_v4) {
													return $rtfeldman$elm_css$Html$Styled$Attributes$class('error');
												},
												A2(
													$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
													fieldBuilder(''),
													errors)));
										var compName = 'slider_' + domId;
										var additionalAttributes = A2(
											$author$project$Components$Clients$Detail$ViewForm$Utils$calculFormItemAttributes,
											blockState,
											$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getInfos(currentVal));
										return A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_Utils_ap(
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
													]),
												errorClass),
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$div,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('sliderForm')
														]),
													_List_fromArray(
														[
															A2(
															$rtfeldman$elm_css$Html$Styled$span,
															_List_Nil,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text(domLabel)
																])),
															A2(
															$rtfeldman$elm_css$Html$Styled$div,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$class('stepped-range-slider')
																]),
															_List_fromArray(
																[
																	A2(
																	$rtfeldman$elm_css$Html$Styled$input,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$type_('range'),
																			$rtfeldman$elm_css$Html$Styled$Attributes$id(compName),
																			$rtfeldman$elm_css$Html$Styled$Attributes$class('slider'),
																			$rtfeldman$elm_css$Html$Styled$Attributes$min(min),
																			$rtfeldman$elm_css$Html$Styled$Attributes$max(max),
																			$rtfeldman$elm_css$Html$Styled$Attributes$value(val),
																			$rtfeldman$elm_css$Html$Styled$Attributes$step(
																			$elm$core$String$fromInt(appliedStep)),
																			A2($rtfeldman$elm_css$Html$Styled$Attributes$attribute, 'style', '--min:' + (min + (';' + ('--max:' + (max + (';' + ('--value:' + val))))))),
																			$rtfeldman$elm_css$Html$Styled$Events$onInput(fieldBuilder)
																		]),
																	_List_Nil),
																	A2(
																	$rtfeldman$elm_css$Html$Styled$i,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class('step-0')
																		]),
																	_List_Nil),
																	A2(
																	$rtfeldman$elm_css$Html$Styled$i,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class('step-25')
																		]),
																	_List_Nil),
																	A2(
																	$rtfeldman$elm_css$Html$Styled$i,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class('step-50')
																		]),
																	_List_Nil),
																	A2(
																	$rtfeldman$elm_css$Html$Styled$i,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class('step-75')
																		]),
																	_List_Nil),
																	A2(
																	$rtfeldman$elm_css$Html$Styled$i,
																	_List_fromArray(
																		[
																			$rtfeldman$elm_css$Html$Styled$Attributes$class('step-100')
																		]),
																	_List_Nil)
																])),
															A2(
															$rtfeldman$elm_css$Html$Styled$label,
															_List_Nil,
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$text(
																	valDecorator(val))
																]))
														]))
												]));
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$sliderForm_ = F2(
	function (errors, profilState) {
		var valDecorator = function (val) {
			return (val === '0') ? (val + '%') : ('jusqu\'à ' + (val + '%'));
		};
		return A6($author$project$Components$Clients$Detail$ViewForm$Utils$sliderForm, errors, valDecorator, 25, '0', '100', profilState);
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$name = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('name');
var $author$project$Components$Clients$Detail$ViewForm$Utils$switchWithMiddle = F9(
	function (errors, blockState, currentVal, formItemLabel, leftLabel, rightLabel, _v0, _v1, fieldBuilder) {
		var domId = _v0;
		var domLabel = _v1;
		var val = A2(
			$elm$core$Maybe$withDefault,
			2,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(currentVal));
		var errorClass = $elm_community$maybe_extra$Maybe$Extra$toList(
			A2(
				$elm$core$Maybe$map,
				function (_v2) {
					return $rtfeldman$elm_css$Html$Styled$Attributes$class('error');
				},
				A2(
					$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
					fieldBuilder(2),
					errors)));
		var compName = 'tss_' + domId;
		var additionalAttributes = A2(
			$author$project$Components$Clients$Detail$ViewForm$Utils$calculFormItemAttributes,
			blockState,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getInfos(currentVal));
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_Utils_ap(errorClass, additionalAttributes.m)),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$label,
					_Utils_ap(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item-label')
							]),
						additionalAttributes.a1),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(formItemLabel)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('switchWithMiddle')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$id(compName + '_1'),
									$rtfeldman$elm_css$Html$Styled$Attributes$type_('radio'),
									$rtfeldman$elm_css$Html$Styled$Attributes$name(compName),
									$rtfeldman$elm_css$Html$Styled$Attributes$value(leftLabel),
									$rtfeldman$elm_css$Html$Styled$Attributes$class('left'),
									$rtfeldman$elm_css$Html$Styled$Attributes$checked(val === 1),
									$rtfeldman$elm_css$Html$Styled$Events$onClick(
									fieldBuilder(1))
								]),
							_List_Nil),
							A2(
							$rtfeldman$elm_css$Html$Styled$label,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$for(compName + '_1')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(leftLabel)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$id(compName + '_2'),
									$rtfeldman$elm_css$Html$Styled$Attributes$type_('radio'),
									$rtfeldman$elm_css$Html$Styled$Attributes$name(compName),
									$rtfeldman$elm_css$Html$Styled$Attributes$value(rightLabel),
									$rtfeldman$elm_css$Html$Styled$Attributes$class('right'),
									$rtfeldman$elm_css$Html$Styled$Attributes$checked(!val),
									$rtfeldman$elm_css$Html$Styled$Events$onClick(
									fieldBuilder(0))
								]),
							_List_Nil),
							A2(
							$rtfeldman$elm_css$Html$Styled$label,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$for(compName + '_2')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(rightLabel)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$input,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$id(compName + '_3'),
									$rtfeldman$elm_css$Html$Styled$Attributes$type_('radio'),
									$rtfeldman$elm_css$Html$Styled$Attributes$name(compName),
									$rtfeldman$elm_css$Html$Styled$Attributes$value('Neutre'),
									$rtfeldman$elm_css$Html$Styled$Attributes$class('middle'),
									$rtfeldman$elm_css$Html$Styled$Attributes$checked(val === 2),
									$rtfeldman$elm_css$Html$Styled$Events$onClick(
									fieldBuilder(2))
								]),
							_List_Nil)
						]))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$paiSection = F3(
	function (errors, profilState, profil) {
		var list = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Dict$toList,
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(profil.cx)));
		var noneSelectedList = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (pai) {
					return _Utils_Tuple2(pai.a, false);
				},
				list));
		var canSelectAll = _Utils_cmp(
			$elm$core$List$length(list),
			A3(
				$elm$core$List$foldl,
				function (current) {
					return function (enabledCount) {
						return current.b ? (enabledCount + 1) : enabledCount;
					};
				},
				0,
				list)) > 0;
		var allSelectedList = $elm$core$Dict$fromList(
			A2(
				$elm$core$List$map,
				function (pai) {
					return _Utils_Tuple2(pai.a, true);
				},
				list));
		return A4(
			$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$foldSwitchToView,
			profil.i0,
			$rtfeldman$elm_css$Html$Styled$text(''),
			$rtfeldman$elm_css$Html$Styled$text(''),
			A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('section pai')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
							]),
						_List_fromArray(
							[
								A9(
								$author$project$Components$Clients$Detail$ViewForm$Utils$switchWithMiddle,
								errors,
								$author$project$Perfimmo$Form$FormModel$blockState(profilState),
								profil.i1,
								'Votre client souhaite-t-il prendre en compte les « Principales Incidences Négatives » (« principal adverse impacts » ou PAI en anglais) ?',
								'Non',
								'Oui',
								'priseEnComptePai',
								'Prise en compte pai',
								A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompPai, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF)),
								A4(
								$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$foldSwitchToView,
								profil.i1,
								$rtfeldman$elm_css$Html$Styled$text(''),
								$rtfeldman$elm_css$Html$Styled$text(''),
								A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('paiContent')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
												]),
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$label,
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('Sélectionnez les PAI')
														])),
													A6(
													$author$project$Components$Clients$Detail$ViewForm$Utils$listSelectForm,
													errors,
													$author$project$Perfimmo$Form$FormModel$blockState(profilState),
													profil.cx,
													'percentagePai',
													'percentagePai',
													A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SelectedPai, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF)),
													canSelectAll ? A2(
													$rtfeldman$elm_css$Html$Styled$button,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('toggle-all'),
															$rtfeldman$elm_css$Html$Styled$Events$onClick(
															$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
																$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SelectedPai(allSelectedList)))
														]),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('Tout sélectionner')
														])) : A2(
													$rtfeldman$elm_css$Html$Styled$button,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('toggle-all'),
															$rtfeldman$elm_css$Html$Styled$Events$onClick(
															$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
																$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SelectedPai(noneSelectedList)))
														]),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('Tout désélectionner')
														])),
													A3(
													$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$displayMissingFieldMessage,
													$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
														$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SelectedPai(
															$elm$core$Dict$fromList(_List_Nil))),
													errors,
													'Vous devez sélectionner au moins un PAI')
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
												]),
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$label,
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('Dans quelles proportions ?')
														])),
													A6(
													$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$sliderForm_,
													errors,
													$author$project$Perfimmo$Form$FormModel$blockState(profilState),
													profil.iF,
													'percentagePai',
													'',
													A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentagePai, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF)),
													A3(
													$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$displayMissingFieldMessage,
													$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
														$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentagePai('')),
													errors,
													'La valeur doit être supérieure à 0%')
												]))
										])))
							])),
						A3(
						$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$displayMissingFieldMessage,
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
							$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompteSection('')),
						errors,
						'Au moins un des critère doit avoir une réponse ')
					])));
	});
var $author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$priseEnCompteEsgSection = F3(
	function (errors, profilState, profil) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section priseEnCompteESG')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A9(
							$author$project$Components$Clients$Detail$ViewForm$Utils$switchWithMiddle,
							errors,
							$author$project$Perfimmo$Form$FormModel$blockState(profilState),
							profil.i0,
							'Votre client souhaite-t-il prendre en considération les risques liés à la durabilité environnementale ou sociale ?',
							'Non (neutre)',
							'Oui',
							'priseEnCompteESG',
							'Prise en compte ESG',
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompteEsg, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF))
						]))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$sdfrSection = F3(
	function (errors, profilState, profil) {
		return A4(
			$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$foldSwitchToView,
			profil.i0,
			$rtfeldman$elm_css$Html$Styled$text(''),
			$rtfeldman$elm_css$Html$Styled$text(''),
			A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('section sdfr')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
							]),
						_List_fromArray(
							[
								A9(
								$author$project$Components$Clients$Detail$ViewForm$Utils$switchWithMiddle,
								errors,
								$author$project$Perfimmo$Form$FormModel$blockState(profilState),
								profil.i2,
								'Votre client a-t-il des préférences au titre des investissements durables (SFDR) (produits « b ») ?',
								'Non',
								'Oui',
								'priseEnCompteSdfr',
								'Prise en compte sdfr',
								A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompSdfr, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF)),
								A4(
								$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$foldSwitchToView,
								profil.i2,
								$rtfeldman$elm_css$Html$Styled$text(''),
								$rtfeldman$elm_css$Html$Styled$text(''),
								A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('sdfrContent')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
												]),
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$label,
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('Produits article 9')
														])),
													A6(
													$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$sliderForm_,
													errors,
													$author$project$Perfimmo$Form$FormModel$blockState(profilState),
													profil.iK,
													'percentageSdfr9e',
													'Environnemental',
													A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr9e, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF)),
													A6(
													$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$sliderForm_,
													errors,
													$author$project$Perfimmo$Form$FormModel$blockState(profilState),
													profil.iM,
													'percentageSdfr9s',
													'Social',
													A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr9s, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF)),
													A6(
													$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$sliderForm_,
													errors,
													$author$project$Perfimmo$Form$FormModel$blockState(profilState),
													profil.iL,
													'percentageSdfr9g',
													'Gouvernance',
													A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr9g, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF))
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
												]),
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$label,
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('Produits article 8')
														])),
													A6(
													$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$sliderForm_,
													errors,
													$author$project$Perfimmo$Form$FormModel$blockState(profilState),
													profil.iH,
													'percentageSdfr8e',
													'Environnemental',
													A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr8e, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF)),
													A6(
													$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$sliderForm_,
													errors,
													$author$project$Perfimmo$Form$FormModel$blockState(profilState),
													profil.iJ,
													'percentageSdfr8s',
													'Social',
													A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr8s, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF)),
													A6(
													$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$sliderForm_,
													errors,
													$author$project$Perfimmo$Form$FormModel$blockState(profilState),
													profil.iI,
													'percentageSdfr8g',
													'Gouvernance',
													A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr8g, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF))
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
												]),
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$label,
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text('Produits article 6')
														])),
													A6(
													$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$sliderForm_,
													errors,
													$author$project$Perfimmo$Form$FormModel$blockState(profilState),
													profil.iG,
													'percentageSdfr6',
													'',
													A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageSdfr6, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF))
												]))
										]))),
								A3(
								$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$displayMissingFieldMessage,
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompteSection('')),
								errors,
								'Au moins un des critère doit avoir une réponse '),
								A3(
								$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$displayMissingFieldMessage,
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SdfrSection('')),
								errors,
								'Au moins une des valeurs doit être supérieure à 0%')
							]))
					])));
	});
var $author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$taxonomieSection = F3(
	function (errors, profilState, profil) {
		return A4(
			$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$foldSwitchToView,
			profil.i0,
			$rtfeldman$elm_css$Html$Styled$text(''),
			$rtfeldman$elm_css$Html$Styled$text(''),
			A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('section taxonomie')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
							]),
						_List_fromArray(
							[
								A9(
								$author$project$Components$Clients$Detail$ViewForm$Utils$switchWithMiddle,
								errors,
								$author$project$Perfimmo$Form$FormModel$blockState(profilState),
								profil.i3,
								'Votre client souhaite-t-il prendre en compte dans son investissement les critères posés par la Taxonomie (produits « a ») ?',
								'Non',
								'Oui',
								'priseEnCompteTaxonomie',
								'Prise en compte taxonomie',
								A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompteTaxonomie, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF)),
								A4(
								$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$foldSwitchToView,
								profil.i3,
								$rtfeldman$elm_css$Html$Styled$text(''),
								$rtfeldman$elm_css$Html$Styled$text(''),
								A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$label,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Si oui, quel pourcentage d’alignement minimum sur la Taxonomie souhaite-t-il prendre en compte ?')
												])),
											A6(
											$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$sliderForm_,
											errors,
											$author$project$Perfimmo$Form$FormModel$blockState(profilState),
											profil.iN,
											'percentageTaxonomie',
											'',
											A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageTaxonomie, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF))
										]))),
								A3(
								$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$displayMissingFieldMessage,
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PriseEnCompteSection('')),
								errors,
								'Au moins un des critère doit avoir une réponse '),
								A3(
								$author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$displayMissingFieldMessage,
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF(
									$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PercentageTaxonomie('')),
								errors,
								'La valeur doit être supérieure à 0%')
							]))
					])));
	});
var $author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$profilExtraFinancierForm = F2(
	function (profilState, errors) {
		var profil = $author$project$Perfimmo$Form$FormModel$getBlock(profilState);
		var inputE = A4(
			$author$project$Perfimmo$Form$FormView$inputForm,
			errors,
			$author$project$Perfimmo$Form$FormModel$blockState(profilState),
			$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
			$author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A3($author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$priseEnCompteEsgSection, errors, profilState, profil),
					A3($author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$taxonomieSection, errors, profilState, profil),
					A3($author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$sdfrSection, errors, profilState, profil),
					A3($author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$paiSection, errors, profilState, profil),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('section dateEntreeRelation')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
								]),
							_List_fromArray(
								[
									A4(
									inputE,
									$author$project$Perfimmo$Form$FormModel$InputDate(profil.gy),
									'dateEntreeRelation',
									'Date de mise à jour du profil extra financier',
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateMiseAJourdateRecueilInfo, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilExtraFinancierF))
								]))
						]))
				]));
	});
var $author$project$Components$Clients$Detail$View$ongletProfilExtraFinancier = function (model) {
	var isActive = A2($author$project$Components$Clients$Detail$Model$ongletIsActive, 6, model) ? ' active' : '';
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('profilExtraFinancier'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('onglet' + isActive)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$author$project$Components$Clients$Detail$Action$SetFormFields,
				A2($author$project$Components$Clients$Detail$ViewForm$ProfilExtraFinancierFormView$profilExtraFinancierForm, model.gb.jg, model.mv))
			]));
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateMajConnaissance = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateMajProfilInvestisseur = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateSignatureConventionReceptionTransmissionOrdre = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateTransformationRelation = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$ViewForm$ProfilInvestisseurFormView$profilInvestisseurForm = F2(
	function (profilState, errors) {
		var selectE = A5(
			$author$project$Perfimmo$Form$FormView$selectForm,
			errors,
			$author$project$Perfimmo$Form$FormModel$blockState(profilState),
			$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
			$author$project$Components$Clients$Detail$ViewForm$Utils$decorator,
			$author$project$Components$Clients$Detail$ViewForm$Utils$selectIsClearable);
		var profil = $author$project$Perfimmo$Form$FormModel$getBlock(profilState);
		var inputE = A4(
			$author$project$Perfimmo$Form$FormView$inputForm,
			errors,
			$author$project$Perfimmo$Form$FormModel$blockState(profilState),
			$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
			$author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('section profilInvestisseur')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
								]),
							_List_fromArray(
								[
									A4(
									selectE,
									'connaissanceExperienceFinanciere',
									'Connaissance expérience financière',
									profil.gg,
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConnaissanceExperienceFinanciere, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilInvestisseurF)),
									A4(
									selectE,
									'profilRisqueInvestisseur',
									'Profil risque investisseur',
									profil.ji,
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilRisqueInvestisseur, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilInvestisseurF)),
									A4(
									selectE,
									'horizonInvestissement',
									'Horizon d\'investissement',
									profil.hz,
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$HorizonInvestissement, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilInvestisseurF)),
									A4(
									selectE,
									'objectifInvestissement',
									'Objectif d\'investissement',
									profil.it,
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ObjectifInvestissement, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilInvestisseurF)),
									A4(
									selectE,
									'capaciteSubirPertes',
									'Capacité à subir des pertes',
									profil.f1,
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$CapaciteSubirPertes, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilInvestisseurF)),
									A4(
									selectE,
									'toleranceAuxRisques',
									'Tolérance aux risques',
									profil.km,
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ToleranceAuxRisques, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilInvestisseurF))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
								]),
							_List_fromArray(
								[
									A4(
									inputE,
									$author$project$Perfimmo$Form$FormModel$InputDate(profil.gt),
									'dateEntreeRelation',
									'Date d\'entrée en relation',
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateEntreeRelation, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilInvestisseurF)),
									A4(
									inputE,
									$author$project$Perfimmo$Form$FormModel$InputDate(profil.gB),
									'dateTransformationRelation',
									'Date de transformation de la relation',
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateTransformationRelation, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilInvestisseurF)),
									A4(
									inputE,
									$author$project$Perfimmo$Form$FormModel$InputDate(profil.gw),
									'dateMajConnaissance',
									'Date mise à jour connaissance',
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateMajConnaissance, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilInvestisseurF)),
									A4(
									inputE,
									$author$project$Perfimmo$Form$FormModel$InputDate(profil.gx),
									'dateMajProfilInvestisseur',
									'Date de mise à jour du profil investisseur',
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateMajProfilInvestisseur, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilInvestisseurF)),
									A4(
									inputE,
									$author$project$Perfimmo$Form$FormModel$InputDate(profil.gA),
									'dateSignatureConventionReceptionTransmissionOrdre',
									'Date de signature convention réception/transmission d\'ordre',
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateSignatureConventionReceptionTransmissionOrdre, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilInvestisseurF))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('section classificationMifII')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h2,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Classification MifII')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
								]),
							_List_fromArray(
								[
									A4(
									selectE,
									'classificationMifIIType',
									'Type de classification MifII',
									profil.ea.kv,
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ClassificationMifIIType,
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ClassificationMifIIF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilInvestisseurF))),
									A4(
									inputE,
									$author$project$Perfimmo$Form$FormModel$InputDate(profil.ea.c$),
									'date',
									'Date',
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ClassificationMifIIDate,
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ClassificationMifIIF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ProfilInvestisseurF)))
								]))
						]))
				]));
	});
var $author$project$Components$Clients$Detail$View$ongletProfilInvestisseur = function (model) {
	var isActive = A2($author$project$Components$Clients$Detail$Model$ongletIsActive, 5, model) ? ' active' : '';
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('profilInvestisseur'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('onglet' + isActive)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$author$project$Components$Clients$Detail$Action$SetFormFields,
				A2($author$project$Components$Clients$Detail$ViewForm$ProfilInvestisseurFormView$profilInvestisseurForm, model.gb.jh, model.mv))
			]));
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$AddRepresentant = {$: 0};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$RemoveRepresentant = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalMail = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalNationalite = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Components$Clients$Detail$ViewForm$CommonFormView$naissanceForm = F4(
	function (errors, formState, form, field) {
		var inputE = A4($author$project$Perfimmo$Form$FormView$inputForm, errors, formState, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Naissance')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A4(
							inputE,
							$author$project$Perfimmo$Form$FormModel$InputDate(form.c$),
							'dateNaissance',
							'Date de naissance',
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$DateNaissance, field)),
							A4(
							inputE,
							$author$project$Perfimmo$Form$FormModel$InputText(form.d_),
							'ville',
							'Ville de naissance',
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$VilleNaissance, field)),
							A9(
							$author$project$Perfimmo$Form$FormView$selectForm,
							errors,
							formState,
							$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
							$author$project$Components$Clients$Detail$ViewForm$Utils$decorator,
							$author$project$Components$Clients$Detail$ViewForm$Utils$selectIsClearable,
							'paysDeNaissance',
							'Pays de naissance',
							form.dq,
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PaysNaissance, field))
						]))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$RepresentantsLegauxFormView$representantLegalForm = F4(
	function (errors, field, n, representantLegal) {
		var reprNo = n + 1;
		var inputE = A4($author$project$Perfimmo$Form$FormView$inputForm, errors, 0, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('person-form')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$button,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('remove'),
							$rtfeldman$elm_css$Html$Styled$Events$onClick(
							field(
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$RemoveRepresentant(reprNo)))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('-')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$h3,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									'Représentant légal ' + $elm$core$String$fromInt(reprNo))
								]))
						])),
					A4(
					$author$project$Components$Clients$Detail$ViewForm$CommonFormView$identiteForm,
					errors,
					0,
					representantLegal.aD,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalIdentite(reprNo),
						field)),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A4(
							inputE,
							$author$project$Perfimmo$Form$FormModel$InputEmail(representantLegal.h_),
							'mail',
							'E-mail',
							A2(
								$elm$core$Basics$composeR,
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalMail(reprNo),
								field)),
							A4(
							inputE,
							$author$project$Perfimmo$Form$FormModel$InputText(representantLegal.g9),
							'fonction',
							'Fonction',
							A2(
								$elm$core$Basics$composeR,
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalFonction(reprNo),
								field)),
							A3(
							$author$project$Components$Clients$Detail$ViewForm$CommonFormView$nationaliteForm,
							errors,
							representantLegal.bD,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalNationalite(reprNo),
								field)),
							A3(
							$author$project$Components$Clients$Detail$ViewForm$CommonFormView$multipleNationalitesForm,
							errors,
							representantLegal.bf,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalAutreNationalite(reprNo),
								field))
						])),
					A4(
					$author$project$Components$Clients$Detail$ViewForm$CommonFormView$naissanceForm,
					errors,
					0,
					representantLegal.aI,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalNaissance(reprNo),
						field)),
					A4(
					$author$project$Components$Clients$Detail$ViewForm$CommonFormView$pieceIdentiteForm,
					errors,
					$author$project$Perfimmo$Form$FormModel$blockState(representantLegal.du),
					$author$project$Perfimmo$Form$FormModel$getBlock(representantLegal.du),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalPieceIdentite(reprNo),
						field)),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('section')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h2,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Adresse')
								])),
							A3(
							$author$project$Components$Clients$Detail$ViewForm$CommonFormView$adresseForm,
							errors,
							representantLegal.fD,
							A2(
								$elm$core$Basics$composeR,
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReprLegalAdresse(reprNo),
								field))
						]))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$RepresentantsLegauxFormView$representantsLegauxForm = F3(
	function (errors, representantsLegaux, field) {
		var title = ($elm$core$List$length(representantsLegaux) > 1) ? 'Représentants légaux' : 'Représentant légal';
		var errorMessages = A2(
			$elm$core$List$map,
			function (m) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('errorMessage')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(m)
						]));
			},
			A2($author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayOngletErrorMessage, 4, errors));
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section representantsLegaux')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(title)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_Utils_ap(
						A2(
							$elm$core$List$indexedMap,
							A2($author$project$Components$Clients$Detail$ViewForm$RepresentantsLegauxFormView$representantLegalForm, errors, field),
							representantsLegaux),
						_List_fromArray(
							[
								A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, errorMessages),
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('add-person')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$button,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Events$onClick(
												field($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$AddRepresentant))
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Ajouter un représentant légal')
											]))
									]))
							])))
				]));
	});
var $author$project$Components$Clients$Detail$View$ongletRepresentantsLegaux = function (model) {
	var isActive = A2($author$project$Components$Clients$Detail$Model$ongletIsActive, 4, model) ? ' active' : '';
	var _v0 = function () {
		var _v1 = model.gb.ic;
		if (!_v1.$) {
			var params = _v1.a;
			return _Utils_Tuple2(
				params.dA,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PP_RepresentantLegal,
					A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonnePhysiqueF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique)));
		} else {
			var params = _v1.a;
			return _Utils_Tuple2(
				params.dA,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PM_RepresentantLegal,
					A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonneMoraleF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique)));
		}
	}();
	var representants = _v0.a;
	var field = _v0.b;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('representantsLegaux'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('onglet' + isActive)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$author$project$Components$Clients$Detail$Action$SetFormFields,
				A3($author$project$Components$Clients$Detail$ViewForm$RepresentantsLegauxFormView$representantsLegauxForm, model.mv, representants, field))
			]));
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$TauxEndettement = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Clients$Detail$ViewForm$SituationPatrimonialeFormView$sitPatField = A2(
	$elm$core$Basics$composeR,
	$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SituationPatrimonialeF,
	A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonnePhysiqueF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique));
var $author$project$Components$Clients$Detail$ViewForm$SituationPatrimonialeFormView$budget = F2(
	function (errors, sitPatBlock) {
		var sitPatState = $author$project$Perfimmo$Form$FormModel$blockState(sitPatBlock);
		var sitPatForm = $author$project$Perfimmo$Form$FormModel$getBlock(sitPatBlock);
		var budgetState = $author$project$Perfimmo$Form$FormModel$blockState(sitPatForm.fX);
		var inputE = A4($author$project$Perfimmo$Form$FormView$inputForm, errors, budgetState, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		var budgetField = $author$project$Perfimmo$Form$FormModel$getBlock(sitPatForm.fX);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('section')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h2,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Budget')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
								]),
							_List_fromArray(
								[
									A4(
									inputE,
									A2(
										$author$project$Perfimmo$Form$FormModel$DecoratedInput,
										$author$project$Perfimmo$Form$FormModel$InputFormFloat(budgetField.jy),
										_List_fromArray(
											[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
									'revenusAnnuels',
									'Revenus annuels',
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$RevenusAnnuels,
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$BudgetF, $author$project$Components$Clients$Detail$ViewForm$SituationPatrimonialeFormView$sitPatField))),
									A4(
									inputE,
									A2(
										$author$project$Perfimmo$Form$FormModel$DecoratedInput,
										$author$project$Perfimmo$Form$FormModel$InputFormFloat(budgetField.f5),
										_List_fromArray(
											[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
									'chargesAnnuelles',
									'Charges annuelles',
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ChargesAnnuelles,
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$BudgetF, $author$project$Components$Clients$Detail$ViewForm$SituationPatrimonialeFormView$sitPatField))),
									A8(
									$author$project$Perfimmo$Form$FormView$inputForm,
									errors,
									sitPatState,
									$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
									$author$project$Components$Clients$Detail$ViewForm$Utils$decorator,
									A2(
										$author$project$Perfimmo$Form$FormModel$DecoratedInput,
										$author$project$Perfimmo$Form$FormModel$InputFormPourcent(sitPatForm.kb),
										_List_fromArray(
											[$author$project$Components$Clients$Detail$ViewForm$Utils$percentClass])),
									'tauxEndettement',
									'Taux d\'endettement',
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$TauxEndettement, $author$project$Components$Clients$Detail$ViewForm$SituationPatrimonialeFormView$sitPatField))
								]))
						]))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$SituationPatrimonialeFormView$fiscalite = F2(
	function (errors, sitPatBlock) {
		var sitPatForm = $author$project$Perfimmo$Form$FormModel$getBlock(sitPatBlock);
		var fiscaliteState = $author$project$Perfimmo$Form$FormModel$blockState(sitPatForm.db);
		var inputE = A4($author$project$Perfimmo$Form$FormView$inputForm, errors, fiscaliteState, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		var fiscaliteFieldDescr = A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$FiscalitePatrimonialeF, $author$project$Components$Clients$Detail$ViewForm$SituationPatrimonialeFormView$sitPatField);
		var fiscaliteField = $author$project$Perfimmo$Form$FormModel$getBlock(sitPatForm.db);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Fiscalité')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A4(
							inputE,
							$author$project$Perfimmo$Form$FormModel$InputFormFloat(fiscaliteField.ik),
							'nombrePartsFiscales',
							'Nombre de part fiscales',
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NbPartFiscales, fiscaliteFieldDescr)),
							A4(
							inputE,
							A2(
								$author$project$Perfimmo$Form$FormModel$DecoratedInput,
								$author$project$Perfimmo$Form$FormModel$InputFormFloat(fiscaliteField.h7),
								_List_fromArray(
									[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
							'montantIR',
							'Montant Impôt sur le Revenu',
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$MontantIR, fiscaliteFieldDescr)),
							A4(
							inputE,
							A2(
								$author$project$Perfimmo$Form$FormModel$DecoratedInput,
								$author$project$Perfimmo$Form$FormModel$InputFormPourcent(fiscaliteField.kj),
								_List_fromArray(
									[$author$project$Components$Clients$Detail$ViewForm$Utils$percentClass])),
							'tmi',
							'Taux marginal d\'imposition (TMI)',
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$TMI, fiscaliteFieldDescr)),
							A4(
							inputE,
							A2(
								$author$project$Perfimmo$Form$FormModel$DecoratedInput,
								$author$project$Perfimmo$Form$FormModel$InputFormFloat(fiscaliteField.h8),
								_List_fromArray(
									[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
							'montantIFI',
							'Montant Impôt sur la Fortune Immobilière (IFI)',
							A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$MontIFI, fiscaliteFieldDescr))
						]))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$SituationPatrimonialeFormView$patrimoine = F2(
	function (errors, sitPatBlock) {
		var sitPatForm = $author$project$Perfimmo$Form$FormModel$getBlock(sitPatBlock);
		var patrimoineState = $author$project$Perfimmo$Form$FormModel$blockState(sitPatForm.iD);
		var patrimoineField = $author$project$Perfimmo$Form$FormModel$getBlock(sitPatForm.iD);
		var patField = A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PatrimoineF, $author$project$Components$Clients$Detail$ViewForm$SituationPatrimonialeFormView$sitPatField);
		var inputE = A4($author$project$Perfimmo$Form$FormView$inputForm, errors, patrimoineState, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		var actifField = patrimoineField.kP;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section patrimoine')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Patrimoine')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('actif')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h3,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Actif')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
								]),
							_List_fromArray(
								[
									A4(
									inputE,
									A2(
										$author$project$Perfimmo$Form$FormModel$DecoratedInput,
										$author$project$Perfimmo$Form$FormModel$InputFormFloat(actifField.da),
										_List_fromArray(
											[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
									'financier',
									'Financier',
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Financier,
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PatrimoineActifF, patField))),
									A4(
									inputE,
									A2(
										$author$project$Perfimmo$Form$FormModel$DecoratedInput,
										$author$project$Perfimmo$Form$FormModel$InputFormFloat(actifField.io),
										_List_fromArray(
											[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
									'non_financier',
									'Non financier',
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NonFinancier,
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PatrimoineActifF, patField))),
									A4(
									inputE,
									A2(
										$author$project$Perfimmo$Form$FormModel$DecoratedInput,
										$author$project$Perfimmo$Form$FormModel$InputFormFloat(actifField.cR),
										_List_fromArray(
											[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass, $author$project$Perfimmo$Dom$Utils$DisabledAttr])),
									'brut',
									'Brut',
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Brut,
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PatrimoineActifF, patField)))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('passif')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h3,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Passif')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
								]),
							_List_fromArray(
								[
									A4(
									inputE,
									A2(
										$author$project$Perfimmo$Form$FormModel$DecoratedInput,
										$author$project$Perfimmo$Form$FormModel$InputFormFloat(patrimoineField.iB),
										_List_fromArray(
											[$author$project$Components$Clients$Detail$ViewForm$Utils$euroClass])),
									'passif',
									'Passif',
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PatrimoinePassif, patField))
								]))
						]))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$SituationPatrimonialeFormView$situationPatrimonialeForm = F2(
	function (errors, params) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$Components$Clients$Detail$ViewForm$SituationPatrimonialeFormView$patrimoine, errors, params.jV),
					A2($author$project$Components$Clients$Detail$ViewForm$SituationPatrimonialeFormView$budget, errors, params.jV),
					A2($author$project$Components$Clients$Detail$ViewForm$SituationPatrimonialeFormView$fiscalite, errors, params.jV)
				]));
	});
var $author$project$Components$Clients$Detail$View$ongletSituationPatrimonialePersonnePhysique = function (model) {
	var result = function () {
		var _v0 = model.gb.ic;
		if (!_v0.$) {
			var params = _v0.a;
			return A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$author$project$Components$Clients$Detail$Action$SetFormFields,
				A2($author$project$Components$Clients$Detail$ViewForm$SituationPatrimonialeFormView$situationPatrimonialeForm, model.mv, params));
		} else {
			return $rtfeldman$elm_css$Html$Styled$text('');
		}
	}();
	var isActive = A2($author$project$Components$Clients$Detail$Model$ongletIsActive, 3, model) ? ' active' : '';
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('situationPatrimonialePersonnePhysique'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('onglet' + isActive)
			]),
		_List_fromArray(
			[result]));
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Ref_ConjointDateMariage = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Ref_ConjointRegimeMatrimoniale = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$conjointField = A2(
	$elm$core$Basics$composeR,
	$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointPartenaireF,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SituationFamillialeF,
		A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonnePhysiqueF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique)));
var $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$referencedIsEmpty = function (form) {
	var regimeMatrimonialeIsEmpty = $elm_community$maybe_extra$Maybe$Extra$isNothing(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.dy));
	var dateMariageIsEmpty = $elm_community$maybe_extra$Maybe$Extra$isNothing(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c0));
	var chosenClientIsEmpty = $elm_community$maybe_extra$Maybe$Extra$isNothing(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$getValue(form.ga));
	return chosenClientIsEmpty && (dateMariageIsEmpty && regimeMatrimonialeIsEmpty);
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointDateMariage = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointNationalite = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointProfession = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointRegimeMatrimoniale = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$unreferencedConjointForm = F4(
	function (errors, sitFamState, params, disableUnref) {
		var inputE = A4($author$project$Perfimmo$Form$FormView$inputForm, errors, sitFamState, $author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError, $author$project$Components$Clients$Detail$ViewForm$Utils$decorator);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('person-form unreferencedConjoint' + disableUnref)
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h3,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Non référencé comme client')
								]))
						])),
					A4(
					$author$project$Components$Clients$Detail$ViewForm$CommonFormView$identiteForm,
					errors,
					sitFamState,
					params.aD,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointIdentiteF,
						A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$UnreferencedConjointF, $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$conjointField))),
					A4(
					$author$project$Components$Clients$Detail$ViewForm$CommonFormView$naissanceForm,
					errors,
					sitFamState,
					params.aI,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointNaissance,
						A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$UnreferencedConjointF, $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$conjointField))),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('section')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h2,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Informations complémentaires')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
								]),
							_List_fromArray(
								[
									A4(
									inputE,
									$author$project$Perfimmo$Form$FormModel$InputText(params.dw),
									'profession',
									'Profession',
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointProfession,
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$UnreferencedConjointF, $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$conjointField))),
									A9(
									$author$project$Perfimmo$Form$FormView$selectForm,
									errors,
									0,
									$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
									$author$project$Components$Clients$Detail$ViewForm$Utils$decorator,
									$author$project$Components$Clients$Detail$ViewForm$Utils$selectIsClearable,
									'regimeMatrimoniale',
									'Régime matrimonial',
									params.dy,
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointRegimeMatrimoniale,
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$UnreferencedConjointF, $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$conjointField))),
									A4(
									inputE,
									$author$project$Perfimmo$Form$FormModel$InputDate(params.c0),
									'dateMariage',
									'Date de mariage / PACS',
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointDateMariage,
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$UnreferencedConjointF, $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$conjointField))),
									A3(
									$author$project$Components$Clients$Detail$ViewForm$CommonFormView$nationaliteForm,
									errors,
									params.bD,
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointNationalite,
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$UnreferencedConjointF, $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$conjointField))),
									A3(
									$author$project$Components$Clients$Detail$ViewForm$CommonFormView$multipleNationalitesForm,
									errors,
									params.bf,
									A2(
										$elm$core$Basics$composeR,
										$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ConjointAutreNationalite,
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$UnreferencedConjointF, $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$conjointField)))
								]))
						]))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$unreferencedIsEmpty = function (form) {
	var regimeMatrimonialeIsEmpty = $elm_community$maybe_extra$Maybe$Extra$isNothing(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.dy));
	var professionIsEmpty = $elm_community$maybe_extra$Maybe$Extra$isNothing(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.dw));
	var natIsEmpty = $elm_community$maybe_extra$Maybe$Extra$isNothing(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.bD));
	var naissanceIsEmpty = $elm_community$maybe_extra$Maybe$Extra$isNothing(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.aI.c$)) && ($elm_community$maybe_extra$Maybe$Extra$isNothing(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.aI.d_)) && $elm_community$maybe_extra$Maybe$Extra$isNothing(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.aI.dq)));
	var idEmpty = $elm_community$maybe_extra$Maybe$Extra$isNothing(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.aD.ii)) && ($elm_community$maybe_extra$Maybe$Extra$isNothing(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.aD.dl)) && ($elm_community$maybe_extra$Maybe$Extra$isNothing(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getValue(form.aD.f8)) && $elm_community$maybe_extra$Maybe$Extra$isNothing(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.aD.iV))));
	var dateMariageIsEmpty = $elm_community$maybe_extra$Maybe$Extra$isNothing(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(form.c0));
	var autreNatIsEmpty = $elm$core$List$isEmpty(
		$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormMultiSelect$getSelectedValues(form.bf));
	return idEmpty && (naissanceIsEmpty && (natIsEmpty && (autreNatIsEmpty && (professionIsEmpty && (dateMariageIsEmpty && regimeMatrimonialeIsEmpty)))));
};
var $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$conjoints = F4(
	function (extSync, errors, params, clients) {
		var sitFamState = $author$project$Perfimmo$Form$FormModel$blockState(params.jU);
		var sitFamForm = $author$project$Perfimmo$Form$FormModel$getBlock(params.jU);
		var unreferencedConjoint = function () {
			var _v4 = sitFamForm.gf;
			if (!_v4.$) {
				return $author$project$Components$Clients$Detail$ModelForm$FormInitializers$initUnreferencedConjointPartenaireForm(extSync);
			} else {
				var unreferencedParams = _v4.a;
				return unreferencedParams;
			}
		}();
		var _v0 = function () {
			var _v1 = sitFamForm.gf;
			if (!_v1.$) {
				var form = _v1.a;
				return _Utils_Tuple3(form.ga, form.c0, form.dy);
			} else {
				var refForm = A2(
					$author$project$Components$Clients$Detail$ModelForm$FormInitializers$initReferencedConjointParamsForm,
					extSync,
					A2(
						$elm$core$List$map,
						function ($) {
							return $.nz;
						},
						clients));
				return _Utils_Tuple3(refForm.ga, refForm.c0, refForm.dy);
			}
		}();
		var chosenClient = _v0.a;
		var dateM = _v0.b;
		var regimeM = _v0.c;
		var _v2 = function () {
			var _v3 = sitFamForm.gf;
			if (!_v3.$) {
				var form = _v3.a;
				return $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$referencedIsEmpty(form) ? _Utils_Tuple2('', '') : _Utils_Tuple2('', ' disabled');
			} else {
				var form = _v3.a;
				return $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$unreferencedIsEmpty(form) ? _Utils_Tuple2('', '') : _Utils_Tuple2(' disabled', '');
			}
		}();
		var disableRef = _v2.a;
		var disableUnref = _v2.b;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section conjointPartenaire')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Conjoint Partenaire')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('person-form referencedClient' + disableRef)
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$h3,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$span,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Référencé comme client')
												]))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
										]),
									_List_fromArray(
										[
											A6(
											$author$project$Components$Clients$Detail$ViewForm$Utils$dataListForm,
											A2(
												$author$project$Components$Clients$Detail$UpdateForm$DisplayError2$errors_,
												errors,
												A2(
													$elm$core$Basics$composeR,
													$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ChooseClient,
													A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReferencedConjoint, $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$conjointField))('')),
											sitFamState,
											'referencedClient',
											'Conjoint',
											chosenClient,
											A2(
												$elm$core$Basics$composeR,
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ChooseClient,
												A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReferencedConjoint, $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$conjointField))),
											A9(
											$author$project$Perfimmo$Form$FormView$selectForm,
											errors,
											sitFamState,
											$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
											$author$project$Components$Clients$Detail$ViewForm$Utils$decorator,
											$author$project$Components$Clients$Detail$ViewForm$Utils$selectIsClearable,
											'regimeMatrimoniale',
											'Régime matrimonial',
											regimeM,
											A2(
												$elm$core$Basics$composeR,
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Ref_ConjointRegimeMatrimoniale,
												A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReferencedConjoint, $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$conjointField))),
											A8(
											$author$project$Perfimmo$Form$FormView$inputForm,
											errors,
											sitFamState,
											$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
											$author$project$Components$Clients$Detail$ViewForm$Utils$decorator,
											$author$project$Perfimmo$Form$FormModel$InputDate(dateM),
											'dateMariage',
											'Date de mariage / PACS',
											A2(
												$elm$core$Basics$composeR,
												$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Ref_ConjointDateMariage,
												A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$ReferencedConjoint, $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$conjointField)))
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('or')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$span,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('ou')
										]))
								])),
							A4($author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$unreferencedConjointForm, errors, sitFamState, unreferencedConjoint, disableUnref)
						]))
				]));
	});
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$AddEnfant = {$: 3};
var $author$project$Perfimmo$Form$FormModel$InputFormInt = function (a) {
	return {$: 8, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NbEnfantsACharge = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$RemoveEnfant = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$sitFamField = A2(
	$elm$core$Basics$composeR,
	$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SituationFamillialeF,
	A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$PersonnePhysiqueF, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NatureJuridique));
var $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$sitFamAction = $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$sitFamField;
var $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$enfantForm = F3(
	function (errors, n, enfant) {
		var noEnfant = n + 1;
		var naissanceField = A2(
			$elm$core$Basics$composeR,
			$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$EnfantNaissanceF,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Enfants(noEnfant),
				$author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$sitFamField));
		var idField = A2(
			$elm$core$Basics$composeR,
			$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$EnfantIdentiteF,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$Enfants(noEnfant),
				$author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$sitFamField));
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('person-form')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$button,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('remove'),
							$rtfeldman$elm_css$Html$Styled$Events$onClick(
							$author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$sitFamAction(
								$author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$RemoveEnfant(noEnfant)))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('-')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$h3,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									'Enfant ' + $elm$core$String$fromInt(noEnfant))
								]))
						])),
					A4($author$project$Components$Clients$Detail$ViewForm$CommonFormView$identiteForm, errors, 0, enfant.aD, idField),
					A4($author$project$Components$Clients$Detail$ViewForm$CommonFormView$naissanceForm, errors, 0, enfant.aI, naissanceField)
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$enfants = F2(
	function (errors, sitFamBlock) {
		var sitFamState = $author$project$Perfimmo$Form$FormModel$blockState(sitFamBlock);
		var sitFam = $author$project$Perfimmo$Form$FormModel$getBlock(sitFamBlock);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section enfants')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Enfant(s)')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('enfantACharge')
									]),
								_List_fromArray(
									[
										A8(
										$author$project$Perfimmo$Form$FormView$inputForm,
										errors,
										sitFamState,
										$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
										$author$project$Components$Clients$Detail$ViewForm$Utils$decorator,
										$author$project$Perfimmo$Form$FormModel$InputFormInt(sitFam.ij),
										'enfantACharge',
										'Nombre d\'enfant(s) à charge',
										A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$NbEnfantsACharge, $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$sitFamField))
									]))
							]),
						_Utils_ap(
							A2(
								$elm$core$List$indexedMap,
								$author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$enfantForm(errors),
								sitFam.gX),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('add-person')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$button,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Events$onClick(
													$author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$sitFamAction($author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$AddEnfant))
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Ajouter un enfant')
												]))
										]))
								]))))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$situation = F2(
	function (errors, params) {
		var state = $author$project$Perfimmo$Form$FormModel$blockState(params.jU);
		var form = $author$project$Perfimmo$Form$FormModel$getBlock(params.jU);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('section situationFamille')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h2,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Situation familiale')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
								]),
							_List_fromArray(
								[
									A9(
									$author$project$Perfimmo$Form$FormView$selectForm,
									errors,
									state,
									$author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayMissingFieldError,
									$author$project$Components$Clients$Detail$ViewForm$Utils$decorator,
									$author$project$Components$Clients$Detail$ViewForm$Utils$selectIsClearable,
									'situationFamille',
									'Situation de famille',
									form.jT,
									A2($elm$core$Basics$composeR, $author$project$Components$Clients$Detail$UpdateForm$FormFieldDescriptor$SituationFamille, $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$sitFamField))
								]))
						]))
				]));
	});
var $author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$situationFamillialeForm = F4(
	function (extSync, errors, params, clients) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$situation, errors, params),
					A4($author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$conjoints, extSync, errors, params, clients),
					A2($author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$enfants, errors, params.jU)
				]));
	});
var $author$project$Components$Clients$Detail$View$ongletSituationPersonnePhysique = function (model) {
	var isActive = A2($author$project$Components$Clients$Detail$Model$ongletIsActive, 2, model) ? ' active' : '';
	var form = function () {
		var _v0 = model.gb.ic;
		if (!_v0.$) {
			var params = _v0.a;
			return A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$author$project$Components$Clients$Detail$Action$SetFormFields,
				A4($author$project$Components$Clients$Detail$ViewForm$SituationFamillialeFormView$situationFamillialeForm, model.am, model.mv, params, model.d$));
		} else {
			return $rtfeldman$elm_css$Html$Styled$text('');
		}
	}();
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('situationFamillialePersonnePhysique'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('onglet' + isActive)
			]),
		_List_fromArray(
			[form]));
};
var $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getStringValue = function (_v0) {
	var val = _v0.a;
	var toString = _v0.e;
	return A2($elm$core$Maybe$map, toString, val);
};
var $author$project$Components$Clients$Detail$View$clientName = function (client) {
	var _v0 = client.ic;
	if (!_v0.$) {
		var params = _v0.a;
		var prenom = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(params.aD.iV);
		var nom = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(params.aD.dl);
		var civ = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormSelect$getStringValue(params.aD.f8);
		return A4(
			$elm$core$Maybe$map3,
			F3(
				function (c, p, n) {
					return c + (' ' + (p + (' ' + n)));
				}),
			civ,
			prenom,
			nom);
	} else {
		var params = _v0.a;
		return $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(params.dl);
	}
};
var $author$project$Components$Clients$Detail$View$pageTitle = function (client) {
	return A2(
		$elm$core$Maybe$withDefault,
		'Créer un client',
		$author$project$Components$Clients$Detail$View$clientName(client));
};
var $author$project$Components$Clients$Detail$Action$Save = {$: 4};
var $author$project$Components$Clients$Detail$View$saveButton = F3(
	function (model, readyToCreate, saveStateClass) {
		var canUpsert = model.d1.kA;
		var but = A2(
			$rtfeldman$elm_css$Html$Styled$button,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Clients$Detail$Action$Save),
					$rtfeldman$elm_css$Html$Styled$Attributes$class('save' + saveStateClass),
					$rtfeldman$elm_css$Html$Styled$Attributes$disabled(!readyToCreate)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Sauvegarder')
				]));
		return A2(
			$elm$core$Maybe$withDefault,
			$rtfeldman$elm_css$Html$Styled$text(''),
			A2(
				$elm$core$Maybe$map,
				function (_v0) {
					return but;
				},
				canUpsert));
	});
var $author$project$Components$Clients$Detail$View$view = function (model) {
	var syncstatus = function () {
		var _v5 = model.bQ;
		switch (_v5.$) {
			case 0:
				return A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil);
			case 2:
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('notO2SSync'),
							$rtfeldman$elm_css$Html$Styled$Attributes$title('Client non synchronisé avec O2S')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('O2S')
						]));
			default:
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('isSyncWithO2S'),
							$rtfeldman$elm_css$Html$Styled$Attributes$title('Client synchronisé avec O2S')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('O2S')
						]));
		}
	}();
	var stringHead = function (s) {
		return A2(
			$elm$core$Maybe$map,
			function (_v4) {
				var h = _v4.a;
				return h;
			},
			$elm$core$String$uncons(s));
	};
	var ongletInError = function (onglet) {
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (e) {
					return ' ' + e;
				},
				A2($author$project$Components$Clients$Detail$UpdateForm$DisplayError$displayOngletErrors, onglet, model.mv)));
	};
	var moduloId = A2(
		$elm$core$Maybe$withDefault,
		'0',
		A2(
			$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
			A2(
				$elm$core$Basics$composeR,
				stringHead,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Maybe$map($elm$core$Char$toCode),
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map(
							$elm$core$Basics$modBy(7)),
						$elm$core$Maybe$map($elm$core$String$fromInt)))),
			model.lu));
	var activeOnglet = function (onglet) {
		return A2($author$project$Components$Clients$Detail$Model$ongletIsActive, onglet, model) ? 'active' : '';
	};
	var buttonParams = function (onglet) {
		var classes = _Utils_ap(
			activeOnglet(onglet),
			ongletInError(onglet));
		return _List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class(classes),
				$rtfeldman$elm_css$Html$Styled$Events$onClick(
				$author$project$Components$Clients$Detail$Action$ActiveOnglet(onglet))
			]);
	};
	var situationFamillialeButton = function () {
		var _v3 = model.gb.ic;
		if (!_v3.$) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$button,
				buttonParams(2),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Situation familiale')
					]));
		} else {
			return A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil);
		}
	}();
	var situationPatrimonialeButton = function () {
		var _v2 = model.gb.ic;
		if (!_v2.$) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$button,
				buttonParams(3),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Situation patrimoniale')
					]));
		} else {
			return A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil);
		}
	}();
	var _v0 = function () {
		var _v1 = model.oq;
		switch (_v1.$) {
			case 0:
				return _Utils_Tuple2(false, ' notModified');
			case 1:
				return _Utils_Tuple2(true, ' readyToSave');
			case 2:
				return _Utils_Tuple2(true, ' error');
			case 3:
				return _Utils_Tuple2(false, ' displayError');
			default:
				return _Utils_Tuple2(false, ' savingClient');
		}
	}();
	var readyToCreate = _v0.a;
	var saveStateClass = _v0.b;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('page-client-detail')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('top')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('back'),
								$rtfeldman$elm_css$Html$Styled$Attributes$href('/clients')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Mes clients')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('top-content')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$h1,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(
										$author$project$Components$Clients$Detail$View$pageTitle(model.gb))
									])),
								syncstatus
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('tabs')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$button,
								buttonParams(0),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Présentation')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$button,
								buttonParams(1),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Informations complémentaires')
									])),
								situationFamillialeButton,
								situationPatrimonialeButton,
								A2(
								$rtfeldman$elm_css$Html$Styled$button,
								buttonParams(4),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Représentant légal')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$button,
								buttonParams(5),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Profil financier')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$button,
								buttonParams(6),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Profil extra financier')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$button,
								buttonParams(7),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Conseillers')
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
					]),
				_List_fromArray(
					[
						$author$project$Components$Clients$Detail$View$ongletPresentation(model),
						$author$project$Components$Clients$Detail$View$ongletInfoComplementaires(model),
						$author$project$Components$Clients$Detail$View$ongletSituationPersonnePhysique(model),
						$author$project$Components$Clients$Detail$View$ongletSituationPatrimonialePersonnePhysique(model),
						$author$project$Components$Clients$Detail$View$ongletRepresentantsLegaux(model),
						$author$project$Components$Clients$Detail$View$ongletProfilInvestisseur(model),
						$author$project$Components$Clients$Detail$View$ongletProfilExtraFinancier(model),
						$author$project$Components$Clients$Detail$View$ongletConseillers(model)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$author$project$Components$Clients$Detail$Action$DeleteAction,
				A4(
					$author$project$Components$Drawer$ConfirmationDrawer$view,
					model.lQ,
					'Supprimer le client',
					_List_fromArray(
						['Souhaitez-vous réellement supprimer ce client ?']),
					$elm$core$Maybe$Just('La suppression est définitive et vous ne pourrez pas revenir en arrière'))),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('fixed')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$button,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Clients$Detail$Action$Cancel),
								$rtfeldman$elm_css$Html$Styled$Attributes$class('back')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Retour')
							])),
						$author$project$Components$Clients$Detail$View$deleteButton(model),
						A3($author$project$Components$Clients$Detail$View$saveButton, model, readyToCreate, saveStateClass)
					]))
			]));
};
var $author$project$Components$Clients$List$Update$AskForEndSyncProcess = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $author$project$Components$Clients$List$View$actionButtons = function (model) {
	var notReadyToEndSyncProcess = model.k.hU || (!model.k.dg);
	var syncClientButtonTitle = notReadyToEndSyncProcess ? $rtfeldman$elm_css$Html$Styled$Attributes$title('Vous devez synchroniser vos clients existants avec 02S avant') : $rtfeldman$elm_css$Html$Styled$Attributes$class('');
	var loading = model.k.hU ? 'loading' : '';
	var syncClient = $elm_community$maybe_extra$Maybe$Extra$toList(
		A2(
			$elm$core$Maybe$map,
			function (l) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$button,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sync'),
							$rtfeldman$elm_css$Html$Styled$Attributes$class(loading),
							$rtfeldman$elm_css$Html$Styled$Attributes$disabled(notReadyToEndSyncProcess),
							$rtfeldman$elm_css$Html$Styled$Events$onClick(
							A2($author$project$Components$Clients$List$Update$AskForEndSyncProcess, 0, l)),
							syncClientButtonTitle
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Finaliser le processus de synchronisation')
						]));
			},
			model.k_.ff));
	var addClient = $elm_community$maybe_extra$Maybe$Extra$toList(
		A2(
			$elm$core$Maybe$map,
			function (_v0) {
				return A2(
					$elm$core$Maybe$withDefault,
					A2(
						$rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$href(
								$author$project$Routes$Model$routes.ca.lF.dX(0)),
								$rtfeldman$elm_css$Html$Styled$Attributes$class('add')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Créer un nouveau Client')
									]))
							])),
					A2(
						$elm$core$Maybe$map,
						function (_v1) {
							return A2(
								$rtfeldman$elm_css$Html$Styled$button,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('add'),
										$rtfeldman$elm_css$Html$Styled$Attributes$disabled(true),
										$rtfeldman$elm_css$Html$Styled$Attributes$title('Vous devez synchroniser vos clients existants avec 02S avant')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Créer un nouveau Client')
									]));
						},
						model.k_.ff));
			},
			model.k_.gm));
	return _Utils_ap(syncClient, addClient);
};
var $author$project$Components$Clients$List$Model$ExtToolSyncMaterials = F2(
	function (client, sync) {
		return {ga: client, dR: sync};
	});
var $author$project$Components$Clients$List$Update$StartSyncClientWithExtToolProcess = function (a) {
	return {$: 4, a: a};
};
var $PerformanceIMMO$elm_utils$Perfimmo$Html$Dom$onClick_stopProp = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$stopPropagationOn,
		'click',
		$elm$json$Json$Decode$succeed(
			_Utils_Tuple2(msg, true)));
};
var $author$project$Components$Clients$List$View$cartoucheClient = function (client) {
	var info = {
		fY: $author$project$Routes$Model$routes.ca.mG.dX(client.kx),
		kH: function () {
			var _v2 = client.bQ;
			if (_v2.$ === 2) {
				var shouldBeSyncPart = _v2.a;
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('notO2SSync'),
							$rtfeldman$elm_css$Html$Styled$Attributes$title('Client non synchronisé avec O2S'),
							$PerformanceIMMO$elm_utils$Perfimmo$Html$Dom$onClick_stopProp(
							$author$project$Components$Clients$List$Update$StartSyncClientWithExtToolProcess(
								A2($author$project$Components$Clients$List$Model$ExtToolSyncMaterials, client, shouldBeSyncPart)))
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('O2S')
						]));
			} else {
				return A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil);
			}
		}()
	};
	var _v0 = function () {
		var _v1 = client.nB;
		if (!_v1) {
			var r = A2($elm$core$String$split, ' ', client.nz);
			var prenom = $elm$core$List$head(r);
			var nom = $elm_community$list_extra$List$Extra$last(r);
			var initialWord = A2(
				$elm$core$Basics$composeR,
				$elm$core$String$toList,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$head,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$Maybe$map($elm$core$String$fromChar),
						$elm$core$Maybe$withDefault(''))));
			var initial = A2(
				$elm$core$Maybe$withDefault,
				'',
				A3(
					$elm$core$Maybe$map2,
					F2(
						function (p, n) {
							return _Utils_ap(
								initialWord(p),
								initialWord(n));
						}),
					prenom,
					nom));
			return _Utils_Tuple2(
				initial,
				$rtfeldman$elm_css$Html$Styled$Attributes$class('pp'));
		} else {
			return _Utils_Tuple2(
				'',
				$rtfeldman$elm_css$Html$Styled$Attributes$class('pm'));
		}
	}();
	var initialDuGars = _v0.a;
	var pm_pp = _v0.b;
	return A2(
		$rtfeldman$elm_css$Html$Styled$a,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('cartouche-client'),
				$rtfeldman$elm_css$Html$Styled$Attributes$href(info.fY)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('main')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('logo'),
								pm_pp
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(initialDuGars)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('title')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(client.nz)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('aside')
					]),
				_List_fromArray(
					[
						info.kH,
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('action')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Voir la fiche client')
									]))
							]))
					]))
			]));
};
var $author$project$Components$Clients$List$View$cartoucheClientForSync = function (client) {
	var extSystemLabel = function (e) {
		return '02S';
	};
	var _v0 = function () {
		var _v1 = client.bQ;
		switch (_v1.$) {
			case 0:
				return _Utils_Tuple3(
					$rtfeldman$elm_css$Html$Styled$Attributes$class(''),
					A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil),
					$rtfeldman$elm_css$Html$Styled$Attributes$class(''));
			case 1:
				var extSystem = _v1.a;
				return _Utils_Tuple3(
					$rtfeldman$elm_css$Html$Styled$Attributes$class(''),
					A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('isSyncWithO2S'),
								$rtfeldman$elm_css$Html$Styled$Attributes$title(
								'Client synchronisé avec ' + extSystemLabel(extSystem))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('O2S')
							])),
					$rtfeldman$elm_css$Html$Styled$Attributes$class('alreadySync'));
			default:
				var shouldBeSyncPart = _v1.a;
				return _Utils_Tuple3(
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
						$author$project$Components$Clients$List$Update$StartSyncClientWithExtToolProcess(
							A2($author$project$Components$Clients$List$Model$ExtToolSyncMaterials, client, shouldBeSyncPart))),
					A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('notO2SSync'),
								$rtfeldman$elm_css$Html$Styled$Attributes$title(
								'Client non synchronisé avec ' + extSystemLabel(shouldBeSyncPart.g$))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('O2S')
							])),
					$rtfeldman$elm_css$Html$Styled$Attributes$class('mustSync'));
		}
	}();
	var action = _v0.a;
	var syncStatus = _v0.b;
	var cartoucheStyle = _v0.c;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('cartouche-client'),
				cartoucheStyle,
				action
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('main')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('logo pp')
							]),
						_List_Nil),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('title')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(client.nz)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('aside')
					]),
				_List_fromArray(
					[
						syncStatus,
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('action')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Synchroniser avec O2S')
									]))
							]))
					]))
			]));
};
var $author$project$Components$Clients$List$View$cartoucheClientBox = F2(
	function (model, client) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Components$Clients$List$View$cartoucheClient(client),
			A2(
				$elm$core$Maybe$map,
				function (_v0) {
					return $author$project$Components$Clients$List$View$cartoucheClientForSync(client);
				},
				model.k_.ff));
	});
var $author$project$Components$Clients$List$View$displayClients = function (model) {
	return A2(
		$elm$core$List$map,
		function (client) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$li,
				_List_Nil,
				_List_fromArray(
					[
						A2($author$project$Components$Clients$List$View$cartoucheClientBox, model, client)
					]));
		},
		model.ck);
};
var $author$project$Components$Clients$List$Model$EndSyncProcessDrawer = 1;
var $author$project$Components$Clients$List$Update$EndSyncProcess = function (a) {
	return {$: 16, a: a};
};
var $author$project$Components$Clients$List$Update$SetIfWantPullingClientsFromExtTool = function (a) {
	return {$: 15, a: a};
};
var $author$project$Components$Clients$List$View$endFirstSynchroDrawerContent = function (endProcess) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('drawer-end-first-synchro')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h2,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Finaliser la synchronisation de vos clients')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-info')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Ceci est la dernière étape de synchronisation avec 02S.')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Une fois validée, vous pourrez reprendre normalement votre activité avec des clients synchronisés avec 02S.')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Voulez-vous récupérer l\'ensemble de vos contacts 02S sur MustCompliance ?')
							])),
						A5($author$project$Components$Clients$Detail$ViewForm$Utils$switchForm, 'endSyncProcessSwitch', 'Non', 'Oui', endProcess.dx, $author$project$Components$Clients$List$Update$SetIfWantPullingClientsFromExtTool)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('drawerActions')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$button,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('button primary done'),
								$rtfeldman$elm_css$Html$Styled$Events$onClick(
								$author$project$Components$Clients$List$Update$EndSyncProcess(endProcess))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Valider')
							]))
					]))
			]));
};
var $author$project$Components$Clients$List$View$endFirstSynchroDrawer = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil),
		A2(
			$elm$core$Maybe$map,
			function (subModel) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$map,
					$author$project$Components$Clients$List$Update$SyncDrawerEvents(1),
					A2(
						$author$project$Components$Drawer$View$view,
						subModel.ep,
						$author$project$Components$Clients$List$View$endFirstSynchroDrawerContent(subModel)));
			},
			model.k.aW));
};
var $author$project$Components$Clients$List$Update$SearchClient = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Clients$List$View$filters = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('filters')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Recherche par nom du client')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$input,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Components$Clients$List$Update$SearchClient),
								$rtfeldman$elm_css$Html$Styled$Attributes$class('search'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
								$rtfeldman$elm_css$Html$Styled$Attributes$value(
								A2($elm$core$Maybe$withDefault, '', model.jG))
							]),
						_List_Nil)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Filtrer par conseillers')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Components$Clients$List$Update$FilterByConseillers,
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.cX)))
							]))
					]))
			]));
};
var $rtfeldman$elm_css$Html$Styled$strong = $rtfeldman$elm_css$Html$Styled$node('strong');
var $author$project$Components$Clients$List$View$syncRemainingCounter = function (model) {
	var clientToSynchronise = function (cs) {
		return $elm$core$List$length(
			A2(
				$elm$core$List$filter,
				function (c) {
					var _v0 = c.bQ;
					if (_v0.$ === 2) {
						return true;
					} else {
						return false;
					}
				},
				cs));
	};
	var syncProcessIsDone = $rtfeldman$elm_css$Html$Styled$Attributes$classList(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'syncProcessIsDone',
				!clientToSynchronise(model.ca))
			]));
	var syncBox = A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('syncRemainingCounter'),
				syncProcessIsDone
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Clients à synchroniser avec O2S : ')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$strong,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$elm$core$String$fromInt(
							clientToSynchronise(model.ca)))
					]))
			]));
	return model.k.e8 ? syncBox : $rtfeldman$elm_css$Html$Styled$text('');
};
var $author$project$Components$Clients$List$Update$FilterExtToolClients = function (a) {
	return {$: 8, a: a};
};
var $author$project$Components$Clients$List$Update$ValidExtToolClientChoice = F2(
	function (a, b) {
		return {$: 10, a: a, b: b};
	});
var $author$project$Components$Clients$List$Model$CreateNew = {$: 0};
var $author$project$Components$Clients$List$Update$SetClientFromExtTool = function (a) {
	return {$: 9, a: a};
};
var $author$project$Components$Clients$List$Model$SyncWith = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Clients$List$View$extClient = F2(
	function (chosenClientId, extClientData) {
		var _v0 = extClientData;
		var clientId = _v0.a;
		var clientName = _v0.b;
		var chosenClass = $rtfeldman$elm_css$Html$Styled$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'chosen',
					A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							$elm$core$Basics$eq(clientId),
							chosenClientId)))
				]));
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('item')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('cartouche-user'),
							chosenClass,
							$rtfeldman$elm_css$Html$Styled$Events$onClick(
							$author$project$Components$Clients$List$Update$SetClientFromExtTool(
								$author$project$Components$Clients$List$Model$SyncWith(clientId)))
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('main')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('logo')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('O2S')
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('title')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(clientName)
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('aside')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('chosen')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Sélectionné')
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('action')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$span,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Sélectionner')
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Components$Clients$List$View$extClientsSelector = function (clientToSync) {
	var createLabel = function () {
		var _v3 = clientToSync.g$;
		return 'Créer un nouveau contact sur 02S';
	}();
	var _v0 = function () {
		var _v1 = clientToSync.e5;
		if (!_v1.$) {
			if (!_v1.a.$) {
				var _v2 = _v1.a;
				return _Utils_Tuple2(
					$elm$core$Maybe$Nothing,
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('chosen', true)
							])));
			} else {
				var id = _v1.a.a;
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(id),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('chosen', false)
							])));
			}
		} else {
			return _Utils_Tuple2(
				$elm$core$Maybe$Nothing,
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('chosen', false)
						])));
		}
	}();
	var chosenClientId = _v0.a;
	var newChosenClass = _v0.b;
	return A2(
		$rtfeldman$elm_css$Html$Styled$ul,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('extClients'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('list'),
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('loading', clientToSync.eQ)
					]))
			]),
		_Utils_ap(
			A2(
				$elm$core$List$map,
				$author$project$Components$Clients$List$View$extClient(chosenClientId),
				clientToSync.c7),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$li,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('create')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$button,
							_List_fromArray(
								[
									newChosenClass,
									$rtfeldman$elm_css$Html$Styled$Events$onClick(
									$author$project$Components$Clients$List$Update$SetClientFromExtTool($author$project$Components$Clients$List$Model$CreateNew))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(createLabel)
								]))
						]))
				])));
};
var $author$project$Components$Clients$List$View$synchroniseClientDrawerContent = function (clientToSync) {
	var extSystparams = function () {
		var _v1 = clientToSync.g$;
		return {
			d8: _List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Choisissez le contact 02S correspondant à ce Client.'),
					$rtfeldman$elm_css$Html$Styled$text(' S\'il n\'y en a aucun, indiquez de le créer sur 02S.')
				]),
			nz: '02S'
		};
	}();
	var _v0 = A2(
		$elm$core$Maybe$withDefault,
		_Utils_Tuple2(
			$rtfeldman$elm_css$Html$Styled$Attributes$disabled(true),
			$rtfeldman$elm_css$Html$Styled$Attributes$class('')),
		A2(
			$elm$core$Maybe$map,
			function (syncChoice) {
				return _Utils_Tuple2(
					$rtfeldman$elm_css$Html$Styled$Attributes$disabled(false),
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
						A2($author$project$Components$Clients$List$Update$ValidExtToolClientChoice, clientToSync, syncChoice)));
			},
			clientToSync.e5));
	var disabledButton = _v0.a;
	var clickButton = _v0.b;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('drawer-client-synchronise')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h2,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Synchroniser ' + (clientToSync.ga.nz + (' avec ' + extSystparams.nz)))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-info')
					]),
				extSystparams.d8),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Rechercher par nom du client')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$input,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class('search'),
								$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Components$Clients$List$Update$FilterExtToolClients),
								$rtfeldman$elm_css$Html$Styled$Attributes$value(clientToSync.eC)
							]),
						_List_Nil)
					])),
				$author$project$Components$Clients$List$View$extClientsSelector(clientToSync),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('drawerActions')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$button,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('button primary done'),
								disabledButton,
								clickButton
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Valider')
							]))
					]))
			]));
};
var $author$project$Components$Clients$List$View$synchroniseClientDrawer = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		A2($rtfeldman$elm_css$Html$Styled$span, _List_Nil, _List_Nil),
		A2(
			$elm$core$Maybe$map,
			function (c) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$map,
					$author$project$Components$Clients$List$Update$SyncDrawerEvents(0),
					A2(
						$author$project$Components$Drawer$View$view,
						c.eq,
						$author$project$Components$Clients$List$View$synchroniseClientDrawerContent(c)));
			},
			model.k.ai));
};
var $author$project$Components$Clients$List$View$view = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('page-clients-list')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('top')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('top-content')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$h1,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Mes clients')
									])),
								$author$project$Components$Clients$List$View$syncRemainingCounter(model)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
					]),
				_List_fromArray(
					[
						$author$project$Components$Clients$List$View$filters(model),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('main')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$ul,
								_List_Nil,
								$author$project$Components$Clients$List$View$displayClients(model))
							]))
					])),
				$author$project$Components$Clients$List$View$synchroniseClientDrawer(model),
				$author$project$Components$Clients$List$View$endFirstSynchroDrawer(model),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('fixed')
					]),
				$author$project$Components$Clients$List$View$actionButtons(model))
			]));
};
var $author$project$Components$Landing$View$gendoc = function (p) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$li,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('item')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('cartouche-doc'),
						$rtfeldman$elm_css$Html$Styled$Attributes$href('/gendoc/generatedoc/' + p.kx)
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('main')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('logo')
									]),
								_List_Nil),
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('title')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(p.nz)
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('aside')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('action')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$span,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Générer')
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Components$Landing$View$sectionDocuments = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$section,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('documents')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h2,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Générer un document')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$ul,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('list')
					]),
				A2($elm$core$List$map, $author$project$Components$Landing$View$gendoc, model.n3)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('more')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('button primary'),
								$rtfeldman$elm_css$Html$Styled$Attributes$href('/gendoc/generatedoc')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Tout autre document')
							]))
					]))
			]));
};
var $author$project$Components$Landing$View$formatBigNumber = F2(
	function (n, p) {
		formatBigNumber:
		while (true) {
			var b = n / 1000;
			var a = _List_fromArray(
				['k', 'M', 'G', 'P', 'E', 'Z', 'Y']);
			if (b > 1000) {
				var $temp$n = b,
					$temp$p = p + 1;
				n = $temp$n;
				p = $temp$p;
				continue formatBigNumber;
			} else {
				return _Utils_ap(
					$elm$core$String$fromInt(
						$elm$core$Basics$floor(b)),
					A2(
						$elm$core$Maybe$withDefault,
						'_',
						A2($elm_community$list_extra$List$Extra$getAt, p, a)));
			}
		}
	});
var $author$project$Components$Landing$View$makeCard = F3(
	function (name, link, children) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('item')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('card')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('card-top')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$h3,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(name)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$a,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$href(link)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Voir')
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('card-content')
								]),
							children)
						]))
				]));
	});
var $author$project$Components$Landing$View$sectionDriveActivity = function (model) {
	var cardProduction = A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			function (m) {
				return _List_fromArray(
					[
						A3(
						$author$project$Components$Landing$View$makeCard,
						'Ma production',
						'/activites/production',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								A2($author$project$Components$Landing$View$formatBigNumber, m, 0))
							]))
					]);
			},
			model.n6));
	var cardClient = A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			function (m) {
				return _List_fromArray(
					[
						A3(
						$author$project$Components$Landing$View$makeCard,
						'Mes clients',
						'/activites/clients',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								$elm$core$String$fromInt(m))
							]))
					]);
			},
			model.lC));
	var cardCA = A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			function (m) {
				return _List_fromArray(
					[
						A3(
						$author$project$Components$Landing$View$makeCard,
						'Mon chiffre d\'affaires',
						'/activites/chiffre_affaire',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(
								A2($author$project$Components$Landing$View$formatBigNumber, m, 0))
							]))
					]);
			},
			model.lh));
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			function (s) {
				return _List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$section,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('activity')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$h2,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Piloter mon activité')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$ul,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('list')
									]),
								$mgold$elm_nonempty_list$List$Nonempty$toList(s))
							]))
					]);
			},
			$mgold$elm_nonempty_list$List$Nonempty$fromList(
				_Utils_ap(
					cardClient,
					_Utils_ap(cardCA, cardProduction)))));
};
var $author$project$Components$Landing$View$sectionTools = A2(
	$rtfeldman$elm_css$Html$Styled$section,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$class('tools')
		]),
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('tools-content')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Mes outils')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$ul,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$li,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('cgp-conseils')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$a,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$href('https://www.cgp-conseils.fr'),
											$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('CGP Conseils')
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$li,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('fidroit')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$a,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$href('https://fidnet.fidroit.fr'),
											$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Fidroit')
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$li,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('o2s')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$a,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$href('https://www.office2s.com/office2s'),
											$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('O2S')
										]))
								]))
						]))
				]))
		]));
var $author$project$Components$Landing$View$view = function (model) {
	return _List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('top')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h1,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Que peut-on faire pour vous ?')
						]))
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
				]),
			_List_fromArray(
				[
					$author$project$Components$Landing$View$sectionDocuments(model),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					$author$project$Components$Landing$View$sectionDriveActivity(model)),
					$author$project$Components$Landing$View$sectionTools
				])),
			$author$project$Components$Website$FooterView$view
		]);
};
var $author$project$Components$Produits$Details$Update$Save = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Components$Produits$Details$Update$ToggleDispoALaGeneration = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$Update$UpdateProduit = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Produits$Details$Documents$Update$CreateDocument = {$: 3};
var $author$project$Components$Produits$Details$View$barreDuBasDocuments = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		$rtfeldman$elm_css$Html$Styled$text(''),
		A2(
			$elm$core$Maybe$map,
			function (docModel) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$id('fixed')
						]),
					function () {
						var _v0 = docModel.fq;
						if (!_v0.$) {
							return _List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$button,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('add'),
											$rtfeldman$elm_css$Html$Styled$Events$onClick(
											$author$project$Components$Produits$Details$Update$DocumentsActions($author$project$Components$Produits$Details$Documents$Update$CreateDocument))
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Ajouter un document')
										]))
								]);
						} else {
							return _List_Nil;
						}
					}());
			},
			model.gO));
};
var $author$project$Components$Produits$Details$View$actions = function (model) {
	var saveAction = function (link) {
		return A2(
			$elm$core$Maybe$withDefault,
			$rtfeldman$elm_css$Html$Styled$Attributes$class(''),
			A2(
				$elm$core$Maybe$map,
				function (p) {
					return $rtfeldman$elm_css$Html$Styled$Events$onClick(
						A2($author$project$Components$Produits$Details$Update$Save, link, p));
				},
				model.jc));
	};
	var canSaveProduit = $elm$core$List$isEmpty(model.kC) && (!model.ip);
	var barreDuBasFicheProduit = A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('fixed')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('toggleButton')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$input,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('dispoGeneration'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('checkbox'),
								$rtfeldman$elm_css$Html$Styled$Attributes$checked(model.i8.lX),
								$rtfeldman$elm_css$Html$Styled$Events$onInput(
								A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$Update$UpdateProduit, $author$project$Components$Produits$Details$Update$ToggleDispoALaGeneration))
							]),
						_List_Nil),
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for('dispoGeneration')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('unchecked')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Non disponible pour la génération')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('checked')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Disponible pour la génération')
									]))
							]))
					])),
				A2(
				$elm$core$Maybe$withDefault,
				$rtfeldman$elm_css$Html$Styled$text(''),
				A2(
					$elm$core$Maybe$map,
					function (link) {
						return A2(
							$rtfeldman$elm_css$Html$Styled$button,
							_List_fromArray(
								[
									saveAction(link),
									$rtfeldman$elm_css$Html$Styled$Attributes$class('save'),
									$rtfeldman$elm_css$Html$Styled$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('saving', model.jE)
										])),
									$rtfeldman$elm_css$Html$Styled$Attributes$disabled(!canSaveProduit)
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Sauvegarder')
								]));
					},
					model.eg))
			]));
	var _v0 = model.e6;
	switch (_v0) {
		case 0:
			return barreDuBasFicheProduit;
		case 1:
			return barreDuBasFicheProduit;
		case 2:
			return barreDuBasFicheProduit;
		case 3:
			return barreDuBasFicheProduit;
		case 4:
			return $rtfeldman$elm_css$Html$Styled$text('');
		default:
			return $author$project$Components$Produits$Details$View$barreDuBasDocuments(model);
	}
};
var $author$project$Components$Produits$Details$Documents$Update$SetDateProduction = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$Produits$Details$Documents$Update$SetNomDocument = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Produits$Details$Documents$Update$SetTypes = function (a) {
	return {$: 9, a: a};
};
var $author$project$Components$Produits$Details$Documents$EditDrawer$displayMissingField = function (_v0) {
	return function (_v1) {
		return $elm$core$Maybe$Nothing;
	};
};
var $author$project$Components$Produits$Details$Documents$Update$SelectFile = {$: 10};
var $author$project$Components$Produits$Details$Documents$EditDrawer$displayFile = function (file) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$span,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$title(
				$elm$file$File$name(file))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(
				$elm$file$File$name(file))
			]));
};
var $author$project$Components$Produits$Details$Documents$EditDrawer$fileInput = function (document) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('upload')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('cartoucheFile')
					]),
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$withDefault,
						$rtfeldman$elm_css$Html$Styled$text(''),
						A2($elm$core$Maybe$map, $author$project$Components$Produits$Details$Documents$EditDrawer$displayFile, document.ew))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$button,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Produits$Details$Documents$Update$SelectFile)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Sélectionner le fichier à uploader')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-warning')
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('La taille du fichier ne doit pas excéder 50 Mo')
					]))
			]));
};
var $author$project$Common$FormElements$decorator = F2(
	function (_v0, itemAttrs) {
		return itemAttrs;
	});
var $author$project$Common$FormElements$displayMissingField = function (_v0) {
	return function (_v1) {
		return $elm$core$Maybe$Nothing;
	};
};
var $author$project$Common$FormElements$inputForm_ = F4(
	function (domId, domLabel, inputType, setter) {
		return A8($author$project$Perfimmo$Form$FormView$inputForm, _List_Nil, 0, $author$project$Common$FormElements$displayMissingField, $author$project$Common$FormElements$decorator, inputType, domId, domLabel, setter);
	});
var $author$project$Perfimmo$Form$FormView$listSelectForm = F6(
	function (errors, displayMissingFieldError, currentVal, _v0, _v1, fieldBuilder) {
		var domId = _v0;
		var domLabel = _v1;
		var errorClass = $elm_community$maybe_extra$Maybe$Extra$toList(
			A2(
				$elm$core$Maybe$map,
				function (_v2) {
					return $rtfeldman$elm_css$Html$Styled$Attributes$class('error');
				},
				A2(
					displayMissingFieldError,
					fieldBuilder(
						$elm$core$Dict$fromList(_List_Nil)),
					errors)));
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_Utils_ap(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('listSelectForm')
					]),
				errorClass),
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$withDefault,
					$rtfeldman$elm_css$Html$Styled$text(''),
					A2(
						$elm$core$Maybe$map,
						function (elements) {
							return A2(
								$rtfeldman$elm_css$Html$Styled$ul,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('listSelectForm')
									]),
								elements);
						},
						A2(
							$elm$core$Maybe$map,
							function (elements) {
								return A2(
									$elm$core$List$map,
									function (item) {
										var title = item.a.a;
										var selected = item.b;
										var key = item.a.b;
										var onClickUpdate = $elm$core$Dict$fromList(
											A2(
												$elm$core$List$map,
												function (element) {
													var actualK = element.a.b;
													return _Utils_eq(actualK, key) ? _Utils_Tuple2(
														_Utils_Tuple2(title, key),
														!element.b) : element;
												},
												elements));
										var classVal = selected ? 'selected' : 'not_selected';
										return A2(
											$rtfeldman$elm_css$Html$Styled$li,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class(classVal),
													$rtfeldman$elm_css$Html$Styled$Events$onClick(
													fieldBuilder(onClickUpdate))
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(item.a.a)
												]));
									},
									elements);
							},
							A2(
								$elm$core$Maybe$map,
								function (elements) {
									return $elm$core$Dict$toList(elements);
								},
								$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(currentVal)))))
				]));
	});
var $author$project$Components$Produits$Details$Documents$EditDrawer$nameIsForbidden = function (editModel) {
	return A2(
		$elm$core$List$member,
		A2(
			$elm$core$Maybe$withDefault,
			'',
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(editModel.dl)),
		editModel.mu);
};
var $author$project$Components$Produits$Details$Documents$Update$UpdateDocumentMetadata = function (a) {
	return {$: 16, a: a};
};
var $author$project$Components$Produits$Details$Documents$EditDrawer$updateButton = F2(
	function (editModel, uploadOrUpdateLink) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$button,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('button primary done'),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('loading', editModel.aG)
						])),
					A2(
					$elm$core$Maybe$withDefault,
					$rtfeldman$elm_css$Html$Styled$Attributes$disabled(true),
					A2(
						$elm$core$Maybe$map,
						function (clickAction) {
							return editModel.aG ? $rtfeldman$elm_css$Html$Styled$Attributes$disabled(true) : clickAction;
						},
						A4(
							$elm$core$Maybe$map3,
							F3(
								function (link, _v0, _v1) {
									return $rtfeldman$elm_css$Html$Styled$Events$onClick(
										$author$project$Components$Produits$Details$Documents$Update$UpdateDocumentMetadata(link));
								}),
							uploadOrUpdateLink,
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(editModel.dl),
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(editModel.ek))))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Valider')
				]));
	});
var $author$project$Components$Produits$Details$Documents$Update$UploadFile = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$Components$Produits$Details$Documents$EditDrawer$uploadButton = F2(
	function (editModel, uploadOrUpdateLink) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$button,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('button primary done'),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('loading', editModel.aG)
						])),
					A2(
					$elm$core$Maybe$withDefault,
					$rtfeldman$elm_css$Html$Styled$Attributes$disabled(true),
					A2(
						$elm$core$Maybe$map,
						function (clickAction) {
							return (editModel.aG || $author$project$Components$Produits$Details$Documents$EditDrawer$nameIsForbidden(editModel)) ? $rtfeldman$elm_css$Html$Styled$Attributes$disabled(true) : clickAction;
						},
						A5(
							$elm$core$Maybe$map4,
							F4(
								function (file, link, _v0, _v1) {
									return $rtfeldman$elm_css$Html$Styled$Events$onClick(
										A2($author$project$Components$Produits$Details$Documents$Update$UploadFile, link, file));
								}),
							editModel.ew,
							uploadOrUpdateLink,
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(editModel.dl),
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(editModel.ek))))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Valider')
				]));
	});
var $author$project$Components$Produits$Details$Documents$EditDrawer$createDocumentDrawerContent = F2(
	function (createOrUpdateLink, editModel) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('drawer-ged-edit-document')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							function () {
							var _v0 = editModel.dj;
							if (!_v0) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$h2,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Ajouter un document')
										]));
							} else {
								return A2(
									$rtfeldman$elm_css$Html$Styled$h2,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Modifier le document')
										]));
							}
						}(),
							function () {
							var _v1 = editModel.dj;
							if (_v1 === 1) {
								return $rtfeldman$elm_css$Html$Styled$text('');
							} else {
								return $author$project$Components$Produits$Details$Documents$EditDrawer$fileInput(editModel);
							}
						}(),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
								]),
							_List_fromArray(
								[
									A4(
									$author$project$Common$FormElements$inputForm_,
									'nomDocument',
									'Nom du document',
									$author$project$Perfimmo$Form$FormModel$InputText(editModel.dl),
									$author$project$Components$Produits$Details$Documents$Update$SetNomDocument)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
								]),
							_List_fromArray(
								[
									A4(
									$author$project$Common$FormElements$inputForm_,
									'dateProduction',
									'Date de production',
									$author$project$Perfimmo$Form$FormModel$InputDate(editModel.ek),
									$author$project$Components$Produits$Details$Documents$Update$SetDateProduction)
								])),
							function () {
							var _v2 = editModel.dj;
							if (!_v2) {
								return $rtfeldman$elm_css$Html$Styled$text('');
							} else {
								return A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item disabled')
										]),
									_List_fromArray(
										[
											A4(
											$author$project$Common$FormElements$inputForm_,
											'dateProduction',
											'Date de publication',
											A2(
												$author$project$Perfimmo$Form$FormModel$DecoratedInput,
												$author$project$Perfimmo$Form$FormModel$InputDate(editModel.lK),
												_List_fromArray(
													[$author$project$Perfimmo$Dom$Utils$DisabledAttr])),
											$author$project$Components$Produits$Details$Documents$Update$SetDateProduction)
										]));
							}
						}(),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Perfimmo$Dom$Utils$formItem,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$label,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Tags')
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
												]),
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$map,
													$author$project$Components$Produits$Details$Documents$Update$SetTags,
													$rtfeldman$elm_css$Html$Styled$fromUnstyled(
														$inkuzmin$elm_multiselect$Multiselect$view(editModel.oK)))
												]))
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Perfimmo$Dom$Utils$formItem,
									_List_Nil,
									_List_fromArray(
										[
											A6($author$project$Perfimmo$Form$FormView$listSelectForm, _List_Nil, $author$project$Components$Produits$Details$Documents$EditDrawer$displayMissingField, editModel.kw, 'documentType', 'Type de document', $author$project$Components$Produits$Details$Documents$Update$SetTypes)
										]))
								])),
							A2(
							$elm$core$Maybe$withDefault,
							$rtfeldman$elm_css$Html$Styled$text(''),
							A2(
								$elm$core$Maybe$map,
								function (error) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-error')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(error)
											]));
								},
								editModel.l9)),
							$author$project$Components$Produits$Details$Documents$EditDrawer$nameIsForbidden(editModel) ? A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-error')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Ce nom de document est déja utilisé')
								])) : $rtfeldman$elm_css$Html$Styled$text(''),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('drawerActions')
								]),
							_List_fromArray(
								[
									function () {
									var _v3 = editModel.dj;
									if (!_v3) {
										return A2($author$project$Components$Produits$Details$Documents$EditDrawer$uploadButton, editModel, createOrUpdateLink);
									} else {
										return A2($author$project$Components$Produits$Details$Documents$EditDrawer$updateButton, editModel, createOrUpdateLink);
									}
								}()
								]))
						]))
				]));
	});
var $author$project$Components$Produits$Details$View$createDocumentDrawerView = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		$rtfeldman$elm_css$Html$Styled$text(''),
		A2(
			$elm$core$Maybe$map,
			function (docModel) {
				var _v0 = docModel._;
				switch (_v0.$) {
					case 0:
						return $rtfeldman$elm_css$Html$Styled$text('');
					case 2:
						var doc = _v0.a;
						var editModel = _v0.b;
						var updateLink = doc.l6;
						return A2(
							$rtfeldman$elm_css$Html$Styled$map,
							A2($elm$core$Basics$composeR, $author$project$Components$Produits$Details$Documents$Update$DrawerEvents, $author$project$Components$Produits$Details$Update$DocumentsActions),
							A2(
								$author$project$Components$Drawer$View$view,
								docModel.ep,
								A2($author$project$Components$Produits$Details$Documents$EditDrawer$createDocumentDrawerContent, doc.fp, editModel)));
					default:
						var editModel = _v0.a;
						return A2(
							$rtfeldman$elm_css$Html$Styled$map,
							A2($elm$core$Basics$composeR, $author$project$Components$Produits$Details$Documents$Update$DrawerEvents, $author$project$Components$Produits$Details$Update$DocumentsActions),
							A2(
								$author$project$Components$Drawer$View$view,
								docModel.ep,
								A2($author$project$Components$Produits$Details$Documents$EditDrawer$createDocumentDrawerContent, docModel.fq, editModel)));
				}
			},
			model.gO));
};
var $author$project$Components$Produits$Details$Model$Documents = 5;
var $author$project$Components$Produits$Details$Model$MarcheCible = 1;
var $author$project$Components$Produits$Details$Model$PEF = 3;
var $author$project$Components$Produits$Details$Model$SupportsEtCommissions = 4;
var $author$project$Components$Produits$Details$Model$Transfert = 2;
var $author$project$Components$Produits$Details$Update$SetInformations = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$View$toMsg = function (msg) {
	return $elm$core$List$map(
		$rtfeldman$elm_css$Html$Styled$map(msg));
};
var $author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetClassification = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetFamille = function (a) {
	return {$: 8, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetSousClassification = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$InformationsSetter$ToggleOpc = function (a) {
	return {$: 5, a: a};
};
var $author$project$Common$FormElements$selectForm_ = F4(
	function (domId, domLabel, formSelect, setter) {
		var clearable = function (_v0) {
			return false;
		};
		return A9($author$project$Perfimmo$Form$FormView$selectForm, _List_Nil, 0, $author$project$Common$FormElements$displayMissingField, $author$project$Common$FormElements$decorator, clearable, domId, domLabel, formSelect, setter);
	});
var $author$project$Components$Produits$Details$ViewForm$Onglets$Informations$viewClassification = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
				$rtfeldman$elm_css$Html$Styled$Attributes$id('informationsClassification')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h2,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Classification')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
					]),
				_List_fromArray(
					[
						A4($author$project$Common$FormElements$selectForm_, 'activite', 'Activité', model.f9, $author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetClassification),
						A4($author$project$Common$FormElements$selectForm_, 'famille', 'Famille', model.g0, $author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetFamille),
						A4($author$project$Common$FormElements$selectForm_, 'sousClassification', 'Sous-classification', model.jZ, $author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetSousClassification),
						A4(
						$author$project$Common$FormElements$inputForm_,
						'opc',
						'Organisme de Placement Collectif',
						$author$project$Perfimmo$Form$FormModel$BooleanSwitch(model.ix),
						$author$project$Components$Produits$Details$FormSetter$InformationsSetter$ToggleOpc)
					]))
			]));
};
var $author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetNomProduit = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetNoteRisque = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetProducteur = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$InformationsSetter$ToggleValideProducteur = function (a) {
	return {$: 4, a: a};
};
var $author$project$Perfimmo$Form$FormView$dataListForm = F9(
	function (errors, blockState, displayMissingFieldError, decorator, selectIsClearable, _v0, _v1, datalistField, fieldBuilder) {
		var domId = _v0;
		var domLabel = _v1;
		var linkToDomId = _List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class(domId)
			]);
		var itemAttributes = A3(
			$author$project$Perfimmo$Form$FormView$calculFormItemAttributes,
			blockState,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$getInfos(datalistField),
			decorator);
		var errorClass = $elm_community$maybe_extra$Maybe$Extra$toList(
			A2(
				$elm$core$Maybe$map,
				function (_v5) {
					return $rtfeldman$elm_css$Html$Styled$Attributes$class('error');
				},
				A2(
					displayMissingFieldError,
					fieldBuilder(''),
					errors)));
		var _v2 = $PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$getSelectValues(datalistField);
		var val = _v2.a;
		var availablesValues = _v2.b;
		var _v3 = function () {
			if (!val.$) {
				if (!val.a.$) {
					var x = val.a.a;
					return selectIsClearable(
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$getInfos(datalistField)) ? _Utils_Tuple2(
						' hidden',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(x)
							])) : _Utils_Tuple2(
						'',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(x)
							]));
				} else {
					var s = val.a.a;
					return selectIsClearable(
						$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormDatalist$getInfos(datalistField)) ? _Utils_Tuple2(
						' hidden',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(s)
							])) : _Utils_Tuple2(
						'',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$value(s)
							]));
				}
			} else {
				return _Utils_Tuple2(
					' hidden',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$value('')
						]));
			}
		}();
		var clearable = _v3.a;
		var valueOnInput = _v3.b;
		return A2(
			$author$project$Perfimmo$Dom$Utils$formItem,
			_Utils_ap(
				itemAttributes.m,
				_Utils_ap(errorClass, linkToDomId)),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$label,
					_Utils_ap(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$for(domId)
							]),
						itemAttributes.x),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(domLabel)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('clearableSelect')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$input,
							_Utils_ap(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id(domId),
										$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
										$rtfeldman$elm_css$Html$Styled$Attributes$list(domId + '_list'),
										$rtfeldman$elm_css$Html$Styled$Events$onInput(fieldBuilder)
									]),
								_Utils_ap(valueOnInput, itemAttributes.a1)),
							_List_Nil),
							A2(
							$rtfeldman$elm_css$Html$Styled$datalist,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$id(domId + '_list')
								]),
							A2(
								$elm$core$List$map,
								function (v) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$option,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$value(v)
											]),
										_List_Nil);
								},
								availablesValues)),
							A2(
							$rtfeldman$elm_css$Html$Styled$button,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('clearSelect' + clearable),
									$rtfeldman$elm_css$Html$Styled$Events$onClick(
									fieldBuilder(''))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('x')
								]))
						]))
				]));
	});
var $author$project$Components$Produits$Details$ViewForm$Onglets$Informations$datalistFormProducteur = F2(
	function (formDatalist, setter) {
		var domLabel = 'Producteur';
		var domId = 'producteur';
		var decorator = function (decoration) {
			return function (itemAttributes) {
				return _Utils_update(
					itemAttributes,
					{
						a1: _Utils_ap(
							itemAttributes.a1,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$disabled(true)
								]))
					});
			};
		};
		var clearable = function (infos) {
			return A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					function (_v2) {
						return true;
					},
					A2(
						$elm_community$list_extra$List$Extra$find,
						function (dec) {
							if (dec.$ === 1) {
								var _v1 = dec.a;
								return true;
							} else {
								return false;
							}
						},
						infos)));
		};
		return A9($author$project$Perfimmo$Form$FormView$dataListForm, _List_Nil, 0, $author$project$Common$FormElements$displayMissingField, decorator, clearable, domId, domLabel, formDatalist, setter);
	});
var $author$project$Components$Produits$Details$ViewForm$Onglets$Informations$viewDetails = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
				$rtfeldman$elm_css$Html$Styled$Attributes$id('informationsDetails')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
					]),
				_List_fromArray(
					[
						A4(
						$author$project$Common$FormElements$inputForm_,
						'nomProduit',
						'Nom du produit',
						function () {
							var _v0 = model.k2;
							if (!_v0.$) {
								if (!_v0.a.$) {
									return A2(
										$author$project$Perfimmo$Form$FormModel$DecoratedInput,
										$author$project$Perfimmo$Form$FormModel$InputText(model.dl),
										_List_fromArray(
											[$author$project$Perfimmo$Dom$Utils$DisabledAttr]));
								} else {
									return $author$project$Perfimmo$Form$FormModel$InputText(model.dl);
								}
							} else {
								return $author$project$Perfimmo$Form$FormModel$InputText(model.dl);
							}
						}(),
						$author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetNomProduit),
						A2($author$project$Components$Produits$Details$ViewForm$Onglets$Informations$datalistFormProducteur, model.i7, $author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetProducteur),
						A4(
						$author$project$Common$FormElements$inputForm_,
						'noteRisqueProduit',
						'Note de risque',
						$author$project$Perfimmo$Form$FormModel$InputFormInt(model.iq),
						$author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetNoteRisque),
						A4(
						$author$project$Common$FormElements$inputForm_,
						'valideProducteur',
						'Validé par le producteur',
						$author$project$Perfimmo$Form$FormModel$BooleanSwitch(model.kD),
						$author$project$Components$Produits$Details$FormSetter$InformationsSetter$ToggleValideProducteur)
					]))
			]));
};
var $author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetPresentationDeclarationAdequation = function (a) {
	return {$: 12, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetPresentationPreconisation = function (a) {
	return {$: 11, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetPresentationProducteur = function (a) {
	return {$: 10, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetPresentationProduit = function (a) {
	return {$: 9, a: a};
};
var $author$project$Components$Produits$Details$ViewForm$Onglets$Informations$viewDocumentsPartiels = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
				$rtfeldman$elm_css$Html$Styled$Attributes$id('informationsDocumentsPartiels')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h2,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Documents partiels')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
					]),
				_List_fromArray(
					[
						A4(
						$author$project$Common$FormElements$inputForm_,
						'presentationProduit',
						'Présentation du produit',
						$author$project$Perfimmo$Form$FormModel$InputText(model.iY),
						$author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetPresentationProduit),
						A4(
						$author$project$Common$FormElements$inputForm_,
						'presentationProducteur',
						'Présentation du producteur',
						$author$project$Perfimmo$Form$FormModel$InputText(model.iX),
						$author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetPresentationProducteur),
						A4(
						$author$project$Common$FormElements$inputForm_,
						'presentationCoutFrais',
						'Présentation des coûts et frais',
						$author$project$Perfimmo$Form$FormModel$InputText(model.iW),
						$author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetPresentationPreconisation),
						A4(
						$author$project$Common$FormElements$inputForm_,
						'argumentFaveurProduit',
						'Présentation des arguments en faveur du produit',
						$author$project$Perfimmo$Form$FormModel$InputText(model.gG),
						$author$project$Components$Produits$Details$FormSetter$InformationsSetter$SetPresentationDeclarationAdequation)
					]))
			]));
};
var $author$project$Components$Produits$Details$ViewForm$Onglets$Informations$view = function (model) {
	return _List_fromArray(
		[
			$author$project$Components$Produits$Details$ViewForm$Onglets$Informations$viewDetails(model),
			$author$project$Components$Produits$Details$ViewForm$Onglets$Informations$viewClassification(model),
			$author$project$Components$Produits$Details$ViewForm$Onglets$Informations$viewDocumentsPartiels(model.cf)
		]);
};
var $author$project$Components$Produits$Details$View$informationView = function (model) {
	return A2(
		$author$project$Components$Produits$Details$View$toMsg,
		$author$project$Components$Produits$Details$Update$SetInformations,
		$author$project$Components$Produits$Details$ViewForm$Onglets$Informations$view(model.i8.mZ));
};
var $author$project$Components$Produits$Details$Update$SetMarcheCible = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$AgeLimitMax = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$AgeLimitMin = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerte = function (a) {
	return {$: 11, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerteAuDelaMontants = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerteJusquaMontants = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerteLimitées = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerteNulle = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerteSignificatives = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerteValeur = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ContrepartieElligible = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CourtTerme = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$DateMAJ = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Defensif = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Dynamique = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Equilibre = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Experience = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ExperienceType = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Experimente = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Horizon = function (a) {
	return {$: 9, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$HorizonValue = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Informe = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$LongTerme = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$MoyenTerme = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$NonProfessionnel = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Novice = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Objectifs = function (a) {
	return {$: 10, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsAutre = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsConservation = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsCouverture = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsCroissance = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsOptionnelOuAEffetDeLevier = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsRendement = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsRevenus = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Offensif = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ProfessionExclues = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Professionnel = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ProfilValue = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Profils = function (a) {
	return {$: 8, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Securitaire = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Synthese = function (a) {
	return {$: 12, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$SyntheseMarcheCibleNegatifProduitParCabinet = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$SyntheseMarcheCibleProduitParCabinet = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ToggleAccessiblePersonneVulnerable = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$TresCourtTerme = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$TypeInvestisseur = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$TypeMarcheCible = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Version = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsValeur = function (a) {
	return {$: 0, a: a};
};
var $author$project$Common$FormElements$listSelectForm_ = A2($author$project$Perfimmo$Form$FormView$listSelectForm, _List_Nil, $author$project$Common$FormElements$displayMissingField);
var $author$project$Components$Produits$Details$ViewForm$Onglets$MarcheCible$objectifs = function (formField) {
	var list = A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			$elm$core$Dict$toList,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(formField)));
	var noneSelectedList = $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (objectif) {
				return _Utils_Tuple2(objectif.a, false);
			},
			list));
	var canSelectAll = _Utils_cmp(
		$elm$core$List$length(list),
		A3(
			$elm$core$List$foldl,
			function (current) {
				return function (enabledCount) {
					return current.b ? (enabledCount + 1) : enabledCount;
				};
			},
			0,
			list)) > 0;
	var allSelectedList = $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (objectif) {
				return _Utils_Tuple2(objectif.a, true);
			},
			list));
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$label,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Sélectionnez les objectifs')
					])),
				A4(
				$author$project$Common$FormElements$listSelectForm_,
				formField,
				'listObjectifs',
				'Objectifs sélectionnées',
				A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Objectifs, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsValeur)),
				canSelectAll ? A2(
				$rtfeldman$elm_css$Html$Styled$button,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('toggle-all'),
						$rtfeldman$elm_css$Html$Styled$Events$onClick(
						A3($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Objectifs, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsValeur, allSelectedList))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Tout sélectionner')
					])) : A2(
				$rtfeldman$elm_css$Html$Styled$button,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('toggle-all'),
						$rtfeldman$elm_css$Html$Styled$Events$onClick(
						A3($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Objectifs, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsValeur, noneSelectedList))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Tout désélectionner')
					]))
			]));
};
var $rtfeldman$elm_css$Html$Styled$textarea = $rtfeldman$elm_css$Html$Styled$node('textarea');
var $author$project$Perfimmo$Form$FormView$textareaForm = F7(
	function (errors, blockState, decorator, _v0, _v1, field, fieldBuilder) {
		var domId = _v0;
		var domLabel = _v1;
		var linkToDomId = _List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class(domId)
			]);
		return A2(
			$author$project$Perfimmo$Dom$Utils$formItem,
			linkToDomId,
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$label,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$for(domId)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(domLabel)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$textarea,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$id(domId),
							$rtfeldman$elm_css$Html$Styled$Events$onInput(fieldBuilder),
							$rtfeldman$elm_css$Html$Styled$Attributes$value(
							A2(
								$elm$core$Maybe$withDefault,
								'',
								$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(field)))
						]),
					_List_Nil)
				]));
	});
var $author$project$Common$FormElements$textareaForm_ = F4(
	function (domId, domLabel, field, setter) {
		return A7($author$project$Perfimmo$Form$FormView$textareaForm, _List_Nil, 0, $author$project$Common$FormElements$decorator, domId, domLabel, field, setter);
	});
var $author$project$Components$Produits$Details$ViewForm$Onglets$MarcheCible$view = function (model) {
	return _List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
					$rtfeldman$elm_css$Html$Styled$Attributes$id('marcheCibleDetails')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A4($author$project$Common$FormElements$selectForm_, 'typeMarcheCible', 'Type de marché cible', model.ku, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$TypeMarcheCible),
							A4(
							$author$project$Common$FormElements$inputForm_,
							'dateMAJ',
							'Date de mise à jour',
							$author$project$Perfimmo$Form$FormModel$InputDate(model.lJ),
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$DateMAJ),
							A4(
							$author$project$Common$FormElements$inputForm_,
							'version',
							'Version',
							$author$project$Perfimmo$Form$FormModel$InputText(model.kE),
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Version),
							A4(
							$author$project$Common$FormElements$inputForm_,
							'ageLimitMin',
							'Âge limite minimum',
							$author$project$Perfimmo$Form$FormModel$InputFormInt(model.fF.h3),
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$AgeLimitMin),
							A4(
							$author$project$Common$FormElements$inputForm_,
							'ageLimitMax',
							'Âge limite maximum',
							$author$project$Perfimmo$Form$FormModel$InputFormInt(model.fF.h0),
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$AgeLimitMax),
							A4(
							$author$project$Common$FormElements$inputForm_,
							'accessiblePersonnesVulnerables',
							'Accessible aux personnes vulnérables',
							$author$project$Perfimmo$Form$FormModel$BooleanSwitch(model.fv),
							$author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ToggleAccessiblePersonneVulnerable)
						]))
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
					$rtfeldman$elm_css$Html$Styled$Attributes$id('marcheCibleInvestisseurs')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Investisseurs')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'professionnel',
							'Arguments pour type d\'investisseur professionnel',
							model.cH.jf,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$TypeInvestisseur, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Professionnel)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'nonProfessionnel',
							'Arguments pour type d\'investisseur non professionnel',
							model.cH.$9,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$TypeInvestisseur, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$NonProfessionnel)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'contrepartieElligible',
							'Arguments pour contre-partie elligible',
							model.cH.lB,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$TypeInvestisseur, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ContrepartieElligible)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'professionExclues',
							'Arguments pour professions exclues',
							model.cH.je,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$TypeInvestisseur, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ProfessionExclues))
						]))
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
					$rtfeldman$elm_css$Html$Styled$Attributes$id('marcheCibleExperience')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Expérience')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A4(
							$author$project$Common$FormElements$selectForm_,
							'experienceValeurs',
							'Niveau d\'expérience',
							model.ci.at,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Experience, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ExperienceType)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'novice',
							'Novice',
							model.ci.nJ,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Experience, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Novice)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'informe',
							'Informé',
							model.ci.m_,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Experience, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Informe)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'experimente',
							'Expérimenté',
							model.ci.me,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Experience, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Experimente))
						]))
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
					$rtfeldman$elm_css$Html$Styled$Attributes$id('marcheCibleCapaciteSubirPerte')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Capacité à subir les pertes')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A4(
							$author$project$Common$FormElements$selectForm_,
							'capaciteSubirPerteValue',
							'Capacité à subir une perte',
							model.bi.at,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerte, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerteValeur)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'capaciteSubirPerte.nulle',
							'Arguments pour la capacité à subir une perte nulle',
							model.bi.ir,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerte, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerteNulle)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'capaciteSubirPerte.limitées',
							'Arguments pour la capacité à subir une perte limitée',
							model.bi.hW,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerte, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerteLimitées)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'capaciteSubirPerte.significatives',
							'Arguments pour la capacité à subir une perte significative',
							model.bi.jP,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerte, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerteSignificatives)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'capaciteSubirPerte.jusqua_montants',
							'Arguments pour la capacité à subir une perte jusqu\'à un montant ',
							model.bi.m7,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerte, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerteJusquaMontants)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'capaciteSubirPerte.au_dela_montants',
							'Arguments pour la capacité à subir une perte au-delà du montant',
							model.bi.k5,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerte, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CapaciteSubirPerteAuDelaMontants))
						]))
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
					$rtfeldman$elm_css$Html$Styled$Attributes$id('marcheCibleProfils')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Profils')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A4(
							$author$project$Common$FormElements$selectForm_,
							'profilValue',
							'Profil minimum requis pour l\'investisseur',
							model.a6.oa,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Profils, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ProfilValue)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'explicationProfils.securitaire',
							'Arguments pour le profil sécuritaire',
							model.a6.cj.jH,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Profils, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Securitaire)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'explicationProfils.defensif',
							'Arguments pour le profil défensif',
							model.a6.cj.gI,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Profils, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Defensif)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'explicationProfils.equilibre',
							'Arguments pour le profil équilibré',
							model.a6.cj.gY,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Profils, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Equilibre)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'explicationProfils.dynamique',
							'Arguments pour le profil dynamique',
							model.a6.cj.gT,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Profils, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Dynamique)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'explicationProfils.offensif',
							'Arguments pour le profil offensif',
							model.a6.cj.iv,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Profils, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Offensif))
						]))
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
					$rtfeldman$elm_css$Html$Styled$Attributes$id('marcheCibleObjectifs')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Objectifs')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							$author$project$Components$Produits$Details$ViewForm$Onglets$MarcheCible$objectifs(model.ap.at),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'objectifs.conservation',
							'Arguments pour l\'objectif de conservation',
							model.ap.gh,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Objectifs, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsConservation)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'objectifs.croissance',
							'Arguments pour l\'objectif de croissance',
							model.ap.gp,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Objectifs, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsCroissance)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'objectifs.rendement',
							'Arguments pour l\'objectif de rendement',
							model.ap.jp,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Objectifs, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsRendement)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'objectifs.revenus',
							'Arguments pour l\'objectif de revenus',
							model.ap.jx,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Objectifs, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsRevenus)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'objectifs.couverture',
							'Arguments pour l\'objectif de couverture',
							model.ap.gj,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Objectifs, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsCouverture)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'objectifs.optionnel_ou_à_effet_de_levier',
							'Arguments pour l\'objectif optionnel ou à effet de levier',
							model.ap.nS,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Objectifs, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsOptionnelOuAEffetDeLevier)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'objectifs.autre',
							'Arguments pour l\'objectif autre',
							model.ap.fM,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Objectifs, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$ObjectifsAutre))
						]))
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
					$rtfeldman$elm_css$Html$Styled$Attributes$id('marcheCibleHorizon')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Horizon')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A4(
							$author$project$Common$FormElements$selectForm_,
							'horizonValue',
							'Horizon',
							model.bx.at,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Horizon, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$HorizonValue)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'horizon.tres_court_terme',
							'Arguments pour l\'horizon très court terme',
							model.bx.oU,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Horizon, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$TresCourtTerme)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'horizon.court_terme',
							'Arguments pour l\'horizon court terme',
							model.bx.lE,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Horizon, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$CourtTerme)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'horizon.moyen_terme',
							'Arguments pour l\'horizon moyen terme',
							model.bx.nw,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Horizon, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$MoyenTerme)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'horizon.long_terme',
							'Arguments pour l\'horizon long terme',
							model.bx.nj,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Horizon, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$LongTerme))
						]))
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
					$rtfeldman$elm_css$Html$Styled$Attributes$id('marcheCibleSynthese')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Synthèse')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'synthese.marcheCibleProduitParCabinet',
							'Marché cible produit par cabinet',
							model.fg.no,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Synthese, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$SyntheseMarcheCibleProduitParCabinet)),
							A4(
							$author$project$Common$FormElements$textareaForm_,
							'synthese.marcheCibleNegatifProduitParCabinet',
							'Marché cible négatif produit par cabinet',
							model.fg.nn,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$Synthese, $author$project$Components$Produits$Details$FormSetter$MarcheCibleSetter$SyntheseMarcheCibleNegatifProduitParCabinet))
						]))
				]))
		]);
};
var $author$project$Components$Produits$Details$View$marcheCibleView = function (model) {
	return A2(
		$author$project$Components$Produits$Details$View$toMsg,
		$author$project$Components$Produits$Details$Update$SetMarcheCible,
		$author$project$Components$Produits$Details$ViewForm$Onglets$MarcheCible$view(model.i8.c));
};
var $author$project$Components$Produits$Details$Update$SetProfilExtraFinancier = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$DateMiseAJour = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$IncidenceNegativePaiSetter = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$InvestissementDurableSfdrSetter = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$ProfilExtraFinancierPrisEnCompte = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$TaxonomieSetter = function (a) {
	return {$: 2, a: a};
};
var $author$project$Common$FormElements$foldSwitchToView = F4(
	function (_switch, left, middle, right) {
		return A2(
			$elm$core$Maybe$withDefault,
			middle,
			A2(
				$elm$core$Maybe$map,
				function (val) {
					return (val === 1) ? left : ((val === 2) ? middle : right);
				},
				$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(_switch)));
	});
var $author$project$Perfimmo$Form$FormView$switchWithMiddle = function (errors) {
	return function (blockState) {
		return function (displayMissingFieldError) {
			return function (decorator) {
				return function (currentVal) {
					return function (formItemLabel) {
						return function (leftLabel) {
							return function (rightLabel) {
								return function (_v0) {
									return function (_v1) {
										return function (fieldBuilder) {
											var domId = _v0;
											var domLabel = _v1;
											var val = A2(
												$elm$core$Maybe$withDefault,
												2,
												$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(currentVal));
											var errorClass = $elm_community$maybe_extra$Maybe$Extra$toList(
												A2(
													$elm$core$Maybe$map,
													function (_v2) {
														return $rtfeldman$elm_css$Html$Styled$Attributes$class('error');
													},
													A2(
														displayMissingFieldError,
														fieldBuilder(2),
														errors)));
											var compName = 'tss_' + domId;
											var additionalAttributes = A3(
												$author$project$Perfimmo$Form$FormView$calculFormItemAttributes,
												blockState,
												$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getInfos(currentVal),
												decorator);
											return A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_Utils_ap(
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
														]),
													_Utils_ap(errorClass, additionalAttributes.m)),
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$label,
														_Utils_ap(
															_List_fromArray(
																[
																	$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item-label')
																]),
															additionalAttributes.a1),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text(formItemLabel)
															])),
														A2(
														$rtfeldman$elm_css$Html$Styled$div,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('switchWithMiddle')
															]),
														_List_fromArray(
															[
																A2(
																$rtfeldman$elm_css$Html$Styled$input,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$id(compName + '_1'),
																		$rtfeldman$elm_css$Html$Styled$Attributes$type_('radio'),
																		$rtfeldman$elm_css$Html$Styled$Attributes$name(compName),
																		$rtfeldman$elm_css$Html$Styled$Attributes$value(leftLabel),
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('left'),
																		$rtfeldman$elm_css$Html$Styled$Attributes$checked(val === 1),
																		$rtfeldman$elm_css$Html$Styled$Events$onClick(
																		fieldBuilder(1))
																	]),
																_List_Nil),
																A2(
																$rtfeldman$elm_css$Html$Styled$label,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$for(compName + '_1')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(leftLabel)
																	])),
																A2(
																$rtfeldman$elm_css$Html$Styled$input,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$id(compName + '_2'),
																		$rtfeldman$elm_css$Html$Styled$Attributes$type_('radio'),
																		$rtfeldman$elm_css$Html$Styled$Attributes$name(compName),
																		$rtfeldman$elm_css$Html$Styled$Attributes$value(rightLabel),
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('right'),
																		$rtfeldman$elm_css$Html$Styled$Attributes$checked(!val),
																		$rtfeldman$elm_css$Html$Styled$Events$onClick(
																		fieldBuilder(0))
																	]),
																_List_Nil),
																A2(
																$rtfeldman$elm_css$Html$Styled$label,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$for(compName + '_2')
																	]),
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text(rightLabel)
																	])),
																A2(
																$rtfeldman$elm_css$Html$Styled$input,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$Attributes$id(compName + '_3'),
																		$rtfeldman$elm_css$Html$Styled$Attributes$type_('radio'),
																		$rtfeldman$elm_css$Html$Styled$Attributes$name(compName),
																		$rtfeldman$elm_css$Html$Styled$Attributes$value('Neutre'),
																		$rtfeldman$elm_css$Html$Styled$Attributes$class('middle'),
																		$rtfeldman$elm_css$Html$Styled$Attributes$checked(val === 2),
																		$rtfeldman$elm_css$Html$Styled$Events$onClick(
																		fieldBuilder(2))
																	]),
																_List_Nil)
															]))
													]));
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Common$FormElements$switchWithMiddle_ = A4($author$project$Perfimmo$Form$FormView$switchWithMiddle, _List_Nil, 0, $author$project$Common$FormElements$displayMissingField, $author$project$Common$FormElements$decorator);
var $author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$switchableSection = F5(
	function (domId, _v0, field, fieldBuilder, content) {
		var domLabel = _v0;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionSwitchable')
				]),
			_List_fromArray(
				[
					A7($author$project$Common$FormElements$switchWithMiddle_, field, domLabel, 'Non', 'Oui', domId, domLabel, fieldBuilder),
					A4(
					$author$project$Common$FormElements$foldSwitchToView,
					field,
					$rtfeldman$elm_css$Html$Styled$text(''),
					$rtfeldman$elm_css$Html$Styled$text(''),
					content)
				]));
	});
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$PrendEnComptePai = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$IncidenceNegativePaiPctAlignement = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$SelectedPai = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$viewIncidenceNegativePaiForm = function (model) {
	var list = A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			$elm$core$Dict$toList,
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(model.hZ)));
	var noneSelectedList = $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (pai) {
				return _Utils_Tuple2(pai.a, false);
			},
			list));
	var canSelectAll = _Utils_cmp(
		$elm$core$List$length(list),
		A3(
			$elm$core$List$foldl,
			function (current) {
				return function (enabledCount) {
					return current.b ? (enabledCount + 1) : enabledCount;
				};
			},
			0,
			list)) > 0;
	var allSelectedList = $elm$core$Dict$fromList(
		A2(
			$elm$core$List$map,
			function (pai) {
				return _Utils_Tuple2(pai.a, true);
			},
			list));
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Sélectionnez les PAI')
							])),
						A4($author$project$Common$FormElements$listSelectForm_, model.hZ, 'listPai', 'PAI sélectionnées', $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$SelectedPai),
						canSelectAll ? A2(
						$rtfeldman$elm_css$Html$Styled$button,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('toggle-all'),
								$rtfeldman$elm_css$Html$Styled$Events$onClick(
								$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$SelectedPai(allSelectedList))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Tout sélectionner')
							])) : A2(
						$rtfeldman$elm_css$Html$Styled$button,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('toggle-all'),
								$rtfeldman$elm_css$Html$Styled$Events$onClick(
								$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$SelectedPai(noneSelectedList))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Tout désélectionner')
							]))
					])),
				A4(
				$author$project$Common$FormElements$inputForm_,
				'incidenceNegativePaiPctAlignement',
				'Pourcentage d\'alignement',
				$author$project$Perfimmo$Form$FormModel$InputFormPourcent(model.bK),
				$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$IncidenceNegativePaiPctAlignement)
			]));
};
var $author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$viewIncidenceNegativePai = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
				$rtfeldman$elm_css$Html$Styled$Attributes$id('pefIncidenceNegativePai')
			]),
		_List_fromArray(
			[
				A5(
				$author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$switchableSection,
				'incidenceNegativePaiPriseEnCompte',
				'Incidence négative PAI prise en compte ?',
				model.iU,
				$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$PrendEnComptePai,
				$author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$viewIncidenceNegativePaiForm(model))
			]));
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$InvestissementDurableSfdrEstAligne = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle6PctAlignement = function (a) {
	return {$: 10, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle6PctEstAligne = function (a) {
	return {$: 9, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle8PctAlignementEnvironnemental = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle8PctAlignementGouvernance = function (a) {
	return {$: 8, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle8PctAlignementSocial = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle8PctEstAligne = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle9PctAlignementEnvironnemental = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle9PctAlignementGouvernance = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle9PctAlignementSocial = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle9PctEstAligne = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$viewInvestissementDurableSfdrForm = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				A5(
				$author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$switchableSection,
				'produitArticle9',
				'Produits article 9',
				model.b2.aX,
				$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle9PctEstAligne,
				A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A4(
							$author$project$Common$FormElements$inputForm_,
							'sfdrCategoriesProduitArticle9PctAlignementEnvironnemental',
							'Pourcentage d\'alignement environnemental',
							$author$project$Perfimmo$Form$FormModel$InputFormPourcent(model.b2.dr),
							$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle9PctAlignementEnvironnemental),
							A4(
							$author$project$Common$FormElements$inputForm_,
							'sfdrCategoriesProduitArticle9PctAlignementSocial',
							'Pourcentage d\'alignement social',
							$author$project$Perfimmo$Form$FormModel$InputFormPourcent(model.b2.dt),
							$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle9PctAlignementSocial),
							A4(
							$author$project$Common$FormElements$inputForm_,
							'sfdrCategoriesProduitArticle9PctAlignementGouvernance',
							'Pourcentage d\'alignement gouvernance',
							$author$project$Perfimmo$Form$FormModel$InputFormPourcent(model.b2.ds),
							$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle9PctAlignementGouvernance)
						]))),
				A5(
				$author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$switchableSection,
				'produitArticle8',
				'Produits article 8',
				model.b1.aX,
				$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle8PctEstAligne,
				A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A4(
							$author$project$Common$FormElements$inputForm_,
							'sfdrCategoriesProduitArticle8PctAlignementEnvironnemental',
							'Pourcentage d\'alignement environnemental',
							$author$project$Perfimmo$Form$FormModel$InputFormPourcent(model.b1.dr),
							$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle8PctAlignementEnvironnemental),
							A4(
							$author$project$Common$FormElements$inputForm_,
							'sfdrCategoriesProduitArticle8PctAlignementSocial',
							'Pourcentage d\'alignement social',
							$author$project$Perfimmo$Form$FormModel$InputFormPourcent(model.b1.dt),
							$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle8PctAlignementSocial),
							A4(
							$author$project$Common$FormElements$inputForm_,
							'sfdrCategoriesProduitArticle8PctAlignementGouvernance',
							'Pourcentage d\'alignement gouvernance',
							$author$project$Perfimmo$Form$FormModel$InputFormPourcent(model.b1.ds),
							$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle8PctAlignementGouvernance)
						]))),
				A5(
				$author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$switchableSection,
				'produitArticle6',
				'Produits article 6',
				model.d3.aX,
				$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle6PctEstAligne,
				A4(
					$author$project$Common$FormElements$inputForm_,
					'sfdrCategoriesProduitArticle6PctAlignement',
					'Pourcentage d\'alignement',
					$author$project$Perfimmo$Form$FormModel$InputFormPourcent(model.d3.bK),
					$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$CategoriesProduitArticle6PctAlignement))
			]));
};
var $author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$viewInvestissementDurableSfdr = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
				$rtfeldman$elm_css$Html$Styled$Attributes$id('pefSfdr')
			]),
		_List_fromArray(
			[
				A5(
				$author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$switchableSection,
				'sfdrAligne',
				'SFDR alignée ?',
				model.aX,
				$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$InvestissementDurableSfdrEstAligne,
				$author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$viewInvestissementDurableSfdrForm(model.Z))
			]));
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$TaxonomieEstAligne = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$TaxonomiePctAlignement = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$viewTaxonomie = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
				$rtfeldman$elm_css$Html$Styled$Attributes$id('pefTaxonomie')
			]),
		_List_fromArray(
			[
				A5(
				$author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$switchableSection,
				'taxonomieAligne',
				'Taxonomie alignée ?',
				model.aX,
				$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$TaxonomieEstAligne,
				A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
						]),
					_List_fromArray(
						[
							A4(
							$author$project$Common$FormElements$inputForm_,
							'taxonomiePctAlignement',
							'Pourcentage d\'alignement',
							$author$project$Perfimmo$Form$FormModel$InputFormPourcent(model.bK),
							$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$TaxonomiePctAlignement)
						])))
			]));
};
var $author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$view = function (model) {
	return _List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('pef')
				]),
			_List_fromArray(
				[
					A5(
					$author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$switchableSection,
					'pefPrisEnCompte',
					'Prise en compte du Profil extra financier ?',
					model.i_,
					$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$ProfilExtraFinancierPrisEnCompte,
					A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$TaxonomieSetter,
								$author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$viewTaxonomie(model.fi)),
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$InvestissementDurableSfdrSetter,
								$author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$viewInvestissementDurableSfdr(model.Q)),
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$IncidenceNegativePaiSetter,
								$author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$viewIncidenceNegativePai(model.de))
							]))),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
							$rtfeldman$elm_css$Html$Styled$Attributes$id('pefUpdate')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
								]),
							_List_fromArray(
								[
									A4(
									$author$project$Common$FormElements$inputForm_,
									'pefDateMiseAJour',
									'Date de mise à jour',
									$author$project$Perfimmo$Form$FormModel$InputDate(model.ej),
									$author$project$Components$Produits$Details$FormSetter$ProfilExtraFinancierSetter$DateMiseAJour)
								]))
						]))
				]))
		]);
};
var $author$project$Components$Produits$Details$View$pefView = function (model) {
	return A2(
		$author$project$Components$Produits$Details$View$toMsg,
		$author$project$Components$Produits$Details$Update$SetProfilExtraFinancier,
		$author$project$Components$Produits$Details$ViewForm$Onglets$ProfilExtraFinancier$view(model.i8.jg));
};
var $author$project$Components$Produits$Details$Update$SetSupportTabData = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$SupportsSetter$ChangeDisplayMode = function (a) {
	return {$: 3, a: a};
};
var $author$project$Perfimmo$Input$SelectInput$ClearSelection = function (a) {
	return {$: 2, a: a};
};
var $author$project$Perfimmo$Input$SelectInput$Dropdown = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$SupportsSetter$SearchSupportsByModeDeGestion = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$SupportsSetter$SearchSupportsByNameOrSupportId = function (a) {
	return {$: 0, a: a};
};
var $author$project$Perfimmo$Input$SelectInput$SelectItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$SupportsSetter$CancelSearch = {$: 1};
var $rtfeldman$elm_css$Html$Styled$Attributes$readonly = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('readOnly');
var $author$project$Components$Produits$Details$ViewForm$Onglets$Supports$displaySupport = F2(
	function (_v0, support) {
		var chosenOne = _v0.d9;
		var displayCabData = _v0.en;
		var _v1 = _Utils_Tuple2(
			$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('chosen', chosenOne),
						_Utils_Tuple2('displayCabinetData', displayCabData)
					])),
			chosenOne ? $author$project$Components$Produits$Details$FormSetter$SupportsSetter$CancelSearch : $author$project$Components$Produits$Details$FormSetter$SupportsSetter$SearchSupportsByNameOrSupportId(support.oE));
		var supportStateClass = _v1.a;
		var supportStateEvt = _v1.b;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('support'),
					supportStateClass
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('primary')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('main'),
									$rtfeldman$elm_css$Html$Styled$Events$onClick(supportStateEvt)
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('title'),
											$rtfeldman$elm_css$Html$Styled$Attributes$title(support.nz)
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(support.nz)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('supportId')
										]),
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(support.oE)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('mode-gestion')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$span,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(
													$author$project$Components$Produits$Details$Model$modeDeGestionToString(support.h4))
												]))
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('aside')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('taux')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$input,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
													$rtfeldman$elm_css$Html$Styled$Attributes$class('percent'),
													$rtfeldman$elm_css$Html$Styled$Attributes$readonly(true),
													$rtfeldman$elm_css$Html$Styled$Attributes$value(
													A2(
														$elm$core$Maybe$withDefault,
														'',
														A2($elm$core$Maybe$map, $torreyatcitty$the_best_decimal$Decimal$toString, support.l3.oi)))
												]),
											_List_Nil),
											A2(
											$rtfeldman$elm_css$Html$Styled$input,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
													$rtfeldman$elm_css$Html$Styled$Attributes$class('percent'),
													$rtfeldman$elm_css$Html$Styled$Attributes$readonly(true),
													$rtfeldman$elm_css$Html$Styled$Attributes$value(
													A2(
														$elm$core$Maybe$withDefault,
														'',
														A2($elm$core$Maybe$map, $torreyatcitty$the_best_decimal$Decimal$toString, support.l3.oL)))
												]),
											_List_Nil)
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('actions')
										]),
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$a,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('link'),
													$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank'),
													$rtfeldman$elm_css$Html$Styled$Attributes$href(
													$author$project$Routes$Model$routes.fe.mG.dX(support.oE))
												]),
											_List_Nil)
										]))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('secondary')
						]),
					A2(
						$elm$core$List$map,
						function (cabData) {
							return A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('cabinet')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('main')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('title'),
														$rtfeldman$elm_css$Html$Styled$Attributes$title(cabData.fZ.dl)
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(cabData.fZ.dl)
													]))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('aside')
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('taux')
													]),
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$input,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
																$rtfeldman$elm_css$Html$Styled$Attributes$class('percent'),
																$rtfeldman$elm_css$Html$Styled$Attributes$readonly(true),
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																A2(
																	$elm$core$Maybe$withDefault,
																	'',
																	A2($elm$core$Maybe$map, $torreyatcitty$the_best_decimal$Decimal$toString, cabData.l2.oi)))
															]),
														_List_Nil),
														A2(
														$rtfeldman$elm_css$Html$Styled$input,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
																$rtfeldman$elm_css$Html$Styled$Attributes$class('percent'),
																$rtfeldman$elm_css$Html$Styled$Attributes$readonly(true),
																$rtfeldman$elm_css$Html$Styled$Attributes$value(
																A2(
																	$elm$core$Maybe$withDefault,
																	'',
																	A2($elm$core$Maybe$map, $torreyatcitty$the_best_decimal$Decimal$toString, cabData.l2.oL)))
															]),
														_List_Nil)
													]))
											]))
									]));
						},
						support.l4))
				]));
	});
var $rtfeldman$elm_css$Css$block = {v: 0, o6: 'block'};
var $rtfeldman$elm_css$Css$RemUnits = 0;
var $rtfeldman$elm_css$Css$rem = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'rem');
var $rtfeldman$elm_css$Css$right = $rtfeldman$elm_css$Css$prop1('right');
var $rtfeldman$elm_css$Css$textDecoration = $rtfeldman$elm_css$Css$prop1('text-decoration');
var $author$project$Perfimmo$Input$SelectInput$buttonClearStyle = function (isSelectedItem) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$css(
		_List_fromArray(
			[
				isSelectedItem ? $rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block) : $rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none),
				$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
				$rtfeldman$elm_css$Css$height(
				$rtfeldman$elm_css$Css$rem(2.4)),
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$rem(2.4)),
				$rtfeldman$elm_css$Css$top(
				$rtfeldman$elm_css$Css$rem(1.2)),
				$rtfeldman$elm_css$Css$right(
				$rtfeldman$elm_css$Css$rem(4.5)),
				$rtfeldman$elm_css$Css$lineHeight(
				$rtfeldman$elm_css$Css$rem(2.4)),
				$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$hover(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$textDecoration($rtfeldman$elm_css$Css$none)
					]))
			]));
};
var $rtfeldman$elm_css$Css$active = $rtfeldman$elm_css$Css$pseudoClass('active');
var $rtfeldman$elm_css$Css$Structure$PseudoElement = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$Preprocess$WithPseudoElement = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$pseudoElement = function (element) {
	return $rtfeldman$elm_css$Css$Preprocess$WithPseudoElement(element);
};
var $rtfeldman$elm_css$Css$after = $rtfeldman$elm_css$Css$pseudoElement('after');
var $rtfeldman$elm_css$Css$borderLeft3 = $rtfeldman$elm_css$Css$prop3('border-left');
var $rtfeldman$elm_css$Css$borderTop3 = $rtfeldman$elm_css$Css$prop3('border-top');
var $rtfeldman$elm_css$Css$calcExpressionToString = function (expression) {
	if (!expression) {
		return '+';
	} else {
		return '-';
	}
};
var $rtfeldman$elm_css$Css$calc = F3(
	function (firstExpr, expression, secondExpr) {
		var withoutCalcStr = function (l) {
			return A2($elm$core$String$startsWith, 'calc(', l.o6) ? A2($elm$core$String$dropLeft, 4, l.o6) : l.o6;
		};
		var calcs = A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					withoutCalcStr(firstExpr),
					$rtfeldman$elm_css$Css$calcExpressionToString(expression),
					withoutCalcStr(secondExpr)
				]));
		var value = A2(
			$rtfeldman$elm_css$Css$cssFunction,
			'calc',
			_List_fromArray(
				[calcs]));
		return {f$: 0, bt: 0, K: 0, y: 0, bA: 0, aF: 0, bB: 0, bC: 0, a2: 0, a3: 0, an: 0, bS: 0, o6: value};
	});
var $rtfeldman$elm_css$Css$disabled = $rtfeldman$elm_css$Css$pseudoClass('disabled');
var $rtfeldman$elm_css$Css$ellipsis = {ki: 0, o6: 'ellipsis'};
var $rtfeldman$elm_css$Css$focus = $rtfeldman$elm_css$Css$pseudoClass('focus');
var $rtfeldman$elm_css$Css$hidden = {N: 0, bF: 0, o6: 'hidden', cK: 0};
var $rtfeldman$elm_css$Css$Subtraction = 1;
var $rtfeldman$elm_css$Css$minus = 1;
var $rtfeldman$elm_css$Css$noWrap = {cl: 0, dc: 0, o6: 'nowrap', aQ: 0};
var $rtfeldman$elm_css$Css$padding4 = $rtfeldman$elm_css$Css$prop4('padding');
var $rtfeldman$elm_css$Css$textOverflow = $rtfeldman$elm_css$Css$prop1('text-overflow');
var $rtfeldman$elm_css$Css$whiteSpace = $rtfeldman$elm_css$Css$prop1('white-space');
var $author$project$Perfimmo$Input$SelectInput$buttonSelectStyle = $rtfeldman$elm_css$Html$Styled$Attributes$css(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$left),
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('000')),
			$rtfeldman$elm_css$Css$fontSize(
			$rtfeldman$elm_css$Css$rem(1.4)),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$rem(2.4)),
			$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$normal),
			A3(
			$rtfeldman$elm_css$Css$border3,
			$rtfeldman$elm_css$Css$rem(0.1),
			$rtfeldman$elm_css$Css$solid,
			$rtfeldman$elm_css$Css$hex('c0c0c0')),
			$rtfeldman$elm_css$Css$height(
			$rtfeldman$elm_css$Css$rem(4.8)),
			A4(
			$rtfeldman$elm_css$Css$padding4,
			$rtfeldman$elm_css$Css$rem(1.2),
			$rtfeldman$elm_css$Css$rem(5.2),
			$rtfeldman$elm_css$Css$rem(1.2),
			$rtfeldman$elm_css$Css$rem(2.4)),
			$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$noWrap),
			$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$hidden),
			$rtfeldman$elm_css$Css$textOverflow($rtfeldman$elm_css$Css$ellipsis),
			$rtfeldman$elm_css$Css$backgroundColor(
			$rtfeldman$elm_css$Css$hex('fff')),
			$rtfeldman$elm_css$Css$after(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
					A2($rtfeldman$elm_css$Css$property, 'content', '\'\''),
					$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
					$rtfeldman$elm_css$Css$right(
					$rtfeldman$elm_css$Css$rem(2)),
					$rtfeldman$elm_css$Css$top(
					A3(
						$rtfeldman$elm_css$Css$calc,
						$rtfeldman$elm_css$Css$pct(50),
						$rtfeldman$elm_css$Css$minus,
						$rtfeldman$elm_css$Css$rem(0.3))),
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$rem(0)),
					$rtfeldman$elm_css$Css$height(
					$rtfeldman$elm_css$Css$rem(0)),
					A3(
					$rtfeldman$elm_css$Css$borderLeft3,
					$rtfeldman$elm_css$Css$rem(0.6),
					$rtfeldman$elm_css$Css$solid,
					$rtfeldman$elm_css$Css$transparent),
					A3(
					$rtfeldman$elm_css$Css$borderRight3,
					$rtfeldman$elm_css$Css$rem(0.6),
					$rtfeldman$elm_css$Css$solid,
					$rtfeldman$elm_css$Css$transparent),
					A3(
					$rtfeldman$elm_css$Css$borderTop3,
					$rtfeldman$elm_css$Css$rem(0.6),
					$rtfeldman$elm_css$Css$solid,
					$rtfeldman$elm_css$Css$hex('808080'))
				])),
			$rtfeldman$elm_css$Css$active(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$textDecoration($rtfeldman$elm_css$Css$none)
				])),
			$rtfeldman$elm_css$Css$focus(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$textDecoration($rtfeldman$elm_css$Css$none)
				])),
			$rtfeldman$elm_css$Css$hover(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$textDecoration($rtfeldman$elm_css$Css$none)
				])),
			$rtfeldman$elm_css$Css$disabled(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$zIndex(
					$rtfeldman$elm_css$Css$int(1))
				]))
		]));
var $author$project$Perfimmo$Input$SelectInput$divStyle = $rtfeldman$elm_css$Html$Styled$Attributes$css(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative)
		]));
var $author$project$Perfimmo$Input$SelectInput$liStyle = function (selectedStyle) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$css(
		_Utils_ap(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
					$rtfeldman$elm_css$Css$color(
					$rtfeldman$elm_css$Css$hex('000')),
					$rtfeldman$elm_css$Css$fontSize(
					$rtfeldman$elm_css$Css$rem(1.4)),
					$rtfeldman$elm_css$Css$lineHeight(
					$rtfeldman$elm_css$Css$rem(2.4)),
					A2(
					$rtfeldman$elm_css$Css$padding2,
					$rtfeldman$elm_css$Css$rem(0.8),
					$rtfeldman$elm_css$Css$rem(2.4)),
					$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$noWrap),
					$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$hidden),
					$rtfeldman$elm_css$Css$textOverflow($rtfeldman$elm_css$Css$ellipsis),
					$rtfeldman$elm_css$Css$hover(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$backgroundColor(
							$rtfeldman$elm_css$Css$hex('1e90ff')),
							$rtfeldman$elm_css$Css$color(
							$rtfeldman$elm_css$Css$hex('fff'))
						]))
				]),
			selectedStyle));
};
var $rtfeldman$elm_css$Css$before = $rtfeldman$elm_css$Css$pseudoElement('before');
var $rtfeldman$elm_css$Css$margin4 = $rtfeldman$elm_css$Css$prop4('margin');
var $author$project$Perfimmo$Input$SelectInput$selectedLiStyle = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$before(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
				A2($rtfeldman$elm_css$Css$property, 'content', '\'✓\''),
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$rem(1.2)),
				$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
				A4(
				$rtfeldman$elm_css$Css$margin4,
				$rtfeldman$elm_css$Css$rem(0),
				$rtfeldman$elm_css$Css$rem(0.4),
				$rtfeldman$elm_css$Css$rem(0),
				$rtfeldman$elm_css$Css$rem(-1.6))
			]))
	]);
var $author$project$Perfimmo$Input$SelectInput$itemView = F3(
	function (selectedItem, action, _v0) {
		var k = _v0.a;
		var v = _v0.b;
		var isSelectedStyle = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Basics$eq(k),
				selectedItem)) ? $author$project$Perfimmo$Input$SelectInput$selectedLiStyle : _List_Nil;
		var isSelected = $rtfeldman$elm_css$Html$Styled$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'selected',
					A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							$elm$core$Basics$eq(k),
							selectedItem)))
				]));
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
					action(k)),
					isSelected,
					$author$project$Perfimmo$Input$SelectInput$liStyle(isSelectedStyle)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(v)
				]));
	});
var $author$project$Perfimmo$Input$SelectInput$itemsView = F3(
	function (selectedItem, options, action) {
		return A2(
			$elm$core$List$map,
			A2($author$project$Perfimmo$Input$SelectInput$itemView, selectedItem, action),
			options);
	});
var $rtfeldman$elm_css$Css$italic = {bu: 0, o6: 'italic'};
var $author$project$Perfimmo$Input$SelectInput$placeholderStyle = $rtfeldman$elm_css$Html$Styled$Attributes$css(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('abb2b9')),
			$rtfeldman$elm_css$Css$fontStyle($rtfeldman$elm_css$Css$italic)
		]));
var $author$project$Perfimmo$Input$SelectInput$ulStyle = function (showHide) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$css(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
				$rtfeldman$elm_css$Css$zIndex(
				$rtfeldman$elm_css$Css$int(1)),
				$rtfeldman$elm_css$Css$top(
				$rtfeldman$elm_css$Css$rem(4.7)),
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$pct(100)),
				$rtfeldman$elm_css$Css$backgroundColor(
				$rtfeldman$elm_css$Css$hex('f5f5f5')),
				A3(
				$rtfeldman$elm_css$Css$border3,
				$rtfeldman$elm_css$Css$rem(0.1),
				$rtfeldman$elm_css$Css$solid,
				$rtfeldman$elm_css$Css$hex('c0c0c0')),
				$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$hidden),
				showHide
			]));
};
var $author$project$Perfimmo$Input$SelectInput$privateView = F5(
	function (model, attributes, dropdownAction, selectAction, clearButton) {
		var isSelectedClass = A2(
			$elm$core$Maybe$withDefault,
			$rtfeldman$elm_css$Html$Styled$Attributes$class(''),
			A2(
				$elm$core$Maybe$map,
				function (_v2) {
					return $rtfeldman$elm_css$Html$Styled$Attributes$class('item-selected');
				},
				model.dO));
		var displaySelected = A2(
			$elm$core$Maybe$withDefault,
			A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[$author$project$Perfimmo$Input$SelectInput$placeholderStyle]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(model.cv)
					])),
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Tuple$second,
					A2(
						$elm$core$Basics$composeR,
						$rtfeldman$elm_css$Html$Styled$text,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$List$singleton,
							$rtfeldman$elm_css$Html$Styled$span(_List_Nil)))),
				A2(
					$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
					function (selectId) {
						return A2(
							$elm_community$list_extra$List$Extra$find,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$Tuple$first,
								$elm$core$Basics$eq(selectId)),
							model.aE);
					},
					model.dO)));
		var _v0 = function () {
			var _v1 = model.aV;
			if (!_v1) {
				return _Utils_Tuple2(
					$rtfeldman$elm_css$Html$Styled$Attributes$class(''),
					$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none));
			} else {
				return _Utils_Tuple2(
					$rtfeldman$elm_css$Html$Styled$Attributes$class('expanded'),
					$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block));
			}
		}();
		var expanded = _v0.a;
		var showHide = _v0.b;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id(model.hB),
					$rtfeldman$elm_css$Html$Styled$Attributes$class('select-input'),
					expanded,
					isSelectedClass,
					$author$project$Perfimmo$Input$SelectInput$divStyle
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$button,
					_Utils_ap(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onClick(
								dropdownAction(0)),
								$author$project$Perfimmo$Input$SelectInput$buttonSelectStyle,
								$rtfeldman$elm_css$Html$Styled$Attributes$class('select')
							]),
						attributes),
					_List_fromArray(
						[displaySelected])),
					clearButton,
					A2(
					$rtfeldman$elm_css$Html$Styled$ul,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('options'),
							$author$project$Perfimmo$Input$SelectInput$ulStyle(showHide)
						]),
					A3($author$project$Perfimmo$Input$SelectInput$itemsView, model.dO, model.aE, selectAction))
				]));
	});
var $author$project$Perfimmo$Input$SelectInput$view = F5(
	function (model, attributes, dropdownAction, selectAction, clearAction) {
		var clearButton = A2(
			$elm$core$Maybe$withDefault,
			$rtfeldman$elm_css$Html$Styled$text(''),
			A2(
				$elm$core$Maybe$map,
				function (_v0) {
					return A2(
						$rtfeldman$elm_css$Html$Styled$button,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('clear'),
								$author$project$Perfimmo$Input$SelectInput$buttonClearStyle(
								$elm_community$maybe_extra$Maybe$Extra$isJust(model.dO)),
								$rtfeldman$elm_css$Html$Styled$Events$onClick(
								clearAction(0))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('x')
							]));
				},
				model.dO));
		return A5($author$project$Perfimmo$Input$SelectInput$privateView, model, attributes, dropdownAction, selectAction, clearButton);
	});
var $author$project$Perfimmo$Input$SelectInput$viewWithoutClear = F5(
	function (model, attributes, dropdownAction, selectAction, clearAction) {
		return A5(
			$author$project$Perfimmo$Input$SelectInput$privateView,
			model,
			attributes,
			dropdownAction,
			selectAction,
			A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil));
	});
var $author$project$Components$Produits$Details$ViewForm$Onglets$Supports$view = function (model) {
	var displayStrategy = {
		d9: model.nP,
		en: A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				function (m) {
					return !_Utils_eq(m, $author$project$Components$Produits$Details$Model$General);
				},
				$author$project$Perfimmo$Input$SelectInput$getSelectedValue(model.lW)))
	};
	return _List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('supports')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$id('filters')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('name-supportId')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$input,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Events$onInput($author$project$Components$Produits$Details$FormSetter$SupportsSetter$SearchSupportsByNameOrSupportId),
											$rtfeldman$elm_css$Html$Styled$Attributes$class('search'),
											$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
											$rtfeldman$elm_css$Html$Styled$Attributes$value(model.e4),
											$rtfeldman$elm_css$Html$Styled$Attributes$placeholder('Filtrer par libellé support ou identifiant du support')
										]),
									_List_Nil)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('mode-gestion')
								]),
							_List_fromArray(
								[
									A5(
									$author$project$Perfimmo$Input$SelectInput$view,
									model.e3,
									_List_Nil,
									A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$SupportsSetter$SearchSupportsByModeDeGestion, $author$project$Perfimmo$Input$SelectInput$Dropdown),
									A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$SupportsSetter$SearchSupportsByModeDeGestion, $author$project$Perfimmo$Input$SelectInput$SelectItem),
									A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$SupportsSetter$SearchSupportsByModeDeGestion, $author$project$Perfimmo$Input$SelectInput$ClearSelection))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('general-cgp')
								]),
							_List_fromArray(
								[
									A5(
									$author$project$Perfimmo$Input$SelectInput$viewWithoutClear,
									model.lW,
									_List_Nil,
									A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$SupportsSetter$ChangeDisplayMode, $author$project$Perfimmo$Input$SelectInput$Dropdown),
									A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$SupportsSetter$ChangeDisplayMode, $author$project$Perfimmo$Input$SelectInput$SelectItem),
									A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$FormSetter$SupportsSetter$ChangeDisplayMode, $author$project$Perfimmo$Input$SelectInput$ClearSelection))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$id('supports-list')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('header')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$span,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Taux de rémunération maximum sur les frais de gestion du contrat reversé au distributeur')
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$span,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Taux des rétrocessions de commissions sur l\'actif')
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$ul,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('list')
								]),
							_Utils_ap(
								A2(
									$elm$core$List$map,
									function (support) {
										return A2(
											$rtfeldman$elm_css$Html$Styled$li,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('item')
												]),
											_List_fromArray(
												[
													A2($author$project$Components$Produits$Details$ViewForm$Onglets$Supports$displaySupport, displayStrategy, support)
												]));
									},
									model.mr.mD),
								_Utils_ap(
									A2(
										$elm$core$List$map,
										function (support) {
											return A2(
												$rtfeldman$elm_css$Html$Styled$li,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('item')
													]),
												_List_fromArray(
													[
														A2($author$project$Components$Produits$Details$ViewForm$Onglets$Supports$displaySupport, displayStrategy, support)
													]));
										},
										model.mr.mE),
									_Utils_ap(
										A2(
											$elm$core$List$map,
											function (support) {
												return A2(
													$rtfeldman$elm_css$Html$Styled$li,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('item')
														]),
													_List_fromArray(
														[
															A2($author$project$Components$Produits$Details$ViewForm$Onglets$Supports$displaySupport, displayStrategy, support)
														]));
											},
											model.mr.mF),
										A2(
											$elm$core$List$map,
											function (support) {
												return A2(
													$rtfeldman$elm_css$Html$Styled$li,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$class('item')
														]),
													_List_fromArray(
														[
															A2($author$project$Components$Produits$Details$ViewForm$Onglets$Supports$displaySupport, displayStrategy, support)
														]));
											},
											model.mr.mC)))))
						]))
				]))
		]);
};
var $author$project$Components$Produits$Details$View$supportsView = function (model) {
	return A2(
		$author$project$Components$Produits$Details$View$toMsg,
		$author$project$Components$Produits$Details$Update$SetSupportTabData,
		$author$project$Components$Produits$Details$ViewForm$Onglets$Supports$view(model.i8.fe));
};
var $author$project$Components$Produits$Details$View$tab = F3(
	function (model, tabId, childs) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('onglet'),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'active',
							_Utils_eq(model.e6, tabId))
						]))
				]),
			childs);
	});
var $author$project$Components$Produits$Details$Update$SetTransfert = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$FraisDuContrat = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$SupportInvestissement = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$Type = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$ViewForm$Onglets$Transfert$viewDetails = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
				$rtfeldman$elm_css$Html$Styled$Attributes$id('commercialisation')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
					]),
				_List_fromArray(
					[
						A4($author$project$Common$FormElements$selectForm_, 'commercialisationValeur', 'Commercialisation', model.at, $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$Type)
					]))
			]));
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$FraisGestionFondsEuro = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$FraisGestionUC = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$FraisTransfertSortant = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$FraisVersement = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$ViewForm$Onglets$Transfert$viewFraisContrat = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
				$rtfeldman$elm_css$Html$Styled$Attributes$id('commercialisationFraisDuContrat')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h2,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Frais du contrat')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
					]),
				_List_fromArray(
					[
						A4(
						$author$project$Common$FormElements$inputForm_,
						'fraisGestionFondsEuro',
						'Frais Gestion Fonds Euro',
						$author$project$Perfimmo$Form$FormModel$InputText(model.hf),
						$author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$FraisGestionFondsEuro),
						A4(
						$author$project$Common$FormElements$inputForm_,
						'fraisGestionUC',
						'Frais Gestion UC',
						$author$project$Perfimmo$Form$FormModel$InputText(model.hh),
						$author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$FraisGestionUC),
						A4(
						$author$project$Common$FormElements$inputForm_,
						'fraisVersement',
						'Frais Versement',
						$author$project$Perfimmo$Form$FormModel$InputText(model.hj),
						$author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$FraisVersement),
						A4(
						$author$project$Common$FormElements$inputForm_,
						'fraisTransfertSortant',
						'Frais Transfert Sortant',
						$author$project$Perfimmo$Form$FormModel$InputText(model.hi),
						$author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$FraisTransfertSortant)
					]))
			]));
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$FondsEuro = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$FraisArrerage = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$FraisGestionPhaseRente = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$NombreUc = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$SupportsImmobiliers = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$TableMortalite = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$TauxMinimumGaranti = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$TauxTechnique = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$ViewForm$Onglets$Transfert$viewSupportInvestissment = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
				$rtfeldman$elm_css$Html$Styled$Attributes$id('commercialisationSupportInvestissement')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h2,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Support d\'investissement')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
					]),
				_List_fromArray(
					[
						A4(
						$author$project$Common$FormElements$inputForm_,
						'tauxTechnique',
						'Taux Technique',
						$author$project$Perfimmo$Form$FormModel$InputText(model.ke),
						$author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$TauxTechnique),
						A4(
						$author$project$Common$FormElements$inputForm_,
						'tableMortalite',
						'Table Mortalite',
						$author$project$Perfimmo$Form$FormModel$InputText(model.j9),
						$author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$TableMortalite),
						A4(
						$author$project$Common$FormElements$inputForm_,
						'fraisGestionPhaseRente',
						'Frais Gestion Phase Rente',
						$author$project$Perfimmo$Form$FormModel$InputText(model.hg),
						$author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$FraisGestionPhaseRente),
						A4(
						$author$project$Common$FormElements$inputForm_,
						'fraisArrerage',
						'Frais Arrerage',
						$author$project$Perfimmo$Form$FormModel$InputText(model.he),
						$author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$FraisArrerage),
						A4(
						$author$project$Common$FormElements$inputForm_,
						'fondsEuro',
						'Fonds Euro',
						$author$project$Perfimmo$Form$FormModel$InputText(model.ha),
						$author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$FondsEuro),
						A4(
						$author$project$Common$FormElements$inputForm_,
						'tauxMinimumGaranti',
						'Taux Minimum Garanti',
						$author$project$Perfimmo$Form$FormModel$InputText(model.kd),
						$author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$TauxMinimumGaranti),
						A4(
						$author$project$Common$FormElements$inputForm_,
						'nombreUc',
						'Nombre UC',
						$author$project$Perfimmo$Form$FormModel$InputText(model.il),
						$author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$NombreUc),
						A4(
						$author$project$Common$FormElements$inputForm_,
						'supportsImmobiliers',
						'Supports Immobiliers',
						$author$project$Perfimmo$Form$FormModel$InputText(model.j6),
						$author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$SupportsImmobiliers)
					]))
			]));
};
var $author$project$Components$Produits$Details$ViewForm$Onglets$Transfert$view = function (model) {
	return _List_fromArray(
		[
			$author$project$Components$Produits$Details$ViewForm$Onglets$Transfert$viewDetails(model),
			A2(
			$rtfeldman$elm_css$Html$Styled$map,
			$author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$SupportInvestissement,
			$author$project$Components$Produits$Details$ViewForm$Onglets$Transfert$viewSupportInvestissment(model.as)),
			A2(
			$rtfeldman$elm_css$Html$Styled$map,
			$author$project$Components$Produits$Details$FormSetter$CommercialisationSetter$FraisDuContrat,
			$author$project$Components$Produits$Details$ViewForm$Onglets$Transfert$viewFraisContrat(model.cn))
		]);
};
var $author$project$Components$Produits$Details$View$transfertView = function (model) {
	return A2(
		$author$project$Components$Produits$Details$View$toMsg,
		$author$project$Components$Produits$Details$Update$SetTransfert,
		$author$project$Components$Produits$Details$ViewForm$Onglets$Transfert$view(model.i8.M));
};
var $author$project$Components$Produits$Details$Documents$Update$DeleteDocument = function (a) {
	return {$: 14, a: a};
};
var $author$project$Components$Produits$Details$Documents$Update$UpdateDocument = function (a) {
	return {$: 4, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Attributes$download = function (fileName) {
	return A2($rtfeldman$elm_css$Html$Styled$Attributes$stringProperty, 'download', fileName);
};
var $rtfeldman$elm_css$Svg$Styled$Attributes$title = $rtfeldman$elm_css$VirtualDom$Styled$attribute('title');
var $author$project$Components$Produits$Details$Documents$View$downloadButton = function (doc) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$a,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$disabled(
				$elm_community$maybe_extra$Maybe$Extra$isNothing(doc.l6)),
				$rtfeldman$elm_css$Html$Styled$Attributes$href(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.hA;
						},
						doc.l6))),
				$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('download'),
				$rtfeldman$elm_css$Html$Styled$Attributes$download(doc.mp),
				$rtfeldman$elm_css$Svg$Styled$Attributes$title('Télécharger le document')
			]),
		_List_Nil);
};
var $author$project$Perfimmo$Date$DisplayableDate$showMonthAsNumber = function (month) {
	switch (month) {
		case 0:
			return '01';
		case 1:
			return '02';
		case 2:
			return '03';
		case 3:
			return '04';
		case 4:
			return '05';
		case 5:
			return '06';
		case 6:
			return '07';
		case 7:
			return '08';
		case 8:
			return '09';
		case 9:
			return '10';
		case 10:
			return '11';
		default:
			return '12';
	}
};
var $author$project$Perfimmo$Date$DisplayableDate$showDateDDmmYYYY = F2(
	function (timezone, date) {
		var rawDay = A2($elm$time$Time$toDay, timezone, date);
		var showableDay = (rawDay < 10) ? ('0' + $elm$core$String$fromInt(rawDay)) : $elm$core$String$fromInt(rawDay);
		return showableDay + ('/' + ($author$project$Perfimmo$Date$DisplayableDate$showMonthAsNumber(
			A2($elm$time$Time$toMonth, timezone, date)) + ('/' + $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, timezone, date)))));
	});
var $author$project$Components$Produits$Details$Documents$View$document = F2(
	function (timezone, doc) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('cartouche-document')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('main')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('logo')
								]),
							_List_Nil),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('title')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(doc.m9)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('infos')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$ul,
									_List_Nil,
									_Utils_ap(
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$li,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(
														'Document produit le ' + A2($author$project$Perfimmo$Date$DisplayableDate$showDateDDmmYYYY, timezone, doc.lH))
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$li,
												_List_Nil,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(
														'Document publié le ' + A2($author$project$Perfimmo$Date$DisplayableDate$showDateDDmmYYYY, timezone, doc.lI))
													]))
											]),
										A2(
											$elm$core$List$map,
											function (tag) {
												return A2(
													$rtfeldman$elm_css$Html$Styled$li,
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text(tag)
														]));
											},
											doc.oK)))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('aside')
						]),
					_List_fromArray(
						[
							doc.m3 ? A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('annexeTag')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Annexe')
								])) : $rtfeldman$elm_css$Html$Styled$text(''),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('actions')
								]),
							_List_fromArray(
								[
									$author$project$Components$Produits$Details$Documents$View$downloadButton(doc),
									A2(
									$rtfeldman$elm_css$Html$Styled$button,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('edit'),
											$rtfeldman$elm_css$Svg$Styled$Attributes$title('Modifier le document'),
											$rtfeldman$elm_css$Html$Styled$Events$onClick(
											$author$project$Components$Produits$Details$Documents$Update$UpdateDocument(doc))
										]),
									_List_Nil),
									A2(
									$elm$core$Maybe$withDefault,
									$rtfeldman$elm_css$Html$Styled$text(''),
									A2(
										$elm$core$Maybe$map,
										function (link) {
											return A2(
												$rtfeldman$elm_css$Html$Styled$button,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('delete'),
														$rtfeldman$elm_css$Svg$Styled$Attributes$title('Supprimer le document'),
														$rtfeldman$elm_css$Html$Styled$Events$onClick(
														$author$project$Components$Produits$Details$Documents$Update$DeleteDocument(link))
													]),
												_List_Nil);
										},
										doc.lP))
								]))
						]))
				]));
	});
var $author$project$Components$Produits$Details$Documents$Model$Annexe = 0;
var $author$project$Components$Produits$Details$Documents$Model$FiltrerParDateDeProductionApres = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Produits$Details$Documents$Model$FiltrerParDateDeProductionAvant = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Produits$Details$Documents$Model$FiltrerParDateDePublicationApres = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Produits$Details$Documents$Model$FiltrerParDateDePublicationAvant = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Produits$Details$Documents$Model$FiltrerParNom = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$Details$Documents$Model$FiltrerParTypeDeDocument = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$Documents$View$decorator = F2(
	function (_v0, itemAttrs) {
		return itemAttrs;
	});
var $author$project$Components$Produits$Details$Documents$View$displayMissingField = function (_v0) {
	return function (_v1) {
		return $elm$core$Maybe$Nothing;
	};
};
var $author$project$Components$Produits$Details$Documents$View$filters = function (filtres) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('filters')
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Perfimmo$Dom$Utils$formItem,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Recherche par nom de document')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$input,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class('search'),
								$rtfeldman$elm_css$Html$Styled$Attributes$value(filtres.dp),
								$rtfeldman$elm_css$Html$Styled$Events$onInput(
								A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$Documents$Update$Filtrer, $author$project$Components$Produits$Details$Documents$Model$FiltrerParNom))
							]),
						_List_Nil)
					])),
				A2(
				$author$project$Perfimmo$Dom$Utils$formItem,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Filtrer par tag')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$Documents$Update$Filtrer, $author$project$Components$Produits$Details$Documents$Model$FiltrerParTags),
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(filtres.cu)))
							]))
					])),
				A2(
				$author$project$Perfimmo$Dom$Utils$formItem,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Date de production du document')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('after')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Après le')
									])),
								A8(
								$author$project$Perfimmo$Form$FormView$inputForm,
								_List_Nil,
								1,
								$author$project$Components$Produits$Details$Documents$View$displayMissingField,
								$author$project$Components$Produits$Details$Documents$View$decorator,
								$author$project$Perfimmo$Form$FormModel$InputDate(filtres.bG),
								'parDateDeProductionApres',
								'',
								A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$Documents$Update$Filtrer, $author$project$Components$Produits$Details$Documents$Model$FiltrerParDateDeProductionApres))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('before')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Avant le')
									])),
								A8(
								$author$project$Perfimmo$Form$FormView$inputForm,
								_List_Nil,
								1,
								$author$project$Components$Produits$Details$Documents$View$displayMissingField,
								$author$project$Components$Produits$Details$Documents$View$decorator,
								$author$project$Perfimmo$Form$FormModel$InputDate(filtres.bH),
								'parDateDeProductionAvant',
								'',
								A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$Documents$Update$Filtrer, $author$project$Components$Produits$Details$Documents$Model$FiltrerParDateDeProductionAvant))
							]))
					])),
				A2(
				$author$project$Perfimmo$Dom$Utils$formItem,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Date de publication du document')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('after')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Après le')
									])),
								A8(
								$author$project$Perfimmo$Form$FormView$inputForm,
								_List_Nil,
								1,
								$author$project$Components$Produits$Details$Documents$View$displayMissingField,
								$author$project$Components$Produits$Details$Documents$View$decorator,
								$author$project$Perfimmo$Form$FormModel$InputDate(filtres.bI),
								'parDateDePublicationApres',
								'',
								A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$Documents$Update$Filtrer, $author$project$Components$Produits$Details$Documents$Model$FiltrerParDateDePublicationApres))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('before')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Avant le')
									])),
								A8(
								$author$project$Perfimmo$Form$FormView$inputForm,
								_List_Nil,
								1,
								$author$project$Components$Produits$Details$Documents$View$displayMissingField,
								$author$project$Components$Produits$Details$Documents$View$decorator,
								$author$project$Perfimmo$Form$FormModel$InputDate(filtres.bJ),
								'parDateDePublicationAvant',
								'',
								A2($elm$core$Basics$composeL, $author$project$Components$Produits$Details$Documents$Update$Filtrer, $author$project$Components$Produits$Details$Documents$Model$FiltrerParDateDePublicationAvant))
							]))
					])),
				A2(
				$author$project$Perfimmo$Dom$Utils$formItem,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Type de document')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$ul,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('listSelectForm')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onClick(
										$author$project$Components$Produits$Details$Documents$Update$Filtrer(
											$author$project$Components$Produits$Details$Documents$Model$FiltrerParTypeDeDocument(0))),
										$rtfeldman$elm_css$Html$Styled$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('selected', filtres.cg)
											]))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Annexe')
									]))
							]))
					]))
			]));
};
var $author$project$Components$Produits$Details$Documents$View$view = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('documents')
			]),
		_List_fromArray(
			[
				$author$project$Components$Produits$Details$Documents$View$filters(model.aY),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('main')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$ul,
						_List_Nil,
						A2(
							$elm$core$List$map,
							function (doc) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$li,
									_List_Nil,
									_List_fromArray(
										[
											A2($author$project$Components$Produits$Details$Documents$View$document, model.aO, doc)
										]));
							},
							model.eB))
					]))
			]));
};
var $author$project$Components$Produits$Details$View$form = function (model) {
	var documentTab = A2(
		$elm$core$Maybe$withDefault,
		$rtfeldman$elm_css$Html$Styled$text(''),
		A2(
			$elm$core$Maybe$map,
			function (docModel) {
				return A3(
					$author$project$Components$Produits$Details$View$tab,
					model,
					5,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$map,
							$author$project$Components$Produits$Details$Update$DocumentsActions,
							$author$project$Components$Produits$Details$Documents$View$view(docModel))
						]));
			},
			model.gO));
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Components$Produits$Details$View$tab,
				model,
				0,
				A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$Html$Styled$map($author$project$Components$Produits$Details$Update$UpdateProduit),
					$author$project$Components$Produits$Details$View$informationView(model))),
				A3(
				$author$project$Components$Produits$Details$View$tab,
				model,
				1,
				A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$Html$Styled$map($author$project$Components$Produits$Details$Update$UpdateProduit),
					$author$project$Components$Produits$Details$View$marcheCibleView(model))),
				A3(
				$author$project$Components$Produits$Details$View$tab,
				model,
				2,
				A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$Html$Styled$map($author$project$Components$Produits$Details$Update$UpdateProduit),
					$author$project$Components$Produits$Details$View$transfertView(model))),
				A3(
				$author$project$Components$Produits$Details$View$tab,
				model,
				3,
				A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$Html$Styled$map($author$project$Components$Produits$Details$Update$UpdateProduit),
					$author$project$Components$Produits$Details$View$pefView(model))),
				A3(
				$author$project$Components$Produits$Details$View$tab,
				model,
				4,
				A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$Html$Styled$map($author$project$Components$Produits$Details$Update$UpdateProduit),
					$author$project$Components$Produits$Details$View$supportsView(model))),
				documentTab
			]));
};
var $author$project$Components$Produits$Details$Update$CancelCreate = {$: 1};
var $author$project$Components$Produits$Details$Update$SelectTab = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$Details$View$tabIdToString = function (tabId) {
	switch (tabId) {
		case 0:
			return 'Informations';
		case 1:
			return 'Marché cible';
		case 2:
			return 'Transfert';
		case 3:
			return 'Profil extra financier';
		case 4:
			return 'Supports et commissions';
		default:
			return 'Documents';
	}
};
var $author$project$Components$Produits$Details$View$tabButton = F2(
	function (model, tabId) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$button,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
					$author$project$Components$Produits$Details$Update$SelectTab(tabId)),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'active',
							_Utils_eq(model.e6, tabId))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(
					$author$project$Components$Produits$Details$View$tabIdToString(tabId))
				]));
	});
var $author$project$Components$Produits$Details$View$header = function (model) {
	var title = function () {
		var _v2 = model.dj;
		if (!_v2.$) {
			return 'Ajouter un produit';
		} else {
			return model.i8.mZ.m9;
		}
	}();
	var shouldDisplaySupportOnglet = function () {
		var _v1 = model.i8.k3;
		if (!_v1.$) {
			if (!_v1.a.$) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	}();
	var shouldDisplayDocumentsOnglet = $elm_community$maybe_extra$Maybe$Extra$isJust(model.gO);
	var duplicateTag = function () {
		var _v0 = model.i8.k3;
		if (!_v0.$) {
			if (!_v0.a.$) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('duplicateTag')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Produit dupliqué')
						]));
			} else {
				return $rtfeldman$elm_css$Html$Styled$text('');
			}
		} else {
			return $rtfeldman$elm_css$Html$Styled$text('');
		}
	}();
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('top')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$button,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('back'),
						$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Produits$Details$Update$CancelCreate)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Tous les produits')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('top-content')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$h1,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(title)
							])),
						duplicateTag
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('tabs')
					]),
				_List_fromArray(
					[
						A2($author$project$Components$Produits$Details$View$tabButton, model, 0),
						A2($author$project$Components$Produits$Details$View$tabButton, model, 1),
						A2($author$project$Components$Produits$Details$View$tabButton, model, 2),
						A2($author$project$Components$Produits$Details$View$tabButton, model, 3),
						shouldDisplaySupportOnglet ? A2($author$project$Components$Produits$Details$View$tabButton, model, 4) : $rtfeldman$elm_css$Html$Styled$text(''),
						shouldDisplayDocumentsOnglet ? A2($author$project$Components$Produits$Details$View$tabButton, model, 5) : $rtfeldman$elm_css$Html$Styled$text('')
					]))
			]));
};
var $author$project$Components$Produits$Details$View$removeDocumentDrawerView = function (model) {
	return A2(
		$elm$core$Maybe$withDefault,
		$rtfeldman$elm_css$Html$Styled$text(''),
		A2(
			$elm$core$Maybe$map,
			function (docModel) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$map,
					A2($elm$core$Basics$composeR, $author$project$Components$Produits$Details$Documents$Update$DeleteAction, $author$project$Components$Produits$Details$Update$DocumentsActions),
					A4(
						$author$project$Components$Drawer$ConfirmationDrawer$view,
						docModel.lQ,
						'Supprimer le document',
						_List_fromArray(
							['Souhaitez-vous réellement supprimer ce document ?']),
						$elm$core$Maybe$Just('La suppression est définitive et vous ne pourrez pas revenir en arrière')));
			},
			model.gO));
};
var $author$project$Components$Produits$Details$View$page = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Components$Produits$Details$View$header(model),
				model.aG ? $rtfeldman$elm_css$Html$Styled$text('') : $author$project$Components$Produits$Details$View$form(model),
				$author$project$Components$Produits$Details$View$actions(model),
				$author$project$Components$Produits$Details$View$createDocumentDrawerView(model),
				$author$project$Components$Produits$Details$View$removeDocumentDrawerView(model)
			]));
};
var $author$project$Components$Produits$Details$View$view = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('page-produit-detail'),
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('loading', model.aG)
					]))
			]),
		_List_fromArray(
			[
				$author$project$Components$Produits$Details$View$page(model)
			]));
};
var $author$project$Components$Produits$Common$ProduitsMenuView$TousLesProduits = 0;
var $author$project$Components$Produits$List$View$actionButtons = function (model) {
	return A2(
		$elm$core$Maybe$map,
		function (_v0) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$a,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$href(
						$author$project$Routes$Model$routes.n7.lF.dX(0)),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('add')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Ajouter un produit')
							]))
					]));
		},
		model.go);
};
var $author$project$Components$Produits$List$View$produitHref = function (produit) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$href(
		$author$project$Routes$Model$routes.n7.mG.dX(produit.hB));
};
var $author$project$Components$Produits$List$View$displayProduit = function (produit) {
	var duplicateTag = function () {
		var _v0 = produit.k2;
		if (!_v0.$) {
			if (!_v0.a.$) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('duplicateTag')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Produit dupliqué')
						]));
			} else {
				return $rtfeldman$elm_css$Html$Styled$text('');
			}
		} else {
			return $rtfeldman$elm_css$Html$Styled$text('');
		}
	}();
	return A2(
		$rtfeldman$elm_css$Html$Styled$a,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('cartouche-product'),
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('disponiblePourDocGen', produit.lX)
					])),
				$author$project$Components$Produits$List$View$produitHref(produit)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('main')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('logo')
							]),
						_List_Nil),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('title')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(produit.m9)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('infos')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$ul,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$li,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('classification')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(
												A2(
													$elm$core$Maybe$withDefault,
													'',
													A2($elm$core$Maybe$map, $author$project$Components$Produits$Common$Classification$classificationToString, produit.f9)))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$li,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('producteur')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(produit.i7.dl)
											]))
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('aside')
					]),
				_List_fromArray(
					[
						duplicateTag,
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('action')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Voir le produit')
									]))
							]))
					]))
			]));
};
var $author$project$Components$Produits$List$View$displayProduits = function (model) {
	return A2(
		$elm$core$List$map,
		function (produit) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$li,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Components$Produits$List$View$displayProduit(produit)
					]));
		},
		model.eE);
};
var $author$project$Components$Produits$List$Update$ByActivite = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Produits$List$Update$ByFamille = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Produits$List$Update$ByNom = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Produits$List$View$filters = function (model) {
	var produitsDupliques = $elm$core$List$length(
		A2(
			$elm$core$List$filterMap,
			function (x) {
				var _v0 = x.k2;
				if (!_v0.$) {
					if (!_v0.a.$) {
						var duplicationInfo = _v0.a.a;
						return $elm$core$Maybe$Just(duplicationInfo);
					} else {
						return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			},
			model.eE));
	var produitsNonDupliques = $elm$core$List$length(model.eE) - produitsDupliques;
	var pluriel = F2(
		function (i, s) {
			return (i > 1) ? (s + 's') : s;
		});
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('filters')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('counters')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$ul,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('facets')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$span,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('selectable-tag selected disabled')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(
												_Utils_ap(
													$elm$core$String$fromInt(produitsNonDupliques),
													A2(pluriel, produitsNonDupliques, ' produit')))
											]))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$span,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('selectable-tag selected disabled')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(
												_Utils_ap(
													$elm$core$String$fromInt(produitsDupliques),
													_Utils_ap(
														A2(pluriel, produitsDupliques, ' produit'),
														A2(pluriel, produitsDupliques, ' dupliqué'))))
											]))
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Rechercher par nom de produit')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$input,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput(
								A2($elm$core$Basics$composeL, $author$project$Components$Produits$List$Update$SearchProduits, $author$project$Components$Produits$List$Update$ByNom)),
								$rtfeldman$elm_css$Html$Styled$Attributes$class('search'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.dL)
							]),
						_List_Nil)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Filtrer par producteur')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								A2($elm$core$Basics$composeL, $author$project$Components$Produits$List$Update$SearchProduits, $author$project$Components$Produits$List$Update$ByProducteur),
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.dM)))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Filtrer par activité')
							])),
						A5(
						$author$project$Perfimmo$Input$SelectInput$view,
						model.bM,
						_List_Nil,
						A2(
							$elm$core$Basics$composeL,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$List$Update$SearchProduits, $author$project$Components$Produits$List$Update$ByActivite),
							$author$project$Perfimmo$Input$SelectInput$Dropdown),
						A2(
							$elm$core$Basics$composeL,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$List$Update$SearchProduits, $author$project$Components$Produits$List$Update$ByActivite),
							$author$project$Perfimmo$Input$SelectInput$SelectItem),
						A2(
							$elm$core$Basics$composeL,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$List$Update$SearchProduits, $author$project$Components$Produits$List$Update$ByActivite),
							$author$project$Perfimmo$Input$SelectInput$ClearSelection))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Filtrer par famille')
							])),
						A5(
						$author$project$Perfimmo$Input$SelectInput$view,
						model.bN,
						_List_Nil,
						A2(
							$elm$core$Basics$composeL,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$List$Update$SearchProduits, $author$project$Components$Produits$List$Update$ByFamille),
							$author$project$Perfimmo$Input$SelectInput$Dropdown),
						A2(
							$elm$core$Basics$composeL,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$List$Update$SearchProduits, $author$project$Components$Produits$List$Update$ByFamille),
							$author$project$Perfimmo$Input$SelectInput$SelectItem),
						A2(
							$elm$core$Basics$composeL,
							A2($elm$core$Basics$composeL, $author$project$Components$Produits$List$Update$SearchProduits, $author$project$Components$Produits$List$Update$ByFamille),
							$author$project$Perfimmo$Input$SelectInput$ClearSelection))
					]))
			]));
};
var $author$project$Components$Produits$Common$ProduitsMenuView$ImportDeDonnees = 2;
var $author$project$Components$Produits$Common$ProduitsMenuView$TousLesSupports = 1;
var $author$project$Components$Produits$Common$ProduitsMenuView$view = function (active) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('top')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h1,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Produits & Supports')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('tabs')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$href(
								$author$project$Routes$Model$routes.n7.hY.dX(0)),
								$rtfeldman$elm_css$Html$Styled$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('active', !active)
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Produits')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$href(
								$author$project$Routes$Model$routes.fe.hY.dX(0)),
								$rtfeldman$elm_css$Html$Styled$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('active', active === 1)
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Supports')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$a,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$href(
								$author$project$Routes$Model$routes.fe.mQ.dX(0)),
								$rtfeldman$elm_css$Html$Styled$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('active', active === 2)
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Imports de données')
							]))
					]))
			]));
};
var $author$project$Components$Produits$List$View$view = function (state) {
	var loading = function () {
		if (!state.$) {
			return true;
		} else {
			return false;
		}
	}();
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('page-produits-list'),
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('loading', loading)
					]))
			]),
		_List_fromArray(
			[
				$author$project$Components$Produits$Common$ProduitsMenuView$view(0),
				function () {
				if (state.$ === 1) {
					var model = state.a;
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
							]),
						_List_fromArray(
							[
								$author$project$Components$Produits$List$View$filters(model),
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('main')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$ul,
										_List_Nil,
										$author$project$Components$Produits$List$View$displayProduits(model))
									]))
							]));
				} else {
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
							]),
						_List_Nil);
				}
			}(),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('fixed')
					]),
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$withDefault,
						$rtfeldman$elm_css$Html$Styled$text(''),
						function () {
							if (state.$ === 1) {
								var model = state.a;
								return $author$project$Components$Produits$List$View$actionButtons(model);
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}())
					]))
			]));
};
var $author$project$Components$Supports$Details$Documents$Update$CreateDocument = {$: 3};
var $author$project$Components$Supports$Details$View$barreDuBas = function (model) {
	var _v0 = model.e6;
	switch (_v0) {
		case 0:
			return $rtfeldman$elm_css$Html$Styled$text('');
		case 1:
			return $rtfeldman$elm_css$Html$Styled$text('');
		default:
			return A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('fixed')
					]),
				function () {
					var _v1 = model.fd.bn.fq;
					if (!_v1.$) {
						return _List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$button,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('add'),
										$rtfeldman$elm_css$Html$Styled$Events$onClick(
										$author$project$Components$Supports$Details$Update$DocumentsActions($author$project$Components$Supports$Details$Documents$Update$CreateDocument))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Ajouter un document')
									]))
							]);
					} else {
						return _List_Nil;
					}
				}());
	}
};
var $author$project$Components$Supports$Details$Documents$Update$SetDateProduction = function (a) {
	return {$: 7, a: a};
};
var $author$project$Components$Supports$Details$Documents$Update$SetNomDocument = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Supports$Details$Documents$Update$SetTypes = function (a) {
	return {$: 9, a: a};
};
var $author$project$Components$Supports$Details$Documents$EditDrawer$displayMissingField = function (_v0) {
	return function (_v1) {
		return $elm$core$Maybe$Nothing;
	};
};
var $author$project$Components$Supports$Details$Documents$Update$SelectFile = {$: 10};
var $author$project$Components$Supports$Details$Documents$EditDrawer$displayFile = function (file) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$span,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$title(
				$elm$file$File$name(file))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(
				$elm$file$File$name(file))
			]));
};
var $author$project$Components$Supports$Details$Documents$EditDrawer$fileInput = function (document) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('upload')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('cartoucheFile')
					]),
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$withDefault,
						$rtfeldman$elm_css$Html$Styled$text(''),
						A2($elm$core$Maybe$map, $author$project$Components$Supports$Details$Documents$EditDrawer$displayFile, document.ew))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$button,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Supports$Details$Documents$Update$SelectFile)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Sélectionner le fichier à uploader')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-warning')
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('La taille du fichier ne doit pas excéder 50 Mo')
					]))
			]));
};
var $author$project$Components$Supports$Details$Documents$EditDrawer$nameIsForbidden = function (editModel) {
	return A2(
		$elm$core$List$member,
		A2(
			$elm$core$Maybe$withDefault,
			'',
			$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(editModel.dl)),
		editModel.mu);
};
var $author$project$Components$Supports$Details$Documents$Update$UpdateDocumentMetadata = function (a) {
	return {$: 16, a: a};
};
var $author$project$Components$Supports$Details$Documents$EditDrawer$updateButton = F2(
	function (editModel, uploadOrUpdateLink) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$button,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('button primary done'),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('loading', editModel.aG)
						])),
					A2(
					$elm$core$Maybe$withDefault,
					$rtfeldman$elm_css$Html$Styled$Attributes$disabled(true),
					A2(
						$elm$core$Maybe$map,
						function (clickAction) {
							return editModel.aG ? $rtfeldman$elm_css$Html$Styled$Attributes$disabled(true) : clickAction;
						},
						A4(
							$elm$core$Maybe$map3,
							F3(
								function (link, _v0, _v1) {
									return $rtfeldman$elm_css$Html$Styled$Events$onClick(
										$author$project$Components$Supports$Details$Documents$Update$UpdateDocumentMetadata(link));
								}),
							uploadOrUpdateLink,
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(editModel.dl),
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(editModel.ek))))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Valider')
				]));
	});
var $author$project$Components$Supports$Details$Documents$Update$UploadFile = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$Components$Supports$Details$Documents$EditDrawer$uploadButton = F2(
	function (editModel, uploadOrUpdateLink) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$button,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('button primary done'),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('loading', editModel.aG)
						])),
					A2(
					$elm$core$Maybe$withDefault,
					$rtfeldman$elm_css$Html$Styled$Attributes$disabled(true),
					A2(
						$elm$core$Maybe$map,
						function (clickAction) {
							return (editModel.aG || $author$project$Components$Supports$Details$Documents$EditDrawer$nameIsForbidden(editModel)) ? $rtfeldman$elm_css$Html$Styled$Attributes$disabled(true) : clickAction;
						},
						A5(
							$elm$core$Maybe$map4,
							F4(
								function (file, link, _v0, _v1) {
									return $rtfeldman$elm_css$Html$Styled$Events$onClick(
										A2($author$project$Components$Supports$Details$Documents$Update$UploadFile, link, file));
								}),
							editModel.ew,
							uploadOrUpdateLink,
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(editModel.dl),
							$PerformanceIMMO$elm_utils$Perfimmo$Form$FormField$FormField$getValue(editModel.ek))))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Valider')
				]));
	});
var $author$project$Components$Supports$Details$Documents$EditDrawer$createDocumentDrawerContent = F2(
	function (createOrUpdateLink, editModel) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('drawer-ged-edit-document')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							function () {
							var _v0 = editModel.dj;
							if (!_v0) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$h2,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Ajouter un document')
										]));
							} else {
								return A2(
									$rtfeldman$elm_css$Html$Styled$h2,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Modifier le document')
										]));
							}
						}(),
							function () {
							var _v1 = editModel.dj;
							if (_v1 === 1) {
								return $rtfeldman$elm_css$Html$Styled$text('');
							} else {
								return $author$project$Components$Supports$Details$Documents$EditDrawer$fileInput(editModel);
							}
						}(),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
								]),
							_List_fromArray(
								[
									A4(
									$author$project$Common$FormElements$inputForm_,
									'nomDocument',
									'Nom du document',
									$author$project$Perfimmo$Form$FormModel$InputText(editModel.dl),
									$author$project$Components$Supports$Details$Documents$Update$SetNomDocument)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
								]),
							_List_fromArray(
								[
									A4(
									$author$project$Common$FormElements$inputForm_,
									'dateProduction',
									'Date de production',
									$author$project$Perfimmo$Form$FormModel$InputDate(editModel.ek),
									$author$project$Components$Supports$Details$Documents$Update$SetDateProduction)
								])),
							function () {
							var _v2 = editModel.dj;
							if (!_v2) {
								return $rtfeldman$elm_css$Html$Styled$text('');
							} else {
								return A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item disabled')
										]),
									_List_fromArray(
										[
											A4(
											$author$project$Common$FormElements$inputForm_,
											'dateProduction',
											'Date de publication',
											A2(
												$author$project$Perfimmo$Form$FormModel$DecoratedInput,
												$author$project$Perfimmo$Form$FormModel$InputDate(editModel.lK),
												_List_fromArray(
													[$author$project$Perfimmo$Dom$Utils$DisabledAttr])),
											$author$project$Components$Supports$Details$Documents$Update$SetDateProduction)
										]));
							}
						}(),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Perfimmo$Dom$Utils$formItem,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$label,
											_List_Nil,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text('Tags')
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$div,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
												]),
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$map,
													$author$project$Components$Supports$Details$Documents$Update$SetTags,
													$rtfeldman$elm_css$Html$Styled$fromUnstyled(
														$inkuzmin$elm_multiselect$Multiselect$view(editModel.oK)))
												]))
										]))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
								]),
							_List_fromArray(
								[
									A2(
									$author$project$Perfimmo$Dom$Utils$formItem,
									_List_Nil,
									_List_fromArray(
										[
											A6($author$project$Perfimmo$Form$FormView$listSelectForm, _List_Nil, $author$project$Components$Supports$Details$Documents$EditDrawer$displayMissingField, editModel.kw, 'documentType', 'Type de document', $author$project$Components$Supports$Details$Documents$Update$SetTypes)
										]))
								])),
							A2(
							$elm$core$Maybe$withDefault,
							$rtfeldman$elm_css$Html$Styled$text(''),
							A2(
								$elm$core$Maybe$map,
								function (error) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$div,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-error')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(error)
											]));
								},
								editModel.l9)),
							$author$project$Components$Supports$Details$Documents$EditDrawer$nameIsForbidden(editModel) ? A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-error')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Ce nom de document est déja utilisé')
								])) : $rtfeldman$elm_css$Html$Styled$text(''),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('drawerActions')
								]),
							_List_fromArray(
								[
									function () {
									var _v3 = editModel.dj;
									if (!_v3) {
										return A2($author$project$Components$Supports$Details$Documents$EditDrawer$uploadButton, editModel, createOrUpdateLink);
									} else {
										return A2($author$project$Components$Supports$Details$Documents$EditDrawer$updateButton, editModel, createOrUpdateLink);
									}
								}()
								]))
						]))
				]));
	});
var $author$project$Components$Supports$Details$Model$Documents = 2;
var $author$project$Components$Supports$Details$Model$Produits = 1;
var $author$project$Components$Supports$Details$Update$DoNothing = {$: 3};
var $author$project$Perfimmo$Form$FormModel$InputDecimal = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Supports$Details$View$informationView = function (model) {
	return _List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('informations')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
							$rtfeldman$elm_css$Html$Styled$Attributes$id('informationsDetail')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h2,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Détail')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
								]),
							_List_fromArray(
								[
									A4(
									$author$project$Common$FormElements$inputForm_,
									'libelle',
									'Libellé du support',
									A2(
										$author$project$Perfimmo$Form$FormModel$DecoratedInput,
										$author$project$Perfimmo$Form$FormModel$InputText(model.nd),
										_List_fromArray(
											[$author$project$Perfimmo$Dom$Utils$DisabledAttr])),
									function (_v0) {
										return $author$project$Components$Supports$Details$Update$DoNothing;
									}),
									A4(
									$author$project$Common$FormElements$inputForm_,
									'supportId',
									'Identifiant du support',
									A2(
										$author$project$Perfimmo$Form$FormModel$DecoratedInput,
										$author$project$Perfimmo$Form$FormModel$InputText(model.oE),
										_List_fromArray(
											[$author$project$Perfimmo$Dom$Utils$DisabledAttr])),
									function (_v1) {
										return $author$project$Components$Supports$Details$Update$DoNothing;
									}),
									A4(
									$author$project$Common$FormElements$inputForm_,
									'company',
									'Société de gestion',
									A2(
										$author$project$Perfimmo$Form$FormModel$DecoratedInput,
										$author$project$Perfimmo$Form$FormModel$InputText(model.ov),
										_List_fromArray(
											[$author$project$Perfimmo$Dom$Utils$DisabledAttr])),
									function (_v2) {
										return $author$project$Components$Supports$Details$Update$DoNothing;
									}),
									A4(
									$author$project$Common$FormElements$inputForm_,
									'fraisGestion',
									'Frais de gestion de l\'actif',
									A2(
										$author$project$Perfimmo$Form$FormModel$DecoratedInput,
										$author$project$Perfimmo$Form$FormModel$InputDecimal(model.mx),
										_List_fromArray(
											[$author$project$Perfimmo$Dom$Utils$DisabledAttr])),
									function (_v3) {
										return $author$project$Components$Supports$Details$Update$DoNothing;
									}),
									A4(
									$author$project$Common$FormElements$inputForm_,
									'categorie',
									'Catégorie de l\'actif',
									A2(
										$author$project$Perfimmo$Form$FormModel$DecoratedInput,
										$author$project$Perfimmo$Form$FormModel$InputText(model.lp),
										_List_fromArray(
											[$author$project$Perfimmo$Dom$Utils$DisabledAttr])),
									function (_v4) {
										return $author$project$Components$Supports$Details$Update$DoNothing;
									})
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('section'),
							$rtfeldman$elm_css$Html$Styled$Attributes$id('informationsPerformance')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h2,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Performance')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('sectionContent')
								]),
							_List_fromArray(
								[
									A4(
									$author$project$Common$FormElements$inputForm_,
									'performanceUniteDeCompte',
									'Performance nette de l\'unité de compte',
									A2(
										$author$project$Perfimmo$Form$FormModel$DecoratedInput,
										$author$project$Perfimmo$Form$FormModel$InputDecimal(model.n$),
										_List_fromArray(
											[$author$project$Perfimmo$Dom$Utils$DisabledAttr])),
									function (_v5) {
										return $author$project$Components$Supports$Details$Update$DoNothing;
									}),
									A4(
									$author$project$Common$FormElements$inputForm_,
									'performanceN1',
									'Performance brute de l\'actif N-1',
									A2(
										$author$project$Perfimmo$Form$FormModel$DecoratedInput,
										$author$project$Perfimmo$Form$FormModel$InputDecimal(model.nZ),
										_List_fromArray(
											[$author$project$Perfimmo$Dom$Utils$DisabledAttr])),
									function (_v6) {
										return $author$project$Components$Supports$Details$Update$DoNothing;
									})
								]))
						]))
				]))
		]);
};
var $author$project$Components$Supports$Details$View$produitsView = function (model) {
	return _List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('products')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$ul,
					_List_Nil,
					A2(
						$elm$core$List$map,
						function (produit) {
							return A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('cartouche-product'),
												$rtfeldman$elm_css$Html$Styled$Attributes$href(
												$author$project$Routes$Model$routes.n7.mG.dX(produit.hB))
											]),
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('main')
													]),
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$div,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('logo')
															]),
														_List_Nil),
														A2(
														$rtfeldman$elm_css$Html$Styled$div,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('title')
															]),
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text(produit.dl)
															])),
														A2(
														$rtfeldman$elm_css$Html$Styled$div,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('infos')
															]),
														_List_fromArray(
															[
																A2(
																$rtfeldman$elm_css$Html$Styled$ul,
																_List_Nil,
																_List_fromArray(
																	[
																		A2(
																		$rtfeldman$elm_css$Html$Styled$li,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('classification')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(
																				A2($elm$core$Maybe$withDefault, '', produit.jZ))
																			])),
																		A2(
																		$rtfeldman$elm_css$Html$Styled$li,
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$Attributes$class('producteur')
																			]),
																		_List_fromArray(
																			[
																				$rtfeldman$elm_css$Html$Styled$text(produit.i7)
																			]))
																	]))
															]))
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('aside')
													]),
												_List_fromArray(
													[
														A2(
														$rtfeldman$elm_css$Html$Styled$div,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$Attributes$class('action')
															]),
														_List_fromArray(
															[
																A2(
																$rtfeldman$elm_css$Html$Styled$span,
																_List_Nil,
																_List_fromArray(
																	[
																		$rtfeldman$elm_css$Html$Styled$text('Voir le produit')
																	]))
															]))
													]))
											]))
									]));
						},
						model))
				]))
		]);
};
var $author$project$Components$Supports$Details$View$tab = F3(
	function (model, tabId, childs) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('onglet'),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'active',
							_Utils_eq(model.e6, tabId))
						]))
				]),
			childs);
	});
var $author$project$Components$Supports$Details$Documents$Update$DeleteDocument = function (a) {
	return {$: 14, a: a};
};
var $author$project$Components$Supports$Details$Documents$Update$UpdateDocument = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Supports$Details$Documents$View$downloadButton = function (doc) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$a,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$disabled(
				$elm_community$maybe_extra$Maybe$Extra$isNothing(doc.l6)),
				$rtfeldman$elm_css$Html$Styled$Attributes$href(
				A2(
					$elm$core$Maybe$withDefault,
					'',
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.hA;
						},
						doc.l6))),
				$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank'),
				$rtfeldman$elm_css$Html$Styled$Attributes$class('download'),
				$rtfeldman$elm_css$Html$Styled$Attributes$download(doc.mp),
				$rtfeldman$elm_css$Svg$Styled$Attributes$title('Télécharger le document')
			]),
		_List_Nil);
};
var $author$project$Components$Supports$Details$Documents$View$document = F2(
	function (timezone, doc) {
		var dateProductionShow = A2(
			$elm$core$List$map,
			function (dateProduction) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$li,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							'Document produit le ' + A2($author$project$Perfimmo$Date$DisplayableDate$showDateDDmmYYYY, timezone, dateProduction))
						]));
			},
			$elm_community$maybe_extra$Maybe$Extra$toList(doc.lH));
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('cartouche-document')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('main')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('logo')
								]),
							_List_Nil),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('title')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(doc.m9)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('infos')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$ul,
									_List_Nil,
									_Utils_ap(
										dateProductionShow,
										_Utils_ap(
											_List_fromArray(
												[
													A2(
													$rtfeldman$elm_css$Html$Styled$li,
													_List_Nil,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text(
															'Document publié le ' + A2($author$project$Perfimmo$Date$DisplayableDate$showDateDDmmYYYY, timezone, doc.lI))
														]))
												]),
											A2(
												$elm$core$List$map,
												function (tag) {
													return A2(
														$rtfeldman$elm_css$Html$Styled$li,
														_List_Nil,
														_List_fromArray(
															[
																$rtfeldman$elm_css$Html$Styled$text(tag)
															]));
												},
												doc.oK))))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('aside')
						]),
					_List_fromArray(
						[
							doc.m3 ? A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('annexeTag')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Annexe')
								])) : $rtfeldman$elm_css$Html$Styled$text(''),
							doc.m4 ? A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('dicTag')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('DIC')
								])) : $rtfeldman$elm_css$Html$Styled$text(''),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('actions')
								]),
							_List_fromArray(
								[
									$author$project$Components$Supports$Details$Documents$View$downloadButton(doc),
									A2(
									$rtfeldman$elm_css$Html$Styled$button,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class('edit'),
											$rtfeldman$elm_css$Svg$Styled$Attributes$title('Modifier le document'),
											$rtfeldman$elm_css$Html$Styled$Events$onClick(
											$author$project$Components$Supports$Details$Documents$Update$UpdateDocument(doc))
										]),
									_List_Nil),
									A2(
									$elm$core$Maybe$withDefault,
									$rtfeldman$elm_css$Html$Styled$text(''),
									A2(
										$elm$core$Maybe$map,
										function (link) {
											return A2(
												$rtfeldman$elm_css$Html$Styled$button,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('delete'),
														$rtfeldman$elm_css$Svg$Styled$Attributes$title('Supprimer le document'),
														$rtfeldman$elm_css$Html$Styled$Events$onClick(
														$author$project$Components$Supports$Details$Documents$Update$DeleteDocument(link))
													]),
												_List_Nil);
										},
										doc.lP))
								]))
						]))
				]));
	});
var $author$project$Components$Supports$Details$Documents$Model$FiltrerParDateDeProductionApres = function (a) {
	return {$: 3, a: a};
};
var $author$project$Components$Supports$Details$Documents$Model$FiltrerParDateDeProductionAvant = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Supports$Details$Documents$Model$FiltrerParDateDePublicationApres = function (a) {
	return {$: 5, a: a};
};
var $author$project$Components$Supports$Details$Documents$Model$FiltrerParDateDePublicationAvant = function (a) {
	return {$: 6, a: a};
};
var $author$project$Components$Supports$Details$Documents$Model$FiltrerParNom = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$Details$Documents$Model$FiltrerParTypeDeDocument = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Supports$Details$Documents$View$decorator = F2(
	function (_v0, itemAttrs) {
		return itemAttrs;
	});
var $author$project$Components$Supports$Details$Documents$View$displayMissingField = function (_v0) {
	return function (_v1) {
		return $elm$core$Maybe$Nothing;
	};
};
var $author$project$Components$Supports$Details$Documents$View$filters = function (filtres) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('filters')
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Perfimmo$Dom$Utils$formItem,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Recherche par nom de document')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$input,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
								$rtfeldman$elm_css$Html$Styled$Attributes$class('search'),
								$rtfeldman$elm_css$Html$Styled$Attributes$value(filtres.dp),
								$rtfeldman$elm_css$Html$Styled$Events$onInput(
								A2($elm$core$Basics$composeL, $author$project$Components$Supports$Details$Documents$Update$Filtrer, $author$project$Components$Supports$Details$Documents$Model$FiltrerParNom))
							]),
						_List_Nil)
					])),
				A2(
				$author$project$Perfimmo$Dom$Utils$formItem,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Filtrer par tag')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								A2($elm$core$Basics$composeL, $author$project$Components$Supports$Details$Documents$Update$Filtrer, $author$project$Components$Supports$Details$Documents$Model$FiltrerParTags),
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(filtres.cu)))
							]))
					])),
				A2(
				$author$project$Perfimmo$Dom$Utils$formItem,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Date de production du document')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('after')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Après le')
									])),
								A8(
								$author$project$Perfimmo$Form$FormView$inputForm,
								_List_Nil,
								1,
								$author$project$Components$Supports$Details$Documents$View$displayMissingField,
								$author$project$Components$Supports$Details$Documents$View$decorator,
								$author$project$Perfimmo$Form$FormModel$InputDate(filtres.bG),
								'parDateDeProductionApres',
								'',
								A2($elm$core$Basics$composeL, $author$project$Components$Supports$Details$Documents$Update$Filtrer, $author$project$Components$Supports$Details$Documents$Model$FiltrerParDateDeProductionApres))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('before')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Avant le')
									])),
								A8(
								$author$project$Perfimmo$Form$FormView$inputForm,
								_List_Nil,
								1,
								$author$project$Components$Supports$Details$Documents$View$displayMissingField,
								$author$project$Components$Supports$Details$Documents$View$decorator,
								$author$project$Perfimmo$Form$FormModel$InputDate(filtres.bH),
								'parDateDeProductionAvant',
								'',
								A2($elm$core$Basics$composeL, $author$project$Components$Supports$Details$Documents$Update$Filtrer, $author$project$Components$Supports$Details$Documents$Model$FiltrerParDateDeProductionAvant))
							]))
					])),
				A2(
				$author$project$Perfimmo$Dom$Utils$formItem,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Date de publication du document')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('after')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Après le')
									])),
								A8(
								$author$project$Perfimmo$Form$FormView$inputForm,
								_List_Nil,
								1,
								$author$project$Components$Supports$Details$Documents$View$displayMissingField,
								$author$project$Components$Supports$Details$Documents$View$decorator,
								$author$project$Perfimmo$Form$FormModel$InputDate(filtres.bI),
								'parDateDePublicationApres',
								'',
								A2($elm$core$Basics$composeL, $author$project$Components$Supports$Details$Documents$Update$Filtrer, $author$project$Components$Supports$Details$Documents$Model$FiltrerParDateDePublicationApres))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('before')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Avant le')
									])),
								A8(
								$author$project$Perfimmo$Form$FormView$inputForm,
								_List_Nil,
								1,
								$author$project$Components$Supports$Details$Documents$View$displayMissingField,
								$author$project$Components$Supports$Details$Documents$View$decorator,
								$author$project$Perfimmo$Form$FormModel$InputDate(filtres.bJ),
								'parDateDePublicationAvant',
								'',
								A2($elm$core$Basics$composeL, $author$project$Components$Supports$Details$Documents$Update$Filtrer, $author$project$Components$Supports$Details$Documents$Model$FiltrerParDateDePublicationAvant))
							]))
					])),
				A2(
				$author$project$Perfimmo$Dom$Utils$formItem,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Type de document')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$ul,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('listSelectForm')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onClick(
										$author$project$Components$Supports$Details$Documents$Update$Filtrer(
											$author$project$Components$Supports$Details$Documents$Model$FiltrerParTypeDeDocument(0))),
										$rtfeldman$elm_css$Html$Styled$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('selected', filtres.cg)
											]))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Annexe')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onClick(
										$author$project$Components$Supports$Details$Documents$Update$Filtrer(
											$author$project$Components$Supports$Details$Documents$Model$FiltrerParTypeDeDocument(1))),
										$rtfeldman$elm_css$Html$Styled$Attributes$classList(
										_List_fromArray(
											[
												_Utils_Tuple2('selected', filtres.bo)
											]))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('DIC')
									]))
							]))
					]))
			]));
};
var $author$project$Components$Supports$Details$Documents$View$view = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('documents')
			]),
		_List_fromArray(
			[
				$author$project$Components$Supports$Details$Documents$View$filters(model.aY),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('main')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$ul,
						_List_Nil,
						A2(
							$elm$core$List$map,
							function (doc) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$li,
									_List_Nil,
									_List_fromArray(
										[
											A2($author$project$Components$Supports$Details$Documents$View$document, model.aO, doc)
										]));
							},
							model.eB))
					]))
			]));
};
var $author$project$Components$Supports$Details$View$form = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
			]),
		_List_fromArray(
			[
				A3(
				$author$project$Components$Supports$Details$View$tab,
				model,
				0,
				$author$project$Components$Supports$Details$View$informationView(model.fd.mZ)),
				A3(
				$author$project$Components$Supports$Details$View$tab,
				model,
				1,
				$author$project$Components$Supports$Details$View$produitsView(model.fd.n7.n7)),
				A3(
				$author$project$Components$Supports$Details$View$tab,
				model,
				2,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Components$Supports$Details$Update$DocumentsActions,
						$author$project$Components$Supports$Details$Documents$View$view(model.fd.bn))
					]))
			]));
};
var $author$project$Components$Supports$Details$Update$Cancel = {$: 1};
var $author$project$Components$Supports$Common$Common$cgpConseilsLabel = 'CGP Conseils';
var $author$project$Components$Supports$Details$Update$SelectTab = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Supports$Details$View$tabIdToString = function (tabId) {
	switch (tabId) {
		case 0:
			return 'Informations';
		case 1:
			return 'Produits proposant le support';
		default:
			return 'Documents';
	}
};
var $author$project$Components$Supports$Details$View$tabButton = F2(
	function (model, tabId) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$button,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
					$author$project$Components$Supports$Details$Update$SelectTab(tabId)),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'active',
							_Utils_eq(model.e6, tabId))
						]))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(
					$author$project$Components$Supports$Details$View$tabIdToString(tabId))
				]));
	});
var $author$project$Components$Supports$Details$View$header = function (model) {
	var source = function () {
		var _v1 = model.mV;
		if (!_v1) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('tag-sync-active'),
						$rtfeldman$elm_css$Html$Styled$Attributes$title('Les données du support sont issues de ' + $author$project$Components$Supports$Common$Common$cgpConseilsLabel)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text($author$project$Components$Supports$Common$Common$cgpConseilsLabel)
					]));
		} else {
			return $rtfeldman$elm_css$Html$Styled$text('');
		}
	}();
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('top')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$button,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('back'),
						$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Supports$Details$Update$Cancel)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Tous les supports')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('top-content')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$h1,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(model.m9)
							])),
						source
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('tabs')
					]),
				_List_fromArray(
					[
						A2($author$project$Components$Supports$Details$View$tabButton, model, 0),
						A2($author$project$Components$Supports$Details$View$tabButton, model, 1),
						A2(
						$elm$core$Maybe$withDefault,
						$rtfeldman$elm_css$Html$Styled$text(''),
						A2(
							$elm$core$Maybe$map,
							function (_v0) {
								return A2($author$project$Components$Supports$Details$View$tabButton, model, 2);
							},
							model.eo))
					]))
			]));
};
var $author$project$Components$Supports$Details$View$page = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Components$Supports$Details$View$header(model),
				model.aG ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
					]),
				_List_Nil) : $author$project$Components$Supports$Details$View$form(model),
				$author$project$Components$Supports$Details$View$barreDuBas(model),
				function () {
				var _v0 = model.fd.bn._;
				switch (_v0.$) {
					case 0:
						return $rtfeldman$elm_css$Html$Styled$text('');
					case 2:
						var doc = _v0.a;
						var editModel = _v0.b;
						return A2(
							$rtfeldman$elm_css$Html$Styled$map,
							A2($elm$core$Basics$composeR, $author$project$Components$Supports$Details$Documents$Update$DrawerEvents, $author$project$Components$Supports$Details$Update$DocumentsActions),
							A2(
								$author$project$Components$Drawer$View$view,
								model.fd.bn.ep,
								A2($author$project$Components$Supports$Details$Documents$EditDrawer$createDocumentDrawerContent, doc.fp, editModel)));
					default:
						var editModel = _v0.a;
						return A2(
							$rtfeldman$elm_css$Html$Styled$map,
							A2($elm$core$Basics$composeR, $author$project$Components$Supports$Details$Documents$Update$DrawerEvents, $author$project$Components$Supports$Details$Update$DocumentsActions),
							A2(
								$author$project$Components$Drawer$View$view,
								model.fd.bn.ep,
								A2($author$project$Components$Supports$Details$Documents$EditDrawer$createDocumentDrawerContent, model.fd.bn.fq, editModel)));
				}
			}(),
				A2(
				$rtfeldman$elm_css$Html$Styled$map,
				A2($elm$core$Basics$composeR, $author$project$Components$Supports$Details$Documents$Update$DeleteAction, $author$project$Components$Supports$Details$Update$DocumentsActions),
				A4(
					$author$project$Components$Drawer$ConfirmationDrawer$view,
					model.fd.bn.lQ,
					'Supprimer le document',
					_List_fromArray(
						['Souhaitez-vous réellement supprimer ce document ?']),
					$elm$core$Maybe$Just('La suppression est définitive et vous ne pourrez pas revenir en arrière')))
			]));
};
var $author$project$Components$Supports$Details$View$view = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('page-support-detail'),
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('loading', model.aG)
					]))
			]),
		_List_fromArray(
			[
				$author$project$Components$Supports$Details$View$page(model)
			]));
};
var $author$project$Components$Supports$ImportSupports$Detail$View$importStatusTag = function (status) {
	var _v0 = function () {
		switch (status) {
			case 2:
				return _Utils_Tuple2(
					$rtfeldman$elm_css$Html$Styled$Attributes$class('import-success'),
					'OK');
			case 0:
				return _Utils_Tuple2(
					$rtfeldman$elm_css$Html$Styled$Attributes$class('import-pending'),
					'En attente');
			default:
				return _Utils_Tuple2(
					$rtfeldman$elm_css$Html$Styled$Attributes$class('import-warning'),
					'Vérifications');
		}
	}();
	var statutClass = _v0.a;
	var statut = _v0.b;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('status'),
				statutClass
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(statut)
			]));
};
var $author$project$Components$Supports$ImportSupports$Detail$View$pageHeader = F3(
	function (topContent, content, loading) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('page-import-supports-detail'),
					$rtfeldman$elm_css$Html$Styled$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('loading', loading)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$id('top')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$a,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('back'),
									$rtfeldman$elm_css$Html$Styled$Attributes$href(
									$author$project$Routes$Model$routes.fe.mQ.dX(0))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Tous les imports de données')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('top-content')
								]),
							topContent)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
						]),
					_List_fromArray(
						[content]))
				]));
	});
var $author$project$Components$Supports$ImportSupports$Detail$Update$ToggleSupportsNonImportesFilter = {$: 1};
var $author$project$Components$Supports$ImportSupports$Detail$View$filters = F2(
	function (detail, shouldFilter) {
		var _v0 = A3(
			$elm$core$Tuple$mapBoth,
			$elm$core$List$length,
			$elm$core$List$length,
			A2(
				$elm$core$List$partition,
				function (s) {
					var _v1 = s.ox;
					if (!_v1.$) {
						return true;
					} else {
						return false;
					}
				},
				detail.oG));
		var supportsImportesNb = _v0.a;
		var supportsNonImportesNb = _v0.b;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id('filters')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('counters')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$ul,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('facets')
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$li,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$span,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('selectable-tag'),
													$rtfeldman$elm_css$Html$Styled$Attributes$classList(
													_List_fromArray(
														[
															_Utils_Tuple2('selected', shouldFilter)
														])),
													$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Supports$ImportSupports$Detail$Update$ToggleSupportsNonImportesFilter)
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(
													$elm$core$String$fromInt(supportsNonImportesNb) + ' supports non importés')
												]))
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$li,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$span,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('selectable-tag disabled selected')
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(
													$elm$core$String$fromInt(supportsImportesNb) + ' supports importés')
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Components$Supports$ImportSupports$Detail$View$showSupportStatus = function (status) {
	if (!status.$) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$span,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('succes')
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Succès')
				]));
	} else {
		var cause = status.a;
		var _v1 = cause.lr;
		switch (_v1.$) {
			case 0:
				return A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('pending')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('En attente')
						]));
			case 1:
				return A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('notfound')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Support non trouvé dans la base ' + $author$project$Components$Supports$Common$Common$cgpConseilsLabel)
						]));
			default:
				var demandeEffectueeAvecSucces = _v1.a.lS;
				return demandeEffectueeAvecSucces ? A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('askCGPConseilSuccess')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Demande d\'ajout dans la base ' + ($author$project$Components$Supports$Common$Common$cgpConseilsLabel + ' en cours'))
						])) : A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('askCGPConseilFailure')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Support non importé. Contacter ' + $author$project$Components$Supports$Common$Common$cgpConseilsLabel)
						]));
		}
	}
};
var $author$project$Components$Supports$ImportSupports$Detail$View$showDetail = F3(
	function (detail, filteredSupports, shouldFilter) {
		var importShow = function () {
			var _v0 = detail.kt;
			switch (_v0.$) {
				case 0:
					var produit = _v0.a;
					return _List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Import complet de supports pour le produit')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('produit')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(produit.m9)
								]))
						]);
				case 1:
					var produit = _v0.a;
					return _List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Import partiel de supports pour le produit')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('produit')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(produit.m9)
								]))
						]);
				case 2:
					var produit = _v0.a;
					var cab = _v0.b;
					return _List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Import complet des commissions pour le cabinet')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('cabinet')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(cab.m9)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('pour les supports du produit')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('produit')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(produit.m9)
								]))
						]);
				case 3:
					var produit = _v0.a;
					var cab = _v0.b;
					return _List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Import partiel des commissions pour le cabinet')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('cabinet')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(cab.m9)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('pour les supports du produit')
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('produit')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(produit.m9)
								]))
						]);
				default:
					return _List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('Import dans le referentiel de support')
								]))
						]);
			}
		}();
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('section')
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h2,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Détail de l\'import')
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('type')
						]),
					importShow),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('supports')
						]),
					_List_fromArray(
						[
							A2($author$project$Components$Supports$ImportSupports$Detail$View$filters, detail, shouldFilter),
							A2(
							$rtfeldman$elm_css$Html$Styled$ul,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('list')
								]),
							A2(
								$elm$core$List$map,
								function (support) {
									return A2(
										$rtfeldman$elm_css$Html$Styled$li,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('supportId')
													]),
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$text(support.oE)
													])),
												A2(
												$rtfeldman$elm_css$Html$Styled$div,
												_List_fromArray(
													[
														$rtfeldman$elm_css$Html$Styled$Attributes$class('status')
													]),
												_List_fromArray(
													[
														$author$project$Components$Supports$ImportSupports$Detail$View$showSupportStatus(support.ox)
													]))
											]));
								},
								filteredSupports))
						]))
				]));
	});
var $author$project$Perfimmo$Date$DisplayableDate$decorateMinutes = function (rawMinutes) {
	return ($elm$core$String$length(rawMinutes) === 1) ? ('0' + rawMinutes) : rawMinutes;
};
var $author$project$Perfimmo$Date$DisplayableDate$showMonth = function (month) {
	switch (month) {
		case 0:
			return 'Janvier';
		case 1:
			return 'Février';
		case 2:
			return 'Mars';
		case 3:
			return 'Avril';
		case 4:
			return 'Mai';
		case 5:
			return 'Juin';
		case 6:
			return 'Juillet';
		case 7:
			return 'Août';
		case 8:
			return 'Septembre';
		case 9:
			return 'Octobre';
		case 10:
			return 'Novembre';
		default:
			return 'Décembre';
	}
};
var $author$project$Perfimmo$Date$DisplayableDate$showDate = F2(
	function (timezone, date) {
		return $elm$core$String$fromInt(
			A2($elm$time$Time$toDay, timezone, date)) + (' ' + ($author$project$Perfimmo$Date$DisplayableDate$showMonth(
			A2($elm$time$Time$toMonth, timezone, date)) + (' ' + $elm$core$String$fromInt(
			A2($elm$time$Time$toYear, timezone, date)))));
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $author$project$Perfimmo$Date$DisplayableDate$showDatetime = F2(
	function (timezone, date) {
		return A2($author$project$Perfimmo$Date$DisplayableDate$showDate, timezone, date) + (' à ' + ($elm$core$String$fromInt(
			A2($elm$time$Time$toHour, timezone, date)) + (':' + ($author$project$Perfimmo$Date$DisplayableDate$decorateMinutes(
			$elm$core$String$fromInt(
				A2($elm$time$Time$toMinute, timezone, date))) + (':' + $author$project$Perfimmo$Date$DisplayableDate$decorateMinutes(
			$elm$core$String$fromInt(
				A2($elm$time$Time$toSecond, timezone, date))))))));
	});
var $author$project$Components$Supports$ImportSupports$Detail$View$title = F2(
	function (timezone, import_) {
		return 'Import du ' + A2($author$project$Perfimmo$Date$DisplayableDate$showDatetime, timezone, import_.gv);
	});
var $author$project$Common$ImpossibleDeChargerContenuDelaPage$view = A2(
	$rtfeldman$elm_css$Html$Styled$div,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$class('page-content-not-loaded')
		]),
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$h2,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Impossible de charger le contenu de la page')
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Merci d\'essayer de recharger la page.')
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Si cela ne fonctionne toujours pas, veuillez contacter le service client.')
				]))
		]));
var $author$project$Components$Supports$ImportSupports$Detail$View$view = function (model) {
	var _v0 = model.ow;
	switch (_v0.$) {
		case 0:
			return A3(
				$author$project$Components$Supports$ImportSupports$Detail$View$pageHeader,
				_List_Nil,
				$rtfeldman$elm_css$Html$Styled$text(''),
				true);
		case 1:
			var importPasse = _v0.a;
			var supports = _v0.b;
			var topcontent = _List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$h1,
					_List_Nil,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							A2($author$project$Components$Supports$ImportSupports$Detail$View$title, model.hx, importPasse))
						])),
					$author$project$Components$Supports$ImportSupports$Detail$View$importStatusTag(importPasse.j$)
				]);
			return A3(
				$author$project$Components$Supports$ImportSupports$Detail$View$pageHeader,
				topcontent,
				A3($author$project$Components$Supports$ImportSupports$Detail$View$showDetail, importPasse, supports.g5, model.c9),
				false);
		default:
			return A3(
				$author$project$Components$Supports$ImportSupports$Detail$View$pageHeader,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$h1,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('loading')
							]),
						_List_Nil)
					]),
				$author$project$Common$ImpossibleDeChargerContenuDelaPage$view,
				false);
	}
};
var $author$project$Components$Supports$ImportSupports$List$Update$ImportData = {$: 1};
var $author$project$Components$Supports$ImportSupports$List$View$actionButtons = A2(
	$rtfeldman$elm_css$Html$Styled$button,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$class('add'),
			$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Supports$ImportSupports$List$Update$ImportData)
		]),
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$text('Importer des supports')
		]));
var $author$project$Components$Supports$ImportSupports$List$View$displayImport = F2(
	function (timezone, importPasse) {
		var goToDetail = A2(
			$elm$core$Maybe$withDefault,
			$rtfeldman$elm_css$Html$Styled$text(''),
			A2(
				$elm$core$Maybe$map,
				function (_v4) {
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('action')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$a,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$href(
										$author$project$Routes$Model$routes.fe.lT.dX(importPasse.kx))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Voir le détail de l\'import')
									]))
							]));
				},
				importPasse.mI));
		var _v0 = function () {
			var _v1 = importPasse.kt;
			switch (_v1.$) {
				case 0:
					var produit = _v1.a;
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Import complet de supports pour le produit')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('produit')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(produit.m9)
									]))
							]),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('import-produit-complet'));
				case 1:
					var produit = _v1.a;
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Import partiel de supports pour le produit ')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('produit')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(produit.m9)
									]))
							]),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('import-produit-partiel'));
				case 2:
					var produit = _v1.a;
					var cab = _v1.b;
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Import complet des commissions pour le cabinet')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('cabinet')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(cab.m9)
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('pour les supports du produit')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('produit')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(produit.m9)
									]))
							]),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('import-cabinet-complet'));
				case 3:
					var produit = _v1.a;
					var cab = _v1.b;
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Import partiel des commissions pour le cabinet')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('cabinet')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(cab.m9)
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('pour les supports du produit')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('produit')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(produit.m9)
									]))
							]),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('import-cabinet-partiel'));
				default:
					return _Utils_Tuple2(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Import d\'ajout de supports dans le référentiel')
									]))
							]),
						$rtfeldman$elm_css$Html$Styled$Attributes$class('import-supports-dans-referentiel'));
			}
		}();
		var typeImportTxt = _v0.a;
		var typeImportClass = _v0.b;
		var _v2 = function () {
			var _v3 = importPasse.j$;
			switch (_v3) {
				case 2:
					return _Utils_Tuple2(
						$rtfeldman$elm_css$Html$Styled$Attributes$class('import-success'),
						'OK');
				case 0:
					return _Utils_Tuple2(
						$rtfeldman$elm_css$Html$Styled$Attributes$class('import-pending'),
						'En attente');
				default:
					return _Utils_Tuple2(
						$rtfeldman$elm_css$Html$Styled$Attributes$class('import-warning'),
						'Vérifications');
			}
		}();
		var statutClass = _v2.a;
		var statut = _v2.b;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('cartouche-import'),
					statutClass,
					typeImportClass
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('main')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('logo')
								]),
							_List_Nil),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('title')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(
									'Import du ' + A2($author$project$Perfimmo$Date$DisplayableDate$showDatetime, timezone, importPasse.gv))
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('infos')
								]),
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Html$Styled$ul, _List_Nil, typeImportTxt)
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('aside')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('status')
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(statut)
								])),
							goToDetail
						]))
				]));
	});
var $author$project$Components$Supports$ImportSupports$List$View$displayImports = function (model) {
	var imports = A2(
		$elm$core$List$map,
		function (importPasse) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$li,
				_List_Nil,
				_List_fromArray(
					[
						A2($author$project$Components$Supports$ImportSupports$List$View$displayImport, model.hx, importPasse)
					]));
		},
		model.eD);
	return A2($rtfeldman$elm_css$Html$Styled$ul, _List_Nil, imports);
};
var $author$project$Components$Supports$ImportSupports$List$Update$AskImportFile = F3(
	function (a, b, c) {
		return {$: 10, a: a, b: b, c: c};
	});
var $author$project$Components$Supports$ImportSupports$List$Update$SelectImportType = function (a) {
	return {$: 4, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Update$ShowError = {$: 13};
var $author$project$Components$Supports$ImportSupports$List$View$displayErrors = function (error) {
	var decorateErr = function (err) {
		switch (err.$) {
			case 0:
				var x = err.a;
				return 'Mode de gestion inconnu' + (' [' + (x.la + ']'));
			case 1:
				return 'Mode de gestion manquant';
			case 2:
				return 'Identifiant de support manquant';
			case 3:
				return 'Taux de rémunération maximum sur les frais de gestion du contrat reversé au distributeur incorrect';
			case 4:
				return 'Taux de rétrocessions maximum de commissions sur l\'actif versé au distributeur incorrect';
			case 5:
				return 'Frais de gestion incorrecte';
			case 11:
				return 'Performance nette incorrecte';
			case 6:
				return 'Taux de rémunération maximum sur les frais de gestion du contrat reversé au distributeur incorrect';
			case 7:
				return 'Donnée non renseignée';
			case 8:
				return 'Chaine de caractère vide';
			case 9:
				var x = err.a;
				return 'Nombre incorrect, valeures valides : ' + A2(
					$elm$core$String$join,
					', ',
					A2($elm$core$List$map, $elm$core$String$fromInt, x.o5));
			default:
				return 'Identifiant non valide';
		}
	};
	var showErrors = function () {
		var _v0 = error.hC.ma;
		switch (_v0.$) {
			case 0:
				var fileParsingErrors = _v0.a;
				return A2(
					$rtfeldman$elm_css$Html$Styled$ul,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('parsingErrors')
						]),
					A2(
						$elm$core$List$map,
						function (err) {
							if (!err.$) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$li,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('le fichier est vide')
										]));
							} else {
								var err_ = err.a;
								return A2(
									$rtfeldman$elm_css$Html$Styled$li,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Html$Styled$span,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('line')
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(
													'Ligne ' + ($elm$core$String$fromInt(err_.ne + 2) + ' : '))
												])),
											A2(
											$rtfeldman$elm_css$Html$Styled$span,
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$Attributes$class('explanation')
												]),
											_List_fromArray(
												[
													$rtfeldman$elm_css$Html$Styled$text(
													decorateErr(err_.es))
												]))
										]));
							}
						},
						fileParsingErrors));
			case 1:
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('otherErrors')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('pas de ligne dans le fichier')
						]));
			case 2:
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('otherErrors')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Il faut effectuer un import complet pour le produit avant de pouvoir importer les données spécifique à un cabinet')
						]));
			case 3:
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('otherErrors')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Il n\'est pas possible de faire un import partiel, pour un produit,avant d\'avoir fait un import complet')
						]));
			case 4:
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('otherErrors')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('IntegrateSupportDataRequiertImportComplet')
						]));
			case 5:
				var supportIds = _v0.a;
				return A2(
					$rtfeldman$elm_css$Html$Styled$ul,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('supportIdsNonImportesErrors')
						]),
					_Utils_ap(
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$li,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Ces supports n\'ont pas été importés au préalable pour le produit')
									]))
							]),
						A2(
							$elm$core$List$map,
							function (supportId) {
								return A2(
									$rtfeldman$elm_css$Html$Styled$li,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(supportId)
										]));
							},
							supportIds)));
			case 6:
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('otherErrors')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Produit non trouvé')
						]));
			case 7:
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('otherErrors')
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text('Cabinet non trouvé')
						]));
			default:
				return A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, _List_Nil);
		}
	}();
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('hideErrors', !error.e9)
					])),
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('showErrors', error.e9)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-error')
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Le fichier uploadé est incorrect. '),
						A2(
						$rtfeldman$elm_css$Html$Styled$button,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Supports$ImportSupports$List$Update$ShowError)
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Voir les erreurs >')
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('detailErrors')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$h3,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Erreurs')
							])),
						showErrors
					]))
			]));
};
var $author$project$Perfimmo$Input$DatalistInput$ClearSelection = function (a) {
	return {$: 3, a: a};
};
var $author$project$Perfimmo$Input$DatalistInput$Dropdown = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Update$InputFichier = {$: 7};
var $author$project$Perfimmo$Input$DatalistInput$SearchItem = function (a) {
	return {$: 1, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Update$SelectCabinet = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Perfimmo$Input$DatalistInput$SelectItem = function (a) {
	return {$: 2, a: a};
};
var $author$project$Components$Supports$ImportSupports$List$Update$SelectProduit = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Perfimmo$Input$DatalistInput$buttonClearStyle = function (isSelectedItem) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$css(
		_List_fromArray(
			[
				isSelectedItem ? $rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block) : $rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none),
				$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
				$rtfeldman$elm_css$Css$height(
				$rtfeldman$elm_css$Css$rem(2.4)),
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$rem(2.4)),
				$rtfeldman$elm_css$Css$top(
				$rtfeldman$elm_css$Css$rem(1.2)),
				$rtfeldman$elm_css$Css$right(
				$rtfeldman$elm_css$Css$rem(4.5)),
				$rtfeldman$elm_css$Css$lineHeight(
				$rtfeldman$elm_css$Css$rem(2.4)),
				$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$hover(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$textDecoration($rtfeldman$elm_css$Css$none)
					]))
			]));
};
var $author$project$Perfimmo$Input$DatalistInput$buttonSelectStyle = $rtfeldman$elm_css$Html$Styled$Attributes$css(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block),
			$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$pct(100)),
			$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$left),
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('000')),
			$rtfeldman$elm_css$Css$fontSize(
			$rtfeldman$elm_css$Css$rem(1.4)),
			$rtfeldman$elm_css$Css$lineHeight(
			$rtfeldman$elm_css$Css$rem(2.4)),
			$rtfeldman$elm_css$Css$fontWeight($rtfeldman$elm_css$Css$normal),
			A3(
			$rtfeldman$elm_css$Css$border3,
			$rtfeldman$elm_css$Css$rem(0.1),
			$rtfeldman$elm_css$Css$solid,
			$rtfeldman$elm_css$Css$hex('c0c0c0')),
			$rtfeldman$elm_css$Css$height(
			$rtfeldman$elm_css$Css$rem(4.8)),
			A4(
			$rtfeldman$elm_css$Css$padding4,
			$rtfeldman$elm_css$Css$rem(1.2),
			$rtfeldman$elm_css$Css$rem(5.2),
			$rtfeldman$elm_css$Css$rem(1.2),
			$rtfeldman$elm_css$Css$rem(2.4)),
			$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$noWrap),
			$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$hidden),
			$rtfeldman$elm_css$Css$textOverflow($rtfeldman$elm_css$Css$ellipsis),
			$rtfeldman$elm_css$Css$backgroundColor(
			$rtfeldman$elm_css$Css$hex('fff')),
			$rtfeldman$elm_css$Css$after(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
					A2($rtfeldman$elm_css$Css$property, 'content', '\'\''),
					$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
					$rtfeldman$elm_css$Css$right(
					$rtfeldman$elm_css$Css$rem(2)),
					$rtfeldman$elm_css$Css$top(
					A3(
						$rtfeldman$elm_css$Css$calc,
						$rtfeldman$elm_css$Css$pct(50),
						$rtfeldman$elm_css$Css$minus,
						$rtfeldman$elm_css$Css$rem(0.3))),
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$rem(0)),
					$rtfeldman$elm_css$Css$height(
					$rtfeldman$elm_css$Css$rem(0)),
					A3(
					$rtfeldman$elm_css$Css$borderLeft3,
					$rtfeldman$elm_css$Css$rem(0.6),
					$rtfeldman$elm_css$Css$solid,
					$rtfeldman$elm_css$Css$transparent),
					A3(
					$rtfeldman$elm_css$Css$borderRight3,
					$rtfeldman$elm_css$Css$rem(0.6),
					$rtfeldman$elm_css$Css$solid,
					$rtfeldman$elm_css$Css$transparent),
					A3(
					$rtfeldman$elm_css$Css$borderTop3,
					$rtfeldman$elm_css$Css$rem(0.6),
					$rtfeldman$elm_css$Css$solid,
					$rtfeldman$elm_css$Css$hex('808080'))
				])),
			$rtfeldman$elm_css$Css$active(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$textDecoration($rtfeldman$elm_css$Css$none)
				])),
			$rtfeldman$elm_css$Css$focus(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$textDecoration($rtfeldman$elm_css$Css$none)
				])),
			$rtfeldman$elm_css$Css$hover(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$textDecoration($rtfeldman$elm_css$Css$none)
				])),
			$rtfeldman$elm_css$Css$disabled(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$zIndex(
					$rtfeldman$elm_css$Css$int(1))
				]))
		]));
var $author$project$Perfimmo$Input$DatalistInput$divStyle = $rtfeldman$elm_css$Html$Styled$Attributes$css(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative)
		]));
var $author$project$Perfimmo$Input$DatalistInput$liStyle = function (selectedStyle) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$css(
		_Utils_ap(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
					$rtfeldman$elm_css$Css$color(
					$rtfeldman$elm_css$Css$hex('000')),
					$rtfeldman$elm_css$Css$fontSize(
					$rtfeldman$elm_css$Css$rem(1.4)),
					$rtfeldman$elm_css$Css$lineHeight(
					$rtfeldman$elm_css$Css$rem(2.4)),
					A2(
					$rtfeldman$elm_css$Css$padding2,
					$rtfeldman$elm_css$Css$rem(0.8),
					$rtfeldman$elm_css$Css$rem(2.4)),
					$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$noWrap),
					$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$hidden),
					$rtfeldman$elm_css$Css$textOverflow($rtfeldman$elm_css$Css$ellipsis),
					$rtfeldman$elm_css$Css$hover(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$backgroundColor(
							$rtfeldman$elm_css$Css$hex('1e90ff')),
							$rtfeldman$elm_css$Css$color(
							$rtfeldman$elm_css$Css$hex('fff'))
						]))
				]),
			selectedStyle));
};
var $author$project$Perfimmo$Input$DatalistInput$selectedLiStyle = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$before(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
				A2($rtfeldman$elm_css$Css$property, 'content', '\'✓\''),
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$rem(1.2)),
				$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
				A4(
				$rtfeldman$elm_css$Css$margin4,
				$rtfeldman$elm_css$Css$rem(0),
				$rtfeldman$elm_css$Css$rem(0.4),
				$rtfeldman$elm_css$Css$rem(0),
				$rtfeldman$elm_css$Css$rem(-1.6))
			]))
	]);
var $author$project$Perfimmo$Input$DatalistInput$itemView = F3(
	function (selectedItem, action, _v0) {
		var k = _v0.a;
		var v = _v0.b;
		var isSelectedStyle = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Basics$eq(k),
				selectedItem)) ? $author$project$Perfimmo$Input$DatalistInput$selectedLiStyle : _List_Nil;
		var isSelected = $rtfeldman$elm_css$Html$Styled$Attributes$classList(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'selected',
					A2(
						$elm$core$Maybe$withDefault,
						false,
						A2(
							$elm$core$Maybe$map,
							$elm$core$Basics$eq(k),
							selectedItem)))
				]));
		return A2(
			$rtfeldman$elm_css$Html$Styled$li,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
					action(k)),
					isSelected,
					$author$project$Perfimmo$Input$DatalistInput$liStyle(isSelectedStyle)
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text(v)
				]));
	});
var $author$project$Perfimmo$Input$DatalistInput$itemsView = F3(
	function (selectedItem, options, action) {
		return A2(
			$elm$core$List$map,
			A2($author$project$Perfimmo$Input$DatalistInput$itemView, selectedItem, action),
			options);
	});
var $author$project$Perfimmo$Input$DatalistInput$placeholderStyle = $rtfeldman$elm_css$Html$Styled$Attributes$css(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$color(
			$rtfeldman$elm_css$Css$hex('abb2b9')),
			$rtfeldman$elm_css$Css$fontStyle($rtfeldman$elm_css$Css$italic)
		]));
var $author$project$Perfimmo$Input$DatalistInput$ulStyle = function (showHide) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$css(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
				$rtfeldman$elm_css$Css$zIndex(
				$rtfeldman$elm_css$Css$int(1)),
				$rtfeldman$elm_css$Css$top(
				$rtfeldman$elm_css$Css$rem(4.7)),
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$pct(100)),
				$rtfeldman$elm_css$Css$backgroundColor(
				$rtfeldman$elm_css$Css$hex('f5f5f5')),
				A3(
				$rtfeldman$elm_css$Css$border3,
				$rtfeldman$elm_css$Css$rem(0.1),
				$rtfeldman$elm_css$Css$solid,
				$rtfeldman$elm_css$Css$hex('c0c0c0')),
				$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$hidden),
				showHide
			]));
};
var $author$project$Perfimmo$Input$DatalistInput$view = F6(
	function (model, attributes, dropdownAction, searchAction, selectAction, clearAction) {
		var isSelectedClass = A2(
			$elm$core$Maybe$withDefault,
			$rtfeldman$elm_css$Html$Styled$Attributes$class(''),
			A2(
				$elm$core$Maybe$map,
				function (_v3) {
					return $rtfeldman$elm_css$Html$Styled$Attributes$class('item-selected');
				},
				model.dO));
		var displaySelected2 = A2(
			$elm$core$Maybe$withDefault,
			A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[$author$project$Perfimmo$Input$DatalistInput$placeholderStyle]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(model.cv)
					])),
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Tuple$second,
					A2(
						$elm$core$Basics$composeR,
						$rtfeldman$elm_css$Html$Styled$text,
						A2(
							$elm$core$Basics$composeR,
							$elm$core$List$singleton,
							$rtfeldman$elm_css$Html$Styled$span(_List_Nil)))),
				A2(
					$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
					function (selectId) {
						return A2(
							$elm_community$list_extra$List$Extra$find,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$Tuple$first,
								$elm$core$Basics$eq(selectId)),
							model.aE);
					},
					model.dO)));
		var displaySelected = A2(
			$elm$core$Maybe$withDefault,
			$rtfeldman$elm_css$Html$Styled$Attributes$placeholder(model.cv),
			A2(
				$elm_community$maybe_extra$Maybe$Extra$orElse,
				$elm$core$String$isEmpty(model.bL) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$rtfeldman$elm_css$Html$Styled$Attributes$value(model.bL)),
				A2(
					$elm$core$Maybe$map,
					A2($elm$core$Basics$composeR, $elm$core$Tuple$second, $rtfeldman$elm_css$Html$Styled$Attributes$value),
					A2(
						$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
						function (selectId) {
							return A2(
								$elm_community$list_extra$List$Extra$find,
								A2(
									$elm$core$Basics$composeR,
									$elm$core$Tuple$first,
									$elm$core$Basics$eq(selectId)),
								model.aE);
						},
						model.dO))));
		var inputOrButton = function () {
			var _v2 = model.dN;
			if (_v2 === 1) {
				return A2(
					$rtfeldman$elm_css$Html$Styled$button,
					_Utils_ap(
						_List_fromArray(
							[
								$author$project$Perfimmo$Input$DatalistInput$buttonSelectStyle,
								$rtfeldman$elm_css$Html$Styled$Attributes$class('select'),
								$rtfeldman$elm_css$Html$Styled$Events$onClick(
								dropdownAction(0))
							]),
						attributes),
					_List_fromArray(
						[displaySelected2]));
			} else {
				return A2(
					$rtfeldman$elm_css$Html$Styled$input,
					_Utils_ap(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
								$author$project$Perfimmo$Input$DatalistInput$buttonSelectStyle,
								$rtfeldman$elm_css$Html$Styled$Attributes$class('select'),
								displaySelected,
								$rtfeldman$elm_css$Html$Styled$Events$onInput(searchAction),
								$rtfeldman$elm_css$Html$Styled$Events$onClick(
								dropdownAction(0))
							]),
						attributes),
					_List_Nil);
			}
		}();
		var clearButton = $elm$core$String$isEmpty(model.bL) ? $rtfeldman$elm_css$Html$Styled$text('') : A2(
			$rtfeldman$elm_css$Html$Styled$button,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('clear'),
					$author$project$Perfimmo$Input$DatalistInput$buttonClearStyle(
					$elm_community$maybe_extra$Maybe$Extra$isJust(model.dO)),
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
					clearAction(0))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('x')
				]));
		var _v0 = function () {
			var _v1 = model.aV;
			if (!_v1) {
				return _Utils_Tuple2(
					$rtfeldman$elm_css$Html$Styled$Attributes$class(''),
					$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$none));
			} else {
				return _Utils_Tuple2(
					$rtfeldman$elm_css$Html$Styled$Attributes$class('expanded'),
					$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$block));
			}
		}();
		var expanded = _v0.a;
		var showHide = _v0.b;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$id(model.hB),
					$rtfeldman$elm_css$Html$Styled$Attributes$class('datalist-input'),
					expanded,
					isSelectedClass,
					$author$project$Perfimmo$Input$DatalistInput$divStyle
				]),
			_List_fromArray(
				[
					inputOrButton,
					clearButton,
					A2(
					$rtfeldman$elm_css$Html$Styled$ul,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('options'),
							$author$project$Perfimmo$Input$DatalistInput$ulStyle(showHide)
						]),
					A3($author$project$Perfimmo$Input$DatalistInput$itemsView, model.dO, model.c8, selectAction))
				]));
	});
var $author$project$Components$Supports$ImportSupports$List$View$drawerContentAfterImportTypeChoice = function (model) {
	switch (model.$) {
		case 0:
			var subModel = model.a;
			return A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-info')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('L\'import complet écrasera les anciennes données pour les supports du produit choisi.')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Choisir le produit')
									])),
								A6(
								$author$project$Perfimmo$Input$DatalistInput$view,
								subModel.ax,
								_List_Nil,
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectProduit(model),
									$author$project$Perfimmo$Input$DatalistInput$Dropdown),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectProduit(model),
									$author$project$Perfimmo$Input$DatalistInput$SearchItem),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectProduit(model),
									$author$project$Perfimmo$Input$DatalistInput$SelectItem),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectProduit(model),
									$author$project$Perfimmo$Input$DatalistInput$ClearSelection))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-warning')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Les valeurs des frais et taux doivent être typées au format '),
										A2(
										$rtfeldman$elm_css$Html$Styled$strong,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Pourcentage')
											])),
										$rtfeldman$elm_css$Html$Styled$text(' dans le fichier Excel.')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Vous pouvez télécharger le modèle de fichier d\'import à utiliser '),
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href('/templates/modele-import-supports-produit.xlsx'),
												$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ici')
											])),
										$rtfeldman$elm_css$Html$Styled$text('.')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('upload')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('cartoucheFile')
									]),
								_List_fromArray(
									[
										A2(
										$elm$core$Maybe$withDefault,
										$rtfeldman$elm_css$Html$Styled$text(''),
										A2(
											$elm$core$Maybe$map,
											function (f) {
												return A2(
													$rtfeldman$elm_css$Html$Styled$span,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$title(
															$elm$file$File$name(f))
														]),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text(
															$elm$file$File$name(f))
														]));
											},
											A2(
												$elm$core$Maybe$map,
												function ($) {
													return $.ew;
												},
												subModel.ew)))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$button,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Supports$ImportSupports$List$Update$InputFichier),
										$rtfeldman$elm_css$Html$Styled$Attributes$class('button secondary')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Sélectionner le fichier à uploader')
									]))
							]))
					]));
		case 1:
			var subModel = model.a;
			return A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-info')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('L\'import partiel ne mettra à jour que les données contenues dans le fichier importé.')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Choisir le produit')
									])),
								A6(
								$author$project$Perfimmo$Input$DatalistInput$view,
								subModel.ax,
								_List_Nil,
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectProduit(model),
									$author$project$Perfimmo$Input$DatalistInput$Dropdown),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectProduit(model),
									$author$project$Perfimmo$Input$DatalistInput$SearchItem),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectProduit(model),
									$author$project$Perfimmo$Input$DatalistInput$SelectItem),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectProduit(model),
									$author$project$Perfimmo$Input$DatalistInput$ClearSelection))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-warning')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Les valeurs des frais et taux doivent être typées au format '),
										A2(
										$rtfeldman$elm_css$Html$Styled$strong,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Pourcentage')
											])),
										$rtfeldman$elm_css$Html$Styled$text(' dans le fichier Excel.')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Vous pouvez télécharger le modèle de fichier d\'import à utiliser '),
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href('/templates/modele-import-supports-produit.xlsx'),
												$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ici')
											])),
										$rtfeldman$elm_css$Html$Styled$text('.')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('upload')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('cartoucheFile')
									]),
								_List_fromArray(
									[
										A2(
										$elm$core$Maybe$withDefault,
										$rtfeldman$elm_css$Html$Styled$text(''),
										A2(
											$elm$core$Maybe$map,
											function (f) {
												return A2(
													$rtfeldman$elm_css$Html$Styled$span,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$title(
															$elm$file$File$name(f))
														]),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text(
															$elm$file$File$name(f))
														]));
											},
											A2(
												$elm$core$Maybe$map,
												function ($) {
													return $.ew;
												},
												subModel.ew)))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$button,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Supports$ImportSupports$List$Update$InputFichier),
										$rtfeldman$elm_css$Html$Styled$Attributes$class('button secondary')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Sélectionner le fichier à uploader')
									]))
							]))
					]));
		case 2:
			var subModel = model.a;
			return A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-info')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('L\'import complet écrasera les anciennes données pour les supports du produit et du cabinet choisi.')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Choisir le produit')
									])),
								A6(
								$author$project$Perfimmo$Input$DatalistInput$view,
								subModel.ax,
								_List_Nil,
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectProduit(model),
									$author$project$Perfimmo$Input$DatalistInput$Dropdown),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectProduit(model),
									$author$project$Perfimmo$Input$DatalistInput$SearchItem),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectProduit(model),
									$author$project$Perfimmo$Input$DatalistInput$SelectItem),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectProduit(model),
									$author$project$Perfimmo$Input$DatalistInput$ClearSelection))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Choisir le cabinet')
									])),
								A6(
								$author$project$Perfimmo$Input$DatalistInput$view,
								subModel.b9,
								_List_Nil,
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectCabinet(model),
									$author$project$Perfimmo$Input$DatalistInput$Dropdown),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectCabinet(model),
									$author$project$Perfimmo$Input$DatalistInput$SearchItem),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectCabinet(model),
									$author$project$Perfimmo$Input$DatalistInput$SelectItem),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectCabinet(model),
									$author$project$Perfimmo$Input$DatalistInput$ClearSelection))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-warning')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Les valeurs des frais et taux doivent être typées au format '),
										A2(
										$rtfeldman$elm_css$Html$Styled$strong,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Pourcentage')
											])),
										$rtfeldman$elm_css$Html$Styled$text(' dans le fichier Excel.')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Vous pouvez télécharger le modèle de fichier d\'import à utiliser '),
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href('/templates/modele-import-supports-cabinet.xlsx'),
												$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ici')
											])),
										$rtfeldman$elm_css$Html$Styled$text('.')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('upload')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('cartoucheFile')
									]),
								_List_fromArray(
									[
										A2(
										$elm$core$Maybe$withDefault,
										$rtfeldman$elm_css$Html$Styled$text(''),
										A2(
											$elm$core$Maybe$map,
											function (f) {
												return A2(
													$rtfeldman$elm_css$Html$Styled$span,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$title(
															$elm$file$File$name(f))
														]),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text(
															$elm$file$File$name(f))
														]));
											},
											A2(
												$elm$core$Maybe$map,
												function ($) {
													return $.ew;
												},
												subModel.ew)))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$button,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Supports$ImportSupports$List$Update$InputFichier),
										$rtfeldman$elm_css$Html$Styled$Attributes$class('button secondary')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Sélectionner le fichier à uploader')
									]))
							]))
					]));
		case 3:
			var subModel = model.a;
			return A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-info')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('L\'import partiel ne mettra à jour que les données contenues dans le fichier importé.')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Choisir le produit')
									])),
								A6(
								$author$project$Perfimmo$Input$DatalistInput$view,
								subModel.ax,
								_List_Nil,
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectProduit(model),
									$author$project$Perfimmo$Input$DatalistInput$Dropdown),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectProduit(model),
									$author$project$Perfimmo$Input$DatalistInput$SearchItem),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectProduit(model),
									$author$project$Perfimmo$Input$DatalistInput$SelectItem),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectProduit(model),
									$author$project$Perfimmo$Input$DatalistInput$ClearSelection))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Choisir le cabinet')
									])),
								A6(
								$author$project$Perfimmo$Input$DatalistInput$view,
								subModel.b9,
								_List_Nil,
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectCabinet(model),
									$author$project$Perfimmo$Input$DatalistInput$Dropdown),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectCabinet(model),
									$author$project$Perfimmo$Input$DatalistInput$SearchItem),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectCabinet(model),
									$author$project$Perfimmo$Input$DatalistInput$SelectItem),
								A2(
									$elm$core$Basics$composeL,
									$author$project$Components$Supports$ImportSupports$List$Update$SelectCabinet(model),
									$author$project$Perfimmo$Input$DatalistInput$ClearSelection))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-warning')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Les valeurs des frais et taux doivent être typées au format '),
										A2(
										$rtfeldman$elm_css$Html$Styled$strong,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Pourcentage')
											])),
										$rtfeldman$elm_css$Html$Styled$text(' dans le fichier Excel.')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Vous pouvez télécharger le modèle de fichier d\'import à utiliser '),
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href('/templates/modele-import-supports-cabinet.xlsx'),
												$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ici')
											])),
										$rtfeldman$elm_css$Html$Styled$text('.')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('upload')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('cartoucheFile')
									]),
								_List_fromArray(
									[
										A2(
										$elm$core$Maybe$withDefault,
										$rtfeldman$elm_css$Html$Styled$text(''),
										A2(
											$elm$core$Maybe$map,
											function (f) {
												return A2(
													$rtfeldman$elm_css$Html$Styled$span,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$title(
															$elm$file$File$name(f))
														]),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text(
															$elm$file$File$name(f))
														]));
											},
											A2(
												$elm$core$Maybe$map,
												function ($) {
													return $.ew;
												},
												subModel.ew)))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$button,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Supports$ImportSupports$List$Update$InputFichier),
										$rtfeldman$elm_css$Html$Styled$Attributes$class('button secondary')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Sélectionner le fichier à uploader')
									]))
							]))
					]));
		default:
			var subModel = model.a;
			return A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-info')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('L\'import d\'ajout de supports dans le référentiel les créera directement, sans attendre de recevoir les informations de ' + ($author$project$Components$Supports$Common$Common$cgpConseilsLabel + '.'))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('alert-warning')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Les valeurs des performances nettes et frais de gestions doivent être typées au format '),
										A2(
										$rtfeldman$elm_css$Html$Styled$strong,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('Pourcentage')
											])),
										$rtfeldman$elm_css$Html$Styled$text(' dans le fichier Excel.')
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$p,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Vous pouvez télécharger le modèle de fichier d\'import à utiliser '),
										A2(
										$rtfeldman$elm_css$Html$Styled$a,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$href('/templates/modele-import-supports-dans-referentiel.xlsx'),
												$rtfeldman$elm_css$Html$Styled$Attributes$target('_blank')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text('ici')
											])),
										$rtfeldman$elm_css$Html$Styled$text('.')
									]))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('upload')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('cartoucheFile')
									]),
								_List_fromArray(
									[
										A2(
										$elm$core$Maybe$withDefault,
										$rtfeldman$elm_css$Html$Styled$text(''),
										A2(
											$elm$core$Maybe$map,
											function (f) {
												return A2(
													$rtfeldman$elm_css$Html$Styled$span,
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$Attributes$title(
															$elm$file$File$name(f))
														]),
													_List_fromArray(
														[
															$rtfeldman$elm_css$Html$Styled$text(
															$elm$file$File$name(f))
														]));
											},
											A2(
												$elm$core$Maybe$map,
												function ($) {
													return $.ew;
												},
												subModel.ew)))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$button,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Components$Supports$ImportSupports$List$Update$InputFichier),
										$rtfeldman$elm_css$Html$Styled$Attributes$class('button secondary')
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Sélectionner le fichier à uploader')
									]))
							]))
					]));
	}
};
var $author$project$Components$Supports$ImportSupports$List$View$drawerContent = function (model) {
	var _v0 = A2(
		$elm$core$Maybe$withDefault,
		_Utils_Tuple2(
			$rtfeldman$elm_css$Html$Styled$Attributes$class(''),
			$rtfeldman$elm_css$Html$Styled$Attributes$disabled(true)),
		A2(
			$elm$core$Maybe$map,
			function (onclick) {
				return _Utils_Tuple2(
					onclick,
					$rtfeldman$elm_css$Html$Styled$Attributes$disabled(false));
			},
			A2(
				$ccapndave$elm_flat_map$Maybe$FlatMap$flatMap,
				function (m) {
					switch (m.$) {
						case 0:
							var record = m.a;
							return A4(
								$elm$core$Maybe$map3,
								F3(
									function (link, file, produitUid) {
										return $rtfeldman$elm_css$Html$Styled$Events$onClick(
											A3(
												$author$project$Components$Supports$ImportSupports$List$Update$AskImportFile,
												$author$project$Components$Supports$ImportSupports$List$Model$ImportCompletSupportsDansProduit(
													{m9: 'Not_Used', kx: produitUid}),
												link,
												file));
									}),
								record.cz,
								record.ew,
								record.cy);
						case 1:
							var record = m.a;
							return A4(
								$elm$core$Maybe$map3,
								F3(
									function (link, file, produitUid) {
										return $rtfeldman$elm_css$Html$Styled$Events$onClick(
											A3(
												$author$project$Components$Supports$ImportSupports$List$Update$AskImportFile,
												$author$project$Components$Supports$ImportSupports$List$Model$ImportPartielSupportsDansProduit(
													{m9: 'Not_Used', kx: produitUid}),
												link,
												file));
									}),
								record.cz,
								record.ew,
								record.cy);
						case 2:
							var record = m.a;
							return A5(
								$elm$core$Maybe$map4,
								F4(
									function (link, file, cabinetUid, produitUid) {
										return $rtfeldman$elm_css$Html$Styled$Events$onClick(
											A3(
												$author$project$Components$Supports$ImportSupports$List$Update$AskImportFile,
												A2(
													$author$project$Components$Supports$ImportSupports$List$Model$ImportCompletDonneesCabinet,
													{m9: 'Not_Used', kx: produitUid},
													{m9: 'Not_Used', kx: cabinetUid}),
												link,
												file));
									}),
								record.cz,
								record.ew,
								record.d7,
								record.cy);
						case 3:
							var record = m.a;
							return A5(
								$elm$core$Maybe$map4,
								F4(
									function (link, file, cabinetUid, produitUid) {
										return $rtfeldman$elm_css$Html$Styled$Events$onClick(
											A3(
												$author$project$Components$Supports$ImportSupports$List$Update$AskImportFile,
												A2(
													$author$project$Components$Supports$ImportSupports$List$Model$ImportCompletDonneesCabinet,
													{m9: 'Not_Used', kx: produitUid},
													{m9: 'Not_Used', kx: cabinetUid}),
												link,
												file));
									}),
								record.cz,
								record.ew,
								record.d7,
								record.cy);
						default:
							var record = m.a;
							return A3(
								$elm$core$Maybe$map2,
								F2(
									function (link, file) {
										return $rtfeldman$elm_css$Html$Styled$Events$onClick(
											A3($author$project$Components$Supports$ImportSupports$List$Update$AskImportFile, $author$project$Components$Supports$ImportSupports$List$Model$ImportSupportsDansReferentiel, link, file));
									}),
								record.hX,
								record.ew);
					}
				},
				model.a0)));
	var clickButton = _v0.a;
	var disabledButton = _v0.b;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('drawer-supports-import')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('drawer-content')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$h2,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Importer un fichier de supports')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$label,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Choisir le type d\'import')
									])),
								A5(
								$author$project$Perfimmo$Input$SelectInput$view,
								model.dd,
								_List_Nil,
								A2($elm$core$Basics$composeL, $author$project$Components$Supports$ImportSupports$List$Update$SelectImportType, $author$project$Perfimmo$Input$SelectInput$Dropdown),
								A2($elm$core$Basics$composeL, $author$project$Components$Supports$ImportSupports$List$Update$SelectImportType, $author$project$Perfimmo$Input$SelectInput$SelectItem),
								A2($elm$core$Basics$composeL, $author$project$Components$Supports$ImportSupports$List$Update$SelectImportType, $author$project$Perfimmo$Input$SelectInput$ClearSelection))
							])),
						A2(
						$elm$core$Maybe$withDefault,
						$rtfeldman$elm_css$Html$Styled$text(''),
						A2($elm$core$Maybe$map, $author$project$Components$Supports$ImportSupports$List$View$drawerContentAfterImportTypeChoice, model.a0)),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('errors')
							]),
						_List_fromArray(
							[
								A2(
								$elm$core$Maybe$withDefault,
								$rtfeldman$elm_css$Html$Styled$text(''),
								A2($elm$core$Maybe$map, $author$project$Components$Supports$ImportSupports$List$View$displayErrors, model.ch))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('drawerActions')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$button,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('button primary done'),
								disabledButton,
								clickButton,
								$rtfeldman$elm_css$Html$Styled$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('loading', model.aG)
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Importer')
							]))
					]))
			]));
};
var $author$project$Components$Supports$ImportSupports$List$View$filtres = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('filters')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Filtrer par type d\'import')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								A2($elm$core$Basics$composeL, $author$project$Components$Supports$ImportSupports$List$Update$Filtrer, $author$project$Components$Supports$ImportSupports$List$Model$FiltrerParTypes),
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.aY.b6)))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Filtrer par produit')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								A2($elm$core$Basics$composeL, $author$project$Components$Supports$ImportSupports$List$Update$Filtrer, $author$project$Components$Supports$ImportSupports$List$Model$FiltrerParProduits),
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.aY.aT)))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Filtrer par cabinet')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								A2($elm$core$Basics$composeL, $author$project$Components$Supports$ImportSupports$List$Update$Filtrer, $author$project$Components$Supports$ImportSupports$List$Model$FiltrerParCabinets),
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.aY.aS)))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Filtrer par statut')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								A2($elm$core$Basics$composeL, $author$project$Components$Supports$ImportSupports$List$Update$Filtrer, $author$project$Components$Supports$ImportSupports$List$Model$FiltrerParStatuts),
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.aY.b7)))
							]))
					]))
			]));
};
var $author$project$Components$Supports$ImportSupports$List$View$view = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('page-import-supports-list'),
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('loading', model.aG)
					]))
			]),
		_List_fromArray(
			[
				$author$project$Components$Produits$Common$ProduitsMenuView$view(2),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
					]),
				_List_fromArray(
					[
						$author$project$Components$Supports$ImportSupports$List$View$filtres(model),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('main')
							]),
						_List_fromArray(
							[
								$author$project$Components$Supports$ImportSupports$List$View$displayImports(model)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$author$project$Components$Supports$ImportSupports$List$Update$SyncDrawerEvents,
				A2(
					$author$project$Components$Drawer$View$view,
					model.w.eq,
					$author$project$Components$Supports$ImportSupports$List$View$drawerContent(model.w))),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('fixed')
					]),
				_List_fromArray(
					[$author$project$Components$Supports$ImportSupports$List$View$actionButtons]))
			]));
};
var $author$project$Components$Supports$List$View$actionButtons = function (model) {
	return $elm$core$Maybe$Nothing;
};
var $author$project$Components$Supports$List$View$supportHref = function (support) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$href(
		$author$project$Routes$Model$routes.fe.mG.dX(support.oE));
};
var $author$project$Components$Supports$List$View$displaySupport = function (support) {
	var source = function () {
		var _v0 = support.mV;
		if (!_v0) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('tag-sync-active'),
						$rtfeldman$elm_css$Html$Styled$Attributes$title('Les données du support sont issues de ' + $author$project$Components$Supports$Common$Common$cgpConseilsLabel)
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text($author$project$Components$Supports$Common$Common$cgpConseilsLabel)
					]));
		} else {
			return $rtfeldman$elm_css$Html$Styled$text('');
		}
	}();
	return A2(
		$rtfeldman$elm_css$Html$Styled$a,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('cartouche-support'),
				$author$project$Components$Supports$List$View$supportHref(support)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('main')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('logo')
							]),
						_List_Nil),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('title')
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(support.nz)
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('infos')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$ul,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$li,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('supportId')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(support.oE)
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$li,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$Attributes$class('societeDeGestion')
											]),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(
												A2($elm$core$Maybe$withDefault, 'Société de gestion inconnue', support.ov))
											]))
									]))
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('aside')
					]),
				_List_fromArray(
					[
						source,
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('action')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$span,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Voir le support')
									]))
							]))
					]))
			]));
};
var $author$project$Components$Supports$List$View$displaySupports = function (model) {
	return A2(
		$elm$core$List$map,
		function (support) {
			return A2(
				$rtfeldman$elm_css$Html$Styled$li,
				_List_Nil,
				_List_fromArray(
					[
						$author$project$Components$Supports$List$View$displaySupport(support)
					]));
		},
		model.mr);
};
var $author$project$Components$Supports$List$Update$ByNameOrSupportId = function (a) {
	return {$: 0, a: a};
};
var $author$project$Components$Supports$List$View$filters = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('filters')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Rechercher par libellé ou identifiant de support')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$input,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Events$onInput(
								A2($elm$core$Basics$composeL, $author$project$Components$Supports$List$Update$SearchSupports, $author$project$Components$Supports$List$Update$ByNameOrSupportId)),
								$rtfeldman$elm_css$Html$Styled$Attributes$class('search'),
								$rtfeldman$elm_css$Html$Styled$Attributes$type_('text'),
								$rtfeldman$elm_css$Html$Styled$Attributes$value(model.e4)
							]),
						_List_Nil)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('form-item')
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$label,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Filtrer par société de gestion')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('multiselect')
							]),
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$map,
								A2($elm$core$Basics$composeL, $author$project$Components$Supports$List$Update$SearchSupports, $author$project$Components$Supports$List$Update$ByCompany),
								$rtfeldman$elm_css$Html$Styled$fromUnstyled(
									$inkuzmin$elm_multiselect$Multiselect$view(model.dK)))
							]))
					]))
			]));
};
var $author$project$Components$Supports$List$View$view = function (state) {
	var loading = function () {
		if (!state.$) {
			return true;
		} else {
			return false;
		}
	}();
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('page-supports-list'),
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('loading', loading)
					]))
			]),
		_List_fromArray(
			[
				$author$project$Components$Produits$Common$ProduitsMenuView$view(1),
				function () {
				if (state.$ === 1) {
					var model = state.a;
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
							]),
						_List_fromArray(
							[
								$author$project$Components$Supports$List$View$filters(model),
								A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$id('main')
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$ul,
										_List_Nil,
										$author$project$Components$Supports$List$View$displaySupports(model))
									]))
							]));
				} else {
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('bottom')
							]),
						_List_Nil);
				}
			}(),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$id('fixed')
					]),
				_List_fromArray(
					[
						A2(
						$elm$core$Maybe$withDefault,
						$rtfeldman$elm_css$Html$Styled$text(''),
						function () {
							if (state.$ === 1) {
								var model = state.a;
								return $author$project$Components$Supports$List$View$actionButtons(model);
							} else {
								return $elm$core$Maybe$Nothing;
							}
						}())
					]))
			]));
};
var $author$project$Main$ErrorView$view = function (cause) {
	var error = 'NotAbleToBuildRouteModel';
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$id('errorView')
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h1,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(error)
					]))
			]));
};
var $author$project$Main$NotFoundView$view = A2(
	$rtfeldman$elm_css$Html$Styled$div,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$id('notFoundView')
		]),
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$h1,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('La page demandée n\'existe pas. Contactez votre administrateur pour plus d\'information.')
				]))
		]));
var $author$project$Main$MainView$subAppView = function (model) {
	var _v0 = _Utils_Tuple2(model.ig, model.iy);
	_v0$0:
	while (true) {
		switch (_v0.b.$) {
			case 0:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('page-home')
							]),
						$author$project$Components$Landing$View$view(subModel));
				}
			case 1:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Main$Msg$ActiveUserComponentAction,
						$author$project$Components$ActiveUser$View$view(subModel));
				}
			case 2:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Main$Msg$ForgotPasswordComponentAction,
						$author$project$Components$ForgotPassword$View$view(subModel));
				}
			case 3:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var _v1 = _v0.b;
					var step = _v1.a;
					var subModel = _v1.b;
					return A2(
						$elm$core$Maybe$withDefault,
						A2(
							$rtfeldman$elm_css$Html$Styled$map,
							$author$project$Main$Msg$CabinetsListComponentAction,
							$author$project$Components$Cabinets$List$View$view(subModel)),
						A2(
							$elm$core$Maybe$map,
							function (_v2) {
								return $rtfeldman$elm_css$Html$Styled$text('');
							},
							step));
				}
			case 5:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var _v3 = _v0.b;
					var step = _v3.a;
					var subModel = _v3.b;
					return A2(
						$elm$core$Maybe$withDefault,
						A2(
							$rtfeldman$elm_css$Html$Styled$map,
							$author$project$Main$Msg$CabinetUsersComponentAction,
							$author$project$Components$Cabinets$Users$List$View$view(subModel)),
						A2(
							$elm$core$Maybe$map,
							function (_v4) {
								return $rtfeldman$elm_css$Html$Styled$text('');
							},
							step));
				}
			case 4:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Main$Msg$CabinetNewComponentAction,
						$author$project$Components$Cabinets$New$View$view(subModel));
				}
			case 6:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Main$Msg$UserNewComponentAction,
						$author$project$Components$Cabinets$Users$New$View$view(subModel));
				}
			case 7:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Main$Msg$CabinetOffresComponentAction,
						$author$project$Components$Cabinets$OffresCommerciales$View$view(subModel));
				}
			case 8:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var _v5 = _v0.b;
					var subModel = _v5.b;
					return A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Main$Msg$ClientsListComponentAction,
						$author$project$Components$Clients$List$View$view(subModel));
				}
			case 9:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Main$Msg$ClientsDetailComponentAction,
						$author$project$Components$Clients$Detail$View$view(subModel));
				}
			case 10:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var _v6 = _v0.b;
					var subModel = _v6.b;
					return A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Main$Msg$ProduitsAction,
						$author$project$Components$Produits$List$View$view(subModel));
				}
			case 11:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Main$Msg$ProduitDetailsAction,
						$author$project$Components$Produits$Details$View$view(subModel));
				}
			case 12:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var _v7 = _v0.b;
					var subModel = _v7.b;
					return A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Main$Msg$ImportSupportsAction,
						$author$project$Components$Supports$ImportSupports$List$View$view(subModel));
				}
			case 13:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Main$Msg$ImportSupportDetailAction,
						$author$project$Components$Supports$ImportSupports$Detail$View$view(subModel));
				}
			case 14:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var _v8 = _v0.b;
					var step = _v8.a;
					var subModel = _v8.b;
					return A2(
						$elm$core$Maybe$withDefault,
						A2(
							$rtfeldman$elm_css$Html$Styled$map,
							$author$project$Main$Msg$ListSupportsAction,
							$author$project$Components$Supports$List$View$view(subModel)),
						A2(
							$elm$core$Maybe$map,
							function (_v9) {
								return $rtfeldman$elm_css$Html$Styled$text('');
							},
							step));
				}
			case 15:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var subModel = _v0.b.a;
					return A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Main$Msg$SupportDetailAction,
						$author$project$Components$Supports$Details$View$view(subModel));
				}
			case 16:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					return A3(
						$rtfeldman$elm_css$Html$Styled$Keyed$node,
						'div',
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('subApp')
							]),
						_List_fromArray(
							[
								_Utils_Tuple2(
								'subApp',
								A3(
									$rtfeldman$elm_css$Html$Styled$Keyed$node,
									'div',
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Main$MainModel$subAppContainerClass)
										]),
									_List_Nil))
							]));
				}
			case 17:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var _v10 = _v0.b;
					return $author$project$Main$NotFoundView$view;
				}
			default:
				if (!_v0.a.$) {
					break _v0$0;
				} else {
					var cause = _v0.b.a;
					return $author$project$Main$ErrorView$view(cause);
				}
		}
	}
	return A3(
		$rtfeldman$elm_css$Html$Styled$Keyed$node,
		'div',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('subApp')
			]),
		_List_fromArray(
			[
				_Utils_Tuple2(
				'subApp',
				A3(
					$rtfeldman$elm_css$Html$Styled$Keyed$node,
					'div',
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class($author$project$Main$MainModel$subAppContainerClass)
						]),
					_List_Nil))
			]));
};
var $author$project$Main$MainView$view = function (model) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		function () {
			var _v0 = A2($PerformanceIMMO$elm_utils$Perfimmo$Http$NavigationLinkUtils$findNavLink, 'logout', model.fK.nf);
			if (!_v0.$) {
				var logoutRel = _v0.a;
				return _List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$id('layout')
							]),
						_List_fromArray(
							[
								A2($author$project$Main$MainView$headerView, model, logoutRel),
								$author$project$Main$MainView$errorsView(model),
								$author$project$Main$MainView$subAppView(model)
							]))
					]);
			} else {
				return _List_fromArray(
					[
						$author$project$Main$MainView$errorsView(model),
						$author$project$Main$MainView$logoutViewsOrAuth(model)
					]);
			}
		}());
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{
		m1: $author$project$Main$MainUpdate$init,
		nN: $author$project$Main$Msg$UrlChanged,
		nO: $author$project$Main$Msg$LinkClicked,
		oD: $author$project$Main$subscriptions,
		oV: $author$project$Main$MainUpdate$update,
		o8: A2(
			$elm$core$Basics$composeR,
			$author$project$Main$MainView$view,
			A2(
				$elm$core$Basics$composeR,
				$rtfeldman$elm_css$Html$Styled$toUnstyled,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$singleton,
					$elm$browser$Browser$Document('MustCompliance'))))
	});
/*
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (mustComplianceUrl) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (docGeneratorHost) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (disabledModules) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (backHost) {
									return $elm$json$Json$Decode$succeed(
										{k9: backHost, lV: disabledModules, l0: docGeneratorHost, ny: mustComplianceUrl});
								},
								A2($elm$json$Json$Decode$field, 'backHost', $elm$json$Json$Decode$string));
						},
						A2(
							$elm$json$Json$Decode$field,
							'disabledModules',
							$elm$json$Json$Decode$oneOf(
								_List_fromArray(
									[
										$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
										A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
									]))));
				},
				A2($elm$json$Json$Decode$field, 'docGeneratorHost', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'mustComplianceUrl', $elm$json$Json$Decode$string)))(0)}});}(this));
*/
export const Elm = {'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (mustComplianceUrl) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (docGeneratorHost) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (disabledModules) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (backHost) {
									return $elm$json$Json$Decode$succeed(
										{k9: backHost, lV: disabledModules, l0: docGeneratorHost, ny: mustComplianceUrl});
								},
								A2($elm$json$Json$Decode$field, 'backHost', $elm$json$Json$Decode$string));
						},
						A2(
							$elm$json$Json$Decode$field,
							'disabledModules',
							$elm$json$Json$Decode$oneOf(
								_List_fromArray(
									[
										$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
										A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, $elm$json$Json$Decode$string)
									]))));
				},
				A2($elm$json$Json$Decode$field, 'docGeneratorHost', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'mustComplianceUrl', $elm$json$Json$Decode$string)))(0)}};
  